import React from 'react';
import WeAreImg from '../image/theme/we-are.png'
function AboutWeAreHere() {
    return (
        <>
            <div className='ph-about-here'>
                <div className='container-lg'>
                <h2 className="ph-main-title-2">Why PurpleHealth?</h2>
                <div className='ph-about-here-outer'>
                    <div className='row align-items-center'>
                        <div className='col-md-5 mb-4 mb-md-0'>                            
                            <div className='ph-about-here-img'>
                                <img src={WeAreImg} className="img-fluid ph-about-fluid"  width="300" height="auto" alt="we are here"/>
                            </div>
                        </div>
                        <div className='col-md-7'>
                        <p className="lead-sm mb-0">
                        With PurpleHealth, you're choosing a partner committed to transforming healthcare through technology. Our unique approach allows us to not just strategize but to design, develop, and deploy bespoke software solutions that empower our clients and partners in the healthcare sector.We have a team of digital marketing specialists and domain experts in pharma operations and marketing. We are a technical team of developers and software engineers which sets us apart from traditional methods of digital marketing.
                        </p>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </>     
    )
}
export default AboutWeAreHere;