import React, { Component } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";

export default class AdminDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  ChangeAdmin = (e) => {
    window.location.href = "/admin/dashboardAdmin";
  };

  ChangeJournal = (e) => {
    window.location.href = "/adminManagement/journalDashboard/journalListing";
  };

  ChangeDoctor = (e) => {
    // window.location.href = "/admin/doctor-dashboard";
    window.location.href = "/admin/doctor-dashboard-list";
  };

  render() {
    return (
      <div>
        <Header></Header>
        <div className="purpleWrap-adminM">
          <div className="container">
            <div className="admindashbord-Outer">
              <div className="admindashbord-Div text-center">
                <span
                  className="sectionTag-admindash sectionTag-admindash-width"
                  onClick={this.ChangeAdmin}
                >
                  Admin Management
                </span>
                <span
                  className="sectionTag-admindash sectionTag-admindash-width"
                  onClick={this.ChangeJournal}
                >
                  Admin Journals
                </span>
                <span
                  className="sectionTag-admindash sectionTag-admindash-width"
                  onClick={this.ChangeDoctor}
                >
                  Doctor Dashboard
                </span>
                
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    );
  }
}
