import React, { Component, Suspense } from "react";
import Edit from "../../../../image/icons/edit.png";
import tick from "../../../../image/icons/tick.png";
import Deactivate from "../../../../image/icons/deactvate.png";
import { apiCalling } from "../../../../apiService";
import $ from "jquery";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import Header from "../../../../layout/header";
import Footer from "../../../../layout/footer";
import Close from "../../../../image/icons/close-mark.png"

// import CountriesPopup from "./countriesPopup";
// import addCountryPopup from "./addCountryPopup"
const folderName = window.localStorage.getItem("homeName");
//const Header = React.lazy(() => import("../../" + folderName + "/header"));
//const Footer = React.lazy(() => import("../../" + folderName + "/footer"));

export default class states extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subCityManagementData: [],
      totalCount: 0,
      activePage: 1,
      showMessage: "Waiting for data... ",
      stateId:0,
      states:"",
      aliasName:"",
      countryId:0,
      region:"",
      subCityId: 0,
      cityId:0,
      subCity:"",
      subCityName:"",
      city:"",
      countryDropDown:[]
    };
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
    var Offset;
    var max;
    if (pageNumber === "1") {
      Offset = "0";
      max = "10";
      this.setState({
        activePage: pageNumber,
        isLoading: "0",
      });
    } else {
      Offset = parseInt(pageNumber - 1) * 10;
      max = "10";
      this.setState({
        activePage: pageNumber,
        isLoading: "0",
      });
    }
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "subcitiesList",
        searchKey:
          $("#selectCategory").val() === "0" ? "" : $("#selectCategory").val(),
        searchString: $("#cityTxt").val(),
        offset: JSON.stringify(Offset),
        max: max,
      }),
    };
    $(".loader").show();
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        this.setState({
          subCityManagementData: data.result.subCity,
          totalCount: data.result.totalCount,
        });
        $(".loader").hide();
      }
    });
  }

  componentDidMount() {
    this.getStateDetails();
    this.dropdownList();
  }
dropdownList(){
  const apiJson = {
    method: "POST",
    body: JSON.stringify({
      functionName: "getDropdownLists"
    })
  };
  apiCalling(apiJson).then((data) => {
    if (data.success === "1") {
      this.setState({
        countryDropDown:data.result[0].cityData
      });
    }
  });
}
  getStateDetails() {
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "subcitiesList",
        searchKey: "",
        searchString: "",
        max: "10",
        offset: "0",
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        $(".loader").hide();
        this.setState({
          subCityManagementData: data.result.subCity,
          totalCount: data.result.totalCount,
        });
      }
      if (data.success === "0") {
        $(".loader").hide();
        this.setState({
          subCityManagementData: [],
          showMessage: data.errorMessage,
        });
      }
    });
  }
  onTodoChangeState = (value) => {
    
    this.setState({
      subCityName:value
    })
  }
  changeText = () => {
    if ($("#selectCategory").val() !== "0") {
      document.getElementById("cityTxt").disabled = false;
      $("input[name=cityTxt]").focus();
    } else {
      document.getElementById("cityTxt").disabled = true;
    }
    document.getElementById("cityTxt").value = "";
  };

  setDropDownState = (event) => {
    //alert($("#txtCountryDropdownId").val())
    //alert(event.target.value,'ooooooooooo')
    if ($("#txtCountryDropdownId").val() !== "0") {
      this.setState({
        cityId:event.target.value
      })
    }
  }
  searchHandler() {
    //debugger;
    if ($("#selectCategory").val() !== "0" && $("#cityTxt").val() === "") {
      var t = document.getElementById("selectCategory");
      var selectedText = t.options[t.selectedIndex].text;
      alert(selectedText + " Required");
      $("#cityTxt").focus();
      return false;
    } else {
      let word = $("#cityTxt").val()
      let newString = word.trim();
      const searchData = {
        method: "POST",
        body: JSON.stringify({
          functionName: "subcitiesList",
          searchKey:
            $("#selectCategory").val() === "0"
              ? ""
              : $("#selectCategory").val(),
          searchString: newString,
          offset: "0",
          max: "10",
        }),
      };
      $(".loader").show();
      apiCalling(searchData).then((data) => {
        
        if (data.success === "1") {
          $("#paginationType").show();
          $(".loader").hide();
          this.setState({
            subCityManagementData: data.result.subCity,
            totalCount: data.result.totalCount,
            activePage: 1,
          });
        }
        if (data.success === "0") {
          $("#paginationType").hide();
          $(".loader").hide();
          this.setState({
            subCityManagementData: [],
            showMessage: data.errorMessage,
          });
        }
      });
    }
  }
  category1PopOpen(){
    $("#popup1").show()
        $(".popOverlay").css("background-color","rgba(0, 0, 0, 0.6)");
        $("#addCitySave").show();
        $("#txtStateId").focus();       
        $("#txtCity").focus();
        $("#txtAlias").focus();
  }
  category2PopOpen = (subCityId)=>{
    this.setState({
      subCityId: 0,
      subCity: "",
      cityId: 0,
    })
    const searchData = {
      method: "POST",
      body: JSON.stringify({
        functionName:"subcitiesEditList",
        subCityId:subCityId
      })
    }
    apiCalling(searchData).then((data) => {
      if(data.success === "1"){
        this.setState({
          subCityId: data.result.subCity[0].subCityId,
          subCityName: data.result.subCity[0].subCityName,
          cityId: data.result.subCity[0].cityId,
        })
      }
    })
    
    $("#popup2").show()
        $(".popOverlay").css("background-color","rgba(0, 0, 0, 0.6)");
        $("#txtCityId").show();
        $("#cityId").show();
        $("#UpdateDetails2").show();
        $("#txtStateId").focus();       
        $("#txtCity").focus();
        $("#txtAlias").focus();
  }
  closepopup(){
    $("#popup1").hide();
    $("#popup2").hide();
    $(".popOverlay").css("background-color","transparent");
    $("#txtStateId").val('')
    $("#txtCity").val('')
    $("#txtAlias").val('')

}
saveHandle() {

  let cityId = this.state.cityId;
  let subCityId = this.state.subCityId;
  var flag = true
  if(subCityId === 0){
  let subCity = $("#txtCity").val();
    if (cityId === 0) {
        alert("City Id Required")
        flag = false;
    }
    if ($("#txtCity").val() === "") {
      alert("SubCity Required")
      $("#txtCity").focus();
      flag = false;
  }
    if (flag === true) {
      
        const dataSave = {
            method:"POST",
            body: JSON.stringify({
                functionName:"subcitiesInsertionList",
                subCity:subCity,
                cityId: cityId,
            }),
        };
        apiCalling(dataSave).then((data) => {
            if (data.success === "1") {
                let message ="Saved successfully";
             
                $("#popup1").hide()
                $("#popup2").hide()
                alert(message);
                setTimeout(() => {
                    window.location.reload();
                  }, 1000);
            } else {
                alert(data.errorMessage)
                $("#txtCity").focus();
            }
        })
    }
  }
  else{
  let subCity = this.state.subCityName;
    if (cityId === 0) {
      alert("City Id Required")
      flag = false;
  }
  if ($("#txtCity2").val() === "") {
    alert("City Required")
    $("#txtCity2").focus();
    flag = false;
  } 
if ($("#txtAlias2").val() === "") {
  alert("City Alias Required")
  $("#txtAlias2").focus();
  flag = false;
  } 
  
  if (flag === true) {
    
      const dataSave = {
          method:"POST",
          body: JSON.stringify({
              functionName:"subcitiesInsertionList",
              subCity:subCity,
              cityId: cityId,
              subCityId:subCityId,
          }),
      };
      apiCalling(dataSave).then((data) => {
          if (data.success === "1") {
              let message = "Updated successfully "
            alert(message);
              $("#popup1").hide()
              $("#popup2").hide()
              setTimeout(() => {
                  window.location.reload();
                }, 1000);
          } else {
              alert(data.errorMessage)
              $("#txtCity").focus();
          }
      })
  }
  }
}
  render() {
    return (
      <div className="bg-clr-wthHead">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
            <div className="loader"></div>
            <div className="container mb-5">
              <div>
                <ul className="breadcrum-adminMangmnt">
                  <li>
                    <a href="/admin/dashboardAdmin">Dashboard</a>
                  </li>
                  <li>
                    <a href="/adminManagement/manageLocations">
                      Manage Locations
                    </a>
                  </li>
                  <li>
                    <a href="/adminManagement/countries">
                      Country
                    </a>
                  </li>
                  <li>
                    <a href="/adminManagement/states">
                      States
                    </a>
                  </li>
                  <li>
                    <a href="/adminManagement/cities">
                      City
                    </a>
                  </li>
                  <li>
                    <a href="/adminManagement/subcities" className="current">
                      Subcity
                    </a>
                  </li>
                </ul>
              </div>
              <div className="head-hospMangmntList">SubCities</div>

              <div className="HosptlMangemntTable">
                <div className="AddNewHspbtnDiv">
                  <button className="addnewhspbtn" onClick={this.category1PopOpen}>Add New SubCities</button>
                  
                </div>
                {this.state.totalCount > 5 ? (
                  <div
                    className="paginationSection orderm-paginationSection pagination-HspList"
                    id="paginationType"
                  >
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={10}
                      totalItemsCount={this.state.totalCount}
                      pageRangeDisplayed={10}
                      onChange={this.handlePageChange}
                    />
                  </div>
                ) : (
                  ""
                )}
                <div className="tableHeader-HspMangemnt">
                  <div className="selectField-HspMangmnt">
                    <select
                      className="slecthsp slecthspMob"
                      id="selectCategory"
                      name="selectCategory"
                      onChange={() => this.changeText()}
                    >
                      <option value="0">Search In</option>
                      <option value="id">Subcity Id</option>
                      <option value="subCityName">SubCity</option>
                    </select>
                  </div>
                  <div className="selectField-HspMangmnt">
                    <input
                      type="text"
                      className="hspmngInput hspmngInputmob"
                      name="cityTxt"
                      id="cityTxt"
                      disabled
                      tabIndex="0"
                      onKeyPress={(event) => {
                        if (
                          event.key === "Enter" ||
                          event.key === "NumpadEnter"
                        ) {
                          event.preventDefault();
                          this.searchHandler();
                        }
                      }}
                    />
                  </div>
                  <div className="selectField-HspMangmnt">
                    <button
                      className="searchbtn-hspMng"
                      onClick={() => this.searchHandler()}
                    >
                      Search
                    </button>
                  </div>
                </div>
                {this.state.subCityManagementData.length > 0 ? (
                  <div className="table-responsive">
                    <table className="table table-bordered-hsp">
                      <thead>
                        <tr>
                          <th className="tablehead-hspmng" colSpan="2">
                            ID
                          </th>
                          <th className="tablehead-hspmng" colSpan="4">
                            SubCity
                          </th>
                          <th className="tablehead-hspmng" colSpan="3">
                           City
                          </th>
                          <th className="tablehead-hspmng" colSpan="3">
                            Edit SubCity
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.subCityManagementData.map((data) => (
                          <tr key={data.subCityId}>
                            <td colSpan="2">{data.subCityId}</td>
                            <td colSpan="4">{data.subCityName}</td>
                            <td colSpan="3">{data.cityName}</td>
                            <td colSpan="4" className="nowrap">
                              <div className="ph_actn_img">
                                <div className="mr-2 ">
                                  <img
                                    src={Edit}
                                    alt=""
                                    width="18"
                                    className="img-fluid"
                                    style={{cursor:"pointer"}}
                                    onClick={()=>{this.category2PopOpen(data.subCityId)}}
                                    />
                                  </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {this.state.totalCount > 5 ? (
                      <div className="paginationSection orderm-paginationSection pagination-HspList pagination-HspListwidth">
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={10}
                          totalItemsCount={this.state.totalCount}
                          pageRangeDisplayed={10}
                          onChange={this.handlePageChange}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <p className="error-msg text-center mb-0">
                    {" "}
                    {this.state.showMessage}{" "}
                  </p>
                )}
              </div>
            </div>
          </section>
        </Suspense>
        <div className="popBack" id="popup1">
          <div className="popOverlay" onClick={this.category1PopClose}></div>
          <div className="customizationPop">
              <input type="hidden" id="stateId" />
              <div className="popuphead-qualifctn">
                <h2>City Details</h2>
              </div>
              <span className="closepopupqual" type="button" onClick={() => this.closepopup()} ><img src={Close} alt="Close" className="img-fluid" width="25" /></span>
                <div className="popupdiv">
                  <label htmlFor="" className="qualifcatin-popuplabel">SubCity <span className="star-red-qual">*</span></label>
                  <input type="text" className="qualifcatin-popupInput" id="txtCity" name="txtCity"  placeholder="SubCity" />
                  <label htmlFor="" className="qualifcatin-popuplabel" id ="stateDropDown">City <span className="star-red-qual">*</span></label>
                  <div className="selectField-HspMangmnt">
                    
                      <select
                      className="slecthsp slecthspMob"
                      id="txtCountryDropdownId"
                      name="selectState"
                     // onChange={()=>this.setDropDownState()}
                     value={this.state.cityId} onChange={this.setDropDownState}
                      >
                        <option key="0" value="0">Select City...</option>
                        {this.state.countryDropDown.map((data)=>(
                      <option key={data.cityId} value={data.cityId} >{data.cityName}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="popupbutndiv">
                  <button className="butnsave-qual"  id="addCitySave"  onClick={() => this.saveHandle()}>Save</button>
                  <button className="butncancel-qual"onClick={() => this.closepopup()}>Cancel</button>
                </div>
          </div>
        </div>
        <div className="popBack" id="popup2">
          
          <div className="popOverlay" onClick={this.category2PopClose}></div>
          <div className="customizationPop">
            <input type="hidden" id="stateId" />
            <div className="popuphead-qualifctn">
              <h2>SubCity Details</h2>
            </div>
            <span className="closepopupqual" type="button" onClick={() => this.closepopup()} ><img src={Close} alt="Close" className="img-fluid" width="25" /></span>
              <div className="popupdiv">
                <label htmlFor="" className="qualifcatin-popuplabel">SubCity <span className="star-red-qual">*</span></label>
                <input type="text" className="qualifcatin-popupInput" id="txtCity2" name="txtCity2" onChange={(e)=>this.onTodoChangeState(e.target.value)} value={this.state.subCityName} />
                <label htmlFor="" className="qualifcatin-popuplabel" id ="stateDropDown">City <span className="star-red-qual">*</span></label>
                <div className="selectField-HspMangmnt">
                  <select
                  className="slecthsp slecthspMob"
                  id="txtCountryDropdownId"
                  name="selectState"
                 // onChange={()=>this.setDropDownState()}
                 value={this.state.cityId} onChange={this.setDropDownState}
                  >
                    <option>Select City...</option>
                    {this.state.countryDropDown.map((data)=>(
                      <option  key={data.cityId} value={data.cityId}  selected={data.cityId === this.state.cityId}>{data.cityName}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="popupbutndiv">
                <button className="butnsave-qual" id="UpdateDetails2" onClick={() => this.saveHandle()}>Update</button>
                <button className="butncancel-qual"onClick={() => this.closepopup()}>Cancel</button>
              </div>
          </div>
        </div>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}

