import React, { Component } from "react";
import $ from "jquery";
import Header from "../layout/header";
import Footer from "../layout/footer";
import heartLine from "../image/theme/heart-20.png";
import heartBg from "../image/theme/heart-21.png";
import upload from "../image/theme/upload.png";
import twitter from "../image/theme/twitter-12.png";
import fb from "../image/theme/facebook-sm.png";
import insta from "../image/theme/instagram-sm.png";
import pintr from "../image/theme/pinterest-sm.png";
import profile from "../image/theme/Screenshot-3.png";
import correct from "../image/theme/correct.png";
import booking from "../image/theme/booking.png";
import phone from "../image/theme/phone-b.png";
import chat from "../image/theme/comment.png";
import mail from "../image/theme/mail.png";
import video from "../image/theme/film.png";
import info from "../image/theme/info.png";
import speciality from "../image/theme/work.png";
import service from "../image/theme/checklist.png";
import hospital from "../image/theme/hospital.png";
import comment from "../image/theme/comment-12.png";
import blog from "../image/theme/blog-1.png";
import question from "../image/theme/question.png";
import booking1 from "../image/theme/booking-12.png";
import phone1 from "../image/theme/phone-13.png";
import chat1 from "../image/theme/comment-11.png";
import mail1 from "../image/theme/mail-11.png";
import video1 from "../image/theme/film-11.png";
import info1 from "../image/theme/info-11.png";
import speciality1 from "../image/theme/work-1.png";
import service1 from "../image/theme/checklist-1.png";
import hospital1 from "../image/theme/hospital-1.png";
import comment1 from "../image/theme/comment-13.png";
import blog1 from "../image/theme/blog-2.png";
import question1 from "../image/theme/question-1.png";
import hspImg from "../image/theme/169.png";
import close from "../image/theme/close-10.png";
import DatePicker from "react-datepicker";
import { apiCalling } from "../apiService";
import "react-datepicker/dist/react-datepicker.css";

export default class DoctorDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      professionalStatement: "",
      doctorBD: [],
      drSpecialization: [],
      hospitalDetails: [],
      drServices: [],
      testing: "yyy",
    };
  }

  handleChange = (date) => {
    this.setState({
      startDate: date,
    });
    $(".react-datepicker__time-container").show();
    $(".react-datepicker__navigation").hide();
    $(".timeClose").show();
  };
  addToFavourites(flag) {
    let functionName = "";
    if (flag == "1") {
      functionName = "addToFavourites";
    }
    if (flag == "0") {
      functionName = "";
    }
    const jsonData = {
      method: "POST",
      body: JSON.stringify({
        functionName: "addToFavourites",
        siteId: localStorage.getItem("siteId"),
        userId: localStorage.getItem("userId"),
        favouriteUserId: localStorage.getItem("doctorPID"),
      }),
    };

    apiCalling(jsonData).then((data) => {
      if (data.success == "1") {
        alert(data.successMessage);
      }
    });
  }
  DrDetailLiked() {
    $("#dr-detail-liked .imgdislike").toggle();
    $("#dr-detail-liked .imgliked").toggle();
  }
  componentDidMount() {
    let self = this;
    $("#callLink").hide();
    $("#chatLink").hide();
    $("#videoLink").hide();
    $("#emailLink").hide();
    $("#bookLink").hide();
    let doctorId;
    if (self.props.location.doctorId) {
      localStorage.setItem("doctorPID", self.props.location.doctorId);
      doctorId = self.props.location.doctorId;
    } else {
      doctorId = localStorage.getItem("doctorPID");
    }

    const doctorDetails = {
      method: "POST",
      body: JSON.stringify({
        functionName: "doctorProfile",
        siteId: localStorage.getItem("siteId"),
        doctorId: doctorId,
      }),
    };

    apiCalling(doctorDetails).then((data) => {
      if (data.success == "1") {
        this.setState({
          doctorBD: data.doctorData[0],
        });

        this.setState({
          hospitalDetails: data.doctorData[0].hospitalDetails,
        });
        //this.state({ drServices: data.doctorData[0].services[0] });
        data.doctorData[0].specialization.map((sp) => {
          this.setState({
            drSpecialization: this.state.drSpecialization.concat(
              sp.specialization
            ),
          });
        });
        data.doctorData[0].services.map((sp) => {
          if (sp == "Call a Doctor") {
            $("#callLink").show();
          }
          if (sp == "Messaging") {
            $("#chatLink").show();
          }
          if (sp == "Video Chat") {
            $("#videoLink").show();
          }
          if (sp == "Email Consult") {
            $("#emailLink").show();
          }
          if (sp == "Book Appointment") {
            $("#bookLink").show();
          }
        });
        // });
        // alert(data.doctorData[0].Favouriteflag);
        if (data.doctorData[0].Favouriteflag == "1") {
          $("#dr-detail-liked .imgdislike").toggle();
          $("#dr-detail-liked .imgliked").toggle();
        }
      } else {
        alert(data.errorMessage);
      }
    });
    $("#dr-detail-liked .imgliked").hide();
    $(".timeClose").on("click", function () {
      $(this).hide();
      $(".react-datepicker__time-container").hide();
      $(".react-datepicker__navigation").show();
    });
  }

  render() {
    return (
      <div className="purpleWrap">
        <Header></Header>
        <div className="container">
          <div className="doctorDetailMain">
            <div className="doctorDetailProfile mt-5">
              <div className="doctorDetailProfileImg">
                <img src={this.state.doctorBD.image} width="100"></img>
              </div>
              <h5 className="drName mb-3">
                {this.state.doctorBD.firstName}
                {this.state.doctorBD.lastName}
              </h5>
              <span className="drspecialist">
                {this.state.doctorBD.primarySpeciality}
              </span>
              <p className="my-3">{this.state.doctorBD.address}</p>
              <span className="profileAvailable">
                <img src={correct} width="17"></img>Available
              </span>
            </div>
            <div className="doctorDetailStatement mt-lg-5 mt-md-5 mt-sm-2">
              <h4>Professional Statement</h4>
              <p>{this.state.doctorBD.professionalStatement}</p>
              <ul className="iconUl">
                <li>
                  <span
                    className="iconOuter imgshowhide"
                    id="dr-detail-liked"
                    onClick={() => this.DrDetailLiked()}
                  >
                    <img
                      src={heartLine}
                      className="imgdislike"
                      onClick={() => this.addToFavourites("1")}
                    ></img>
                    <img
                      src={heartBg}
                      className="imgliked"
                      onClick={() => this.addToFavourites("0")}
                    ></img>
                  </span>
                </li>
                <li id="iconOuterSocial">
                  <span className="iconOuter">
                    <img src={upload}></img>
                  </span>
                  <span className="iconSocial">
                    <img src={fb}></img>
                    <img src={insta}></img>
                    <img src={twitter}></img>
                    <img src={pintr}></img>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="drDetailServicemain py-3">
            <a href="../patient/bookappointment" id="bookLink">
              <span className="drDetailServiceSpan imgshowhide">
                <img src={booking} className="imgshow" width="22"></img>
                <img src={booking1} className="imghide" width="22"></img> Book
              </span>
            </a>
            <a href="#" id="callLink">
              <span className="drDetailServiceSpan imgshowhide">
                <img src={phone} className="imgshow" width="22"></img>
                <img src={phone1} className="imghide" width="22"></img> call
              </span>
            </a>
            <a href="#" id="chatLink">
              <span className="drDetailServiceSpan imgshowhide">
                <img src={chat} className="imgshow" width="22"></img>
                <img src={chat1} className="imghide" width="22"></img> chat
              </span>
            </a>
            <a href="#" id="emailLink">
              <span className="drDetailServiceSpan imgshowhide">
                <img src={mail} className="imgshow" width="22"></img>
                <img src={mail1} className="imghide" width="22"></img> email
              </span>
            </a>
            <a href="#" id="videoLink">
              <span className="drDetailServiceSpan imgshowhide">
                <img src={video} className="imgshow" width="22"></img>
                <img src={video1} className="imghide" width="22"></img> video chat
              </span>
            </a>
            {/*<span className="drDetailServiceSpan imgshowhide">
              <img src={info} className="imgshow" width="22"></img>
              <img src={info1} className="imghide" width="22"></img> doctor info
            </span>
            <span className="drDetailServiceSpan imgshowhide">
              <img src={speciality} className="imgshow" width="22"></img>
              <img src={speciality1} className="imghide" width="22"></img>{" "}
              specialities
            </span>
            <span className="drDetailServiceSpan imgshowhide">
              <img src={service} className="imgshow" width="22"></img>
              <img src={service1} className="imghide" width="22"></img> service
              offered
            </span>
            <span className="drDetailServiceSpan imgshowhide">
              <img src={hospital} className="imgshow" width="22"></img>
              <img src={hospital1} className="imghide" width="22"></img> office
              details
            </span>
            <span className="drDetailServiceSpan imgshowhide">
              <img src={comment} className="imgshow" width="22"></img>
              <img src={comment1} className="imghide" width="22"></img> comments
            </span>
            <span className="drDetailServiceSpan imgshowhide">
              <img src={blog} className="imgshow" width="22"></img>
              <img src={blog1} className="imghide" width="22"></img> health blog
            </span>
            <span className="drDetailServiceSpan imgshowhide">
              <img src={question} className="imgshow" width="22"></img>
              <img src={question1} className="imghide" width="22"></img> q & a
            </span>*/}
          </div>
          {this.state.hospitalDetails.map((hdata) => {
            let place = [hdata.city, hdata.state, hdata.country];

            return (
              <div className="drDetailHospital row">
                <div className="col-lg-8 row">
                  <div className="col-lg-4 drDetailHospitalImg mb-4">
                    <img src={hdata.hospitalImage}></img>
                  </div>

                  <div className="col-lg-8 drDetailHospitalDet mb-4">
                    <h5 className="drDetailHospitalHead">{hdata.name}</h5>
                    <p>{hdata.address}</p>
                    <p>{place.join(",")}</p>

                    <p>{hdata.zip == "" ? "" : "ZIP - " + hdata.zip}</p>
                    <p>Phone - {hdata.phoneNumber}</p>
                  </div>
                </div>
                <div className="col-lg-4 calendarDiv">
                  <span className="timeClose">
                    <img src={close} width="20"></img>
                  </span>
                  <DatePicker
                    selected={this.state.startDate}
                    onChange={this.handleChange}
                    showTimeSelect
                    timeIntervals={15}
                    timeCaption="Select the hour"
                    dateFormat="MMMM d, yyyy h:mm aa"
                    inline
                  />
                </div>
              </div>
            );
          })}
        </div>
        <Footer></Footer>
      </div>
    );
  }
}
