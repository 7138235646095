import React, { Component } from 'react';
import $ from "jquery";

export default class AppointmentEditPopup extends Component {
  componentDidMount(){
    $(".popup-hide").click(function () {
      $(".popup-overlay").hide();
    });
  }

  render() {
    return (
      <div className="popup-overlay" id="appointment-edit-main">
        <div className="popup-content">
          <div className="modal-popup">
            <a className="close-popup popup-hide" >
              &times;
            </a>
            <div className="header border-0  text-left"> Change Appointment Date and Time </div>
            <div className="content">
              <div className="row">
                <div className="col-lg-6">
                    <label className="label-text">Patient Name</label>
                    <input type="text" className="input-design" />
                </div>
                <div className="col-lg-6">
                    <label className="label-text">Appointment Date & Time</label>
                    <input type="text" className="input-design" />
                </div>
              </div>
              <div className="text-center">
                <a href="#">
                  <input type="submit" value="Change" className="formButtonBg"></input>
                </a>
                  <input type="button" value="cancel" className="formButton cancelButtonBg popup-hide"  ></input>
              </div>
            </div>
          </div>
        </div>
      </div>
                          
    )
  }
}
