import React, { Component } from 'react';
import digitalBrand from "../image/theme/insightx.png"
export default class pdtDoctorBanner extends Component {

  render() {
    return (
        <div className="ph-main-outer pb-0 py-4">
     <div className='container-lg'>
        <div className="row">
            <div className="col-lg-6 d-md-flex align-items-center">
                <div className="ph-db-banner">
                    {/* <span className="ph-banner-title">
                       Products
                    </span> */}
                    <h1 className="ph-main-title mt-4">
                    Insightx
                    </h1>
                    {/* <p className="lead">
                    Your digital brand is the face of your practice in the virtual world. With focus on personalised content creation, we help you build trust, expand your reach, and navigate the evolving landscape of healthcare in the digital age. 
                    </p> */}
                </div>
            </div>
            <div className="col-lg-6 pt-5 py-md-0 pb-3">
                <div>
                    <img src={digitalBrand} alt="digitalBrand" width="" height="" className='img-fluid'/>
                </div>
            </div>
        </div>
    </div>
    </div>
    )
  }
}
