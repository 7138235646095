import React, { Component } from "react";
import { awsAPIUrlcommon } from "../apiService";

export default class UrlRedirect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageToken: window.location.pathname.split("/").pop(),
    };
  }
  componentDidMount() {
    this.getMinifiedUrl();
  }
  async getMinifiedUrl() {
    const apiReq = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getSiteUrlByMinifiedId",
        minifiedId: this.state.pageToken.split("=").pop(),
      }),
    };
    awsAPIUrlcommon(apiReq).then((data) => {
      if (data.success != "0") window.location.href = data.siteUrl;

      //   window.location.href = data.siteUrl;
    });
  }
  render() {
    return <div>Redirecting...</div>;
  }
}
