import React, { Component } from 'react';
import Header from "../layout/header"
import Footer from "../layout/footer"
import PdtBnner from "../home/product-for-pharma-company-banner"
import DigitalSolutions from "../home/product-for-pharma-company-solution"
import DigitalServices from "../home/product-for-phrama-company-services"
import DigitalSuccess from "../home/product-for-pharma-company-success-stories"
import AboutTakeNextStep from './about-take-next-step';
export default class PdtPharmaCompany extends Component {
  componentDidMount(){
    document.getElementById("products").classList.add("active");
  }

  render() {
    return (
      <div className="ph-main-wrapper">
          <Header></Header>
            <PdtBnner/>
         <DigitalSolutions/>
         <DigitalServices/>
         <DigitalSuccess/>
         <AboutTakeNextStep/>
          <Footer></Footer>
      </div>
    )
  }
}
