import React, { Component } from "react";
import $ from "jquery";
import Slider from "react-slick";
import { apiCalling } from "../apiService";
import { isAndroid, isIOS } from "react-device-detect";
import { Link } from "react-router-dom";
import purple from "../image/purple.png";
import purple1 from "../image/purple1.png";

export default class Splash extends Component {
  constructor(props) {
    super(props);
    this.state = {
      splashId: this?.props?.match?.params?.splashId,
      networkId: this?.props?.match?.params?.networkId,
      android: "",
      iosId: "",
      // doctorId:this.props.match.params.doctorId
    };
  }
  componentDidMount() {
    if (isAndroid === true) {
      $("#androidSpan").show();
    }
    if (isIOS === true) {
      $("#iosSpan").show();
    }
    //alert(this.props.match.params.splashId)
    // alert(this.props.match.params.networkId)
    let splashId = this?.props?.match?.params?.splashId;
    splashId = splashId.split("~");
    // alert(splashId[0]);
    // alert(splashId[1])
    this.setState({ android: splashId[0], iosId: splashId[1] });
  }
  notNow() {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "networkRequestDeclined",
        networkId: this.state.networkId,
      }),
    };
    apiCalling(apiJson).then((data) => {});
  }

  render() {
    var settings = {
      dots: true,
      infinite: false,
      autoplay: true,
      arrows: false,
      autoplaySpeed: 13000,
      pauseOnHover: false,
    };
    return (
      <div className="pharmacyapp-wrapper p-0">
        <Slider {...settings}>
          {/* <div className="splash-banner splash-banner1">
              <div className="sp-banner-img-div">
              <img src="https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/phImageFolder_v1/pharmacyApp/splash-1.png" alt="banner" className="img-fluid sp-banner-img"/>
              </div>
                 <div className="sp-banner-content-head">
                      <div className="pharmacyapp-splashhead pharmacyapp-splashheadMarginb">Join Today</div>
                      <div className="pharmacyapp-splashhead pharmacyapp-splashheadMarginb pharmacyapp-splashhead-p">It is simple and easy to do! </div>
                 </div>
              <ul className="sp-banner-content">
                
                  <li className="sp-banner-content-p">Create a network</li>
                  <li className="sp-banner-content-p">Refer patients and track referrals</li>
                  <li className="sp-banner-content-p">Connect with colleagues</li>
              </ul>
            </div> */}
          {/* <div className="splash-banner splash-banner2">
              <div className="sp-banner-img-div">
              <img src="https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/phImageFolder_v1/pharmacyApp/splash-2.png" alt="banner" className="img-fluid sp-banner-img"/>
              </div>
              <div className="sp-banner-content-head">
                      <div className="pharmacyapp-splashhead pharmacyapp-splashheadMarginb">Join Today</div>
                      <div className="pharmacyapp-splashhead pharmacyapp-splashheadMarginb pharmacyapp-splashhead-p">It is simple and easy to do! </div>
                 </div>
              <ul className="sp-banner-content">
                  <li className="sp-banner-content-p">Make your Practice Digital & Modern</li>
                  <li className="sp-banner-content-p">Use Telehealth to Connect with Patients</li>
                  <li className="sp-banner-content-p">Full-featured yet simple to use.</li>
              </ul>
            </div>
            <div className="splash-banner splash-banner3">
            <div className="sp-banner-img-div">
              <img src="https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/phImageFolder_v1/pharmacyApp/splash-3.png" alt="banner" className="img-fluid sp-banner-img"/>
              </div>
              <div className="sp-banner-content-head">
                      <div className="pharmacyapp-splashhead pharmacyapp-splashheadMarginb">Join Today</div>
                      <div className="pharmacyapp-splashhead pharmacyapp-splashheadMarginb pharmacyapp-splashhead-p">It is simple and easy to do! </div>
                 </div>
              <ul className="sp-banner-content">
                  <li className="sp-banner-content-p">Supercharge your practice</li>
                  <li className="sp-banner-content-p">Take your practice with you anywhere</li>
                  <li className="sp-banner-content-p">Join the app for free</li>
              </ul>
            </div>
            <div className="splash-banner splash-banner4">
            <div className="sp-banner-img-div">
              <img src="https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/phImageFolder_v1/pharmacyApp/splash-4.png" alt="banner" className="img-fluid sp-banner-img"/>
              </div>
              <div className="sp-banner-content mt-2">
                  <div className="pharmacyapp-splashhead">Sign Up for Telehealth </div>
                  <button className="pharmacyapp-splash-freebtn">It's FREE</button>
                  <ul className="sp-banner-content mt-2 ml-5">
                    <li className="sp-banner-content-p mb-2">Attract new Patients</li>
                    <li className="sp-banner-content-p mb-2">Set your own fees</li>
                    <li className="sp-banner-content-p mb-2">Simple & Convenient!</li>
                  </ul>
                  <div className="pharmacy-splash-btnmain">
                  <span id="androidSpan" style={{display:"none",color:"#fff"}} onClick={()=> window.open("https://purplehealth.page.link/"+this.state.android,"_self")}>
                     <a className="pharmacy-splash-button pharmacy-splash-button-r"                      
                     //href={"https://purplehealth.page.link/"+this.state.android}                   
                     >I am Interested</a> 
                     </span>
                 
  
                    <span id="iosSpan" className="iosSpan" style={{display:"none",color:"#fff"}} onClick={()=> window.open("https://purplehealth.page.link/"+this.state.iosId,"_self")}>
                    <a className="pharmacy-splash-button pharmacy-splash-button-r" 
                    //href={"https://purplehealth.page.link/"+this.state.iosId}
                    >I am Interested</a> 
                  </span>
                  <div className="sp-notNow-div">
                  <a className="sp-notNow-text mr-2"
                                onClick={() =>this.notNow()}> Not Now</a>
                  </div>

                    
                  </div>
              </div>
            </div> */}
          <div className="splash-banner splash-banner5">
            {/* <div className="pharmacyapp-splashhead text-dark">
              Dr. (Name) would like to connect with you!
            </div> */}
            <div className="sp-banner-img-div">
              <img
                src="https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/phImageFolder_v1/pharmacyApp/banner.png"
                alt="banner"
                className="img-fluid sp-banner-img"
                height="150"
              />
            </div>
            <div className="sp-banner-content">
              <div className="pharmacyapp-splashhead">
               You have a networking request!
              </div>
              <p className="sp-banner-p">Download the PurpleHealth Practitioner App and you can
                <span className="sp-banner-p-color"> send and receive patient referrals</span> within your network!
              </p>
              <span className="feature-apphead">This App also has these great features :</span>
              <ul className="sp-banner-content">
                <li className="sp-banner-content-p imagesplash image1splash">
                  Refer Patients to doctors and clinics!
                </li>               
                <li className="sp-banner-content-p imagesplash image2splash">
                  Maintain patient health records
                </li>
                <li className="sp-banner-content-p imagesplash image3splash">
                  Manage your appointment calendar
                </li>
                <li className="sp-banner-content-p imagesplash image4splash">
                  Do Telehealth consultations
                </li>
                <li className="sp-banner-content-p imagesplash image5splash">
                  Manage Prescriptions
                </li>
               
              </ul>
              <span className="best_of">
                  Best of all, this app is <span className="splashBlue">FREE!</span>
                </span>
              <div className="pharmacy-splash-btnmain">
                {/* <div className="sp-notNow-div">
                  <a className="sp-accept mr-2 "
                                >Accept</a>
                  </div> */}
                <div
                  id="androidSpan"
                  className="sp-notNow-div"
                  style={{ display: "none" }}
                  onClick={() =>
                    window.open(
                      "https://purplehealth.page.link/" + this.state.android,
                      "_self"
                    )
                  }
                >
                  <a
                    className="sp-accept mr-2 "
                    //href={"https://purplehealth.page.link/"+this.state.android}
                  >
                    Accept
                  </a>
                </div>

                <div
                  id="iosSpan"
                  className="sp-notNow-div"
                  style={{ display: "none" }}
                  onClick={() =>
                    window.open(
                      "https://purplehealth.page.link/" + this.state.iosId,
                      "_self"
                    )
                  }
                >
                  <a
                    className="sp-accept mr-2 "
                    //href={"https://purplehealth.page.link/"+this.state.iosId}
                  >
                    Accept
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    );
  }
}
