import React, { Component, Suspense } from "react";
import { apiCalling } from "../../apiService";
import $ from "jquery";
import Header from "../../layout/header";
import Footer from "../../layout/footer";
var s3;
let fullFilePath;
const hospitalName=window.localStorage.getItem("hospitalName")?JSON.parse(window.localStorage.getItem("hospitalName")):""   
const hospitalId=window.localStorage.getItem("hospitalId")?JSON.parse(window.localStorage.getItem("hospitalId")):""
export default class HospitalRazrPay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hospitalId: "",
      hospitalName: "",
      hospitalPaymentKeyId: "",
      isActive: false,
      publicKey: "",
      secretKey: "",
    };
  }
  componentDidMount() {
    if (hospitalId) {
      // const { hospitalId, hospitalName } = this.props.location.state;
      this.setState({
        hospitalId: hospitalId,
        hospitalName: hospitalName,
      });
      this.getHospitalPaymentKey();
    }
  }
  getHospitalPaymentKey() {
    const getHospitalPaymentKey = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getHospitalPaymentKey",
        hospitalId:hospitalId,
      }),
    };
    apiCalling(getHospitalPaymentKey).then((data) => {
      if (data.success === "1") {
        this.setState(
          {
            hospitalPaymentKeyId:
              data.hospitalPaymentKeyData[0].hospitalPaymentKeyId,
            isActive: data.hospitalPaymentKeyData[0].isActive,
            publicKey: data.hospitalPaymentKeyData[0].publicKey,
            secretKey: data.hospitalPaymentKeyData[0].secretKey,
          },
          () => {
            if (this.state.isActive === true) {
              $("#isActive").prop("checked", true);
            }
          }
        );
      }
    });
  }
  saveHandle() {
    if (this.state.publicKey === "") {
      alert("Enter Public Key");
      setTimeout(() => {
        $("#publicKey").focus();
      }, 10);
      return false;
    } else if (this.state.secretKey === "") {
      alert("Enter Secret Key");
      setTimeout(() => {
        $("#secretKey").focus();
      }, 10);
      return false;
    }
    const saveHospitalPaymentKey = {
      method: "POST",
      body: JSON.stringify({
        functionName: "saveHospitalPaymentKey",
        hospitalId:hospitalId,
        publicKey: this.state.publicKey,
        secretKey: this.state.secretKey,
        hospitalPaymentKeyId:
          this.state.hospitalPaymentKeyId !== ""
            ? this.state.hospitalPaymentKeyId
            : "",
        isActive:
          this.state.hospitalPaymentKeyId === "" ? true : this.state.isActive,
      }),
    };
    apiCalling(saveHospitalPaymentKey).then((data) => {
      if (data.success === "1") {
        alert("Saved Successfully");
        window.location.href =
          "/adminManagement/hospitalManagementList/hospitalManagementList";
      } else alert("Something went wrong");
    });
  }
  changeHandler(e) {
    if (e.target.id === "isActive") {
      if ($("#isActive").prop("checked") === true) {
        this.setState({
          isActive: true,
        });
      } else {
        this.setState({ isActive: false });
      }
    } else {
      this.setState({
        [e.target.id]: e.target.value,
      });
    }
  }
  cancelHandle() {
    window.location.href =
      "/adminManagement/hospitalManagementList/hospitalManagementList";
  }
  render() {
    return (
      <div className="purpleWrap bg-clr-wthHead">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
            <div className="container mb-5">
              <div>
                <ul className="breadcrum-adminMangmnt">
                  <li>
                    <a href="/admin/dashboardAdmin">Dashboard</a>
                  </li>
                  <li>
                    <a href="/adminManagement/hospitalManagementList/hospitalManagementList">
                      Hospital Management
                    </a>
                  </li>
                  <li>
                    <a href="" className="current">
                      Add Images
                    </a>
                  </li>
                </ul>
              </div>
              <div className="smssetng-btn">
                <input
                  type="submit"
                  value="Save"
                  className="smssetng-svebtn"
                  onClick={() => this.saveHandle()}
                />
                <input
                  type="submit"
                  value="Cancel"
                  className="smssetng-cancelbtn"
                  onClick={() => this.cancelHandle()}
                />
              </div>
              <div className="ph-addimagewraper doctr-wraper-border-addimge">
                <div className="hspInformation-wraper ph-add-images">
                  <h2 className="hspInfoSubhead pb-4">
                    Hospital Razor Pay Details
                  </h2>
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="row">
                          <div className="form-group addimgfield-div">
                            <label>Hospital Id</label>
                            <input
                              type="text"
                              className="form-control"
                              readOnly
                              value={hospitalId}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="row">
                          <div className="form-group addimgfield-div">
                            <label for="" className="smalLbl">
                              Hospital Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              readOnly
                              defaultValue={
                                hospitalName
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="row">
                          <div className="form-group addimgfield-div">
                            <label>
                              Razor Pay Public Key
                              <span className="hspifoMantory">*</span>
                            </label>
                            <input
                              type="text"
                              id="publicKey"
                              className="form-control"
                              onChange={(e) => this.changeHandler(e)}
                              value={this.state.publicKey}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="row">
                          <div className="form-group addimgfield-div">
                            <label for="" className="smalLbl">
                              Razor Pay Secret Key
                              <span className="hspifoMantory">*</span>
                            </label>
                            <input
                              type="text"
                              id="secretKey"
                              className="form-control"
                              onChange={(e) => this.changeHandler(e)}
                              value={this.state.secretKey}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <label className="d-flex align-items-center mb-0">
                      Activate Payment
                      <input
                        type="checkbox"
                        name="isActive"
                        id="isActive"
                        className="mx-3"
                        onChange={(e) => this.changeHandler(e)}
                      />
                    </label>
                    <div className="smssetng-btn mt-3">
                      <input
                        type="submit"
                        value="Save"
                        className="smssetng-svebtn"
                        onClick={() => this.saveHandle()}
                      />
                      <input
                        type="submit"
                        value="Cancel"
                        className="smssetng-cancelbtn"
                        onClick={() => this.cancelHandle()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Suspense>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
