import React, { useState, useEffect, Suspense } from "react";
import $ from "jquery";
import { apiCalling } from "../../apiService";
import Edit from "../../image/icons/edit.png";
import tick from "../../image/icons/delete.png";
import Deactivate from "../../image/icons/deactvate.png";
import {useNavigate } from "react-router-dom";
import InnerHTML from "dangerously-set-html-content";
import Header from "../../layout/header";
import Footer from "../../layout/footer";
function Journalisting() {
  const navigate=useNavigate()
  const [journalData, setJournalData] = useState([]);
  const [showMessage, setShowMessage] = useState("Waiting for data...");
  useEffect(() => {
    journalListing();
  }, []);
  const journalListing = () => {
    const dataList = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getJournalsListing",
        siteId: "",
        journalId: "",
      }),
    };
    $(".loader").show();
    apiCalling(dataList).then((data) => {
      $(".loader").hide();
      if (data.success === "1" && data.result.length > 0) {
        setJournalData(data.result);
      } else if (data.success === "0") {
        setShowMessage("No Data Found");
      }
    });
  };
  const addNewJournal = () => {
    navigate("/adminManagement/journalDashboard/addJournal");
  };
  const statusChangeHandler = (id, Status) => {
    var x = false;
    if (Status === true) {
      x = window.confirm("Do you want to Delete this?");
    } else {
      x = window.confirm("Do you want to Delete this?");
    }
    if (x === true) {
      const SatusData = {
        method: "POST",
        body: JSON.stringify({
          functionName: "journalStatusChange",
          journalId: id,
          isActive: Status === true ? 0 : 1,
        }),
      };
      $(".loader").show();
      apiCalling(SatusData).then((data) => {
        if (data.success === "1") {
          $(".loader").hide();
          alert("Status Changed Successfully");
          journalListing();
        }
        if (data.success === "0") {
          $(".loader").hide();
          alert(data.errorMessage);
        }
      });
    }
  };
  const handleClickEdit = (data) =>{
    navigate('/adminManagement/journalDashboard/addJournal/', {
       state:data  
    });
  };
  return (
    <div className="purpleWrap">
      <Suspense fallback={<div>Waiting...</div>}>
        <section>
          <Header />
          <div className="loader"></div>
        </section>
      </Suspense>
      <div className="container mb-5 mt-4" id="feedList">
        <h3 className="text-left journlHead"> IDA Journal Listing </h3>
        <div>
          <ul className="breadcrum-adminMangmnt">
            <li>
              <a href={"/admin/dashboard"}>IDA Journal</a>
            </li>
            <li>
              <a href="/#" className="current">
                IDA Journal Listing
              </a>
            </li>
          </ul>
        </div>
        <div className="col-md-12 pl-0">
          <button
            className="addNew-btn-journel addNew-btn-journel-border"
            onClick={addNewJournal}
          >
            Add New Journal
          </button>
        </div>
        {journalData.length > 0 ? (
          <div className="table-responsive table-bordered-hsp">
            <table className="table mb-0">
              <thead>
                <tr>
                  <th className="col-sm-1">Journal ID</th>
                  <th className="col-sm-5">Journal Title</th>
                  <th className="col-sm-5">Journal Description</th>
                  <th className="col-sm-1">Actions</th>
                </tr>
              </thead>
              <tbody>
                {journalData.map((data) => (
                  <tr key={data.journalId}>
                    <td>{data.journalId}</td>
                    <td
                      style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        width: "10vw",
                      }}
                    >
                      {data.journalTitle}
                    </td>
                    <td className="journl-des-txt">
                      <InnerHTML
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          width: "10vw",
                        }}
                        html={data.description}
                      />
                    </td>
                    <td>
                      <div className="row">
                        <div className="col-sm-6">
                          <form>
                            {data.isActive === true ? (
                              <img
                                src={tick}
                                width="22"
                                title="Click to Delete"
                                className="mr-2 conf-cursorpointer"
                                alt=""
                                onClick={() => {
                                  statusChangeHandler(data.journalId, true);
                                }}
                              />
                            ) : (
                              <img
                                src={tick}
                                alt=""
                                title="Click to Delete"
                                className="mr-2 conf-cursorpointer"
                                width="20"
                                onClick={() => {
                                  statusChangeHandler(data.journalId, false);
                                }}
                              />
                            )}
                          </form>
                        </div>
                        <div className="col-sm-6">
                          {/* <Link
                            className="whitespace-nowrap"
                            to={`/adminManagement/journalDashboard/addJournal/${data.journalId}`}
                          > */}
                            <img
                                onClick={()=>handleClickEdit(data.journalId)}
                              src={Edit}
                              className="img-fluid"
                              width="20px"
                            />
                          {/* </Link> */}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p className="error-msg text-center mb-0"> {showMessage} </p>
        )}
      </div>
      <Suspense fallback={<div></div>}>
        <section>
          <Footer />
        </section>
      </Suspense>
    </div>
  );
}
export default Journalisting;
