import React, { Component, Suspense } from "react";
import Closebtn from "../../image/icons/close-sm.png";
import AddImg from "../../image/icons/addbtn.png";
import $ from "jquery";

export default class VisitingTime extends Component {
  // componentDidMount() {
  //   if (this.props.hospitalVisitingTimeEdit.length > 0) {
  //     this.setState({
  //       visitingDetailsArray: this.props.hospitalVisitingTimeEdit,
  //     });
  //   }
  // }

  addVisitingTime() {
    let a = {
      visitingTimeFrom: $("#visitingTimeFrom").val(),
      visitingTimeTo: $("#visitingTimeTo").val(),
    };

    var showalertforempty =(a.visitingTimeFrom ==='From' || a.visitingTimeFrom === null) ||(a.visitingTimeTo==="To" || a.visitingTimeTo===null)

    if(showalertforempty){

      alert("Visiting Time Required")

      return ;
    }

    let _hospitalVisitingTimeEdit = this.props.hospitalVisitingTimeEdit;

    _hospitalVisitingTimeEdit.push(a);
    this.props.setHospitalVisitingTimeEdit(_hospitalVisitingTimeEdit)

    $("#visitingTimeFrom").val("0");

    $("#visitingTimeTo").val("0");
  }

  removeVisitingTime(visitingTimeTo) {
    if (this.props.hospitalVisitingTimeEdit.length > 0) {
      var data = $.grep(this.props.hospitalVisitingTimeEdit, function (e) {
        return e.visitingTimeTo !== visitingTimeTo;
      });
    
      this.props.setHospitalVisitingTimeEdit(data)
    }
  }

  // visitingArrayDetails() {
  //   debugger;
  //   var d = {
  //     visitingArray: this.state.visitingDetailsArray,
  //   };

  //   return d;
  // }



  setVisitingData(value, name, i, mode) {
    if (mode === "visitingTimeFrom") {
      this.props.hospitalVisitingTimeEdit[i].visitingTimeFrom = $(
        "#" + mode + i
      ).val();
    } else if (mode === "visitingTimeTo") {
      this.props.hospitalVisitingTimeEdit[i].visitingTimeTo = $(
        "#" + mode + i
      ).val();
    }

    // this.setState({
    //   [name]: value,
    //   visitingDetailsArray: this.state.visitingDetailsArray,
    // });
  }

  render() {
    return (
      <div className="purpleWrap bg-clr-wthHead mb-4">
        <Suspense fallback={<div></div>}>
          <section>
            <div className="consultngTime-wrapper">
              <h2 className="hspInfoSubhead">Visiting Time</h2>

              <div className="hsptaltimeHead">
                <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                  <label className="tmelabel-hspadmin">Timing From</label>

                  <select name="visitingTimeFrom" id="visitingTimeFrom">
                    <option selected="From">From</option>

                    <option value="01:00 AM">01:00 AM</option>

                    <option value="01:30 AM">01:30 AM</option>

                    <option value="02:00 AM">02:00 AM</option>

                    <option value="02:30 AM">02:30 AM</option>

                    <option value="03:00 AM">03:00 AM</option>

                    <option value="03:30 AM">03:30 AM</option>

                    <option value="04:00 AM">04:00 AM</option>

                    <option value="04:30 AM">04:30 AM</option>

                    <option value="05:00 AM">05:00 AM</option>

                    <option value="05:30 AM">05:30 AM</option>

                    <option value="06:00 AM">06:00 AM</option>

                    <option value="06:30 AM">06:30 AM</option>

                    <option value="07:00 AM">07:00 AM</option>

                    <option value="07:30 AM">07:30 AM</option>

                    <option value="08:00 AM">08:00 AM</option>

                    <option value="08:30 AM">08:30 AM</option>

                    <option value="09:00 AM">09:00 AM</option>

                    <option value="09:30 AM">09:30 AM</option>

                    <option value="10:00 AM">10:00 AM</option>

                    <option value="10:30 AM">10:30 AM</option>

                    <option value="11:00 AM">11:00 AM</option>

                    <option value="11:30 AM">11:30 AM</option>

                    <option value="12:00 AM">12:00 AM</option>

                    <option value="12:30 AM">12:30 AM</option>

                    <option value="01:00 PM">01:00 PM</option>

                    <option value="01:30 PM">01:30 PM</option>

                    <option value="02:00 PM">02:00 PM</option>

                    <option value="02:30 PM">02:30 PM</option>

                    <option value="03:00 PM">03:00 PM</option>

                    <option value="03:30 PM">03:30 PM</option>

                    <option value="04:00 PM">04:00 PM</option>

                    <option value="04:30 PM">04:30 PM</option>

                    <option value="05:00 PM">05:00 PM</option>

                    <option value="05:30 PM">05:30 PM</option>

                    <option value="06:00 PM">06:00 PM</option>

                    <option value="06:30 PM">06:30 PM</option>

                    <option value="07:00 PM">07:00 PM</option>

                    <option value="07:30 PM">07:30 PM</option>

                    <option value="08:00 PM">08:00 PM</option>

                    <option value="08:30 PM">08:30 PM</option>

                    <option value="09:00 PM">09:00 PM</option>

                    <option value="09:30 PM">09:30 PM</option>

                    <option value="10:00 PM">10:00 PM</option>

                    <option value="10:30 PM">10:30 PM</option>

                    <option value="11:00 PM">11:00 PM</option>

                    <option value="11:30 PM">11:30 PM</option>

                    <option value="12:00 PM">12:00 PM</option>

                    <option value="12:30 PM">12:30 PM</option>

                    <option value="12:00 AM">12:00 AM </option>
                  </select>
                </div>

                <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                  <label className="tmelabel-hspadmin-to">Timing To</label>

                  <select name="visitingTimeTo" id="visitingTimeTo">
                    <option selected="From">To</option>

                    <option value="01:00 AM">01:00 AM</option>

                    <option value="01:30 AM">01:30 AM</option>

                    <option value="02:00 AM">02:00 AM</option>

                    <option value="02:30 AM">02:30 AM</option>

                    <option value="03:00 AM">03:00 AM</option>

                    <option value="03:30 AM">03:30 AM</option>

                    <option value="04:00 AM">04:00 AM</option>

                    <option value="04:30 AM">04:30 AM</option>

                    <option value="05:00 AM">05:00 AM</option>

                    <option value="05:30 AM">05:30 AM</option>

                    <option value="06:00 AM">06:00 AM</option>

                    <option value="06:30 AM">06:30 AM</option>

                    <option value="07:00 AM">07:00 AM</option>

                    <option value="07:30 AM">07:30 AM</option>

                    <option value="08:00 AM">08:00 AM</option>

                    <option value="08:30 AM">08:30 AM</option>

                    <option value="09:00 AM">09:00 AM</option>

                    <option value="09:30 AM">09:30 AM</option>

                    <option value="10:00 AM">10:00 AM</option>

                    <option value="10:30 AM">10:30 AM</option>

                    <option value="11:00 AM">11:00 AM</option>

                    <option value="11:30 AM">11:30 AM</option>

                    <option value="12:00 AM">12:00 AM</option>

                    <option value="12:30 AM">12:30 AM</option>

                    <option value="01:00 PM">01:00 PM</option>

                    <option value="01:30 PM">01:30 PM</option>

                    <option value="02:00 PM">02:00 PM</option>

                    <option value="02:30 PM">02:30 PM</option>

                    <option value="03:00 PM">03:00 PM</option>

                    <option value="03:30 PM">03:30 PM</option>

                    <option value="04:00 PM">04:00 PM</option>

                    <option value="04:30 PM">04:30 PM</option>

                    <option value="05:00 PM">05:00 PM</option>

                    <option value="05:30 PM">05:30 PM</option>

                    <option value="06:00 PM">06:00 PM</option>

                    <option value="06:30 PM">06:30 PM</option>

                    <option value="07:00 PM">07:00 PM</option>

                    <option value="07:30 PM">07:30 PM</option>

                    <option value="08:00 PM">08:00 PM</option>

                    <option value="08:30 PM">08:30 PM</option>

                    <option value="09:00 PM">09:00 PM</option>

                    <option value="09:30 PM">09:30 PM</option>

                    <option value="10:00 PM">10:00 PM</option>

                    <option value="10:30 PM">10:30 PM</option>

                    <option value="11:00 PM">11:00 PM</option>

                    <option value="11:30 PM">11:30 PM</option>

                    <option value="12:00 PM">12:00 PM</option>

                    <option value="12:30 PM">12:30 PM</option>

                    <option value="12:00 AM">12:00 AM </option>
                  </select>
                </div>

                <div className="addimgeDivHsp">
                  <img
                    src={AddImg}
                    alt="Add"
                    onClick={() => this.addVisitingTime()}
                  />
                </div>
              </div>

              {this.props.hospitalVisitingTimeEdit.map((Obj, i) => {
                return (
                  <div className="hsptaltimeHead">
                    <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                      <label className="educationdoctrlabelwidth tmelabel-hspadmin">
                        Timing From <span className="hspifoMantory"></span>
                      </label>

                      <select
                        name={"visitingTimeFrom" + i}
                        id={"visitingTimeFrom" + i}
                        value={Obj.visitingTimeFrom}
                        onChange={(e) =>
                          this.setVisitingData(
                            e,
                            "visitingTimeFrom" + i,
                            i,
                            "visitingTimeFrom"
                          )
                        }
                      >
                        <option selected="From">From</option>

                        <option value="01:00 AM">01:00 AM</option>

                        <option value="01:30 AM">01:30 AM</option>

                        <option value="02:00 AM">02:00 AM</option>

                        <option value="02:30 AM">02:30 AM</option>

                        <option value="03:00 AM">03:00 AM</option>

                        <option value="03:30 AM">03:30 AM</option>

                        <option value="04:00 AM">04:00 AM</option>

                        <option value="04:30 AM">04:30 AM</option>

                        <option value="05:00 AM">05:00 AM</option>

                        <option value="05:30 AM">05:30 AM</option>

                        <option value="06:00 AM">06:00 AM</option>

                        <option value="06:30 AM">06:30 AM</option>

                        <option value="07:00 AM">07:00 AM</option>

                        <option value="07:30 AM">07:30 AM</option>

                        <option value="08:00 AM">08:00 AM</option>

                        <option value="08:30 AM">08:30 AM</option>

                        <option value="09:00 AM">09:00 AM</option>

                        <option value="09:30 AM">09:30 AM</option>

                        <option value="10:00 AM">10:00 AM</option>

                        <option value="10:30 AM">10:30 AM</option>

                        <option value="11:00 AM">11:00 AM</option>

                        <option value="11:30 AM">11:30 AM</option>

                        <option value="12:00 AM">12:00 AM</option>

                        <option value="12:30 AM">12:30 AM</option>

                        <option value="01:00 PM">01:00 PM</option>

                        <option value="01:30 PM">01:30 PM</option>

                        <option value="02:00 PM">02:00 PM</option>

                        <option value="02:30 PM">02:30 PM</option>

                        <option value="03:00 PM">03:00 PM</option>

                        <option value="03:30 PM">03:30 PM</option>

                        <option value="04:00 PM">04:00 PM</option>

                        <option value="04:30 PM">04:30 PM</option>

                        <option value="05:00 PM">05:00 PM</option>

                        <option value="05:30 PM">05:30 PM</option>

                        <option value="06:00 PM">06:00 PM</option>

                        <option value="06:30 PM">06:30 PM</option>

                        <option value="07:00 PM">07:00 PM</option>

                        <option value="07:30 PM">07:30 PM</option>

                        <option value="08:00 PM">08:00 PM</option>

                        <option value="08:30 PM">08:30 PM</option>

                        <option value="09:00 PM">09:00 PM</option>

                        <option value="09:30 PM">09:30 PM</option>

                        <option value="10:00 PM">10:00 PM</option>

                        <option value="10:30 PM">10:30 PM</option>

                        <option value="11:00 PM">11:00 PM</option>

                        <option value="11:30 PM">11:30 PM</option>

                        <option value="12:00 PM">12:00 PM</option>

                        <option value="12:30 PM">12:30 PM</option>

                        <option value="12:00 AM">12:00 AM </option>
                      </select>
                    </div>

                    <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                      <label className="educationlabelwidthyear tmelabel-hspadmin-to">
                        Timing To
                      </label>

                      <select
                        name={"visitingTimeTo" + i}
                        id={"visitingTimeTo" + i}
                        value={Obj.visitingTimeTo}
                        onChange={(e) =>
                          this.setVisitingData(
                            e,
                            "visitingTimeTo" + i,
                            i,
                            "visitingTimeTo"
                          )
                        }
                      >
                        <option selected="From">To</option>

                        <option value="01:00 AM">01:00 AM</option>

                        <option value="01:30 AM">01:30 AM</option>

                        <option value="02:00 AM">02:00 AM</option>

                        <option value="02:30 AM">02:30 AM</option>

                        <option value="03:00 AM">03:00 AM</option>

                        <option value="03:30 AM">03:30 AM</option>

                        <option value="04:00 AM">04:00 AM</option>

                        <option value="04:30 AM">04:30 AM</option>

                        <option value="05:00 AM">05:00 AM</option>

                        <option value="05:30 AM">05:30 AM</option>

                        <option value="06:00 AM">06:00 AM</option>

                        <option value="06:30 AM">06:30 AM</option>

                        <option value="07:00 AM">07:00 AM</option>

                        <option value="07:30 AM">07:30 AM</option>

                        <option value="08:00 AM">08:00 AM</option>

                        <option value="08:30 AM">08:30 AM</option>

                        <option value="09:00 AM">09:00 AM</option>

                        <option value="09:30 AM">09:30 AM</option>

                        <option value="10:00 AM">10:00 AM</option>

                        <option value="10:30 AM">10:30 AM</option>

                        <option value="11:00 AM">11:00 AM</option>

                        <option value="11:30 AM">11:30 AM</option>

                        <option value="12:00 AM">12:00 AM</option>

                        <option value="12:30 AM">12:30 AM</option>

                        <option value="01:00 PM">01:00 PM</option>

                        <option value="01:30 PM">01:30 PM</option>

                        <option value="02:00 PM">02:00 PM</option>

                        <option value="02:30 PM">02:30 PM</option>

                        <option value="03:00 PM">03:00 PM</option>

                        <option value="03:30 PM">03:30 PM</option>

                        <option value="04:00 PM">04:00 PM</option>

                        <option value="04:30 PM">04:30 PM</option>

                        <option value="05:00 PM">05:00 PM</option>

                        <option value="05:30 PM">05:30 PM</option>

                        <option value="06:00 PM">06:00 PM</option>

                        <option value="06:30 PM">06:30 PM</option>

                        <option value="07:00 PM">07:00 PM</option>

                        <option value="07:30 PM">07:30 PM</option>

                        <option value="08:00 PM">08:00 PM</option>

                        <option value="08:30 PM">08:30 PM</option>

                        <option value="09:00 PM">09:00 PM</option>

                        <option value="09:30 PM">09:30 PM</option>

                        <option value="10:00 PM">10:00 PM</option>

                        <option value="10:30 PM">10:30 PM</option>

                        <option value="11:00 PM">11:00 PM</option>

                        <option value="11:30 PM">11:30 PM</option>

                        <option value="12:00 PM">12:00 PM</option>

                        <option value="12:30 PM">12:30 PM</option>

                        <option value="12:00 AM">12:00 AM </option>
                      </select>
                    </div>

                    <div className="addimgeDivHsp">
                      <img
                        src={Closebtn}
                        alt="Close"
                        onClick={() =>
                          this.removeVisitingTime(Obj.visitingTimeTo)
                        }
                        width="20"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </section>
        </Suspense>

        <Suspense fallback={<div></div>}></Suspense>
      </div>
    );
  }
}
