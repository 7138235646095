import React, { Component } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { apiCalling } from "../apiService";
export default class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      mobileNo: "",
      emailId: "",
    };

    const loginRequest = {
      method: "POST",
      body: JSON.stringify({
        functionName: "userDetails",
        siteId: localStorage.getItem("siteId"),
        userId: localStorage.getItem("userId"),
      }),
    };

    apiCalling(loginRequest).then((data) => {
      if (data.success == "1") {
        //alert(data.userData[0].lastName);
        this.setState({ firstName: data.userData[0].firstName });
        this.setState({ lastName: data.userData[0].lastName });
        this.setState({ mobileNo: data.userData[0].phoneNumber });
        this.setState({ emailId: data.userData[0].emailId });
      }
    });
  }
  render() {
    return (
      <div className="purpleWrap">
        <Header></Header>
        <div className="container container-auto">
          <div className="main-wrapper1">
            <div className="memberHead">
              <h4 className="">Member Profile</h4>
            </div>
            <div className="form-section border mt-2 form-section-padng">
              <h5 className="mb-4">Member Details</h5>
              <div className="row">
                <div className="col-sm-6">
                  <div className="row row-margin">
                    <label className="col-4 label-text">First Name</label>
                    <label className="col-8 ">{this.state.firstName}</label>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="row row-margin">
                    <label className="col-4 label-text">Last Name</label>
                    <label className="col-8 ">{this.state.lastName}</label>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="row row-margin">
                    <label className="col-4 label-text">Email ID </label>
                    <label className="col-8 ">{this.state.emailId}</label>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="row row-margin">
                    <label className="col-4 label-text">Mobile Number </label>
                    <label className="col-8 ">{this.state.mobileNo}</label>
                  </div>
                </div>
                <div className="col-sm-12 px-0">
                  <a href="../patient/profile-edit">
                    <input
                      type="submit"
                      value="Edit Profile"
                      className="formButton"
                    ></input>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer></Footer>
      </div>
    );
  }
}
