import React, { Component, Suspense } from "react";
import * as ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./relianceStyle.css";
import relianceLogo from "../image/reliance/relianceLogo.png";

export default class Header extends Component {
  logout = () => {
    window.localStorage.setItem("isLogin", false);
    window.location.href = "/reliance/login";
  };

  render() {
    return (
      <div>
        <div className="header-reliance">
          <div className=" container logo-reliance">
            <a href="#">
              <img src={relianceLogo} alt="" />
            </a>
          </div>
          <div className="menu-reliance">
            <div className="container">
              <ul className="menu-ul">
                {window.localStorage.getItem("isLogin") == "true" ? (
                  <li>
                    <a onClick={this.logout} style={{ color: "#fff" }}>
                      Logout
                    </a>
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
