import React, { Component, Suspense } from "react";

export default class LogoUpload extends Component {
  render() {
    return (
      <div className="purpleWrap bg-clr-wthHead mb-4 pb-5">
        <Suspense fallback={<div></div>}>
          <section>
            <div className="logouploadWrapperHspMangmnt">
              <div className="container mb-4 mt-4">
                <span className="seosectinTextHspMangemnt">
                  Please save hospital details before you upload the logo image
                </span>

                <div className="imgeUploadmtn-hspMangmntDiv mt-4">
                  <div className="hspinfo-btnupload">
                    <span>Upload</span>
                    <input type="file" className="hspinfoInputfieldupload" />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Suspense>
        <Suspense fallback={<div></div>}></Suspense>
      </div>
    );
  }
}
