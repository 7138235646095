import React, { Component } from 'react';
import Header from "../layout/header"
import Footer from "../layout/footer"
import Dashboardleftmenu from "./dashboardleftmenu"

export default class WorkTime extends Component {
 
  render() {
    return (
      <div className="purpleWrap">
        <Header></Header>
        <div className="container container-auto">
          <div className="main-wrapper1">
              <div className="row">
                <div className="col-lg-4">
                  <Dashboardleftmenu></Dashboardleftmenu>
                </div>
                <div className="col-lg-8">
                  <div className="memberHead">
                    <h4>Doctor Schedule Management</h4>
                  </div>
                  <div className="messageCenterDiv">
                    
                    <h5 className="py-4 text-muted">Hospital Name : Xilligence Clinic Test ortho </h5>

                    <div className="row row-margin">
                        <div className="col-7 text-center mb-3" style={{paddingLeft:"80px"}}>schedule 1</div>
                        <div className="col-5 text-center mb-3">schedule 2</div>
                        <br></br>
                        <label className="col-2 label-text text-right">Monday</label>
                        <div className="col-5 p-0">
                          <div className="col-lg-6 d-inline-block mb-2">
                            <select className="input-design input-designBg">
                              <option value="0">From</option>
                            </select>
                          </div>
                          <div className="col-lg-6 d-inline-block mb-2">
                            <select className="input-design input-designBg">
                              <option value="0">To</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-5 p-0">
                          <div className="col-lg-6 d-inline-block mb-2">
                            <select className="input-design input-designBg">
                              <option value="0">From</option>
                            </select>
                          </div>
                          <div className="col-lg-6 d-inline-block mb-2">
                            <select className="input-design input-designBg">
                              <option value="0">To</option>
                            </select>
                          </div>
                        </div>
                    </div>
                    
                    <div className="row row-margin">
                        <label className="col-2 label-text text-right">Tuesday</label>
                        <div className="col-5 p-0">
                          <div className="col-lg-6 d-inline-block mb-2">
                            <select className="input-design input-designBg">
                              <option value="0">From</option>
                            </select>
                          </div>
                          <div className="col-lg-6 d-inline-block mb-2">
                            <select className="input-design input-designBg">
                              <option value="0">To</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-5 p-0"> 
                        <div className="col-lg-6 d-inline-block mb-2">
                            <select className="input-design input-designBg">
                              <option value="0">From</option>
                            </select>
                          </div>
                          <div className="col-lg-6 d-inline-block mb-2">
                            <select className="input-design input-designBg">
                              <option value="0">To</option>
                            </select>
                          </div>
                        </div>
                    </div>
                    
                    <div className="row row-margin">
                        <label className="col-2 label-text text-right">Wednesday</label>
                        <div className="col-5 p-0">
                          <div className="col-lg-6 d-inline-block mb-2">
                            <select className="input-design input-designBg">
                              <option value="0">From</option>
                            </select>
                          </div>
                          <div className="col-lg-6 d-inline-block mb-2">
                            <select className="input-design input-designBg">
                              <option value="0">To</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-5 p-0">
                        <div className="col-lg-6 d-inline-block mb-2">
                            <select className="input-design input-designBg">
                              <option value="0">From</option>
                            </select>
                          </div>
                          <div className="col-lg-6 d-inline-block mb-2">
                            <select className="input-design input-designBg">
                              <option value="0">To</option>
                            </select>
                          </div>
                        </div>
                    </div>
                    
                    <div className="row row-margin">
                        <label className="col-2 label-text text-right">Thursday</label>
                        <div className="col-5 p-0">
                          <div className="col-lg-6 d-inline-block mb-2">
                            <select className="input-design input-designBg">
                              <option value="0">From</option>
                            </select>
                          </div>
                          <div className="col-lg-6 d-inline-block mb-2">
                            <select className="input-design input-designBg">
                              <option value="0">To</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-5 p-0">
                        <div className="col-lg-6 d-inline-block mb-2">
                            <select className="input-design input-designBg">
                              <option value="0">From</option>
                            </select>
                          </div>
                          <div className="col-lg-6 d-inline-block mb-2">
                            <select className="input-design input-designBg">
                              <option value="0">To</option>
                            </select>
                          </div>
                        </div>
                    </div>
                    
                    <div className="row row-margin">
                        <label className="col-2 label-text text-right">Friday</label>
                        <div className="col-5 p-0">
                          <div className="col-lg-6 d-inline-block mb-2">
                            <select className="input-design input-designBg">
                              <option value="0">From</option>
                            </select>
                          </div>
                          <div className="col-lg-6 d-inline-block mb-2">
                            <select className="input-design input-designBg">
                              <option value="0">To</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-5 p-0">
                        <div className="col-lg-6 d-inline-block mb-2">
                            <select className="input-design input-designBg">
                              <option value="0">From</option>
                            </select>
                          </div>
                          <div className="col-lg-6 d-inline-block mb-2">
                            <select className="input-design input-designBg">
                              <option value="0">To</option>
                            </select>
                          </div>
                        </div>
                    </div>
                    
                    <div className="row row-margin">
                        <label className="col-2 label-text text-right">Saturday</label>
                        <div className="col-5 p-0">
                          <div className="col-lg-6 d-inline-block mb-2">
                            <select className="input-design input-designBg">
                              <option value="0">From</option>
                            </select>
                          </div>
                          <div className="col-lg-6 d-inline-block mb-2">
                            <select className="input-design input-designBg">
                              <option value="0">To</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-5 p-0">
                        <div className="col-lg-6 d-inline-block mb-2">
                            <select className="input-design input-designBg">
                              <option value="0">From</option>
                            </select>
                          </div>
                          <div className="col-lg-6 d-inline-block mb-2">
                            <select className="input-design input-designBg">
                              <option value="0">To</option>
                            </select>
                          </div>
                        </div>
                    </div>
                    
                    <div className="row row-margin">
                        <label className="col-2 label-text text-right">Sunday</label>
                        <div className="col-5 p-0">
                          <div className="col-lg-6 d-inline-block mb-2">
                            <select className="input-design input-designBg">
                              <option value="0">From</option>
                            </select>
                          </div>
                          <div className="col-lg-6 d-inline-block mb-2">
                            <select className="input-design input-designBg">
                              <option value="0">To</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-5 p-0">
                        <div className="col-lg-6 d-inline-block mb-2">
                            <select className="input-design input-designBg">
                              <option value="0">From</option>
                            </select>
                          </div>
                          <div className="col-lg-6 d-inline-block mb-2">
                            <select className="input-design input-designBg">
                              <option value="0">To</option>
                            </select>
                          </div>
                        </div>
                    </div>  

                    <div className="col-sm-12 text-right px-0">
                      <a href="#">
                        <input type="submit" value="Update" className="formButton"></input>
                      </a>
                        <input type="button" value="cancel" className="formButton cancelButton"></input>
                    </div>

                  </div>
                </div>
              </div>
           </div>
        </div>

        <Footer></Footer>
      </div>
    )
  }
}
