import React, { useEffect } from 'react';
import Header from "../layout/header"
import Footer from "../layout/footer"
import AboutBanner from './about-banner';
import AboutWeAreHere from './about-we-are-here';
import AboutOurTeam from './about-ourteam';
import AboutTakeNextStep from './about-take-next-step';
function About() {
    useEffect(() => {
      document.getElementById("about-menu").classList.add("active");
    }, [])
    
    return (
        <div className='ph-main-wrapper'>
        <Header/>
        <div className='ph-about'>
           <AboutBanner/>
           <AboutWeAreHere/>
           <AboutOurTeam/>
           <AboutTakeNextStep/>
        </div>
        <Footer/>
        </div>     
    )
}
export default About;