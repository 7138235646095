import React, { Component } from 'react';
import Header from "../layout/header"
import Footer from "../layout/footer"

export default class EmailConfirm extends Component {

  render() {
    return (
      <div className="purpleWrap">
        <Header></Header>
        <div className="container">
          <div className="main-wrapper">
              <div className="col-sm-12 text-center">
                <h3 className="doctor-name">Ask the Doctor - Dr.  Video schedule doc MBBS</h3>
                <h4 className="my-4">Confirmation</h4>
              </div>
              <div className="form-section form-section-bgcolor">
                <div className="row row-margin">
                  <label className="col-sm-4 label-text label-text-right">Question</label>
                  <label className="col-sm-8 ">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</label>
                </div>

                <div className="row row-margin">
                  <label className="col-sm-4 label-text label-text-right">Amount(Rs)</label>
                  <label className="col-sm-8 ">Free</label>
                </div>
              </div>

              <div className="col-sm-12 text-center mt-4">
                <a href="../patient/emailsuccess">
                  <input type="submit" value="Send" className="formButton"></input>
                </a>
                <input type="button" value="Cancel" className="formButton cancelButton"></input>
              </div>
          </div>
        </div>

        <Footer></Footer>
      </div>
    )
  }
}
