import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import { EditorState, convertToRaw, ContentState, convertFromHTML } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"; // Ensure you import CSS

const SearchOffceService = React.forwardRef(({ doctorData }, ref) => {
  const [editorState1, setEditorState1] = useState(EditorState.createEmpty());
  const [editorState2, setEditorState2] = useState(EditorState.createEmpty());
  const [content1, setContent1] = useState("");
  const [content2, setContent2] = useState("");

  const txtAreaSearchKeywordRef = useRef(null);

  useEffect(() => {
    if (doctorData.length > 0) {
      // Updating the textarea
      $("#txtAreaSearchKeyword").val(doctorData[0].searchKeywords);

      // Updating the editors
      const officeDetailsContent = ContentState.createFromBlockArray(
        convertFromHTML(doctorData[0].officeDetails)
      );
      const serviceOfferedContent = ContentState.createFromBlockArray(
        convertFromHTML(doctorData[0].serviceOffered)
      );

      setEditorState1(EditorState.createWithContent(officeDetailsContent));
      setEditorState2(EditorState.createWithContent(serviceOfferedContent));

      setContent1(draftToHtml(convertToRaw(officeDetailsContent)));
      setContent2(draftToHtml(convertToRaw(serviceOfferedContent)));
    }
  }, [doctorData]);

  const onContentChange1 = (editorState) => {
    const content = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setContent1(content);
    setEditorState1(editorState);
  };

  const onContentChange2 = (editorState) => {
    const content = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setContent2(content);
    setEditorState2(editorState);
  };

  React.useImperativeHandle(ref, () => ({
    searchOfficeServices: () => ({
      content1,
      content2
    })
  }));

  return (
    <div className="bg-clr-wthHead mb-4 search-dctrdiv-clear">
      <section>
        <div className="consultngTime-wrapper">
          <div className="hsplistTextareaWrapper pb-4">
            <div className="mt-4">
              <label className="label-hspservice">Search Keyword</label>
              <div>
                <textarea
                  ref={txtAreaSearchKeywordRef}
                  name="txtAreaSearchKeyword"
                  id="txtAreaSearchKeyword"
                  cols="30"
                  rows="10"
                  placeholder="Search Keyword"
                  className="textarea-hspservice"
                ></textarea>
              </div>
            </div>
            <div className="mt-4">
              <label className="label-hspservice">Office Details</label>
              <Editor
                // ref={editor1Ref}
                editorState={editorState1}
                toolbarClassName="toolbarClassName"
                wrapperClassName="redactor-wrapper"
                editorClassName="editorClassName"
                onEditorStateChange={onContentChange1}
                handlePastedText={() => false}
              />
            </div>
            <div className="mt-4">
              <label className="label-hspservice">Service Offered</label>
              <Editor
                // ref={editor2Ref}
                editorState={editorState2}
                toolbarClassName="toolbarClassName"
                wrapperClassName="redactor-wrapper"
                editorClassName="editorClassName"
                onEditorStateChange={onContentChange2}
                handlePastedText={() => false}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
});

export default SearchOffceService;
