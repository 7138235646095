import React from 'react';
import { Link } from 'react-router-dom';
function AboutTakeNextStep() {
    return (
        <>
            <div className='ph-take-next'>
                <div className='container-lg'>
                <div className='ph-take-div'>
                    <div className='ph-take-bg-img ph-take-bg-img01'></div>
                    <div className='ph-take-bg-img ph-take-bg-img02'></div>
                    <div className='ph-take-bg-img ph-take-bg-img03'></div>
                    <div className='ph-take-bg-img ph-take-bg-img04'></div>
                    <div className='ph-take-bg-img ph-take-bg-img05'></div>
                    <div className='ph-take-bg-img ph-take-bg-img06'></div>
                    <div className='ph-take-bg-img ph-take-bg-img07'></div>
                    <div className='ph-take-bg-img ph-take-bg-img08'></div>
                    <h4 className='ph-take-title'>Take the Next Step Towards a Streamlined Practice</h4>
                    <h5 className='ph-take-subtitle'>Experience the Power of PurpleHealth Today</h5>
                    <Link to="/contactus" className='ph-text-decoration'><button type='button' className='ph-take-btn'>Request A Demo</button></Link>
                </div>
                </div>
            </div>
        </>     
    )
}
export default AboutTakeNextStep;