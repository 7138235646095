import React, { useState ,useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import Ser2 from "../image/theme/Case-Study-1.png";
import { Link } from "react-router-dom";
import Team01 from '../image/theme/news/bwdisrupt_S.png';
import Team03 from '../image/theme/news/Ehealth-S.png'; 
import Team02 from '../image/theme/news/YourStory_S.png';
import Team04 from '../image/theme/news/Inc 42_S.png';
import Team05 from '../image/theme/news/Mint_S.png';
import Team06 from '../image/theme/news/The-Financial-Express-S.png';
import Team07 from '../image/theme/news/tech stroy-S.png';
import Team08 from '../image/theme/news/the economic times-S.png';
import Team09 from '../image/theme/news/Business Standard_s.png';
import Team10 from '../image/theme/news/Business Line-S.png';
function NewsListBlocks(props) {
    // const navigate = useNavigate();

    const [teamsDetailArray1, setteamsDetailArray1] = useState([
        {
            id: "news1",
            desc: "<p className='card-text'>PurpleHealth.com, a 360-degree ‘Health On Demand’ platform, today announced the appointment of Nagarjun Mishra as the Co-founder and Chief Officer - Business & Strategy, to lead the sales operations and drive the business strategy for the company. An Emergency Physician by education, Nagarjun comes with a wealth of experience in the Healthcare and Wellness sector. Prior to joining PurpleHealth.com, Nagarjun was leading ....</p>" ,           
            name: "Ex-Reliance General Insurance- Head of Wellness joins PurpleHealth.com, a 360-degree ‘Health On Demand’ Platform",
            image: Team01
        },
        {
            id: "news2",
            desc: "<p className='card-text'>While the first week of June seemed rather mellow in terms of executive movements, the second week was anything but mellow, with several shifts in the movers and shakers of the ecosystem. With global companies announcing some power-packed appointments in the subcontinent, Indian e-commerce players weren’t really far behind, silently restructuring senior positions in a bid to increase profits and take on competition...</p>",
            name: "Facebook has a new India MD, Snapdeal doesn’t want a CPO and Flipkart has a new technology team - HR shuffles this week",
            image: Team02
        },
        {
            id: "news3",
            desc: "<p className='card-text'>PurpleHealth.com, Indias leading digital health platform offering 360-degree access to doctors and healthcare providers, on June 8 announced the appointment of Dr Nagarjun Mishra as the Co-Founder and Chief Officer – Business & Strategy, to lead the sales operations and drive the business strategy.</p>",
            name: "PurpleHealth.com appoints Dr Nagarjun Mishra Aims to innovate product roadmap & empower consumers via cost-effective, technology-driven solutions",
            image: Team03
        },
        {
            id: "news4",
            desc: "<p className='card-text'>Flipkart has once again changed its technology leadership team. The company has moved engineering head Peeyush Ranjan to the role of group chief technology officer. Ranjan will now look at areas such as mobile technologies and machine learning at Flipkart and head technology strategy. </p>",
            name: "Movers & Shakers Of The week [6 June – 11 June]",
            image: Team04
        },
        {
            id: "news5",
            desc: "<p className='card-text'>PurpleHealth.com, a digital health and wellness platform, on Wednesday said it has raised $100,000 from technology investor, Katabole Technology Venture.Founded in 2014, PurpleHealth.com delivers a diverse spectrum of solutions, services and products to consumers, corporates, healthcare providers and institutions.</p>",
            name: "PurpleHealth raises $100,000 from Katabole Technology Venture",
            image: Team05
        },
        {
            id: "news6",
            desc: "<p className='card-text'>PurpleHealth.com has raised $100,000 from investor, Katabole Technology Venture. PurpleHealth.com is a health and wellness platform that provides an interface to choose medical practitioners, schedule appointments, set up video and audio chats with doctors etc.Reportedly, the platform currently has over 38,000 doctors and 4000 hospitals as part of its network.</p>",
            name: "PurpleHealth.com raises $100,000 from Katabole Technology Venture",
            image: Team06
        },
        {
            id: "news7",
            desc: "<p className='card-text'>Trivandrum based “Health On Demand” platform PurpleHealth.com raised $100K (Rs. 67 lakhs approx.) from its investor, Katabole Technology Venture.The company will use this capital for their product roadmap and to strengthen their team.According to sources and reports by ET, the co-founder and CEO of PurpleHealth.com, Vikram Nair said, “The Indian healthcare sector is expected to register a compounded annual growth rate (CAGR) of 22.9% during 2015-20 and grow to USD 280 billion.</p>",
            name: "PurpleHealth Raises $100K From Its Investor – Katabole Technology Venture",
            image: Team07
        },
        {
            id: "news8",
            desc: "<p className='card-text'>PurpleHealth.com, a 360-degree 'Health On Demand' platform, on Wednesday said it has raised $100,000 from tech investor, Katabole Technology Venture. Founded in 2014, PurpleHealth.com is a 360-degree health and wellness platform that delivers a diverse spectrum of solutions, services and products to consumers, corporates, healthcare providers and institutions.</p>",
            name: "PurpleHealth.com raises $100,000 from Katabole Technology Venture",
            image: Team08
        },
        {
            id: "news9",
            desc: "<p className='card-text'>PurpleHealth.com, a health-on-demand platform, has raised $100,000 from tech investor Katabole Technology Venture. Founded in 2014, PurpleHealth.Com is a '360-degree' health and wellness platform that delivers diverse spectrumof solutions, services and products to consumers, corporates, healthcare providers and institutions. The platform empowers consumers to take active controlof their health by providing a one-stop seamless interface to choose the right medical practitioners, schedule appointments... ",
            name: "PurpleHealth raises $100,000 from Katabole Venture",
            image: Team09
        },
        {
            id: "news10",
            desc: "<p className='card-text'>PurpleHealth.com has raised $100,000 from investor, Katabole Technology Venture. PurpleHealth.com is a health and wellness platform that provides an interface to choose medical practitioners, schedule appointments, set up video and audio chats with doctors etc.Reportedly, the platform currently has over 38,000 doctors and 4000 hospitals as part of its network.</p>",
            name: "PurpleHealth.com raises $100,000 from Katabole Technology Venture",
            image: Team10
        },
        {
            id: "news11",
            desc: "<p className='card-text'>Thiruvananthapuram-based on-demand health platform PurpleHealth has raised $100,000 from tech investor Katabole Technology Venture. The fresh funds will help the startup build collaborative healthcare distribution models with a common customer pool, through integrated technology.</p>",
            name: "Thiruvananthapuram-based PurpleHealth raises $100k funding from Katabole Technology Venture",
            image: Team02
        },
        
        {
            id: "news12",
            desc: "<p className='card-text'>Kerala-based on demand health platform PurpleHealth.com has raised $100K from Katabole Technology Venture.Founded in 2014 by Vikram Nair, Mini Balaraman and Prakash Sathyapalan, PurpleHealth, provides diverse spectrum of solutions, services and products to consumers, corporates, healthcare providers, and institutions. </p>",
            name: "Healthcare Platform PurpleHealth Raises $100K From Katabole Technology Venture",
            image: Team04
        },
    ]);
    
  const handleItemClick = (itemId) => {
    //navigate(`/news-details`);
 
    window.location.href = `/news-details`;
    window.localStorage.setItem("newsIdNo",itemId)
    //console.log("new-list"+ window.localStorage.getItem("newsIdNo",itemId))
  };
  const [newsDisplayArray, setNewsDisplayArray] = useState([]);

   
    useEffect(() => {
        if (props.blogDetailFlag === true) {
            const updatedArray = teamsDetailArray1.filter(item => item.id !== props.newsId);
            const clickedNewsIndex = teamsDetailArray1.findIndex(item => item.id === props.newsId);
            const Total = teamsDetailArray1.length;
            const lastIndex = Total - 1;
            const numberOfArticlesInARow = 3;
            if (Total <= numberOfArticlesInARow) {
                // const articleList = updatedArray.splice(clickedNewsIndex,1)
                setNewsDisplayArray(updatedArray);
                return;
            }
            if (clickedNewsIndex >= lastIndex - 2) {
                if (clickedNewsIndex === lastIndex) {
                    const next3News1 = updatedArray.slice(clickedNewsIndex - 3, clickedNewsIndex);
                    setNewsDisplayArray(next3News1);
                }
                else if(clickedNewsIndex === lastIndex - 1) {
                    const next3News2 = updatedArray.slice(clickedNewsIndex, clickedNewsIndex + 1);
                    const next3News4 = updatedArray.slice(clickedNewsIndex - 2, clickedNewsIndex);
                    setNewsDisplayArray([...next3News2, ...next3News4]);               
                }
                else if(clickedNewsIndex === lastIndex - 2) {
                    const next2News2 = updatedArray.slice(clickedNewsIndex, clickedNewsIndex + 2);
                    const next1News4 = updatedArray.slice(clickedNewsIndex - 1, clickedNewsIndex);
                    setNewsDisplayArray([...next1News4, ...next2News2]);               
                }
                
            } else {
                const next3News = updatedArray.slice(clickedNewsIndex, clickedNewsIndex + 3);
                setNewsDisplayArray(next3News);
            }

        } else {
            setNewsDisplayArray(teamsDetailArray1);
        }
    }, [props.blogDetailFlag, props.newsId]);
      
      
    return (
        
          

                <div className='container-lg'>

                    <div className="row">
                
                    {newsDisplayArray.map((team, index) => (
                    <div key={index} className="col-lg-4 ph-card-success1 cursor-pointer" onClick={() => handleItemClick(team.id)} >
                        <div className="ph-service-img">
                            <img src={team.image} alt="image-news" className='img-fluid ph-image-news' />
                        </div>
                        <div className="card-body px-0">
                            <Link to={team.profileLink} className="ph-card-success-title">{team.name}</Link>
                            <div className="ph-points" dangerouslySetInnerHTML={{ __html: team.desc }}>
                            </div>                           
                            <div className='ph-bottom-btn'>
                                <div 
                                
                                className='ph-know-outcome'>
                                    Know the outcome
                                    <span className='ml-1'>
                                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                       
                    </div>
                </div>
    
    );
}

export default NewsListBlocks;
