import React, { Component, Suspense } from "react";
import Header from "../../layout/header";
import Footer from "../../layout/footer";

class healthInformation extends Component {
  state = {};
  render() {
    return (
      <div className="purpleWrap bg-clr-wthHead">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />

            <div className="container">
              <div>
                <ul className="breadcrum-adminMangmnt">
                  <li>
                    <a href="/admin/dashboard">Dashboard</a>
                  </li>
                  <li>
                    <a href="/adminManagement/healthInformation" className="current">
                    Health info Dashboard
                    </a>
                  </li>
                </ul>
              </div>
              <div className="head-hospMangmntList">Health info Dashboard</div>

              <div className="sectionItemDiv sectionItemDivBorder sectionBorderadminMangmnt mt-4">
                <div className="adminMangemnt-dashboard">
                  <div className="admin-dc-dashBtns">
                    <a href="/adminManagement/healthInfo">
                      <div>Health Info</div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Suspense>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}

export default healthInformation;
