import React, { Component } from 'react';
import Header from "../layout/header"
import Footer from "../layout/footer"
import leftarrow from "../image/media/left-arrow-hsp.png"

export default class DashboardAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
    ;
  }

  ChangeAdmin = (e) =>{
    window.location.href = "/adminManagement/dashboardAdmin"
  }

  render() {
    return (
    <div>
      <Header></Header> 
      <div className="container">
                <div>
                      <ul className="breadcrum-adminMangmnt">
                          <li><a href="/admin/dashboardAdmin">Home</a></li>
                          <li><a href="" className="current">DC Admin</a></li>
                      </ul>
                </div>
              <div className="sectionItemDiv-dashboard">
                  
                  <div className="adminMangemntdc-dashboard">
                     
                    
                     

                  </div>
              </div>
            </div>

        <Footer></Footer>
      </div>
    );
  }
}
