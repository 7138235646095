import React, { Component } from 'react';
import Ser2 from "../image/theme/Case-Study-1.png"
import Ser3 from "../image/theme/Case-Study-2.png"
import Ser4 from "../image/theme/Case-Study-3.png"
import Ser5 from "../image/theme/Case-Study-4.png"
import Rightarrow from "../image/theme/right-arrow.png"
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export default class digitalBrandingSuccesstories extends Component {

    render() {
        var settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                  breakpoint: 991,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                },
            ]
        };
        return (
            <div className="ph-main-outerdiv ph-space-service">
                <div className='container-lg'>
                    <div className="row">
                        <div className="col-lg-10">
                            <h2 className="ph-main-title-2">
                                Success Stories
                            </h2>
                        </div>
                        <div className="col-lg-2 d-none d-md-block">
                            <Link to="/resources" className='ph-explore-now mt-3'>
                                Explore More
                                <span>
                                    <img src={Rightarrow} alt="Rightarrow" className='img-fluid' width="20" height="20" />
                                </span>
                            </Link>
                        </div>
                    </div>

                    <Slider {...settings} className='row'>
                    <div>
                            <div className="col-lg-4 ph-card-success">

                                <div className="ph-service-img">
                                    <img src={Ser3} alt="Ser1" width="400" height="148" className='img-fluid' />
                                    {/* <div className="ph-success-left">
        Trending
    </div>
    <div className="ph-success-right">
        8pm - 12 Dec 2023
    </div> */}
                                </div>
                                <div class="card-body ph-card-body">
                                <Link to="/case-study-prans-patient" className="ph-card-success-title">IVF Clinic’s Patient Engagement Soars: Home Consults Increase, Outreach grows by 3x! </Link>
                                    <div className="ph-points">
                                        <p className='ph-card-points mb-0'>Client : </p>
                                        <p class="card-text ph-client">A Leading IVF hospital </p>
                                    </div>
                                    <div className="ph-points">
                                        <p className='ph-card-points mb-0'>The Challenge : </p>
                                        <p class="card-text ph-challenge">Pran Fertility and Well Woman Centre wanted a telemedicine solution to connect doctors to patients. They did not want to be a part of a third party aggregation </p>
                                    </div>
                                    <div className="ph-points">
                                        <p className='ph-card-points mb-0'>Solution  : </p>
                                        <p class="card-text">PurpleHealth built a comprehensive solution for Pran that offered their own branded mobile apps for their patients, which included a complete array of</p>
                                    </div>
                                    <div className='ph-bottom-btn'>
                                        <Link to="/case-study-prans-patient" className='ph-know-outcome'>
                                            Know the outcome
                                            <span className='ml-1'>
                                                <i class="fa fa-angle-right" aria-hidden="true"></i>
                                            </span>
                                        </Link>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div>
                            <div className="col-lg-4 ph-card-success">

                                <div className="ph-service-img">
                                    <img src={Ser2} alt="Ser1" width="400" height="148" className='img-fluid' />
                                    {/* <div className="ph-success-left">
        Trending
    </div>
    <div className="ph-success-right">
        8pm - 12 Dec 2023
    </div> */}
                                </div>
                                <div class="card-body ph-card-body">
                                <Link to="/case-study" className="ph-card-success-title">Thriving Growth, 100% Annual Usage Surge, Enhanced Customer Loyalty!
                             </Link>
                                    <div className="ph-points">
                                        <p className='ph-card-points mb-0'>Client : </p>
                                        <p class="card-text ph-client">A Large Insurance Company - Reliance General Insurance</p>
                                    </div>
                                    <div className="ph-points">
                                        <p className='ph-card-points mb-0'>The Challenge : </p>
                                        <p class="card-text ph-challenge">Reliance General Insurance (Reliance)one of the largest Indian health insurance companies needed a telemedicine platform to connect their corporate health insurance customers to empanelled doctors in their network. </p>
                                    </div>
                                    <div className="ph-points">
                                        <p className='ph-card-points mb-0'>Solution  : </p>
                                        <p class="card-text">PurpleHealth built a platform called RHealth Assist that connected insurance policy holders to a group of doctors across the country</p>
                                    </div>
                                    <div className='ph-bottom-btn'>
                                        <Link to="/case-study" className='ph-know-outcome'>
                                            Know the outcome
                                            <span className='ml-1'>
                                                <i class="fa fa-angle-right" aria-hidden="true"></i>
                                            </span>
                                        </Link>
                                    </div>
                                </div>

                            </div>
                        </div>
                       
                        <div className=''>
                            <div className="col-lg-4 ph-card-success">

                                <div className="ph-service-img">
                                    <img src={Ser4} alt="Ser1" width="400" height="148" className='img-fluid' />
                                    {/* <div className="ph-success-left">
        Trending
    </div>
    <div className="ph-success-right">
        8pm - 12 Dec 2023
    </div> */}
                                </div>
                                <div class="card-body ph-card-body">
                                 <Link to="/case-study-ida" className="ph-card-success-title">A State Dental Association: Showing leadership by providing Clinic Management Software for Members! </Link>
                                    <div className="ph-points">
                                        <p className='ph-card-points mb-0'>Client : </p>
                                        <p class="card-text ph-client">The Indian Dental Association - Kerala State</p>
                                    </div>
                                    <div className="ph-points">
                                        <p className='ph-card-points mb-0'>The Challenge : </p>
                                        <p class="card-text ph-challenge">The Indian Dental Association (Kerala State) had three requirements. First, they wanted a comprehensive solution to help their members go digital in terms of electronic health records</p>
                                    </div>
                                    <div className="ph-points">
                                        <p className='ph-card-points mb-0'>Solution  : </p>
                                        <p class="card-text">PurpleHealth built a complete solution that incorporated all three requirements. A dentist app was built for IDA Kerala members that let IDA Kerala</p>
                                    </div>
                                    <div className='ph-bottom-btn'>
                                        <Link to="/case-study-ida" className='ph-know-outcome'>
                                            Know the outcome
                                            <span className='ml-1'>
                                                <i class="fa fa-angle-right" aria-hidden="true"></i>
                                            </span>
                                        </Link>
                                    </div>
                                </div>

                            </div>
                        </div>
                       

                    </Slider>
                </div>
            </div>
        )
    }
}
