import React, { Component } from "react";
import Header from "./header";
import Footer from "./footer";
import $ from "jquery";
import { KJUR, b64utoutf8 } from 'jsrsasign';
import {apiUrlRelianceFunction } from "../apiService";
export default class RelianceIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminUserName: "",
      adminPassword: "",
      otp: "", 
      errorMessage: "",
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  componentDidMount() {
    //window.localStorage.setItem("isLogin", false);
  }
  

  generateToken = (payload) => {
    return new Promise((resolve, reject) => {
        try {
            const header = { alg: 'HS256', typ: 'JWT' };
            const sHeader = JSON.stringify(header);
            const sPayload = JSON.stringify(payload);
            const hmacKey = { utf8: process.env.REACT_APP_JWT_SECRET };
            const token = KJUR.jws.JWS.sign('HS256', sHeader, sPayload, hmacKey);
            resolve(token);
        } catch (error) {
            reject(error);
        }
    });
};
 
  loginHandler() {
    let adminUserName = this.state.adminUserName;
    let adminPassword = this.state.adminPassword;
    let flags = false;
    let msgError = "";
    if (!adminUserName) {
      msgError = "Please Enter The Email-Id";
      $("#adminUserName").focus();
    } else if (!adminPassword) {
      msgError = "Please Enter The Password";
      $("#adminPassword").focus();
    } else {
      flags = true;
    }
    if (flags == false) {
      $("#Errmessagebloc").css("color", "red");
      this.setState({ errorMessage: msgError });
    } else {
      this.generateToken({
        username: adminUserName,
        password: adminPassword,
      }).then(token => {
      // const token = jwt.sign(
      //   {
      //     username: adminUserName,
      //     password: adminPassword,
      //   },
      //   jwtSecret()
      // );
      const PasswordLoginRequest = {
        method: "POST",
        body: JSON.stringify({
          functionName: "login",
          token: token,
          siteId: "",
          otp: "",
        }),
      };
      apiUrlRelianceFunction(PasswordLoginRequest).then((data) => {
        //debugger;
        if (data.success == "1") {
          $("#Errmessagebloc").css("color", "Green");
          this.setState({ errorMessage: "Login Successfully" });
          window.localStorage.setItem("isLogin", true);

          // if (data.userData[0].role === "ROLE_ADMIN") {
          //     window.location.href = "/admin/dashboard";
          // }

          if (
            data.userData[0].role === "ROLE_ADMIN" ||
            data.userData[0].role === "ROLE_GROUPHOSPITALADMIN"
          ) {
            window.localStorage.setItem("userId", data.userData[0].userId)
            window.location.href = "/reliance/dashboard";
          }
        } else if (data.success === "0") {
          $("#Errmessagebloc").css("color", "red");
          this.setState({ errorMessage: data.errorMessage });
        }
      });
    }).catch(error => {
      console.error("Token Generation Error:", error);
  });
    }
  }

  render() {
    return (
      <div>
        <Header></Header>
        <div className="adminHspM-loginwrper">
          <div className="container adminHspM-loginOuter">
            <div className="adminHspM-loginDiv">
              <h2>Welcome!</h2>
              <p>Log in with your Email ID and password</p>
              <p class="error-msg px-3" id="Errmessagebloc">
                {this.state.errorMessage}
              </p>
            </div>
            <div>
              <div className="adminHspM-loginfield">
                <label htmlFor="">Email ID</label>
                <input
                  type="text"
                  name="adminUserName"
                  id="adminUserName"
                  placeholder="Email ID"
                  onChange={this.handleInputChange}
                />
              </div>
              <div className="adminHspM-loginfield">
                <label htmlFor="">Password</label>
                <input
                  type="password"
                  name="adminPassword"
                  id="adminPassword"
                  placeholder="Password"
                  onChange={this.handleInputChange}
                />
              </div>
              {/* <div className="adminHspM-loginforgot">
                                <a href="">Forgot Password?</a>
                            </div> */}
              <div className="adminHspM-loginbtndiv">
                <button
                  className="adminHspM-loginbtn"
                  onClick={() => this.loginHandler()}
                >
                  Sign In
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    );
  }
}
