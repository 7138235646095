import React, { Component } from "react";
import $ from "jquery";
import { apiCalling } from "../../../apiService";
import Close from "../../../image/icons/close-mark.png"
export default class SpecialisationPopup extends Component {
  state = {
    specializationMedicineBranchId: "",
    specialityName: "",
    medicineBranchId: "",
    isActive: "",
    description: "",
    type: "",
    searchKeywords: "",
    medicineBranchData: [],
  };
  componentDidMount() {
    setTimeout(() => {
      document.getElementsByName("specialityName")[0].focus();
    }, 20);
    this.getMedicineBranch();
    if (this.props.specializationMedicineBranchId > 0) {
      this.getSpecializationDetails();
    } else {
      this.setState({
        specialityName: "",
        medicineBranchId: "",
        isActive: "",
        description: "",
        type: "",
        searchKeywords: "",
      });
    }
  }
  handleSaveSpecialisation(data) {
    $(".loader").show();
    const addOrEditSpecialization = {
      method: "POST",
      body: JSON.stringify({
        functionName: "addOrEditSpecialization",
        specializationMedicineBranchId: data.specializationMedicineBranchId,
        specialityName: data.specialityName,
        medicineBranchId: data.medicineBranchId,
        isActive: data.isActive === ""?false:data.isActive,
        description: data.description,
        type: data.type,
        searchKeywords: data.searchKeywords,
      }),
    };
    apiCalling(addOrEditSpecialization).then((data) => {
      if (data.success === "0") {
        alert(data.errorMessage);
      }
      else{
        this.props.getSpecializationList()
        this.props.ShowSpecialisationPopup(false);
      }
      $(".loader").hide();
    });
  }
  handleSave = () => {
    if (this.state.specialityName == "") {
      setTimeout(() => {
        document.getElementsByName("specialityName")[0].focus();
      }, 20);
      alert("speciality required.");
    } else if (this.state.medicineBranchId == "") {
      alert("Medicine Branch required.");
    } else {
      this.handleSaveSpecialisation(this.state);
    }
  };
  getMedicineBranch() {
    $(".loader").show();
    const specializationList = {
      method: "POST",
      body: JSON.stringify({
        functionName: "medicineBranchDetails",
      }),
    };
    apiCalling(specializationList).then((data) => {
      if (data.success === "1") {
        this.setState({ medicineBranchData: data.medicineBranchData });
      } else if (data.success === "0") {
        this.setState({ medicineBranchData: [] });
      }
      $(".loader").hide();
    });
  }
  getSpecializationDetails() {
    $(".loader").show();
    const specializationList = {
      method: "POST",
      body: JSON.stringify({
        functionName: "specializationDetails",
        specializationMedicineBranchId:
          this.props.specializationMedicineBranchId,
      }),
    };
    apiCalling(specializationList).then((data) => {
      if (data.success === "1") {
        let spec = data.specializationData[0];
        this.setState({
          specializationMedicineBranchId: spec.specializationMedicineBranchId,
          specialityId: spec.specialityId,
          specialityName: spec.specialityName,
          searchKeywords: spec.searchKeywords,
          description: spec.description,
          type: spec.specializationType,
          isActive: spec.status,
          medicineBranchName: spec.medicineBranchName,
          medicineBranchId: spec.medicineBranchId,
        });
      }
      $(".loader").hide();
    });
  }
  changeHandler = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  cancelPopup() {
    this.props.ShowSpecialisationPopup(false);
  }
  handleMedicineBranchChange = () => {
    let medicineBranchIdVal =
      $("#medicineBranchId").val() !== "0" ? $("#medicineBranchId").val() : "";
    this.setState({ medicineBranchId: medicineBranchIdVal });
  };
  handleTypeChange = () => {
    let typeVal = $("#type").val() !== "0" ? $("#type").val() : "";
    this.setState({ type: typeVal });
  };
  isActiveChangeHandler = (e) => {
    let active = e.currentTarget.checked
    this.setState({ isActive: active});
  };  
  render() {
    return (
      <div className="popup-overlay" style={{ display: "block" }}>
        <div className="popup-contentDiv">
          <div className="modal-popup">
            <span
              className="closepopupqual" id="txtCancel"
              onClick={() => this.cancelPopup()}
            >
               <img src={Close} alt="Close" className="img-fluid" width="25" />
              {/* &times; */}
            </span>
            <div className="header border-0">Add specialisation </div>
            <div className="content">
              <div className="row">
                <div className="row-margin col-md-6">
                  <label className="col-md-12 label-text">
                    Speciality <span className="hspifoMantory">*</span>
                  </label>
                  <div className="col-md-12">
                    <input
                      type="text"
                      className="qualifcatin-popupInput specilistn-popupInput-mob"
                      id="specialityName"
                      name="specialityName"
                      value={this.state.specialityName}
                      onInput={this.changeHandler}
                    />
                  </div>
                </div>
                <div className="row-margin col-md-6">
                  <label className="col-md-12 label-text">Search Keywords</label>
                  <div className="col-md-12">
                    <textarea
                      className="language-popuptextarea specilistn-popupInput-mob"
                      id="searchKeywords"
                      name="searchKeywords"
                      value={this.state.searchKeywords}
                      onInput={this.changeHandler}
                    ></textarea>
                  </div>
                </div>
                <div className="row-margin col-md-12">
                  <label className="col-md-12 label-text">Description</label>
                  <div className="col-md-12">
                    <textarea
                      className="language-popuptextarea special-popuptextareawidth"
                      id="description"
                      name="description"
                      value={this.state.description}
                      onInput={this.changeHandler}
                    ></textarea>
                  </div>
                </div>
                <div className="row-margin col-md-6">
                  <label className="col-md-12 label-text">Medicine Branch<span className="hspifoMantory">*</span></label>
                  <div className="col-md-12">
                    <select
                      className="slecthsp qualifcatin-popupInput specilistn-popupInput-mob"
                      id="medicineBranchId"
                      value={this.state.medicineBranchId}
                      onChange={() => this.handleMedicineBranchChange()}
                    >
                      <option value="0">Search In</option>
                      {this.state.medicineBranchData.map((m) => (
                        <option
                          key={m.medicineBranchId}
                          value={m.medicineBranchId}
                        >
                          {m.medicineBranchName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row-margin col-md-6">
                  <label className="col-md-12 label-text">Type</label>
                  <div className="col-md-12">
                    <select
                      className="slecthsp qualifcatin-popupInput specilistn-popupInput-mob"
                      id="type"
                      value={this.state.type}
                      onChange={() => this.handleTypeChange()}
                    >
                      <option value="b">Both</option>
                      <option value="h">Hospital</option>
                      <option value="d">Doctor</option>
                    </select>
                  </div>
                </div>
                <div className="row-margin col-md-12">
                  <label className="col-md-2 label-text qualifcatin-popuplabel" for="isActive">
                    Is Active
                  </label>
                  <div className="">
                    <input
                      type="checkbox"
                      id="isActive"
                      name="isActive"
                      checked={this.state.isActive}
                      onChange={this.isActiveChangeHandler}
                      className="qual-checkbox"
                    ></input>
                  </div>
                </div>
              </div>
              <div className="text-center">
                <span className="sitesetng-svebtn" onClick={this.handleSave}>
                  Save
                </span>
                <input
                  type="button"
                  value="cancel"
                  className="sitesetng-cancelbtn"
                  onClick={() => this.cancelPopup()}
                ></input>
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    );
  }
}
