import React, { Component } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";

export default class TextchatSuccess extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sucessMessage: "",
      transactionId: "",
      paymentId: "",
      orderNo: "",
      paymentStatus: "",
      appointmentId: "",
      amount: "",
    };
    this.closeAppointment = this.closeAppointment.bind(this);
  }
  closeAppointment() {
    window.location.href = "../home/doctor-list-main";
  }

  componentDidMount() {
    let dataSet = JSON.parse(localStorage.getItem("appointmentSuccessArray"));
    if (dataSet.success == "1") {
      this.setState({
        sucessMessage: dataSet.successMessage,
        transactionId: dataSet.result[0].transactionId,
        paymentId: dataSet.result[0].paymentId,
        orderNo: dataSet.result[0].orderNo,
        paymentStatus: dataSet.result[0].paymentStatus,
        appointmentId: dataSet.result[0].appointmentId,
        amount: dataSet.result[0].amount,
      });
    }
  }
  render() {
    return (
      <div className="purpleWrap">
        <Header></Header>
        <div className="container">
          <div className="main-wrapper">
            <div className="col-sm-12 text-center">
              <h3 className="doctor-name">{this.state.sucessMessage}</h3>
              <h4 className="my-4">Transaction Summary</h4>
            </div>
            <div className="form-section form-section-bgcolor">
              <div className="row row-margin">
                <label className="col-sm-6 label-text label-text-right">
                  Payment ID{" "}
                </label>
                <label className="col-sm-6 ">{this.state.paymentId}</label>
              </div>

              <div className="row row-margin">
                <label className="col-sm-6 label-text label-text-right">
                  Transaction ID
                </label>
                <label className="col-sm-6 ">{this.state.transactionId}</label>
              </div>

              <div className="row row-margin">
                <label className="col-sm-6 label-text label-text-right">
                  Order No.
                </label>
                <label className="col-sm-6 ">{this.state.orderNo}</label>
              </div>

              <div className="row row-margin">
                <label className="col-sm-6 label-text label-text-right">
                  Amount(Rs)
                </label>
                <label className="col-sm-6 ">{this.state.amount}</label>
              </div>
            </div>

            <div className="col-sm-12 text-center mt-4">
              <a href="../chatmessage">
                <input
                  type="submit"
                  value="Close"
                  onClick={this.closeAppointment}
                  className="formButton"
                ></input>
              </a>
            </div>
          </div>
        </div>

        <Footer></Footer>
      </div>
    );
  }
}
