import React, { Component } from 'react';

export default class Footer extends Component{
    render(){
        return(
            <div className="footerWrapper">
                <div className="footerCopyRight">Copyright © Reliance {new Date().getFullYear()}. All Rights Reserved.</div>
            </div>
           
        )
    }
}