import React, { Component } from 'react';
import Header from "../layout/header"
import Footer from "../layout/footer"
import Dashboardleftmenu from "./dashboardleftmenu"
import $ from "jquery";
import avatar from "../image/media/avatar.png" 
import doctors from "../image/media/doctors.jpg"
import like from "../image/media/icon4.png"

export default class Dashboard extends Component {
 
  render() {
    return (
      <div className="purpleWrap">
        <Header></Header>
        <div className="container">
          <div className="main-wrapper1">
              <div className="row">
                <div className="col-lg-4">
                  <Dashboardleftmenu></Dashboardleftmenu>
                </div>
                <div className="col-lg-8">
  
                <div className="bulletinBoardHead" >
                  <div className="bltHeading">Bulletin Board</div>
                </div>
    
      <div className="container">      			
        <div className="row">        		
          <div className="col-md-12">
            <div className="blogVisitPage">
              <div className="row">              	
                
              </div>
              
              <div className="row">              	    
                <div className="" id="content">
                
                
                
                	
	                  	<div className="blogVisitBlock">
		                    <div className="blogVistAvtarBlock"><a href="#"><img className="avtarImg139496" src={avatar} /></a></div>
		                    <div className="blogVisitAvatarInfo"><span className="avtarname"><a href="#" style={{fontWeight: "700", color: "var(--common-color)"}}>Clinic Management</a></span>
		                    
		                    	
                      				<span title="Following" className="smlFollow">+  Following</span>
                      			
                      		
                      									
							<span className="datetime">06 Jul 2017</span></div>
									
								<div className="blogVisitbannerBlock">
									<img src={doctors} />
			          			</div>                
							   
		                    <div className="blogVisitHead"><a href="/healthInfo/healthInfoMore/healthInfo-161-WelcometoyourClinicManagementsolution%21?drug=1179">Welcome to your Clinic Management solution!</a></div>
		                    <div className="blogVisitPara"><a href="/healthInfo/healthInfoMore/healthInfo-161-WelcometoyourClinicManagementsolution%21?drug=1179" className="grey">Welcome and congratulations on signing up with us for the Clinic Management solution!

Read more..</a></div>		                   
		                    <div className="clearfix"></div>
		                    <div className="blogVisiIconsSet">
		                    	<span className="blogVisitLike">
		                        	<div className="iconTwo">
		                        		
		                        			
			                      				<a href="/healthInfo/healthInfoLike?fromPage=healthInfo&amp;healthInfoId=161&amp;drug=1179">
					                      			<img title="Like" src={like} />
					                      		</a>
			                      							                      		
			                      		
			                      		
		                        	</div>
		                        	<span className="nocursorPointer">19</span>
		                        </span>
		                        <span className="blogVisitFollow"></span>
		                    </div>
	                  	</div>
                  	
                </div>
                              
              </div>   
            </div>
                       
            <div className="clearfix"></div>
             <div className="" >
            	<div className="blogpagination">
            
           			<div className="doc pagination">
           				
           			</div>
				  </div> 
            </div>
             <div className="col-md-4 col-lg-4">
             </div>
            
          </div>         
        </div>
      </div>
    </div>
              </div>
           </div>
        </div>

        <Footer></Footer>
      </div>
    )
  }
}
