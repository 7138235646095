import React, { Component } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import AppointmentEditPopup from "../patient/appointment-edit-popup";
import $ from "jquery";
import { apiCalling, changeDateFormat } from "../apiService";
import moment from "moment";
export default class Appointmentlist extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hospitals: [],
      doctors: [],
      appoinments: [],
      appType: "In Person",
      hospitalName: "",
      doctorName: "",
      toDate: "",
      fromDate: "",
    };
    this.loadData = this.loadData.bind(this);
    this.cancelAppointment = this.cancelAppointment.bind(this);
    this.searchAction = this.searchAction.bind(this);
    this.resetAction = this.resetAction.bind(this);
    this.changeState = this.changeState.bind(this);
  }
  handleInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };
  changeState() {
    this.setState({ appType: "In Person" });
  }
  resetAction() {
    window.location.reload(false);
  }
  searchAction() {
    this.loadData();
  }
  cancelAppointment(id) {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "cancelAppoinment",
        userId: localStorage.getItem("userId"),
        appoinmentId: id,
      }),
    };
    $(".loader").show();
    apiCalling(apiJson).then((data) => {
      if (data.success == "1") {
        alert(data.successMessage);
        $(".loader").hide();
        this.loadData();
      }
    });
  }
  componentDidMount() {
    $("html").click(function () {
      $(".popup-overlay").hide();
    });

    $(".popup-content").click(function (event) {
      event.stopPropagation();
    });

    this.loadData();
  }

  appointmentEdit() {
    $("#appointment-edit-main").show();
  }

  loadData() {
    let todateT = moment(this.state.toDate);
    let fromdateT = moment(this.state.fromDate);
    let todatex = "";
    // let fromDatex = "";

    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "userMyAppointment",
        userId: localStorage.getItem("userId"),
        siteId: localStorage.getItem("siteId"),
        max: "0",
        offset: "10",
        appointmentType: this.state.appType,
        doctorId: this.state.doctorName,
        hospitalId: this.state.hospitalName,
        fromDate:
          fromdateT.format("DD/MM/YYYY") == "Invalid date"
            ? ""
            : fromdateT.format("DD/MM/YYYY"),
        toDate:
          todateT.format("DD/MM/YYYY") == "Invalid date"
            ? ""
            : todateT.format("DD/MM/YYYY"),
      }),
    };
    //alert(apiJson.body);
    $(".loader").show();
    apiCalling(apiJson).then((data) => {
      if (data.success == "1") {
        this.setState({
          hospitals: data.hospitals,
          doctors: data.doctors,
          appoinments: data.appoinments,
        });
        $(".loader").hide();
      }
    });
  }

  render() {
    return (
      <div className="purpleWrap">
        <Header></Header>
        <div className="container">
          <div className="main-wrapper1">
            <h4 className="mb-4"> Appointment(s) </h4>
            <div className="custom-search row">
              <div className="col-lg-3 col-md-4 mb-3">
                <select
                  className="input-design"
                  id="appType"
                  name="appType"
                  onChange={this.handleInputChange}
                >
                  <option value="In Person">In Person</option>
                  <option value="Video">Video</option>
                  <option value="Audio">Audio</option>
                </select>
              </div>
              <div className="col-lg-3 col-md-4 mb-3">
                <select
                  className="input-design"
                  id="hospitalName"
                  name="hospitalName"
                  onChange={this.handleInputChange}
                >
                  <option value="">Hospital Name</option>
                  {this.state.hospitals.map((obj) => {
                    return (
                      <option value={obj.hospitalId}>{obj.hospitalName}</option>
                    );
                  })}
                </select>
              </div>
              <div className="col-lg-3 col-md-4 mb-3">
                <select
                  className="input-design"
                  id="doctorName"
                  name="doctorName"
                  onChange={this.handleInputChange}
                >
                  <option value="">Doctor Name</option>
                  {this.state.doctors.map((obj) => {
                    return (
                      <option value={obj.doctorId}>{obj.doctorName}</option>
                    );
                  })}
                </select>
              </div>
              <div className="col-lg-3 col-md-4 mb-3">
                <input
                  type="date"
                  className="input-design"
                  id="fromDate"
                  name="fromDate"
                  onChange={this.handleInputChange}
                ></input>
              </div>
              <div className="col-lg-3 col-md-4 mb-3">
                <input
                  type="date"
                  className="input-design"
                  id="toDate"
                  name="toDate"
                  onChange={this.handleInputChange}
                ></input>
              </div>
              <div className="col-lg-3 col-md-4 mb-3">
                <button
                  className="btn btn-bg"
                  id="btnSearch"
                  onClick={this.searchAction}
                >
                  <span className=" fa fa-search"></span>
                </button>
                <button
                  className="btn btn-bg"
                  id="btnreset"
                  onClick={this.resetAction}
                >
                  <span className=" fa fa-undo"></span>
                </button>
              </div>
            </div>
            <div className="list-section">
              <div className="pendingLink">
                <a href="/patient/videocalllist">Pending Video Appointments</a>
              </div>
              {this.state.appoinments.map((obj) => {
                let appD = moment(obj.startTime);

                return (
                  <div className="practiceTimeline-panel practiceTimelineWrap">
                    <div className="practiceTimeSpecific">
                      <p className="timeSpecificBlock">
                        <span>{appD.format("DD")}</span>
                        <span className="timeSpecificSubBlock">
                          {appD.format("MMM")} {appD.format("YYYY")}
                        </span>
                      </p>
                    </div>

                    <div className="practiceTimelineDivision">
                      <div className="practiceTimeline-heading">
                        <h4 className="practiceTimeline-title practiceTitleTop">
                          <span className="elipsis">{obj.doctorName} </span>
                          <span className="span2 elipsis">{obj.hospitalName}</span>
                          <span className="span2 elipsis">
                            Transaction Id :{" "}
                            <font color="red">{obj.transactionId}</font>
                          </span>
                        </h4>

                        <div className="my-2">
                          <small className="color-maintext mrgRgtPrctmngt">
                            <i className="fa fa-clock-o"></i>
                            <span className="appoinmentTymBlock">
                              {" "}
                              {moment(appD).format("h:mm a")}
                            </span>
                            <i
                              className="fa fa-edit ml-2"
                              onClick={this.appointmentEdit}
                            ></i>
                            <AppointmentEditPopup></AppointmentEditPopup>
                          </small>
                          <small className="text-muted mrgRgtPrctmngt">
                            <i className="fa fa-mobile"></i> {obj.mobNumber}
                          </small>
                          <small className="text-muted mrgRgtPrctmngt">
                            {" "}
                            MR No : {obj.memberMrNo}{" "}
                          </small>
                        </div>
                      </div>
                      <div className="col-12 float-left p-0">
                        <span className="statusPrctmngt confirm">
                          Type : {obj.appointmentType}
                        </span>
                        <span className="statusPrctmngt confirm mrgRgtPrctmngt pt-0">
                          Status : {obj.status}
                        </span>
                      </div>
                      <div className="col-12 float-left p-0 text-right">
                        <input
                          type="button"
                          value="cancel"
                          className="formButton cancelButton"
                          onClick={() =>
                            this.cancelAppointment(obj.appointmentId)
                          }
                        ></input>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <Footer></Footer>
      </div>
    );
  }
}
