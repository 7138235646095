import React, { Component } from "react";
import { Link } from "react-router-dom";
import profile from "../image/theme/Screenshot-3.png";
import correct from "../image/theme/correct.png";
import phone from "../image/theme/phone-b.png";
import mail from "../image/theme/mail.png";
import video from "../image/theme/film.png";
import chat from "../image/theme/comment.png";
import booking from "../image/theme/booking.png";
import info from "../image/theme/info.png";
import close from "../image/theme/close.png";
import $ from "jquery";
import Bookappointment from "../patient/bookappointment";
import { apiCalling } from "../apiService";
export default class DoctorList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      callADoctor: "",
      bookApp: "",
      messaging: "",
      emailConsult: "",
      vcChat: "",
      drServiceList: [],
    };
  }
  addToFavourites(docId) {
    const jsonData = {
      method: "POST",
      body: JSON.stringify({
        functionName: "addToFavourites",
        siteId: localStorage.getItem("siteId"),
        userId: localStorage.getItem("userId"),
        favouriteUserId: docId,
      }),
    };
    apiCalling(jsonData).then((data) => {
      if (data.success == "1") {
        alert(data.successMessage);
      }
    });
  }
  profileAvailable(docId) {
    $("#drServiceMain" + docId).slideDown(400);
    $(".drServiceMain").hide();
    $("#drServiceMain" + docId).show();
  }
  profileAvailableCancl(docId) {
    $("#drServiceMain" + docId).slideUp(400);
  }
  componentDidMount() {
    let self = this;

    if (this.props.docData.services) {
      const obj = this.props.docData.services;

      if (obj.includes("Messaging")) {
        $("#chatDr" + this.props.consti).show();
      } else {
        $("#chatDr" + this.props.consti).hide();
      }

      if (obj.includes("Call a Doctor")) {
        $("#callDr" + this.props.consti).show();
      } else {
        $("#callDr" + this.props.consti).hide();
      }
      if (obj.includes("Video Chat")) {
        $("#vcDr" + this.props.consti).show();
      } else {
        $("#vcDr" + this.props.consti).hide();
      }
      if (obj.includes("Email Consult")) {
        $("#emailDr" + this.props.consti).show();
      } else {
        $("#emailDr" + this.props.consti).hide();
      }
      if (obj.includes("Book Appointment")) {
        $("#bookDr" + this.props.consti).show();
      } else {
        $("#bookDr" + this.props.consti).hide();
      }
    }
    if (this.props.docData.isLogged == "1") {
      $("#isLogged" + this.props.docData.doctorId).show();
    } else {
      $("#isLogged" + this.props.docData.doctorId).hide();
    }
  }
  render() {
    return (
      <div className="doctorDetailProfile doctorListProfile">
        <label className="drListHeart imgshowhide">
          <input type="checkbox" />
          <span
            className="checkmark"
            onClick={() => this.addToFavourites(this.props.docData.doctorId)}
          ></span>
        </label>
        <div className="doctorDetailProfileImg">
          <img src={this.props.docData.image} width="100"></img>
        </div>
        <h5 className="drName mb-3">{this.props.docData.displayName}</h5>
        <span className="drspecialist">{this.props.docData.specialization}</span>
        <p className="p-18 my-3">{this.props.docData.address}</p>

        <span
          id={"isLogged" + this.props.consti}
          className="profileAvailable"
          onClick={() => this.profileAvailable(this.props.consti)}
        >
          <img src={correct} width="17"></img>
          Available
        </span>
        <div className="drServiceMain" id={"drServiceMain" + this.props.consti}>
          <span
            className="closeMainDiv"
            onClick={() => this.profileAvailableCancl(this.props.consti)}
          >
            <img src={close} width="18"></img>
          </span>
          <br></br>

          <span className="drServiceSp" id={"chatDr" + this.props.consti}>
            {this.props.docData.textChatPrice != "0.00" ?
            <Link
              to={{
                pathname: "../patient/textchatpayment",
                doctorId: this.props.docData.doctorId,
                hospitalId: this.props.docData.hospitalId,
                chatDoctorPanelId:this.props.docData.chatDoctorPanelId,
                textChatPrice:this.props.docData.textChatPrice
              }}
              className="text-decoration-none"
            >
              <img src={chat}></img> Chat(MSG)
            </Link>
            :<Link
            to={{
              pathname: "../chatmessage",
              doctorId: this.props.docData.doctorId,
              hospitalId: this.props.docData.hospitalId,
              chatDoctorPanelId:this.props.docData.chatDoctorPanelId,
              textChatPrice:this.props.docData.textChatPrice
            }}
            className="text-decoration-none"
          >
            <img src={chat}></img> Chat(MSG)
          </Link>
            }
           
          </span>
          <span className="drServiceSp" id={"callDr" + this.props.consti}>
            <Link
              to={{
                pathname: "../patient/bookappointment",
                doctorId: this.props.docData.doctorId,
                hospitalId: this.props.docData.hospitalId,
                flagOption: "c",
              }}
              className="text-decoration-none"
            >
              <img src={phone}></img> Call
            </Link>
          </span>
          <span className="drServiceSp" id={"emailDr" + this.props.consti}>
            <a href="../patient/email">
              <img src={mail}></img> Email
            </a>
          </span>
          <span className="drServiceSp" id={"vcDr" + this.props.consti}>
            <Link
              to={{
                pathname: "../patient/bookappointment",
                doctorId: this.props.docData.doctorId,
                hospitalId: this.props.docData.hospitalId,
                flagOption: "v",
              }}
              className="text-decoration-none"
            >
              <img src={video}></img> Video Chat
            </Link>
          </span>

          <span className="drServiceSp" id={"bookDr" + this.props.consti}>
            <Link
              to={{
                pathname: "../patient/bookappointment",
                doctorId: this.props.docData.doctorId,
                hospitalId: this.props.docData.hospitalId,
                flagOption: "p",
              }}
              className="text-decoration-none"
            >
              <img src={booking}></img> Book
            </Link>
          </span>
          <span className="drServiceSp">
            <Link
              to={{
                pathname: "/home/doctor-detail",
                doctorId: this.props.docData.doctorId,
              }}
              state={{ docId: this.props.docData.doctorId }}
              className="text-decoration-none"
            >
              <img src={info}></img> Doctor info
            </Link>
          </span>
        </div>
      </div>
    );
  }
}
