import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header";
import Footer from "../layout/footer";
import ContactForm from "../home/contactus-form";

export default class AppPrivacyPolicy extends Component {
  render() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    return (
      <div className="purpleWrap green-shade">
        <Header></Header>
        <div className="container">
          <div className="main-wrapper1 terms-wrapper">
            <div className="terms-head">Privacy & Data Usage Policy</div>
            {/* <div className="terms-subhead">General</div> */}

            <div>
              <div className="terms-wrapper terms-wrapper-popup">
                <p className="terms-p-bold">General</p>
                <p className="terms-p-bold">
                  To use any of our services users must be 18+ years of age and
                  Citizens of India
                </p>
                <p>
                  We take your Privacy Seriously. In general we only ask for,
                  view and store data that is necessary to run our apps and
                  websites and to facilitate engagement with Health Care
                  Providers and Users and for the smooth running of the
                  services..
                </p>

                <p>
                  We do our very best to follow all laws regarding your Data
                  Privacy and we implement industry standards and best practices
                  for your Data Security.
                </p>
                <p className="terms-p-bold">Definitions</p>
                <p>
                  “We”, “Our”, “Us” - refers to the owner(s) of this website or
                  mobile app.
                </p>
                <p>
                  “You”, “Your”, “User”, “Client”, “Medical Reps”, “MRs" - refer
                  to the individual Users of the mobile app or website and its
                  associated services.
                </p>
                <p>
                  “Health Care Provider” or “HCP” “Provider” - refers to
                  Doctors, Clinicians, Therapists or any other health and
                  medical professional, expert, consultant or practitioner that
                  may be using or connected to the service.
                </p>
                <p>
                  “Health Care Partner”, “Affiliate”, “Partner”, “Service
                  Provider” - refer to associated partners that we may engage
                  with or whose services that we use such as health care
                  providers, clinics, hospitals, labs, pharmacies,
                  pharmaceutical companies, insurance companies, medical device
                  &amp; equipment providers, third party technology providers or
                  any third party service providers that we may engage with in
                  order to provide services for the User of the apps &amp;
                  website.
                </p>
                <p>
                  “Personal Information”, “Personally Identifiable Information”,
                  “PII” - refer to information that you provide us that
                  identifies you like:&nbsp;
                </p>
                <ul>
                  <li>
                    {" "}
                    <p>Name, Phone Number, Home Address, Email address.</p>{" "}
                  </li>
                  <li>
                    <p>Financial Information like Bank Accounts</p>
                  </li>
                </ul>
                <p>
                  “Personal Health Information”, “PHI” - refer to information
                  that is provided to us in regards to users’s health
                  like:&nbsp;
                </p>
                <ul>
                  <li>
                    <p>
                      Medical information: age, gender, information on your
                      medical conditions, information on your health related
                      issues
                    </p>
                  </li>
                </ul>
                <p>
                  “Digital Platform”, “Platform” - refer to the mobile app or
                  website and the underlying technology that Users, Health Care
                  Partners and Health Care Providers use to engage each other in
                  terms of either accessing or providing various relevant
                  services between each party.
                </p>
                <p className="terms-p-bold">
                  Use of Personal Data and Personal Information That Users and
                  HCP’s Provide To Us
                </p>
                <p>
                  In order to use our services, we require that Users and Health
                  Care Providers give us personal information in order to do
                  basic things like the following:
                </p>
                <ul>
                  <li>
                    <p>Create and Login to an account.</p>
                  </li>
                  <li>
                    <p>
                      Send SMS, Phone Calls or emails for appointment reminders,
                      calls or chats between a User and a Health Care Provider
                      or and any such engagement between a User and healthcare
                      provider that requires knowledge of contact information
                      for either of the parties.
                    </p>
                  </li>
                  <li>
                    <p>
                      Purchase of Services by using an User’s or HCP’s financial
                      information, insurance information or government ID
                      through online methods (like online payments using
                      credit-cards/debit-cards, bank transfers or other online
                      payment services)
                    </p>
                  </li>
                  <li>
                    <p>
                      Customer Service and Support requests whereby we have to
                      contact the User requesting help.
                    </p>
                  </li>
                  <li>
                    <p>
                      Purchase of Health Services by using an User’s financial
                      information, insurance information or government ID
                      through online methods (like online payments using
                      credit-cards/debit-cards, bank transfers or other online
                      payment services)
                    </p>
                  </li>
                  <li>
                    <p>
                      Payment to HCP’s bank for purchased Health Care Services
                      such as booking a telehealth appointment by User
                    </p>
                  </li>
                  <li>
                    <p>
                      Customer Service and Support requests whereby we have to
                      contact the person requesting help
                    </p>
                  </li>
                  <li>
                    <p>
                      Transmission of Personal Information to associated HCP’s
                      whom Users have requested to engage with and who use the
                      platform’s technologies in order fulfill services such as
                      appointments, or any other services.
                    </p>
                  </li>
                  <li>
                    <p>
                      We may at times personalize information and content so
                      that it is relevant to Users and HCP’s and share those
                      recommendations. This may include relevant articles,
                      content, information and potential advertisements of
                      services or products.
                    </p>
                  </li>
                  <li>
                    <p className="terms-p-bold">
                      {" "}
                      Note that we do not share or sell anyone’s personal
                      information to advertisers.
                    </p>
                  </li>
                </ul>
                <p className="terms-p-bold">Data Usage, Storage & Transmission</p>
                <p>
                  Some example of what we use application and device data access
                  for:
                </p>
                <ul>
                  <li>
                    <p>Media Storing & Transmission</p>
                    <ul>
                      <li>
                        <p>
                          Users & HCP’s saving images and files of relevant
                          records. eg. a pdf, photos, documents etc…
                        </p>
                      </li>
                      <li>
                        <p>
                          Sending and receiving digitized media through
                          video-chats and messages, eg. a User sends a doctor a
                          pdf or a photo or a document
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <p>Analytics</p>
                    <ul>
                      <li>
                        <p>
                          Transmission of Performance analytics to see if
                          services are working properly
                        </p>
                      </li>
                      <li>
                        <p>
                          Transmission of Crash & error logs to note application
                          crashes and errors
                        </p>
                      </li>
                      <li>
                        <p>
                          Transmission of Data showing usage levels of services
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <p>Contacts</p>
                    <ul>
                      <li>
                        <p>
                          Facilitate audio, video, email contact between Users &
                          HCP’s. For example an HCP may need to call a User or
                          video-chat with them.
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <p>Other Data</p>
                    <ul>
                      <li>
                        <p>
                          The use of technical non-personal data such as device
                          or browser information which is used in order to
                          improve the experience and functionality of our
                          services as well as to identify and solve any
                          technology related problems or issues.
                        </p>
                      </li>
                      <li>
                        <p>Storage of data to simplify login process.</p>
                      </li>
                    </ul>
                  </li>
                </ul>
                {/* <p>
                  In general you agree to not use our services in a manner other
                  than what it is intended for - namely for you (or your family)
                  to get access to health care professionals and for viewing
                  content on healthcare information. No other use of our
                  services is acceptable.
                </p> */}
                <p className="terms-p-bold">
                  NOTE: WE DO NOT SELL PERSONAL IDENTIFIABLE INFORMATION TO DATA
                  BROKERS OR ADVERTISERS, PHARMA OR INSURANCE COMPANIES
                </p>
                <p className="terms-p-bold">Removal of Data</p>
                <p>
                  Should you wish to delete your Personal Information from your
                  account please contact support@purplehealth.com with your
                  request. We do not guarantee the removal of Personal
                  Information that has been shared between User and an HCP. In
                  such an instance, you will have to contact the HCP directly.
                  We only deal with information on this platform.
                </p>
                <p>
                  If you use our services, you agree to this Privacy Policy that
                  has been laid out as well as our Terms Of Service for any
                  relevant services that you may engage with.
                </p>
                {/* <p>
                  You agree that the maximum liability that we have is the
                  amount that you may have paid for an appointment directly
                  through our platform for services or Rs. 1000 at a maximum.
                </p> */}
                <p className="terms-p-bold">Consent</p>
                <p>
                  You agree to the above terms and conditions of our privacy and
                  data usage policy.
                </p>
                {/* <p>
                  Furthermore, you must not use someone else’s credentials and
                  access their account.
                </p>
                <p>
                  If you suspect that someone else has your credentials, account
                  information and is using your account, you must contact us
                  immediately and inform us. Please email
                  support@purplehealth.com.
                </p>
                <p className="terms-p-bold">
                  Your Data and Information that You Provide
                </p>
                <p className="terms-p-bold">
                  Please read our Privacy Policy for more details on how we
                  handle your data.
                </p>
                <ul>
                  <li>
                    <p>
                      Note that we do not sell your personal health data or
                      personal identifying data to anyone.
                    </p>
                  </li>
                  <li>
                    <p>
                      You do agree to let us share your data with health care
                      practitioners, healthcare providers or partners &amp;
                      affiliates in order to facilitate booking an appointment,
                      for a telehealth consultation, for appointment
                      rescheduling or cancellation, e-prescriptions or for any
                      action relevant to providing our services to you.
                    </p>
                  </li>
                  <li>
                    <p>
                      You understand and acknowledge that we have no control
                      over or have any liability as it relates to personal
                      information or personal health information that you choose
                      to give to a healthcare provider or healthcare
                      practitioner, using our platform and services.
                    </p>
                  </li>
                  <li>
                    <p>
                      You may voluntarily choose to use services such as
                      uploading a personal health record to the platform or wish
                      to receive health records such as prescriptions from the
                      healthcare provider that you choose to engage with, such
                      as a doctor. This information can be sent through email,
                      SMS and similar means whose details you provide us and the
                      healthcare provider and practitioner for this purpose.
                      Should you provide a wrong email or phone number to which
                      this information is sent, we are not liable in any way for
                      the wrong party receiving this information. It is up to
                      you to ensure that the information that you provide is
                      100% accurate.
                    </p>
                  </li>
                  <li>
                    <p>
                      In addition, should you change your number or give it up,
                      you must update your information with us. You agree that
                      we are not responsible for any issues or problems arising
                      with you not informing us of any changes in your contact
                      details and updating your information on the platform.
                    </p>
                  </li>
                </ul>
                <p className="terms-p-bold">Suspension or Termination of Account</p>
                <p>
                  We reserve the right to suspend or cancel an account for any
                  reason, including:
                </p>
                <ul>
                  <li>
                    <p>
                      Harassment of others including healthcare providers and
                      healthcare practitioners
                    </p>
                  </li>
                  <li>
                    <p>
                      Booking of appointments and not attending the appointment
                      after booking. Typically, though not always, we will
                      suspend an account after 3 such occurrences
                    </p>
                  </li>
                  <li>
                    <p>
                      Sending purposely misleading information to our partner
                      healthcare providers or practitioners
                    </p>
                  </li>
                  <li>
                    <p>Using the system to surveil our partners</p>
                  </li>
                  <li>
                    <p>Copying our Intellectual Property</p>
                  </li>
                  <li>
                    <p>Scraping data from our services</p>
                  </li>
                  <li>
                    <p>Using an account that does not belong to you.</p>
                  </li>
                  <li>
                    <p>
                      Trying to get credentials of accounts that do not belong
                      to you.
                    </p>
                  </li>
                  <li>
                    <p>Any other reason that we see fit.</p>
                  </li>
                </ul>
                <p>
                  Should you wish to delete your account please email{" "}
                  <a href="mailTo:support@purplehealth.com">
                    support@purplehealth.com
                  </a>
                  .
                </p>
                <p className="terms-p-bold">Notifications and Communications</p>
                <ul>
                  <li>
                    <p>
                      In order to remind you of appointments, booking
                      confirmations, telehealth consultations, cancelled
                      appointments or for any reason related to our services,
                      you agree to allow us to send you notices and information
                      by SMS, in-app mobile push notifications, browser
                      notifications, messages, emails or phone calls. You may
                      manage these notifications as appropriate in any available
                      settings in our services related to notifications or in
                      your mobile phone or computer device &amp; browser
                      settings.
                    </p>
                  </li>
                  <li>
                    <p>
                      Should you opt out of receiving notifications, you
                      acknowledge that you may miss important notifications such
                      as reminders for events. In addition, should you opt out
                      of notifications and communications, the services may not
                      work properly or may not work at all.
                    </p>
                  </li>
                  <li>
                    <p>
                      You also agree that we may send your Personal Information
                      or Personal Health Information when you use our services
                      to consult with a healthcare provider or healthcare
                      professional such as a doctor. We may send information via
                      email, sms, in-app push-notification as it relates to
                      prescriptions, lab-orders, medical advice and related
                      services which you have voluntarily expressly opted into
                      and consented to allow. Should these communications be
                      intercepted or viewed by someone else, while unlikely, you
                      agree to release us from any liability arising from any
                      interception or unauthorized access.
                    </p>
                  </li>
                </ul>
                <p className="terms-p-bold">Ownership, Trademarks and Copyrights</p>
                <ul>
                  <li>
                    <p>
                      All of the software, content and intellectual property is
                      owned by us or has been licensed by us. We give users such
                      as yourself a limited permission to use our services and
                      access our content. This does not give any permanent
                      access or re-sale or commercial rights to our content or
                      services. You agree to not try to copy, sell or license
                      our content or services or any of our intellectual
                      property.
                    </p>
                  </li>
                </ul>
                <p className="terms-p-bold">User Generated Content</p>
                <p>
                  There may be parts of our service or platform that allows for
                  user generated. In those instances:
                </p>
                <ul>
                  <li>
                    <p>
                      As a user, you agree that any user generated content shall
                      automatically be licensed to us free of charge and free of
                      any legal, trademark or intellectual property encumbrances
                      and free for us to use, should we wish to use it, at any
                      time without any required payment of any sort and in any
                      manner that we choose. This is a perpetual, worldwide
                      license.
                    </p>
                  </li>
                  <li>
                    <p>
                      You also agree to not upload or save any trademarked
                      content on our services or platforms and that we are not
                      liable for any such misuse by you and that you indemnify
                      us for any IP or trademarked content that is uploaded by
                      you.
                    </p>
                  </li>
                  <li>
                    <p>
                      You agree that we are not liable for any of the content
                      uploaded by users. As expressed elsewhere in the Terms of
                      Service, we express no warranties or recommend any such
                      content to follow or believe, which has been stated in
                      other parts of this Terms of Service.
                    </p>
                  </li>
                  <li>
                    <p>
                      You agree to not upload pornography, illegal content or
                      any other content that may harm others, damage the service
                      or platform in any way such as malicious code or anything
                      similar.
                    </p>
                  </li>
                  <li>
                    <p>
                      You also agree that we are free to delete or modify any
                      such content that is posted or uploaded by you.
                    </p>
                  </li>
                </ul>
                <p className="terms-p-bold">Severability</p>
                <p>
                  If any part of the Agreement is held by a legal court, or
                  legal arbitrator, to be unenforceable then that provision will
                  be excluded but this shall not invalidate the rest of the
                  agreement which shall remain and considered to be valid.
                </p>
                <p className="terms-p-bold">We May Update This Agreement</p>
                <p>
                  You acknowledge, understand and agree that we may update these
                  Terms of Service from time to time and that the latest Terms
                  of Service will be in effect. We may require you to re-accept
                  the Terms of Use from time to time if we feel that the changes
                  have been substantial enough to warrant that effort.
                </p>
                <p className="terms-p-bold">Jurisdiction and Disputes</p>
                <p>
                  This Agreement is governed by the Laws of India and any legal
                  dispute shall be resolved in the State of Kerala in the City
                  of Thiruvananthapuram.
                </p>
                <p>
                  The dispute resolution method shall be by arbitration for
                  which we (or based on its approval a partner or affiliate)
                  will appoint an arbitrator and the arbitration shall be
                  conducted in English in accordance with the Arbitration and
                  Conciliation Act, 1996.
                </p> */}
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    );
  }
}
