import React, { Component, Suspense } from "react";
import $ from "jquery";
import { apiCalling } from "../../../apiService";
import Edit from "../../../image/icons/edit.png";
import check from "../../../image/icons/tick.png";
import cancel from "../../../image/icons/deactvate.png";
import Pagination from "react-js-pagination";
import SpecialisationPopup from "./SpecialisationPopup";
class SpecialisationList extends Component {
  state = {
    specializationData: [],
    totalCount: 0,
    activePage: 1,
    offset: "0",
    maxRow: "10",
    cmbSelect: "",
    cmbStatus: "",
    searchKeyWord: "",
    isSpecialisationPopupShow: false,
    specializationMedicineBranchId: 0,
  };
  componentDidMount() {
    this.getSpecializationList();
  }
  addEditSpec = (specializationMedicineBranchId) => {
    this.setState({
      isSpecialisationPopupShow: true,
      specializationMedicineBranchId: specializationMedicineBranchId,
    });
  };
  handlSpecializationStatusChange = (specialization) => {
    $(".loader").show();
    const specializationStatusChange = {
      method: "POST",
      body: JSON.stringify({
        functionName: "specializationStatusChange",
        specializationId: specialization.specialityId,
        isActive: !specialization.status,
      }),
    };
    apiCalling(specializationStatusChange).then((data) => {
      if (data.success === "1") {
        this.getSpecializationList();
     alert( specialization.status ?  "Specialisation Deactivated":"Specialisation Activated")
      } else if (data.success === "0") {
        alert("Error occurs..");
      }
      $(".loader").hide();
    });
  };
  renderSpecialisationData = () => {
    if (this.state.specializationData.length > 0) {
      return this.state.specializationData.map((spec) => (
        <tr key={spec.specialityId}>
          <td>{spec.specialityId}</td>
          <td>{spec.specialityName}</td>
          <td>{spec.description}</td>
          <td>{spec.medicineBranchName}</td>
          <td>{spec.status ? "Active" : "Inactive"}</td>
          <td style={{ width: "10vw" }} className="nowrap">
            <div>
              <span>
                <a
                  className="mr-2"
                  onClick={() =>
                    this.addEditSpec(spec.specializationMedicineBranchId)
                  }
                >
                  <img src={Edit} alt="Edit" width="18" className="img-fluid" />
                </a>
              </span>
              <span>
                <a
                  className="mr-2"
                  onClick={() => this.handlSpecializationStatusChange(spec)}
                  title={spec.status ? "Click to Deacitvate" : "Click to Activate"}
                >
                  <img
                    src={spec.status ? check : cancel}
                    alt={spec.status ? "Click to Deacitvate" : "Click to Activate"}
                    width="22"
                    className="img-fluid"
                  />
                </a>
              </span>
            </div>
          </td>
        </tr>
      ));
    } else {
      return (
        <tr>
          <td colSpan="6" className="text-center">
            No Data Found
          </td>
        </tr>
      );
    }
  };
  async handlePageChange(pageNumber) {
    await this.setState({
      activePage: pageNumber,
      offset: (parseInt(pageNumber) - 1) * 10,
    });
    this.getSpecializationList();
  }
  getSpecializationList = () => {
    if ( $("#cmbSerch").val() !== "0" && $("#searchKeyWord").val() === "") {
      var t = document.getElementById("cmbSerch");
      var selectedText = t.options[t.selectedIndex].text;
      alert(selectedText + " Required");
      $("#searchKeyWord").focus();
      return false;
    }
    $(".loader").show();
    const specializationList = {
      method: "POST",
      body: JSON.stringify({
        functionName: "specializationList",
        cmbSelect: this.state.cmbSelect,
        txtInput: this.state.searchKeyWord,
        cmbStatus: this.state.cmbStatus,
        max: this.state.maxRow,
        Offset: this.state.offset,
      }),
    };
    apiCalling(specializationList).then((data) => {
      if (data.success === "1") {
        this.setState({
          specializationData: data.specializationData,
          totalCount: data.totalCount,
        });
      } else if (data.success === "0") {
        this.setState({ specializationData: [], totalCount: 0 });
      }
      $(".loader").hide();
    });
  };
  handleCmbSerchChange = () => {
    var cmbSelectVal = $("#cmbSerch").val() !== "0" ? $("#cmbSerch").val() : "";
    $("#searchKeyWord").prop("disabled", cmbSelectVal !== "" ? false : true);
    this.setState({ cmbSelect: cmbSelectVal, searchKeyWord: "" });
    $("#searchKeyWord").focus();
  };
  handlecmbStatusChange = () => {
    var cmbStatus = $("#cmbStatus").val() !== "0" ? $("#cmbStatus").val() : "";
    this.setState({ cmbStatus: cmbStatus });
  };
  changeHandler = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  render() {
    return (
      <div className="HosptlMangemntTable">
        <div className="AddNewHspbtnDiv">
          <a className="addnewhspbtn" onClick={() => this.addEditSpec(0)}>
            Add Specialisation
          </a>
        </div>
        {this.state.totalCount > this.state.maxRow ? (
          <div className="pagination-qualList pagination-qualListwidth">
            <Pagination
              activePage={this.state.activePage}
              itemsCountPerPage={this.state.maxRow}
              totalItemsCount={this.state.totalCount}
              pageRangeDisplayed={10}
              onChange={(pageNumber) => this.handlePageChange(pageNumber)}
            />
          </div>
        ) : (
          ""
        )}
        <div className="tableHeader-HspMangemnt">
          <div className="selectField-HspMangmnt">
            <select
              className="slecthsp"
              id="cmbSerch"
              onChange={() => this.handleCmbSerchChange()}
            >
              <option value="0">Search In</option>
              <option value="1">Id</option>
              <option value="2">Speciality</option>
            </select>
          </div>
          <div className="selectField-HspMangmnt">
            <input
              type="text"
              className="hspmngInput"
              name="searchKeyWord"
              id="searchKeyWord"
              disabled
              value={this.state.searchKeyWord}
              onInput={this.changeHandler}
            />
          </div>
          <div className="selectField-HspMangmnt">
            <select
              className="slecthsp"
              id="cmbStatus"
              onChange={() => this.handlecmbStatusChange()}
            >
              <option value="0">Status</option>
              <option value="true">Active</option>
              <option value="false">Inactive</option>
            </select>
          </div>
          <div className="selectField-HspMangmnt">
            <button
              className="searchbtn-hspMng"
              onClick={() => this.getSpecializationList()}
            >
              Search
            </button>
          </div>
        </div>
        <div className="table-responsive">
        <table className="table table-bordered-hsp">
          <thead>
            <tr>
              <th className="tablehead-hspmng">ID</th>
              <th className="tablehead-hspmng">Speciality</th>
              <th className="tablehead-hspmng">Description</th>
              <th className="tablehead-hspmng">Medicine Branch</th>
              <th className="tablehead-hspmng">Status</th>
              <th className="tablehead-hspmng">Actions</th>
            </tr>
          </thead>
          <tbody>{this.renderSpecialisationData()}</tbody>
        </table>
        </div>
        {this.state.totalCount > this.state.maxRow ? (
          <div className="pagination-qualList pagination-qualListwidth">
            <Pagination
              activePage={this.state.activePage}
              itemsCountPerPage={this.state.maxRow}
              totalItemsCount={this.state.totalCount}
              pageRangeDisplayed={10}
              onChange={(pageNumber) => this.handlePageChange(pageNumber)}
            />
          </div>
        ) : (
          ""
        )}
        {this.state.isSpecialisationPopupShow == true && (
          <SpecialisationPopup
            ShowSpecialisationPopup={(data) =>
              this.setState({ isSpecialisationPopupShow: data })
            }
            specializationMedicineBranchId={
              this.state.specializationMedicineBranchId
            }
            getSpecializationList={this.getSpecializationList}
          />
        )}
      </div>
    );
  }
}
export default SpecialisationList;
