import React, { Component } from 'react';
import {Link} from "react-router-dom";
import Header from "../layout/header"
import Footer from "../layout/footer"
import ContactForm from "../home/contactus-form"


export default class contactus extends Component {



  render() {
    return (
        
        <div className="purpleWrap green-shade">
            <Header></Header>
            <ContactForm></ContactForm>
            <Footer></Footer>
        </div>
        
       
    )
  }
}
