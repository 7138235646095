import React, { Component, Suspense } from "react";
import Edit from "../image/icons/edit.png";
import AddImage from "../image/icons/addImage.png";
import rupeeicon from "../image/icons/rupee.png"
import Copy from "../image/icons/copy.png";
import Procedure from "../image/icons/procedure.svg";
import tick from "../image/icons/tick.png";
import Deactivate from "../image/icons/deactvate.png";
import { apiCalling } from "../apiService";
import $ from "jquery";
import Pagination from "react-js-pagination";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { Link } from "react-router-dom";
export default class HospitalMangementList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            registrationReportData: [],
            totalCount: 0,
            activePage: window.localStorage.getItem("hospitalpageNumber"),
            hospitalIds: [],
            showMessage: "Waiting for data... ",
            pageOffset: 0,
        };
        this.handlePageChange = this.handlePageChange.bind(this);
    }
    handlePageChange(pageNumber) {
        window.localStorage.setItem("hospitalpageNumber", pageNumber);
        this.setState({ activePage: pageNumber });
        var offset;
        var max;
        if (pageNumber === "1") {
            offset = "0";
            max = "10";
            this.setState({
                activePage: pageNumber,
                isLoading: "0",
            });
        } else {
            offset = parseInt(pageNumber - 1) * 10;
            max = "10";
            this.setState({
                activePage: pageNumber,
                isLoading: "0",
                pageOffset: offset,
            });
            window.localStorage.setItem("hospitalpageNumber", pageNumber);
            window.localStorage.setItem("hospitaloffset", offset);
        }

        const apiJson = {
            method: "POST",
            body: JSON.stringify(
                {
                    "functionName": "app_doctorRegistrationReport",
                    "siteId": "",
                    "doctorName": "",
                    "hospitalName": "",
                    "mobileNumber": "",
                    "emailId": "",
                    "offset":JSON.stringify(offset),
                    "max": max
                }
            ),
        };
        $(".loader").show();
        apiCalling(apiJson).then((data) => {
            $(".loader").hide();
            if (data.success === "1") {
                this.setState({
                    registrationReportData: data.result,
                    totalCount: data.resultCount,
                });
               
            }
        });
    }
    setactivepagenumber = (hospitaloffset) => {
        window.localStorage.setItem("hospitaloffset", hospitaloffset);
    };
    componentDidMount() {
        this.getDoctorRegistrationResult();
        window.addEventListener("beforeunload", (e) => this.handleBefore(e));
        return () => {
            window.removeEventListener("beforeunload", (e) => this.handleBefore(e));
        };
    }
    handleBefore = (e) => {
        e.preventDefault();
        window.localStorage.setItem("hospitalpageNumber", 1);
        window.localStorage.setItem("hospitaloffset", 0);
    };
    getDoctorRegistrationResult() {
        $(".loader").show();
        this.setState({
            activePage: Number(window.localStorage.getItem("hospitalpageNumber")),
        });
        const apiJson = {
            method: "POST",
            body: JSON.stringify(
                {
                    "functionName": "app_doctorRegistrationReport",
                    "siteId": "",
                    "doctorName": "",
                    "hospitalName": "",
                    "mobileNumber": "",
                    "emailId": "",
                    "offset": window.localStorage.getItem("hospitaloffset") == null
                        ? 0
                        : window.localStorage.getItem("hospitaloffset"),
                    "max": "10"
                }
            ),
        };
        apiCalling(apiJson).then((data) => {
            if (data.success === "1") {
                $(".loader").hide();
                this.setState({
                    registrationReportData: data.result,
                    totalCount: data.resultCount,
                });
            }
            if (data.success === "0") {
                $(".loader").hide();
                this.setState({
                    registrationReportData: [],
                    showMessage: data.errorMessage,
                });
            }
        });
    }
    changeText = () => {
        if ($("#selectCategory").val() !== "0") {
            document.getElementById("hospitalTxt").disabled = false;
            $("input[name=hospitalTxt]").focus();
        } else {
            document.getElementById("hospitalTxt").disabled = true;
        }
        document.getElementById("hospitalTxt").value = "";
    };

    searchHandler() {
        if ($("#selectCategory").val() !== "0" && $("#hospitalTxt").val() === "") {
            var t = document.getElementById("selectCategory");
            var selectedText = t.options[t.selectedIndex].text;
            alert(selectedText + " Required");
            $("#hospitalTxt").focus();
            return false;
        } else {
            const searchData = {
                method: "POST",
                body: JSON.stringify(
                    {
                        "functionName": "app_doctorRegistrationReport",
                        "siteId":$("#selectCategory").val()=="siteId"?$("#hospitalTxt").val():"",
                        "doctorName": $("#selectCategory").val()=="doctorName"?$("#hospitalTxt").val():"",
                        "hospitalName": $("#selectCategory").val()=="hospitalName"?$("#hospitalTxt").val():"",
                        "mobileNumber":$("#selectCategory").val()=="mobileNumber"?$("#hospitalTxt").val():"",
                        "emailId":$("#selectCategory").val()=="emailId"?$("#hospitalTxt").val():"",
                        "offset": "0",
                        "max": "10"
                    }
                ),
            };
            $(".loader").show();
            apiCalling(searchData).then((data) => {
                if (data.success === "1") {
                    $("#paginationType").show();
                    $(".loader").hide();
                    this.setState({
                        registrationReportData: data.result,
                        totalCount: data.resultCount,
                        activePage: 1,
                    });
                }
                if (data.success === "0") {
                    $("#paginationType").hide();
                    $(".loader").hide();
                    this.setState({
                        registrationReportData: [],
                        showMessage: data.errorMessage,
                    });
                }
            });
        }
    }
    statusChangeHandler(id, Status, updateType) {
        var x = false;
        if (Status === false) {
            x = window.confirm("Do you want to Disable this?");
        } else {
            x = window.confirm("Do you want to Enable this?");
        }
        if (x === true) {
            const StatusData = {
                method: "POST",
                body: JSON.stringify(
                    {
                        "functionName": "updateDoctorHospitalVerificationStatus",
                        "updateType": updateType,
                        "id": id,
                        "isVerified": Status,
                    }

                ),
            };
            $(".loader").show();
            apiCalling(StatusData).then((data) => {
                if (data.success === "1") {
                    $(".loader").hide();
                    alert("Status Changed Successfully");
                    this.getDoctorRegistrationResult();
                }
                if (data.success === "0") {
                    $(".loader").hide();
                    alert(data.errorMessage);
                }
            });
        }
    }
    render() {
        return (
            <div className="purpleWrap bg-clr-wthHead">
                <Suspense fallback={<div></div>}>
                    <section>
                        <Header /> <div className="loader"></div>
                    </section>
                </Suspense>
                <div className="loader"></div>
                <div className="container mb-5">
                    <div>
                        <ul className="breadcrum-adminMangmnt">
                            <li>
                                <a href="/admin/dashboardAdmin">Dashboard</a>
                            </li>
                            <li>
                                <a
                                    href="/adminManagement/doctorRegistrationReportThroughApp"
                                    className="current"
                                >
                                    Doctor Registration Report Through App
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="head-hospMangmntList">Hospital Management</div>
                    <div className="HosptlMangemntTable">
                        {this.state.totalCount > 5 ? (
                            <div
                                className="paginationSection orderm-paginationSection pagination-HspList"
                                id="paginationType" style={{width:'100%'}}
                            >
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={10}
                                    totalItemsCount={this.state.totalCount}
                                    pageRangeDisplayed={10}
                                    onChange={this.handlePageChange}
                                />
                            </div>
                        ) : (
                            ""
                        )}
                        <div className="tableHeader-HspMangemnt">
                            <div className="selectField-HspMangmnt">
                                <select
                                    className="slecthsp slecthspMob"
                                    id="selectCategory"
                                    name="selectCategory"
                                    onChange={() => this.changeText()}
                                >
                                    <option value="0">Search In</option>
                                    {/* <option value="siteId">Hospital Id</option> */}
                                    <option value="hospitalName">Hospital Name</option>
                                    <option value="doctorName">Doctor Name</option>
                                    <option value="mobileNumber">Mobile Number</option>
                                    <option value="emailId">Email Id</option>
                                </select>
                            </div>
                            <div className="selectField-HspMangmnt">
                                <input
                                    type="text"
                                    className="hspmngInput hspmngInputmob"
                                    name="hospitalTxt"
                                    id="hospitalTxt"
                                    disabled
                                    tabIndex="0"
                                    onKeyPress={(event) => {
                                        if (event.key === "Enter" || event.key === "NumpadEnter") {
                                            event.preventDefault();
                                            this.searchHandler();
                                        }
                                    }}
                                />
                            </div>
                            <div className="selectField-HspMangmnt">
                                <button
                                    className="searchbtn-hspMng"
                                    onClick={() => this.searchHandler()}
                                >
                                    Search
                                </button>
                            </div>
                        </div>
                        {this.state.registrationReportData.length > 0 ? (
                            <div className="table-responsive">
                                <table className="table table-bordered-hsp">
                                    <thead>
                                        <tr>
                                            <th className="tablehead-hspmng" width="5%">
                                                Hospital Id
                                            </th>
                                            <th className="tablehead-hspmng" width="30%">
                                                Hospital Name
                                            </th>
                                            <th className="tablehead-hspmng" width="10%">
                                                DoctorName
                                            </th>
                                            <th className="tablehead-hspmng" width="20%">
                                                Mobile Number
                                            </th>
                                            <th className="tablehead-hspmng" width="10%">
                                                Email Id
                                            </th>
                                            {/* <th className="tablehead-hspmng" width="10%">
                        City
                      </th> */}
                                            <th className="tablehead-hspmng" width="15%">
                                                Actions
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.registrationReportData.map((data) => (
                                            <tr key={data.hospitalId}>
                                                <td>{data.hospitalId}</td>
                                                <td>{data.hospitalName}</td>
                                                <td>{data.displayName}</td>
                                                <td>{data.mobileNumber}</td>
                                                <td>{data.emailId}</td>
                                                <td className="nowrap d-flex align-items-center">
                                                    <span className="d-flex flex-column align-items-center text-nowrap mr-3">Hospital Status
                                                        {data.isVerifiedHospital ===1  ? (
                                                            <img
                                                                src={tick}
                                                                width="22"
                                                                title="Click to Deactivate "
                                                                className="conf-cursorpointer"
                                                                alt=""
                                                                onClick={() => {
                                                                    this.statusChangeHandler(
                                                                        data.hospitalId,
                                                                        false,
                                                                        "Hospital"
                                                                    );
                                                                }}
                                                            ></img>
                                                        ) : (
                                                            <img
                                                                src={Deactivate}
                                                                alt=""
                                                                title="Click to Activate"
                                                                className="conf-cursorpointer"
                                                                width="20"
                                                                onClick={() => {
                                                                    this.statusChangeHandler(
                                                                        data.hospitalId,
                                                                        true,
                                                                        "Hospital"
                                                                    );
                                                                }}
                                                            ></img>
                                                        )}</span>
                                                    <span  className="d-flex flex-column align-items-center text-nowrap">Doctor Status
                                                        {data.isVerifiedDoctor === 1 ? (
                                                            <img
                                                                src={tick}
                                                                width="22"
                                                                title="Click to Deactivate "
                                                                className="conf-cursorpointer"
                                                                alt=""
                                                                onClick={() => {
                                                                    this.statusChangeHandler(
                                                                        data.doctorId,
                                                                        false,
                                                                        "Doctor"
                                                                    );
                                                                }}
                                                            ></img>
                                                        ) : (
                                                            <img
                                                                src={Deactivate}
                                                                alt=""
                                                                title="Click to Activate"
                                                                className="conf-cursorpointer"
                                                                width="20"
                                                                onClick={() => {
                                                                    this.statusChangeHandler(
                                                                        data.doctorId,
                                                                        true,
                                                                        "Doctor"
                                                                    );
                                                                }}
                                                            ></img>
                                                        )}</span>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {this.state.totalCount > 5 ? (
                                    <div className="paginationSection orderm-paginationSection pagination-HspList pagination-HspListwidth">
                                        <Pagination
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={10}
                                            totalItemsCount={this.state.totalCount}
                                            pageRangeDisplayed={10}
                                            onChange={this.handlePageChange}
                                        />
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        ) : (
                            <p className="error-msg text-center mb-0">
                                {" "}
                                {this.state.showMessage}{" "}
                            </p>
                        )}
                    </div>
                </div>
                <Suspense fallback={<div></div>}>
                    <section>
                        <Footer />
                    </section>
                </Suspense>
            </div>
        );
    }
}
