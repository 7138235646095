import React, { Component } from 'react';


export default class DoctorInboxMessage extends Component {

  render() {
    return (
          
           <div className="col-md-12">
             <div className="memberHead">
               <h4>Message Center</h4>
             </div>
             <div className="messageCenterDiv">
                   <div className="row">
                     <div className="col-md-3 col-sm-12">
                       <ul className="messageBlock">
                         <li className="inbox_btn" id="inbox_btn"><a href="/doctor/doctorinbox">Inbox </a></li>
                         <li className="sent_btn" id="sent_btn"><a href="/doctor/doctorsend">Sent</a></li>
                       </ul>
                     </div>
                     <div className="col-md-9 col-sm-12">
                       <div className="searchMessage">
                           <input type="text" placeholder="Search Messages" className="search_message" />
                          <input type="submit" value="Search" className="btn btn-bg" />
                       </div>
                       <div className="emailBox error-msg">   
                        No messages to display
                       </div>
                       <div className="emailBox">    
                          <ul>
	                            <li>
									              <div className="sender">
	                                <h3>TEST PATIENT (1)</h3>
                                  <span id="divcreatedOn0">Tue May 05 2020 10:03 PM</span>
	                              </div>
	                              <div className="subject">
	                                <a href="/doctor/messgedetail">
                                    <h6 className="color-maintext">Send To : Dr. Foggy Nelson</h6>
	                                  <h3>Ask the Doctor - SONALI  GURAV .</h3>
                                    <span>	 test	</span>
                                  </a>
                                </div>
                              </li>  
	                            <li>
									              <div className="sender">
	                                <h3>TEST PATIENT (1)</h3>
                                  <span id="divcreatedOn0">Tue May 05 2020 10:03 PM</span>
	                              </div>
	                              <div className="subject">
	                                <a href="/doctor/messgedetail">
                                    <h6 className="color-maintext">Send To : Dr. Foggy Nelson</h6>
	                                  <h3>Ask the Doctor - SONALI  GURAV .</h3>
                                    <span>	 test	</span>
                                  </a>
                                </div>
                              </li>   
	                            <li>
									              <div className="sender">
	                                <h3>TEST PATIENT (1)</h3>
                                  <span id="divcreatedOn0">Tue May 05 2020 10:03 PM</span>
	                              </div>
	                              <div className="subject">
	                                <a href="/doctor/messgedetail">
                                    <h6 className="color-maintext">Send To : Dr. Foggy Nelson</h6>
	                                  <h3>Ask the Doctor - SONALI  GURAV .</h3>
                                    <span>	 test	</span>
                                  </a>
                                </div>
                              </li>   
                                                        
                          </ul>
                        </div>
                     </div>
                   </div>
             </div>
           </div>


          
    )
  }
}
