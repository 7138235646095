import React, { Component, Suspense } from "react";
import BgImg from "../image/promotion/banner_bg01.png";
import DocImg from "../image/promotion/doctor.png";
import PromImg01 from "../image/promotion/calendar.png";
import PromImg02 from "../image/promotion/bottle.png";
import PromImg03 from "../image/promotion/medical-record.png";
import PromImg04 from "../image/promotion/telemedicine.png";
import PromImg05 from "../image/promotion/bill.png";
import PromImg06 from "../image/promotion/smartphone.png";
import Header from "../layout/header";
import Footer from "../layout/footer";
import moment from "moment";
import { apiCalling } from "../apiService";
import $ from "jquery";
export default class FreeCmsOne extends Component {
  contactUsEmailFree() {
    var doctorName = $("#doctorNameFree").val();
    var mrNumber = $("#mrNumberFree").val();
    var phone = $("#phoneFree").val();
    var flag = true;
    const re = /^([0-9]){10}$/;
    if (doctorName == "") {
      alert("Please enter your Name");
      flag = false;
    } else if (mrNumber == "") {
      alert("Please enter your Medical Registration Number");
      flag = false;
    } else if (phone == "") {
      alert("Please enter your Mobile Number");
      flag = false;
    } else if (re.test(phone) == false) {
      alert("Please enter valid 10-digit Mobile Number");
      flag = false;
    }
    if (flag == true) {
      const apiJson = {
        method: "POST",
        body: JSON.stringify({
          functionName: "contactUsEmail",
          siteId: null,
          emailId: "support@purplehealth.com",
          phone: phone,
          doctorName: doctorName,
          planType: "contactUsFreePlan",
          mrNumber: mrNumber,
          DateSigned: moment(new Date()).format("DD-MM-YYYY"),
          amount: "",
        }),
      };

      apiCalling(apiJson).then((data) => {
        if (data.success === "1") {
          alert("Email Sent Successfully");
          $("#doctorNameFree").val("");
          $("#mrNumberFree").val("");
          $("#phoneFree").val("");
        } else {
          alert("Failed");
        }
      });
    }
  }

  allowNumbersOnly(e) {
    var code = e.which ? e.which : e.keyCode;
    if (code > 31 && (code < 48 || code > 57)) {
      e.preventDefault();
    }
  }

  render() {
    return (
      <div className="freecms">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
            <div className="freecms_banner">
              <div className="freecms_container container">
                <div className="freecms_sliderleft">
                  <div className="freecms_sliderhead">
                    Free Clinic Management Software for Doctors!
                  </div>
                  <div className="freecms_slidersubhead">Free Forever!</div>
                  <div className="freecms_sliderpara">
                    Join the thousands of Doctors who have already signed up!
                  </div>
                </div>
                <div className="freecms_sliderright">
                  <div className="freecms_input">
                    <input
                      type="text"
                      placeholder="Enter Name"
                      id="doctorNameFree"
                    />
                    <input
                      type="text"
                      placeholder="Medical Registration Number*"
                      id="mrNumberFree"
                    />
                    <input
                      type="text"
                      placeholder="Mobile Number*"
                      id="phoneFree"
                      onKeyPress={(e) => this.allowNumbersOnly(e)}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          this.contactUsEmailFree();
                        }
                      }}
                    />
                    <button
                      type="submit"
                      onClick={this.contactUsEmailFree}
                      id="joinbutton"
                    >
                      Join Now!
                    </button>
                    <div className="freecms_required">*Required</div>
                  </div>
                </div>
                <div className="freecms_left_user">
                  <img src={DocImg} />
                </div>
              </div>
              <div className="freecms_bg">
                <img src={BgImg} />
              </div>
            </div>
            <div className="freecms_features">
              <div className=" container">
                <div className="freecms_featureshead">
                  Key Features Include:
                </div>
                <div className="freecms_features_key">
                  <div className="freecms_features_img">
                    <img src={PromImg01} />
                  </div>
                  <div className="freecms_features_connt">
                    <div className="freecms_apponint_head">
                      Appointment Calendar
                    </div>
                    <div className="freecms_apponint_para">
                      Manage appointments and patients can make online bookings
                    </div>
                  </div>
                </div>
                <div className="freecms_features_key">
                  <div className="freecms_features_img">
                    <img src={PromImg02} />
                  </div>
                  <div className="freecms_features_connt">
                    <div className="freecms_apponint_head">Prescriptions</div>
                    <div className="freecms_apponint_para">
                      Send Patients prescriptions and manage medicines & drugs
                    </div>
                  </div>
                </div>
                <div className="freecms_features_key">
                  <div className="freecms_features_img">
                    <img src={PromImg03} />
                  </div>
                  <div className="freecms_features_connt">
                    <div className="freecms_apponint_head">Patient Records</div>
                    <div className="freecms_apponint_para">
                      Electronic Medical Records, Patient Details and
                    </div>
                  </div>
                </div>
                <div className="freecms_features_key">
                  <div className="freecms_features_img">
                    <img src={PromImg04} />
                  </div>
                  <div className="freecms_features_connt">
                    <div className="freecms_apponint_head">
                      Telehealth Technology
                    </div>
                    <div className="freecms_apponint_para">
                      Paid online consultations: Video-Chat, Messages and Phone
                      Consultations
                    </div>
                  </div>
                </div>
                <div className="freecms_features_key">
                  <div className="freecms_features_img">
                    <img src={PromImg05} />
                  </div>
                  <div className="freecms_features_connt">
                    <div className="freecms_apponint_head">Billing</div>
                    <div className="freecms_apponint_para">
                      Online payments, Patient Billing
                    </div>
                  </div>
                </div>
                <div className="freecms_features_key">
                  <div className="freecms_features_img">
                    <img src={PromImg06} />
                  </div>
                  <div className="freecms_features_connt">
                    <div className="freecms_apponint_head">Mobile</div>
                    <div className="freecms_apponint_para">iOS & Android</div>
                  </div>
                </div>
                <div className="freecms_featureshead">
                  ...and many more powerful features!
                </div>
                <button
                  type="submit"
                  className="freecms_joinnow"
                  onClick={(e) => {
                    $("html, body").animate(
                      {
                        scrollTop: $(".freecms_banner").offset().top,
                      },
                      100
                    );
                  }}
                >
                  Join Today!
                </button>
              </div>
            </div>
          </section>
        </Suspense>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
