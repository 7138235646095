import React, { Component } from "react";
import $ from "jquery";
import DatePicker from "react-datepicker";
import { formatDatePickerToddMMyyyy } from "../../apiService";
import Close from "../.././image/icons/close-mark.png"
import moment from "moment";


export default class addPromoCodePopup extends Component {
  state = {
    siteId: this.props.siteId,
    promocodeId:
      this.props.promoDeatils != "" ? this.props.promoDeatils.promocodeId : "",
    promoCode:
      this.props.promoDeatils != "" ? this.props.promoDeatils.promoCode : "",
    hospitalPromoCodeFrom:
      this.props.promoDeatils != ""
        ? this.props.promoDeatils.hospitalPromoCodeFrom
        : "",
    hospitalPromoCodeTo:
      this.props.promoDeatils != ""
        ? this.props.promoDeatils.hospitalPromoCodeTo
        : "",
    hospitalPromoDiscountPercentage:
      this.props.promoDeatils != ""
        ? this.props.promoDeatils.hospitalPromoDiscountPercentage
        : "",
    fromDate:new Date()
  };

  changeHandler = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  dateChangeHandlerFrom = (date) => {
    this.setState({ 
      hospitalPromoCodeFrom: formatDatePickerToddMMyyyy(date),
      fromDate: date

    });
  };

  dateChangeHandlerTo = (date) => {
    if(moment(date).isBefore(this.state.fromDate)){
      alert("Active To Date Must be grater than from Date")
      return false
    }
    else{
      this.setState({ hospitalPromoCodeTo: formatDatePickerToddMMyyyy(date) });
    }
  };

  cancelPopup() {
    this.props.showAddPromoCodePopup(false);
  }

  disableDateField(id) {
    $("#" + id).attr("readonly", "readonly");
    this.setState({ [id]: null });
  }

  handleSave=()=>{    
    if(this.state.promoCode ==""){
      alert("Promo Code required")
    }else if(this.state.hospitalPromoDiscountPercentage ==""){
      alert("Discount percentage required")
    }else if(this.state.hospitalPromoCodeFrom == ""){
      alert("Active From Date required")
    }
    else if(this.state.hospitalPromoCodeTo == ""){
      alert("Active To Date required")
    }
    else{
      this.props.savePromoLink(this.state)
    }
  }

  render() {
    return (
      <div className="popup-overlay" style={{ display: "block" }}>
        <div className="popup-contentDiv">
          <div className="modal-popup">
            <span
              className="closepopupqual"
              onClick={() => this.cancelPopup()}
            >
              {/* &times; */}
              <img src={Close} alt="Close" className="img-fluid" width="25" />
            </span>
            <div className="header border-0">Add Promo Code</div>
            <div className="content">
              <div className="row">
                <div className="row-margin col-md-6">
                  <label className="col-md-12 label-text">
                    Promo Code <span className="hspifoMantory">*</span>
                  </label>
                  <div className="col-md-12">
                    <input
                      type="text"
                      className="qualifcatin-popupInput qualifcatin-popupInput-promomob"
                      id="promoCode"
                      name="promoCode"
                      value={this.state.promoCode}
                      onInput={this.changeHandler}
                    />
                  </div>
                </div>
                <div className="row-margin col-md-6">
                  <label className="col-md-12 label-text">
                    Discount Percentage <span className="hspifoMantory">*</span>
                  </label>
                  <div className="col-md-12">
                    <input
                      type="text"
                      className="qualifcatin-popupInput qualifcatin-popupInput-promomob"
                      id="hospitalPromoDiscountPercentage"
                      name="hospitalPromoDiscountPercentage"
                      value={this.state.hospitalPromoDiscountPercentage}
                      onInput={this.changeHandler}
                    />
                  </div>
                </div>

                <div className="row-margin col-md-6">
                  <label className="col-md-12 label-text">
                    Active From Date <span className="hspifoMantory">*</span>
                  </label>
                  <div className="col-md-12 site-promodateinput">
                    <DatePicker
                      id="hospitalPromoCodeFrom"
                      name="hospitalPromoCodeFrom"
                      placeholderText="Promo Code From"
                      className="hspInfofieldinput healthInput-radius-date"
                      // maxDate={new Date()}
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={100}
                      dropdownMode="scroll"
                      peekNextMonth
                      disabledKeyboardNavigation
                      onSelect={(date) =>
                        this.dateChangeHandlerFrom(date)
                      }
                      value={this.state.hospitalPromoCodeFrom}
                      dateFormat="dd/MM/yyyy"
                      calendarClassName="react-date-picker"
                      autoComplete="off"
                      tabIndex="3"
                      onKeyDown={() =>
                        this.disableDateField("hospitalPromoCodeFrom")
                      }
                    />
                  </div>
                </div>

                <div className="row-margin col-md-6">
                  <label className="col-md-12 label-text">
                    Active To Date <span className="hspifoMantory">*</span>
                  </label>
                  <div className="col-md-12 site-promodateinput">
                    <DatePicker
                      id="hospitalPromoCodeTo"
                      name="hospitalPromoCodeTo"
                      placeholderText="Promo Code To"
                      className="hspInfofieldinput healthInput-radius-date"
                      // maxDate={new Date()}
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={100}
                      dropdownMode="scroll"
                      peekNextMonth
                      disabledKeyboardNavigation
                      onSelect={(date) =>
                        this.dateChangeHandlerTo(date)
                      }
                      value={this.state.hospitalPromoCodeTo}
                      dateFormat="dd/MM/yyyy"
                      calendarClassName="react-date-picker "
                      autoComplete="off"
                      tabIndex="3"
                      onKeyDown={() =>
                        this.disableDateField("hospitalPromoCodeTo")
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="text-center">
                <span
                  className="sitesetng-svebtn"
                  onClick={this.handleSave}
                >
                  Save
                </span>
                <input
                  type="button"
                  value="cancel"
                  className="sitesetng-cancelbtn"
                  onClick={() => this.cancelPopup()}
                ></input>
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    );
  }
}
