import React, { Component } from "react";

export default class embedCodePopup extends Component {
  state = {
    embedCode: `<object type="text/html" data="https://www.purplehealth.com//vaas/doctorsFullBlog?doctorId=303805"></object>`,
  };

  cancelPopup() {
    this.props.showEmbedCodePopup(false);
  }

  render() {
    return (
      <div className="popup-overlay" style={{ display: "block" }}>
        <div className="modal-dialog embedPopup" role="document">
          {" "}
          {/*modal-xl */}
          <div className="modal-content">
            <div className="modal-header modal-popup">
              <a
                className="close-popup popup-hide"
                onClick={() => this.props.showEmbedCodePopup(false)}
              >
                &times;
              </a>
              <div className="header border-0">Embed Code</div>
            </div>
            <div className="row modal-body">
              <div className="col-md-12">
                <textarea
                  className="qualifcatin-popupInput"
                  onInput={this.props.changeHandler}
                  style={{ width: "100%", height: "200px" }}
                  value={this.state.embedCode}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
