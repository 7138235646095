import React, { Component, Suspense } from "react";
import Header from "../../../layout/header";
import Footer from "../../../layout/footer";
import SpecialisationList from "./SpecialisationList";

class Specialisation extends Component {
  render() {
    const navigation = [
      { pageName: "DashBoard", url: "/admin/dashboardAdmin" },
      {
        pageName: "Medical Branch Dashboard",
        url: "/adminManagement/medicineBranch",
      },
      {
        pageName: "Specialisation",
        url: "/adminManagement/specialisation",
        current: true,
      },
    ];
 
    return (
      <div className="purpleWrap bg-clr-wthHead">
        <Suspense fallback={<div>Waiting...</div>}>
          <section>
            <Header />
            <div className="loader"></div>
            <div className="container">
              <div>
                <ul className="breadcrum-adminMangmnt">
                  {navigation.map((nav) => (
                    <li>
                      <a
                        href={nav.url}
                        className={nav.current ? "current" : null}
                      >
                        {nav.pageName}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="head-hospMangmntList">Specialisation</div>
              <SpecialisationList />
            </div>
          </section>
        </Suspense>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}

export default Specialisation;
