import React, { Component } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import $ from "jquery";
import rx from "../image/media/rx.png";
import { apiCalling } from "../apiService";
import {
  createSession,
  OTSession,
  OTPublisher,
  OTStreams,
  OTSubscriber,
} from "opentok-react";
export default class DoctorVideocall extends Component {
  constructor(props) {
    super(props);
    this.state = {
      streams: [],
      apiKey: localStorage.getItem("apiKey"),
      sessionId: localStorage.getItem("sessionId"),
      token: localStorage.getItem("token"),
      audioSource: undefined,
      videoSource: undefined,
      connected: false,
      callType: "",
      publisher: null,
      error: null,
      connection: "Connecting",
      publishVideo: true,
    };
    this.endChat = this.endChat.bind(this);
    this.sessionEventHandlers = {
      sessionConnected: () => {
        this.setState({ connection: "Connected" });
      },
      sessionDisconnected: () => {
        this.setState({ connection: "Disconnected" });
      },
      sessionReconnected: () => {
        this.setState({ connection: "Reconnected" });
      },
      sessionReconnecting: () => {
        this.setState({ connection: "Reconnecting" });
      },
    };
    this.publisherEventHandlers = {
      accessDenied: () => {
        console.log("User denied access to media source");
      },
      streamCreated: () => {
        console.log("Publisher stream created");
      },
      streamDestroyed: ({ reason }) => {
        console.log(`Publisher stream destroyed because: ${reason}`);
      },
    };

    this.subscriberEventHandlers = {
      videoEnabled: () => {
        console.log("Subscriber video enabled");
      },
      videoDisabled: () => {
        console.log("Subscriber video disabled");
      },
    };
  }
  onSessionError = (error) => {
    this.setState({ error });
  };

  onPublish = () => {
    console.log("Publish Success");
  };

  onPublishError = (error) => {
    this.setState({ error });
  };

  onSubscribe = () => {
    console.log("Subscribe Success");
  };

  onSubscribeError = (error) => {
    this.setState({ error });
  };

  toggleVideo = () => {
    this.setState((state) => ({
      publishVideo: !state.publishVideo,
    }));
  };
  endChat() {
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "completeChatStatus",
        requestVideoAppointmentId: localStorage.getItem(
          "doctorVideoScheduleId"
        ),
        appointmentStatus: "Call Completed",
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success == "1") {
        alert("Call ended");
      }
      $(".loader").hide();
    });
  }
  getDeviceIds = (kind) => {
    const deviceIds = this.devices
      .filter((device) => device.kind === kind)
      .map((device) => device.deviceId);
    return deviceIds;
  };
  componentWillMount() {}

  componentWillUnmount() {
    this.sessionHelper.disconnect();
  }
  componentDidMount() {
    let self = this;
    $("#videoButton").hide();
    let drcallType = localStorage.getItem("drcallType");
    if (drcallType == "audio") {
      $("#videoButton").click();
    }

    window.OT.getDevices((error, devices) => {
      if (error) {
        console.log("getDevices error:", error);
        return;
      }
      this.devices = devices;
      this.audioDeviceIds = this.getDeviceIds("audioInput");
      this.videoDeviceIds = this.getDeviceIds("videoInput");
      this.audioIndex = 0;
      this.videoIndex = 0;
    });
    $("#prescribe").hide();
    $("#rxspan").click(function () {
      $("#chatDiv").hide();
      $("#prescribe").show();
    });
    $("#prescribe-close").click(function () {
      $("#prescribe").hide();
      $("#chatDiv").show();
    });
  }
  cycleCamera = () => {
    const videoSource = this.videoDeviceIds[this.videoIndex];
    this.setState({ videoSource });
    this.videoIndex =
      this.videoIndex === this.videoDeviceIds.length ? 0 : this.videoIndex + 1;
  };

  cycleMicrophone = () => {
    const audioSource = this.audioDeviceIds[this.audioIndex];
    this.setState({ audioSource });
    this.audioIndex =
      this.audioIndex === this.audioDeviceIds.length ? 0 : this.audioIndex + 1;
  };
  render() {
    const { error, connection, publishVideo } = this.state;
    return (
      <div className="purpleWrap">
        <Header></Header>

        <div className="container">
          <div className="main-wrapper1 px-2">
            <div className="row">
              <div className="col-sm-12 text-right p-0 mb-1">
                <a href="#" className="color-maintext bold-700">
                  Back
                </a>
              </div>
              <div className="col-lg-9 video-mainwrapper">
                <div className="time-counting">00:00:30</div>
                <div className="video-wrapper">
                  <div className="videoBig">
                    <button id="videoButton" onClick={this.toggleVideo}>
                      {publishVideo ? "Disable" : "Enable"} Video
                    </button>
                    <OTSession
                      apiKey={this.state.apiKey}
                      sessionId={this.state.sessionId}
                      token={this.state.token}
                      onError={this.onSessionError}
                      eventHandlers={this.sessionEventHandlers}
                    >
                      <OTStreams id="OTStreams">
                        <OTSubscriber
                          properties={{ width: 100, height: 100 }}
                          onSubscribe={this.onSubscribe}
                          onError={this.onSubscribeError}
                          onError={this.onSessionError}
                          eventHandlers={this.subscriberEventHandlers}
                        />
                      </OTStreams>
                    </OTSession>
                  </div>
                  <div className="videosmall">
                    <OTSession
                      apiKey={this.state.apiKey}
                      sessionId={this.state.sessionId}
                      token={this.state.token}
                      onError={this.onSessionError}
                      eventHandlers={this.sessionEventHandlers}
                    >
                      <OTPublisher
                        properties={{ publishVideo, width: 50, height: 50 }}
                        onPublish={this.onPublish}
                        onError={this.onPublishError}
                        eventHandlers={this.publisherEventHandlers}
                      />
                    </OTSession>
                  </div>
                </div>
                <div className="text-right mt-4">
                  <input
                    type="button"
                    className="end-chatbtn"
                    value="End Chat"
                    onClick={() => this.endChat()}
                  ></input>
                </div>
              </div>
              <div className="col-lg-3 p-0">
                <div className="video-left-wrapper">
                  <div className="video-chat-left">
                    <small className="color-maintext">You are speaking to:</small>
                    <p className="color-maintext bold-700">TEST NEW PATIENT</p>
                    <textarea
                      className="input-design"
                      rows="4"
                      placeholder="Comments"
                    ></textarea>
                  </div>
                  <div className="chatbottom-box" id="chatDiv">
                    <div className="chat-head">
                      <i className="fa fa-comment"></i> Chats
                    </div>
                    <div className="chat-body">
                      <div className="textchat-wrapper">
                        <div className="mb-2">
                          <span className="time-doctor">
                            <small>Test doc </small>
                            <small className="float-right">2:19 pm</small>
                            <br></br>
                          </span>
                          <small>dsf esfs sewd</small>
                        </div>
                        <div className="mb-2">
                          <span className="time-doctor">
                            <small>Test doc </small>
                            <small className="float-right">2:19 pm</small>
                            <br></br>
                          </span>
                          <small>dsf esfs sewd</small>
                        </div>
                        <div className="mb-2">
                          <img src={rx} width="30"></img>
                        </div>
                      </div>
                      <small className="">10000 characters left</small>
                      <textarea
                        className="input-design"
                        rows="4"
                        placeholder="Send a message..."
                      ></textarea>
                      <select className="input-design">
                        <option value="0">Default Questions</option>
                      </select>
                    </div>
                    <div className="col-md-12 chat-bottom">
                      <div className="messageFileUpload">
                        <input
                          id="file1"
                          name="file1"
                          tabindex="33"
                          className="uploadattach"
                          type="file"
                        />
                      </div>
                      <input
                        type="button"
                        value="Upload"
                        className="chat-upload"
                      ></input>
                      <input type="submit" value="Send" className="chatbtn"></input>
                      <span className="rxspan" id="rxspan">
                        {" "}
                        <img src={rx}></img>{" "}
                      </span>
                    </div>
                  </div>
                  <div className="prescribe-wrapper" id="prescribe">
                    <p>Prescribe Medicine</p>
                    <i className="fa fa-close close-icon" id="prescribe-close"></i>
                    <textarea
                      className="textarea input-design"
                      placeholder="Prescribe Medicine"
                    ></textarea>
                    <input type="submit" value="Send" className="chatbtn"></input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer></Footer>
      </div>
    );
  }
}
