import React, { useState } from 'react';
import BlogList from './blog-list-blocks';

function BlogLists() {
    
    return (
        <div className="ph-main-outerdiv">
            <div className="ph-success  ph-space-service mb-5 ">
                <div className="ph-db-banner text-md-center mb-4">
                    <span className="ph-banner-title1">
                        Resources
                    </span>
                </div>
                <h2 className="ph-main-title-2 text-md-center">
                    Blogs
                    <div className='text-md-center ph-success-story-subtitle pt-2 '>
                        PurpleHealth Blogs Room
                    </div>
                </h2>
            </div>
            <div className='d-flex justify-content-center container'>
            <BlogList blogDetailFlag={false} blogId={""}/>
            </div>
        
        </div>
    );
}

export default BlogLists;
