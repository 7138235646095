import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Edit from "../../image/icons/edit.png";
import tick from "../../image/icons/tick.png";
import Deactivate from "../../image/icons/deactvate.png";
import { apiCalling } from "../../apiService";
import $ from "jquery";
import Pagination from "react-js-pagination";
import Header from "../../layout/header";
import Footer from "../../layout/footer";


const DoctorManagement = () => {
  const history = useNavigate();
  const [doctorManagementData, setDoctorManagementData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [showMessage, setShowMessage] = useState("Waiting for data...");

  useEffect(() => {
    getHospitalDetails();
    window.addEventListener("beforeunload", handleBefore);
    return () => {
      window.removeEventListener("beforeunload", handleBefore);
    };
  }, []);

  const handleBefore = (e) => {
    e.preventDefault();
    window.localStorage.setItem("doctorpageNumber", 1);
    window.localStorage.setItem("Doctoroffset", 0);
  };

  const getHospitalDetails = () => {
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "doctorLists",
        cmbSelect: "",
        txtInput: "",
        max: "10",
        Offset:
          window.localStorage.getItem("Doctoroffset") == null
            ? 0
            : window.localStorage.getItem("Doctoroffset"),
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        $(".loader").hide();
        setDoctorManagementData(data.doctorData);
        setTotalCount(data.totalCount);
        setActivePage(Number(window.localStorage.getItem("doctorpageNumber")));
      }
      if (data.success === "0") {
        $(".loader").hide();
        setDoctorManagementData([]);
        setShowMessage(data.errorMessage);
      }
    });
  };

  const handlePageChange = (pageNumber) => {
    var offset;
    var max;
    if (pageNumber === "1") {
      offset = "0";
      max = "10";
      setActivePage(pageNumber);
    } else {
      offset = parseInt(pageNumber - 1) * 10;
      max = "10";
      setActivePage(pageNumber);
      window.localStorage.setItem("doctorpageNumber", pageNumber);
      window.localStorage.setItem("Doctoroffset", offset);
    }
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "doctorLists",
        cmbSelect:
          $("#selectCategory").val() === "0" ? "" : $("#selectCategory").val(),
        txtInput: $("#hospitalTxt").val().trim(),
        Offset: JSON.stringify(offset),
        max: max,
      }),
    };
    $(".loader").show();
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        setDoctorManagementData(data.doctorData);
        setTotalCount(data.totalCount);
        $(".loader").hide();
      }
    });
  };

  const changeText = () => {
    if ($("#selectCategory").val() !== "0") {
      document.getElementById("hospitalTxt").disabled = false;
      $("input[name=hospitalTxt]").focus();
    } else {
      document.getElementById("hospitalTxt").disabled = true;
    }
    document.getElementById("hospitalTxt").value = "";
  };

  const searchHandler = () => {
    if ($("#selectCategory").val() !== "0" && $("#hospitalTxt").val() === "") {
      var t = document.getElementById("selectCategory");
      var selectedText = t.options[t.selectedIndex].text;
      alert(selectedText + " Required");
      $("#hospitalTxt").focus();
      return false;
    } else {
      const searchData = {
        method: "POST",
        body: JSON.stringify({
          functionName: "doctorLists",
          cmbSelect:
            $("#selectCategory").val() === "0"
              ? ""
              : $("#selectCategory").val(),
          txtInput: $("#hospitalTxt").val().trim(),
          Offset: "0",
          max: "10",
        }),
      };
      $(".loader").show();
      apiCalling(searchData).then((data) => {
        if (data.success === "1") {
          $("#paginationType").show();
          setDoctorManagementData(data.doctorData);
          setTotalCount(data.totalCount);
          $(".loader").hide();
          setActivePage(1);
        }
        if (data.success === "0") {
          $("#paginationType").hide();
          $(".loader").hide();
          setDoctorManagementData([]);
          setShowMessage(data.errorMessage);
        }
      });
    }
  };

  const statusChangeHandler = (id, Status) => {
    var x = false;
    if (Status === true) {
      x = window.confirm("Do you want to Disable this?");
    } else {
      x = window.confirm("Do you want to Enable this?");
    }
    if (x === true) {
      const SatusData = {
        method: "POST",
        body: JSON.stringify({
          functionName: "doctorStatusChange",
          isActive: Status === true ? 0 : 1,
          doctorId: id,
        }),
      };
      $(".loader").show();
      apiCalling(SatusData).then((data) => {
        if (data.success === "1") {
          $(".loader").hide();
          alert("Status Changed Successfully");
          getHospitalDetails();
        }
        if (data.success === "0") {
          $(".loader").hide();
          alert(data.errorMessage);
        }
      });
    }
  };

  const copyData = (doctorId) => {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "copyDoctor",
        doctorId: doctorId,
      }),
    };
    apiCalling(apiJson).then((data) => {
      console.log('xxxxxxxx',data);
      if (data.success === "1") {
        alert("Doctor data copied successfully");
        getHospitalDetails();
      } else {
        alert(data.errorMessage);
      }
    });
  };
  // const loginToPurpleHealth = () => {
  //   window.location.href =
  //     "https://www.imahospitals.com/videoSchedule/chatroomconsole";
  // };


  // const loginToPulse = () => {
  //   window.location.href =
  //     "https://pulse.purplehealth.com/practiceManagement/loginFromPurple?";
  // };

  const handleAddDoctorClick = (data) => {
    history('/adminManagement/doctorManagementList/addDoctor', {
      state:data  
   });
  };
  return (
    <div className="bg-clr-wthHead">
      <Header
      />
      <div className="loader"></div>
      <div className="container mb-5">
        <div>
          <ul className="breadcrum-adminMangmnt">
            <li>
              <a href="/admin/dashboardAdmin">Dashboard</a>
            </li>
            <li>
              <a href="" className="current">
                Doctor Management
              </a>
            </li>
          </ul>
        </div>
        <div className="head-hospMangmntList">Doctor Management</div>
        <div className="HosptlMangemntTable">
          <div className="AddNewHspbtnDiv">
            <div
              onClick={() => handleAddDoctorClick("")}
              className="addnewhspbtn"
            >
              Add New Doctor
            </div>
          </div>
          {totalCount > 5 ? (
            <div
              className="paginationSection orderm-paginationSection pagination-HspList"
              id="paginationType"
            >
              <Pagination
                activePage={activePage}
                itemsCountPerPage={10}
                hideDisabled
                totalItemsCount={totalCount}
                pageRangeDisplayed={10}
                onChange={handlePageChange}
              />
            </div>
          ) : (
            ""
          )}
         <div className="tableHeader-HspMangemnt">
              <div className="selectField-HspMangmnt">
                <select
                  className="slecthsp slecthspMob"
                  id="selectCategory"
                  name="selectCategory"
                  onChange={() => changeText()}
                >
                  <option value="0">Search In</option>
                  <option value="1">ID</option>
                  <option value="2">Doctor Name</option>
                  <option value="3">City</option>
                  <option value="4">Email Id</option>
                  <option value="5">Mobile</option>
                </select>
              </div>
              <div className="selectField-HspMangmnt">
                <input
                  type="text"
                  className="hspmngInput hspmngInputmob"
                  name="hospitalTxt"
                  id="hospitalTxt"
                  disabled
                  tabIndex="0"
                  onKeyPress={(event) => {
                    if (
                      event.key === "Enter" ||
                      event.key === "NumpadEnter"
                    ) {
                      event.preventDefault();
                      searchHandler();
                    }
                  }}
                />
              </div>
              <div className="selectField-HspMangmnt">
                <button
                  className="searchbtn-hspMng"
                  onClick={() => searchHandler()}
                >
                  Search
                </button>
              </div>
            </div>
          {doctorManagementData.length > 0 ? (
            <div className="table-responsive">
               <table className="table table-bordered-hsp">
                  <thead>
                    <tr>
                      <th className="tablehead-hspmng" colSpan="2">
                        ID
                      </th>
                      <th className="tablehead-hspmng" colSpan="4">
                        Doctor Name{" "}
                      </th>
                      <th className="tablehead-hspmng" colSpan="3">
                        City
                      </th>
                      <th className="tablehead-hspmng" colSpan="4">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {doctorManagementData.map((data) => (
                      <tr key={data.doctorId}>
                        <td colSpan="2">{data.doctorId}</td>
                        <td colSpan="4">{data.doctorName}</td>
                        <td colSpan="3">{data.city}</td>
                        <td colSpan="4" className="nowrap d-flex aling-items-center flex-wrap">
                          <div className="ph_actn_img d-flex aling-items-center">
                            <div
                              onClick={() =>
                                handleAddDoctorClick(data.doctorId)
                              }
                              className="mr-2 "
                            >
                              <img
                                src={Edit}
                                alt=""
                                width="18"
                                className="img-fluid"
                              />
                            </div>
                            {data.enabled === true ? (
                              <img
                                src={tick}
                                width="22"
                                title="Click to Deactivate "
                                className="mr-2 conf-cursorpointer"
                                alt=""
                                onClick={() =>
                                  statusChangeHandler(data.doctorId, true)
                                }
                              ></img>
                            ) : (
                              <img
                                src={Deactivate}
                                alt=""
                                title="Click to Activate"
                                className="mr-2 conf-cursorpointer"
                                width="20"
                                onClick={() =>
                                  statusChangeHandler(data.doctorId, false)
                                }
                              ></img>
                            )}
                          </div>
                          <div>
                            <span className="ph_action_horizontal">
                              <a
                                href="#"
                                className="listLink-hspmangmnt"
                                onClick={() => copyData(data.doctorId)}
                              >
                                Copy Data
                              </a>
                              {/* <a
                                href="#"
                                className="listLink-hspmangmnt"
                                onClick={() => loginToPurpleHealth()}
                              >
                                Login To PurpleHealth
                              </a> */}
                            </span>
                          </div>
                          {/* <div>
                            <span className="ph_action_horizontal">
                              <a
                                href="#"
                                className="listLink-hspmangmnt"
                                onClick={() => loginToPulse()}
                              >
                                Login To Pulse
                              </a>
                            </span>
                          </div> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              {totalCount > 5 ? (
                <div className="paginationSection orderm-paginationSection pagination-HspList pagination-HspListwidth">
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={10}
                    totalItemsCount={totalCount}
                    pageRangeDisplayed={10}
                    hideDisabled
                    onChange={handlePageChange}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            <p className="error-msg text-center mb-0">{showMessage}</p>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DoctorManagement;
