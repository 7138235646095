import React, { Component } from 'react';
import Header from "../layout/header"
import Footer from "../layout/footer"
import $ from "jquery";
import book from "../image/media/icon6.png" 
import video from "../image/media/icon5.png"
import chat from "../image/media/icon7.png"
import phone from "../image/media/icon9.png"
export default class Docservices extends Component {
  render() {
    return (
      <div className="purpleWrap">
        <Header></Header>
        <div className="container container-auto">
          <div className="main-wrapper1">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li><a href="/doctor/dashboard">Dashboard</a></li>
              </ul>
              </div>
              <div className="serviceWrap pt-3">
                <ul>
                  <li>
                    <a className="icon tabIcon serviceEnable"><img src={book} /><span>Book</span></a>
                    <button>Disable</button> 
                    <div className="hospBox">
                      <div className="priceCover">
                        <label>Price per minute</label>
                        <input placeholder="Price" className="popField" type="text" />
                        <label>Price per session</label>
                        <input placeholder="Price" className="popField" type="text" />
                      </div>
                      <span className="changeLink"> Change Price</span>	
                    </div> 
                  </li>  
                  <li>
                    <a className="icon tabIcon tabeActiveClass"><img src={chat} /><span> Chat</span></a>
                    <button>Disable</button> 
                    <div className="hospBox">
                      <div className="priceCover">
                        <label>Price per minute</label>
                        <input placeholder="Price" className="popField" type="text" />
                        <label>Price per session</label>
                        <input placeholder="Price" className="popField" type="text" />
                      </div>
                      <span className="changeLink"> Change Price</span>	
                    </div>
                  </li>    
                   <li>
                     <a className="icon tabIcon tabeActiveClass"><img src={video} /><span>Video Chat</span></a>
                     <button>Disable</button> 
                     <div className="hospBox">
                       <div className="priceCover">
                         <label>Price per minute</label>
                         <input placeholder="Price" className="popField" type="text" />
                         <label>Price per session</label>
                         <input placeholder="Price" className="popField" type="text" />
                       </div>
                       <span className="changeLink"> Change Price</span>	
                     </div>
                   </li> 
                   <li>
                     <a className="icon tabIcon tabeActiveClass"><img src={chat} /><span> Email Consult</span></a>
                     <button>Disable</button> 
                     <div className="hospBox">
                       <div className="priceCover">
                         <label>Price per minute</label>
                         <input placeholder="Price" className="popField" type="text" />
                         <label>Price per session</label>
                         <input placeholder="Price" className="popField" type="text" />
                       </div>
                       <span className="changeLink"> Change Price</span>	
                     </div>
                   </li> 
                   <li>
                     <a className="icon tabIcon tabeActiveClass"><img src={phone} /><span> Call </span></a>
                     <button>Disable</button> 
                     <div className="hospBox">
                       <div className="priceCover">
                         <label>Price per minute</label>
                         <input placeholder="Price" className="popField" type="text" />
                         <label>Price per session</label>
                         <input placeholder="Price" className="popField" type="text" />
                       </div>
                       <span className="changeLink"> Change Price</span>	
                     </div>
                   </li> 
                </ul>
              </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    )
  }
}
