import React, { Component } from 'react';
import Header from "../layout/header"
import Footer from "../layout/footer"
import AboutTakeNextStep from './about-take-next-step';
import Newslist from './news-list';
export default class Resources extends Component {
  componentDidMount(){
    document.getElementById("success-menu").classList.add("active");
  }

  render() {
    return (
      <div className="ph-main-wrapper">
          <Header></Header>
          <Newslist/>
          <AboutTakeNextStep/>
          <Footer></Footer>
      </div>
    )
  }
}
