import React, { Component } from 'react';
import Header from "../layout/header"
import Footer from "../layout/footer"
import DigitalBnner from "../home/digital-branding-banner"
import DigitalSolutions from "../home/digital-branding-solutions"
import DigitalServices from "../home/digital-branding services"
import DigitalSuccess from "../home/digital-branding-success-stories"
import AboutTakeNextStep from './about-take-next-step';
export default class digitalBranding extends Component {
  componentDidMount(){
    document.getElementById("solution-menu").classList.add("active");
  }

  render() {
    return (
      <div className="ph-main-wrapper">
          <Header></Header>
            <DigitalBnner/>
         <DigitalSolutions/>
         <DigitalServices/>
         <DigitalSuccess/>
         <AboutTakeNextStep/>
          <Footer></Footer>
      </div>
    )
  }
}
