import React, { Component, Suspense } from "react";
// import Doctor from "./doctor";
import Pagination from "react-js-pagination";
import $ from "jquery";
import { apiCalling, awsAPIUrlcommon } from "../apiService";
import moment from "moment";
import * as XLSX from "xlsx";
import HospitalAutoComplete from "../adminManagement/messaging/doctorPanenl/HospitalAutoComplete";
import HospitalAutoCompleteSiteId from "../adminManagement/messaging/doctorPanenl/HospitalSiteIdAoutoComplete";
import SiteAutoComplite from "../adminDashboard/siteAutoComplete";
import Header from "../layout/header";
import Footer from "../layout/footer";
import Uploading from "../image/media/uploading.gif";
var totalCountOfFileRemaining = 0;
let intervalId = null;
const { convertExcelToJson } = require("convert-excel-to-json");
class doctorPanel extends Component {

  state = {
    docMsgSearchData: [],
    activePage: 1,
    max: 12,
    offset: 0,
    totalCount: 0,
    tempHospitalData: { hospitalId: "", hospitalName: "" },
    tempSiteData: { siteId: 0, siteName: "" },
    tempDoctorData: { doctorId: 0, doctorName: "" },
    tempSpecialityData: { hospitalId: "", hospitalName: "" },
    chatStatus: 1,
    uploadedFile: {},
    exportReportsData: [],
    tempHospitalDataSiteId: "",
    mrnoForGenerating: "",
    mrnoForGeneratinglastMrNo: "",
    totalCountOfFileRemainingState: "",
    SuccessfullyUploadedCount: "",
    totalCountOfUploadedFile: "",
    flag: false
  };
  componentDidMount() {
    this.getExcelUploadedData();
  }
  handlePageChange = (pageNumber) => {
    window.scrollTo(0, 0);
    this.setState({ activePage: pageNumber });
    var offset;
    var max;
    if (pageNumber === "1") {
      offset = "0";
      max = "10";
      this.setState({
        activePage: pageNumber,
        isLoading: "0",
      });
    } else {
      offset = parseInt(pageNumber - 1) * 10;
      max = "10";
      this.setState({
        activePage: pageNumber,
        isLoading: "0",
      });
    }
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "patientImportData",
        max: max,
        Offset: JSON.stringify(offset),
      }),
    };
    $(".loader").show();
    var res = awsAPIUrlcommon(apiJson).then((data) => {
      $(".loader").hide();
      if (data.success === "1") {
        this.setState(
          {
            exportReportsData: data.result.patientImportDetails,
            totalCount: data.result.totalCount,
          },
          () => {
            $(".loader").hide();
          }
        );
      }
      if (data.success === "0") {
        $(".loader").hide();
        this.setState(
          {
            exportReportsData: [],
            totalCount: data.result.totalCount,
          },
          () => {
            $(".loader").hide();
          }
        );
      }
    });
  };
  getExcelUploadedData = async () => {
    $(".loader").hide();
    const getDoctorMessaginglist = {
      method: "POST",
      body: JSON.stringify({
        functionName: "patientImportData",
        max: this.state.max,
        Offset: this.state.offset,
      }),
    };
    var res = await awsAPIUrlcommon(getDoctorMessaginglist).then((data) => {
      if (data.success === "1") {
        this.setState(
          {
            exportReportsData: data.result.patientImportDetails,
            totalCount: data.result.totalCount,
          },
          () => {
            $(".loader").hide();
          }
        );
      } else if (data.success === "0") {
        this.setState({ patientImportDetails: [], totalCount: 0 }, () => {
          $(".loader").hide();
        });
      }
    });
  };
  recallingapiSave = (e) => {
    const doctorPanelMsgDisable = {
      method: "POST",
      body: JSON.stringify({
        functionName: "patientImportMrNoCreation",
        hospitalId: e.hospitalId ? e.hospitalId : "",
        memberId: "0",
      }),
    };
    apiCalling(doctorPanelMsgDisable).then((data) => {
      if (data.success === "1") {
        this.setState({
          tempHospitalData: e,
          mrnoForGenerating: data.mrNo,
          mrnoForGeneratinglastMrNo: data.lastMrNo,
        });
      }
    });
  };
  saveDoctorChatSetting = async () => {
    if (document.getElementById("uploadProcedureCSVFile").value == "") {
      alert("Please Choose File First");
      return false;
    } else if (
      this.state.tempHospitalData.hospitalId &&
      this.state.tempSiteData.siteId
    ) {
      $(".loader").show();
      const doctorChatSetting = {
        method: "POST",
        body: JSON.stringify({
          functionName: "patientImport",
          hospitalId: this.state.tempHospitalData.hospitalId,
          siteId: this.state.tempSiteData.siteId,
          patientArray: this.state.uploadedFile,
        }),
      };
      ////////////////////////
      try {
        apiCalling(doctorChatSetting).then((data) => {
          $(".loader").hide();
          if (data.success === "1") {
            this.updatePatientList(data.patientImportDateTime)
            this.setState({
              flag: true,
            });
            setTimeout(() => {
              this.startInterval(data.patientImportDateTime);
            }, 8000); // Delay of 5 seconds

          }
        })
      } catch (error) { }
      ////////////////////////////////////////////////////////////////
    } else {
      alert("Please Choose Hospital And Site First");
      document.getElementById("uploadProcedureCSVFile").value = "";
    }
  };
  stopInterval = () => {
    clearInterval(intervalId);
  };
  closepopup() {
    $("#popup1").hide();
    $(".popOverlay").css("background-color", "transparent");
    window.location.reload();
  }
  handleHospitalAutoFill = (e) => {
    this.setState({
      tempHospitalData: e,
      tempSpecialityData: e,
    });
    if (!e == "") {
      this.recallingapiSave(e);
    }
  };
  handleSpecialityAutoFill = (e) => {
    this.setState({ tempSiteData: e });
  };
  handleSpecialityAutoFillSiteId = (e) => {
    this.setState({
      tempSpecialityData: e,
      tempHospitalData: e,
    });
    if (!e == "") {
      this.recallingapiSave(e);
    }
  };
  handleFileUpload = (event) => {
    if (
      this.state.tempHospitalData.hospitalId &&
      this.state.tempSiteData.siteId
    ) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        let worksheetData = [];
        json.slice(1).map((item) => {
          if (item != "" && item != undefined && item != null) {
            var incrementConvertion = this.state.mrnoForGenerating
              .toString()
              .replace(/(\d*)$/, (_, t) =>
                (+t + 1).toString().padStart(t.length, 0)
              );
            var lastMrNoConvertion = this.state.mrnoForGeneratinglastMrNo
              .toString()
              .replace(/(\d*)$/, (_, t) =>
                (+t + 1).toString().padStart(t.length, 0)
              );
            this.setState({
              mrnoForGenerating: incrementConvertion,
              mrnoForGeneratinglastMrNo: lastMrNoConvertion,
            });
            var formattedDate = "";
            if (item[4] && item[4].length > 8) {
              const parts = item[4].split("-");
              const year = parts[2];
              const month = parts[1];
              const day = parts[0];
              formattedDate = `${year}-${month}-${day}`;
              formattedDate = moment(formattedDate).format("YYYY-MM-DD");
            } else if (item[4]) {
              formattedDate = moment(
                new Date(new Date().getTime() - item[4] * 31556926000)
              ).format("YYYY-MM-DD");
            } else {
              formattedDate = "";
            }
            worksheetData.push({
              firstName: item[0] ? item[0] : "",
              lastName: item[1] ? item[1] : "",
              secUserEmailId: item[2] ? item[2] : "",
              secUserMobileNumber: item[3] ? item[3].toString() : "",
              memberDateOfBirth: formattedDate,
              // memberDateOfBirth: item[4] ?(moment(new Date(item[4].split("-")[2], parseInt(item[4].split("-")[1]) - 1, item[4].split("-")[0]))).format("YYYY-MM-DD"):"",
              memberGender: item[5] ? item[5] : "",
              memberAddress: item[6] ? item[6] : "",
              memberCity: item[7] ? item[7] : "",
              memberCountry: item[9] ? item[9] : "",
              memberState: item[8] ? item[8] : "",
              memberZipCode: item[10] ? item[10].toString() : "",
              mrNo: item[11] ? item[11].toString() : "",
              memberMrNo: incrementConvertion,
              // memberMrNo: this.state.mrnoForGenerating++,
              lastMrNo: lastMrNoConvertion,
            });
          }
        });
        this.setState({
          uploadedFile: worksheetData,
        });
        // this.saveDoctorChatSetting(worksheetData);
      };
      reader.readAsArrayBuffer(file);
    } else {
      alert("Please Choose Hospital And Site First");
      document.getElementById("uploadProcedureCSVFile").value = "";
      return false;
    }
  };
  ExportDataValues = (props) => {
    const csvRow = [];
    var A = [
      [
        "First%20Name",
        "Last%20Name",
        "Email%20Id",
        "Mobile%20Number",
        "DOB(DD-MMM-YYYY)",
        "Gender",
        "Address",
        "City",
        "State",
        "Country",
        "Pin Code",
        "MR NO",
      ],
    ];
    for (var i = 0; i < A.length; ++i) {
      csvRow.push(A[i].join(","));
    }
    var csvString = csvRow.join("%0A");
    var a = document.createElement("a");
    a.href = "data:attachment/csv," + csvString;
    a.target = "_Blank";
    a.download = "Patient Report " + ".csv";
    document.body.appendChild(a);
    a.click();
  };
  handleSpecialitySearch = (e) => {
    this.setState({
      tempSiteData: {
        siteId: "",
        siteName: e,
      },
    });
  };
  handleHospitalSearch = (e) => {
    if (e == "") {
      this.setState({
        tempSpecialityData: {
          hospitalId: "",
          hospitalName: "",
        },
      });
    }
    this.setState({
      tempHospitalData: {
        hospitalId: "",
        hospitalName: e,
      },
    });
  };
  handleSpecialitySearchSiteId = (e) => {
    if (e == "") {
      this.setState({
        tempHospitalData: {
          hospitalId: 0,
          hospitalName: "",
        },
      });
    }
    if (!e == "") {
      // this.recallingapiSave(e)
    }
    this.setState({
      tempSpecialityData: {
        hospitalId: e,
        hospitalName: "",
      },
    });
  };
  updatePatientList = async (currentDate) => {
    // const currentDate = moment().format('YYYY-M-D H:mm:ss');
    const doctorPanelMsgDisable = {
      method: "POST",
      body: JSON.stringify(
        {
          "functionName": "patientImportUpdate",
          "patientImportDateTime": currentDate,
          hospitalId: this.state.tempHospitalData.hospitalId,
          siteId: this.state.tempSiteData.siteId,
        })
    };
    try {
      var res = await apiCalling(doctorPanelMsgDisable);
    } catch (error) { }
  }
  getPatientImportStatus = (currentDate) => {
    const doctorPanelMsgDisable = {
      method: "POST",
      body: JSON.stringify(
        {
          "functionName": "getPatientImportStatus",
          "patientImportDateTime": currentDate
        })
    };
    apiCalling(doctorPanelMsgDisable).then((data) => {
      if (data.success === "1") {
        if (data.result.patientImportTotalCount == (data.result.patientImportTotalSuccessCount + data.result.patientImportTotalAlreadyInsertedCount)) {
          this.stopInterval();
        }
        this.setState({
          flag: false,
          totalCountOfUploadedFile: data.result.patientImportTotalCount,
          SuccessfullyUploadedCount: data.result.patientImportTotalSuccessCount,
        });
      }
    });
  }
  startInterval = (currentTime) => {
    intervalId = setInterval(() => {
      this.getPatientImportStatus(currentTime);
    }, 10000); // Call the API every 10 seconds
  };
  componentWillUnmount() {
    this.stopInterval();
  }
  render() {
    return (
      <div className="purpleWrap bg-clr-wthHead">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
            <div className="container mb-5">
              <div>
                <ul className="breadcrum-adminMangmnt">
                  <li>
                    <a href="/admin/dashboard">Dashboard</a>
                  </li>
                  <li>
                    <a className="current">Patient Reports</a>
                  </li>
                </ul>
              </div>
              <div className="head-hospMangmntList">Patient Report<span className="countList">
                {this.state.flag == true ?
                  <span className="countListnames"> Status is updating...</span> : ""}
                {this.state.totalCountOfUploadedFile ?
                  <span className="countListnames">Total Patient Count: {this.state.totalCountOfUploadedFile}</span> : ""}
                {this.state.SuccessfullyUploadedCount ?
                  <span className="countListnames ml-2">Uploaded Count:{this.state.SuccessfullyUploadedCount}</span> : ""}
              </span></div>
              <div className="row tableHeader-HspMangemnt table-headerDctrPanel tble-dctrpanel-mob">
                <div className="col-md-3 dctrpanel-headinput">
                  <HospitalAutoComplete
                    onItemChange={this.handleHospitalAutoFill}
                    setSearch={this.handleHospitalSearch}
                    tempHospitalData={this.state.tempHospitalData}
                  />
                </div>
                <div className="col-md-2 dctrpanel-headinput">
                  <HospitalAutoCompleteSiteId
                    onItemChange={this.handleSpecialityAutoFillSiteId}
                    setSearch={this.handleSpecialitySearchSiteId}
                    tempSpecialityData={this.state.tempSpecialityData}
                  />
                </div>
                <div className="col-md-2 dctrpanel-headinput">
                  <SiteAutoComplite
                    onItemChange={this.handleSpecialityAutoFill}
                    setSearch={this.handleSpecialitySearch}
                    tempSiteData={this.state.tempSiteData}
                  />
                </div>
                <div className="col-md-2">
                  <input
                    type="file"
                    id="uploadProcedureCSVFile"
                    name="file1"
                    onChange={(e) => {
                      this.handleFileUpload(e);
                    }}
                    accept=".csv,.xlsx,xls"
                    className="upload_upload"
                  />
                </div>
                <div className="col-md-1">
                  <button
                    className="searchbtn-hspMng"
                    type="file"
                    // id="uploadProcedureCSVFile"
                    onClick={(e) => {
                      this.saveDoctorChatSetting(e);
                    }}
                  >
                    Save
                  </button>
                </div>
                <div className="col-md-1">
                  <button
                    className="searchbtn-hspMng export_excel"
                    type="file"
                    // id="uploadProcedureCSVFile"
                    onClick={(e) => {
                      this.ExportDataValues(e);
                    }}
                  >
                    Export Excel
                  </button>
                </div>
              </div>
              {this.state.exportReportsData.length > 0 && (
                <div className="row">
                  <table className="table table-bordered-hsp table-responsive">
                    <thead>
                      <tr>
                        <th className="tablehead-hspmng" width="30%">
                          First Name
                        </th>
                        <th className="tablehead-hspmng" width="30%">
                          Last Name
                        </th>
                        <th className="tablehead-hspmng" width="20%">
                          Email Id
                        </th>
                        <th className="tablehead-hspmng" width="10%">
                          Mobile Number
                        </th>
                        <th className="tablehead-hspmng" width="10%">
                          DOB(DD-MM-YYYY)
                        </th>
                        <th className="tablehead-hspmng" width="10%">
                          Gender
                        </th>
                        <th className="tablehead-hspmng" width="10%">
                          Address
                        </th>
                        <th className="tablehead-hspmng" width="10%">
                          City
                        </th>
                        <th className="tablehead-hspmng" width="10%">
                          State
                        </th>
                        <th className="tablehead-hspmng" width="10%">
                          Country
                        </th>
                        <th className="tablehead-hspmng" width="10%">
                          Pincode
                        </th>
                        <th className="tablehead-hspmng" width="10%">
                          Mr No
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.exportReportsData.map((data, i) => (
                        <tr key={i}>
                          <td>{data.firstName}</td>
                          <td>{data.lastName}</td>
                          <td>{data.emailId}</td>
                          <td>{data.mobileNumber}</td>
                          <td>
                            {" "}
                            {data.dob
                              ? moment(data.dob).format("DD-MM-YYYY")
                              : ""}
                          </td>
                          <td>{data.gender}</td>
                          <td>{data.address}</td>
                          <td>{data.city}</td>
                          <td>{data.state}</td>
                          <td>{data.country}</td>
                          <td>{data.pincode}</td>
                          <td>{data.mrno}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {this.state.totalCount > this.state.max ? (
                    <div className="paginationSection orderm-paginationSection pagination-HspList pagination-HspListwidth p-3">
                      <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.state.max}
                        totalItemsCount={this.state.totalCount}
                        pageRangeDisplayed={10}
                        onChange={(pageNumber) =>
                          this.handlePageChange(pageNumber)
                        }
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {/* </div> */}
                </div>
              )}

              <div className="popBack" id="popup1">
                <div className="popOverlay" onClick={this.closepopup}></div>
                <div className="customizationPop">
                  <input type="hidden" id="qualificationId" />
                  {/* <div className="popuphead-qualifctn">
                            <h2>Qualification Details</h2>
                            </div> */}
                  <div className="patientupload_popup">
                    <img
                      id="UploadingLoader"
                      src={Uploading}
                      alt="Uploading"
                      width={135}
                    />
                    <h3 className="popup-successmsg" hidden id="SuccessMessage">
                      Your data has been saved successfully and will be
                      reflected soon.
                    </h3>
                  </div>
                  <div className="uploadtext-popup">
                    Remaining-{this.state.totalCountOfFileRemainingState}{" "}
                  </div>

                  <div className="popupbutndiv text-right">
                    <button
                      className="butncancel-qual"
                      hidden
                      id="cancelButton"
                      onClick={() => this.closepopup()}
                    >
                      Ok
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Suspense>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
export default doctorPanel;
