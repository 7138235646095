import React, { Component } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import Dashboardleftmenu from "./dashboardleftmenu";
import { apiCalling } from "../apiService";
import $ from "jquery";
export default class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drCurrentPassword: "",
      drNewPassword: "",
      drConfirmPassword: "",
      drMessages: "",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.submitPassword = this.submitPassword.bind(this);
  }
  handleInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };
  submitPassword() {
    let drCurrentPassword = this.state.drCurrentPassword;
    let drNewPassword = this.state.drNewPassword;
    let drConfirmPassword = this.state.drConfirmPassword;
    let flags = false;
    let msg = "";
    if (!drCurrentPassword) {
      msg = "Please enter your current password properly";
    } else if (!drNewPassword) {
      msg = "Please enter your new password properly";
    } else if (!drConfirmPassword) {
      msg = "Please enter your confirm password properly";
    } else if (drNewPassword != drConfirmPassword) {
      msg = "New password anf confirm password are not matched ";
    } else {
      flags = true;
    }
    if (flags == false) {
      $("#drmessagebloc").css("color", "red");
      this.setState({ drMessages: msg });
    } else {
      const apiJson = {
        method: "POST",
        body: JSON.stringify({
          functionName: "changePassword",
          userId: localStorage.getItem("userId"),
          siteId: localStorage.getItem("siteId"),
          bundleId: "",
          currentPassword: drCurrentPassword,
          newPassword: drConfirmPassword,
        }),
      };
      apiCalling(apiJson).then((data) => {
        if (data.success == "1") {
          alert("Password reset successfully");
        }
      });
    }
  }
  render() {
    return (
      <div className="purpleWrap">
        <Header></Header>
        <div className="container container-auto">
          <div className="main-wrapper1">
            <div className="row">
              <div className="col-lg-4">
                <Dashboardleftmenu></Dashboardleftmenu>
              </div>
              <div className="col-lg-8">
                <div className="memberHead">
                  <h4>Change Password</h4>
                </div>
                <div className="messageCenterDiv">
                  <h5 className="py-4 text-muted">
                    Provide your old password & your new password
                  </h5>
                  <h6 className="py-4 text-muted red " id="drmessagebloc">
                    {this.state.drMessages}
                  </h6>

                  <div className="row row-margin px-3">
                    <label className="col-sm-12 label-text">Current Password</label>
                    <div className="col-sm-6">
                      <input
                        type="password"
                        name="drCurrentPassword"
                        id="drCurrentPassword"
                        className="input-design"
                        placeholder="Type your Current Password"
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="row row-margin px-3">
                    <label className="col-sm-12 label-text">New Password</label>
                    <div className="col-sm-6">
                      <input
                        type="password"
                        className="input-design"
                        name="drNewPassword"
                        id="drNewPassword"
                        placeholder="New Password"
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="row row-margin px-3">
                    <label className="col-sm-12 label-text">Confirm Password</label>
                    <div className="col-sm-6">
                      <input
                        type="password"
                        className="input-design"
                        placeholder="Confirm Password"
                        name="drConfirmPassword"
                        id="drConfirmPassword"
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>

                  <div className="row px-4">
                    <a href="#">
                      <input
                        type="submit"
                        value="Change Password"
                        className="formButton"
                        onClick={() => this.submitPassword()}
                      ></input>
                    </a>
                    <input
                      type="button"
                      value="cancel"
                      className="formButton cancelButton"
                    ></input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer></Footer>
      </div>
    );
  }
}
