import React, { useState, useEffect } from "react";
import Closebtn from "../../image/icons/close-sm.png";
import AddImg from "../../image/icons/addbtn.png";
import $ from "jquery";

const EducationDetails =React.forwardRef((props, ref) => {
  const [educationDetailsArray, setEducationDetailsArray] = useState([]);
  const [educationData, setEducationData] = useState([]);
  const [year, setYear] = useState([]);

  useEffect(() => {
    setEducationData(props.getHospitalDropdown.education);

    if (props.educationData.length > 0) {
      setEducationDetailsArray(props.educationData);
      props.educationData.forEach((_, i) => yearDropdown(i));
    }

    yearDropdown("");
    yearDropdownDefault();
  }, [props?.educationData, props?.getHospitalDropdown?.education]);

  const yearDropdown = (index) => {
    const ddlYears = document.getElementById("cmbYear" + index);
    const currentYear = new Date().getFullYear();
    if (ddlYears) {
      ddlYears.innerHTML = "";
      const option1 = document.createElement("OPTION");
      option1.innerHTML = "Select";
      option1.value = "0";
      ddlYears.appendChild(option1);
      for (let i = currentYear; i >= 1950; i--) {
        const option = document.createElement("OPTION");
        option.innerHTML = i;
        option.value = i;
        ddlYears.appendChild(option);
      }
    }
  };

  const yearDropdownDefault = () => {
    const currentYear = new Date().getFullYear();
    const yearArray = ["Select"];
    for (let i = currentYear; i >= 1950; i--) {
      yearArray.push(i);
    }
    setYear(yearArray);
  };

  const addEducationDetails = () => {
    if ($("#cmbEducation").val() === "0") {
      alert("Education Required");
      return false;
    }

    for (let i = 0; i <= educationDetailsArray.length; i++) {
      if ($("#cmbEducation" + i).val() === "0") {
        alert("Education Required");
        $("#cmbEducation" + i).focus();
        return;
      }
    }

    const newEducation = {
      Id: $("#cmbEducation").val(),
      year: $("#cmbYear").val(),
      college: $("#txtCollege").val()
    };

    setEducationDetailsArray([...educationDetailsArray, newEducation]);

    $("#cmbEducation").val('0');
    $("#cmbYear").val('0');
    $("#txtCollege").val('');
  };

  const setEducationDataState = (value, name, i, mode) => {
    const newEducationDetailsArray = [...educationDetailsArray];
    if (mode === "cmbEducation") {
      newEducationDetailsArray[i].Id = $("#" + mode + i).val();
    } else if (mode === "cmbYear") {
      newEducationDetailsArray[i].year = $("#" + mode + i).val();
    } else if (mode === "txtCollege") {
      newEducationDetailsArray[i].college = $("#" + mode + i).val();
    }
    setEducationDetailsArray(newEducationDetailsArray);
  };

  const removeEducationDetails = (Id) => {
    const updatedEducationDetailsArray = educationDetailsArray.filter(e => e.Id !== Id);
    setEducationDetailsArray(updatedEducationDetailsArray);
  };
  React.useImperativeHandle(ref, () => ({
    education: () => {
      return {
        EducationDetails:educationDetailsArray
      };
    }
  }));
  return (
    <div className="bg-clr-wthHead mb-4">
      <section>
        <div className="consultngTime-wrapper">
          <h2 className="hspInfoSubhead">Education Details</h2>
          <div className="hsptaltimeHead">
            <div className="educationdoctr-contentdiv">
              <label className="educationdoctrlabelwidth">Education <span className="hspifoMantory">*</span></label>
              <select id="cmbEducation" name="cmbEducation" className="hspInfofieldinput eductninputwidthdctr">
                <option value="0">Select</option>
                {educationData.map((eduObj) => (
                  <option key={eduObj.educationCategoryId} value={eduObj.educationCategoryId}>{eduObj.educationCategory}</option>
                ))}
              </select>
            </div>
            <div className="hsptimefieldDiv hsptimefieldDivMob-time">
              <label className="educationlabelwidthyear">Year</label>
              <select name="cmbYear" id="cmbYear"></select>
            </div>
            <div className="hsptimefieldDiv pl-2 hsptimefieldDivMob-time">
              <label className="educationlabelwidthdctr">College</label>
              <input type="text" id="txtCollege" name="txtCollege" className="hspInfofieldinput eductninputwidthdctr" placeholder="College" />
            </div>
            <div className="addimgeDivHsp">
              <img src={AddImg} alt="Add" onClick={addEducationDetails} />
            </div>
          </div>
          {educationDetailsArray.map((Obj, i) => (
            <div key={Obj.Id} className="hsptaltimeHead">
              <div className="educationdoctr-contentdiv">
                <label className="educationdoctrlabelwidth">Education <span className="hspifoMantory">*</span></label>
                <select id={"cmbEducation" + i} name={"cmbEducation" + i} value={Obj.Id} className="hspInfofieldinput eductninputwidthdctr" onChange={(e) => setEducationDataState(e.target.value, "cmbEducation" + i, i, "cmbEducation")}>
                  <option value="0">Select</option>
                  {educationData.map((eduObj) => (
                    <option key={eduObj.educationCategoryId} value={eduObj.educationCategoryId}>{eduObj.educationCategory}</option>
                  ))}
                </select>
              </div>
              <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                <label className="educationlabelwidthyear">Year</label>
                <select name={"cmbYear" + i} id={"cmbYear" + i} value={Obj.year} onChange={(e) => setEducationDataState(e.target.value, "cmbYear" + i, i, "cmbYear")}>
                  {year.map((yearObj) => (
                    <option key={yearObj} value={yearObj}>{yearObj}</option>
                  ))}
                </select>
              </div>
              <div className="hsptimefieldDiv pl-2 hsptimefieldDivMob-time">
                <label className="educationlabelwidthdctr">College</label>
                <input type="text" value={Obj.college} id={"txtCollege" + i} name={"txtCollege" + i} className="hspInfofieldinput eductninputwidthdctr" placeholder="College" onChange={(e) => setEducationDataState(e.target.value, "txtCollege" + i, i, "txtCollege")} />
              </div>
              <div className="addimgeDivHsp">
                <img src={Closebtn} alt="Close" onClick={() => removeEducationDetails(Obj.Id)} width="20" />
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}
)

export default EducationDetails;
