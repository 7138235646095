import React, { Component, Suspense } from "react";
import OfficeTime from "../doctorManagementList/doctorOfficeTime";
import { apiCalling } from "../../apiService";
import $ from "jquery";
export default class HospitalworkDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accreditation: [],
      insurance: [],
    };
  }
  componentDidMount() {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({ 
        functionName: "getHospitalDropdown",
      }), 
    };
    if (this.props.HospitalworkData.length === 0) {
    
      $(".loader").show();
    }else{
      // $(".loader").hide();
    }
      apiCalling(apiJson).then((data) => {
        if (data.success === "1") {
          this.setState(
            {
              accreditation: data.hospitalData[0].accreditation,
              insurance: data.hospitalData[0].insurance,
            },
            () => {
         
              if (this.props.HospitalworkData.length > 0) {
        
                $("#sh1FriFrom").val(this.props.HospitalworkData[0].fridayFrom);
                $("#sh1FriTo").val(this.props.HospitalworkData[0].fridayTo);
                $("#sh1MonFrom").val(this.props.HospitalworkData[0].mondayFrom);
                $("#sh1MonTo").val(this.props.HospitalworkData[0].mondayTo);
                $("#sh1SatFrom").val(this.props.HospitalworkData[0].saturdayFrom);
                $("#sh1SatTo").val(this.props.HospitalworkData[0].saturdayTo);
                $("#sh1SunFrom").val(this.props.HospitalworkData[0].sundayFrom);
                $("#sh1SunTo").val(this.props.HospitalworkData[0].sundayTo);
                $("#sh1ThurFrom").val(
                  this.props.HospitalworkData[0].thursdayFrom
                );
                $("#sh1ThurTo").val(this.props.HospitalworkData[0].thursdayTo);
                $("#sh1TueFrom").val(this.props.HospitalworkData[0].tuesdayFrom);
                $("#sh1TueTo").val(this.props.HospitalworkData[0].tuesdayTo);
                $("#sh1WedFrom").val(
                  this.props.HospitalworkData[0].wednesdayFrom
                );
                $("#sh1WedTo").val(this.props.HospitalworkData[0].wednesdayTo);
                $("#accreditationIds").val(this.props.accreditationIdEdit);
                $("#insuranceIds").val(this.props.insuranceIdEdit);
              }
       
            }
          );
        }
      });


 
  }
  render() {
    return (
      <div className="purpleWrap bg-clr-wthHead">
        <Suspense fallback={<div></div>}>
        <div className="loader"></div>
        {/* className="hspworkdetails-wrappermargin" */}
          <section className="hspworkdetails-wrapper-margintop" >
            <div className="hspworkdetails-wrapper pb-5 pt-3 mb-5 mt-4">
              <h2 className="hspInfoSubhead pb-3">Hospital Work Details</h2>
              <div className="hspwrkdetal-ofcehour mb-5">
                <h4 className="officehrsHead">
                  Office Hours<span className="hspifoMantory">*</span>
                </h4>
                <div>
                  <div className="offcehrDaydiv doctroffcehrDaydiv hspoffcehrDay-widthfromto">
                    <label>Monday</label>
                    <OfficeTime
                      idValue="sh1MonFrom"
                      nameValue="sh1"
                      timeType="From"
                    ></OfficeTime>
                    <OfficeTime
                      idValue="sh1MonTo"
                      nameValue="sh1"
                      timeType="To"
                    ></OfficeTime>
                  </div>
                  <div className="offcehrDaydiv doctroffcehrDaydiv hspoffcehrDay-widthfromto">
                    <label>Tuesday</label>
                    <OfficeTime
                      idValue="sh1TueFrom"
                      nameValue="sh1"
                      timeType="From"
                    ></OfficeTime>
                    <OfficeTime
                      idValue="sh1TueTo"
                      nameValue="sh1"
                      timeType="To"
                    ></OfficeTime>
                  </div>
                  <div className="offcehrDaydiv doctroffcehrDaydiv hspoffcehrDay-widthfromto">
                    <label>Wednesday</label>
                    <OfficeTime
                      idValue="sh1WedFrom"
                      nameValue="sh1"
                      timeType="From"
                    ></OfficeTime>
                    <OfficeTime
                      idValue="sh1WedTo"
                      nameValue="sh1"
                      timeType="To"
                    ></OfficeTime>
                  </div>
                  <div className="offcehrDaydiv doctroffcehrDaydiv hspoffcehrDay-widthfromto">
                    <label>Thursday</label>
                    <OfficeTime
                      idValue="sh1ThurFrom"
                      nameValue="sh1"
                      timeType="From"
                    ></OfficeTime>
                    <OfficeTime
                      idValue="sh1ThurTo"
                      nameValue="sh1"
                      timeType="To"
                    ></OfficeTime>
                  </div>
                  <div className="offcehrDaydiv doctroffcehrDaydiv hspoffcehrDay-widthfromto">
                    <label>Friday</label>
                    <OfficeTime
                      idValue="sh1FriFrom"
                      nameValue="sh1"
                      timeType="From"
                    ></OfficeTime>
                    <OfficeTime
                      idValue="sh1FriTo"
                      nameValue="sh1"
                      timeType="To"
                    ></OfficeTime>
                  </div>
                  <div className="offcehrDaydiv doctroffcehrDaydiv hspoffcehrDay-widthfromto">
                    <label>Saturday</label>
                    <OfficeTime
                      idValue="sh1SatFrom"
                      nameValue="sh1"
                      timeType="From"
                    ></OfficeTime>
                    <OfficeTime
                      idValue="sh1SatTo"
                      nameValue="sh1"
                      timeType="To"
                    ></OfficeTime>
                  </div>
                  <div className="offcehrDaydiv doctroffcehrDaydiv hspoffcehrDay-widthfromto">
                    <label>Sunday</label>
                    <OfficeTime
                      idValue="sh1SunFrom"
                      nameValue="sh1"
                      timeType="From"
                    ></OfficeTime>
                    <OfficeTime
                      idValue="sh1SunTo"
                      nameValue="sh1"
                      timeType="To"
                    ></OfficeTime>
                  </div>
                  {/* <div className="offcehrDaydiv">
        <label>Monday</label>
        <div className="offcehrDay">
        <select name="mondayFrom" id="mondayFrom" >
        <option selected="">From</option>
        <option value="1">01:00 AM</option>
        <option value="25">01:30 AM</option>
        <option value="2">02:00 AM</option>
        <option value="26">02:30 AM</option>
        <option value="3">03:00 AM</option>
        <option value="27">03:30 AM</option>
        <option value="4">04:00 AM</option>
        <option value="28">04:30 AM</option>
        <option value="5">05:00 AM</option>
        <option value="29">05:30 AM</option>
        <option value="6">06:00 AM</option>
        <option value="30">06:30 AM</option>
        <option value="7">07:00 AM</option>
        <option value="31">07:30 AM</option>
        <option value="8">08:00 AM</option>
        <option value="32">08:30 AM</option>
        <option value="9">09:00 AM</option>
        <option value="33">09:30 AM</option>
        <option value="10">10:00 AM</option>
        <option value="34">10:30 AM</option>
        <option value="11">11:00 AM</option>
        <option value="35">11:30 AM</option>
        <option value="12">12:00 AM</option>
        <option value="36">12:30 AM</option>
        <option value="13">01:00 PM</option>
        <option value="37">01:30 PM</option>
        <option value="14">02:00 PM</option>
        <option value="38">02:30 PM</option>
        <option value="15">03:00 PM</option>
        <option value="39">03:30 PM</option>
        <option value="16">04:00 PM</option>
        <option value="40">04:30 PM</option>
        <option value="17">05:00 PM</option>
        <option value="41">05:30 PM</option>
        <option value="18">06:00 PM</option>
        <option value="42">06:30 PM</option>
        <option value="19">07:00 PM</option>
        <option value="43">07:30 PM</option>
        <option value="20">08:00 PM</option>
        <option value="44">08:30 PM</option>
        <option value="21">09:00 PM</option>
        <option value="45">09:30 PM</option>
        <option value="22">10:00 PM</option>
        <option value="46">10:30 PM</option>
        <option value="23">11:00 PM</option>
        <option value="47">11:30 PM</option>
        <option value="24">12:00 PM</option>
        <option value="48">12:30 PM</option>
        <option value="49">12:00 AM </option>
        </select>
        </div>
        <div className="offcehrDay">
        <select name="mondayTo" id="mondayTo" >
        <option selected="">To</option>
        <option value="1">01:00 AM</option>
        <option value="25">01:30 AM</option>
        <option value="2">02:00 AM</option>
        <option value="26">02:30 AM</option>
        <option value="3">03:00 AM</option>
        <option value="27">03:30 AM</option>
        <option value="4">04:00 AM</option>
        <option value="28">04:30 AM</option>
        <option value="5">05:00 AM</option>
        <option value="29">05:30 AM</option>
        <option value="6">06:00 AM</option>
        <option value="30">06:30 AM</option>
        <option value="7">07:00 AM</option>
        <option value="31">07:30 AM</option>
        <option value="8">08:00 AM</option>
        <option value="32">08:30 AM</option>
        <option value="9">09:00 AM</option>
        <option value="33">09:30 AM</option>
        <option value="10">10:00 AM</option>
        <option value="34">10:30 AM</option>
        <option value="11">11:00 AM</option>
        <option value="35">11:30 AM</option>
        <option value="12">12:00 AM</option>
        <option value="36">12:30 AM</option>
        <option value="13">01:00 PM</option>
        <option value="37">01:30 PM</option>
        <option value="14">02:00 PM</option>
        <option value="38">02:30 PM</option>
        <option value="15">03:00 PM</option>
        <option value="39">03:30 PM</option>
        <option value="16">04:00 PM</option>
        <option value="40">04:30 PM</option>
        <option value="17">05:00 PM</option>
        <option value="41">05:30 PM</option>
        <option value="18">06:00 PM</option>
        <option value="42">06:30 PM</option>
        <option value="19">07:00 PM</option>
        <option value="43">07:30 PM</option>
        <option value="20">08:00 PM</option>
        <option value="44">08:30 PM</option>
        <option value="21">09:00 PM</option>
        <option value="45">09:30 PM</option>
        <option value="22">10:00 PM</option>
        <option value="46">10:30 PM</option>
        <option value="23">11:00 PM</option>
        <option value="47">11:30 PM</option>
        <option value="24">12:00 PM</option>
        <option value="48">12:30 PM</option>
        <option value="49">12:00 AM </option>
        </select>
        </div>
        </div>
        <div className="offcehrDaydiv">
        <label>Tuesday</label>
        <div className="offcehrDay">
        <select name="tuesdayFrom" id="tuesdayFrom" >
        <option selected="">From</option>
        <option value="1">01:00 AM</option>
        <option value="25">01:30 AM</option>
        <option value="2">02:00 AM</option>
        <option value="26">02:30 AM</option>
        <option value="3">03:00 AM</option>
        <option value="27">03:30 AM</option>
        <option value="4">04:00 AM</option>
        <option value="28">04:30 AM</option>
        <option value="5">05:00 AM</option>
        <option value="29">05:30 AM</option>
        <option value="6">06:00 AM</option>
        <option value="30">06:30 AM</option>
        <option value="7">07:00 AM</option>
        <option value="31">07:30 AM</option>
        <option value="8">08:00 AM</option>
        <option value="32">08:30 AM</option>
        <option value="9">09:00 AM</option>
        <option value="33">09:30 AM</option>
        <option value="10">10:00 AM</option>
        <option value="34">10:30 AM</option>
        <option value="11">11:00 AM</option>
        <option value="35">11:30 AM</option>
        <option value="12">12:00 AM</option>
        <option value="36">12:30 AM</option>
        <option value="13">01:00 PM</option>
        <option value="37">01:30 PM</option>
        <option value="14">02:00 PM</option>
        <option value="38">02:30 PM</option>
        <option value="15">03:00 PM</option>
        <option value="39">03:30 PM</option>
        <option value="16">04:00 PM</option>
        <option value="40">04:30 PM</option>
        <option value="17">05:00 PM</option>
        <option value="41">05:30 PM</option>
        <option value="18">06:00 PM</option>
        <option value="42">06:30 PM</option>
        <option value="19">07:00 PM</option>
        <option value="43">07:30 PM</option>
        <option value="20">08:00 PM</option>
        <option value="44">08:30 PM</option>
        <option value="21">09:00 PM</option>
        <option value="45">09:30 PM</option>
        <option value="22">10:00 PM</option>
        <option value="46">10:30 PM</option>
        <option value="23">11:00 PM</option>
        <option value="47">11:30 PM</option>
        <option value="24">12:00 PM</option>
        <option value="48">12:30 PM</option>
        <option value="49">12:00 AM </option>
        </select>
        </div>
        <div className="offcehrDay">
        <select name="tuesdayTo" id="tuesdayTo" >
        <option selected="">To</option>
        <option value="1">01:00 AM</option>
        <option value="25">01:30 AM</option>
        <option value="2">02:00 AM</option>
        <option value="26">02:30 AM</option>
        <option value="3">03:00 AM</option>
        <option value="27">03:30 AM</option>
        <option value="4">04:00 AM</option>
        <option value="28">04:30 AM</option>
        <option value="5">05:00 AM</option>
        <option value="29">05:30 AM</option>
        <option value="6">06:00 AM</option>
        <option value="30">06:30 AM</option>
        <option value="7">07:00 AM</option>
        <option value="31">07:30 AM</option>
        <option value="8">08:00 AM</option>
        <option value="32">08:30 AM</option>
        <option value="9">09:00 AM</option>
        <option value="33">09:30 AM</option>
        <option value="10">10:00 AM</option>
        <option value="34">10:30 AM</option>
        <option value="11">11:00 AM</option>
        <option value="35">11:30 AM</option>
        <option value="12">12:00 AM</option>
        <option value="36">12:30 AM</option>
        <option value="13">01:00 PM</option>
        <option value="37">01:30 PM</option>
        <option value="14">02:00 PM</option>
        <option value="38">02:30 PM</option>
        <option value="15">03:00 PM</option>
        <option value="39">03:30 PM</option>
        <option value="16">04:00 PM</option>
        <option value="40">04:30 PM</option>
        <option value="17">05:00 PM</option>
        <option value="41">05:30 PM</option>
        <option value="18">06:00 PM</option>
        <option value="42">06:30 PM</option>
        <option value="19">07:00 PM</option>
        <option value="43">07:30 PM</option>
        <option value="20">08:00 PM</option>
        <option value="44">08:30 PM</option>
        <option value="21">09:00 PM</option>
        <option value="45">09:30 PM</option>
        <option value="22">10:00 PM</option>
        <option value="46">10:30 PM</option>
        <option value="23">11:00 PM</option>
        <option value="47">11:30 PM</option>
        <option value="24">12:00 PM</option>
        <option value="48">12:30 PM</option>
        <option value="49">12:00 AM </option>
        </select>
        </div>
        </div>
        <div className="offcehrDaydiv">
        <label>Wednesday</label>
        <div className="offcehrDay">
        <select name="wednesdayFrom" id="wednesdayFrom" >
        <option selected="">From</option>
        <option value="1">01:00 AM</option>
        <option value="25">01:30 AM</option>
        <option value="2">02:00 AM</option>
        <option value="26">02:30 AM</option>
        <option value="3">03:00 AM</option>
        <option value="27">03:30 AM</option>
        <option value="4">04:00 AM</option>
        <option value="28">04:30 AM</option>
        <option value="5">05:00 AM</option>
        <option value="29">05:30 AM</option>
        <option value="6">06:00 AM</option>
        <option value="30">06:30 AM</option>
        <option value="7">07:00 AM</option>
        <option value="31">07:30 AM</option>
        <option value="8">08:00 AM</option>
        <option value="32">08:30 AM</option>
        <option value="9">09:00 AM</option>
        <option value="33">09:30 AM</option>
        <option value="10">10:00 AM</option>
        <option value="34">10:30 AM</option>
        <option value="11">11:00 AM</option>
        <option value="35">11:30 AM</option>
        <option value="12">12:00 AM</option>
        <option value="36">12:30 AM</option>
        <option value="13">01:00 PM</option>
        <option value="37">01:30 PM</option>
        <option value="14">02:00 PM</option>
        <option value="38">02:30 PM</option>
        <option value="15">03:00 PM</option>
        <option value="39">03:30 PM</option>
        <option value="16">04:00 PM</option>
        <option value="40">04:30 PM</option>
        <option value="17">05:00 PM</option>
        <option value="41">05:30 PM</option>
        <option value="18">06:00 PM</option>
        <option value="42">06:30 PM</option>
        <option value="19">07:00 PM</option>
        <option value="43">07:30 PM</option>
        <option value="20">08:00 PM</option>
        <option value="44">08:30 PM</option>
        <option value="21">09:00 PM</option>
        <option value="45">09:30 PM</option>
        <option value="22">10:00 PM</option>
        <option value="46">10:30 PM</option>
        <option value="23">11:00 PM</option>
        <option value="47">11:30 PM</option>
        <option value="24">12:00 PM</option>
        <option value="48">12:30 PM</option>
        <option value="49">12:00 AM </option>
        </select>
        </div>
        <div className="offcehrDay">
        <select name="wednesdayTo" id="wednesdayTo" >
        <option selected="">To</option>
        <option value="1">01:00 AM</option>
        <option value="25">01:30 AM</option>
        <option value="2">02:00 AM</option>
        <option value="26">02:30 AM</option>
        <option value="3">03:00 AM</option>
        <option value="27">03:30 AM</option>
        <option value="4">04:00 AM</option>
        <option value="28">04:30 AM</option>
        <option value="5">05:00 AM</option>
        <option value="29">05:30 AM</option>
        <option value="6">06:00 AM</option>
        <option value="30">06:30 AM</option>
        <option value="7">07:00 AM</option>
        <option value="31">07:30 AM</option>
        <option value="8">08:00 AM</option>
        <option value="32">08:30 AM</option>
        <option value="9">09:00 AM</option>
        <option value="33">09:30 AM</option>
        <option value="10">10:00 AM</option>
        <option value="34">10:30 AM</option>
        <option value="11">11:00 AM</option>
        <option value="35">11:30 AM</option>
        <option value="12">12:00 AM</option>
        <option value="36">12:30 AM</option>
        <option value="13">01:00 PM</option>
        <option value="37">01:30 PM</option>
        <option value="14">02:00 PM</option>
        <option value="38">02:30 PM</option>
        <option value="15">03:00 PM</option>
        <option value="39">03:30 PM</option>
        <option value="16">04:00 PM</option>
        <option value="40">04:30 PM</option>
        <option value="17">05:00 PM</option>
        <option value="41">05:30 PM</option>
        <option value="18">06:00 PM</option>
        <option value="42">06:30 PM</option>
        <option value="19">07:00 PM</option>
        <option value="43">07:30 PM</option>
        <option value="20">08:00 PM</option>
        <option value="44">08:30 PM</option>
        <option value="21">09:00 PM</option>
        <option value="45">09:30 PM</option>
        <option value="22">10:00 PM</option>
        <option value="46">10:30 PM</option>
        <option value="23">11:00 PM</option>
        <option value="47">11:30 PM</option>
        <option value="24">12:00 PM</option>
        <option value="48">12:30 PM</option>
        <option value="49">12:00 AM </option>
        </select>
        </div>
        </div>
        <div className="offcehrDaydiv">
        <label>Thursday</label>
        <div className="offcehrDay">
        <select name="thursdayFrom" id="thursdayFrom" >
        <option selected="">From</option>
        <option value="1">01:00 AM</option>
        <option value="25">01:30 AM</option>
        <option value="2">02:00 AM</option>
        <option value="26">02:30 AM</option>
        <option value="3">03:00 AM</option>
        <option value="27">03:30 AM</option>
        <option value="4">04:00 AM</option>
        <option value="28">04:30 AM</option>
        <option value="5">05:00 AM</option>
        <option value="29">05:30 AM</option>
        <option value="6">06:00 AM</option>
        <option value="30">06:30 AM</option>
        <option value="7">07:00 AM</option>
        <option value="31">07:30 AM</option>
        <option value="8">08:00 AM</option>
        <option value="32">08:30 AM</option>
        <option value="9">09:00 AM</option>
        <option value="33">09:30 AM</option>
        <option value="10">10:00 AM</option>
        <option value="34">10:30 AM</option>
        <option value="11">11:00 AM</option>
        <option value="35">11:30 AM</option>
        <option value="12">12:00 AM</option>
        <option value="36">12:30 AM</option>
        <option value="13">01:00 PM</option>
        <option value="37">01:30 PM</option>
        <option value="14">02:00 PM</option>
        <option value="38">02:30 PM</option>
        <option value="15">03:00 PM</option>
        <option value="39">03:30 PM</option>
        <option value="16">04:00 PM</option>
        <option value="40">04:30 PM</option>
        <option value="17">05:00 PM</option>
        <option value="41">05:30 PM</option>
        <option value="18">06:00 PM</option>
        <option value="42">06:30 PM</option>
        <option value="19">07:00 PM</option>
        <option value="43">07:30 PM</option>
        <option value="20">08:00 PM</option>
        <option value="44">08:30 PM</option>
        <option value="21">09:00 PM</option>
        <option value="45">09:30 PM</option>
        <option value="22">10:00 PM</option>
        <option value="46">10:30 PM</option>
        <option value="23">11:00 PM</option>
        <option value="47">11:30 PM</option>
        <option value="24">12:00 PM</option>
        <option value="48">12:30 PM</option>
        <option value="49">12:00 AM </option>
        </select>
        </div>
        <div className="offcehrDay">
        <select name="thursdayTo" id="thursdayTo" >
        <option selected="">To</option>
        <option value="1">01:00 AM</option>
        <option value="25">01:30 AM</option>
        <option value="2">02:00 AM</option>
        <option value="26">02:30 AM</option>
        <option value="3">03:00 AM</option>
        <option value="27">03:30 AM</option>
        <option value="4">04:00 AM</option>
        <option value="28">04:30 AM</option>
        <option value="5">05:00 AM</option>
        <option value="29">05:30 AM</option>
        <option value="6">06:00 AM</option>
        <option value="30">06:30 AM</option>
        <option value="7">07:00 AM</option>
        <option value="31">07:30 AM</option>
        <option value="8">08:00 AM</option>
        <option value="32">08:30 AM</option>
        <option value="9">09:00 AM</option>
        <option value="33">09:30 AM</option>
        <option value="10">10:00 AM</option>
        <option value="34">10:30 AM</option>
        <option value="11">11:00 AM</option>
        <option value="35">11:30 AM</option>
        <option value="12">12:00 AM</option>
        <option value="36">12:30 AM</option>
        <option value="13">01:00 PM</option>
        <option value="37">01:30 PM</option>
        <option value="14">02:00 PM</option>
        <option value="38">02:30 PM</option>
        <option value="15">03:00 PM</option>
        <option value="39">03:30 PM</option>
        <option value="16">04:00 PM</option>
        <option value="40">04:30 PM</option>
        <option value="17">05:00 PM</option>
        <option value="41">05:30 PM</option>
        <option value="18">06:00 PM</option>
        <option value="42">06:30 PM</option>
        <option value="19">07:00 PM</option>
        <option value="43">07:30 PM</option>
        <option value="20">08:00 PM</option>
        <option value="44">08:30 PM</option>
        <option value="21">09:00 PM</option>
        <option value="45">09:30 PM</option>
        <option value="22">10:00 PM</option>
        <option value="46">10:30 PM</option>
        <option value="23">11:00 PM</option>
        <option value="47">11:30 PM</option>
        <option value="24">12:00 PM</option>
        <option value="48">12:30 PM</option>
        <option value="49">12:00 AM </option>
        </select>
        </div>
        </div>
        <div className="offcehrDaydiv">
        <label>Friday</label>
        <div className="offcehrDay">
        <select name="fridayFrom" id="fridayFrom" >
        <option selected="">From</option>
        <option value="1">01:00 AM</option>
        <option value="25">01:30 AM</option>
        <option value="2">02:00 AM</option>
        <option value="26">02:30 AM</option>
        <option value="3">03:00 AM</option>
        <option value="27">03:30 AM</option>
        <option value="4">04:00 AM</option>
        <option value="28">04:30 AM</option>
        <option value="5">05:00 AM</option>
        <option value="29">05:30 AM</option>
        <option value="6">06:00 AM</option>
        <option value="30">06:30 AM</option>
        <option value="7">07:00 AM</option>
        <option value="31">07:30 AM</option>
        <option value="8">08:00 AM</option>
        <option value="32">08:30 AM</option>
        <option value="9">09:00 AM</option>
        <option value="33">09:30 AM</option>
        <option value="10">10:00 AM</option>
        <option value="34">10:30 AM</option>
        <option value="11">11:00 AM</option>
        <option value="35">11:30 AM</option>
        <option value="12">12:00 AM</option>
        <option value="36">12:30 AM</option>
        <option value="13">01:00 PM</option>
        <option value="37">01:30 PM</option>
        <option value="14">02:00 PM</option>
        <option value="38">02:30 PM</option>
        <option value="15">03:00 PM</option>
        <option value="39">03:30 PM</option>
        <option value="16">04:00 PM</option>
        <option value="40">04:30 PM</option>
        <option value="17">05:00 PM</option>
        <option value="41">05:30 PM</option>
        <option value="18">06:00 PM</option>
        <option value="42">06:30 PM</option>
        <option value="19">07:00 PM</option>
        <option value="43">07:30 PM</option>
        <option value="20">08:00 PM</option>
        <option value="44">08:30 PM</option>
        <option value="21">09:00 PM</option>
        <option value="45">09:30 PM</option>
        <option value="22">10:00 PM</option>
        <option value="46">10:30 PM</option>
        <option value="23">11:00 PM</option>
        <option value="47">11:30 PM</option>
        <option value="24">12:00 PM</option>
        <option value="48">12:30 PM</option>
        <option value="49">12:00 AM </option>
        </select>
        </div>
        <div className="offcehrDay">
        <select name="fridayTo" id="fridayTo" >
        <option selected="">To</option>
        <option value="1">01:00 AM</option>
        <option value="25">01:30 AM</option>
        <option value="2">02:00 AM</option>
        <option value="26">02:30 AM</option>
        <option value="3">03:00 AM</option>
        <option value="27">03:30 AM</option>
        <option value="4">04:00 AM</option>
        <option value="28">04:30 AM</option>
        <option value="5">05:00 AM</option>
        <option value="29">05:30 AM</option>
        <option value="6">06:00 AM</option>
        <option value="30">06:30 AM</option>
        <option value="7">07:00 AM</option>
        <option value="31">07:30 AM</option>
        <option value="8">08:00 AM</option>
        <option value="32">08:30 AM</option>
        <option value="9">09:00 AM</option>
        <option value="33">09:30 AM</option>
        <option value="10">10:00 AM</option>
        <option value="34">10:30 AM</option>
        <option value="11">11:00 AM</option>
        <option value="35">11:30 AM</option>
        <option value="12">12:00 AM</option>
        <option value="36">12:30 AM</option>
        <option value="13">01:00 PM</option>
        <option value="37">01:30 PM</option>
        <option value="14">02:00 PM</option>
        <option value="38">02:30 PM</option>
        <option value="15">03:00 PM</option>
        <option value="39">03:30 PM</option>
        <option value="16">04:00 PM</option>
        <option value="40">04:30 PM</option>
        <option value="17">05:00 PM</option>
        <option value="41">05:30 PM</option>
        <option value="18">06:00 PM</option>
        <option value="42">06:30 PM</option>
        <option value="19">07:00 PM</option>
        <option value="43">07:30 PM</option>
        <option value="20">08:00 PM</option>
        <option value="44">08:30 PM</option>
        <option value="21">09:00 PM</option>
        <option value="45">09:30 PM</option>
        <option value="22">10:00 PM</option>
        <option value="46">10:30 PM</option>
        <option value="23">11:00 PM</option>
        <option value="47">11:30 PM</option>
        <option value="24">12:00 PM</option>
        <option value="48">12:30 PM</option>
        <option value="49">12:00 AM </option>
        </select>
        </div>
        </div>
        <div className="offcehrDaydiv">
        <label>Saturday</label>
        <div className="offcehrDay">
        <select name="saturdayFrom" id="saturdayFrom" >
        <option selected="">From</option>
        <option value="1">01:00 AM</option>
        <option value="25">01:30 AM</option>
        <option value="2">02:00 AM</option>
        <option value="26">02:30 AM</option>
        <option value="3">03:00 AM</option>
        <option value="27">03:30 AM</option>
        <option value="4">04:00 AM</option>
        <option value="28">04:30 AM</option>
        <option value="5">05:00 AM</option>
        <option value="29">05:30 AM</option>
        <option value="6">06:00 AM</option>
        <option value="30">06:30 AM</option>
        <option value="7">07:00 AM</option>
        <option value="31">07:30 AM</option>
        <option value="8">08:00 AM</option>
        <option value="32">08:30 AM</option>
        <option value="9">09:00 AM</option>
        <option value="33">09:30 AM</option>
        <option value="10">10:00 AM</option>
        <option value="34">10:30 AM</option>
        <option value="11">11:00 AM</option>
        <option value="35">11:30 AM</option>
        <option value="12">12:00 AM</option>
        <option value="36">12:30 AM</option>
        <option value="13">01:00 PM</option>
        <option value="37">01:30 PM</option>
        <option value="14">02:00 PM</option>
        <option value="38">02:30 PM</option>
        <option value="15">03:00 PM</option>
        <option value="39">03:30 PM</option>
        <option value="16">04:00 PM</option>
        <option value="40">04:30 PM</option>
        <option value="17">05:00 PM</option>
        <option value="41">05:30 PM</option>
        <option value="18">06:00 PM</option>
        <option value="42">06:30 PM</option>
        <option value="19">07:00 PM</option>
        <option value="43">07:30 PM</option>
        <option value="20">08:00 PM</option>
        <option value="44">08:30 PM</option>
        <option value="21">09:00 PM</option>
        <option value="45">09:30 PM</option>
        <option value="22">10:00 PM</option>
        <option value="46">10:30 PM</option>
        <option value="23">11:00 PM</option>
        <option value="47">11:30 PM</option>
        <option value="24">12:00 PM</option>
        <option value="48">12:30 PM</option>
        <option value="49">12:00 AM </option>
        </select>
        </div>
        <div className="offcehrDay">
        <select name="saturdayTo" id="saturdayTo" >
        <option selected="">To</option>
        <option value="1">01:00 AM</option>
        <option value="25">01:30 AM</option>
        <option value="2">02:00 AM</option>
        <option value="26">02:30 AM</option>
        <option value="3">03:00 AM</option>
        <option value="27">03:30 AM</option>
        <option value="4">04:00 AM</option>
        <option value="28">04:30 AM</option>
        <option value="5">05:00 AM</option>
        <option value="29">05:30 AM</option>
        <option value="6">06:00 AM</option>
        <option value="30">06:30 AM</option>
        <option value="7">07:00 AM</option>
        <option value="31">07:30 AM</option>
        <option value="8">08:00 AM</option>
        <option value="32">08:30 AM</option>
        <option value="9">09:00 AM</option>
        <option value="33">09:30 AM</option>
        <option value="10">10:00 AM</option>
        <option value="34">10:30 AM</option>
        <option value="11">11:00 AM</option>
        <option value="35">11:30 AM</option>
        <option value="12">12:00 AM</option>
        <option value="36">12:30 AM</option>
        <option value="13">01:00 PM</option>
        <option value="37">01:30 PM</option>
        <option value="14">02:00 PM</option>
        <option value="38">02:30 PM</option>
        <option value="15">03:00 PM</option>
        <option value="39">03:30 PM</option>
        <option value="16">04:00 PM</option>
        <option value="40">04:30 PM</option>
        <option value="17">05:00 PM</option>
        <option value="41">05:30 PM</option>
        <option value="18">06:00 PM</option>
        <option value="42">06:30 PM</option>
        <option value="19">07:00 PM</option>
        <option value="43">07:30 PM</option>
        <option value="20">08:00 PM</option>
        <option value="44">08:30 PM</option>
        <option value="21">09:00 PM</option>
        <option value="45">09:30 PM</option>
        <option value="22">10:00 PM</option>
        <option value="46">10:30 PM</option>
        <option value="23">11:00 PM</option>
        <option value="47">11:30 PM</option>
        <option value="24">12:00 PM</option>
        <option value="48">12:30 PM</option>
        <option value="49">12:00 AM </option>
        </select>
        </div>
        </div>
        <div className="offcehrDaydiv">
        <label>Sunday</label>
        <div className="offcehrDay">
        <select name="sundayFrom" id="sundayFrom" >
        <option selected="">From</option>
        <option value="1">01:00 AM</option>
        <option value="25">01:30 AM</option>
        <option value="2">02:00 AM</option>
        <option value="26">02:30 AM</option>
        <option value="3">03:00 AM</option>
        <option value="27">03:30 AM</option>
        <option value="4">04:00 AM</option>
        <option value="28">04:30 AM</option>
        <option value="5">05:00 AM</option>
        <option value="29">05:30 AM</option>
        <option value="6">06:00 AM</option>
        <option value="30">06:30 AM</option>
        <option value="7">07:00 AM</option>
        <option value="31">07:30 AM</option>
        <option value="8">08:00 AM</option>
        <option value="32">08:30 AM</option>
        <option value="9">09:00 AM</option>
        <option value="33">09:30 AM</option>
        <option value="10">10:00 AM</option>
        <option value="34">10:30 AM</option>
        <option value="11">11:00 AM</option>
        <option value="35">11:30 AM</option>
        <option value="12">12:00 AM</option>
        <option value="36">12:30 AM</option>
        <option value="13">01:00 PM</option>
        <option value="37">01:30 PM</option>
        <option value="14">02:00 PM</option>
        <option value="38">02:30 PM</option>
        <option value="15">03:00 PM</option>
        <option value="39">03:30 PM</option>
        <option value="16">04:00 PM</option>
        <option value="40">04:30 PM</option>
        <option value="17">05:00 PM</option>
        <option value="41">05:30 PM</option>
        <option value="18">06:00 PM</option>
        <option value="42">06:30 PM</option>
        <option value="19">07:00 PM</option>
        <option value="43">07:30 PM</option>
        <option value="20">08:00 PM</option>
        <option value="44">08:30 PM</option>
        <option value="21">09:00 PM</option>
        <option value="45">09:30 PM</option>
        <option value="22">10:00 PM</option>
        <option value="46">10:30 PM</option>
        <option value="23">11:00 PM</option>
        <option value="47">11:30 PM</option>
        <option value="24">12:00 PM</option>
        <option value="48">12:30 PM</option>
        <option value="49">12:00 AM </option>
        </select>
        </div>
        <div className="offcehrDay">
        <select name="sundayTo" id="sundayTo" >
        <option selected="">To</option>
        <option value="1">01:00 AM</option>
        <option value="25">01:30 AM</option>
        <option value="2">02:00 AM</option>
        <option value="26">02:30 AM</option>
        <option value="3">03:00 AM</option>
        <option value="27">03:30 AM</option>
        <option value="4">04:00 AM</option>
        <option value="28">04:30 AM</option>
        <option value="5">05:00 AM</option>
        <option value="29">05:30 AM</option>
        <option value="6">06:00 AM</option>
        <option value="30">06:30 AM</option>
        <option value="7">07:00 AM</option>
        <option value="31">07:30 AM</option>
        <option value="8">08:00 AM</option>
        <option value="32">08:30 AM</option>
        <option value="9">09:00 AM</option>
        <option value="33">09:30 AM</option>
        <option value="10">10:00 AM</option>
        <option value="34">10:30 AM</option>
        <option value="11">11:00 AM</option>
        <option value="35">11:30 AM</option>
        <option value="12">12:00 AM</option>
        <option value="36">12:30 AM</option>
        <option value="13">01:00 PM</option>
        <option value="37">01:30 PM</option>
        <option value="14">02:00 PM</option>
        <option value="38">02:30 PM</option>
        <option value="15">03:00 PM</option>
        <option value="39">03:30 PM</option>
        <option value="16">04:00 PM</option>
        <option value="40">04:30 PM</option>
        <option value="17">05:00 PM</option>
        <option value="41">05:30 PM</option>
        <option value="18">06:00 PM</option>
        <option value="42">06:30 PM</option>
        <option value="19">07:00 PM</option>
        <option value="43">07:30 PM</option>
        <option value="20">08:00 PM</option>
        <option value="44">08:30 PM</option>
        <option value="21">09:00 PM</option>
        <option value="45">09:30 PM</option>
        <option value="22">10:00 PM</option>
        <option value="46">10:30 PM</option>
        <option value="23">11:00 PM</option>
        <option value="47">11:30 PM</option>
        <option value="24">12:00 PM</option>
        <option value="48">12:30 PM</option>
        <option value="49">12:00 AM </option>
        </select>
        </div> */}
                  {/* </div> */}
                </div>
              </div>
              <div>
                <div className="offcehrDayFieldDiv">
                  <label>Accreditation</label>
                  <select
                    multiple
                    name="accreditationIds"
                    id="accreditationIds"
                    value={this.props.accreditationIdEdit}
                    className="hspInfofieldinput Infoinputwidthdoctr hspworkdel-widthselect"
                  >
                    {this.state.accreditation.map((accreditationObj, i) => {
                      return (
                        <option key={accreditationObj.accreditationId} value={accreditationObj.accreditationId}>
                          {accreditationObj.accreditationName}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="offcehrDayFieldDiv">
                  <label>Insurance</label>
                  <select
                    multiple
                    name="insuranceIds"
                    id="insuranceIds"
                    className="hspInfofieldinput Infoinputwidthdoctr hspworkdel-widthselect"
                  >
                    {this.state.insurance.map((insuranceObj, i) => {
                      return (
                        <option key={insuranceObj.insuranceId} value={insuranceObj.insuranceId}>
                          {insuranceObj.insuranceName}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </section>
        </Suspense>
        <Suspense fallback={<div></div>}></Suspense>
      </div>
    );
  }
}
