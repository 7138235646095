import React, { Component, Suspense } from "react";
import $ from "jquery";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import moment from "moment";
import Pagination from "react-js-pagination";
import { apiCalling, apiUrlRelianceFunction } from "../apiService";
import csv from "../image/icons/csv.png";
import * as XLSX from "xlsx";
import Header from "./header";
import Footer from "./footer";
const folderName = window.localStorage.getItem("homeName");
export default class videoaudioReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentData: [],
      toDate: "",
      fromDate: "",
      totalCount: 0,
      activePage: 1,
      fuctionName: "VideoAudioPayment Report",
      csvDetails: [],
      showMessage: "Waiting for data... ",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeTo = this.handleChangeTo.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.videoAudioReport();
  }
  handleChange = (date) => {
    this.setState({
      fromDate: date,
    });
  };
  handleChangeTo = (date) => {
    this.setState({
      toDate: date,
    });
  };
  changeHandler = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };
  handlePageChange(pageNumber) {
    window.scrollTo(0, 0);
    var startDate = $("#fromDate").val();
    var toDate = $("#toDate").val();
    var newDate = moment(startDate, "DD-MM-YYYY").format("MM-DD-YYYY");
    var SearchDate = new Date(newDate);
    var milliStartDate = SearchDate.getTime();
    if (newDate === "Invalid date") {
      SearchDate = "";
      milliStartDate = "";
    }
    var endDate = moment(toDate, "DD-MM-YYYY").format("MM-DD-YYYY");
    var SearchEndDate = new Date(endDate);
    var milliEndDate = SearchEndDate.getTime() + 86400000;
    if (endDate === "Invalid date") {
      SearchEndDate = "";
      milliEndDate = "";
    }
    this.setState({ activePage: pageNumber });
    var offset;
    var max;
    if (pageNumber === "1") {
      offset = "0";
      max = "10";
      this.setState({
        activePage: pageNumber,
        isLoading: "0",
      });
    } else {
      offset = parseInt(pageNumber - 1) * 10;
      max = "10";
      this.setState({
        activePage: pageNumber,
        isLoading: "0",
      });
    }
    if (($("#appointmentDateType").val() !== "") && (!milliStartDate && !milliEndDate)) {
      alert("Please Fill From Date And To Date Or Clear Date Filter");
      return false;
    }
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "paymentReport",
        subFunctionName: "videoAudioPayment",
        userId: this?.props?.match?.params?.groupHospitalId, //"19238",
        max: max,
        offset: JSON.stringify(offset),
        date: $("#appointmentDateType").val(),
        cmbCategory1:
          $("#videoAudioSelect").val() === "0"
            ? ""
            : $("#videoAudioSelect").val(),
        searchKeyWord: $("#txtVideoAudio").val(),
        appointmentStatus:
          $("#appointmentStatusType").val() === "0"
            ? ""
            : $("#appointmentStatusType").val(),
        appointmentType:
          $("#appointmentType1").val() === "0"
            ? ""
            : $("#appointmentType1").val(),
        exportFromDate: milliStartDate,
        exportToDate: milliEndDate,
        fromDate: milliStartDate,
        toDate: milliEndDate,
      }),
    };
    $(".loader").show();
    let apiFunction = folderName !== null ? apiCalling : apiUrlRelianceFunction;
    apiFunction(apiJson).then((data) => {
      $(".loader").hide();
      if (data.success === "1") {
        this.setState({
          paymentData: data.result.paymentData,
          totalCount: data.result.count,
        });
      }
      if (data.success === "0") {
        $(".loader").hide();
        this.setState({
          paymentData: [],
          // csvDetails: [],
          showMessage: data.errorMessage,
        });
      }
    });
  }
  videoAudioReport() {
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "paymentReport",
        subFunctionName: "videoAudioPayment",
        userId: this?.props?.match?.params?.groupHospitalId, // "19238",
        max: "10",
        offset: "0",
        date: "",
        exportFromDate: "",
        exportToDate: "",
        fromDate: "",
        toDate: "",
      }),
    };
    let abcc = folderName != null ? "a1" : "apiUrlRelianceFunction";
    let apiFunction = folderName !== null ? apiCalling : apiUrlRelianceFunction;
    apiFunction(apiJson).then((data) => {
      $(".loader").hide();
      if (data.success === "1") {
        this.setState({
          paymentData: data.result.paymentData,
          // csvDetails: data.result.paymentExportData,
          totalCount: data.result.count,
        });
      }
      if (data.success === "0") {
        $(".loader").hide();
        this.setState({
          paymentData: [],
          // csvDetails: [],
          showMessage: data.errorMessage,
        });
      }
    });
  }
  changeText = () => {
    if ($("#videoAudioSelect").val() !== "0") {
      document.getElementById("txtVideoAudio").disabled = false;
      $("input[name=txtVideoAudio]").focus();
    } else {
      document.getElementById("txtVideoAudio").disabled = true;
    }
    document.getElementById("txtVideoAudio").value = "";
  };
  searchHandler() {
    var fromDate;
    var toDate;
    var startDate = this.state.fromDate ? new Date(this.state.fromDate) : "";
    if (startDate) {
      startDate.setHours(0);
      startDate.setMinutes(0);
      startDate.setSeconds(0);
      startDate.setTime(startDate.getTime() + 1000);
      fromDate = startDate.getTime();
    } else {
      fromDate = "";
    }
    var endDate = this.state.toDate ? new Date(this.state.toDate) : "";
    if (endDate) {
      endDate.setHours(23);
      endDate.setMinutes(59);
      endDate.setSeconds(59);
      toDate = endDate.getTime();
    } else {
      toDate = "";
    }
    if (
      $("#videoAudioSelect").val() !== "0" &&
      $("#txtVideoAudio").val() === ""
    ) {
      var t = document.getElementById("videoAudioSelect");
      var selectedText = t.options[t.selectedIndex].text;
      alert(selectedText + " Required");
      $("#txtVideoAudio").focus();
      return false;
    }
    if (($("#appointmentDateType").val() !== "") && (!fromDate && !toDate)) {
      alert("Please Fill From Date And To Date Or Clear Date Filter");
      return false;
    }
    else if (fromDate > toDate) {
      alert("To date should be greater than or equal to from date");
      return false;
    } else {
      const searchData = {
        method: "POST",
        body: JSON.stringify({
          functionName: "paymentReport",
          subFunctionName: "videoAudioPayment",
          userId: this?.props?.match?.params?.groupHospitalId, //"19238",
          max: "10",
          offset: "0",
          date: $("#appointmentDateType").val(),
          cmbCategory1:
            $("#videoAudioSelect").val() === "0"
              ? ""
              : $("#videoAudioSelect").val(),
          searchKeyWord: $("#txtVideoAudio").val(),
          appointmentStatus:
            $("#appointmentStatusType").val() === "0"
              ? ""
              : $("#appointmentStatusType").val(),
          appointmentType:
            $("#appointmentType1").val() === "0"
              ? ""
              : $("#appointmentType1").val(),
          exportFromDate: fromDate,
          exportToDate: toDate,
          fromDate: fromDate,
          toDate: toDate,
        }),
      };
      $(".loader").show();
      let apiFunction =
        folderName !== null ? apiCalling : apiUrlRelianceFunction;
      apiFunction(searchData).then((data) => {
        if (data.success === "1") {
          $(".loader").hide();
          this.setState({
            paymentData: data.result.paymentData,
            // csvDetails: data.result.paymentExportData,
            totalCount: data.result.count,
            activePage: 1,
          });
        }
        if (data.success === "0") {
          $(".loader").hide();
          this.setState({
            paymentData: [],
            showMessage: data.errorMessage,
            // csvDetails: [],
          });
        }
      });
    }
  }
  csvFileDownload() {
    var fromDate;
    var toDate;
    var startDate = this.state.fromDate ? new Date(this.state.fromDate) : "";
    if (startDate) {
      startDate.setHours(0);
      startDate.setMinutes(0);
      startDate.setSeconds(0);
      startDate.setTime(startDate.getTime() + 1000);
      fromDate = startDate.getTime();
    } else {
      fromDate = "";
    }
    var endDate = this.state.toDate ? new Date(this.state.toDate) : "";
    if (endDate) {
      endDate.setHours(23);
      endDate.setMinutes(59);
      endDate.setSeconds(59);
      toDate = endDate.getTime();
    } else {
      toDate = "";
    }
    // if(($("#appointmentDateType").val() !=="")&&(!fromDate&&!toDate))
    // { alert("Please Fill From Date And To Date");
    // return false;}
    if (fromDate && toDate) {
      if (fromDate > toDate) {
        alert("To date should be greater than or equal to from date");
        return false;
      } else {
        $(".loader").show();
        const apiJson = {
          method: "POST",
          body: JSON.stringify({
            functionName: "paymentReport",
            subFunctionName: "videoAudioPaymentExport",
            userId: this?.props?.match?.params?.groupHospitalId, //"19238",
            max: "10",
            offset: "0",
            cmbCategory1:
              $("#videoAudioSelect").val() === "0"
                ? ""
                : $("#videoAudioSelect").val(),
            searchKeyWord: $("#txtVideoAudio").val(),
            appointmentStatus:
              $("#appointmentStatusType").val() === "0"
                ? ""
                : $("#appointmentStatusType").val(),
            date: $("#appointmentDateType").val(),
            appointmentType:
              $("#appointmentType1").val() === "0"
                ? ""
                : $("#appointmentType1").val(),
            exportFromDate: fromDate,
            exportToDate: toDate,
            fromDate: fromDate,
            toDate: toDate,
          }),
        };
        let abcc = folderName != null ? "a1" : "apiUrlRelianceFunction";
        let apiFunction = folderName !== null ? apiCalling : apiUrlRelianceFunction;
        apiFunction(apiJson).then((data) => {
          $(".loader").hide();
          if (data.success === "1") {
            const csvRow = [];
            var dataArray = [];
            var columnDetails = [
              "Policy No.",
              "Employee Id.",
              "Patient Name",
              "Patient Mobile No.",
              "Hospital Name",
              "Doctor Name",
              "Chat Requested Date",
              "Appointment Took Place Date",
              "Transaction Id",
              "Appointment Type",
              "Appointment Time",
              "Appointment Status",
              "Coupon Amount",
              "Doctor Charge",
              "Amount Received",
            ];
            var results = data.result.paymentExportData;
            //* Policy Number, mobNo- new addition
            for (var item = 0; item < results.length; item++) {
              dataArray.push([
                results[item].policyNo,
                results[item].empId ? results[item].empId : "No",
                results[item].memName,
                results[item].mobNo,
                results[item].hospitalName,
                results[item].docName,
                moment(results[item].requestedAppointmentDate).format("DD-MMM-YYYY"),
                moment(results[item].requestedDate).format("DD-MMM-YYYY") ===
                  "01-Jan-1970"
                  ? " "
                  : moment(results[item].requestedDate).format(
                    "DD-MMM-YYYY"
                  ),
                results[item].transactionId,
                results[item].type,
                moment(results[item].requestedDate).format("hh:mm A"),
                results[item].statusName,
                results[item].promoAmt,
                results[item].actualAmount,
                results[item].amount,
              ]);
            }
            const worksheet = XLSX.utils.json_to_sheet(dataArray);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(
              workbook,
              worksheet,
              "VideoAudioPaymentReports"
            );
            XLSX.utils.sheet_add_aoa(worksheet, [columnDetails], { origin: "A1" });
            XLSX.writeFile(workbook, "VideoAudioPaymentReports.xlsx", {
              compression: true,
            });
            this.setState({
              // paymentData: data.result.paymentData,
              csvDetails: data.result.paymentExportData,
              // totalCount: data.result.count,
            });
          }
          if (data.success === "0") {
            $(".loader").hide();
            this.setState({
              paymentData: [],
              csvDetails: [],
              showMessage: data.errorMessage,
            });
          }
        });
      }
    }
    else { alert("Please Fill From Date and To Date") }
  }
  render() {
    return (
      <div className="purpleWrap">
        <Suspense fallback={<div>Waiting...</div>}>
          <section>
            <Header />
            <div className="loader"></div>
            <div className="container">
              <div className="main-wrapper1">
                <div className="col-md-12 p-0">
                  <ul className="breadcrumb">
                    <li>
                      <a href={"/reliance/dashboard"}>Dashboard</a>
                      <i className="fa fa-chevron-right"></i>
                    </li>
                    <li>Video /Audio Chat Payment Report</li>
                  </ul>
                </div>
                <div className="form-head"> Video /Audio Chat Payment Report </div>
                <div className="custom-search row">
                  <div className="col-md-4 col-6 mb-3 custom-search-sub custom-search-subWidth">
                    <select
                      className="input-design"
                      id="videoAudioSelect"
                      name="videoAudioSelect"
                      onChange={() => this.changeText()}
                    >
                      <option value="0">- - Select - -</option>
                      <option value="Patient Name">Patient Name</option>
                      <option value="Doctor Name"> Doctor Name</option>
                      <option value="Hospital Name">Hospital Name</option>
                      <option value="Transaction Id"> Transaction ID</option>
                      <option value="PolicyNo"> Policy No</option>
                    </select>
                  </div>
                  <div className="col-md-4 col-6 mb-3 custom-search-sub custom-search-subWidth">
                    <input
                      type="text"
                      className="input-design"
                      id="txtVideoAudio"
                      name="txtVideoAudio"
                      disabled
                    />
                  </div>
                  <div className="col-md-4 col-6 mb-3 custom-search-sub custom-search-subWidth">
                    <select
                      className="input-design"
                      id="appointmentType1"
                      name="appointmentType1"
                    >
                      <option value="0">Appointment Type </option>
                      <option value="Video Chat">Video Chat</option>
                      <option value="Audio Chat">Audio Chat</option>
                    </select>
                  </div>
                  <div className="col-md-4 col-6 mb-3 custom-search-sub custom-search-subWidth">
                    <select
                      className="input-design"
                      id="appointmentStatusType"
                      name="appointmentStatusType"
                    >
                      <option value="0">Appointment Status</option>
                      <option value="Open">Open</option>
                      <option value="Missed">Missed</option>
                      <option value="Confirmed">Confirmed</option>
                      <option value="Rejected">Rejected</option>
                      <option value="Cancel">Cancel</option>
                      <option value="Completed">Completed</option>
                      <option value="Call Completed">Call Completed</option>
                    </select>
                  </div>
                  <div className="col-md-4 col-6 mb-3 custom-search-sub custom-search-subWidth">
                    <select
                      className="input-design"
                      id="appointmentDateType" 
                      name="appointmentDateType"
                    >
                      <option value="">Appointment Date Type</option>
                      <option value="requestVideoAppointmentDate">Appointment Submitted Date</option>
                      <option value="requestedDate">Appointment Requested Date</option>
                      <option value="confirmedAppointmentDate">Appointment Confirmed/Took Place Date</option>
                    </select>
                  </div>
                  <div className="col-md-4 col-6 mb-3 custom-search-sub custom-search-subWidth">
                    <DatePicker
                      placeholderText="From Date"
                      id="fromDate"
                      name="fromDate"
                      selected={this.state.fromDate}
                      onChange={this.handleChange}
                      dateFormat="dd/MM/yyyy"
                      maxDate={new Date()}
                      calendarClassName="react-date-picker"
                    />
                  </div>
                  <div className="col-md-4 col-6 mb-3 custom-search-sub custom-search-subWidth">
                    <DatePicker
                      placeholderText="To Date"
                      id="toDate"
                      name="toDate"
                      maxDate={new Date()}
                      selected={this.state.toDate}
                      onChange={this.handleChangeTo}
                      dateFormat="dd/MM/yyyy"
                      calendarClassName="react-date-picker"
                    />
                  </div>
                  <div className="col-md-1 col-10 mb-3 custom-search-sub custom-search-subWidth">
                    <input
                      type="submit"
                      value="Search"
                      className="formButton m-0 formButtonsearch-width"
                      onClick={() => this.searchHandler()}
                    />
                  </div>
                  <div className="mb-3 custom-search-sub p-0 ml-2">
                    <a
                      onClick={() => this.csvFileDownload()}
                      className="cursor-pointer"
                    >
                      <img
                        src={csv}
                        alt="csv"
                        width="25"
                        title="Export CSV"
                        className="mt-1"
                      />
                    </a>
                  </div>
                </div>
                {this.state.paymentData.length > 0 ? (
                  <div className="table-responsive">
                    <table className="table video_audio_table">
                      <thead>
                        <tr>
                          <th>Policy Number</th>
                          <th>Employee ID</th>
                          <th>Patient Name</th>
                          <th>Patient Mobile No.</th>
                          <th>Hospital Name</th>
                          <th>Doctor Name</th>
                          <th>Doctor Speciality</th>
                          <th>Appoinment submitted Date</th>
                          <th>Appointment Request Date</th>
                          <th>Appointment Request Time</th>
                          <th>Appoinment Confirmed/Took Place Date</th>
                          <th>Appointment Confirmed/Took Place Time</th>
                          <th>Transaction Id</th>
                          <th>Appointment Type</th>
                          <th>Appointment Status </th>
                          <th>Coupon Amount</th>
                          <th>Doctor Charge</th>
                          <th>Amount Received</th>
                          <th>Chat Details</th>
                        </tr>
                      </thead>
                      <tbody style={{ fontSize: "12px" }}>
                        {this.state.paymentData.map((data) => {
                          let requestedDate = moment(data.requestVideoAppointmentDate).format(
                            "DD-MMM-YYYY"
                          );
                          let appointmentTookPlaceDate = moment(data.confirmedAppointmentDate).format(
                            "DD-MMM-YYYY"
                          );
                          let appointmentRequestedDate = moment(data.requestedDate).format(
                            "DD-MMM-YYYY"
                          );
                          let appointmentTime = moment(
                            data.requestedDate
                          ).format("hh:mm A");
                          let confirmedAppointmentTime = moment(
                            data.confirmedAppointmentDate
                          ).format("hh:mm A");
                          return (
                            <tr>
                              <td>{data.policyNo}</td>
                              <td>{data.empId ? data.empId : "No"}</td>
                              <td>{data.memName}</td>
                              <td>{data.mobNo}</td>
                              <td>{data.hospitalName}</td>
                              <td>Dr.{data.docName}</td>
                              <td>{data.doctorSpeciality}</td>
                              <td>
                                {requestedDate === "01-Jan-1970"
                                  ? ""
                                  : requestedDate}
                              </td>
                              <td>{appointmentRequestedDate}</td>
                              <td>{appointmentTime}</td>
                              <td>{appointmentTookPlaceDate === "01-Jan-1970"
                                  ? ""
                                  : appointmentTookPlaceDate}</td>
                              <td>{data.confirmedAppointmentDate === 0? "":confirmedAppointmentTime}</td>
                              {/* <td>
                                {confirmedAppointmentDate === "01-Jan-1970"
                                  ? ""
                                  : confirmedAppointmentDate}
                              </td> */}
                              <td>{data.transactionId}</td>
                              <td>{data.type}</td>
                              <td>{data.statusName}</td>
                              <td>₹ {data.promoAmt}</td>
                              <td>₹ {data.amount}</td>
                              <td>₹ {data.actualAmount}</td>
                              <td>
                                {" "}
                                {data.chatDetails.map((i) => {
                                  let startTime = moment(
                                    parseInt(i.chatStartTime)
                                  )
                                    .add(moment.duration(1000))
                                    .format(" HH:mm:ss");
                                  let endTime = moment(
                                    parseInt(i.chatEndTime)
                                  ).format("HH:mm:ss");
                                  let ms = moment(endTime, "HH:mm:ss").diff(
                                    moment(startTime, "HH:mm:ss")
                                  );
                                  let dur = moment.utc(ms).format("HH:mm:ss");
                                  return (
                                    <p className="vid-aud-rprs-p">
                                      <span>
                                        Start Time :{" "}
                                        {moment(
                                          parseInt(i.chatStartTime)
                                        ).format("DD-MMM-YYYY hh:mm:ss A")}
                                      </span>
                                      <span>
                                        End Time :{" "}
                                        {moment(parseInt(i.chatEndTime)).format(
                                          "DD-MMM-YYYY hh:mm:ss A"
                                        )}
                                      </span>
                                      <span>Duration : {dur}</span>
                                    </p>
                                  );
                                })}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    {this.state.totalCount > 5 ? (
                      <div className="paginationSection orderm-paginationSection">
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={10}
                          totalItemsCount={this.state.totalCount}
                          pageRangeDisplayed={10}
                          onChange={this.handlePageChange}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <p className="error-msg text-center mb-0">
                    {" "}
                    {this.state.showMessage}{" "}
                  </p>
                )}
              </div>
            </div>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
