import React, { Component, Suspense } from "react";
import Edit from "../../image/icons/edit.png";
import { apiCalling } from "../../apiService";
import $ from "jquery";
import tick from "../../image/icons/tick.png";
import Deactivate from "../../image/icons/deactvate.png";
import Pagination from "react-js-pagination";
import Header from "../../layout/header"
import Footer from "../../layout/footer"
import Close from "../../image/icons/close-mark.png"

export default class Qualification extends Component {

    constructor(props) {
        super(props);
        this.state = {
            qualificationData: [],
            totalCount: 0,
            activePage: 1,
            showMessage: "Waiting for data... "
        };
        this.handlePageChange = this.handlePageChange.bind(this);
    }

    handlePageChange(pageNumber) {
        this.setState({ activePage: pageNumber });
        var offset;
        var max;
        if (pageNumber === "1") {
            offset = "0";
            max = "10";
            this.setState({
                activePage: pageNumber,
                isLoading: '0'
            })
        }
        else {
            offset = parseInt(pageNumber - 1) * 10;
            max = "10";
            this.setState({
                activePage: pageNumber,
                isLoading: '0'
            })
        }
        const apiJson = {
            method: "POST",
            body: JSON.stringify({
                functionName: "qualificationListing",
                cmbSelect: $("#categoryType").val() === '0' ? '' : $("#categoryType").val(),
                txtInput: $("#searchTxt").val(),
                cmbStatus: $("#statusType").val() === '0' ? '' : $("#statusType").val(),
                Offset:JSON.stringify(offset),
                max:max
            }),
        };
        $(".loader").show();
        apiCalling(apiJson).then((data) => {
            if (data.success === "1") {
                this.setState({
                    qualificationData: data.qualificationData,
                    totalCount: data.totalCount,
                });
                $(".loader").hide();
            }
        });
    }
    componentDidMount() {

        this.getQualificationDetails();
    }

    getQualificationDetails() {
        $(".loader").show();
        const apiJson = {
            method: "POST",
            body: JSON.stringify({
                functionName: "qualificationListing",
                cmbSelect: "",
                txtInput: "",
                cmbStatus: "",
                Offset: "0",
                max: "10"
            }),
        };
        apiCalling(apiJson).then((data) => {
            if (data.success === "1") {
                $(".loader").hide();
                this.setState({
                    qualificationData: data.qualificationData,
                    totalCount: data.totalCount,
                });
            }
            if (data.success === "0") {
                $(".loader").hide();
                this.setState({
                    qualificationData: [],
                    showMessage: data.errorMessage
                });
            }
        });
    }

    changeText = () => {
        if ($("#categoryType").val() !== '0') {
            document.getElementById("searchTxt").disabled = false
            $('input[name=searchTxt]').focus();
        }
        else {
            document.getElementById("searchTxt").disabled = true
        }
        document.getElementById("searchTxt").value = ""
    }

    searchHandler() {

        if ($("#categoryType").val() !== "0" && $("#searchTxt").val() === "") {
            var t = document.getElementById("categoryType");
            var selectedText = t.options[t.selectedIndex].text;
            alert(selectedText + " Required");
            $("#searchTxt").focus();
            return false;
        } else {
            const searchData = {
                method: "POST",
                body: JSON.stringify({
                    functionName: "qualificationListing",
                    cmbSelect: $("#categoryType").val() === '0' ? '' : $("#categoryType").val(),
                    txtInput: $("#searchTxt").val(),
                    cmbStatus:$("#statusType").val() === '0' ? '' : $("#statusType").val(),
                    Offset: "0",
                    max: "10"
                }),
            };
            $(".loader").show();
            apiCalling(searchData).then((data) => {
                if (data.success === "1") {
                    $("#paginationType").show();
                    $(".loader").hide();
                    this.setState({
                        qualificationData: data.qualificationData,
                        totalCount: data.totalCount,
                        activePage: 1,
                    });
                }
                if (data.success === "0") {
                    $("#paginationType").hide();
                    $(".loader").hide();
                    this.setState({
                        qualificationData: [],
                        showMessage: data.errorMessage
                    });
                }
            })
        }
    }

    statusChangeHandler(id, Status) {

        var x = false;
        if (Status === true) {
            x = window.confirm("Do you want to Disable this?")
        }
        else {
            x = window.confirm("Do you want to Enable this?")
        }
        if (x === true) {
            const SatusData = {
                method: "POST",
                body: JSON.stringify({
                    functionName: "qualificationStatusChange",
                    qualificationId: id,
                    status: Status === true ? 'false' : 'true'
                }),
            };
            $(".loader").show();
            apiCalling(SatusData).then((data) => {
                if (data.success === "1") {
                    $(".loader").hide();
                    alert("Status Changed Successfully")
                    this.getQualificationDetails()
                }
                if (data.success === "0") {
                    $(".loader").hide();
                    alert(data.errorMessage)
                }
            })
        }
    }

    category1PopOpen(){
      
        $("#popup1").show()
        $(".popOverlay").css("background-color","rgba(0, 0, 0, 0.6)");
        $("#addQualificationSave").show();
        $("#UpdateDetails").hide();
        $("#qualificationId").val('');
        $("#qualificationId").hide();
        $("#txtQualification").focus();
        $("#txtQualification").val('')
        $("#statusChange").prop('checked', false);
    }

    saveHandle() {
        let qualificationId = $("#qualificationId").val();
        let  description = $("#txtQualification").val();
       
       /* if ($("#qualificationId").val() === '') {
            var statusData = 'true'
        } else { */
            var statusData = $("#statusChange").is(":checked") === true ? 'true' : 'false'
        //}
       
        var flag = true
        if ($("#txtQualification").val() === "") {
            alert("Qualification Required")
            $("#txtQualification").focus();
            flag = false;
        }
        if (flag === true) {
            const dataSave = {
                method:"POST",
                body: JSON.stringify({
                    functionName:"addOrEditQualification",
                    qualificationId:qualificationId,
                    description:description,
                    status:statusData
                }),
            };
            apiCalling(dataSave).then((data) => {
                if (data.success === "1") {
                    let message =
                      qualificationId > 0
                      ? "Updated successfully "
                      : "Saved successfully";
                  alert(message);
                    $("#popup1").hide()
                    setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                } else {
                    alert(data.errorMessage)
                    $("#txtQualification").focus();
                }
            })
        }
    }

    editDetails(id , description , status){

        $("#popup1").show()
        $(".popOverlay").css("background-color","rgba(0, 0, 0, 0.6)");
        $("#UpdateDetails").show()
        $("#addQualificationSave").hide()
        $("#qualificationId").val(id)
        $("#txtQualification").val(description)
        if (status === true) {
            $("#statusChange").prop('checked', true);
        }
        else {
            $("#statusChange").prop('checked', false);
        }
        
    }

    closepopup(){
        $("#popup1").hide();
        $(".popOverlay").css("background-color","transparent");
        $("#qualificationId").val('')
        $("#txtQualification").val('')
        $("#statusChange").prop('checked', false);
    }


    render() {
        return (
            <div className="purpleWrap bg-clr-wthHead">
                <Suspense fallback={<div></div>}>
                    <section>
                        <Header />{" "}
                        <div className='loader'></div>
                    </section>
                </Suspense>

                <div className="container mb-5">
                    <div>
                        <ul className="breadcrum-adminMangmnt">
                            <li><a href="/admin/dashboardAdmin">Dashboard</a></li>
                            <li><a href="/adminDashboard/doctorWorkdashboard">Doctor Work Dashboard</a></li>
                            <li><a href="" className="current">Qualification</a></li>
                        </ul>
                    </div>
                    <div className="head-doctorworkList">Qualification</div>

                    <div className="DoctorworkListTable">
                       <div className="AddNewdoctrworkDiv qual-popupdivouter">
                                <button className="AddNewdoctrbtnDiv" onClick={this.category1PopOpen}> Add New Qualification</button>
                        </div>
                        {this.state.totalCount > 5 ?
                            <div className="pagination-qualList" id="paginationType">
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={10}
                                    totalItemsCount={this.state.totalCount}
                                    pageRangeDisplayed={10}
                                    onChange={this.handlePageChange}
                                />
                            </div>
                            : ""}
                      
                        <div className="tableHeader-HspMangemnt mt-1">
                            <div className="selectField-HspMangmnt">
                                <select className="slecthsp slecthspMob" id="categoryType" name="categoryType" onChange={() => this.changeText()}>
                                    <option value="0">Search In</option>
                                    <option value="1">ID</option>
                                    <option value="2">Qualification</option>

                                </select>
                            </div>
                            <div className="selectField-HspMangmnt">
                                <input type="text" className="hspmngInput doctlistInputmob" name="searchTxt" id="searchTxt" disabled />
                            </div>
                            <div className="selectField-HspMangmnt">
                                <select className="slecthsp slecthspMob" id="statusType" name="statusType">
                                    <option value="0">Status</option>
                                    <option value="true">Active</option>
                                    <option value="false">Inactive</option>

                                </select>
                            </div>
                            <div className="selectField-HspMangmnt">
                                <button className="searchbtn-hspMng" onClick={() => this.searchHandler()}>Search</button>
                            </div>
                        </div>
                        {this.state.qualificationData.length > 0 ?
                        <div className="table-responsive">
                            <table className="table table-bordered-hsp table-doctrworkList">
                                <thead>
                                    <tr>
                                        <th className="tablehead-hspmng" colSpan="2">ID</th>
                                        <th className="tablehead-hspmng" colSpan="5">Qualification </th>
                                        <th className="tablehead-hspmng" colSpan="4">Status</th>
                                        <th className="tablehead-hspmng" colSpan="4">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {this.state.qualificationData.map((data) => (
                                    <tr>
                                        <td colSpan="2">{data.qualificationId}</td>
                                        <td colSpan="5">{data.description}</td>
                                        <td colSpan="4">{data.status===true?'Active':'Inactive'}</td>
                                        <td colSpan="4" className="nowrap">
                                            <div>
                                                <span className="mr-2 editbtn-qual"><img src={Edit} alt="" width="18" className="img-fluid" onClick={() => this.editDetails(data.qualificationId, data.description, data.status)} /></span>
                                                {/* <Popup ></Popup> */}
                                                {data.status === true ? <img src={tick} alt="" title="Click to Deactivate" className="mr-2 conf-cursorpointer" width="20" onClick={() => { this.statusChangeHandler(data.qualificationId, true) }}></img>:<img src={Deactivate} width="22" title="Click to Activate " className="mr-2 conf-cursorpointer" alt="" onClick={() => { this.statusChangeHandler(data.qualificationId, false) }}></img>} 
                                            </div>

                                        </td>
                                    </tr>
                                     ))}
                                </tbody>
                            </table>
                            {this.state.totalCount > 5 ?
                                            <div className="pagination-qualList pagination-qualListwidth">
                                                <Pagination
                                                    activePage={this.state.activePage}
                                                    itemsCountPerPage={10}
                                                    totalItemsCount={this.state.totalCount}
                                                    pageRangeDisplayed={10}
                                                    onChange={this.handlePageChange}
                                                />
                                            </div>
                                            : ""}
                        </div>
                        :
                                    <p className="error-msg text-center mb-0"> {this.state.showMessage} </p>
                                }
                    </div>
                {/* </div> */}


                {/* popup */}

                </div><div className="popBack" id="popup1">
                    <div className="popOverlay" onClick={this.category1PopClose}></div>
                    <div className="customizationPop">
                       <input type="hidden" id="qualificationId" />
                        <div className="popuphead-qualifctn">
                            <h2>Qualification Details</h2>
                            </div>
                            <span className="closepopupqual" type="button" onClick={() => this.closepopup()} ><img src={Close} alt="Close" className="img-fluid" width="25" /></span>
                            <div className="popupdiv">
                                <label htmlFor="" className="qualifcatin-popuplabel">Qualification <span className="star-red-qual">*</span></label>
                                <input type="text" className="qualifcatin-popupInput" id="txtQualification" name="txtQualification"  placeholder="Qualification" />
                            </div>

                            <div className="popupdiv">
                                <label htmlFor="" className="qualifcatin-popuplabel">Is Active</label>
                                <input type="checkbox"  id="statusChange" className="qual-checkbox"/>
                                <label for="statusChange"></label>
                            </div>
                            <div className="popupbutndiv">
                                <button className="butnsave-qual"  id="addQualificationSave"  onClick={() => this.saveHandle()}>Save</button>
                                <button className="butnsave-qual" id="UpdateDetails" onClick={() => this.saveHandle()}>Update</button>
                                <button className="butncancel-qual"onClick={() => this.closepopup()}>cancel</button>
                        </div>
                    </div>
                </div>
               
               
               
               
                <Suspense fallback={<div></div>}>
                    <section>
                        <Footer />
                    </section>
                </Suspense>
            </div>
        );
    }
}
