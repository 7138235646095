import React, { useState, useRef } from "react";
import { useEffect } from "react";
import { apiCalling } from "../apiService";
import $ from "jquery";

var SiteAutoComplete = function (props) {
  const [display, setDisplay] = useState(false);
  const [options, setOptions] = useState([]);
  const wrapperRef = useRef(null);

  const setDex = (dex) => {
    props.setSearch(dex);
    setDisplay(false);
  };

  useEffect(() => {
    $(".loader").show();
    const siteDetail = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getAllSiteDetails",
      }),
    };

    apiCalling(siteDetail).then((data) => {
      if (data.success === "1") {
        setOptions(data.siteData);
      }
      $(".loader").hide();
    });
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleModeClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleModeClickOutside);
    };
  }, []);

  const handleModeClickOutside = (e) => {
    const { current: wrap } = wrapperRef;
    if (wrap && !wrap.contains(e.target)) {
      setDisplay(false);
    }
  };

  return (
    <div ref={wrapperRef} className="flex-container flex-column pos-rel">
      <input
        id="autoSpecialityName"
        onClick={() => setDisplay(!display)}
        onFocus={(e) => e.target.select()}
        placeholder="Site Name"
        value={props.tempSiteData.siteName}
        onChange={(e) => props.setSearch(e.target.value)}
        className="input-design"
        autocomplete="off"
      ></input>
      {display && (
        <div
          className="searchDropdowns ml-0"
          style={{
            position: "absolute",
            zIndex: 1,
            background: "white",
            borderColor: "lightgray",
            borderWidth: 1,
            borderStyle: "solid",
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: -15,
            marginLeft: 15,
            fontSize: 14,
            fontFamily: "Poppins Light",
            width: "auto",
            height: "300px",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          {options
            .filter(({ siteName }) =>
              siteName
                .toLocaleLowerCase()
                .toString()
                .includes(
                  props.tempSiteData.siteName.toLocaleLowerCase().toString()
                )
            )
            .map((v, i) => {
              return (
                <div
                  onClick={() => {
                    setDex(v.siteName);
                    props.onItemChange(v);
                  }}
                  className="option"
                  key={i}
                  tabIndex="0"
                >
                  <span>{v.siteName}</span>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default SiteAutoComplete;
