import React, { Component } from "react";
import $ from "jquery";
import { apiCalling } from "../../apiService";
import tick from "../../image/icons/delete.png";
import Close from "../../image/icons/close-mark.png"
export default class AddMetaDataPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      GetingMetaDataDetails: [],
      siteId:this.props.siteId !== ""
      ? this.props.siteId
      : "",
      name:"",
      description:""
    };
  }
  componentDidMount() {
    this.getPopUpDetails();
  }

  getPopUpDetails() {
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getSiteMetaTagsDetails",
        pmSiteId: this.state.siteId
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        this.setState({
            GetingMetaDataDetails: data.result[0].siteMetaTagDetails
        });
        
      }
      if (data.success === "0") {
        this.setState({
            GetingMetaDataDetails: data.result
        });
      }
      this.setState({ name:"" ,description:""});
    });
    $(".loader").hide();
  }

  saveMetaData =()=> {
      if (this.state.name === "") {
          alert("Name required");
      }
      else if (this.state.description === "") {
          alert("Description required");
      }
      else {
          $(".loader").show();
          const newItem = {
              metaTagName: this.state.name,
              metaTagDescription: this.state.description
          };
          const addMetaDAta = {
              method: "POST",
              body: JSON.stringify({
                  functionName: "saveSiteMetaTagsDetails",
                  pmSiteId: this.state.siteId,
                  siteMetaTagDetails: [...this.state.GetingMetaDataDetails, newItem]
              }),
          };
          apiCalling(addMetaDAta).then((data) => {
            if (data.success === "0") {
                alert(data.errorMessage);
            }
            this.getPopUpDetails();
          });
      }
  }

  changeHandler = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  statusChangeHandler(id, Status) {
    var x = false;
    if (Status === true) {
      x = window.confirm("Do you want to Delete this?");
    } 
    if (x === true) {
      const StatusData = {
        method: "POST",
        body: JSON.stringify({
          functionName: "deleteSiteMetaTagDetails",
          metaTagId: id
        }),
      };
      $(".loader").show();
      apiCalling(StatusData).then((data) => {
        if (data.success === "1") {
          $(".loader").hide();
          alert("Status Changed Successfully");
          this.getPopUpDetails();
        }
        if (data.success === "0") {
          $(".loader").hide();
          alert(data.errorMessage);
        }
      });
    }
  }

  cancelPopup() {
    $("#metadataPopup").hide();
    this.setState({ name:"" ,description:""});
  }

  render() {
    return (
      <div className="popup-overlay modal_overlay" id="metadataPopup">
        <div className="popup-contentDiv popup-content-padd">
        <div className="modal-popup">
            <span
              className="closepopupqual"
              onClick={() => this.cancelPopup()}
            >
              <img src={Close} alt="Close" className="img-fluid" width="25" />
              {/* &times;  */}
            </span>
            <div className="header border-0">Add MetaData</div>
            <div className="content">
              <div className="row">
                <div className="row-margin col-md-5">
                  <label className="col-md-12 label-text">
                    Meta Name <span className="hspifoMantory">*</span>
                  </label>
                 
                    <input
                      type="text"
                      className="qualifcatin-popupInput"
                      id="name"
                      name="name"
                      value={this.state.name}
                      onInput={this.changeHandler}
                    />
                  </div>
                <div className="row-margin col-md-5">
                  <label className="col-md-12 label-text">Meta Description
                  <span className="hspifoMantory">*</span></label>
                 
                    <input
                      type="text"
                      className="qualifcatin-popupInput"
                      id="description"
                      name="description"
                      value={this.state.description}
                      onInput={this.changeHandler}
                    />
                 
                  </div>
                <div className="row-margin col-md-2">
                  <label className="col-md-12 label-text"></label>
                 
                  <span
                  className="sitesetng-svebtn btn-popup"
                  onClick={this.saveMetaData}
                >
                  Add
                </span>
                </div>
              </div>
              <div className="HosptlMangemntTable">
              {this.state.GetingMetaDataDetails.length > 0 ? (<div className="table-responsive">
              <table className="table table-bordered-hsp">
                <thead>
                  <tr>
                    <th className="tablehead-hspmng" width="40%">Meta Name</th>
                    <th className="tablehead-hspmng" width="50%">Meta Description</th>
                    <th className="tablehead-hspmng"  width="10%">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.GetingMetaDataDetails.map((data) => (
                          <tr>
                            <td width="40%">{data.metaTagName}</td>
                            <td width="50%">{data.metaTagDescription}</td>
                            <td width="10%">
                                <img
                                    src={tick}
                                    width="22"
                                    title="Click to Delete "
                                    className="mr-2 conf-cursorpointer"
                                    alt=""
                                    onClick={() => {
                                      this.statusChangeHandler(
                                        data.metaTagId,
                                        true
                                      );
                                    }}
                                ></img>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      </table>
                      </div>) : (
                        <div className="header text-center mb-0">
                            No MetaData
                        </div>
                      )}
                    </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}