import React, { Component, Suspense } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";

export default class MobileappDashboard extends Component {
  state = {};
  componentDidMount() {
    window.localStorage.setItem("contactUsReportsPageNumber", 0);
    window.localStorage.setItem("contactUsReportsOffset", 0);
  }
  render() {
    return (
      <div className="dashboard-doctorOuter">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />

            <div className="container mt-5">
              <div>
                <ul className="breadcrum-adminMangmnt">
                  <li>
                    <a href="/admin/dashboardAdmin">Dashboard</a>
                  </li>
                  <li>
                    <a href="" className="current">
                     Reports
                    </a>
                  </li>
                </ul>
              </div>
              <div className="doctorWork-dashboard">
                <div className="adminMangemnt-dashboard">
                  <div className="admin-dc-dashBtns">
                    <a href="/adminManagement/reports/contactUsReports">
                      <div>Enquiry Reports</div>
                    </a>
                  </div>

                </div>
              </div>
            </div>
          </section>
        </Suspense>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
