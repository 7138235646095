import React, { Component } from 'react';
import Solution2 from "../image/theme/clinic-management.png"
import Solution3 from "../image/theme/content-creatn.png"
import Solution4 from "../image/theme/brand-strategy.png"
import Websitebranding from "../image/theme/website-branding1.png"
import Add from "../image/theme/add.png"
import Close from "../image/theme/closebtn.png"
export default class digitalBrandingSolutions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isContentVisible: false,
            isContentVisibleTwo:false,
            isContentVisibleThree:false,
            isContentVisibleFour:false
          };
    }
    toggleVisibility = (contentId) => {
        this.setState((prevState) => ({
          [contentId]: !prevState[contentId],
        }));
      };
    render() {
        const { isContentVisible, isContentVisibleTwo, isContentVisibleThree,isContentVisibleFour } = this.state;
        return (
            <div className="ph-main-outerdiv">
                <div className='container-lg'>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="ph-db-banner">
                                <h2 className="ph-main-title-2 mt-5">
                                Our Solutions for Digital Branding
                                </h2>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="d-md-flex flex-md-equal w-100 mt-3 mb-5 my-md-5">
                                <div className="col-lg-6 px-0">
                                    <div className='ph-local-pm-more'>
                                        <img src={Websitebranding} alt="digitalBrand" width="605" height="291" className='img-fluid' />
                                    </div>
                                </div>
                                <div className="col-lg-6 px-0">
                                    <div className="ph-solutions">
                                        <h3 className="ph-solution-title">Website with Branding of the Clinic/ Doctor</h3>
                                        <p className="ph-solution-content">
                                        {!isContentVisible ?
                                            "Create your online presence with an engaging website  that serves both you and your patients."
                                            :
                                            "The first and most important step in creating your online presence is having an attractive, useful and engaging website that can be found easily by new and existing patients. PurpleHealth has websites designed specifically for doctors and their clinics."
                                        }
                                        </p>
                                        <h4 className="ph-solution-sub">
                                            Key Features :
                                        </h4>
                                        <div className="">
                                            <div className="ph-key-features">
                                            Attractive design with your own clinic branding
                                            </div>
                                            <div className="ph-key-features">
                                            Optimised website for easy discovery on google and other search engines
                                            </div>
                                           
                                            <div className={` ${isContentVisible ? 'visible-class' : 'invisible-class'}`}>
                                                <div className="ph-key-features">
                                                Appointment calendars for patient booking that is directly integrated to your clinic management system
                                                </div>
                                                <div className="ph-key-features">
                                                Built-in telehealth technology for better patient engagement
                                                </div>
                                                <div className="ph-key-features">
                                                Patient focused tools for easy access and updates
                                                </div>
                                                </div>

                                        </div>
                                        <button className={`ph-learn-more ${isContentVisible ? 'ph-btn-show' : 'ph-btn-hide'}`} onClick={() => this.toggleVisibility("isContentVisible")}>
                                                <span className={`isspan ${isContentVisible ? 'isspanHide' : 'isspanShow'}`}>Learn More</span>
                                                <span className='text-right ph-icon-wrapper'>
                                                    {isContentVisible ?
                                                    <img src={Close} alt="Add" className='img-fluid' width="20" height="20" />:
                                                    <img src={Add} alt="Close" className='img-fluid' width="20" height="20" />
                                                    }
                                                </span>
                                            </button>
                                    </div>
                                </div>
                            </div>
                            <div className="d-md-flex flex-md-equal w-100 my-5">
                                <div className="col-lg-6 px-0">
                                    <div className='ph-local-pm-more'>
                                        <img src={Solution2} alt="digitalBrand" width="605" height="291" className='img-fluid' />
                                    </div>
                                </div>
                                <div className="col-lg-6 px-0">
                                    <div className="ph-solutions">
                                        <h3 className="ph-solution-title">Clinic Management System</h3>
                                        <p className="ph-solution-content">
                                            {!isContentVisibleTwo ?
                                            "PurpleHealth’s powerful and comprehensive clinic management system helps remove the hassle of managing data manually."
                                            :
                                            "A comprehensive solution designed specifically for doctors helping them streamline and enhance their practice management."
                                        }
                                        </p>
                                        <h4 className="ph-solution-sub">
                                            Key Features :
                                        </h4>
                                        <div className="">
                                            <div className="ph-key-features">
                                            Electronic health records for both doctors and patients
                                            </div>
                                            <div className="ph-key-features">
                                                Ensures confidentiality and security of patients' data
                                            </div>
                                            <div className={` ${isContentVisibleTwo ? 'visible-class' : 'invisible-class'}`}>
                                                    <div className="ph-key-features">
                                                        Follows HIPAA complaint standards
                                                    </div>
                                                    <div className="ph-key-features">
                                                        Facilitate collaboration among doctors working across different clinics
                                                    </div>
                                                    <div className="ph-key-features">
                                                        Manages appointment scheduling and resource management
                                                    </div>
                                                    <div className="ph-key-features">
                                                        Integrated prescription and billing
                                                    </div>
                                                </div>

                                        </div>
                                        <button className={`ph-learn-more ${isContentVisibleTwo ? 'ph-btn-show' : 'ph-btn-hide'}`} onClick={() => this.toggleVisibility("isContentVisibleTwo")}>
                                                <span className={`isspan ${isContentVisibleTwo ? 'isspanHide' : 'isspanShow'}`}>Learn More</span>
                                                <span className='text-right ph-icon-wrapper'>
                                                    {isContentVisibleTwo ?
                                                    <img src={Close} alt="Add" className='img-fluid' width="20" height="20" />:
                                                    <img src={Add} alt="Close" className='img-fluid' width="20" height="20" />
                                                    }
                                                </span>
                                            </button>
                                    </div>
                                </div>
                            </div>
                            <div className="d-md-flex flex-md-equal w-100 my-5">
                                <div className="col-lg-6 px-0">
                                    <div className='ph-local-pm-more'>
                                        <img src={Solution3} alt="digitalBrand" width="605" height="291" className='img-fluid' />
                                    </div>
                                </div>
                                <div className="col-lg-6 px-0">
                                    <div className="ph-solutions">
                                        <h3 className="ph-solution-title">Content Creation</h3>
                                        <p className="ph-solution-content">
                                            {!isContentVisibleThree ?
                                            "Our content creation services use SEO techniques to make sure your content aligns with the latest trends and searches."
                                            :
                                            "Our Content Creation services use SEO techniques to make sure your content aligns with local search trends and Google keyword analytics."
                                        }
                                        
                                        </p>
                                        <h4 className="ph-solution-sub">
                                            Key Features :
                                        </h4>
                                        <div className="">
                                            <div className="ph-key-features">
                                            Enhance your online visibility through targeted keywords
                                            </div>
                                            <div className="ph-key-features">
                                            Establish a robust presence across various digital platforms
                                            </div>
                                            <div className={` ${isContentVisibleThree ? 'visible-class' : 'invisible-class'}`}>
                                                    <div className="ph-key-features">
                                                       Optimise your content for better search engine performance
                                                    </div>
                                                   
                                                </div>
                                        </div>
                                        <button className={`ph-learn-more ${isContentVisibleThree ? 'ph-btn-show' : 'ph-btn-hide'}`}  onClick={() => this.toggleVisibility("isContentVisibleThree")}>
                                                    <span className={`isspan ${isContentVisibleThree ? 'isspanHide' : 'isspanShow'}`}>Learn More</span>
                                                    <span className='text-right ph-icon-wrapper'>
                                                    {isContentVisibleThree ?
                                                    <img src={Close} alt="Add" className='img-fluid' width="20" height="20" />:
                                                    <img src={Add} alt="Close" className='img-fluid' width="20" height="20" />
                                                    }
                                                </span>
                                            </button>
                                    </div>
                                </div>
                            </div>
                            <div className="d-md-flex flex-md-equal w-100 my-5">
                                <div className="col-lg-6 px-0">
                                    <div className='ph-local-pm-more'>
                                        <img src={Solution4} alt="digitalBrand" width="605" height="291" className='img-fluid' />
                                    </div>
                                </div>
                                <div className="col-lg-6 px-0">
                                    <div className="ph-solutions">
                                        <h3 className="ph-solution-title">Brand Strategy</h3>
                                        <p className="ph-solution-content">
                                            {!isContentVisible ?
                                            "Establish a unique brand identity for your healthcare practice both offline and online."
                                            :
                                            "Create a strong  brand identity that reflects brand's core values and execeptional services."
                                        }
                                        
                                        </p>
                                         <h4 className="ph-solution-sub">
                                            Key Features :
                                        </h4>
                                        <div className="">
                                            <div className="ph-key-features">
                                            Develop a compelling brand identity for your healthcare practice
                                            </div>
                                            <div className="ph-key-features">
                                            Implement brand consistency across various touchpoints
                                            </div>
                                            <div className={` ${isContentVisible ? 'visible-class' : 'invisible-class'}`}>
                                                    <div className="ph-key-features">
                                                        Tailor your brand messaging to effectively communicate your healthcare values and mission
                                                    </div>
                                                    
                                                </div>
                                        </div>
                                        <button className={`ph-learn-more ${isContentVisible ? 'ph-btn-show' : 'ph-btn-hide'}`} onClick={() => this.toggleVisibility("isContentVisible")}>
                                                <span className={`isspan ${isContentVisible ? 'isspanHide' : 'isspanShow'}`}>Learn More</span>
                                                <span className='text-right ph-icon-wrapper'>
                                                    {isContentVisible ?
                                                    <img src={Close} alt="Add" className='img-fluid' width="20" height="20" />:
                                                    <img src={Add} alt="Close" className='img-fluid' width="20" height="20" />
                                                    }
                                                </span>
                                            </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}
