import React, { Component } from 'react';
import keyImg from '../image/theme/dental-lap.png'
import Icon01 from '../image/1.png'
import Icon02 from '../image/2.png'
import Icon03 from '../image/3.png'
import Icon04 from '../image/4.png'
import Icon05 from '../image/5.png'
import Icon06 from '../image/6.png'
import Icon07 from '../image/7.png'
import Icon08 from '../image/8.png'
import Icon09 from '../image/9.png'
import Icon10 from '../image/10.png'
import Icon11 from '../image/11.png'
import Icon12 from '../image/dental.png'
export default class KeyFeatures extends Component {


  render() {
    return (
        <div className='key-features pt-5 pb-2 py-lg-5 py-md-5'>
            <div className="container">
            <div className="row m-0 px-lg-5 px-md-5 pb-5 px-0">
                <div className='col-md-11 mx-auto mb-4'>
                    <div className='row'>
                        <div className='col-md-7'>
                        <h2 class="ph-main-title-2 text-center">Key Features</h2>
                        <p className='lead text-left'>
                        PurpleHealth’s Clinic Management Software is full featured and has all the tools that you need to run an grow a modern practice:

                        </p>
                        </div>
                        <div className='col-md-5'>
                            <img src={keyImg} className='img-fluid' width="100%" height="auto" alt="key"/>
                        </div>
                    </div>
                </div>
              <div className="col-lg-4 mb-lg-5 mb-4">
                <div className='key-features-div'>
                    <div className='key-features-icon'>
                        <img src={Icon01} width={50} height={50} className='img-fluid' alt='appointment'/>
                    </div>
                    <div className='key-features-content'>
                        <div className='key-feature-head'>Smart Appointment Scheduling</div>
                        <div className='key-feature-para'>Effortlessly manage patient appointments with our intuitive scheduling tool.  </div>
                    </div>
                </div>
              </div>
              <div className="col-lg-4 mb-lg-5 mb-4">
                <div className='key-features-div'>
                    <div className='key-features-icon'>
                        <img src={Icon05} width={50} height={50} className='img-fluid' alt=''/>
                    </div>
                    <div className='key-features-content'>
                        <div className='key-feature-head'>Advanced Patient Electronic Health Records (EHR) </div>
                        <div className='key-feature-para'>Instantly access and update patient information in a digital format!</div>
                    </div>
                </div>
              </div>
              <div className="col-lg-4 mb-lg-5 mb-4">
                <div className='key-features-div'>
                    <div className='key-features-icon'>
                        <img src={Icon12} width={50} height={50} className='img-fluid' alt=''/>
                    </div>
                    <div className='key-features-content'>
                        <div className='key-feature-head'>Dentistry Focused Modules </div>
                        <div className='key-feature-para'>Designed by dentists and includes dental-only features! </div>
                    </div>
                </div>
              </div>
              <div className="col-lg-4 mb-lg-5 mb-4">
                <div className='key-features-div'>
                    <div className='key-features-icon'>
                        <img src={Icon02} width={50} height={50} className='img-fluid' alt=''/>
                    </div>
                    <div className='key-features-content'>
                        <div className='key-feature-head'>Billing and Invoicing Module </div>
                        <div className='key-feature-para'>Billing, invoice generation and bank integration for payments!</div>
                    </div>
                </div>
              </div>
              <div className="col-lg-4 mb-lg-5 mb-4">
                <div className='key-features-div'>
                    <div className='key-features-icon'>
                        <img src={Icon06} width={50} height={50} className='img-fluid' alt=''/>
                    </div>
                    <div className='key-features-content'>
                        <div className='key-feature-head'>Efficient Inventory Management </div>
                        <div className='key-feature-para'>Track stock levels, manage medications and inventory levels of supplies!</div>
                    </div>
                </div>
              </div>
              <div className="col-lg-4 mb-lg-5 mb-4">
                <div className='key-features-div'>
                    <div className='key-features-icon'>
                        <img src={Icon09} width={50} height={50} className='img-fluid' alt=''/>
                    </div>
                    <div className='key-features-content'>
                        <div className='key-feature-head'>Insightful Reporting and Analytics </div>
                        <div className='key-feature-para'>Identify trends, measure performance, and uncover opportunities for growth!</div>
                    </div>
                </div>
              </div>
              <div className="col-lg-4 mb-lg-5 mb-4">
                <div className='key-features-div'>
                    <div className='key-features-icon'>
                        <img src={Icon03} width={50} height={50} className='img-fluid' alt=''/>
                    </div>
                    <div className='key-features-content'>
                        <div className='key-feature-head'>Patient-Centric Portal </div>
                        <div className='key-feature-para'>Empower your patients with online access to health records and appointment scheduling </div>
                    </div>
                </div>
              </div>
              <div className="col-lg-4 mb-lg-5 mb-4">
                <div className='key-features-div'>
                    <div className='key-features-icon'>
                        <img src={Icon07} width={50} height={50} className='img-fluid' alt=''/>
                    </div>
                    <div className='key-features-content'>
                        <div className='key-feature-head'>Cutting-Edge Telemedicine Integration </div>
                        <div className='key-feature-para'>Reach more patients with paid video and messaging consultations </div>
                    </div>
                </div>
              </div>
              <div className="col-lg-4 mb-lg-5 mb-4">
                <div className='key-features-div'>
                    <div className='key-features-icon'>
                        <img src={Icon10} width={50} height={50} className='img-fluid' alt=''/>
                    </div>
                    <div className='key-features-content'>
                        <div className='key-feature-head'>Lab Integration </div>
                        <div className='key-feature-para'>Seamless lab orders to network labs and testing centers. </div>
                    </div>
                </div>
              </div>
              <div className="col-lg-4 mb-lg-0 mb-4">
                <div className='key-features-div'>
                    <div className='key-features-icon'>
                        <img src={Icon04} width={50} height={50} className='img-fluid' alt=''/>
                    </div>
                    <div className='key-features-content'>
                        <div className='key-feature-head'>Accurate Prescription Management </div>
                        <div className='key-feature-para'>Create, track, and send prescriptions with ease </div>
                    </div>
                </div>
              </div>
              <div className="col-lg-4 mb-lg-0 mb-4">
                <div className='key-features-div'>
                    <div className='key-features-icon'>
                        <img src={Icon08} width={50} height={50} className='img-fluid' alt=''/>
                    </div>
                    <div className='key-features-content'>
                        <div className='key-feature-head'>Robust Security and Compliance </div>
                        <div className='key-feature-para'>Protect sensitive patient data with the state of the art security standards. </div>
                    </div>
                </div>
              </div>
              <div className="col-lg-4 mb-lg-0 mb-4">
                <div className='key-features-div'>
                    <div className='key-features-icon'>
                        <img src={Icon11} width={50} height={50} className='img-fluid' alt=''/>
                    </div>
                    <div className='key-features-content'>
                        <div className='key-feature-head'>Multi-location Mastery </div>
                        <div className='key-feature-para'>Manage multiple clinic locations with ease.</div>
                    </div>
                </div>
              </div>
              </div>
              </div>
        </div>
    )
  }
}
