import React, { useState, useEffect } from "react";
import Closebtn from "../../image/icons/close-sm.png";   
import AddImg from "../../image/icons/addbtn.png";
import $ from "jquery";

const Proceduredetails = React.forwardRef(({ getHospitalDropdown, proceduresData }, ref) => {
    const [procedures, setProcedures] = useState([]);
    const [procedureDetailsArray, setProcedureDetailsArray] = useState([]);

    useEffect(() => {
        setProcedures(getHospitalDropdown?.procedures);
        if (proceduresData.length > 0) {
            setProcedureDetailsArray(proceduresData);
        } else {
            setProcedureDetailsArray([]);
        }
    }, [getHospitalDropdown?.procedures, proceduresData]);

    const addProcedureDetails = () => {
        let flag = false;
        for (let i = 0; i <= procedureDetailsArray.length; i++) {
            if ($(`#cmbProcedures${i}`).val() === "0") {
                alert("Procedures Required");
                $(`#cmbProcedures${i}`).focus();
                flag = true;
                break;
            }
        }
        if (!flag) {
            const newProcedureDetails = {
                Id: parseFloat($("#cmbProcedures").val()),
                minPrice: parseFloat($("#txtMinPrice").val()),
                maxPrice: parseFloat($("#txtMaxPrice").val()),
                buyPrice: parseFloat($("#txtBuyPrice").val()),
                mtMinPrice: parseFloat($("#txtMtMinPrice").val()),
                mtMaxPrice: parseFloat($("#txtMtMaxPrice").val()),
                mtBuyPrice: parseFloat($("#txtMtBuyPrice").val()),
                isActive: $("#cmbStatus").val() === "true" ? true : false
            };
            setProcedureDetailsArray([...procedureDetailsArray, newProcedureDetails]);
        }
        $("input[type='text']").val('');
        $("#cmbProcedures").val('0');
        $("#cmbStatus").val('false');
    };

    const removeProcedureDetails = (Id) => {
        const newData = procedureDetailsArray.filter((item) => item.Id !== Id);
        setProcedureDetailsArray(newData);
    };

    const setProcedureData = (event, name, i, mode) => {
        const value = event.target.value;
        const newData = procedureDetailsArray.map((item, index) => {
            if (index === i) {
                if (mode === "cmbProcedures") {
                    return { ...item, Id: parseFloat(value) };
                } else if (mode === "txtMinPrice") {
                    return { ...item, minPrice: parseFloat(value) };
                } else if (mode === "txtMaxPrice") {
                    return { ...item, maxPrice: parseFloat(value) };
                } else if (mode === "txtBuyPrice") {
                    return { ...item, buyPrice: parseFloat(value) };
                } else if (mode === "txtMtMinPrice") {
                    return { ...item, mtMinPrice: parseFloat(value) };
                } else if (mode === "txtMtMaxPrice") {
                    return { ...item, mtMaxPrice: parseFloat(value) };
                } else if (mode === "txtMtBuyPrice") {
                    return { ...item, mtBuyPrice: parseFloat(value) };
                } else if (mode === "cmbStatus") {
                    return { ...item, isActive: value === "true" ? true : false };
                }
            }
            return item;
        });
        setProcedureDetailsArray(newData);
    };

    const handleChange = (event) => {
        const re = /^\d+$/;
        const value = event.target.value;
        const id = event.target.id;
        if (value === "" || !re.test(value)) {
            const newValue = value.toString().slice(0, -1);
            $(`#${id}`).val(newValue);
            if (!re.test($(`#${id}`).val())) {
                $(`#${id}`).val('');
                alert("Only Numbers Accepted");
            }
        }
    };

    const enablingPrices = () => {
        if ($("#cmbProcedures").val() !== "0") {
            $("input[type='text']").prop("disabled", false);
        } else {
            $("input[type='text']").prop("disabled", true);
        }
    };

    React.useImperativeHandle(ref, () => ({
        procedures: () => ({
            procedureDetail: procedureDetailsArray
        })
    }));
    return (
      <div className="bg-clr-wthHead mb-4">
          <section>
              <div className="consultngTime-wrapper">
                  <h2 className="hspInfoSubhead">Procedure Details</h2>
                  <div className="hsptaltimeHead">
                      <div className="educationdoctr-contentdiv">
                          <label className="educationdoctrlabelwidth">Procedures </label>
                          <select name="cmbProcedures" id="cmbProcedures" className="hspInfofieldinput eductninputwidthdctr" onChange={enablingPrices}>
                              <option value="0">Select</option>
                              {procedures.map((procedureObj, s) => (
                                  <option key={procedureObj.procedureId} value={procedureObj.procedureId}>{procedureObj.procedureName}</option>
                              ))}
                          </select>
                      </div>
                      <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                          <label htmlFor="">Min. Price <span className="priceinrtext">(INR)</span></label>
                          <input type="text" id="txtMinPrice" name="txtMinPrice" placeholder="Price" className="hspInfofieldinput priceinputproduredetl" onKeyUp={handleChange} onChange={handleChange} disabled />
                      </div>
                      <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                          <label htmlFor="">Max. Price <span className="priceinrtext">(INR)</span></label>
                          <input type="text" id="txtMaxPrice" name="txtMaxPrice" placeholder="Price" className="hspInfofieldinput priceinputproduredetl" onKeyUp={handleChange} onChange={handleChange} disabled />
                      </div>
                      <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                          <label htmlFor="">Buy Price <span className="priceinrtext">(INR)</span></label>
                          <input type="text" id="txtBuyPrice" name="txtBuyPrice" placeholder="Price" className="hspInfofieldinput priceinputproduredetl" onKeyUp={handleChange} onChange={handleChange} disabled />
                      </div>
                      <div className="addimgeDivHsp">
                          <img src={AddImg} alt="Add" onClick={addProcedureDetails} />
                      </div>
                  </div>
                  <div className="hsptaltimeHead">
                      <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                          <label htmlFor="">MT Min. Price <span className="priceinrtext">(USD)</span></label>
                          <input type="text" id="txtMtMinPrice" name="txtMtMinPrice" placeholder="Price" className="hspInfofieldinput priceinputproduredetl" onKeyUp={handleChange} onChange={handleChange} disabled />
                      </div>
                      <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                          <label htmlFor="">MT Max. Price <span className="priceinrtext">(USD)</span></label>
                          <input type="text" id="txtMtMaxPrice" name="txtMtMaxPrice" placeholder="Price" className="hspInfofieldinput priceinputproduredetl" onKeyUp={handleChange} onChange={handleChange} disabled />
                      </div>
                      <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                          <label htmlFor="">MT Buy Price <span className="priceinrtext">(USD)</span></label>
                          <input type="text" id="txtMtBuyPrice" name="txtMtBuyPrice" placeholder="Price" className="hspInfofieldinput priceinputproduredetl" onKeyUp={handleChange} onChange={handleChange} disabled />
                      </div>
                      <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                          <label htmlFor="">Status</label>
                          <select name="cmbStatus" id="cmbStatus">
                              <option value="false">Disabled </option>
                              <option value="true">Enabled </option>
                          </select>
                      </div>
                  </div>
                  {procedureDetailsArray.map((Obj, i) => (
                      <div key={Obj.Id}>
                          <div className="hsptaltimeHead">
                              <div className="educationdoctr-contentdiv">
                                  <label>Procedures </label>
                                  <select name={"cmbProcedures" + i} id={"cmbProcedures" + i} value={Obj.Id} className="hspInfofieldinput eductninputwidthdctr" onChange={(e) => setProcedureData(e, "cmbProcedures" + i, i, "cmbProcedures")}>
                                      <option value="0">Select</option>
                                      {procedures.map((procedureObj, s) => (
                                          <option key={procedureObj.procedureId} value={procedureObj.procedureId}>{procedureObj.procedureName}</option>
                                      ))}
                                  </select>
                              </div>
                              <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                                  <label htmlFor="">Min. Price <span className="priceinrtext">(INR)</span></label>
                                  <input type="text" id={"txtMinPrice" + i} name={"txtMinPrice" + i} value={Obj.minPrice} placeholder="Price" className="hspInfofieldinput priceinputproduredetl" onKeyUp={handleChange} onChange={(e) => setProcedureData(e, "txtMinPrice" + i, i, "txtMinPrice")} />
                              </div>
                              <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                                  <label htmlFor="">Max. Price <span className="priceinrtext">(INR)</span></label>
                                  <input type="text" id={"txtMaxPrice" + i} name={"txtMaxPrice" + i} value={Obj.maxPrice} placeholder="Price" className="hspInfofieldinput priceinputproduredetl" onKeyUp={handleChange} onChange={(e) => setProcedureData(e, "txtMaxPrice" + i, i, "txtMaxPrice")} />
                              </div>
                              <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                                  <label htmlFor="">Buy Price <span className="priceinrtext">(INR)</span></label>
                                  <input type="text" id={"txtBuyPrice" + i} name={"txtBuyPrice" + i} value={Obj.buyPrice} placeholder="Price" className="hspInfofieldinput priceinputproduredetl" onKeyUp={handleChange} onChange={(e) => setProcedureData(e, "txtBuyPrice" + i, i, "txtBuyPrice")} />
                              </div>
                          </div>
                          <div className="hsptaltimeHead">
                              <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                                  <label htmlFor="">MT Min. Price <span className="priceinrtext">(USD)</span></label>
                                  <input type="text" id={"txtMtMinPrice" + i} name={"txtMtMinPrice" + i} value={Obj.mtMinPrice} placeholder="Price" className="hspInfofieldinput priceinputproduredetl" onKeyUp={handleChange} onChange={(e) => setProcedureData(e, "txtMtMinPrice" + i, i, "txtMtMinPrice")} />
                              </div>
                              <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                                  <label htmlFor="">MT Max. Price <span className="priceinrtext">(USD)</span></label>
                                  <input type="text" id={"txtMtMaxPrice" + i} name={"txtMtMaxPrice" + i} value={Obj.mtMaxPrice} placeholder="Price" className="hspInfofieldinput priceinputproduredetl" onKeyUp={handleChange} onChange={(e) => setProcedureData(e, "txtMtMaxPrice" + i, i, "txtMtMaxPrice")} />
                              </div>
                              <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                                  <label htmlFor="">MT Buy Price <span className="priceinrtext">(USD)</span></label>
                                  <input type="text" id={"txtMtBuyPrice" + i} name={"txtMtBuyPrice" + i} value={Obj.mtBuyPrice} placeholder="Price" className="hspInfofieldinput priceinputproduredetl" onKeyUp={handleChange} onChange={(e) => setProcedureData(e, "txtMtBuyPrice" + i, i, "txtMtBuyPrice")} />
                              </div>
                              <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                                  <label htmlFor="">Status</label>
                                  <select name={"cmbStatus" + i} id={"cmbStatus" + i} value={Obj.isActive} onChange={(e) => setProcedureData(e, "cmbStatus" + i, i, "cmbStatus")}>
                                      <option value="false">Enabled </option>
                                      <option value="true">Disabled </option>
                                  </select>
                              </div>
                              <div className="addimgeDivHsp">
                                  <img src={Closebtn} alt="Close" onClick={() => removeProcedureDetails(Obj.Id)} width="20" />
                              </div>
                          </div>
                      </div>
                  ))}
              </div>
          </section>
      </div>
  );
  
}
)

export default Proceduredetails;
