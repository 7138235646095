import React, { Component, Suspense } from "react";
import Header from "../../layout/header"
import Footer from "../../layout/footer"

class siteSettingsSiteModuleByDoctor extends Component {
  state = {};
  render() {
    return (
      <div className="purpleWrap bg-clr-wthHead">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
            <div className="container">
              <div className="sectionItemDiv sectionItemDivBorder sectionBorderadminMangmnt">
                <div className="adminMangemnt-dashboard">
                Site Module by doctor
                </div>
              </div>
            </div>
          </section>
        </Suspense>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}

export default siteSettingsSiteModuleByDoctor;
