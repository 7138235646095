import React, { Component, Suspense } from "react";
import Edit from "../../../../image/icons/edit.png";
import tick from "../../../../image/icons/tick.png";
import Deactivate from "../../../../image/icons/deactvate.png";
import { apiCalling } from "../../../../apiService";
import $ from "jquery";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import Header from "../../../../layout/header";
import Footer from "../../../../layout/footer";
import Close from "../../../../image/icons/close-mark.png"

// import CountriesPopup from "./countriesPopup";
// import addCountryPopup from "./addCountryPopup"
const folderName = window.localStorage.getItem("homeName");
//const Header = React.lazy(() => import("../../" + folderName + "/header"));
//const Footer = React.lazy(() => import("../../" + folderName + "/footer"));

export default class cities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cityManagementData: [],
      totalCount: 0,
      activePage: 1,
      showMessage: "Waiting for data... ",
      cityId:0,
      city:"",
      aliasName:"",
      stateId:0,
      stateDropDown:[]
    };
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
    var Offset;
    var max;
    if (pageNumber === "1") {
      Offset = "0";
      max = "10";
      this.setState({
        activePage: pageNumber,
        isLoading: "0",
      });
    } else {
      Offset = parseInt(pageNumber - 1) * 10;
      max = "10";
      this.setState({
        activePage: pageNumber,
        isLoading: "0",
      });
    }
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "citiesList",
        searchKey:
          $("#selectCategory").val() === "0" ? "" : $("#selectCategory").val(),
        searchString: $("#cityTxt").val(),
        offset: JSON.stringify(Offset),
        max: max,
      }),
    };
    $(".loader").show();
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        this.setState({
          cityManagementData: data.result.cityData,
          totalCount: data.result.totalCount,
        });
        $(".loader").hide();
      }
    });
  }

  componentDidMount() {
    this.getCityDetails();
    this.dropdownList();
  }
dropdownList(){
  const apiJson = {
    method: "POST",
    body: JSON.stringify({
      functionName: "getDropdownLists"
    })
  };
  apiCalling(apiJson).then((data) => {
    if (data.success === "1") {
      this.setState({
        stateDropDown:data.result[0].stateData
      });
    }
  });
}
  getCityDetails() {
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "citiesList",
        searchKey: "",
        searchString: "",
        max: "10",
        offset: "0",
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        $(".loader").hide();
        this.setState({
          cityManagementData: data.result.cityData,
          totalCount: data.result.totalCount,
        });
      }
      if (data.success === "0") {
        $(".loader").hide();
        this.setState({
          cityManagementData: [],
          showMessage: data.errorMessage,
        });
      }
    });
  }

  changeText = () => {
    if ($("#selectCategory").val() !== "0") {
      document.getElementById("cityTxt").disabled = false;
      $("input[name=cityTxt]").focus();
    } else {
      document.getElementById("cityTxt").disabled = true;
    }
    document.getElementById("cityTxt").value = "";
  };

  setDropDownState = (event) => {
  //  / alert($("#txtStateDropdownId").val());
    if ($("#txtStateDropdownId").val() !== "0") {
      // console.log('valuuuue.....',$("#txtStateDropdownId").val())
      // this.setState({
      //   stateId:$("#txtStateDropdownId").val()
      // })
      this.setState({
        stateId:event.target.value
      })
    }
  }
  searchHandler() {
    //debugger;
    if ($("#selectCategory").val() !== "0" && $("#cityTxt").val() === "") {
      var t = document.getElementById("selectCategory");
      var selectedText = t.options[t.selectedIndex].text;
      alert(selectedText + " Required");
      $("#cityTxt").focus();
      return false;
    } else {
      let word = $("#cityTxt").val()
      let newString = word.replace(/\s+/g,'').trim();
      const searchData = {
        method: "POST",
        body: JSON.stringify({
          functionName: "citiesList",
          searchKey:
            $("#selectCategory").val() === "0"
              ? ""
              : $("#selectCategory").val(),
          searchString: newString,
          offset: "0",
          max: "10",
        }),
      };
      $(".loader").show();
      apiCalling(searchData).then((data) => {
        
        if (data.success === "1") {
          $("#paginationType").show();
          $(".loader").hide();
          this.setState({
            cityManagementData: data.result.cityData,
            totalCount: data.result.totalCount,
            activePage: 1,
          });
        }
        if (data.success === "0") {
          $("#paginationType").hide();
          $(".loader").hide();
          this.setState({
            cityManagementData: [],
            showMessage: data.errorMessage,
          });
        }
      });
    }
  }
  onTodoChangeCity = (value) => {
    this.setState({
      city:value
    })
  }
  onTodoChangeAlias = (value) => {
    this.setState({
      aliasName:value
    })
  }
  category1PopOpen(){
    $("#popup1").show()
        $(".popOverlay").css("background-color","rgba(0, 0, 0, 0.6)");
        $("#addCitySave").show();
        $("#txtStateId").focus();       
        $("#txtCity").focus();
        $("#txtAlias").focus();
  }
  category2PopOpen = (cityId)=>{
  //  alert(cityId)
    const searchData = {
      method: "POST",
      body: JSON.stringify({
        functionName:"citiesEditList",
        cityId:cityId
      })
    }
    apiCalling(searchData).then((data) => {
      if(data.success === "1"){
        this.setState({
          cityId: data.result.cityData[0].cityId,
          city: data.result.cityData[0].city,
          aliasName: data.result.cityData[0].aliasName,
          stateId: data.result.cityData[0].stateId,
        })
      }
    })

    $("#popup2").show()
        $(".popOverlay").css("background-color","rgba(0, 0, 0, 0.6)");
        $("#txtCityId").show();
        $("#cityId").show();
        $("#UpdateDetails2").show();
        $("#txtStateId").focus();       
        $("#txtCity").focus();
        $("#txtAlias").focus();
  }
  closepopup(){
    $("#popup1").hide();
    $("#popup2").hide();
    $(".popOverlay").css("background-color","transparent");
    $("#txtStateId").val('')
    $("#txtCity").val('')
    $("#txtAlias").val('')
    this.setState({
      cityId: 0,
      city: "",
      aliasName: "",
      stateId: 0,
    })
}
saveHandle() {

  let stateId = this.state.stateId;
  let cityId = this.state.cityId;
  var flag = true
  if(cityId==0){
  let cityName = $("#txtCity").val();
  let alias = $("#txtAlias").val();
    if (stateId === 0) {
        alert("State is Required")
        flag = false;
    }
    if ($("#txtCity").val() === "") {
      alert("City is Required")
      $("#txtCity").focus();
      flag = false;
  }
    if (flag === true) {
      
        const dataSave = {
            method:"POST",
            body: JSON.stringify({
                functionName:"citiesAddList",
                aliasName:alias,
                cityName:cityName,
                stateId: stateId
            }),
        };
        apiCalling(dataSave).then((data) => {
            if (data.success === "1") {
                let message =
                  data.CityId > 0
                  ? "Updated successfully "
                  : "Saved successfully";
             
                $("#popup1").hide()
                $("#popup2").hide()
                alert(message);
                //window.location.reload();
                setTimeout(() => {
                    window.location.reload();
                  }, 1000);
            } else {
                alert(data.errorMessage)
                $("#txtCity").focus();
            }
        })
    }
  }
  else{
  let cityName = $("#txtCity2").val();
  let alias = $("#txtAlias2").val();
    if (stateId === 0) {
      alert("State Id Required")
      flag = false;
  }
  if ($("#txtCity2").val() === "") {
    alert("City Required")
    $("#txtCity2").focus();
    flag = false;
  } 
if ($("#txtAlias2").val() === "") {
  alert("City Alias Required")
  $("#txtAlias2").focus();
  flag = false;
  } 
  
  if (flag === true) {
    
      const dataSave = {
          method:"POST",
          body: JSON.stringify({
              functionName:"citiesAddList",
              aliasName:alias,
              cityName:cityName,
              stateId: stateId,
              cityId:cityId
          }),
      };
      apiCalling(dataSave).then((data) => {
          if (data.success === "1") {
              let message =
                data.CityId > 0
                ? "Updated successfully "
                : "Saved successfully";
            alert(message);
              $("#popup1").hide()
              $("#popup2").hide()
              setTimeout(() => {
                  window.location.reload();
                }, 1000);
          } else {
              alert(data.errorMessage)
              $("#txtCity").focus();
          }
      })
  }
  }
}
  render() {
    return (
      <div className="bg-clr-wthHead">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
            <div className="loader"></div>
            <div className="container mb-5">
              <div>
                <ul className="breadcrum-adminMangmnt">
                  <li>
                    <a href="/admin/dashboardAdmin">Dashboard</a>
                  </li>
                  <li>
                    <a href="/adminManagement/manageLocations">
                      Manage Locations
                    </a>
                  </li>
                  <li>
                    <a href="/adminManagement/countries">
                      Country
                    </a>
                  </li>
                  <li>
                    <a href="/adminManagement/states">
                      State
                    </a>
                  </li>
                  <li>
                    <a href="/adminManagement/cities/" className="current">
                      City
                    </a>
                  </li>
                </ul>
              </div>
              <div className="head-hospMangmntList">Cities</div>

              <div className="HosptlMangemntTable">
                <div className="AddNewHspbtnDiv">
                  <button className="addnewhspbtn" onClick={this.category1PopOpen}>Add New City</button>
                  
                </div>
                {this.state.totalCount > 5 ? (
                  <div
                    className="paginationSection orderm-paginationSection pagination-HspList"
                    id="paginationType"
                  >
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={10}
                      totalItemsCount={this.state.totalCount}
                      pageRangeDisplayed={10}
                      onChange={this.handlePageChange}
                    />
                  </div>
                ) : (
                  ""
                )}
                <div className="tableHeader-HspMangemnt">
                  <div className="selectField-HspMangmnt">
                    <select
                      className="slecthsp slecthspMob"
                      id="selectCategory"
                      name="selectCategory"
                      onChange={() => this.changeText()}
                    >
                      <option value="0">Search In</option>
                      <option value="id">City Id</option>
                      <option value="cityName">City</option>
                      <option value="aliasName">City Alias</option>
                    </select>
                  </div>
                  <div className="selectField-HspMangmnt">
                    <input
                      type="text"
                      className="hspmngInput hspmngInputmob"
                      name="cityTxt"
                      id="cityTxt"
                      disabled
                      tabIndex="0"
                      onKeyPress={(event) => {
                        if (
                          event.key === "Enter" ||
                          event.key === "NumpadEnter"
                        ) {
                          event.preventDefault();
                          this.searchHandler();
                        }
                      }}
                    />
                  </div>
                  <div className="selectField-HspMangmnt">
                    <button
                      className="searchbtn-hspMng"
                      onClick={() => this.searchHandler()}
                    >
                      Search
                    </button>
                  </div>
                </div>
                {this.state.cityManagementData.length > 0 ? (
                  <div className="table-responsive">
                    <table className="table table-bordered-hsp">
                      <thead>
                        <tr>
                          <th className="tablehead-hspmng" colSpan="2">
                            ID
                          </th>
                          <th className="tablehead-hspmng" colSpan="4">
                            City
                          </th>
                          <th className="tablehead-hspmng" colSpan="3">
                            City Alias
                          </th>
                          <th className="tablehead-hspmng" colSpan="3">
                            State
                          </th>
                          <th className="tablehead-hspmng" colSpan="4">
                            Edit City
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.cityManagementData.map((data) => (
                          <tr key={data.cityId}>
                            <td colSpan="2">{data.cityId}</td>
                            <td colSpan="4">{data.city}</td>
                            <td colSpan="3">{data.aliasName}</td>
                            <td colSpan="3">{data.state}</td>
                            <td colSpan="4" className="nowrap">
                              <div className="ph_actn_img">
                                <div className="mr-2 ">
                                  <img
                                    src={Edit}
                                    alt=""
                                    width="18"
                                    className="img-fluid"
                                    style={{cursor:"pointer"}}
                                    onClick={()=>{this.category2PopOpen(data.cityId)}}
                                    />
                                  </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {this.state.totalCount > 5 ? (
                      <div className="paginationSection orderm-paginationSection pagination-HspList pagination-HspListwidth">
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={10}
                          totalItemsCount={this.state.totalCount}
                          pageRangeDisplayed={10}
                          onChange={this.handlePageChange}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <p className="error-msg text-center mb-0">
                    {" "}
                    {this.state.showMessage}{" "}
                  </p>
                )}
              </div>
            </div>
          </section>
        </Suspense>
        <div className="popBack" id="popup1">
          <div className="popOverlay" onClick={this.category1PopClose}></div>
          <div className="customizationPop">
              <input type="hidden" id="stateId" />
              <div className="popuphead-qualifctn">
                <h2>City Details</h2>
              </div>
              <span className="closepopupqual" type="button" onClick={() => this.closepopup()} ><img src={Close} alt="Close" className="img-fluid" width="25" /></span>
                <div className="popupdiv">
                  <label htmlFor="" className="qualifcatin-popuplabel">City <span className="star-red-qual">*</span></label>
                  <input type="text" className="qualifcatin-popupInput" id="txtCity" name="txtCity"  placeholder="City" />
                  <label htmlFor="" className="qualifcatin-popuplabel">City Alias <span className="star-red-qual">*</span></label>
                  <input type="text" className="qualifcatin-popupInput" id="txtAlias" name="txtAlias"  placeholder="Alias" />
                  <label htmlFor="" className="qualifcatin-popuplabel" id ="stateDropDown">State <span className="star-red-qual">*</span></label>
                  <div className="selectField-HspMangmnt">
                    
                      <select
                      className="slecthsp slecthspMob"
                      id="txtStateDropdownId"
                      name="selectState"
                      value={this.state.stateId} onChange={this.setDropDownState}
                     // onChange={()=>this.setDropDownState()}
                      >
                        <option value="0">Select City</option>
                        {this.state.stateDropDown.map((data)=>(
                      <option key={data.stateId} value={data.stateId}>{data.stateName}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="popupbutndiv">
                  <button className="butnsave-qual"  id="addCitySave"  onClick={() => this.saveHandle()}>Save</button>
                  <button className="butncancel-qual"onClick={() => this.closepopup()}>Cancel</button>
                </div>
          </div>
        </div>
        <div className="popBack" id="popup2">
          
          <div className="popOverlay" onClick={this.category2PopClose}></div>
          <div className="customizationPop">
              <input type="hidden" id="stateId" />
              <div className="popuphead-qualifctn">
                <h2>City Details</h2>
              </div>
              <span className="closepopupqual" type="button" onClick={() => this.closepopup()} ><img src={Close} alt="Close" className="img-fluid" width="25" /></span>
                <div className="popupdiv">
                  <label htmlFor="" className="qualifcatin-popuplabel">City <span className="star-red-qual">*</span></label>
                  <input type="text" className="qualifcatin-popupInput" id="txtCity2" name="txtCity2"  onChange={(e)=>this.onTodoChangeCity(e.target.value)} value={this.state.city} />
                  <label htmlFor="" className="qualifcatin-popuplabel">City Alias <span className="star-red-qual">*</span></label>
                  <input type="text" className="qualifcatin-popupInput" id="txtAlias2" name="txtAlias2" onChange={(e)=>this.onTodoChangeAlias(e.target.value)}  value={this.state.aliasName} />
                  <label htmlFor="" className="qualifcatin-popuplabel" id ="stateDropDown">State <span className="star-red-qual">*</span></label>
                  <div className="selectField-HspMangmnt">
                    
                      <select
                      className="slecthsp slecthspMob"
                      id="txtStateDropdownId"
                      name="selectState"
                      //onChange={()=>this.setDropDownState()}
                      value={this.state.stateId} onChange={this.setDropDownState}
                      >
                      <option key="0" value="0">Select City</option>
                        {this.state.stateDropDown.map((data)=>(
                      <option key={data.stateId} value={data.stateId} selected={data.stateId === this.state.stateId}>{data.stateName}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="popupbutndiv">
                  <button className="butnsave-qual" id="UpdateDetails2" onClick={() => this.saveHandle()}>Update</button>
                  <button className="butncancel-qual"onClick={() => this.closepopup()}>Cancel</button>
                </div>
          </div>
        </div>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}

// import React, { Component, Suspense } from "react";
// import $, { event } from "jquery";
// import CountriesPopup from "./countriesPopup";
// import addCountryPopup from "./addCountryPopup"
// import Header from "../../../../layout/header";
// import Footer from "../../../../layout/footer";
// import { apiCalling } from "../../../../apiService";
// import Edit from "../../../../image/icons/edit.png";
// import Pagination from "react-js-pagination";

// class countries extends React.Component {
//   state = {
//     currentPage: "Countries",
//     cmbCategory: "",
//     txtInput: "",
//     max: "10",
//     Offset: "0",
//     hospitalProductData: [],
//     countryData:[],
//     activePage: 1,
//     navigation: [
//       { pageName: "Dashboard", url: "/admin/dashboard" },
//       {
//         pageName: "Manage Locations",
//         url: "/adminManagement/manageLocations",
//       },
//       {
//         pageName: "Countries",
//         url: "/adminManagement/countries",
//         current: true,
//       },
//     ],
//     showCountriesPopup: false,
//     hospitalDeatils: [],
//   };

//   componentDidMount() {
//     this.getCountryListing();
//   }

//   changeHandler = (e) => {
//     this.setState({ [e.target.id]: e.target.value });
//   };

//   async handlePageChange(pageNumber) {
//     await this.setState({
//       activePage: pageNumber,
//       Offset: (parseInt(pageNumber) - 1) * 10,
//     });

//     this.getCountryListing();
//   }

//   handleCmbSerchChange = () => {
//     var cmbSelectVal =
//       $("#cmbCategory").val() !== "0" ? $("#cmbCategory").val() : "";
//     $("#txtInput").prop("disabled", cmbSelectVal !== "" ? false : true);
//     this.setState({ cmbCategory: cmbSelectVal, txtInput: "" });
//     $("#txtInput").focus();
//   };
//   handleAddFunction = (hospital) =>{
//     this.setState({
//       showCountriesPopup: true,
//       hospitalDeatils: hospital,
//     });
//   }

//   editProduct = (hospital) => {
//     this.setState({
//       showCountriesPopup: true,
//       hospitalDeatils: hospital,
//     });
//   };

//   getCountryListing() {
//     $(".loader").show();
//     const productListing = {
//       method: "POST",
//       body: JSON.stringify({
//         functionName: "getDropdownLists"
//       }),
//     };
//     apiCalling(productListing).then((data) => {
//       if (data.success === "1") {
//         alert("success")
//         var countryData = data.result[0].countryData;
//         this.setState({
//           countryData:countryData
//         });
//       } else if (data.success === "0") {
//         alert("failure")
//         this.setState({ hospitalProductData: [], totalCount: 0 });
//       }
//       $(".loader").hide();
//     });
//   }

//   // handleSaveHospitalProductData = (state) => {
//   //   let hospitalProductData = state.hospitalProductData;
//   //   let commision = [];
//   //   let productId = [];
//   //   let statusLists = [];
//   //   let expirydays = hospitalProductData.find(
//   //     (element) => element.productId == 2
//   //   ).expiryDays;

//   //   hospitalProductData.map((e) => {
//   //     //if (e.status) {
//   //       commision.push(e.commision == "" ? 0 : e.commision);
//   //       productId.push(e.productId);
//   //       statusLists.push(e.status?1:0)
//   //     //}
//   //   });
   
//   //   $(".loader").show();
//   //   const productSave = {
//   //     method: "POST",
//   //     body: JSON.stringify({
//   //       commision: commision,
//   //       functionName: "productSave",
//   //       expirydays: expirydays == "" ? 0 : expirydays,
//   //       productId: productId,
//   //       status: statusLists,  
//   //       hospitalId: state.hospitalId,              
//   //     }),
//   //   };
//   //   console.log(productSave.body);
//   //   apiCalling(productSave).then((data) => {
//   //     debugger;
//   //     if (data.success === "1") {
//   //       this.setState({
//   //         showCountriesPopup: false,
//   //         hospitalDeatils: [],
//   //       });
//   //     } else if (data.success === "0") {
//   //     }
//   //     $(".loader").hide();
//   //   });
//   // };

//   renderHospitalProductData() {
//     if (this.state.countryData.length > 0) {
//       console.log("countryData",this.state.countryData)
//       return this.state.countryData.map((index) => (
//         <tr key={index.countryId}>
//           <td style={{ width: "10vw" }}>{index.countryId}</td>
//           <td>{index.countryName}</td>
//           <td>{index.countryCode}</td>
//           <td style={{ width: "10vw" }} className="nowrap ph_siteedit">
//             <div>
//               <span>
//                 <a className="mr-2" onClick={() => this.editProduct(index)}>
//                   <img src={Edit} alt="Edit" width="18" className="img-fluid" />
//                 </a>
//               </span>
//             </div>
//           </td>
//         </tr>
//       ));
//     } else {
//       return (
//         <tr>
//           <td colSpan="6" className="text-center">
//             No Data Found
//           </td>
//         </tr>
//       );
//     }
//   }

//   RenderList() {
//     return (
//       <div className="HosptlMangemntTable table-responsive">
//         <div className="AddNewHspbtnDiv">
//           <button className="addnewhspbtn" onClick = {(hospital) => this.handleAddFunction(hospital)}>
//             Add New Country</button>        
//         </div>
//         <div className="tableHeader-HspMangemnt">
//           <div className="selectField-HspMangmnt">
//             <select
//               className="slecthsp"
//               id="cmbCategory"
//               onChange={() => this.handleCmbSerchChange()}
//             >
//               <option value="0">Search In</option>
//               <option value="1">Country Id</option>
//               <option value="1">Country Code</option>
//               <option value="2">Country Name</option>
//             </select>
//           </div>
//           <div className="selectField-HspMangmnt">
//             <input
//               type="text"
//               className="hspmngInput"
//               name="txtInput"
//               id="txtInput"
//               disabled
//               value={this.state.txtInput}
//               onInput={this.changeHandler}
//             />
//           </div>
//           <div className="selectField-HspMangmnt">
//             <button
//               className="searchbtn-hspMng"
//               onClick={() => this.getCountryListing()}
//             >
//               Search
//             </button>
//           </div>
//         </div>

//         <table className="table table-bordered-hsp">
//           <thead>
//             <tr>
//               <th className="tablehead-hspmng">Id</th>
//               <th className="tablehead-hspmng">Country Name</th>
//               <th className="tablehead-hspmng">Code</th>
//               <th className="tablehead-hspmng">Actions</th>
//             </tr>
//           </thead>
//           <tbody>{this.renderHospitalProductData()}</tbody>
//         </table>
//         {this.state.totalCount > 5 ? (
//           <div className="paginationSection orderm-paginationSection pagination-HspList pagination-HspListwidth">
//             <Pagination
//               activePage={this.state.activePage}
//               itemsCountPerPage={10}
//               totalItemsCount={this.state.totalCount}
//               pageRangeDisplayed={10}
//               onChange={(pageNumber) => this.handlePageChange(pageNumber)}
//             />
//           </div>
//         ) : (
//           ""
//         )}
//       </div>
//     );
//   }

//   render() {
//     return (
//       <div className="purpleWrap bg-clr-wthHead">
        
//         <Suspense fallback={<div>Waiting...</div>}>
//           <section>
//             <Header />
//             <div className="loader"></div>
//             <div className="container">
//               <div>
                
//                 <ul className="breadcrum-adminMangmnt">
//                   {this.state.navigation.map((nav) => (
//                     <li>
//                       <a
//                         href={nav.url}
//                         className={nav.current ? "current" : null}
//                       >
//                         {nav.pageName}
//                       </a>
//                     </li>
//                   ))}
//                 </ul>
//               </div>
//               <div className="head-hospMangmntList">
//                 {this.state.currentPage}
//               </div>
//               {
//               <addCountryPopup/>
//               } 
//               {this.RenderList()}
//             </div>
//           </section>
//         </Suspense>
//         <Suspense fallback={<div></div>}>
//           <section>
//             <Footer />
//           </section>
//         </Suspense>

//         {this.state.showCountriesPopup == true && (
//           <CountriesPopup
//             showCountriesPopup={(data) =>
//               this.setState({ showCountriesPopup: data })
//             }
//             hospitalDeatils={this.state.hospitalDeatils}
//             // saveHospitalProductData={this.handleSaveHospitalProductData}
//           />
//         )}
//       </div>
//     );
//   }
// }
// export default countries;
