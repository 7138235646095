import React, { Component } from 'react';
import Solution2 from "../image/theme/pdtpharma-company2.png"
import Solution3 from "../image/theme/pdtpharma-company3.png"
import Solution4 from "../image/theme/pdtpharma-company4.png"
import Websitebranding from "../image/theme/pdtpharma-company1.png"
import Add from "../image/theme/add.png"
import Close from "../image/theme/closebtn.png"
export default class digitalBrandingSolutions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isContentVisible: false,
            isContentVisibleTwo:false,
            isContentVisibleThree:false,
            isContentVisibleFour:false
          };
    }
    toggleVisibility = (contentId) => {
        this.setState((prevState) => ({
          [contentId]: !prevState[contentId],
        }));
      };
    render() {
        const { isContentVisible, isContentVisibleTwo, isContentVisibleThree,isContentVisibleFour } = this.state;
        return (
            <div className="ph-main-outerdiv">
                <div className='container-lg'>
                    <div className="row">
                        {/* <div className="col-lg-12">
                            <div className="ph-db-banner">
                                <h2 className="ph-main-title-2 mt-5">
                                Our Solutions for Digital Branding
                                </h2>
                            </div>
                        </div> */}
                        <div className="col-lg-12">
                            <div className={isContentVisible ? 'd-md-flex flex-md-equal w-100 my-5 ph-border-img' : 'd-md-flex flex-md-equal w-100 my-5'}>
                                <div className="col-lg-6 px-0">
                                    <div className='ph-local-pm-more'>
                                        <img src={Websitebranding} alt="digitalBrand" width="605" height="291" className='img-fluid pdt-long' />
                                    </div>
                                </div>
                                <div className="col-lg-6 px-0 ph-right-center">
                                    <div className="ph-solutions">
                                        <h3 className="ph-solution-title">PharmaNet</h3>
                                        <p className="ph-solution-content">
                                        {!isContentVisible ?
                                            "Share product, marketing, promotional or related communications on the news feed section of the doctor app. Ability to run target group specific focused campaigns. Analytics for data driven marketing strategy."
                                            :
                                            "Expand your reach and attract more patients. Run campaigns across social platforms to generate leads, engagement etc."
                                        }
                                        </p>
                                        {/* <h4 className="ph-solution-sub">
                                            Key Features :
                                        </h4>
                                        <div className="">
                                            <div className="ph-key-features">
                                            Attractive design with your own clinic branding
                                            </div>
                                            <div className="ph-key-features">
                                            Optimised website for easy discovery on Google and other search engines
                                            </div>
                                           
                                            <div className={` ${isContentVisible ? 'visible-class' : 'invisible-class'}`}>
                                                <div className="ph-key-features">
                                                Appointment calendars for patient booking that is directly integrated to your Clinic Management System
                                                </div>
                                                <div className="ph-key-features">
                                                Built-in telehealth technology for better patient engagement
                                                </div>
                                                <div className="ph-key-features">
                                                Patient focused tools for easy access and updates
                                                </div>
                                                </div>

                                        </div> */}
                                        <button className={`ph-learn-more ${isContentVisible ? 'ph-btn-show' : 'ph-btn-hide'}`} onClick={() => this.toggleVisibility("isContentVisible")}>
                                                <span className={`isspan ${isContentVisible ? 'isspanHide' : 'isspanShow'}`}>Learn More</span>
                                                <span className='text-right ph-icon-wrapper'>
                                                    {isContentVisible ?
                                                    <img src={Close} alt="Add" className='img-fluid' width="20" height="20" />:
                                                    <img src={Add} alt="Close" className='img-fluid' width="20" height="20" />
                                                    }
                                                </span>
                                            </button>
                                    </div>
                                </div>
                            </div>
                            <div className={isContentVisibleTwo ? 'd-md-flex flex-md-equal w-100 my-5 ph-border-img' : 'd-md-flex flex-md-equal w-100 my-5'}>
                                <div className="col-lg-6 px-0">
                                    <div className='ph-local-pm-more'>
                                        <img src={Solution2} alt="digitalBrand" width="605" height="291" className='img-fluid pdt-long' />
                                    </div>
                                </div>
                                <div className="col-lg-6 px-0 ph-right-center">
                                    <div className="ph-solutions">
                                        <h3 className="ph-solution-title">MR Connect</h3>
                                        <p className="ph-solution-content">
                                            {!isContentVisibleTwo ?
                                            "Map MRs to specific doctors. MRs chat with doctors and attach media & voice messages similar to whatsApp. Get analytics of MR – doctor engagement."
                                            :
                                            "Increase your visibility in search engine results and attract more patients. We optimize your online content, making it easier for potential patients to find you."
                                        }
                                        </p>
                                        {/* <h4 className="ph-solution-sub">
                                            Key Features :
                                        </h4>
                                        <div className="">
                                            <div className="ph-key-features">
                                            Electronic health records for both doctors and patients
                                            </div>
                                            <div className="ph-key-features">
                                                Ensures confidentiality and security of patients' data
                                            </div>
                                            <div className={` ${isContentVisibleTwo ? 'visible-class' : 'invisible-class'}`}>
                                                    <div className="ph-key-features">
                                                        Follows HIPAA complaint standards
                                                    </div>
                                                    <div className="ph-key-features">
                                                        Facilitate collaboration among doctors working across different clinics
                                                    </div>
                                                    <div className="ph-key-features">
                                                        Manages appointment scheduling and resource management
                                                    </div>
                                                    <div className="ph-key-features">
                                                        Integrated prescription and billing
                                                    </div>
                                                </div>

                                        </div> */}
                                        <button className={`ph-learn-more ${isContentVisibleTwo ? 'ph-btn-show' : 'ph-btn-hide'}`} onClick={() => this.toggleVisibility("isContentVisibleTwo")}>
                                                <span className={`isspan ${isContentVisibleTwo ? 'isspanHide' : 'isspanShow'}`}>Learn More</span>
                                                <span className='text-right ph-icon-wrapper'>
                                                    {isContentVisibleTwo ?
                                                    <img src={Close} alt="Add" className='img-fluid' width="20" height="20" />:
                                                    <img src={Add} alt="Close" className='img-fluid' width="20" height="20" />
                                                    }
                                                </span>
                                            </button>
                                    </div>
                                </div>
                            </div>
                            <div className={isContentVisibleThree ? 'd-md-flex flex-md-equal w-100 my-5 ph-border-img' : 'd-md-flex flex-md-equal w-100 my-5'}>
                                <div className="col-lg-6 px-0">
                                    <div className='ph-local-pm-more'>
                                        <img src={Solution3} alt="digitalBrand" width="605" height="291" className='img-fluid pdt-long' />
                                    </div>
                                </div>
                                <div className="col-lg-6 px-0 ph-right-center">
                                    <div className="ph-solutions">
                                        <h3 className="ph-solution-title">DocEd</h3>
                                        <p className="ph-solution-content">
                                            {!isContentVisibleThree ?
                                            "Education platform for doctors. Conduct webinars, CME & CPD programs, certifications, marketing events, quizzes, contests, brand/ product launches, etc."
                                            :
                                            "Understand patient needs, discover patient trends, grasp healthcare dynamics and enhance patient satisfaction and loyalty."
                                        }
                                        
                                        </p>
                                        {/* <h4 className="ph-solution-sub">
                                            Key Features :
                                        </h4> */}
                                        {/* <div className="">
                                            <div className="ph-key-features">
                                            Enhance your online visibility through targeted keywords
                                            </div>
                                            <div className="ph-key-features">
                                            Establish a robust presence across various digital platforms
                                            </div>
                                            <div className={` ${isContentVisibleThree ? 'visible-class' : 'invisible-class'}`}>
                                                    <div className="ph-key-features">
                                                       Optimise your content for better search engine performance
                                                    </div>
                                                   
                                                </div>
                                        </div> */}
                                        <button className={`ph-learn-more ${isContentVisibleThree ? 'ph-btn-show' : 'ph-btn-hide'}`}  onClick={() => this.toggleVisibility("isContentVisibleThree")}>
                                                    <span className={`isspan ${isContentVisibleThree ? 'isspanHide' : 'isspanShow'}`}>Learn More</span>
                                                    <span className='text-right ph-icon-wrapper'>
                                                    {isContentVisibleThree ?
                                                    <img src={Close} alt="Add" className='img-fluid' width="20" height="20" />:
                                                    <img src={Add} alt="Close" className='img-fluid' width="20" height="20" />
                                                    }
                                                </span>
                                            </button>
                                    </div>
                                </div>
                            </div>
                            <div className={isContentVisibleFour ? 'd-md-flex flex-md-equal w-100 my-5 ph-border-img' : 'd-md-flex flex-md-equal w-100 my-5'}>
                                <div className="col-lg-6 px-0">
                                    <div className='ph-local-pm-more'>
                                        <img src={Solution4} alt="digitalBrand" width="605" height="291" className='img-fluid pdt-long' />
                                    </div>
                                </div>
                                <div className="col-lg-6 px-0 ph-right-center">
                                    <div className="ph-solutions">
                                        <h3 className="ph-solution-title">PurplePoll</h3>
                                        <p className="ph-solution-content">
                                            {!isContentVisibleFour ?
                                            "Conduct opinion polls and surveys with your doctors. Inceltivize participation. Drive innovation, enhance product development and improve rois with the power of market research and doctor feedback directly in your hands."
                                            :
                                            "Understand patient needs, discover patient trends, grasp healthcare dynamics and enhance patient satisfaction and loyalty."
                                        }
                                        
                                        </p>
                                         {/* <h4 className="ph-solution-sub">
                                            Key Features :
                                        </h4>
                                        <div className="">
                                            <div className="ph-key-features">
                                            Develop a compelling brand identity for your healthcare practice
                                            </div>
                                            <div className="ph-key-features">
                                            Implement brand consistency across various touchpoints
                                            </div>
                                            <div className={` ${isContentVisible ? 'visible-class' : 'invisible-class'}`}>
                                                    <div className="ph-key-features">
                                                        Tailor your brand messaging to effectively communicate your healthcare values and mission
                                                    </div>
                                                    
                                                </div>
                                        </div> */}
                                        <button className={`ph-learn-more ${isContentVisibleFour ? 'ph-btn-show' : 'ph-btn-hide'}`} onClick={() => this.toggleVisibility("isContentVisibleFour")}>
                                                <span className={`isspan ${isContentVisibleFour ? 'isspanHide' : 'isspanShow'}`}>Learn More</span>
                                                <span className='text-right ph-icon-wrapper'>
                                                    {isContentVisibleFour ?
                                                    <img src={Close} alt="Add" className='img-fluid' width="20" height="20" />:
                                                    <img src={Add} alt="Close" className='img-fluid' width="20" height="20" />
                                                    }
                                                </span>
                                            </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}
