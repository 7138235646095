import React, { Component, useState, useEffect, Suspense } from "react";
import Edit from "../../image/icons/edit.png";
import tick from "../../image/icons/tick.png";
import Deactivate from "../../image/icons/deactvate.png";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import Header from "../../layout/header"
import Footer from "../../layout/footer"
import Logoimg from "../../image/icons/image-preview.png";
import $ from 'jquery';
import { apiCalling, awsAPIUrlcommon } from "../../apiService";


const Addapproval = () => {
    const [activePage, setactivePage] = useState(1);
    const [totalCount, settotalCount] = useState(1);
    const [adApprovalData, setadApprovalData] = useState(1);
    const [showMessage, setshowMessage] = useState(1);
    const [isLoading, setisLoading] = useState("0");

    useEffect(() => {
        getAdApprovalList();
    }, []);
    const getAdApprovalList = () => {
        $(".loader").show();
        const apiJson = {
            method: "POST",
            body: JSON.stringify({
                functionName: "adApprovalListing",
                cmbSelect: "",
                status: "",
                txtInput: "",
                Offset: "0",
                max: "10",
                siteId: ""
            }),
        };
        apiCalling(apiJson).then((data) => {
            if (data.success === "1") {
                $(".loader").hide();
                setadApprovalData(data.AdApprovalSearchData);
                settotalCount(data.totalCount);
            }
            if (data.success === "0") {
                $(".loader").hide();
                setadApprovalData([]);
                setshowMessage(data.errorMessage);

            }
        });
    }
    const handlePageChange = (pageNumber) => {
        setactivePage(pageNumber);
        var offset;
        var max;
        if (pageNumber === "1") {
            offset = "0";
            max = "10";
            setactivePage(pageNumber);
            setisLoading('0');
        }
        else {
            offset = parseInt(pageNumber - 1) * 10;
            max = "10";
            setactivePage(pageNumber);
            setisLoading('0');
        }
        const apiJson = {
            method: "POST",
            body: JSON.stringify({
                functionName: "adApprovalListing",
                cmbSelect: $("#searchType").val() === '0' ? '' : $("#searchType").val(),
                txtInput: $("#resultTxt").val(),
                status: "",
                Offset: JSON.stringify(offset),
                max: max
            }),
        };
        $(".loader").show();
        apiCalling(apiJson).then((data) => {
            if (data.success === "1") {
                setadApprovalData(data.AdApprovalSearchData);
                settotalCount(data.totalCount);
                $(".loader").hide();
            }
            if (data.success === "0") {
                $(".loader").hide();
                setadApprovalData([]);
                setshowMessage(data.errorMessage);
            }
        });
    }
    const changeText = () => {
        if ($("#searchType").val() !== '0') {
            document.getElementById("resultTxt").disabled = false
            $('input[name=resultTxt]').focus();
        }
        else {
            document.getElementById("resultTxt").disabled = true
        }
        document.getElementById("resultTxt").value = ""
    }
    const searchHandler = () => {
        if ($("#searchType").val() !== "0" && $("#resultTxt").val() === "") {
            var t = document.getElementById("searchType");
            var selectedText = t.options[t.selectedIndex].text;
            alert(selectedText + " Required");
            $("#resultTxt").focus();
            return false;
        } else {
            const searchData = {
                method: "POST",
                body: JSON.stringify({
                    functionName: "adApprovalListing",
                    cmbSelect: $("#searchType").val() === '0' ? '' : $("#searchType").val(),
                    status: $("#searchStatusType").val() === '0' ? '' : $("#searchStatusType").val(),
                    txtInput: $("#resultTxt").val(),
                    Offset: "0",
                    max: "10"
                }),
            };
            $(".loader").show();
            apiCalling(searchData).then((data) => {
                if (data.success === "1") {
                    $("#paginationType").show();
                    $(".loader").hide();
                    setadApprovalData(data.AdApprovalSearchData);
                    settotalCount(data.totalCount);
                    setactivePage("1");
                }
                if (data.success === "0") {
                    $("#paginationType").hide();
                    $(".loader").hide();
                    // this.setState({
                    //     adApprovalData: [],
                    //     showMessage: data.errorMessage
                    // });
                    setadApprovalData([]);
                    setshowMessage(data.errorMessage);
                }
            })
        }
    }
    const statusChangeHandler = (id, status) => {
        const confirmMessage = status ? "Do you want to Disable this?" : "Do you want to Enable this?";
        if (!window.confirm(confirmMessage)) return;
        const StatusData = {
            method: "POST",
            body: JSON.stringify({
                functionName: "deactivatePharmaRepAds",
                pharmaRepAdsId: id,
            }),
        };

        $(".loader").show();
        apiCalling(StatusData).then((data) => {
            if (data.success === "1") {
                $(".loader").hide();
                alert("Status Changed Successfully")
                getAdApprovalList();
            }
            if (data.success === "0") {
                $(".loader").hide();
                alert(data.errorMessage)
            }
        })
    }
    const approveStatusChange = (e, pid) => {

        if (e.target.id === "Approved") {
            const confirmMessage = "Do you want to Approve this?"
            if (!window.confirm(confirmMessage)) return;
            const approveData = {
                method: "POST",
                body: JSON.stringify({
                    functionName: "approveOrRejectPharmaRepAds",
                    pharmaRepAdsId: pid,
                    statusName: "Approve"
                })
            }
            $(".loader").show();
            apiCalling(approveData).then((data) => {
                if (data.success === "1") {
                    alert("Status Changed Successfully")
                    let data = {
                        method: "POST",
                        body: JSON.stringify({
                            "functionName": "sendNewsFeedNotificationsToDoctor",
                            "neesFeedId": pid,
                            "userId": window.localStorage.getItem("userId"),
                        })
                    }
                    awsAPIUrlcommon(data)


                    $(".loader").hide();
                    getAdApprovalList();
                }
                if (data.success === "0") {
                    $(".loader").hide();
                    alert(data.errorMessage)
                }
            })
        }
        else if (e.target.id === "Rejected") {
            const confirmMessage = "Do you want to Rejected this?"
            if (!window.confirm(confirmMessage)) return;
            const rejectData = {
                method: "POST",
                body: JSON.stringify({
                    functionName: "approveOrRejectPharmaRepAds",
                    pharmaRepAdsId: pid,
                    statusName: "Reject"
                })
            }
            $(".loader").show();
            apiCalling(rejectData).then((data) => {
                if (data.success === "1") {
                    $(".loader").hide();
                    alert("Status Changed Successfully")
                    getAdApprovalList();
                }
                if (data.success === "0") {
                    $(".loader").hide();
                    alert(data.errorMessage)
                }
            })
        }

    }

    return (
        <div className="purpleWrap bg-clr-wthHead">
            <Suspense fallback={<div></div>}>
                <section>
                    <Header />{" "}
                    <div className='loader'></div>
                </section>
            </Suspense>
            <div className='loader'></div>
            <div className="container mb-5">
                <div>
                    <ul className="breadcrum-adminMangmnt">
                        <li><a href="/admin/dashboardAdmin">Dashboard</a></li>
                        <li><a href="/adminDashboard/pharmaDashboard">Pharma Ad Approval</a></li>
                        <li><a href="" className="current">Ad Approval List</a></li>
                    </ul>
                </div>
                <div className="head-hospMangmntList">Ad Approval List</div>

                <div className="HosptlMangemntTable">
                    <div className="tableHeader-HspMangemnt">
                        <div className="selectField-HspMangmnt">
                            <select className="slecthsp slecthspMob" id="searchType" name="searchType" onChange={() => changeText()}>
                                <option value="0">Search In</option>
                                <option value="1">ID</option>
                                <option value="2">Ad Name</option>
                            </select>
                        </div>
                        <div className="selectField-HspMangmnt">
                            <input type="text" className="hspmngInput hspmngInputmob" name="resultTxt" id="resultTxt" disabled />
                        </div>
                        <div className="selectField-HspMangmnt">
                            <select className="slecthsp slecthspMob" id="searchStatusType" name="searchStatusType">
                                <option value="0">Status</option>
                                <option value="Approved">Approved</option>
                                <option value="Rejected">Rejected</option>
                            </select>
                        </div>
                        <div className="selectField-HspMangmnt" onClick={() => searchHandler()}>
                            <button className="searchbtn-hspMng">Search</button>
                        </div>
                    </div>
                    {adApprovalData.length > 0 ?
                        <div className="table-responsive">
                            <table className="table table-bordered-hsp">
                                <thead>
                                    <tr>
                                        <th className="tablehead-hspmng">ID</th>
                                        <th className="tablehead-hspmng">Ad Name</th>
                                        <th className="tablehead-hspmng">Rep Name</th>
                                        <th className="tablehead-hspmng">Image</th>
                                        <th className="tablehead-hspmng">Broucher Link</th>
                                        <th className="tablehead-hspmng">Actions</th>
                                        <th className="tablehead-hspmng">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {adApprovalData.map((data) => (

                                        <tr>
                                            <td>{data.pharmaRepAdsId}</td>
                                            <td>{data.adName}</td>
                                            <td>{data.repName}</td>
                                            <td><div className="td_img"><img src={data.imageName} alt="logo" /></div></td>
                                            <td><div className="link_overflow">
                                                {data.brochureLink}
                                            </div></td>
                                            <td className="text-nowrap">
                                                <div className="action_tdtr">
                                                    <Link to={"/adminManagement/pharmadashboardList/adApprovalEdit/" + data.pharmaRepAdsId}>
                                                        <img src={Edit} className="mr-2" />
                                                    </Link>
                                                    {(data.isActive === true) ? <img src={tick} width="20" title="Click to Deactivate " className="mr-1 conf-cursorpointer" alt="" onClick={() => { statusChangeHandler(data.pharmaRepAdsId, true) }}></img> :
                                                        (data.isActive === false && data.status === "Rejected") ? <img src={Deactivate} width="20" title="Click to Deactivate " className="mr-1 conf-cursorpointer" alt="" onClick={() => { statusChangeHandler(data.pharmaRepAdsId, true) }}></img> :
                                                            <span className="ml-2">Deactivated</span>}
                                                    {/* {(data.status === "Rejected" || data.status === "Approved" ) && data.isActive === true ? 
                                                        <img src={Deactivate} width="20" title="Click to Deactivate " className="mr-1 conf-cursorpointer" alt="" onClick={() => { this.statusChangeHandler(data.pharmaRepAdsId, true) }}></img> : 
                                                        <span className="ml-2">Deactivated</span>} */}
                                                </div>
                                            </td>
                                            <td>
                                                {(data.status === "Pending") ? <span><a href="#" id="Approved" className="mr-2" onClick={(e) => { approveStatusChange(e, data.pharmaRepAdsId) }}>Approve</a><a href="#" id="Rejected" onClick={(e) => { approveStatusChange(e, data.pharmaRepAdsId) }}>Reject</a></span> :
                                                    (data.status === "Approved") ? <span>Approved</span> :
                                                        (data.status === "Rejected") ? <span>Rejected</span> : ""}
                                            </td>
                                            {/* <td>{data.status.replace(/\s+/g,'')==="Pending" && data.isActive === false ? <span><a href="#" id="Approved" className="mr-2" onClick={(e)=>{approveStatusChange(e,data.pharmaRepAdsId)}}>Approve</a>
                                                <a href="#" id="Rejected" onClick={(e)=>{approveStatusChange(e,data.pharmaRepAdsId)}}>Reject</a></span>:<span>{data.status}</span>}
                                            </td> */}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="pagination-qualList pagination-qualListwidth">
                                <Pagination
                                    activePage={activePage}
                                    itemsCountPerPage={10}
                                    totalItemsCount={totalCount}
                                    pageRangeDisplayed={5}
                                    onChange={handlePageChange}
                                />
                            </div>
                        </div>
                        : <p className="error-msg text-center mb-0"> {showMessage} </p>}
                </div>
            </div>
            <Suspense fallback={<div></div>}>
                <section>
                    <Footer />
                </section>
            </Suspense>
        </div>
    );
}


export default Addapproval;