import React, { Component } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import $ from "jquery";
import {
  apiCalling,
  patientAPI,
  validateEmail,
  phonenumberValidation,
} from "../apiService";

export default class ProfileEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      mobileNo: "",
      emailId: "",
      address: "",
      city: "",
      state: "",
      country: "",
      gender: "",
      phoneExtension: "",
      zip: "",
      countryData: [],
      stateData: [],
      cityData: [],
      selectedTeam: "",
      validationError: "",
      dob: "",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.updateProfile = this.updateProfile.bind(this);
    this.handleInputChangeCountry = this.handleInputChangeCountry.bind(this);
    const getUserData = {
      method: "POST",
      body: JSON.stringify({
        functionName: "userDetails",
        siteId: localStorage.getItem("siteId"),
        userId: localStorage.getItem("userId"),
      }),
    };
    apiCalling(getUserData).then((data) => {
      if (data.success == "1") {
        this.setState({ firstName: data.userData[0].firstName });
        this.setState({ lastName: data.userData[0].lastName });
        this.setState({ mobileNo: data.userData[0].phoneNumber });
        this.setState({ emailId: data.userData[0].emailId });
        this.setState({ gender: data.userData[0].gender });
        this.setState({ address: data.userData[0].address });
        this.setState({ city: data.userData[0].city });
        this.setState({ state: data.userData[0].state });
        this.setState({ country: data.userData[0].country });
        this.setState({ phoneExtension: data.userData[0].phoneExtension });
        this.setState({ zip: data.userData[0].zip });
        this.setState({ dob: data.userData[0].dob });
      }
    });

    const getCountryData = {
      method: "POST",
      body: JSON.stringify({
        functionName: "locationDetails",
        siteId: localStorage.getItem("siteId"),
      }),
    };
    apiCalling(getCountryData).then((data) => {
      if (data.success == "1") {
        this.setState({ countryData: data.result.countryData });
        if (this.state.state) {
          this.setState({ stateData: data.result.stateData });
          this.setState({ cityData: data.result.cityData });
        }
      }
    });
  }
  handleInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };
  handleInputChangeCountry = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };
  /* setTimeout(() => {
    $("#loginMenu").hide();
    $("#signInWrap").hide();
    $("#loginErrorMsg").text("");
  }, 2000);*/

  updateProfile = (e) => {
    e.preventDefault();
    let emailId = this.state.emailId;
    let mobileNo = this.state.mobileNo;
    let gender = this.state.gender;
    let address = this.state.address;
    let errormessage = "";
    if (!validateEmail(emailId)) {
      errormessage = "Please enter valid emailId";
    } else if (!phonenumberValidation(mobileNo)) {
      errormessage = "Please enter valid mobile no";
    } else if (!gender) {
      errormessage = "Please select gender";
    } else if (!address) {
      errormessage = "Please enter address field";
    } else {
      const saveData = {
        method: "POST",
        body: JSON.stringify({
          functionName: "updateUserDetails",
          siteId: localStorage.getItem("siteId"),
          userId: localStorage.getItem("userId"),
          emailId: this.state.emailId,
          mobileNumber: this.state.mobileNo,
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          address: this.state.address,
          city: this.state.city,
          state: this.state.state,
          country: this.state.country,
          gender: this.state.gender,
          phoneExtension: this.state.phoneExtension,
          zip: this.state.zip,
        }),
      };

      apiCalling(saveData).then((data) => {
        if (data.success == "1") {
          $("#messageMemberEdit").text("Updated Successfully");
          $("#messageMemberEdit").css("color", "green");
        }
      });
    }
    $("#messageMemberEdit").text(errormessage);
    $("#messageMemberEdit").css("color", "red");

    setTimeout(() => {
      $("#messageMemberEdit").text("");
    }, 4000);
  };
  componentDidMount() {
    let self = this;
    $("#countrySelect").change(function () {
      getStateFunction();
    });

    function getStateFunction() {
      const getState = {
        method: "POST",
        body: JSON.stringify({
          functionName: "locationDetails",
          siteId: localStorage.getItem("siteId"),
          countryId: $("#countrySelect").val(),
        }),
      };
      apiCalling(getState).then((data) => {
        if (data.success == "1") {
          self.setState({
            stateData: data.result.stateData,
          });
          self.setState({
            cityData: [],
          });
        }
      });
    }

    $("#state").change(function () {
      const getCity = {
        method: "POST",
        body: JSON.stringify({
          functionName: "locationDetails",
          siteId: localStorage.getItem("siteId"),
          countryId: $("#countrySelect").val(),
          stateId: $("#state").val(),
        }),
      };

      apiCalling(getCity).then((data) => {
        if (data.success == "1") {
          self.setState({
            cityData: data.result.cityData,
          });
        }
      });
    });

    //if (this.state.country != "" || this.state.country != "null") {
    // getStateFunction();
    // alert(this.state.state);

    //}
  }
  render() {
    const { stateData } = this.state;
    const { cityData } = this.state;
    $("#state").val(this.state.state);
    $("#city").val(this.state.city);
    let stateList =
      stateData.length > 0 &&
      stateData.map((item) => {
        return (
          <option key={item.stateId} value={item.stateId}>
            {item.stateName}
          </option>
        );
      }, this);

    let cityList =
      cityData.length > 0 &&
      cityData.map((item) => {
        return (
          <option key={item.cityId} value={item.cityId}>
            {item.cityName}
          </option>
        );
      }, this);

    return (
      <div className="purpleWrap">
        <Header></Header>
        <div className="container container-auto">
          <div className="main-wrapper1">
            <div className="memberHead">
              <h4 className="">Member Profile</h4>
            </div>
            <div className="form-section border mt-2 form-section-padng">
              <h5 className="mb-4">Member Details</h5>
              <h2>
                <p id="messageMemberEdit"></p>
              </h2>
              <div className="row">
                <div className="col-sm-6">
                  <div className="row row-margin">
                    <label className="col-4 label-text">First Name *</label>
                    <div className="col-8">
                      <input
                        type="text"
                        className="input-design"
                        name="firstName"
                        id="firstName"
                        disabled
                        value={this.state.firstName}
                        onChange={this.handleInputChange}
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="row row-margin">
                    <label className="col-4 label-text">Last Name *</label>
                    <div className="col-8">
                      <input
                        type="text"
                        className="input-design"
                        disabled
                        name="lastName"
                        id="lastName"
                        value={this.state.lastName}
                        onChange={this.handleInputChange}
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="row row-margin">
                    <label className="col-4 label-text">Email ID * </label>
                    <div className="col-8">
                      <input
                        type="email"
                        className="input-design"
                        value={this.state.emailId}
                        id="emailId"
                        name="emailId"
                        onChange={this.handleInputChange}
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="row row-margin">
                    <label className="col-4 label-text">Mobile Number *</label>
                    <div className="col-8">
                      <input
                        type="text"
                        className="input-design"
                        value={this.state.mobileNo}
                        id="mobileNo"
                        name="mobileNo"
                        onChange={this.handleInputChange}
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="row row-margin">
                    <label className="col-4 label-text">Phone Extension </label>
                    <div className="col-8">
                      <input
                        type="text"
                        className="input-design"
                        value={
                          this.state.phoneExtension == "null"
                            ? ""
                            : this.state.phoneExtension
                        }
                        id="phoneExtension"
                        name="phoneExtension"
                        onChange={this.handleInputChange}
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="row row-margin">
                    <label className="col-4 label-text">Gender *</label>
                    <div className="col-8">
                      <select
                        type="text"
                        className="input-design"
                        value={this.state.gender}
                        id="gender"
                        name="gender"
                        onChange={this.handleInputChange}
                      >
                        <option value="">- - Select Gender - -</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="row row-margin">
                    <label className="col-4 label-text">Address *</label>
                    <div className="col-8">
                      <textarea
                        type="text"
                        className="input-design"
                        id="address"
                        name="address"
                        value={this.state.address}
                        onChange={this.handleInputChange}
                      >
                        {this.state.address}
                      </textarea>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="row row-margin">
                    <label className="col-4 label-text">Country </label>
                    <div className="col-8">
                      <select
                        className="input-design"
                        onChange={this.handleInputChange}
                        id="countrySelect"
                        name="country"
                        value={this.state.country}
                      >
                        <option value="">- - Select Country - -</option>
                        {this.state.countryData.map((obj) => {
                          return (
                            <option value={obj.countryId}>
                              {obj.countryName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="row row-margin">
                    <label className="col-4 label-text">
                      State{this.state.state}
                    </label>
                    <div className="col-8">
                      <select
                        className="input-design"
                        onChange={this.handleInputChange}
                        id="state"
                        name="state"
                        value={this.state.state}
                      >
                        <option value="">- -Select state - -</option>
                        {stateList}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="row row-margin">
                    <label className="col-4 label-text">City </label>
                    <div className="col-8">
                      <select
                        className="input-design"
                        onChange={this.handleInputChange}
                        id="city"
                        name="city"
                        value={this.state.city}
                      >
                        <option value="">- - Select city - -</option>
                        {cityList}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="row row-margin">
                    <label className="col-4 label-text">Zip </label>
                    <div className="col-8">
                      <input
                        type="text"
                        className="input-design"
                        value={this.state.zip}
                        id="zip"
                        name="zip"
                        onChange={this.handleInputChange}
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 px-0">
                  <a href="#">
                    <input
                      type="submit"
                      value="Update Profile"
                      className="formButton"
                      onClick={this.updateProfile}
                    ></input>
                  </a>
                  <a href="../patient/profile">
                    <input
                      type="button"
                      value="cancel"
                      className="formButton cancelButton"
                    ></input>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer></Footer>
      </div>
    );
  }
}
