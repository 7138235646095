import React, { useState,useEffect } from 'react';
import { useMemo } from "react";
import ReactApexChart from 'react-apexcharts';

const ApexChart = ({ monthVsConsultGraph }) => {
  const [graphData, setGraphData] = useState({})
  
  useEffect(() => {
    setGraphData(monthVsConsultGraph)
  }, [monthVsConsultGraph])

  const chartData = useMemo(() => ({
    series: monthVsConsultGraph.series.map((data) => ({
      ...data,
      type: data.name === "Grand Total" ? 'line' : 'bar',
    })),
    options: {
      chart: {
        height: 350,
        stacked: true,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        }
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            show: true,
            position: 'bottom',
            offsetX: -10,
            offsetY: 0
          }
        }
      }],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 0,
          borderRadiusApplication: 'end',
          borderRadiusWhenStacked: 'last',
          dataLabels: {
            position: 'center',
          }
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '11px',
        },
        offsetY: -10,
        formatter: function (val) {
          return val;
        }
      },
      xaxis: {
        categories: monthVsConsultGraph.label || [],
      },
      legend: {
        show: true,
        position: 'bottom',
        offsetY: 0,
      },
      fill: {
        opacity: 1,
        colors: ['#247bad', '#00a76d', '#9d8a84'] // Custom colors for the bars and line
      },
      colors: ['#247bad', '#00a76d', '#9d8a84'], // Ensure colors match the series order
    }
  }), [graphData]);

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="line"
          height={350}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default ApexChart;
