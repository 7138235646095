import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Header from "../layout/header"
import Footer from "../layout/footer"
import AboutTakeNextStep from './about-take-next-step';
import Newslist from './new-list-blocks';
import Fb from '../image/theme/fbico.png';
import Insta from '../image/theme/instaico.png';
import Linkdn from '../image/theme/linkdnico.png';

import news1 from "../image/theme/news/bwdisrupt.png"
import news2 from "../image/theme/news/YourStory.png"
import news3 from "../image/theme/news/Ehealth.png"
import news4 from "../image/theme/news/Inc 42.png"
import news5 from "../image/theme/news/Mint.png"
import news6 from "../image/theme/news/The-Financial-Express.png"
import news7 from "../image/theme/news/tech stroy.png"
import news8 from "../image/theme/news/the economic times.png"
import news9 from "../image/theme/news/Business Standard.png"
import news10 from "../image/theme/news/Business Line.png"
// import news2 from "../image/theme/news/YourStory.png"

function NewsDetails() {
    const newsIdNo = window.localStorage.getItem("newsIdNo");
    const [teamDetailArray1, setTeamDetailArray1] = useState([
        {
            id: "news1",
            authorname: "BW Online Bureau",
            date: "June 08, 2016",
            heading: "Ex-Reliance General Insurance- Head of Wellness joins PurpleHealth.com, a 360-degree ‘Health On Demand’ Platform",
            desc: "<p className='card-text'>PurpleHealth.com, a 360-degree ‘Health On Demand’ platform, today announced the appointment of Nagarjun Mishra as the Co-founder and Chief Officer - Business & Strategy, to lead the sales operations and drive the business strategy for the company. An Emergency Physician by education, Nagarjun comes with a wealth of experience in the Healthcare and Wellness sector. Prior to joining PurpleHealth.com, Nagarjun was leading Health & Wellness teams of top Indian insurance companies such as Reliance General Insurance and ICICI Lombard where he founded and engineered the strategy for preventive health.</p>" +
                "<p className='card-text'>Speaking on his appointment, Nagarjun Mishra says,” What brought us together was the vision of creating a difference to the health and wellness industry by providing technology driven holistic healthcare solutions. India faces a huge shortfall of Doctors and the current healthcare ecosystem operates in silos. PurpleHealth has an innovative product roadmap answering to this concern and I am looking forward to contributing to it. I truly believe in PurpleHealth's mission to empower people in improving their health index by making healthcare more affordable and accessible.”</p>" +
                "<p className='card-text'>During his tenure at Reliance General Insurance, he conceptualized and designed several innovative services like Go Fit (A technology enabled fitness program with gamification), Health-o-pedia (Post Claim Advisory), R HealthBeat (in-house Wellness magazine), Wellness Carnival (Corporate Wellness initiative), besides many more healthcare programs. In addition, Dr. Nagarjun helped found one of India's first healthcare portals, TotalHealthNeeds.com, back in 2008, which won multiple national accolades. His foresight and vision was deeply appreciated by industry peers, corporates and healthcare providers alike.</p>"+
                "<p className='card-text'>Vikram Nair, Co-founder & CEO, PurpleHealth.com welcomes Nagarjun Mishra on board and says, 'We are very pleased to have Dr. Mishra join the PurpleHealth team. He has a great understanding of the healthcare domain, and brings in a rare combination of medical expertise and business acumen with a technology bent. His deep industry relationships will help accelerate our deployment, and his involvement will only strengthen our product design and roadmap.'</p>"+
                "<p className='card-text'>Nagarjun is an alumnus of Hyderabad Public School and pursued Emergency Medicine through Apollo Hospitals, Hyderabad.</p>"
                ,
            image: news1
        },
        {
            id: "news2",
            authorname: "Tarush Bhalla",
            date: "Friday, June 10, 2016",
            heading: "Facebook has a new India MD, Snapdeal doesn’t want a CPO and Flipkart has a new technology team - HR shuffles this week",
            desc: "<p className='card-text'>While the first week of June seemed rather mellow in terms of executive movements, the second week was anything but mellow, with several shifts in the movers and shakers of the ecosystem. <p>"+ "<p className='card-text'>With global companies announcing some power-packed appointments in the subcontinent, Indian e-commerce players weren’t really far behind, silently restructuring senior positions in a bid to increase profits and take on competition. Have a look at the senior-level personnel changes in the industry this week.</p>" +
            "<h3 class='news-inner-head'>Adobe South Asia MD Umang Bedi to take over as Facebook India MD</h3><p className='card-text'>Following Kirthiga Reddy stepping down from her role as India MD in February this year, Facebook appointed Umang Bedi, Adobe’s South Asia MD, as her replacement. Umang has close to two decades of leadership experience in sales, marketing and partnerships.”</p>" +
            "<p className='card-text'>Starting July, Umang will lead, build and maintain strategic relationships with top clients and regional agencies in the country. Further, Kirthiga will be returning to the US to take on a new role at company’s headquarters in Menlo Park in August.</p>"+
            "<p className='card-text'>This is a key role, since India is the largest market for the Internet giant after the US, having close to 150 million monthly active users.</p>"+
            "<h3 class='news-inner-head'>Ex-CEO of Vodafone Arun Sarin joins Truecaller’s advisory board</h3><p className='card-text'>On June 9, it was announced that Arun Sarin will join the advisory board of caller ID app Truecaller, following his capital investment in the company. Although the investment amount wasn’t revealed, the company said that Arun will be using his expertise to strategically guide Truecaller on its overall strategy.</p>"+
            "<p className='card-text'>At the moment, Truecaller is investing big on innovation.</p>"+
            "<h3 class='news-inner-head'>Rajiv Mangla to take Anand Chandrasekaran’s spot at Snapdeal</h3><p className='card-text'>On Tuesday, Snapdeal reported that it will not be naming a new Chief Product Officer and will instead have Ranjiv Mangla, Chief Technology Officer, oversee both functions, due to increasing synergies between the company’s product and engineering teams. Last month, Anand Chandrasekaran, who was the CPO, had quit the company, barely completing a year at the e-commerce marketplace.</p>"+
            "<p className='card-text'>Rajiv Mangla joined the company last August, after spending a decade in Adobe Systems.</p>"+"<h3 class='news-inner-head'>More changes in Flipkart- technology leadership rejig second time in a year</h3><p className='card-text'>After coming under negative light for several senior-level exits, the top management of Flipkart resorted to some key restructuring in their technology executives.</p>"+
            "<p className='card-text'>Engineering Head Peeyush Ranjan was elevated to the role of Group Chief Technology Officer. In his new role, Peeyush’s responsibilities are split such that he is expected to head the technology strategy of the company while looking into areas of mobile technologies and machine learning.</p>"+
            "<p className='card-text'>The e-commerce giant has also appointed former Amazon and Micromax Informatics executive Ashish Agarwal as Senior Vice President of Engineering. Expected to join the company this month, Ashish will look after the engineering of Flipkart’s consumer shopping experience division.</p>"+
            "<p className='card-text'>Hari Vasudev, who was Senior Vice president at the company, has also been promoted to head the technology function of Flipkart’s marketplace and Ekart, Flipkart’s logistics arm. Hari is expected to report to both CEO Binny Bansal, as well as to Saikiran Krishnamurthy, Head of Ekart.</p>"+
            "<p className='card-text'>Additionally, following the exit of Chief Product Officer Punit Soni in April, former Google executive Surojit Chatterjee has been appointed as the new CPO.</p>"+"<h3 class='news-inner-head'>Japanese e-commerce giant Rakuten has its India lead</h3><p className='card-text'>According to Economic Times, Sachin Dalal, Co-founder of BSE listed company Infibeam, is all set to spearhead Rakuten’s entry into India.</p>"+
            "<p className='card-text'>Reported to have joined earlier this month, Sachin will look at the India operations of the business, which is said to be a big focus for the Japanese e-commerce giant. Reports claim that Rakuten has already started to poach mid-level managers from Flipkart and Amazon and plans to start its marketplace in India by next year. Earlier this week, the company announced its exit from markets in UK, Austria and Spain, citing weak growth prospects.</p>"+
            "<h3 class='news-inner-head'>Ex-Reliance General Insurance- Head of Wellness joins PurpleHealth.com</h3>"+
            "<p className='card-text'>On Wednesday, Nagarjun Mishra was appointed Co-founder and Chief Officer - Business & Strategy of PurpleHealth.com. As a part of his new role, Dr Mishra will lead sales operations, and drive the business strategy of the company.</p>"+
            "<p className='card-text'>In his previous roles in companies such as Reliance General Insurance and ICICI Lombard, Dr Mishra was credited with engineering strategies for preventive health.</p>"+
            "<p className='card-text'>The world of tech was also not spared from the resignations. While Umang called it quits to join Facebook, Adobe elevated its Head of Sales Kulmeet Bawa to the position of Managing Director, South Asia and India.</p>"+
            "<p className='card-text'>Karan Bajwa, Managing Director of Microsoft’s sales and marketing group, is set to join Facebook India, after he stepped down from his position on June 7. He had joined Microsoft in November 2007.</p>"+
            "<p className='card-text'>But will these senior-level changes translate into better talent acquisition and business opportunities for these companies or startups? We will have to wait and watch.</p>",
            image: news2
        },
        {
            id: "news3",
            authorname: "eHealth Network",
            date: "10 June 2016",
            heading: "PurpleHealth.com appoints Dr Nagarjun Mishra Aims to innovate product roadmap & empower consumers via cost-effective, technology-driven solutions",
            desc: "<p className='card-text'>PurpleHealth.com, Indias leading digital health platform offering 360-degree access to doctors and healthcare providers, on June 8 announced the appointment of Dr Nagarjun Mishra as the Co-Founder and Chief Officer – Business & Strategy, to lead the sales operations and drive the business strategy.</p>" +
            "<h3 class='news-head-inner'>Credentials</h3>" +
            "<ul class='news-ul-tag'>" +
    "<li><span class='news-span-head'>Education & Experience:</span> An emergency physician by education from Apollo Hospital, Hyderabad, Dr Nagarjun will steer the company towards growth with his wide experience in health & wellness sector.</li>" +
    "<li><span class='news-span-head'>Earlier Positions:</span> Dr Nagarjun was leading health & wellness teams of top Indian insurance companies, such as Reliance General Insurance and ICICI Lombard. In all earlier positions, he had played a key role in engineering the strategy for preventive health.</li>" +
    "<li><span class='news-span-head'>Contributions:</span> Dr Nagarjun conceptualised and designed several innovative services, such as Go Fit (A technology-enabled fitness programme with gamification), Health-o-pedia (Post Claim Advisory), Wellness Carnival (Corporate Wellness initiative), besides many more healthcare programmes.</li>" +
    "</ul>" +
    "<h3 class='news-head-inner'>Vision</h3>" +
    "<ul class='news-ul-tag'>" +
    "<li>Provide technology-driven healthcare solutions to create a difference to the health & wellness industry</li>" +
    "<li>Provide an innovative product roadmap to address the challenge of a huge shortfall of doctors</li>" +
    "<li>Empower people in improving their health index by making healthcare affordable and accessible</li>" +
    "</ul>"
            ,
            image: news3
        },
        {
            id: "news4",
            authorname: "Team Inc42",
            date: "11 June 2016",
            heading: "Movers & Shakers Of The week [6 June – 11 June]",
            desc: "<p className='card-text'>Flipkart has once again changed its technology leadership team. The company has moved engineering head Peeyush Ranjan to the role of group chief technology officer. Ranjan will now look at areas such as mobile technologies and machine learning at Flipkart and head technology strategy.</p>"+
            "<p className='card-text'>Flipkart has hired former Amazon.com Inc. and Micromax Informatics executive Ashish Agrawal as senior vice-president of engineering. Agrawal, who will join Flipkart this month, will be the engineering head of the consumer shopping experience. Flipkart has also promoted Hari Vasudev, another senior vice-president of engineering, to head the technology function of two of its most important businesses—marketplace and Ekart. Both Vasudev and Agrawal will report to Flipkart chief executive officer Binny Bansal. Vasudev will also report to Saikiran Krishnamurthy, the head of Ekart, Flipkart’s massive logistics arm.</p>"+
            "<h3 class='news-head-inner'>Facebook Announces New MD For India</h3>"+
            "<p className='card-text'>Facebook has appointed Umang Bedi, former Adobe executive for South Asia region, as new managing director for the company’s Indian operations. Bedi will start with his role officially in July 2016 and will be responsible for building strategic relationships with top clients and regional agencies. He will take over from Kirthiga Reddy who was earlier heading Facebook India and is now returning to United States to take on a new role at the company’s headquarters at Menlo Park. Umang is an engineering graduate from University of Pune, an alumnus of Harvard Business School, and was a recipient of the ‘40 Under Forty: India’s Hottest Business Leaders Award 2014’. He has close to two decades of leadership experience covering sales, marketing and partnerships with multinational companies.</p>"+

            "<h3 class='news-head-inner'>Paytm’s Senior VP Quits</h3>"+
            "<p className='card-text'>Vikas Purohit, senior vice-president at Paytm has quit the company to start his own venture. He joined Paytm in December 2015 and was looking after the business correspondent network for Paytm’s upcoming payment banks. The former head of fashion at Amazon India is teaming up with another Paytm employee and ex McKinsey executive Apoorv Gautam to start a digital customer experience firm that would use machine learning</p>"+
            "<h3 class='news-head-inner'>DigitalOcean Hires Julia Austin</h3>"+
            "<p className='card-text'>DigitalOcean, the developer’s cloud computing platform, hired Julia Austin as its Chief Technology Officer. As CTO, Austin will lead product and engineering for one of the fastest growing cloud computing platforms, driving innovation to grow DigitalOcean’s simple, on-demand offering for over 700,000 registered customers. Austin has spent last few years as an advisor and investor to early stage companies, and as a faculty member of Harvard Business School’s Entrepreneurial Management Unit where she taught Product Management. She was previously with VMware for eight years, where she managed the expansion of the engineering organization. Prior to VMware, Julia was the VP of Engineering at Akamai. She has successfully led large organizations in technical positions, partnered with entrepreneurs to help start and scale technology companies, and serves as a board member, advisor and angel investor who provides leadership coaching with a focus on engineering, process, staffing and functional operations.</p>"+
            "<h3 class='news-head-inner'>GREX Gets A New CEO</h3>"+
            "<p className='card-text'>GREX, a fintech and private market platform, announced change in its management structure. The company decided on this move to bring balance to its current execution objectives and future product road map. Manish Kumar, the CEO and MD of GREX, will continue with the role of Managing Director of the Company, whereas Abhijeet Bhandari, Director and Co-founder of GREX, will become new CEO’s role. Abhijeet who was handling the entire market development for GREX, will now be responsible for overall delivery and execution at the company level, while in his role as MD of GREX, Manish continues to lead governance, institutional partnerships and strategic initiatives.</p>" +"<h3 class='news-head-inner'>Nagarjun Mishra Joins PurpleHealth.com</h3>"+
            "<p className='card-text'>PurpleHealth.com, a 360-degree ‘health on demand’ platform, announced the appointment of Dr. Nagarjun Mishra as the co-founder and Chief Officer – Business & Strategy, to lead the sales operations and drive the business strategy for the company. An Emergency Physician by education, Dr. Nagarjun comes with a wealth of experience in the Healthcare and Wellness sector. Prior to joining PurpleHealth.com, Dr. Nagarjun was leading Health & Wellness teams of top Indian insurance companies such as Reliance General Insurance and ICICI Lombard where he founded and engineered the strategy for preventive health.</p>" +"<h3 class='news-head-inner'>Rakuten Appoints Infibeam Co-founder As India Operations Head</h3>"+
            "<p className='card-text'>Japanese ecommerce firm Rakuten has appointed Infibeam co-founder Sachin Dalal as head of Indian operations, early this month. Rakuten will soon expanding its presence into Indian markets. It is expected to launch its Indian venture next year. the company began poaching executives from Flipkart and Amazon in April for its marketplace, having already set up a development centre in Bangalore in 2014.</p>" +"<h3 class='news-head-inner'>TeaBox Hires Amit Sharma And Nicole Naumoff</h3>"+
            "<p className='card-text'>Bangalore and Singapore based tea etailer, Teabox has appointed Amit Sharma as chief technology officer and Nicole Naumoff as senior vice president marketing. Amit is an alumnus of IIT Delhi and was earlier associated with ZoomCar. The step came in after the company recently raised funding from Cameron Jones, a Singapore-based angel investor.</p>"
            ,
            image: news4
        },
        {
            id: "news5",
            authorname: "Viswanath Pilla",
            date: "18 May 2016, 05:33 PM IST",
            heading: "PurpleHealth raises $100,000 from Katabole Technology Venture",
            desc: "<p className='card-text'>PurpleHealth.com, a digital health and wellness platform, on Wednesday said it has raised $100,000 from technology investor, Katabole Technology Venture.</p>"+
            "<p className='card-text'>Founded in 2014, PurpleHealth.com delivers a diverse spectrum of solutions, services, and products to consumers, corporates, healthcare providers, and institutions.</p>"+
            "<p className='card-text'>The platform empowers consumers to take active control of their health by providing a one-stop seamless interface to choose the right medical practitioners, schedule appointments, set up video and audio chats with doctors.</p>"+
            "<p className='card-text'>Furthermore, PurpleHealth enables healthcare providers with tools and technologies that assist them to better engage with their customers. The platform currently has over 38,000 doctors and 4,000 hospitals as part of its vast and growing network.</p>"+
            "<p className='card-text'>'We will use this round of funding to further build on the PurpleHealth.com product roadmap and strengthen the team,' said Vikram Nair, co-founder, and chief executive officer of PurpleHealth.com.</p>"+
            "<p className='card-text'>Last week, mobile health and fitness start-up HealthifyMe, run by Caeruz Ventures Pvt. Ltd, raised $6 million in series A funding led by IDG Ventures India, Inventus Capital and Blume Ventures.</p>"
            +
            "<p className='card-text'>The consumer healthcare sector in the country has received about $338 million in investments since 2009, with a considerable portion—about $159 million—going to start-ups that offer healthcare delivery solutions for doctors, according to a report on consumer healthcare start-ups by start-up data analytics firm Tracxn Technologies Pvt. Ltd.</p>"
           
            ,
            image: news5
        },
        {
            id: "news6",
            authorname: "EH News Bureau",
            date: "18 May 2016, 11:06 IST",
            heading: "PurpleHealth.com raises $100,000 from Katabole Technology Venture",
            desc: "<p className='card-text'>PurpleHealth.com has raised $100,000 from investor, Katabole Technology Venture. PurpleHealth.com is a health and wellness platform that provides an interface to choose medical practitioners, schedule appointments, set up video and audio chats with doctors etc.</p>"+
            "<p className='card-text'>Reportedly, the platform currently has over 38,000 doctors and 4000 hospitals as part of its network.</p>"+
            "<p className='card-text'>Vikram Nair, Co-founder & CEO, PurpleHealth.com says, “We will use this round of funding to further build on the PurpleHealth.com product roadmap and strengthen the team.”</p>"+
            "<p className='card-text'>Balaji Jagannathan, Founder & CEO, Katabole says, “We are confident that PurpleHealth.com will disrupt the health and wellness industry. Their product roadmap is very exciting and we are looking forward to the innovations that PurpleHealth will be bringing to the market.”</p>"
            ,
            image: news6
        },
        {
            id: "news7",
            authorname: "Anirudh Saligrama",
            date: "19 May 2016, 11:06 IST",
            heading: "PurpleHealth Raises $100K From Its Investor – Katabole Technology Venture",
            desc: "<p className='card-text'>Trivandrum based “Health On Demand” platform PurpleHealth.com raised $100K (Rs. 67 lakhs approx.) from its investor, Katabole Technology Venture.</p>"+
            "<p className='card-text'>The company will use this capital for their product roadmap and to strengthen their team.</p>"+
            "<p className='card-text'>According to sources and reports by ET, the co-founder and CEO of PurpleHealth.com, Vikram Nair said, “The Indian healthcare sector is expected to register a compounded annual growth rate (CAGR) of 22.9% during 2015-20 and grow to USD 280 billion. We intend to play a substantial part in that growth story by creating a massive, positive impact on how health & wellness products and services are accessed by and delivered to every individual, corporate & institution”.</p>"+
            "<p className='card-text'>Founded in 2014 by Vikram Nair, Mini Balaraman and Prakash Sathyapalan, PurpleHealth.com (formerly DoctorsCabin.com) is a health and wellness platform that provides various solutions, services and products to consumers, corporates, healthcare providers and institutions. The company also empowers consumers to take control of their health by providing an interface to select the right medical practitioners, set-up video & audio chats with doctors, schedule appointments and more. PurpleHealth.com currently has over 38,000 doctors and around 4000 hospitals as part of its growing network.</p>"
            ,
            image: news7
        },
        {
            id: "news8",
            authorname: "Anirudh Saligrama",
            date: "18 May 2016, 10:58:00 AM IST",
            heading: "PurpleHealth.com raises $100,000 from Katabole Technology Venture",
            desc: "<p className='card-text'>PurpleHealth.com, a 360-degree 'Health On Demand' platform, on Wednesday said it has raised $100,000 from tech investor, Katabole Technology Venture. Founded in 2014, PurpleHealth.com is a 360-degree health and wellness platform that delivers a diverse spectrum of solutions, services and products to consumers, corporates, healthcare providers and institutions.The platform empowers consumers to take active control of their health by providing a one-stop seamless interface to choose the right medical practitioners, schedule appointments, set up video & audio chats with doctors and much more, thus helping them better manage their health and wellness interactions.</p>"+
            "<p className='card-text'>Furthermore, PurpleHealth enables healthcare providers with tools and technologies that assist them to better engage with their customers. The platform currently has over 38,000 doctors and 4000 hospitals as part of its growing network.</p>"+
            "<p className='card-text'>'The Indian healthcare sector is expected to register a compounded annual growth rate (CAGR) of 22.9% during 2015-20 and grow to USD 280 billion. We intend to play a substantial part in that growth story by creating a massive, positive impact on how health & wellness products and services are accessed by and delivered to every individual, corporate & institution,' said PurpleHealth.com, Co-founder & CEO, Vikram Nair in a statement. He added that his round of funding will be used to build on the PurpleHealth.com product roadmap and strengthen the team.</p>"+
            "<p className='card-text'>'What really excited us about PurpleHealth was their inclusive approach that recognizes the scale of the problems the health space presents, and looks for imaginative (and sustainable) solutions that deal with the ecosystem, and involve all stakeholders, small and big - healthcare providers, corporates, other institutions and individual consumers. Their product roadmap is very exciting and we are looking forward to the innovations that PurpleHealth will be bringing to the market,' said Katabole Technology Venture, Founder & CEO, Balaji Jagannathan.</p>"
            ,
            image: news8
        },
        {
            id: "news9",
            authorname: "BS Reporter",
            date: "17 May 2016 6:22 PM IST",
            heading: "PurpleHealth raises $100,000 from Katabole Technology Venture",
            desc: "<p className='card-text'>PurpleHealth.com, a health-on-demand platform, has raised $100,000 from tech investor Katabole Technology Venture.</p>"+
            "<p className='card-text'>Founded in 2014, PurpleHealth.Com is a '360-degree' health and wellness platform that delivers diverse spectrumof solutions, services and products to consumers, corporates, healthcare providers and institutions. The platform empowers consumers to take active controlof their health by providing a one-stop seamless interface to choose the right medical practitioners, schedule appointments,set up video and audio chats with doctors, among others, PurpleHealth said in a statement. * * * * * * Tata Steel launches 'Ground Granulated Blast Furnace Slag' * Tata Steel has launched Ground Granulated Blast Furnace Slag (GGBS), a new value added product which can cut down the cost of concrete and make it stronger and more durable.</p>"+
            "<p className='card-text'>Around 40 potential customers from construction companies, RMCs (ready mixed concrete), EPCs and Paver Block manufacturers were present at the Customer Meet organised in Bhubaneswar yesterday for this purpose, the company said in a statement. GGBS is a downstream product of blast furnace slag. It can be used as a partial replacement of Ordinary Portland Cement (OPC) up to 70 per cent, thus bringing down the cost of concrete. The concrete made by using GGBS is stronger, more durable and eco-friendly, it added. * * * * * * ICCS plans mass hiring to support growth plans * BPO firm ICCS is planning to recruit 200-300 employees every month to support its growth plans.</p>"+
            "<p className='card-text'>The company is hiring for various process domains such as customer service, business acquisition, customer satisfaction survey and customer engagement processes. 'These (mass hiring) will majorly be for verticals like business development, business excellence and finance vertical. Institutes like IIMC, XLRI, IMI and IMT will be the preferred choice for recruitment drives, the company said in a statement. * * * * * * 10th edition of 'Texfair 2016' from May 20 * The 10th edition of 'Texfair 2016', which will showcase modern developments in the textile industry will be held from May 20 in Coimbatore. The four-day fair will display major products and services by 220 exhibitors including textile machinery, spares, accessories, testing equipments, electrical and electronic components, Southern India Mills' Association (SIMA) Chairman M Senthilkumar said.Vodafone launches unlimited calling plan starting Rs 1,699</p>"+
            "<p className='card-text'>* Telecom operator Vodafone today launched unlimited mobile phone calling plan on 3G and 4G post-paid customers at starting price of Rs 1,699 per month. 'The postpaid customer's usage behaviour has evolved significantly with higher usage of roaming and data. With the new Vodafone RED, we are offering our postpaid customers an all-in-one plan that addresses their roaming, data, and voice needs comprehensively,' Vodafone India Director-Commercial Sandeep Kataria said in a statement. Under the Rs 1,699 plan, Vodafone customers will get free incoming calls while roaming, unlimited voice calling and 6 GB of 3G or 4G data. * * * * * *</p>"+
            "<p className='card-text'>Rooftop solar plants to help save water evaporation:Assocham * Canal and dam-based rooftop solar plants can help in producing power and save 25 per cent of water evaporation losses in India, an Assocham-EY study today said. Besides, higher maintenance cost comes with less or no cost of land and water and energy security are addressed simultaneously, it said. These measures will lead to the development of a market where 100 per cent solar power can potentially be sold at the exchange, it added. * * * * * * NCR Corp to move its R&D facility to new location in Hyd * Omni-solutions provider NCR Corporation will move its R&D facility to a new location in Raheja Mindspace in Hyderabad by early 2017.</p>"+
            "<p className='card-text'>This new facility will be a global centre of excellence for NCR Corporation as part of its transformational journey towards leadership in the omni-channel evolution, a company statement said today. 'As we continue to build out our innovative, omni-channel architecture, the Hyderabad facility will play an important role as a strategic global centre for software development,' said Eli Rosner, company's senior vice president and chief technology officer for software solutions. NCR's Hyderabad R&D center is one of NCR's largest software development centres and will remain a critical hub focused on developing innovative solutions for global markets.</p>"
            ,
            image: news9
        },
        {
            id: "news10",
            authorname: "RAJESH KURUP",
            date: "20 Januvary 2018 02:54 PM IST",
            heading: "PurpleHealth raises $100,000 from Katabole Venture",
            desc: "<p className='card-text'>PurpleHealth.com, a Thiruvananthapuram-based health-on-demand platform, has raised seed funding of $100,000 from Mumbai-based private equity firm Katabole Technology Venture. The firm will use the funds to build its product roadmap and strengthen the team.</p>"
            ,
            image: news10
        },
        {
            id: "news11",
            authorname: "Aparajita Choudhury",
            date: "18 May 2016 ",
            heading: "PurpleHealth raises $100,000 from Katabole Venture",
            desc: "<p className='card-text'>Thiruvananthapuram-based on-demand health platform PurpleHealth has raised $100,000 from tech investor Katabole Technology Venture. The fresh funds will help the startup build collaborative healthcare distribution models with a common customer pool, through integrated technology.</p>"+"<p className='card-text'>Founded in 2014 by Vikram Nair, Prakash Sathyapalan and Mini Balaraman, PurpleHealth is a 360-degree health and wellness platform that delivers a diverse spectrum of solutions, services and products to consumers, corporates, healthcare providers and institutions. The platform allows users to choose the right medical practitioners, schedule appointments, set up video and audio chats with doctors, and helps better manage their health and wellness interactions.</p>"+
            "<p className='card-text'>Vikram claims that PurpleHealth works with a unique ‘total health’ approach that focusses on meeting the country’s healthcare challenges with integrated solutions through a 360-degree health platform delivered to individuals, corporate and institution.</p>"+
            "<p className='card-text'>PurpleHealth enables healthcare providers with tools and technologies that assist them to better engage with their customers. The platform currently has a network of over 38,000 doctors and 4,000 hospitals, with a range of products and services that spans the entire spectrum of health and wellness needs. PurpleHealth has a team of 15 employees and it generates revenue through SaaS-based model.</p>"+
            "<p><b>Investors say</b></p>"+
            "<p className='card-text'>Balaji Jagannathan, Founder and CEO, Katabole Technology Venture, says,</p>"+
            "<p><b>PurpleHealth recognises the scale of the problems the health space presents, and looks for sustainable solutions that deal with the ecosystem, and involves all stakeholders, small and big - healthcare providers, corporates, other institutions and individual consumers.</b></p>"+
            "<p className='card-text'>Katabole invests in disruptive ideas across education, healthcare, fintech, digital, security, storage, IoT, mobile, social networks and data analytics.</p>"+
            "<p><b>A sneak peek into the market</b></p>"+
            "<p className='card-text'>In 2015, the funding in India’s healthtech space reached $277 million with 57 deals. According to IBEF, the healthcare market in India was worth close to $100 billion as of 2015, and is expected to touch $280 billion by 2020, clocking a CAGR of 22.9 percent. Of the overall market, the healthcare delivery sub-segment accounts for 65 percent.</p>"+
            "<p className='card-text'>About 90 percent of the startups evaluated in 2015 were working on preventive healthcare and monitoring solutions, revealed an InnAccel’s report. Hospitals, medical devices, clinical trials, outsourcing, tele-medicine, medical tourism, health insurance and medical equipment collectively form the pillar of healthcare sector.</p>"+
            "<p className='card-text'>Tencent-backed Practo is among the leading players in this space, having acquired multiple startups such as Fitho, Genii and Qikwell and entered the online medicine-ordering segment. Accel and IFC-backed Portea is another established player in this sector that acquired a majority stake in PSTakeCare in January this year. The list of dominant players also includes names like MedGenome, Lybrate and Medwell Ventures.</p>"
            ,
            image: news2
        },
        {
            id: "news12",
            authorname: "Team Inc42",
            date: "18 May 2016 ",
            heading: "PurpleHealth raises $100,000 from Katabole Venture",
            desc: "<p className='card-text'>Kerala-based on demand health platform PurpleHealth.com has raised $100K from Katabole Technology Venture.</p>"+
            "<p className='card-text'>Founded in 2014 by Vikram Nair, Mini Balaraman and Prakash Sathyapalan, PurpleHealth, provides diverse spectrum of solutions, services and products to consumers, corporates, healthcare providers, and institutions. The startup is consumer-focused and gives a greater control, choice and flexibility to people in connecting with doctors and healthcare providers. The platform provides one-stop seamless interface to consumers so that they can choose the right medical practitioners, schedule appointments, set up video & audio chats with doctors and much more, thus helping them better manage their health and wellness interactions.</p>"+
            "<p className='card-text'>Vikram Nair, co-founder & CEO, PurpleHealth said, “At PurpleHealth, we work with a unique ‘Total Health’ approach that focuses on meeting the country’s (and moving forward, the world’s) health-care challenges with integrated solutions through a 360-degree health platform.”</p>"+
            "<p className='card-text'>The Indian healthcare sector is expected to register a compounded annual growth rate (CAGR) of 22.9% during 2015-20 and grow to $280 Bn.</p>"+
            "<p className='card-text'>Nair added that they intend to play a substantial part in the growth story of Indian healthcare sector by creating a massive, positive impact on how health & wellness products and services are accessed by and delivered to every individual, corporate & institution.</p>"+
            "<p className='card-text'>PurpleHealth currently has network of 38,000 Doctors and 4000 Hospitals and operates through individual, independent and unconnected silos. The startup aims to build collaborative healthcare distribution models with a common customer pool, through integrated technology.</p>"+
            "<p className='card-text'>The startup plans to use the newly raised capital to further build its product roadmap and strengthen the team. </p>"
            ,
            image: news4
        }
    ]);
    
    return (
        <div className="ph-main-wrapper">
            <Header></Header>
            <div className="container-lg">
                {teamDetailArray1
                    .filter(news => news.id === newsIdNo)
                    .map((news, index) => (
                        <>
                            {/* <div className='d-block d-md-none ph-mobile-div mt-5'> 
                            <div >
                               { <img src={news.image} alt="News" className='img-fluid' width="100%" height="300" />}
                            </div>
                            <div className="ph-news-admin pt-1">
                               
                                <div className='ph-news-main-head'>
                                    {news.heading}
                                </div> 
                                <div className='d-flex mb-4'>
                                    <a href="https://www.facebook.com/gopurplehealth"><img src={Fb} alt="fb" className='img-fluid mr-2' width="30" height="30" /></a>
                                    <a href="https://www.linkedin.com/company/purplehealth"><img src={Linkdn} alt="Linkdn" className='img-fluid mr-2' width="30" height="30" /></a>
                                    <a href="https://www.instagram.com/gopurplehealth?igsh=cm02a21zdjdrb3d6"><img src={Insta} alt="Insta" className='img-fluid mr-2' width="28" height="28" /></a>
                                    
                                </div>
                            </div>
                            </div> */}
                             
                            <div className="ph-news-admin ph-news-letter-mobile">
                                <div className='ph-news-main-head'>
                                    {news.heading}
                                </div>
                                <div className='d-flex mb-0 mb-md-4 order-2'>
                                    <a href="https://www.facebook.com/gopurplehealth"><img src={Fb} alt="fb" className='img-fluid mr-2' width="30" height="30" /></a>
                                    <a href="https://www.linkedin.com/in/nagarjunmishra/"><img src={Linkdn} alt="Linkdn" className='img-fluid mr-2' width="30" height="30" /></a>
                                    <a href="https://www.instagram.com/gopurplehealth?igsh=cm02a21zdjdrb3d6"><img src={Insta} alt="Insta" className='img-fluid mr-2' width="28" height="28" /></a>
                                    
                                </div>
                                <div className='text-center'>
                               { <img src={news.image} alt="News" className='img-fluid news-details-img' width="100%" height="300" />}
                            </div>
                            </div>
                            
                            <div className='ph-news-details-main'>
                                <div className="ph-points" dangerouslySetInnerHTML={{ __html: news.desc }}>
                                </div>
                            </div>
                        </>
                    ))}
                <div className='ph-related-newsletter'>
                    <div className="ph-main-outerdiv">
                        <div className=''>
                            <div className='ph-newsletter px-0 px-md-2'>
                                Related Newsletter
                            </div>
                            {/* <div className="row">
                            {teamDetailArray1
                    .filter(news => news.id !== newsIdNo)
                    .map((news, index) => (
                                <div className="col-lg-4 ph-card-success1">
                                    <div className="ph-service-img">
                                        <img src={news.image} alt="Ser1" width="400" height="148" className='img-fluid' />

                                    </div>
                                    <div class="card-body px-0">
                                        <Link to="/case-study" className="ph-card-success-title">{news.heading}
                                        </Link>

                                        <div className="ph-points">
                                            <p class="card-text">PurpleHealth.com, a 360-degree ‘Health On Demand’ platform, has raised US$ 100,000 from disruptive tech investor, Katabole Technology Venture.
                                            </p>
                                        </div>
                                        <div className="ph-points">
                                            <p class="card-text">The two year-old PurpleHealth.com empowers consumers to take active control of their health by providing a one-stop seamless interface to choose the right medical practitioners, schedule appointments, set up video & audio chats with doctors and much more, thus helping them better manage their health.</p>
                                        </div>
                                        <div className='ph-bottom-btn'>
                                            <Link to="/case-study" className='ph-know-outcome'>
                                                Know the outcome
                                                <span className='ml-1'>
                                                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                                                </span>
                                            </Link>
                                        </div>
                                    </div>

                                </div>
                    )
                )}
                            </div> */}
<Newslist    newsId={ window.localStorage.getItem("newsIdNo")}
                            blogDetailFlag={true}/>
                            
                        </div>
                    </div>
                </div>
            </div>
            <AboutTakeNextStep />
            <Footer></Footer>
        </div>
    );
}

export default NewsDetails;
