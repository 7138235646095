import React, { Suspense } from "react";
import Header from "../../layout/header";
import Footer from "../../layout/footer";
window.localStorage.setItem("pageNumber", 1);
window.localStorage.setItem("offset", 0);

export default function SiteSettings() {
  return (
    <div className="purpleWrap bg-clr-wthHead">
      <Suspense fallback={<div></div>}>
        <section>
          <Header />

          <div className="container">
            <div>
              <ul className="breadcrum-adminMangmnt">
                <li>
                  <a href="/admin/dashboard">Dashboard</a>
                </li>
                <li>
                  <a href="/admin/dashboardAdmin">Admin Management</a>
                </li>
                <li>
                  <a href="/adminManagement/SiteSettings" className="current">
                    Site Settings
                  </a>
                </li>
              </ul>
            </div>
            <div className="head-hospMangmntList mt-4">Site Settings</div>

            <div className="sectionItemDiv siteset-sectnDivBorder sectionBorderadminMangmnt mt-0">
              <div className="adminMangemnt-dashboard">
                <div className="admin-dc-dashBtns">
                  <a href="/adminManagement/SiteSettings-AddEdit">
                    <div>Add/Edit Site</div>
                  </a>
                </div>
                {/* <div className="admin-dashBtns">
                  <a href="/adminManagement/SiteSettings-SiteModule">
                    <div>Site Module</div>
                  </a>
                </div>
                <div className="admin-dashBtns">
                  <a href="/adminManagement/SiteSettings-SiteModuleByHospital">
                    <div>Site Module By Hospital</div>
                  </a>
                </div>
                <div className="admin-dashBtns">
                  <a href="/adminManagement/SiteSettings-SiteModuleByDoctor">
                    <div>Site Module By Doctor</div>
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </section>
      </Suspense>
      <Suspense fallback={<div></div>}>
        <section>
          <Footer />
        </section>
      </Suspense>
    </div>
  );
}
