import React, { useEffect, useState } from "react";
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import Edit from "../../image/icons/edit.png";
import $ from "jquery";
import { apiCalling } from "../../apiService";
import { Link, useNavigate } from "react-router-dom";
import tick from "../../image/icons/tick.png";
import Deactivate from "../../image/icons/deactvate.png";

const hospitalId = JSON.parse(window.localStorage.getItem("dataPassing"));
const Procedurelist = () => {
  const [procedureListingData, setProcedureListingData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("Waiting For Data to Load...");
  const navigate = useNavigate();

  useEffect(() => {
    getProcedureList();
  }, []);

  const getProcedureList = () => {
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "procedureListing",
        maxlimit: "10",
        cmbSelect: "",
        txtInput: "",
        offset: "0",
        isActive: "",
        hospitalId: hospitalId
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === '1') {
        $(".loader").hide();
        setProcedureListingData(data.procedureListingData);
      }
      if (data.success === '0') {
        $(".loader").hide();
        setErrorMessage("No Data Found");
      }
    });
  };

  const statusChangeHandler = (id, Status) => {
    $(".loader").show();
    var x = false;
    if (Status === true) {
      x = window.confirm("Do you want to Disable this?");
    } else {
      x = window.confirm("Do you want to Enable this?");
    }
    if (x === true) {
      const apiJson = {
        method: "POST",
        body: JSON.stringify({
          functionName: "removeProcedure",
          isActive: Status === true ? "1" : "0",
          hospitalProcedureId: id
        })
      }
      apiCalling(apiJson).then((data) => {
        if (data.success === "1") {
          $(".loader").hide();
          alert("Status Changed Successfully");
          getProcedureList();
        }
        if (data.success === "0") {
          $(".loader").hide();
          alert(data.errorMessage);
        }
      })
    }
  };

  const changeText = () => {
    if ($("#selectCategory").val() !== "0") {
      document.getElementById("hospitalTxt").disabled = false;
      $("input[name=hospitalTxt]").focus();
    } else {
      document.getElementById("hospitalTxt").disabled = true;
    }
    document.getElementById("hospitalTxt").value = "";
  };

  const searchHandler = () => {
    if ($("#selectCategory").val() !== "0" && $("#hospitalTxt").val() === "") {
      var t = document.getElementById("selectCategory");
      var selectedText = t.options[t.selectedIndex].text;
      alert(selectedText + " Required");
      $("#hospitalTxt").focus();
      return false;
    }
    else {
      const searchData = {
        method: "POST",
        body: JSON.stringify({
          functionName: "procedureListing",
          cmbSelect: $("#selectCategory").val() === "0" ? "" : $("#selectCategory").val(),
          txtInput: ($("#hospitalTxt").val()).trim(),
          offset: "0",
          maxlimit: "10",
          isActive: $("#searchStatusType").val() === '' ? '' : $("#searchStatusType").val(),
          hospitalId: hospitalId
        }),
      };
      $(".loader").show();
      apiCalling(searchData).then((data) => {
        if (data.success === "1") {
          $("#paginationType").show();
          $(".loader").hide();
          setProcedureListingData(data.procedureListingData);
        }
        if (data.success === "0") {
          $("#paginationType").hide();
          $(".loader").hide();
          setProcedureListingData([]);
          setErrorMessage(data.errorMessage);
        }
      });
    }
  };

  const handleClickLink =  (data)  => {
    navigate('/adminManagement/hospitalManagementList/addProcedure', {
       state:  data  
    });
  };
  const handleClickEdit=(data)=>{
    navigate('/adminManagement/hospitalManagementList/addProcedure', {
      state:  data  
   });
  }

  return (
    <div className="purpleWrap bg-clr-wthHead">
      <section>
        <Header />
        <div className="procedureListWrapperHsp">
          <div className="container mb-5">
            <div>
              <ul className="breadcrum-adminMangmnt">
                <li><a href="/admin/dashboardAdmin">Dashboard</a></li>
                <li><a href="/adminManagement/hospitalManagementList/hospitalManagementList">Hospital Management</a></li>
                <li><a href="" className="current">Procedure Listing</a></li>
              </ul>
            </div>
            <div className="head-hospMangmntList pt-4">Procedure Listing</div>
            <div className="HosptlMangemntTable">
              <div className="AddNewHspbtnDiv pt-2 pb-2">
                <button 
                onClick={()=>handleClickLink({ hospitalId: hospitalId })}
                 className="addnewhspbtn">Add New Procedure</button>
              </div>
              <div className="tableHeader-HspMangemnt">
                <div className="selectField-HspMangmnt">
                  <select className="slecthsp slecthspMob" id="selectCategory" name="selectCategory" onChange={() => changeText()}>
                    <option value="0">Search In</option>
                    <option value="1">ID</option>
                    <option value="2">Hospital Name</option>
                    <option value="3">Phone</option>
                    <option value="4">City</option>
                    <option value="5">Category</option>
                  </select>
                </div>
                <div className="selectField-HspMangmnt">
                  <input type="text" className="hspmngInput hspmngInputmob" name="hospitalTxt" id="hospitalTxt" disabled tabIndex="0" onKeyPress={(event) => { if (event.key === "Enter" || event.key === "NumpadEnter") { event.preventDefault(); searchHandler(); } }} />
                </div>
                <div className="selectField-HspMangmnt">
                  <select className="slecthsp slecthspMob" id="searchStatusType" name="searchStatusType">
                    <option value="">Status</option>
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                  </select>
                </div>
                <div className="selectField-HspMangmnt">
                  <button className="searchbtn-hspMng" onClick={() => searchHandler()}>Search</button>
                </div>
              </div>
              {procedureListingData.length > 0 ?
                <div className="table-responsive">
                  <table className="table table-bordered-hsp">
                    <thead>
                      <tr>
                        <th className="tablehead-hspmng">ID</th>
                        <th className="tablehead-hspmng">Procedure Name</th>
                        <th className="tablehead-hspmng">Max Price</th>
                        <th className="tablehead-hspmng">Min Price</th>
                        <th className="tablehead-hspmng">Buy Price</th>
                        <th className="tablehead-hspmng">Procedure Image</th>
                        <th className="tablehead-hspmng">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {procedureListingData.map((data) => (
                        <tr key={data.hospitalProcedureId}>
                          <td>{data.hospitalProcedureId}</td>
                          <td>{data.procedureName}</td>
                          <td>{data.maxPrice}</td>
                          <td>{data.minPrice}</td>
                          <td>{data.buyPrice}</td>
                          <td>{data.imageNameWithUrl}</td>
                          <td>
                            <button  onClick={() => handleClickEdit({ hospitalId: hospitalId, hospitalProcedureId: data.hospitalProcedureId, procedureName: data.procedureName })}  className="mr-2 ">
                            {/* <Link to={{ pathname: "/adminManagement/hospitalManagementList/addProcedure", state: { hospitalId: hospitalId, hospitalProcedureId: data.hospitalProcedureId, procedureName: data.procedureName } }} className="mr-2 "> */}
                              <img src={Edit} alt="" title="Edit" width="18" className="img-fluid" />
                            {/* </Link> */}</button>
                            {data.isActive === true ?
                              <img src={tick} alt="" title="Click to Deactivate" className="mr-2 conf-cursorpointer" width="20" onClick={() => statusChangeHandler(data.hospitalProcedureId, false)} />
                              :
                              <img src={Deactivate} alt="" title="Click to Activate" className="mr-2 conf-cursorpointer" width="20" onClick={() => statusChangeHandler(data.hospitalProcedureId, true)} />}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                :
                <p className="error-msg text-center mb-0"> {errorMessage} </p>
              }
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Procedurelist;
