import React, { Component, Suspense } from "react";
import $, { event } from "jquery";
import HospitalPopup from "./hospitalPopup";
import Header from "../../../layout/header";
import Footer from "../../../layout/footer";
import { apiCalling } from "../../../apiService";
import Edit from "../../../image/icons/edit.png";
import Pagination from "react-js-pagination";

class hospitalProducts extends React.Component {
  state = {
    currentPage: "Hospital Products",
    cmbCategory: "",
    txtInput: "",
    max: "10",
    Offset: "0",
    hospitalProductData: [],
    allProducts:[],
    activePage: 1,
    navigation: [
      { pageName: "Dashboard", url: "/admin/dashboard" },
      {
        pageName: "Product Dashboard",
        url: "/adminManagement/productDashboard",
      },
      {
        pageName: "Hospital Products",
        url: "/adminManagement/hospitalProducts",
        current: true,
      },
    ],
    showHospitalPopup: false,
    hospitalDeatils: [],
  };

  componentDidMount() {
    this.getProductListing();
    this.getAllProducts();
  }

  changeHandler = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  getAllProducts = () => {
    $(".loader").show();
    const showAllProducts = {
      method: "POST",
      body: JSON.stringify({
        functionName: "showAllProducts"
      }),
    };
    apiCalling(showAllProducts).then((data) => {
      if (data.success === "1") {
        let allProducts = data.hospitalProductData;
        this.setState({
          allProducts: allProducts,
        });
      }
      $(".loader").hide();
    });
  };

  async handlePageChange(pageNumber) {
    await this.setState({
      activePage: pageNumber,
      Offset: (parseInt(pageNumber) - 1) * 10,
    });

    this.getProductListing();
  }

  handleCmbSerchChange = () => {
    var cmbSelectVal =
      $("#cmbCategory").val() !== "0" ? $("#cmbCategory").val() : "";
    $("#txtInput").prop("disabled", cmbSelectVal !== "" ? false : true);
    this.setState({ cmbCategory: cmbSelectVal, txtInput: "" });
    $("#txtInput").focus();
  };

  editProduct = (hospital) => {
    this.setState({
      showHospitalPopup: true,
      hospitalDeatils: hospital,
    });
  };

  getProductListing() {
    $(".loader").show();
    const productListing = {
      method: "POST",
      body: JSON.stringify({
        functionName: "productListing",
        cmbCategory: this.state.cmbCategory,
        txtInput: this.state.txtInput,
        max: "10",
        Offset: this.state.Offset,
      }),
    };
    apiCalling(productListing).then((data) => {
      if (data.success === "1") {
        var hospitalProductData = data.hospitalProductData;
        this.setState({
          hospitalProductData: hospitalProductData,
          totalCount: data.totalCount,
        });
      } else if (data.success === "0") {
        this.setState({ hospitalProductData: [], totalCount: 0 });
      }
      $(".loader").hide();
    });
  }

  handleSaveHospitalProductData = (state) => {
    let hospitalProductData = state.hospitalProductData;
    let commision = [];
    let productId = [];
    let statusLists = [];
    //debugger;
    let product2 = hospitalProductData.find(
      (element) => element.productId == 2
    );

    let expirydays = product2 == undefined ? 0 :product2.expiryDays;
    

    hospitalProductData.map((e) => {
      //if (e.status) {
        commision.push(e.commision == "" ? 0 : e.commision);
        productId.push(e.productId);
        statusLists.push(e.status?1:0)
      //}
    });
   
    $(".loader").show();
    const productSave = {
      method: "POST",
      body: JSON.stringify({
        commision: commision,
        functionName: "productSave",
        expirydays: expirydays == "" ? 0 : expirydays,
        productId: productId,
        status: statusLists,  
        hospitalId: state.hospitalId,              
      }),
    };
    apiCalling(productSave).then((data) => {
      if (data.success === "1") {
        this.setState({
          showHospitalPopup: false,
          hospitalDeatils: [],
        });
      } else if (data.success === "0") {
      }
      $(".loader").hide();
    });
  };

  renderHospitalProductData() {
    if (this.state.hospitalProductData.length > 0) {
      return this.state.hospitalProductData.map((hospital) => (
        <tr key={hospital.hospitalId}>
          <td style={{ width: "10vw" }}>{hospital.hospitalId}</td>
          <td>{hospital.hopsitalName}</td>
          <td style={{ width: "10vw" }} className="nowrap ph_siteedit">
            <div>
              <span>
                <a className="mr-2" onClick={() => this.editProduct(hospital)}>
                  <img src={Edit} alt="Edit" width="18" className="img-fluid" />
                </a>
              </span>
            </div>
          </td>
        </tr>
      ));
    } else {
      return (
        <tr>
          <td colSpan="6" className="text-center">
            No Data Found
          </td>
        </tr>
      );
    }
  }

  RenderList() {
    return (
      <div className="HosptlMangemntTable">
        <div className="tableHeader-HspMangemnt">
          <div className="selectField-HspMangmnt">
            <select
              className="slecthsp"
              id="cmbCategory"
              onChange={() => this.handleCmbSerchChange()}
            >
              <option value="0">Search In</option>
              <option value="1">Hospital Id</option>
              <option value="2">Hospital Name</option>
            </select>
          </div>
          <div className="selectField-HspMangmnt">
            <input
              type="text"
              className="hspmngInput"
              name="txtInput"
              id="txtInput"
              disabled
              value={this.state.txtInput}
              onInput={this.changeHandler}
            />
          </div>
          <div className="selectField-HspMangmnt">
            <button
              className="searchbtn-hspMng"
              onClick={() => this.getProductListing()}
            >
              Search
            </button>
          </div>
        </div>

        <div className="table-responsive">
        <table className="table table-bordered-hsp">
          <thead>
            <tr>
              <th className="tablehead-hspmng">Hospital Id</th>
              <th className="tablehead-hspmng">Hospital Name</th>
              <th className="tablehead-hspmng">Actions</th>
            </tr>
          </thead>
          <tbody>{this.renderHospitalProductData()}</tbody>
        </table>
        </div>
        {this.state.totalCount > 5 ? (
          <div className="paginationSection orderm-paginationSection pagination-HspList pagination-HspListwidth">
            <Pagination
              activePage={this.state.activePage}
              itemsCountPerPage={10}
              totalItemsCount={this.state.totalCount}
              pageRangeDisplayed={10}
              onChange={(pageNumber) => this.handlePageChange(pageNumber)}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }

  render() {
    return (
      <div className="purpleWrap bg-clr-wthHead">
        <Suspense fallback={<div>Waiting...</div>}>
          <section>
            <Header />
            <div className="loader"></div>
            <div className="container">
              <div>
                <ul className="breadcrum-adminMangmnt">
                  {this.state.navigation.map((nav) => (
                    <li>
                      <a
                        href={nav.url}
                        className={nav.current ? "current" : null}
                      >
                        {nav.pageName}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="head-hospMangmntList">
                {this.state.currentPage}
              </div>
              {this.RenderList()}
            </div>
          </section>
        </Suspense>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>

        {this.state.showHospitalPopup == true && (
          <HospitalPopup
            showHospitalPopup={(data) =>
              this.setState({ showHospitalPopup: data })
            }
            hospitalDeatils={this.state.hospitalDeatils}
            saveHospitalProductData={this.handleSaveHospitalProductData}
            allProducts = {this.state.allProducts}
          />
        )}
      </div>
    );
  }
}
export default hospitalProducts;
