import React, { useEffect } from 'react';
import Header from "../layout/header"
import Footer from "../layout/footer"
import bannerImg from '../image/theme/Case-Study-4.png'
import AboutTakeNextStep from './about-take-next-step';
import DigitalSuccess from "../home/digital-branding-success-stories"
function CaseStudy4() {
    useEffect(() => {
      document.getElementById("success-menu").classList.add("active");
    }, [])
    return (
        <div className='ph-main-wrapper'>
            <Header />
            <div className='ph-case-study'>
                <div className='ph-case-study-banner'>
                    <div className='container-lg'>
                        <div className='ph-case-study-inner-banner'>
                            <div className='row align-items-center'>
                                <div className='col-md-4 mb-3 mb-md-0'>
                                    <img src={bannerImg} className="img-fluid" alt='banner' width="auto" height="auto" />
                                </div>
                                <div className='col-md-8'>
                                    <div className="ph-db-banner">
                                        <span className="ph-banner-title d-none d-md-block">Case Studies</span>
                                        <h1 className="ph-case-study-title-1 mt-4 mt-md-5">Medical Rep to Doctor Engagement Software for Pharma </h1>
                                        {/* <div className='ph-case-study-btn-div'>
                                            <button className='ph-case-study-btn'><span className="ph-dot"></span>Solutions Act</button>
                                            <button className='ph-case-study-btn'><span className="ph-dot"></span>Customer Loyalty</button>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='ph-case-study-container'>
                    <div className='container-lg ph-lg-space'>
                        <div className='ph-case-study-inner-container'>
                            <h2 className="ph-main-title-2 mb-3">The Challenge  </h2>
                            <p className="lead-sm mb-0">The pharma industry has undergone tremendous changes in recent times due to the increase in competition as the industry has grown as well as changes in healthcare policies and regulations. Medical reps are finding it increasingly difficult to spend enough time with doctors to establish and maintain relationships critical for promoting pharma products.   </p>
                        </div>
                        <div className='ph-case-study-inner-container'>
                            <h2 className="ph-main-title-2 mb-3">Solution</h2>
                            <p className="lead-sm">
                            PurpleHealth has built a MR to Doctor engagement tool that allows for closed loop marketing between the MR, pharma company and doctors. This tool lets doctors and MR’s directly converse over chat, as well as lets doctors send requests for samples and product information. In addition there is promotional and informational opportunities for the pharma company to advertise directly to doctors.

</p>

                           
                            <p className="lead-sm mb-0">Finally, there is also full reporting and analytics on engagement and performance for pharma companies to benchmark and track their efforts.</p>
                        </div>
                        <div className='ph-case-study-inner-container'>
                            <h2 className="ph-main-title-2 mb-3">Outcome</h2>
                            <p className="lead-sm mb-0">Multiple pharma companies are using the PurpleHealth Doctor Connect solution for which they are engaged with 1000’s of doctors on the platform. Increase in doctor face-time has increased by 50% in terms of time spent with doctors and MR’s are providing feedback of improved doctor engagement. </p>
                        </div>
                    </div>
                </div>
                <DigitalSuccess />
                <AboutTakeNextStep />
            </div>
            <Footer />
        </div>
    )
}
export default CaseStudy4;