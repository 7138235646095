import React, { Component } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import purpleimg from "../image/theme/purple.png";
import closered from "../image/theme/closered.png";
import $ from "jquery";
import { apiCalling } from "../apiService";
import S3FileUpload from "react-s3";
export default class HealthRecord extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  uploadButton = () => {
    let file = "";
    const config = {
      bucketName: "myBucket",
      dirName: "photos",
      region: "eu-west-1",
      accessKeyId: "ANEIFNENI4324N2NIEXAMPLE",
      secretAccessKey: "cms21uMxçduyUxYjeg20+DEkgDxe6veFosBT7eUgEXAMPLE",
    };
    S3FileUpload.uploadFile(file, config)
      .then((data) => console.log(data))
      .catch((err) => console.error(err));
  };
  render() {
    return (
      <div className="purpleWrap">
        <Header></Header>
        <div className="container container-auto">
          <div className="main-wrapper1">
            <h4 className="mb-4">
              {" "}
              Health Records
              <button
                className="formButtonBg formButton-size float-right"
                style={{ fontWeight: "700" }}
              >
                <span className=" fa fa-plus" style={{ fontSize: "12px" }}></span>{" "}
                Add
              </button>
            </h4>
            <div className="form-section border mt-2">
              <p className="error-msg text-center"> No Records Found </p>
            </div>

            <div>
              <h6 className="mt-4"> Add Health Records </h6>
              <div className="form-section form-section-padng border mt-2">
                <div className="row row-margin">
                  <div className="col-sm-3">
                    <input
                      type="text"
                      placeholder="Enter File Name"
                      className="input-design"
                    />
                  </div>
                  <div className="col-sm-9 mt-3 mt-lg-0">
                    <div className="hrecordFileUpload">
                      <input
                        className="uploadattach"
                        type="file"
                        name="fileText"
                        id="fileText"
                      />
                    </div>
                    <div className="fileRecordThumb">
                      <img src={purpleimg} className="fileRecordThumbImg"></img>
                      <span className="thumbClose">
                        <img title="Delete" src={closered} />
                      </span>
                    </div>
                    <input
                      type="submit"
                      value="Upload"
                      className="formButton my-0"
                      onClick={() => this.uploadButton()}
                    ></input>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <h6 className="mt-4"> Health Records</h6>
              <div className="form-section p-3 border mt-2">
                <div className="fileRecordThumb fileRecordThumb-list">
                  <img src={purpleimg} className="fileRecordThumbImg"></img>
                  <small>02-Jul-2020 02:33:47 PM</small>
                  <span className="thumbClose">
                    <img title="Delete" src={closered} />
                  </span>
                </div>
                <div className="fileRecordThumb fileRecordThumb-list">
                  <img src={purpleimg} className="fileRecordThumbImg"></img>
                  <small>02-Jul-2020 02:33:47 PM</small>
                  <span className="thumbClose">
                    <img title="Delete" src={closered} />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer></Footer>
      </div>
    );
  }
}
