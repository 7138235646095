import React, { useEffect } from 'react';
import Header from "../layout/header"
import Footer from "../layout/footer"
import bannerImg from '../image/theme/Case-Study-2.png'
import AboutTakeNextStep from './about-take-next-step';
import DigitalSuccess from "../home/digital-branding-success-stories"
function CaseStudy1() {
    useEffect(() => {
      document.getElementById("success-menu").classList.add("active");
    }, [])
    return (
        <div className='ph-main-wrapper'>
        <Header/>
        <div className='ph-case-study'>
            <div className='ph-case-study-banner'>
                <div className='container-lg'>
                <div className='ph-case-study-inner-banner'>
                    <div className='row align-items-center'>
                        <div className='col-md-4 mb-3 mb-md-0'>
                            <img src={bannerImg} className="img-fluid" alt='banner' width="auto" height="auto"/>
                        </div>
                        <div className='col-md-8'>
                        <div className="ph-db-banner">
                                <span className="ph-banner-title d-none d-md-block">Case Studies</span>
                                <h1 className="ph-case-study-title-1 mt-4 mt-md-5">IVF Clinic’s Patient Engagement Soars: Home Consults Increase, Outreach grows by 3x! </h1>
                                {/* <div className='ph-case-study-btn-div'>
                                <button className='ph-case-study-btn'><span className="ph-dot"></span>Solutions Act</button>
                                <button className='ph-case-study-btn'><span className="ph-dot"></span>Customer Loyalty</button>
                            </div> */}
                            </div>                        
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className='ph-case-study-container'>
            <div className='container-lg ph-lg-space'>
                <div className='ph-case-study-inner-container'>
                <h2 className="ph-main-title-2 mb-3">The Challenge  </h2>
                <p className="lead-sm mb-0">Pran Fertility and Well Woman Centre wanted a telemedicine solution to connect doctors to patients. They did not want to be a part of a third party aggregation website as they wanted to maintain their own identity and brand, but at the same time they wanted the features and functionality that these aggregator portals offered, including patient engagement tools and the ability to provision multiple doctors with multiple services and pricing. </p>
                </div>
                <div className='ph-case-study-inner-container'>
                <h2 className="ph-main-title-2 mb-3">Solution</h2>
                <p className="lead-sm"> PurpleHealth built a comprehensive solution for Pran that offered their own branded mobile apps for their patients, which included a complete array of telemedicine services like video-chat, messaging, and audio calls. The solution was flexible enough to add multiple doctors with differentiated services and pricing.</p>
               <p className="lead-sm">
                This telemedicine solution also integrates with PurpleHealth’s clinic management solution that can manage patients, send prescriptions, provide billing and more. </p>
                
               <p className="lead-sm">In addition, PurpleHealth designs and provides marketing collateral for both digital marketing as well as in clinic/hospital posters for patient awareness and education.</p>
               <p className="lead-sm mb-0">PurpleHealth also supports, maintains and upgrades the platform with the latest, up to date  technologies to ensure the best experience for doctors and patients.</p>
                </div>
                <div className='ph-case-study-inner-container'>
                <h2 className="ph-main-title-2 mb-3">Outcome</h2>
                <p className="lead-sm mb-0">Pran patients increased their engagement with Pran physicians as they were able to do consults from home when convenient. In addition, the PurpleHealth solution increased Pran’s patient outreach ability by 3X, increasing the patient potential for the hospital in a substantial manner. </p>
                </div>
            </div>
        </div> 
        <DigitalSuccess/>
           <AboutTakeNextStep/>
        </div>
        <Footer/>
        </div>     
    )
}
export default CaseStudy1;