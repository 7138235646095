import React, { Suspense, useEffect, useState } from "react";
import $ from "jquery";
import { apiCalling } from "../../apiService";
import Edit from "../../image/icons/edit.png";
import IcoAddFooterLink from "../../image/icons/plus.png";
import IcoAddHeaderLink from "../../image/icons/plus.png";
import IcoAddPromoCode from "../../image/icons/plus.png";
import Pagination from "react-js-pagination";

export default function SiteSettingsAddEditList({ changeMode }) {
  const [state, setState] = useState({
    addOrEditSite: [],
    totalCount: 0,
    activePage: 1,
    offset: "0",
    maxRow: "10",
    cmbSelect: "",
    searchKeyWord: "",
  });

  //! functions

  useEffect(() => {
    getSiteList();
  }, []);

  const getSiteList = () => {
    $(".loader").show();
    const addOrEditSite = {
      method: "POST",
      body: JSON.stringify({
        functionName: "addOrEditSite",
        cmbSelect: $("#cmbSerch").val() !== "0" ? $("#cmbSerch").val() : "",
        searchKeyWord: state.searchKeyWord.trim(),
        max: state.maxRow,
        Offset: window.localStorage.getItem("offset"),
      }),
    };
    apiCalling(addOrEditSite).then((data) => {
      if (data.success === "1") {
        var siteData = data.siteData;
        setState((props) => ({
          ...props,
          addOrEditSite: siteData,
          totalCount: data.totalCount,
          activePage: Number(window.localStorage.getItem("pageNumber")),
        }));
        // this.setState({});
      } else if (data.success === "0") {
        setState((props) => ({
          ...props,
          addOrEditSite: [],
          totalCount: 0,
          activePage: 0,
        }));
        // this.setState({ addOrEditSite: [], totalCount: 0 });
      }
      $(".loader").hide();
    });
  };

  const handlePageChange = async (pageNumber) => {
    // await this.setState({
    //   activePage: pageNumber,
    //   offset: (parseInt(pageNumber) - 1) * 10,
    // });
    setState((props) => ({
      ...props,
      activePage: pageNumber,
      offset: (parseInt(pageNumber) - 1) * 10,
    }));
    window.localStorage.setItem("pageNumber", pageNumber);
    window.localStorage.setItem("offset", (parseInt(pageNumber) - 1) * 10);
    getSiteList();
  };
  const renderSiteData = () => {
    const navigation = [
      { pageName: "Dashboard", url: "/admin/dashboard" },
      { pageName: "Admin Management", url: "/admin/dashboardAdmin" },
      { pageName: "Site Settings", url: "/adminManagement/SiteSettings" },
      {
        pageName: "Add/Edit Site",
        url: "/adminManagement/SiteSettings-AddEdit",
        current: true,
      },
    ];

    const navigationPromoCode = [
      { pageName: "Dashboard", url: "/admin/dashboard" },
      { pageName: "Admin Management", url: "/admin/dashboardAdmin" },
      { pageName: "Site Settings", url: "/adminManagement/SiteSettings" },
      {
        pageName: "Add/Edit Site",
        url: "/adminManagement/SiteSettings-AddEdit",
      },
      { pageName: "Add Promo Code", url: "#", current: true },
    ];

    const navigationFooter = [
      { pageName: "Dashboard", url: "/admin/dashboard" },
      { pageName: "Admin Management", url: "/admin/dashboardAdmin" },
      { pageName: "Site Settings", url: "/adminManagement/SiteSettings" },
      {
        pageName: "Add/Edit Site",
        url: "/adminManagement/SiteSettings-AddEdit",
      },
      { pageName: "Add Footer", url: "#", current: true },
    ];

    const navigationHeader = [
      { pageName: "Dashboard", url: "/admin/dashboard" },
      { pageName: "Admin Management", url: "/admin/dashboardAdmin" },
      { pageName: "Site Settings", url: "/adminManagement/SiteSettings" },
      {
        pageName: "Add/Edit Site",
        url: "/adminManagement/SiteSettings-AddEdit",
      },
      { pageName: "Add Header", url: "#", current: true },
    ];

    if (state.addOrEditSite.length > 0) {
      return state.addOrEditSite.map((site) => (
        <tr key={site.siteId}>
          <td>{site.siteId}</td>
          <td>{site.siteName}</td>
          <td>
            <a href={site.siteUrl.startsWith('http') ? site.siteUrl : `https://${site.siteUrl}`} target="_blank">
              {site.siteUrl}
            </a>
          </td>
          <td>{site.siteTitle}</td>
          <td>{site.contactNo}</td>
          <td>{site.email}</td>
          <td style={{ width: "10vw" }} className="nowrap ph_siteedit">
            <div>
              <span>
                <a
                  className="mr-2"
                  onClick={() =>
                    changeMode("edit", navigation, site.siteId, "Add/Edit Site")
                  }
                >
                  <img src={Edit} alt="Edit" width="18" className="img-fluid" />
                </a>
              </span>
              <span>
                <a
                  className="mr-2"
                  onClick={() =>
                    changeMode(
                      "addNewHeaderLink",
                      navigationHeader,
                      site.siteId,
                      "Header Link",
                      site.groupHospitalAdminId
                    )
                  }
                  title="Add Header Links"
                >
                  <img
                    src={IcoAddHeaderLink}
                    alt="Add Header Links"
                    width="22"
                    className="img-fluid"
                  />
                </a>
              </span>
              <span>
                <a
                  className="mr-2"
                  onClick={() =>
                    changeMode(
                      "addNewFooterLink",
                      navigationFooter,
                      site.siteId,
                      "Footer Link",
                      site.groupHospitalAdminId
                    )
                  }
                  title="Add Footer Link"
                >
                  <img
                    src={IcoAddFooterLink}
                    alt="Add Footer Link"
                    width="22"
                    className="img-fluid"
                  />
                </a>
              </span>
              <span>
                <a
                  className="mr-2"
                  onClick={() =>
                    changeMode(
                      "addNewPromoCode",
                      navigationPromoCode,
                      site.siteId,
                      "Promo Code",
                      site.groupHospitalAdminId
                    )
                  }
                  title="Add Promo Code"
                >
                  <img
                    src={IcoAddPromoCode}
                    alt="Add Promo Code"
                    width="22"
                    className="img-fluid"
                  />
                </a>
              </span>
            </div>
          </td>
        </tr>
      ));
    } else {
      return (
        <tr>
          <td colSpan="7" className="text-center">
            No Data Found
          </td>
        </tr>
      );
    }
  };

  const navigationEdit = [
    { pageName: "Dashboard", url: "/admin/dashboard" },
    { pageName: "Admin Management", url: "/admin/dashboardAdmin" },
    { pageName: "Site Settings", url: "/adminManagement/SiteSettings" },
    {
      pageName: "Add/Edit Site",
      url: "/adminManagement/SiteSettings-AddEdit",
      current: true,
    },
  ];

  const handleCmbSerchChange = () => {
    $("#searchKeyWord").val("");
    var cmbSelectVal = $("#cmbSerch").val() !== "0" ? $("#cmbSerch").val() : "";
    // if(cmbSelectVal!==0){
      $("#searchKeyWord").prop("disabled", cmbSelectVal !== "" ? false : true);
      // setState({
      //       cmbSelect: cmbSelectVal,
      //       //searchKeyWord: $("#searchKeyWord").val(),
      //     });

     // alert($("#searchKeyWord").val())
    // }
  //   $("#searchKeyWord").val("");
  //   var cmbSelectVal = $("#cmbSerch").val() !== "0" ? $("#cmbSerch").val() : "";
  //   // $("#searchKeyWord").val("")
  //   $("#searchKeyWord").prop("disabled", cmbSelectVal !== "" ? false : true);
  //  setState({
  //     cmbSelect: cmbSelectVal,
  //     searchKeyWord: $("#searchKeyWord").val(),
  //   });
  //   alert(state.cmbSelect);alert(state.searchKeyWord);
  //   $("#searchKeyWord").focus();
  };

  const changeHandler = (e) => {
    setState((props) => ({
      ...props,
      [e.target.id]: e.target.value,
    }));
    // this.setState({ [e.target.id]: e.target.value });
  };

  return (
    <div className="HosptlMangemntTable">
      <div className="AddNewHspbtnDiv">
        <a
          className="addnewhspbtn"
          onClick={() =>
            changeMode("addNew", navigationEdit, 0, "Add/Edit Site")
          }
        >
          Add New Site
        </a>
      </div>

      {state.totalCount > 5 ? (
        <div className="paginationSection orderm-paginationSection pagination-HspList">
          <Pagination
            activePage={state.activePage}
            itemsCountPerPage={10}
            totalItemsCount={state.totalCount}
            pageRangeDisplayed={10}
            onChange={(pageNumber) => handlePageChange(pageNumber)}
          />
        </div>
      ) : (
        ""
      )}

      <div className="tableHeader-HspMangemnt">
        <div className="selectField-HspMangmnt">
          <select
            className="slecthsp"
            id="cmbSerch"
            onChange={() => handleCmbSerchChange()}
          >
            <option value="0">Search In</option>
            <option value="siteId">Site Id</option>
            <option value="siteName">Site Name</option>
            <option value="siteUrl">Site URL</option>
            <option value="title">Title</option>
            <option value="contactNo">Contact Number</option>
            <option value="email">Email</option>
          </select>
        </div>
        <div className="selectField-HspMangmnt">
          <input
            type="text"
            className="hspmngInput"
            name="searchKeyWord"
            id="searchKeyWord"
            disabled
            value={state.searchKeyWord}
            onInput={changeHandler}
            tabIndex="0"
            onKeyPress={(event) => {
              if (event.key === "Enter" || event.key === "NumpadEnter") {
                event.preventDefault();
                getSiteList();
              }
            }}
          />
        </div>
        <div className="selectField-HspMangmnt">
          <button className="searchbtn-hspMng" onClick={() => getSiteList()}>
            Search
          </button>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-bordered-hsp">
          <thead>
            <tr>
              <th className="tablehead-hspmng">ID</th>
              <th className="tablehead-hspmng">Site Name</th>
              <th className="tablehead-hspmng">Site URL</th>
              <th className="tablehead-hspmng">Title</th>
              <th className="tablehead-hspmng">Contact Number</th>
              <th className="tablehead-hspmng">Email</th>
              <th className="tablehead-hspmng">Actions</th>
            </tr>
          </thead>
          <tbody>{renderSiteData()}</tbody>
        </table>
      </div>
      {state.totalCount > 5 ? (
        <div className="paginationSection orderm-paginationSection pagination-HspList pagination-HspListwidth">
          <Pagination
            activePage={state.activePage}
            itemsCountPerPage={10}
            totalItemsCount={state.totalCount}
            pageRangeDisplayed={10}
            onChange={(pageNumber) => handlePageChange(pageNumber)}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
