import React, { Component,useEffect, Suspense, useState } from "react";
import { useParams } from "react-router-dom";
import { apiCalling } from "../../apiService";
import Edit from "../../image/icons/edit.png";
import tick from "../../image/icons/tick.png";
import Deactivate from "../../image/icons/deactvate.png";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import Logoimg from "../../image/icons/image-preview.png";
import Plus from "../../image/icons/plus.png";
import Closebtn from "../../image/icons/close-sm.png";
import DatePicker from "react-datepicker";
import moment from "moment";
import AWS from "aws-sdk";
import $ from "jquery";
import "react-datepicker/dist/react-datepicker.css";
import { FormSelect } from "semantic-ui-react";
var s3;
var imageFile;
const AddEditIdaStore=()=> {
  const[name,setname]=useState("");
  const[address,setaddress]=useState("");
  const[imageLogo,setimageLogo]=useState("");
  const[storeCode,setstoreCode]=useState("");
  const[adName,setadName]=useState("");
  const[contactNo,setcontactNo]=useState("");
  const[seviceContactNumber,setseviceContactNumber]=useState("");
  const[idaStoreDetailsId,setidaStoreDetailsId]=useState("");
  const[idaStoreCategoryId,setidaStoreCategoryId]=useState("");
  const[imagePath,setimagePath]=useState("");
  const[s3bucketAccessKey,sets3bucketAccessKey]=useState("");
  const[pharmaImagePath,setpharmaImagePath]=useState("");
  const[companyLogoName,setcompanyLogoName]=useState("");
  const[AWSAccessKeyId,setAWSAccessKeyId]=useState("");
  const[region,setregion]=useState("us-east-1");
  const[bucket,setbucket]=useState("");
  const[bucketFolder,setbucketFolder]=useState("");
  const[categoryList,setcategoryList]=useState([]);
  const[imageNameOnly,setimageNameOnly]=useState("");
  const[imageNameWithPath,setimageNameWithPath]=useState("");
  const[S3bucketAttachmentPath,sets3bucketAttachmentPath]=useState("");
  
  const [formData, setFormData] = useState({});
  const { storeId } = useParams();
  useEffect(() => {
    imageUploadHandler();
    getStoreCategoryDetails();
    if (storeId) {
      editIdaStoreDetails();
    }
  }, []);


 const getStoreCategoryDetails=()=> {
    const apiReq = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getStoreCategoryDetails",
        keyWord: "",
      }),
    };
    apiCalling(apiReq).then((data) => {
      setcategoryList(data.result);
    });
  }

  const editIdaStoreDetails=()=> {
    const idaStoreData = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getStoreListingByCategory",
        idaStoreCategoryId: "",
        keyWord: "",
        idaStoreDetailsId: storeId,
        Offset: "0",
        max: "10",
      }),
    };
    $(".loader").show();
    apiCalling(idaStoreData).then((data) => {
      if (data.success === "1") {
        setname(data.result[0].storeName);
        setaddress(data.result[0].address);
        setcontactNo(data.result[0].contactNo);
        setstoreCode(data.result[0].storeCode);
        setseviceContactNumber(data.result[0].seviceContactNumber);
        setidaStoreCategoryId(data.result[0].idaStoreCategoryId);
        setidaStoreDetailsId(data.result[0].idaStoreDetailsId);
        setimageNameOnly(data.result[0].imageNameOnly);
        setimageNameWithPath(data.result[0].imageNameWithPath);
      }
      $(".loader").hide();
    });
  }
  const textType=(e)=> {
    var code = "charCode" in e ? e.charCode : e.keyCode;
    if (!(code > 47 && code < 58)) {
      // lower alpha (a-z)
      alert("Only Numbers Accepted");
      e.preventDefault();
    }
  }

  const numberHandleChange=(event)=> {
    const re = /^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      setcontactNo(event.target.value);
    }
  }
  const numberHandleChangeService=(event) =>{
    const re = /^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      setseviceContactNumber(event.target.value);
    }
  }
 const descChange=(e)=> {
  const { name, value } = e.target;
  setFormData(prevState => ({
    ...prevState,
    [name]: value
  }));
  }
 const cancelHandle=() =>{
    window.location.href = "/adminManagement/pharmadashboardList/idaStoresList";
  }
  const srcTypeImage=(ev) =>{
    ev.target.src = Logoimg;
  }
 const fileChange = (e) => {
    imageFile = e.target.files[0];
    if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
      alert("Add file format with only JPG,JPEG,PNG format");
      return false;
    }
    if (imageFile.size > 3145728) {
      alert("File size must under 3 MB!");
      return false;
    } else {
      var fileObj = e.target.files[0];
      setimageNameWithPath(window.URL.createObjectURL(fileObj))
      fileUpload(imageFile);
    }
  };
 const imageUploadHandler=()=> {
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "imagePath",
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        $(".loader").hide();
        sets3bucketAccessKey(data.configPathDetails[0].s3bucketAccessKey);
        setAWSAccessKeyId(data.configPathDetails[0].s3bucketSecretKey);
        setbucket(data.configPathDetails[0].PurpleHealthS3bucket);
        setbucketFolder(data.configPathDetails[0].S3BucketMainFolder);
        setpharmaImagePath(data.configPathDetails[0].pharmaImagePath);
        sets3bucketAttachmentPath(data.configPathDetails[0].s3bucketAttachmentPath)
        
      }
    });
  }
  const fileUpload=(file)=> {
    $(".loader").show();
    AWS.config.update({
      accessKeyId: s3bucketAccessKey,
      secretAccessKey: AWSAccessKeyId,
      region: region,
    });
    debugger;
    var bucketParams = {
      Bucket:
        bucket
        +
        "/" +
       bucketFolder+"/"+S3bucketAttachmentPath
      // +
      // "/" +
      // this.state.imageNameWithPath,
    };
    s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
    let S3BucketMainFolder = "";
    let fileName =uuidv4() + file.name;
    let fullFilePath = S3BucketMainFolder + fileName;
    imageFile = fileName;
    setimageNameWithPath(fileName);
    s3.upload(
      {
        Key: fullFilePath,
        Body: file,
        ACL: "public-read",
      },
      (err, data) => {
        if (err) {
          alert("There was an error uploading your photo: ", err.message);
        } else if (data) {
          setimageNameWithPath(data.Location);
          setimageNameOnly(fileName);
          $(".loader").hide();
          return null;
        }
      }
    );
  }
  const uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };
 const  validateForm=()=> {
  var name=$('#storeName').val();
  var address=$('#address').val();
  var storeCode=$('#storeCode').val();
  var contactNo=$('#contactNo').val();

  var seviceContactNumber=$('#serviceNo').val();
    if (name === "") {
      alert("Store Name Required");
      $("#storeName").focus();
      return false;
    }
    else if (address === "") {
      alert("Address Required");
      $("#address").focus();
      return false;
    }
    else if (storeCode === "") {
      alert("Store Code Required");
      $("#storeCode").focus();
      return false;
    }
    else if (idaStoreCategoryId === "") {
      alert("Store Category Required");
      $("#StoreCategoryId").focus();
      return false;
    }
    else if (contactNo === "") {
      alert("Contact Number Required");
      $("#contactNo").focus();
      return false;
    } else if (!contactNo.match(/^[0-9.\b]{10}$/)) {
      alert("Please enter valid mobile number");
      $("#contactNo").focus();
    }
    else if (seviceContactNumber === "") {
      alert("Service Number Required");
      $("#serviceNo").focus();
      return false;
    }
    else if (!seviceContactNumber.match(/^[0-9.\b]{10}$/)) {
      alert("Please enter valid mobile number");
      $("#serviceNo").focus();
    }
   
    
    else if (imageNameWithPath === "") {
      alert("Image required.");
      return false;
    }
    else {
      return true;
    }
  }
  const saveHandle = () => {
    if (validateForm()) {
      //debugger;
      var name=$('#storeName').val();
      var address=$('#address').val();
      var storeCode=$('#storeCode').val();

      const apiReq = {
        method: "POST",
        body: JSON.stringify({
          functionName: "saveIdaStoreDetails",
          idaStoreDetailsId:
            idaStoreDetailsId !== "" ? idaStoreDetailsId : "",
          name:name,
          address: address,
          storeCode: storeCode !== "" ? storeCode : "",
          idaStoreCategoryId: idaStoreCategoryId,
          contactNo:contactNo,
          seviceContactNumber: seviceContactNumber,
          image: imageNameOnly,
        })
      }
      $(".loader").show();
      apiCalling(apiReq).then((data) => {
        $(".loader").hide();
        if (data.success == 1) {
          alert("Saved Successfully");
          window.location.href =
            "/adminManagement/pharmadashboardList/idaStoresList";
        } else {
          alert("Something went wrong while saving");
        }
      });
    }
  };
  
    return (
      <div className="purpleWrap bg-clr-wthHead">
        <Suspense fallback={<div></div>}>
          <section>
            <Header /> <div className="loader"></div>
          </section>
        </Suspense>
        <div className="loader"></div>
        <div className="container mb-5">
          <div>
            <ul className="breadcrum-adminMangmnt">
              <li>
                <a href="/admin/dashboardAdmin">Dashboard</a>
              </li>
              <li>
                <a href="/adminDashboard/pharmaDashboard">Pharma Ad Approval</a>
              </li>
              <li>
                <a href="/adminManagement/pharmadashboardList/idaStoresList">
                  IDA Stores List
                </a>
              </li>
              <li>
                {storeId > 0 ? <a href="" className="current">Edit IDA Store
                </a> : <a href="" className="current">Add New IDA Store</a>}
              </li>
            </ul>
          </div>
        </div>
        <div className="addrepwrapperborder container pt-5 m-auto text-left purple_edit">
          <div className="row">
            <div className="col-md-6 mb-3">
              <div className="row">
                <div className="col-md-4">
                  Name<span className="text-danger">*</span>
                </div>
                <div className="col-md-8">
                  <input
                    type="text"
                    id="storeName"
                    className="form-control"
                    name="name"
                    value={formData.name?formData.name:name}
                    onChange={(e) => descChange(e)}
                  />
                </div>
              </div>
            </div>
            {storeId > 0 ? <div className="col-md-6 mb-3">
              <div className="row">
                <div className="col-md-4">
                  Ida Store Id<span className="text-danger">*</span>
                </div>
                <div className="col-md-8">
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    disabled
                    value={idaStoreDetailsId}
                  />
                </div>
              </div>
            </div> : ""}
            <div className="col-md-6 mb-3">
              <div className="row">
                <div className="col-md-4">
                  Address<span className="text-danger">*</span>
                </div>
                <div className="col-md-8">
                  <input
                    type="text"
                    id="address"
                    className="form-control"
                    name="address"
                    value={formData.address?formData.address:address}
                    onChange={(e) => descChange(e)}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="row">
                <div className="col-md-4">
                  Store Code<span className="text-danger">*</span>
                </div>
                <div className="col-md-8">
                  <input
                    type="text"
                    className="form-control"
                    name="storeCode"
                    id="storeCode"
                    value={formData.storeCode?formData.storeCode:storeCode}
                    onChange={(e) => descChange(e)}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="row">
                <div className="col-md-4">
                  Ida Store Category<span className="text-danger">*</span>
                </div>
                <div className="col-md-8">
                  <select
                    type="text"
                    className="form-control"
                    name="idaStoreCategoryId"
                    id="StoreCategoryId"
                    value={idaStoreCategoryId}
                    onChange={(e) =>
                      setidaStoreCategoryId(e.target.value)
                    }
                  >
                    <option value="">Select</option>
                    {categoryList.map((data) => (
                      <option value={data.idaStoreCategoryId}>
                        {data.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="row">
                <div className="col-md-4">
                  Contact No<span className="text-danger">*</span>
                </div>
                <div className="col-md-8">
                  <input
                    type="text"
                    id="contactNo"
                    className="form-control"
                    name="contactNo"
                    maxLength="10"
                    value={contactNo}
                    onChange={numberHandleChange}
                    onKeyPress={textType}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="row">
                <div className="col-md-4">
                  Service Contact Number<span className="text-danger">*</span>
                </div>
                <div className="col-md-8">
                  <input
                    type="text"
                    id="serviceNo"
                    className="form-control"
                    name="seviceContactNumber"
                    maxLength="10"
                    value={seviceContactNumber}
                    onChange={numberHandleChangeService}
                    onKeyPress={textType}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6 mb-3">
              <div className="row">
                <div className="col-md-4">
                  Image<span className="text-danger">*</span>
                </div>
                <div className="col-md-8 d-flex align-items-center">
                  <div className="hspinfo-btnupload mb-auto">
                    <span>Upload</span>
                    <input
                      type="file"
                      className="hspinfoInputfieldupload"
                      onChange={fileChange}
                    />
                  </div>
                  {imageNameWithPath ? (
                    <div className="file_img">
                      <img
                        id="imageNameWithPath"
                        src={imageNameWithPath}
                        onError={srcTypeImage}
                        width="50"
                        alt="Upload"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-12 text-right mb-3">
              <input
                type="submit"
                className="smssetng-svebtn"
                value={storeId > 0 ? "Update" : "Save"}
                onClick={() => saveHandle()}
              />
              <input
                type="submit"
                value="Cancel"
                className="smssetng-cancelbtn"
                onClick={cancelHandle}
              />
            </div>
          </div>
        </div>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  
}
export default AddEditIdaStore;
