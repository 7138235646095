import React, { Component } from 'react';
import digitalBrand from "../image/theme/digital-marketing-banner.png"
export default class digitalMarketingBanner extends Component {

  render() {
    return (
        <div className="ph-main-outer pb-0 pt-1">
     <div className='container-lg'>
        <div className="row">
            <div className="col-lg-6 d-md-flex justify-content-center align-items-center">
                <div className="ph-db-banner">
                    {/* <span className="ph-banner-title">
                       Products
                    </span> */}
                    <h1 className="ph-main-title mt-4">
                    Digital Marketing
                    </h1>
                    <p className="lead">
                    Boost your online presence with digital marketing strategies, content planning, language localisation across digital platforms, and more.
                    </p>
                </div>
            </div>
            <div className="col-lg-6 pt-5 py-md-0 pb-3">
                <div>
                    <img src={digitalBrand} alt="digitalBrand" width="" height="" className='img-fluid'/>
                </div>
            </div>
        </div>
    </div>
    </div>
    )
  }
}
