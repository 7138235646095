import React, { Component } from "react";
import Header from "../layout/header";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Footer from "../layout/footer";
import $ from "jquery";
import { apiCalling, changeDateFormat } from "../apiService";
export default class Bookappointment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      appDocId: "",
      appHosId: "",
      testState: "",
      appType: "",
      reasonForVisit: "",
      seeingDr: "me",
      sFirstName: "",
      sLastName: "",
      sGender: "",
      sDOB: "",
      userDOB: "",
      maxDate: "",
      appDate: "",
      clicked: false,
      appMessage: "",
      appointmentSlot: [],
    };
    if (this.props.location.doctorId) {
      localStorage.setItem("appDocId", this.props.location.doctorId);
      localStorage.setItem("appHosId", this.props.location.hospitalId);
      localStorage.setItem("flagOption", this.props.location.flagOption);
    }
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputChangeSlot = this.handleInputChangeSlot.bind(this);
  }
  handleInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };
  handleInputChangeSlot = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
    let newAppDate = changeDateFormat(value);
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "appointmentSlot",
        appoinmentType: this.state.appType,
        doctorId: localStorage.getItem("appDocId"),
        hospitalId: localStorage.getItem("appHosId"),
        appointmentDate: newAppDate,
      }),
    };

    apiCalling(apiJson).then((data) => {
      if (data.isExist == "true") {
        this.setState({ appointmentSlot: data.slotVal });
      } else {
        alert("Doctor is not available at selected date.");
        this.setState({ appointmentSlot: data.slotVal });
        $("#appDate").val("");
      }
    });
  };
  componentDidMount() {
    let self = this;
    let today = new Date();
    var month = ("0" + (today.getMonth() + 1)).slice(-2);
    var date = ("0" + today.getDate()).slice(-2);
    self.state.maxDate = today.getFullYear() + "-" + month + "-" + date;
    self.setState({ appType: localStorage.getItem("flagOption") });
    if (localStorage.getItem("flagOption") == "v") {
      $(".radioVideo").prop("checked", true);
    }
    if (localStorage.getItem("flagOption") == "p") {
      $(".radioPerson").prop("checked", true);
    }
    if (localStorage.getItem("flagOption") == "c") {
      $(".radioCall").prop("checked", true);
    }
    $("#divSomeoneElse").hide();
    $(".radioSomeoneelse").click(function () {
      $("#divSomeoneElse").show();
      $("#divSomeoneMe").hide();
      $(".radioSomeoneelse").prop("checked", true);
    });
    $(".radioMe").click(function () {
      $("#divSomeoneElse").hide();
      $("#divSomeoneMe").show();
    });
    $("#book-warnalert").hide();

    $("#bookAppButton").click(function () {
      const appType = self.state.appType;
      const appDate = $("#appDate").val();
      let appMessage = "";
      let newAppDate = "";
      if (appDate) {
        newAppDate = changeDateFormat(appDate);
      }

      const appTime = $("#newSlot").val();
      const reasonForVisit = self.state.reasonForVisit;
      const seeingDr = self.state.seeingDr;

      const userDOB = changeDateFormat(self.state.userDOB);
      const sFirstName = "";
      const sLastName = "";
      const sGender = "";
      const sDOB = "";
      if (seeingDr == "someone") {
        sFirstName = self.state.sFirstName;
        sLastName = self.state.sLastName;
        sGender = self.state.sGender;
        sDOB = self.state.sDOB;
      }
      //alert(appTime);
      if (!newAppDate) {
        appMessage = "Please select your appointment date properly";
      } else if (appTime == "" || appTime == null) {
        appMessage = "Please select your appointment time properly";
      }
      if (appMessage == "") {
        self.setState({
          clicked: true,
        });
        // $("#book-warnalert").show().delay(5000).fadeOut(300);
        //// $("html, body").animate(
        // {
        // scrollTop: $("#book-warnalert").offset().top,
        // },
        // 5000
        // );

        const apiJson = {
          method: "POST",
          body: JSON.stringify({
            functionName: "createAppointment",
            userId: localStorage.getItem("userId"),
            siteId: localStorage.getItem("siteId"),
            doctorId: localStorage.getItem("appDocId"),
            hospitalId: localStorage.getItem("appHosId"),
            appointmentType: appType,
            appointmentDate: newAppDate,
            appointmentTime: appTime,
            reasonForVisit: reasonForVisit,
            appointmentPerson: "",
            dateOfBirth: userDOB,
            firstName: sFirstName,
            gender: sGender,
            lastName: sLastName,
            newUserDOB: sDOB,
            newUserGender: sGender,
          }),
        };
        localStorage.setItem("appArray", apiJson.body);
        window.location.href = "../patient/bookappointmentsuccess";
      } //if loop
      else {
        alert(appMessage);
      }
      //+ appData.body;
    });
  }
  alertclose() {
    $("#book-warnalert").hide();
  }

  render() {
    return (
      <div className="purpleWrap">
        <Header></Header>
        <div className="container">
          <div className="main-wrapper main-wrapper-padding">
            <div className="memberHeadGrayBg">
              <h3>Request Appointment</h3>
            </div>
            <div className="form-section form-section-padng">
              <div className="alert warnalert alert-danger" id="book-warnalert">
                {this.state.appMessage}
                <button type="button" className="close" onClick={this.alertclose}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="row row-margin">
                <label className="col-sm-4 label-text">Type of Appointment*</label>
                <div className="col-sm-8">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input radioPerson"
                      type="radio"
                      id="appType"
                      name="appType"
                      value="p"
                      onChange={this.handleInputChange}
                    />
                    <label className="form-check-label " for="radioPerson">
                      In Person
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input radioVideo"
                      type="radio"
                      id="appType"
                      value="v"
                      name="appType"
                      onChange={this.handleInputChange}
                    />
                    <label className="form-check-label" for="radioVideo">
                      Video Chat
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input radioCall"
                      type="radio"
                      id="appType"
                      value="c"
                      name="appType"
                      onChange={this.handleInputChange}
                    />
                    <label className="form-check-label" for="radioCall">
                      Audio Chat
                    </label>
                  </div>
                </div>
              </div>

              <div className="row row-margin">
                <label className="col-sm-4 label-text">Appointment Date*</label>
                <div className="col-sm-8">
                  <input
                    type="date"
                    className="input-design"
                    placeholder="Date OF Birth"
                    id="appDate"
                    name="appDate"
                    min={this.state.maxDate}
                    data-date-format="DD/MM/YYYY"
                    onChange={this.handleInputChangeSlot}
                    // onchange="this.className=(this.value!=''?'has-value': '')"
                  />
                </div>
              </div>

              <div className="row row-margin">
                <label className="col-sm-4 label-text">Appointment Time*</label>
                <div className="col-sm-8">
                  {/*<select
                    id="appTime_hr"
                    name="hh"
                    className="input-design selecttime"
                  >
                    <option value="">HH</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                  </select>
                  <select
                    id="appTime_min"
                    name="mm"
                    className="input-design selecttime"
                  >
                    <option value="">MM</option>
                    <option value="00">00</option>
                    <option value="15">15</option>
                    <option value="30">30</option>
                    <option value="45">45</option>
                  </select>
                  <select
                    id="appTime_ampm"
                    name="amorpm"
                    className="input-design selecttime"
                  >
                    <option value="">AM/PM</option>
                    <option value="AM">AM</option>
                    <option value="PM">PM</option>
                  </select>*/}
                  <select
                    id="newSlot"
                    name="mm"
                    className="input-design newSlot selecttime"
                  >
                    {this.state.appointmentSlot.map((obj, index) => {
                      return <option value={obj}>{obj}</option>;
                    })}
                  </select>
                </div>
              </div>

              <div className="row row-margin">
                <label className="col-sm-4 label-text">
                  Reason For Appointment*
                </label>
                <div className="col-sm-8">
                  <textarea
                    className="input-design"
                    placeholder="Reason For Appointment"
                    rows="3"
                    name="reasonForVisit"
                    id="reasonForVisit"
                    onChange={this.handleInputChange}
                  ></textarea>
                </div>
              </div>

              <div className="row row-margin">
                <label className="col-sm-4 label-text">
                  Who will be seeing the Doctor?
                </label>
                <div className="col-sm-8">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input radioMe"
                      type="radio"
                      id="seeingDr"
                      name="seeingDr"
                      value="me"
                      onChange={this.handleInputChange}
                      checked
                    />
                    <label className="form-check-label" for="radioMe">
                      Me
                    </label>
                  </div>
                  <div className="form-check form-check-inline ">
                    <input
                      className="form-check-input radioSomeoneelse"
                      type="radio"
                      name="seeingDr"
                      id="seeingDr"
                      onChange={this.handleInputChange}
                      value="someone"
                    />
                    <label className="form-check-label" for="radioSomeoneelse">
                      Someone else
                    </label>
                  </div>
                </div>
              </div>
              {/*<div id="divSomeoneMe">
                <div className="row row-margin">
                  <label className="col-sm-4 label-text">Date Of Birth *</label>
                  <div className="col-sm-8">
                    <input
                      type="date"
                      className="input-design"
                      onChange={this.handleInputChange}
                      id="userDOB"
                      name="userDOB"
                    />
                  </div>
                </div>
              </div>*/}
              <div id="divSomeoneElse">
                <div className="row row-margin">
                  <label className="col-sm-4 label-text">First Name *</label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="input-design"
                      id="sFirstName"
                      name="sFirstName"
                      placeholder="First Name"
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>

                <div className="row row-margin">
                  <label className="col-sm-4 label-text">Last Name *</label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="input-design"
                      placeholder="Last Name"
                      name="sLastName"
                      id="sLastName"
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>

                <div className="row row-margin">
                  <label className="col-sm-4 label-text">Date Of Birth *</label>
                  <div className="col-sm-8">
                    <input
                      type="date"
                      name="sDOB"
                      id="sDOB"
                      className="input-design"
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>

                <div className="row row-margin">
                  <label className="col-sm-4 label-text">Gender *</label>
                  <div className="col-sm-8">
                    <select
                      className="input-design input-box-w-150"
                      name="sGender"
                      id="sGender"
                      onChange={this.handleInputChange}
                    >
                      <option>Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 text-right">
                {/*<a href="../patient/bookappointmentsuccess">*/}
                <input
                  type="submit"
                  value="Send Request"
                  className="formButton"
                  id="bookAppButton"
                  onClick={this.alertShow}

                  // {this.state.clicked ? <NewComponent /> : null}
                ></input>
              </div>
            </div>
          </div>
        </div>

        <Footer></Footer>
      </div>
    );
  }
}
