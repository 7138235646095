import React, { Component } from 'react';
import Header from "../layout/header"
import Footer from "../layout/footer"
import $ from "jquery";

export default class email extends Component {
  componentDidMount(){
    $("#divSomeoneElse").hide();
    $("#file1,#remfile1,#file2,#remfile2,#file3,#remfile3").hide();
    $("#inlineRadioSomeone").click(function()		
    {		 
		  $("#divSomeoneElse").show();
    });
    $("#attach").click(function(){
	
      if($("#file1").is(':hidden'))
      {
      $("#file1").show();
      $("#file1").click();
      $("#remfile1").show();
      }
      else if($("#file2").is(':hidden'))
      {
      $("#file2").show();
      $("#file2").click();
      $("#remfile2").show();
      }
      else if($("#file3").is(':hidden'))
      {
      $("#file3").show();
      $("#file3").click();
      $("#remfile3").show();
      }
      
    });
    $("#remfile1").click(function(){	 	
  		$("#file1").val("");
  		$("#file1").hide();
  		$("#remfile1").hide();
  	});
	$("#remfile2").click(function(){
  		$("#file2").val("");
  		$("#file2").hide();
  		$("#remfile2").hide();
  	});
	$("#remfile3").click(function(){
  		$("#file3").val("");
  		$("#file3").hide();
  		$("#remfile3").hide();
  	});
  }
  render() {
    return (
      <div className="purpleWrap">
        <Header></Header>
        <div className="container">
          <div className="main-wrapper">
            <div className="doctorDetail">
              <h3 className="doctor-name">Dr.  Video schedule doc MBBS</h3>
              <p className="doctoAddress">Ortho</p>
              <p className="doctoAddress">Trivandrum  - 695582 </p>
              <p className="doctoAddress">Kerala, India</p>
            </div>
            <div className="form-section">
              <div className="row row-margin">
                <label className="col-4 label-text">Name</label>
                <label className="col-8 ">Name</label>
              </div>

              <div className="row row-margin">
                <label className="col-sm-4 label-text">Asking Question For?</label>
                <div className="col-sm-8">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" id="inlineRadiome" />
                    <label className="form-check-label" for="inlineRadiome">Me</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" id="inlineRadioSomeone" value="option2" />
                    <label className="form-check-label" for="inlineRadioSomeone">Someone Else</label>
                  </div>
                </div>
              </div>
              <div id="divSomeoneElse">
                <div className="row row-margin">
                  <label className="col-sm-4 label-text">First Name *</label>
                  <div className="col-sm-8">
                    <input type="text" className="input-design" placeholder="First Name"/>
                  </div>
                </div>

                <div className="row row-margin">
                  <label className="col-sm-4 label-text">Last Name *</label>
                  <div className="col-sm-8">
                    <input type="text" className="input-design" placeholder="Last Name"/>
                  </div>
                </div>

                <div className="row row-margin">
                  <label className="col-sm-4 label-text">Date Of Birth *</label>
                  <div className="col-sm-8">
                    <input type="date" className="input-design" />
                  </div>
                </div>

                <div className="row row-margin">
                  <label className="col-sm-4 label-text">Gender *</label>
                  <div className="col-sm-8">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" id="radioMale" />
                      <label className="form-check-label" for="inlineRadio1">Male</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" id="radioFemale" value="option2" />
                      <label className="form-check-label" for="inlineRadio2">Female</label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row row-margin">
                <label className="col-sm-4 label-text">Question * </label>
                <div className="col-sm-8">
                  <textarea className="input-design" placeholder="Type Your Question" rows="3"></textarea>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-3">
                  <div className="fileUpload">
                    <span>Add Medical Records</span>
                    <input type="button" value="Add Medical Records" id="attach" name="attach" className="upload" />
                  </div>
                </div>
                <div className="col-sm-9">
                <p className="note">(Max. 3 files. format: JPEG, PDF, GIF,JPG,PNG,File Size: 10MB maximum.)</p>

                </div>
              
							</div>
                <div className="fileAttachment">
                  <div className="fileAttachmentList">
                  <input type="file" id="file1" className="attchedFile" />
                  <input type="button" id="remfile1" className="attchedDelete"/>
                  </div>
                  <div className="fileAttachmentList">
                  <input type="file" id="file2" className="attchedFile" />
                  <input type="button" id="remfile2" className="attchedDelete"/>
                  </div>
                  <div className="fileAttachmentList">
                  <input type="file" id="file3" className="attchedFile" />
                  <input type="button" id="remfile3" className="attchedDelete"/>
                  </div>
                </div>

                <div className="row row-margin">
                  <div className="col-sm-12">
                    <input  type="checkbox" id="invalidCheck" />
                    <label className="label-text ml-3" for="invalidCheck">
                      I agree with <a href="#" className="link-color">Terms and Conditions</a>
                    </label>
                  </div>
                </div>

              <div className="col-sm-12 text-right">
                <a href="../patient/emailconfirm">
                  <input type="submit" value="Submit" className="formButton"></input>
                </a>
                <input type="button" value="Cancel" className="formButton cancelButton"></input>
              </div>


            </div>
          </div>
        </div>

        <Footer></Footer>
      </div>
    )
  }
}
