import React, { useState, useEffect } from "react";
import Closebtn from "../../image/icons/close-sm.png";
import AddImg from "../../image/icons/addbtn.png";
import $ from "jquery";

const Achievementsdetails =React.forwardRef((props, ref) => {
  const [achievementTypeData, setAchievementTypeData] = useState([]);
  const [achievementDetailsArray, setAchievementDetailsArray] = useState([]);

  useEffect(() => {
    setAchievementTypeData(props.getDropdownLists.achievementTypeData);

    if (props.achievementsData.length > 0) {
      setAchievementDetailsArray(props.achievementsData);
    } else {
      setAchievementDetailsArray([]);
    }
  }, [props.achievementsData, props.getDropdownLists.achievementTypeData]);

  const addAchievementsDetails = () => {
    let flag = false;
    for (let i = 0; i <= achievementDetailsArray.length; i++) {
      if ($("#cmbAchievements" + i).val() === "0") {
        alert("Achievements Required");
        $("#cmbAchievements" + i).focus();
        flag = true;
        break;
      }
    }
    if (!flag) {
      const newAchievement = {
        Id: $("#cmbAchievements").val(),
        description: $("#txtDescription").val(),
      };
      setAchievementDetailsArray([...achievementDetailsArray, newAchievement]);
    }
    $("#cmbAchievements").val("0");
    $("#txtDescription").val("");
  };

  const setAchievementData = (value, name, i, mode) => {
    const updatedAchievements = [...achievementDetailsArray];
    if (mode === "cmbAchievements") {
      updatedAchievements[i].Id = $("#" + mode + i).val();
    } else if (mode === "txtDescription") {
      updatedAchievements[i].description = $("#" + mode + i).val();
    }
    setAchievementDetailsArray(updatedAchievements);
  };

  const removeAchievementDetails = (Id) => {
    const updatedAchievements = achievementDetailsArray.filter((e) => e.Id !== Id);
    setAchievementDetailsArray(updatedAchievements);
  };

  const enableAchievement = () => {
    if ($("#cmbAchievements").val() !== "0") {
      $("#txtDescription").prop("disabled", false);
    } else {
      $("#txtDescription").prop("disabled", true);
    }
  };
  React.useImperativeHandle(ref, () => ({
    achievements: () => {
      return {
        AchievementArray: achievementDetailsArray
      };
    }
  }));
  return (
    <div className="bg-clr-wthHead mb-4">
      <section>
        <div className="consultngTime-wrapper">
          <h2 className="hspInfoSubhead">Achievements Details</h2>
          <div className="hsptaltimeHead">
            <div className="educationdoctr-contentdiv">
              <label className="educationdoctrlabelwidth">Achievements</label>
              <select
                name="cmbAchievements"
                id="cmbAchievements"
                className="hspInfofieldinput eductninputwidthdctr"
                onChange={enableAchievement}
              >
                <option value="0">Select</option>
                {achievementTypeData.map((eduObj) => (
                  <option key={eduObj.achievementTypeId} value={eduObj.achievementTypeId}>
                    {eduObj.achievementTypeName}
                  </option>
                ))}
              </select>
            </div>
            <div className="hsptimefieldDiv pl-2 hsptimefieldDivMob-time">
              <label className="educationlabelwidthdctr">Description</label>
              <input
                type="text"
                id="txtDescription"
                name="txtDescription"
                className="hspInfofieldinput eductninputwidthdctr"
                placeholder="Description"
                disabled
              />
            </div>
            <div className="addimgeDivHsp">
              <img src={AddImg} alt="Add" onClick={addAchievementsDetails} />
            </div>
          </div>
          {achievementDetailsArray.map((Obj, i) => (
            <div key={Obj.Id} className="hsptaltimeHead">
              <div className="educationdoctr-contentdiv">
                <label className="eductninputwidthdctr">Achievements</label>
                <select
                  name={"cmbAchievements" + i}
                  id={"cmbAchievements" + i}
                  value={Obj.Id}
                  className="hspInfofieldinput eductninputwidthdctr"
                  onChange={(e) => setAchievementData(e.target.value, "cmbAchievements" + i, i, "cmbAchievements")}
                >
                  <option value="0">Select</option>
                  {achievementTypeData.map((eduObj) => (
                    <option key={eduObj.achievementTypeId} value={eduObj.achievementTypeId}>
                      {eduObj.achievementTypeName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="hsptimefieldDiv pl-2 hsptimefieldDivMob-time">
                <label className="educationlabelwidthdctr">Description</label>
                <input
                  type="text"
                  id={"txtDescription" + i}
                  name={"txtDescription" + i}
                  value={Obj.description}
                  className="hspInfofieldinput eductninputwidthdctr"
                  placeholder="Description"
                  onChange={(e) => setAchievementData(e.target.value, "txtDescription" + i, i, "txtDescription")}
                />
              </div>
              <div className="addimgeDivHsp">
                <img src={Closebtn} alt="Close" onClick={() => removeAchievementDetails(Obj.Id)} width="20" />
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}
)

export default Achievementsdetails;
