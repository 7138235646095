import React, { Component } from 'react';
import Header from "../layout/header"
import Footer from "../layout/footer"
import edit from "../image/media/edit.png"
import publish from "../image/media/draft.png"
import draft from "../image/media/publish.png"
import Popup from "reactjs-popup";

export default class ChatHistory extends Component {
 
  render() {
    return ( 
      <div className="purpleWrap">
        <Header></Header>
        <div className="container container-auto">
          <div className="main-wrapper1">
                    
      
                    <div className="col-md-12 p-0">
                      <ul className="breadcrumb">
                        <li><a href="/doctor/dashboard">Dashboard</a><i className="fa fa-chevron-right"></i></li>
                        <li>Video chat history</li>
                      </ul>
                    </div>
                    
                    <h5 className="color-maintext"> Video chat history </h5><br></br>
                    <div className="col-sm-12 search-main">
                      <div className="row">
                        <div className="col-md-3 col-lg-3 my-2">
                            <input type="date" className="input-design" disabled="" />
                        </div> 
                        <div className="col-md-3 col-lg-3 my-2">
                            <input type="date" className="input-design" disabled="" />
                        </div> 
                        <div className="col-md-2 col-lg-2 my-2">
                          <input type="submit" value="Search" className="btn-bg w-100"/>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead className="form-section-bgcolor">
                        <tr>     
                          <th>Video Chat Date</th>
                          <th>Patient</th>
                          <th>Video start time</th>
                          <th>Video end time</th>
                          <th>Billing start time</th>
                          <th>Billing end time</th>
                          <th>Billed Time (minutes)</th>
                          <th>Is Session Based</th>
                          <th>Charge/Minute</th>
                          <th>Charge/Session</th>
                          <th>Billed Amount</th>
                        </tr>
                        </thead>
                        <tbody style={{fontSize:"12px"}}>
                          <tr>
                            <td>03/07/2020</td>
                            <td className="whitespace-nowrap">SMS PATIENT	</td>
                            <td>2:33:45 PM	</td>
                            <td>2:33:45 PM	</td>
                            <td> - </td>
                            <td> - </td>
                            <td> - </td>
                            <td> Yes </td>
                            <td>  </td>
                            <td className="whitespace-nowrap">  ₹0.00 </td>
                            <td className="whitespace-nowrap">  ₹0.00 </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

           </div>
        </div>

        <Footer></Footer>
      </div>
    )
  }
}
