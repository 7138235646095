import React, { useState, useEffect, useRef } from "react";

const DoctorSpecialisation = React.forwardRef((props, ref) => {
  const [specialization, setSpecialization] = useState([]);
  const [medicineBranchData, setMedicineBranchData] = useState([]);
  const [medicineBranchId, setMedicineBranchId] = useState("");
  const [professionalStatement, setProfessionalStatement] = useState("");
  const [checkedSpecializations, setCheckedSpecializations] = useState([]);
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };    
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      setMedicineBranchData(props.getDropdownLists.medicineBranchData || []);
    }
  }, [props.getDropdownLists.medicineBranchData]);

  useEffect(() => {
    if (isMounted.current) {
      if (props.doctorData.length > 0) {
        const doctorData = props.doctorData[0];
        setMedicineBranchId(doctorData.medicineBranchId || "");
        setProfessionalStatement(doctorData.professionalStatement || "");
        if (doctorData.medicineBranchId) {
          getSpecialisation(doctorData.medicineBranchId, props.specialisationEdit);
        }
      } else {
        setMedicineBranchId("");
        setProfessionalStatement("");
      }
    }
  }, [props.doctorData, props.specialisationEdit]);
  const getSpecialisation = (branchId, specialisationEdit) => {
    if (!branchId) return;
    const specilisationArray = medicineBranchData?.find(
      (o) => o.medicineBranchId.toString() === branchId.toString()
    );
    if (specilisationArray) {
      const specializationData = specilisationArray.specializationData || [];
      setSpecialization(specializationData);

      const initialChecked = specializationData?.map((speciality) => {
        const edit = specialisationEdit&&specialisationEdit.find((edit) => edit.Id === speciality.specialityId);
        return {
          specializationIds: speciality.specialityId,
          isPrimarySpecialisation: edit?.isPrimarySpecialization || false,
          checked: !!edit,
        };
      });
      setCheckedSpecializations(initialChecked);
    }
  };

  const handleBranchChange = (event) => {
    const branchId = event.target.value;
    setMedicineBranchId(branchId);
    getSpecialisation(branchId, []); // Pass an empty array for specialisationEdit to avoid recursion with edits
  };

  const handleSpecializationChange = (specialityId) => {
    setCheckedSpecializations((prev) =>
      prev.map((spec) =>
        spec.specializationIds === specialityId
          ? { ...spec, checked: !spec.checked, isPrimarySpecialisation: spec.checked ? false : spec.isPrimarySpecialisation }
          : spec
      )
    );
  };

  const handlePrimaryChange = (specialityId) => {
    setCheckedSpecializations((prev) =>
      prev.map((spec) =>
        spec.specializationIds === specialityId
          ? { ...spec, isPrimarySpecialisation: !spec.isPrimarySpecialisation, checked: !spec.isPrimarySpecialisation || spec.checked }
          : { ...spec, isPrimarySpecialisation: false }
      )
    );
  };

  const handleStatementChange = (event) => {
    const statement = event.target.value;
    setProfessionalStatement(statement);
    props.proffesionalStatementSetting(statement);
  };

  React.useImperativeHandle(ref, () => ({
    specialisations: () => {
      return {
        SpecialisationArray: checkedSpecializations
          .filter((spec) => spec.checked)
          .map((spec) => ({
            "specializationIds": spec.specializationIds,
            "isPrimarySpecialisation": spec.isPrimarySpecialisation.toString(),
          })),
      };
    },
  }));

  return (
    <div className="bg-clr-wthHead">
      <section>
        <div className="container">
          <div className="superadminDivBoxcover pt-0">
            <div className="hspinfo-contentdiv">
              <label>
                Medicine branch<span className="hspifoMantory">*</span>
              </label>
              <select
                name="cmbMedicineBranch"
                id="cmbMedicineBranch"
                className="hspInfofieldinput Infoinputwidthdoctr"
                value={medicineBranchId}
                onChange={handleBranchChange}
              >
                <option value="">Select</option>
                {medicineBranchData.map((branchObj) => (
                  <option key={branchObj.medicineBranchId} value={branchObj.medicineBranchId}>
                    {branchObj.medicineBranchName}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="specializationWraper pb-5 pt-5">
            <h2 className="hspInfoSubhead pb-3">
              Specialisation <span>(Choose at least one Primary Specialisation)*</span>
            </h2>
            {specialization.length > 0 && (
              <div className="hspspecializationDiv">
                <div className="table-bordered-hspSpecl">
                  <table className="table table-bordered-hspSpecl mainBx table-specializtin-admin">
                    <tbody>
                      {specialization.map((speObj) => (
                        <tr key={speObj.specialityId} className="admindctr-specialzation-tr">
                          <td className="admindctr-specialzation">
                            <input
                              type="checkbox"
                              id={"chkSpecialisation" + speObj.specialityId}
                              checked={
                                checkedSpecializations.find(
                                  (spec) => spec.specializationIds === speObj.specialityId
                                )?.checked || false
                              }
                              onChange={() => handleSpecializationChange(speObj.specialityId)}
                            />
                            <div className="tdtxtLabel-hsp">{speObj.speciality}</div>
                          </td>
                          <td className="admindctr-specialzation">
                            <input
                              type="checkbox"
                              id={"chkPrimarySpecialisation" + speObj.specialityId}
                              checked={
                                checkedSpecializations.find(
                                  (spec) => spec.specializationIds === speObj.specialityId
                                )?.isPrimarySpecialisation || false
                              }
                              onChange={() => handlePrimaryChange(speObj.specialityId)}
                            />
                            <div className="tdtxtLabel-hsp">Primary Specialization</div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            <div className="hsplistTextareaWrapper">
              <div className="mt-4">
                <label className="label-hspservice">
                  Professional Statement<span className="hspifoMantory">*</span>
                </label>
                <div>
                  <textarea
                    name="txtProfessionalStatement"
                    id="txtProfessionalStatement"
                    cols="30"
                    rows="10"
                    placeholder="Professional Statement"
                    className="textarea-hspservice"
                    value={professionalStatement}
                    onChange={handleStatementChange}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
});

export default DoctorSpecialisation;
