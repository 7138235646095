import React, { Component } from "react";
import { apiCalling, Captcha, signupValidation } from "../apiService";
import $ from "jquery";
import { KJUR, b64utoutf8 } from 'jsrsasign';
export default class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  generateToken = (payload) => {
    return new Promise((resolve, reject) => {
        try {
            const header = { alg: 'HS256', typ: 'JWT' };
            const sHeader = JSON.stringify(header);
            const sPayload = JSON.stringify(payload);
            const hmacKey = { utf8: process.env.REACT_APP_JWT_SECRET };
            const token = KJUR.jws.JWS.sign('HS256', sHeader, sPayload, hmacKey);
            resolve(token);
        } catch (error) {
            reject(error);
        }
    });
};
  componentDidMount() {
    $("#signupOTP").hide();
    var newCaptcha = Captcha();
    $("#mainCaptcha").text(newCaptcha);
    $("#hiddenCaptcha").val(newCaptcha);

    setInterval(function () {
      var newCaptcha = Captcha();
      $("#mainCaptcha").text(newCaptcha);
      $("#hiddenCaptcha").val(newCaptcha);
    }, 60000);

    $("#refreshCapcha").click(function () {
      var newCaptcha = Captcha();
      $("#mainCaptcha").text(newCaptcha);
      $("#hiddenCaptcha").val(newCaptcha);
    });

    $("#registerId").click(function () {
      var textCaptcha = $("#textCaptcha").val();
      var hiddenCaptcha = $("#hiddenCaptcha").val();
      var firstName = $("#firstName").val();
      var lastName = $("#lastName").val();
      var emailId = $("#emailId").val();
      var mobileNo = $("#mobileNo").val();
      var password = $("#password").val();
      var cpassword = $("#cpassword").val();
      var siteId = "";
      let validate = signupValidation(
        firstName,
        lastName,
        emailId,
        mobileNo,
        password,
        cpassword,
        textCaptcha,
        hiddenCaptcha
      );
      alert(validate);
      $("#" + validate[1]).focus();
      $("#errorMessage").text(validate[0]);
      if (!validate[0]) {
        this.generateToken({
          firstName: firstName,
          lastName: lastName,
          mobileNumber: mobileNo,
          emailId: emailId,
          password: password,
      }).then(token => {
  //       const token =this.generateToken({
  //         firstName: firstName,
  //         lastName: lastName,
  //         mobileNumber: mobileNo,
  //         emailId: emailId,
  //         password: password,
  //       },
  // process.env.jwtSecret)
        
        // jwt.sign(
        //   {
        //     firstName: firstName,
        //     lastName: lastName,
        //     mobileNumber: mobileNo,
        //     emailId: emailId,
        //     password: password,
        //   },
        //   jwtSecret()
        // );
        const requestData = {
          method: "POST",
          body: JSON.stringify({
            functionName: "signUp",
            token: token,
            siteId: localStorage.getItem("siteId"),
            otp: "",
          }),
        };

        apiCalling(requestData).then((data) => {
          if (data.success == "1") {
            $("#signupform").hide();
            $("#signupOTP").show();
            $("#errorMessageOtp").text(data.successMessage);
          } else {
            $("#signupform").show();
            $("#signupOTP").hide();
            $("#errorMessage").text(data.errorMessage);
          }
        });
      }).catch(error => {
        console.error("Token Generation Error:", error);
    });
      } //if validate
    });
    

    $("#signUpId").click(function () {
      var firstName = $("#firstName").val();
      var lastName = $("#lastName").val();
      var emailId = $("#emailId").val();
      var mobileNo = $("#mobileNo").val();
      var password = $("#password").val();
      var signupOTP = $("#signupOTPTxt").val();
      alert(signupOTP);
      if (!signupOTP) {
        alert("if");
      } else {
        alert("else");
  //       const token = this.generateToken({
  //         firstName: firstName,
  //           lastName: lastName,
  //           mobileNumber: mobileNo,
  //           emailId: emailId,
  //           password: password,
  //       },
  // process.env.jwtSecret)
  this.generateToken({
    firstName: firstName,
            lastName: lastName,
            mobileNumber: mobileNo,
            emailId: emailId,
            password: password,
}).then(token => {
        
        // jwt.sign(
        //   {
        //     firstName: firstName,
        //     lastName: lastName,
        //     mobileNumber: mobileNo,
        //     emailId: emailId,
        //     password: password,
        //   },
        //   jwtSecret()
        // );
        const requestData = {
          method: "POST",
          body: JSON.stringify({
            functionName: "signUp",
            token: token,
            siteId: localStorage.getItem("siteId"),
            otp: signupOTP,
          }),
        };
        //$("#errorMessageOtp").text(requestData.body);
        apiCalling(requestData).then((data) => {
          if (data.success == "1") {
            $("#errorMessageOtp").text(data.successMessage);
          } else {
            $("#errorMessageOtp").text(data.errorMessage);
          }
        });
      }).catch(error => {
        console.error("Token Generation Error:", error);
    });
      }
    });
  }
  render() {
    return (
      <div className="signInWrap" id="signUpWrap">
        <form id="signupform">
          <div className="signInHead">
            <h2>Get Started!</h2>
            <p>Create your USER ID!</p>
            <p id="errorMessage"></p>
          </div>
          <div className="signInBody">
            <input
              type="text"
              id="firstName"
              name="firstName"
              className="input-design my-2"
              placeholder="First Name"
              required
            />
            <input
              type="text"
              id="lastName"
              name="lastName"
              className="input-design my-2"
              placeholder="Last Name"
              required
            />
            <input
              type="email"
              id="emailId"
              className="input-design my-2"
              placeholder="Email Id"
              required
            />
            <input
              type="text"
              className="input-design my-2"
              placeholder="Mobile No."
              name="mobileNo"
              id="mobileNo"
              required
            />
            <input
              type="password"
              className="input-design my-2"
              placeholder="Password"
              name="password"
              id="password"
              required
            />
            <input
              type="password"
              className="input-design my-2"
              placeholder="Confirm password"
              name="cpassword"
              id="cpassword"
              required
            />
            <div className="row my-2">
              <div className="col-md-7">
                <input
                  type="text"
                  placeholder="Type Captcha"
                  className="input-design"
                  id="textCaptcha"
                  name="textCaptcha"
                />
              </div>
              <div className="col-md-5 text-left mt-1">
                <span className="captchaOverText" id="mainCaptcha"></span>
                <input type="hidden" id="hiddenCaptcha" value="" />
                <i className="fa fa-refresh ml-3" id="refreshCapcha"></i>
              </div>
            </div>
            <div className="signInButton mt-2">
              <input
                type="button"
                value="Register"
                className="formButtonBg"
                id="registerId"
              />
            </div>
            <div className="signUp">
              <h3>
                Already have an account? <a id="signIn">Sign in!</a>
              </h3>
            </div>
          </div>
        </form>
        <form id="signupOTP">
          <div className="signInHead">
            <h2>Get Started!</h2>
            <p>Create your USER ID!</p>
            <p id="errorMessageOtp"></p>
          </div>
          <div className="signInBody">
            <input
              type="number"
              id="signupOTPTxt"
              name="signupOTPTxt"
              className="input-design my-2"
              placeholder="Enter Your OTP "
              required
            />
            <div className="signInButton mt-2">
              <input
                type="button"
                value="SignUp"
                className="formButtonBg"
                id="signUpId"
              />
            </div>
            <div className="signUp">
              <h3>
                Already have an account? <a id="signIn">Sign in!</a>
              </h3>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
