import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import $ from "jquery";
import Slider from "react-slick";
import { awsAPIUrlcommon, apiCalling } from "../apiService";

const Network = () => {
  const { splashId } = useParams();
  const [mobileNumber, setMobileNumber] = useState("");
  const [appflag, setAppflag] = useState("");
  const [nwId, setNwId] = useState("");
  const [minifiedUrl, setMinifiedUrl] = useState("");
  const [minifiedUrlSm, setMinifiedUrlSm] = useState("");
  const [encodedUrl, setEncodedUrl] = useState("");
  const [urlExpired, setUrlExpired] = useState(false);

  useEffect(() => {
    getMinifiedUrl();
  }, []);

  const getMinifiedUrl = async () => {
    const apiReq = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getSiteUrlByMinifiedId",
        minifiedId: splashId,
      }),
    };

    awsAPIUrlcommon(apiReq).then((data) => {
      if (data.success !== "0" && data.siteUrl) {
        try {
          debugger;
          let siteUrl = data.siteUrl;
          const url = new URL(siteUrl);
          const urlParams = new URLSearchParams(url.search);
          const appflag = urlParams.get("appflag");
          const lastSlashIndex = siteUrl.lastIndexOf("/");
          const dataAfterLastSlash = siteUrl.substring(lastSlashIndex + 1);
          const cleanedUrl = siteUrl.substring(0, lastSlashIndex + 1);
          const encodedUrl = encodeURIComponent(dataAfterLastSlash);

          setMinifiedUrlSm(cleanedUrl + encodedUrl);
          setMinifiedUrl(siteUrl);
          setAppflag(appflag);
          let nwIdTemp = urlParams.get("nwId")
          setNwId(nwIdTemp);

          expiryCheck(nwIdTemp);
        } catch (error) {
          console.error("Invalid URL: ", error);
        }
      } else {
        console.error("Failed to get valid site URL from response.");
      }
    }).catch(error => {
      console.error("API call failed: ", error);
    });
  };


  const expiryCheck = (nwId) => {
    const apiReq = {
      method: "POST",
      body: JSON.stringify({
        networkId: nwId,
        functionName: "networkUrlExipiredChecking",
      }),
    };
    apiCalling(apiReq).then((data) => {
      setUrlExpired(data.urlExpired);
    });
  };

  const acceptClick = () => {
    if (/^\d+$/.test(mobileNumber) && mobileNumber.length === 10) {
      const apiReq = {
        method: "POST",
        body: JSON.stringify({
          functionName: "saveNetworkMobile",
          mobileNumber: mobileNumber,
          networkId: nwId,
        }),
      };
      apiCalling(apiReq).then((data) => {
        if (data.success === "1") {
          window.open(minifiedUrlSm, "_self");
        }
      });
    } else if (mobileNumber === "") {
      $("#mobileNumber").focus();
      alert("Please Enter Mobile Number");
    } else {
      $("#mobileNumber").focus();
      alert("Invalid Mobile Number");
    }
  };

  const settings = {
    dots: true,
    infinite: false,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 13000,
    pauseOnHover: false,
  };

  return (
    <div className="pharmacyapp-wrapper p-0">
      <Slider {...settings}>
        <div className="splash-banner splash-banner5">
          <div className="sp-banner-img-div">
            <img
              src="https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/phImageFolder_v1/pharmacyApp/banner.png"
              alt="banner"
              className="img-fluid sp-banner-img"
              height="150"
            />
          </div>
          <div className="sp-banner-content">
            <div className="pharmacyapp-splashhead">
              You have a networking request!
            </div>
            <p className="sp-banner-p">
              Download the PurpleHealth Practitioner App and you can
              <span className="sp-banner-p-color"> send and receive patient referrals</span> within your network!
            </p>
            <span className="feature-apphead">
              This App also has these great features :
            </span>
            <ul className="sp-banner-content">
              <li className="sp-banner-content-p imagesplash image1splash">
                Refer Patients to doctors and clinics!
              </li>
              <li className="sp-banner-content-p imagesplash image2splash">
                Maintain patient health records
              </li>
              <li className="sp-banner-content-p imagesplash image3splash">
                Manage your appointment calendar
              </li>
              <li className="sp-banner-content-p imagesplash image4splash">
                Do Telehealth consultations
              </li>
              <li className="sp-banner-content-p imagesplash image5splash">
                Manage Prescriptions
              </li>
            </ul>
            <span className="best_of">
              Best of all, this app is <span className="splashBlue">FREE!</span>
            </span>
            {urlExpired ? (
              <div class="alert alert-danger alert-msgg" role="alert">
                This link has either been used already or has expired.
              </div>
            ) : (
              <>
                <input
                  type="text"
                  className="pharmacyapp-textField mt-3"
                  placeholder="Enter Mobile Number"
                  id="mobileNumber"
                  value={mobileNumber}
                  onChange={(e) => {
                    setMobileNumber(e.target.value);
                  }}
                />
                <div className="pharmacy-splash-btnmain mt-1">
                  <div
                    className="sp-notNow-div"
                    onClick={() => acceptClick()}
                  >
                    <a className="sp-accept mr-2">Accept</a>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default Network;
