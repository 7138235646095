import React, { Component } from 'react';

export default class SpecialityDetail extends Component {
 
  render() {
    return (
        <div>
          <div className="col-sm-12 p-0 mb-3">
              <h6>Medicine Branch : Doctors</h6>
          </div>
          <div className="row px-2 scrollDiv">
            <div className="col-sm-6 mb-2">
                <input type="checkbox" className="mr-2" /> Abdominal Transplant
            </div>
            <div className="col-sm-6 mb-2">
                <input type="checkbox" className="mr-2" /> Acne
            </div>
            <div className="col-sm-6 mb-2">
                <input type="checkbox" className="mr-2" /> Acupuncture
            </div>
            <div className="col-sm-6 mb-2">
                <input type="checkbox" className="mr-2" /> Acupuncturist
            </div>
            <div className="col-sm-6 mb-2">
                <input type="checkbox" className="mr-2" /> Addictive Medicine
            </div>
            <div className="col-sm-6 mb-2">
                <input type="checkbox" className="mr-2" /> Adolescent Medicine
            </div>
            <div className="col-sm-6 mb-2">
                <input type="checkbox" className="mr-2" /> Aesthetic Surgeon
            </div>
            <div className="col-sm-6 mb-2">
                <input type="checkbox" className="mr-2" /> Allergy Specialist
            </div>
            <div className="col-sm-6 mb-2">
                <input type="checkbox" className="mr-2" /> Alopecia
            </div>
            <div className="col-sm-6 mb-2">
                <input type="checkbox" className="mr-2" /> Anatomy
            </div>
            <div className="col-sm-6 mb-2">
                <input type="checkbox" className="mr-2" /> Andrologist
            </div>
            <div className="col-sm-6 mb-2">
                <input type="checkbox" className="mr-2" /> Anesthesiologist
            </div>
            <div className="col-sm-6 mb-2">
                <input type="checkbox" className="mr-2" /> Areata
            </div>
            <div className="col-sm-6 mb-2">
                <input type="checkbox" className="mr-2" /> Arthroscopy
            </div>
            <div className="col-sm-6 mb-2">
                <input type="checkbox" className="mr-2" /> Asthma
            </div>
            <div className="col-sm-6 mb-2">
                <input type="checkbox" className="mr-2" /> Audiologists
            </div>
            <div className="col-sm-6 mb-2">
                <input type="checkbox" className="mr-2" /> Bariatric (Obesity)
            </div>
            <div className="col-sm-6 mb-2"> 
                <input type="checkbox" className="mr-2" /> Biochemist
            </div>
            <div className="col-sm-6 mb-2">
                <input type="checkbox" className="mr-2" /> Blood Bank
            </div>
          </div>
          <div className="col-sm-12">
              <input type="submit" value="Save" className="formButton"></input>
          </div>
        </div>
    )
  }
}
