import React, { Component, Suspense } from "react";

export default class LinkHsp extends Component {
  render() {
    return (
      <div className="purpleWrap bg-clr-wthHead mb-4">
        <Suspense fallback={<div></div>}>
          <section>
            <div className="linkHspWrapper">
              <div className="linkgeneratedivOuter">
                <div className="hspinfo-contentdiv hsplink-field">
                  <label>Button Colour</label>
                  <input type="text" className="hspInfofieldinput" />
                </div>
                <div className="hspinfo-contentdiv hsplink-field">
                  <label>Background Colour</label>
                  <input type="text" className="hspInfofieldinput" />
                </div>

                <div className="hspinfo-contentdiv hsplink-field">
                  <label>Foreground Colour</label>
                  <input type="text" className="hspInfofieldinput" />
                </div>
                <div className="hspinfo-contentdiv hsplink-field">
                  <label>Font Colour</label>
                  <input type="text" className="hspInfofieldinput" />
                </div>

                <div className="linkmobhspsubDiv">
                  <hr />
                  <div className="hspinfo-contentdiv hsplink-field">
                    <label>Height</label>
                    <input
                      type="text"
                      className="hspInfofieldinput"
                      placeholder="Height"
                    />
                  </div>
                  <div className="hspinfo-contentdiv hsplink-field">
                    <label>Width</label>
                    <input
                      type="text"
                      className="hspInfofieldinput"
                      placeholder="Width"
                    />
                  </div>
                </div>

                <div className="linkhspGenerateBtn-div">
                  <button className="linkhspGenerateBtn">
                    Generate Iframe Code
                  </button>
                </div>
                <div>
                  <input type="text" className="linkhspgenerteInput" />
                </div>
                <div className="linkhspGenerateBtn-div">
                  <button className="linkhspGenerateBtn">Test me</button>
                </div>
              </div>

              <div className="linkgeneratedivOuter">
                <div className="linkmobhspsubDiv">
                  <div className="hspinfo-contentdiv hsplink-field">
                    <label>Height</label>
                    <input
                      type="text"
                      className="hspInfofieldinput"
                      placeholder="Height"
                    />
                  </div>
                  <div className="hspinfo-contentdiv hsplink-field">
                    <label>Width</label>
                    <input
                      type="text"
                      className="hspInfofieldinput"
                      placeholder="Width"
                    />
                  </div>
                </div>

                <div className="linkhspGenerateBtn-div">
                  <button className="linkhspGenerateBtn">
                    Generate Open Window Code
                  </button>
                </div>
                <div>
                  <input type="text" className="linkhspgenerteInput" />
                </div>
                <div className="linkhspGenerateBtn-div">
                  <button className="linkhspGenerateBtn">Test me</button>
                </div>
              </div>
            </div>
          </section>
        </Suspense>
        <Suspense fallback={<div></div>}></Suspense>
      </div>
    );
  }
}
