import React, { Component } from 'react';

export default class HospitalDetails extends Component {
 
  render() {
    return ( 
      <div className="row px-2">
        <div className="col-sm-12  px-0  px-sm-3">
        <div className="table-responsive">
          <table className="table" id="tblLocation">
            <thead>
                <tr>           
                  <th className="w-25">Country</th>
                  <th className="w-25"> State</th>
                  <th className="w-50"> Hospital</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                  <td className="w-25">
                    <input disabled="disabled" placeholder="Country" type="text" className="input-design" />
                  </td>
                  <td className="w-25">
                      <input disabled="disabled" placeholder="State" type="text" className="input-design" />
                  </td>
                  <td className="w-50">
                      <input disabled="disabled" type="text" className="input-design" />
                  </td>
                </tr>
                
            </tbody>
          </table>
      </div>
        </div>
      </div>
    )
  }
}
