import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Header from "../layout/header"
import Footer from "../layout/footer"
import AboutTakeNextStep from './about-take-next-step';
import blog1 from '../image/theme/news/img3.png';
import blog2 from '../image/theme/news/img2.png';
import Fb from '../image/theme/fbico.png';
import Insta from '../image/theme/instaico.png';
import Linkdn from '../image/theme/linkdnico.png';
import Bloglistblocks from "./blog-list-blocks"
function BlogDetails() {
    const blogsIdNo = window.localStorage.getItem("blogid");
    const [blogDetailArray1, setBlogDetailArray1] = useState([
        {
            id: "blog1",
            authorname: "Dr. Nagarjun Mishra",
            date: "Monday, April 22, 2024, 03.45pm  [IST]",
            heading: "Transforming Pharma - Doctor Engagement: Data-Driven Closed Loop Marketing Strategies",
            desc: "<p class='card-text' style='opacity:1;'>In today's rapidly evolving healthcare landscape, effective communication and collaboration between pharmaceutical companies and doctors are essential. Traditional approaches often lack personalization and fail to deliver meaningful insights. However, with the emergence of innovative technologies, a new era of pharma-doctor engagement has dawned. This article explores the transformative power of closed-loop marketing and data-driven strategies in revolutionizing this relationship, fostering enhanced collaboration, and improving patient outcomes.</p>" +
                "<h3 class='blog-sub-head blog-inner-head'>The Evolution of Pharma-Doctor Engagement:</h3> <p class='card-text text-'>Traditional marketing methods no longer suffice in a world where personalization is key. Closed-loop marketing (CLM) has emerged as a pivotal tool in driving engagement. Unlike traditional approaches, CLM focuses on gathering real-time feedback from doctors, analyzing data insights, and refining strategies accordingly. By closing the loop between marketing initiatives and doctor responses, pharmaceutical companies can deliver more relevant and personalized content which is relevant and impactful.</p>" +
                "<h3 class='blog-sub-head blog-inner-head'>Unlocking the Potential of Data-Driven Strategies:</h3> <p class='card-text'>Data-driven strategies have become indispensable in modern healthcare marketing. With the abundance of digital touchpoints available, pharmaceutical companies can leverage data to gain actionable insights into doctor preferences, behaviors, and prescribing patterns. Advanced analytics and predictive modeling enable companies to tailor their marketing efforts to align with doctors' needs, driving better engagement and ROI.</p>" +
                "<h3 class='blog-sub-head blog-inner-head'>The Role of Closed Loop Marketing in Pharma-Doctor Collaboration:</h3> <p className='card-text'>Closed-loop marketing provides a feedback loop that allows pharmaceutical companies to continuously refine their strategies based on real-time insights. By capturing and analyzing doctor interactions, companies can adapt their messaging, channels, and timing to better resonate with doctors. This leads to more meaningful interactions and stronger partnerships, ultimately improving the effectiveness of marketing campaigns.</p>" +
                "<h3 class='blog-sub-head blog-inner-head'>PurpleHealth's Role in Pharma-Doctor Interaction:</h3> <p class='card-text'>PurpleHealth offers innovative tools designed to facilitate seamless interaction between pharmaceutical companies and doctors. Through our platform, companies can leverage closed-loop marketing techniques to gather real-time feedback and refine their strategies accordingly. Additionally, our data-driven insights enable companies to personalize their communication efforts, delivering content that resonates with doctors' preferences and needs.</p>" +
                "<h3 class='blog-sub-head blog-inner-head'>Real-World Success Stories:</h3> <p class='card-text'>To illustrate the effectiveness of closed-loop marketing and data-driven strategies, let's explore a couple of real-world examples:</p>" +
                "<ul>" +
                "<li><div class='mr-2'>1.</div>CLM through PurpleHealth has seen a 2.5x better open rate compared to conventional digital marketing channels of email and WhatsApp.</li>" +
                "<li><div class='mr-2'>2. </div>MR to Doctor communication mapping ensures 100% continuity of communication.</li>" +
                "<li><div class='mr-2'>3.</div>37% improvement in MR efficiency with more inbound meeting requests.</li>" +
                "</ul>" +
                "<h3 class='blog-sub-head blog-inner-head'>Relevant Products PurpleHealth:</h3></p>" +
                "<ul>" +
                "<li><div class='mr-2 blog-span-head'>1.</div><div><span class='blog-span-head'>PharmaNet:</span> Share product, marketing, promotional or related communications on the News feed section of the Doctor App. Ability to run target group specific focused campaigns. Analytics for data-driven marketing strategy</div></li>" +
                "<li><div class='mr-2 blog-span-head'>2.</div><div><span class='blog-span-head'>MR Connect:</span> Map MRs to specific Doctors. MRs chat with doctors and attach media & voice messages similar to WhatsApp. Get analytics of MR – Doctor engagement</div></li>" +
                "<li><div class='mr-2 blog-span-head'>3.</div><div><span class='blog-span-head'>DocEd:</span> Education platform for doctors. Conduct webinars, CME & CPD programs, Certifications, Marketing events, Quizzes, Surveys, Contests, Brand/Product launches, etc.</div></li>" +
                "<li><div class='mr-2 blog-span-head'>4.</div><div><span class='blog-span-head'>InsightX:</span> Leverage PurpleHealth’s data analytics with automated reporting dashboards for data-driven strategies. Reports are configurable and available at a group and individual level.</div></li>" +
                
                "<li><div class='mr-2 blog-span-head blog-span-dark'>1.</div><div><span class='blog-span-head blog-span-dark'>Listing insights:</span> Analyze traffic reports, reviews feeds, keyword clouds, reviews bucketing, Google Q&A, customer sentiment trends, etc.</div></li>" +
                "<li><div class='mr-2 blog-span-head blog-span-dark'>2.</div><div><span class='blog-span-head blog-span-dark'>Website insights:</span> Source, Traffic, Page visits, Unique visits, Scroll depth, Time on site, etc.</div></li>" +
                "<li><div class='mr-2 blog-span-head blog-span-dark'>3.</div><div><span class='blog-span-head blog-span-dark'>Clinic business insights:</span> Transactions, Patient registrations, Business insights, Referrals, Consultations, etc.</div></li>" +
                "<li><div class='mr-2 blog-span-head blog-span-dark'>4.</div><div><span class='blog-span-head blog-span-dark'>Pharma Marketing insights:</span> Campaign performance, likes, shares, open rate, visits, request for interactions, etc.</div></li>" +
                "<li><div class='mr-2 blog-span-head blog-span-dark'>5.</div><div><span class='blog-span-head blog-span-dark'>MR activity insights:</span> Visits, Responses, Communications, etc.</div></li>" +
                "</ul>" +
                "</ul>",
            image: blog1
        },
        {
            id: "blog2",
            authorname: "Dr. Nagarjun Mishra",
            date: "Monday, April 22, 2024, 04.45pm  [IST]",
            heading: "Unlocking Success: The Importance of Managing Google Listings for Doctors",
            desc: "<p class='card-text'  style='opacity:1;'>In today's digital age, a doctor's online presence is as crucial as their medical expertise. With more patients turning to the internet to find healthcare providers, managing Google listings has become essential for doctors. In this article, we'll explore why it's imperative for doctors to manage their Google listings effectively. </p>" +
                "<h3 class='blog-sub-head blog-inner-head'>Shift in Consumer Behavior</h3> <p class='card-text'>Gone are the days when patients relied solely on word-of-mouth referrals to find a doctor. With the rise of the internet, patients now conduct online searches to find healthcare providers in their area. According to recent studies, over 80% of patients use search engines to find healthcare information, making it essential for doctors to have a strong online presence.</p>" +
                "<h3 class='blog-sub-head blog-inner-head'>Online Reputation Management </h3><p class='card-text'>Your Google listing serves as a digital storefront for your practice, often forming the first impression for potential patients. Managing your Google listing allows you to control the information patients see when they search for your practice online. This includes updating your contact information, hours of operation, and ensuring accuracy to enhance your practice's credibility and trustworthiness.</p>" +
                "<h3 class='blog-sub-head blog-inner-head'>Role of Reviews and Ratings</h3><p class='card-text'>Reviews and ratings on Google can significantly impact a doctor's reputation and patient acquisition. Positive reviews and high ratings can attract new patients and build trust, while negative reviews can deter potential patients from seeking your services. By actively managing and responding to reviews, doctors can demonstrate their commitment to patient satisfaction and address any concerns promptly.</p>" +
                "<h3 class='blog-sub-head blog-inner-head'>5 Ways You can optimize Your Google Business Profile: </h3>" +
                "<ul>" +
                "<li><div class='mr-2 blog-span-head blog-span-dark'>1.</div><div><span class='blog-span-head blog-span-dark'>Claim Your Listing :</span> It is critical that you claim your listing to prevent any misinformation or misuse.</div></li>" +
                "<li><div class='mr-2 blog-span-head blog-span-dark'>2.</div><div><span class='blog-span-head blog-span-dark'>Keep Information Up-to-Date :</span> Ensure that your contact information, address, and hours of operation are accurate and regularly updated on your Google Business Profile.</div></li>" +
                "<li><div class='mr-2 blog-span-head blog-span-dark'>3.</div><div><span class='blog-span-head blog-span-dark'>Engage with Reviews :</span> Respond to patient reviews, both positive and negative, in a timely and professional manner to demonstrate your commitment to patient satisfaction.</div></li>" +
                "<li><div class='mr-2 blog-span-head blog-span-dark'>4.</div><div><span class='blog-span-head blog-span-dark'>Add Photos and Videos : </span>Enhance your Google listing with high-quality photos and videos showcasing your practice, staff, and facilities to provide patients with a visual insight into your services.</div></li>" +
                "<li><div class='mr-2 blog-span-head blog-span-dark'>5.</div><div><span class='blog-span-head blog-span-dark'>Utilize Google Posts :</span> Share updates, promotions, and relevant information about your practice through Google Posts to engage with potential patients and improve visibility.</div></li>" +
                "</ul>" +
                "<p class='card-text'>It is important that you regularly review the insights provided by Google Business Profile to understand how patients find and interact with your listing, allowing you to make informed decisions to optimize your profile further.</p>" +
                "<h3 class='blog-sub-head blog-inner-head'>Conclusion</h3><p class='card-text'>In today's digital landscape, managing Google listings is not just an option but a necessity for doctors. By actively maintaining their online presence, doctors can attract new patients, build trust, and enhance their reputation in the community. Investing time and effort into managing Google listings is an essential component of a successful practice in the modern age of healthcare.In conclusion, doctors must recognize the significance of managing their Google listings effectively to thrive in an increasingly digital world.</p>"
                +
                "<h3 class='blog-sub-head blog-inner-head'>How can we help?</h3>" +
                "<ul>" +
                "<li><div><span class='blog-span-head blog-span-dark'>Local Presence Management (Google listing & others):</span>  Improve online presence & increase online traffic across digital and social channels for multi-location businesses with an Integrated Hyperlocal Management solution</div></li>" +
                "<li class='mb-2'><div class='mr-2 blog-span-head blog-span-dark'>1.</div><div><span class='blog-span-head blog-span-dark'>Local Listings Management:</span> Manage, standardise, optimise and monitor performance for local listings across search and discovery platforms such as Google, Apple, Facebook etc.</div></li>" +
                "<li class='mb-2'><div class='mr-2 blog-span-head blog-span-dark'>2.</div><div><span class='blog-span-head blog-span-dark'>Reviews & Reputation Management: </span> Monitor, manage, track customer sentiment, respond & analyse Google reviews and Q&A.</div></li>" +
                "<li class='mb-2'><div class='mr-2 blog-span-head blog-span-dark'>3.</div><div><span class='blog-span-head blog-span-dark'>Store Locator & Location Pages: </span> Enhance customer journey and highlight all business locations seamlessly across the Brand website to leverage Local SEO</div></li>" +
                "<li class='mb-2'><div class='mr-2 blog-span-head blog-span-dark'>4.</div><div><span class='blog-span-head blog-span-dark'>Listing insights: </span>  Analyse traffic reports, reviews feeds, keyword clouds, reviews bucketing, Google Q&A, customer sentiment trends etc.</div></li>" +
                "<li class='mb-2'><div class='mr-2 blog-span-head blog-span-dark'>5.</div><div><span class='blog-span-head blog-span-dark'>SEO, Local SEO & Website content: </span>  Manage onsite, off-site & technical SEO on brand website, location pages, landing pages, blogs, etc by focussing on Search Engine Optimisation with content management, relevant keywords, tags, etc.</div></li>" +
                "<li class='mb-2'><div class='mr-2 blog-span-head blog-span-dark'>6.</div><div><span class='blog-span-head blog-span-dark'>Website with branding of the Clinic/ Doctor:  </span>  Full functional and interactive. With secure patient logins, appointment booking, tele-health and payment gateway.</div></li>" +
                "</ul>" +
                "<p class='card-text'>Want to know more about how we help our Doctors with SEO and optimise their Google listing? Reach out to us on <a href='tel:+919544986088' class='ph-mobile'>+91 95449 86088.</a></p>",
            image: blog2
        }
    ]);





    return (
        <div className="ph-main-wrapper">
            <Header></Header>
            <div className="container-lg">
                {blogDetailArray1
                    .filter(blog => blog.id === blogsIdNo)
                    .map((blog, index) => (
                        <div>
                           {/* <div className='d-block d-md-none ph-mobile-div mt-5'> 
                            <div >
                               { <img src={blog.image} alt="News" className='img-fluid' width="100%" height="300" />}
                            </div>
                            <div className="ph-news-admin pt-1">
                                <div className='d-md-flex ph-news-top'>
                                    <span className='ph-news-author d-none d-md-block'>Author - </span>
                                    <span className='ms-1 ms-sm-0 ph-news-authorname'>{blog.authorname}</span>
                                    <span className='ph-news-linkdn  d-none d-md-block'>
                                        <ul className='mb-0'>
                                            <li className='cursor-pointer'><a href="https://www.linkedin.com/in/nagarjunmishra/" className='cursor-pointer'></a>Linked Profile</li>
                                        </ul>
                                    </span>
                                </div>
                                <div className="d-md-flex ph-news-date mt-0">
                                    <span>{blog.date}</span>
                                </div>
                                <div className='ph-news-main-head mb-3 mb-md-0'>
                                    {blog.heading}
                                </div>
                                <div className='d-flex mb-0 mb-md-4'>
                                    <a href="https://www.facebook.com/gopurplehealth"><img src={Fb} alt="fb" className='img-fluid mr-2' width="30" height="30" /></a>
                                    <a href="https://www.linkedin.com/company/purplehealth"><img src={Linkdn} alt="Linkdn" className='img-fluid mr-2' width="30" height="30" /></a>
                                    <a href="https://www.instagram.com/gopurplehealth?igsh=cm02a21zdjdrb3d6"><img src={Insta} alt="Insta" className='img-fluid mr-2' width="28" height="28" /></a>
                                    
                                </div>
                            </div>
                            </div> */}
                             
                            <div className="ph-news-admin   ph-news-letter-mobile">
                                <div className='d-md-flex ph-news-top order-2'>
                                    <span className='ph-news-author d-none d-md-block'>Author - </span>
                                    <span className='ms-1 ms-sm-0 ph-news-authorname'>{blog.authorname}</span>
                                    <span className='ph-news-linkdn  d-none d-md-block'>
                                        <ul className='mb-0'>
                                        <li className='cursor-pointer'><a href="https://www.linkedin.com/in/nagarjunmishra/" className='cursor-pointer'></a>Linked Profile</li>
                                        </ul>
                                    </span>
                                </div>
                                <div className="d-md-flex ph-news-date mt-0 order-3">
                                    <span>{blog.date}</span>
                                </div>
                                <div className='ph-news-main-head order-4 mb-3 mb-md-0'>
                                    {blog.heading}
                                </div>
                                <div className='d-flex mb-0 mb-md-4 order-4'>
                                    <a href="https://www.facebook.com/gopurplehealth"><img src={Fb} alt="fb" className='img-fluid mr-2' width="30" height="30" /></a>
                                    <a href="https://www.linkedin.com/in/nagarjunmishra/"><img src={Linkdn} alt="Linkdn" className='img-fluid mr-2' width="30" height="30" /></a>
                                    <a href="https://www.instagram.com/gopurplehealth?igsh=cm02a21zdjdrb3d6"><img src={Insta} alt="Insta" className='img-fluid mr-2' width="28" height="28" /></a>
                                    
                                </div>
                                 <div className='text-center order-1 mb-3 mb-md-0'>
                               { <img src={blog.image} alt="News" className='img-fluid' width="100%" height="300" />}
                            </div>
                            </div>
                           
                            <div className='container ph-news-details-main'>
                                <div className="ph-points ph-main-details " dangerouslySetInnerHTML={{ __html: blog.desc }}>
                                </div>
                            </div>
                        </div>
                    ))}
                <div className='ph-related-newsletter'>
                    <div className="ph-main-outerdiv">
                        <div className=''>
                            <div className='  ph-newsletter px-0 px-md-2'>
                                Related Blogs
                            </div>                        
                            <Bloglistblocks 
                            blogId={ window.localStorage.getItem("blogid")}
                            blogDetailFlag={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <AboutTakeNextStep />
            <Footer></Footer>
        </div>
    );
}

export default BlogDetails;
