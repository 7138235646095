import React, { Component } from 'react';
import Solution1 from "../image/theme/local-listing-management.png"
import Solution2 from "../image/theme/online-reputation-manegement.png"
import Solution3 from "../image/theme/clinical-locator.png"
import Add from "../image/theme/add.png"
import Close from "../image/theme/closebtn.png"
export default class localPMSolutions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isContentVisible: false,
            isContentVisibleTwo:false,
            isContentVisibleThree:false
          };
    }
    toggleVisibility = (contentId) => {
        this.setState((prevState) => ({
          [contentId]: !prevState[contentId],
        }));
      };
    render() {
        const { isContentVisible, isContentVisibleTwo, isContentVisibleThree } = this.state;
        return (
            <div className="ph-main-outerdiv">
                <div className='container-lg'>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="ph-db-banner">
                                <h2 className="ph-main-title-2 mt-5">
                                Our Solutions for LPM
                                </h2>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="d-md-flex flex-md-equal w-100 mt-3 mb-5 my-md-5">
                                <div className="col-lg-6 px-0">
                                    <div className='ph-local-pm-more'>
                                        <img src={Solution1} alt="digitalBrand" width="605" height="291" className='img-fluid' />
                                    </div>
                                </div>
                                <div className="col-lg-6 px-0">
                                    <div className="ph-solutions">
                                        <h3 className="ph-solution-title">Local Listings Management</h3>
                                        <p className="ph-solution-content">
                                            {!isContentVisible ?
                                            "We use the latest techniques and data insights to optimize your listings to increas their visibility. This helps your patients find you online easily."
                                            :
                                            "We use the latest techniques and data insights to optimize your listings, increasing their visibility and effectiveness, so you can connect better with local customers."
                                            }
                                        </p>
                                        <h4 className="ph-solution-sub">
                                            Key Features :
                                        </h4>
                                        <div className="d-flex flex-wrap">
                                            <div className="ph-key-features">
                                            Ensure your business information is accurate and consistent across all digital platforms
                                            </div>
                                            <div className="ph-key-features">
                                                Help your potential customers to locate your services effortlessly
                                            </div>
                                            
                                            <div className={`d-flex flex-wrap ${isContentVisible ? 'visible-class' : 'invisible-class'}`}>
                                                    <div className="ph-key-features">
                                                        An all-encompassing solution to enhance your presence on prominent digital search and discovery platforms
                                                    </div>
                                                </div>
                                            
                                        </div>
                                            <button className={`ph-learn-more ${isContentVisible ? 'ph-btn-show' : 'ph-btn-hide'}`} onClick={() => this.toggleVisibility("isContentVisible")}>
                                                <span className={`isspan ${isContentVisible ? 'isspanHide' : 'isspanShow'}`}>Learn More</span>
                                                <span className='text-right ph-icon-wrapper'>
                                                    {isContentVisible ?
                                                    <img src={Close} alt="Add" className='img-fluid' width="20" height="20" />:
                                                    <img src={Add} alt="Close" className='img-fluid' width="20" height="20" />
                                                    }
                                                </span>
                                            </button>
                                    </div>
                                </div>
                            </div>
                            <div className="d-md-flex flex-md-equal w-100 my-5">
                                <div className="col-lg-6 px-0">  
                                    <div className='ph-local-pm-more'>
                                        <img src={Solution2} alt="digitalBrand" width="605" height="291" className='img-fluid' />
                                    </div>
                                </div>
                                <div className="col-lg-6 px-0">
                                    <div className="ph-solutions">
                                        <h3 className="ph-solution-title">Online Reputation Management</h3>
                                        <p className="ph-solution-content">
                                            {!isContentVisibleTwo ?
                                            "Establish a positive and influential digital reputation and ensure your online image."
                                            :
                                            "Establish a positive and influential digital reputation and ensure youronline image reflects the true essence of your practice."
                                            }
                                        </p>
                                        <h4 className="ph-solution-sub">
                                            Key Features :
                                        </h4>
                                        <div className="d-flex flex-wrap">
                                            <div className="ph-key-features">
                                            Monitor, manage, and track patient sentiment across all digital platforms
                                            </div>
                                            <div className="ph-key-features">
                                                Analyze and respond to google reviews and Q&A, creating a direct line of communication with your patients
                                            </div>
                                            <div className={`d-flex flex-wrap ${isContentVisibleTwo ? 'visible-class' : 'invisible-class'}`}>
                                                    <div className="ph-key-features">
                                                    Address concerns, resolve issues and showcase your commitment to continuous improvement of your practice
                                                    </div>
                                                    <div className="ph-key-features">
                                                    Ensure location pages and the clinic locator tool are optimised for mobile devices
                                                    </div>
                                                </div>

                                        </div>
                                        <button className={`ph-learn-more ${isContentVisibleTwo ? 'ph-btn-show' : 'ph-btn-hide'}`} onClick={() => this.toggleVisibility("isContentVisibleTwo")}>
                                                <span className={`isspan ${isContentVisibleTwo ? 'isspanHide' : 'isspanShow'}`}>Learn More</span>
                                                <span className='text-right ph-icon-wrapper'>
                                                    {isContentVisibleTwo ?
                                                    <img src={Close} alt="Add" className='img-fluid' width="20" height="20" />:
                                                    <img src={Add} alt="Close" className='img-fluid' width="20" height="20" />
                                                    }
                                                </span>
                                            </button>
                                    </div>
                                </div>
                            </div>
                            <div className="d-md-flex flex-md-equal w-100 my-5">
                                <div className="col-lg-6 px-0">
                                    <div className='ph-local-pm-more'>
                                        <img src={Solution3} alt="digitalBrand" width="605" height="291" className='img-fluid' />
                                    </div>
                                </div>
                                <div className="col-lg-6 px-0">
                                    <div className="ph-solutions">
                                        <h3 className="ph-solution-title">Clinic Locator & Location Pages</h3>
                                        <p className="ph-solution-content">
                                            {!isContentVisibleThree ?
                                            "Help your patients find your clinic location(s) and access directions easily."
                                            :
                                            "Help your patients find your clinic location(s) easily, access directions, and explore the services offered at each location for a seamless experience."
                                            }
                                        </p>
                                        <h4 className="ph-solution-sub">
                                            Key Features :
                                        </h4>
                                        <div className="d-flex flex-wrap">
                                            <div className="ph-key-features">
                                            Create dedicated pages for each clinic location, showcasing specific services, medical professionals, and unique offerings at each site
                                            </div>
                                            <div className="ph-key-features">
                                            Give comprehensive insights to patients, fostering trust and informed decision-making
                                            </div>
                                            <div className={`d-flex flex-wrap ${isContentVisibleThree ? 'visible-class' : 'invisible-class'}`}>
                                                    {/* <div className="ph-key-features">
                                                  
                                                    </div> */}
                                                </div>
                                        </div>
                                                 <button className={`ph-learn-more ${isContentVisibleThree ? 'ph-btn-show' : 'ph-btn-hide'}`}  onClick={() => this.toggleVisibility("isContentVisibleThree")}>
                                                    <span className={`isspan ${isContentVisibleThree ? 'isspanHide' : 'isspanShow'}`}>Learn More</span>
                                                    <span className='text-right ph-icon-wrapper'>
                                                    {isContentVisibleThree ?
                                                    <img src={Close} alt="Add" className='img-fluid' width="20" height="20" />:
                                                    <img src={Add} alt="Close" className='img-fluid' width="20" height="20" />
                                                    }
                                                </span>
                                            </button>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        )
    }
}
