import React, { Component, Suspense } from "react";
import $ from "jquery";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { apiCalling, apiUrlRelianceFunction } from "../apiService";
import moment from "moment";
import Pagination from "react-js-pagination";
import csv from "../image/icons/csv.png";
import * as XLSX from "xlsx";
import Header from "./header";
import Footer from "./footer";
const folderName = window.localStorage.getItem("homeName");
export default class textChatReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentData: [],
      toDate: "",
      fromDate: "",
      totalCount: 0,
      activePage: 1,
      fuctionName: "TextChatPayment Report",
      csvDetails: [],
      showMessage: "Waiting for data... ",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeTo = this.handleChangeTo.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.textChatReport();
  }
  handleChange = (date) => {
    this.setState({
      fromDate: date,
    });
  };
  handleChangeTo = (date) => {
    this.setState({
      toDate: date,
    });
  };
  changeHandler = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };
  handlePageChange(pageNumber) {
    var fromDate;
    var toDate;
    var startDate = this.state.fromDate ? new Date(this.state.fromDate) : "";
    if (startDate) {
      startDate.setHours(0);
      startDate.setMinutes(0);
      startDate.setSeconds(0);
      startDate.setTime(startDate.getTime() + 1000);
      fromDate = moment(startDate).format("YYYY-MM-DD HH:mm");
      // fromDate = startDate.getTime();
    } else {
      fromDate = "";
    }
    var endDate = this.state.toDate ? new Date(this.state.toDate) : "";
    if (endDate) {
      endDate.setHours(23);
      endDate.setMinutes(59);
      endDate.setSeconds(59);
      toDate = moment(endDate).format("YYYY-MM-DD HH:mm");
      // toDate = endDate.getTime();
    } else {
      toDate = "";
    }
    window.scrollTo(0, 0);
    this.setState({ activePage: pageNumber });
    var offset;
    var max;
    if (pageNumber === "1") {
      offset = "0";
      max = "10";
      this.setState({
        activePage: pageNumber,
        isLoading: "0",
      });
    } else {
      offset = parseInt(pageNumber - 1) * 10;
      max = "10";
      this.setState({
        activePage: pageNumber,
        isLoading: "0",
      });
    }
    const apiJson = {
      method: "POST",
      body: JSON.stringify(
       !$("#patientReply").val() ? // Check if patientReply exists or not for filter
        {
          functionName: "paymentReport",
          subFunctionName: "textChatPayment",
          userId: this.props?.match?.params?.groupHospitalId,
          max: max,
          offset: JSON.stringify(offset),
          cmbCategory1: $("#textChatSelect").val() === "0" ? "" : $("#textChatSelect").val(),
          searchKeyWord: $("#txtTextChat").val(),
          exportFromDate: fromDate,
          exportToDate: toDate,
          fromDate: fromDate,
          toDate: toDate,
        } :
        {
          functionName: "paymentReport",
          subFunctionName: "textChatPayment",
          userId: this.props?.match?.params?.groupHospitalId, 
          max:max,
          offset:JSON.stringify(offset),
          patientReply: $("#patientReply").val(), 
          exportFromDate: fromDate,
          exportToDate: toDate,
          fromDate: fromDate,
          toDate: toDate,
        }
      ),
    };
    
    $(".loader").show();
    let apiFunction = folderName !== null ? apiCalling : apiUrlRelianceFunction;
    apiFunction(apiJson).then((data) => {
      if (data.success === "1") {
        this.setState({
          paymentData: data.result.paymentData,
          totalCount: data.result.count,
        });
        $(".loader").hide();
      }
    }).catch((error) => {
      // console.error("Error sending notification:", error);
  });
  }
  textChatReport() {
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "paymentReport",
        subFunctionName: "textChatPayment",
        userId: this.props?.match?.params?.groupHospitalId,
        max: "10",
        offset: "0",
        exportFromDate: "",
        exportToDate: "",
        fromDate: "",
        toDate: "",
      }),
    };
    let apiFunction = folderName !== null ? apiCalling : apiUrlRelianceFunction;
    apiFunction(apiJson).then((data) => {
      $(".loader").hide();
      if (data.success === "1") {
        this.setState({
          paymentData: data.result.paymentData,
          csvDetails: data.result.paymentExportData,
          totalCount: data.result.count,
        });
      }
      if (data.success === "0") {
        $(".loader").hide();
        this.setState({
          paymentData: [],
          csvDetails: [],
          showMessage: data.errorMessage,
        });
      }
    }).catch((error) => {
      // console.error("Error sending notification:", error);
  });
  }
  changeText = () => {
    if ($("#textChatSelect").val() !== "0") {
      document.getElementById("txtTextChat").disabled = false;
      $("input[name=txtTextChat]").focus();
    } else {
      document.getElementById("txtTextChat").disabled = true;
    }
    document.getElementById("txtTextChat").value = "";
    $("#patientReply").val("")
  };
  searchHandler() {
    var fromDate;
    var toDate;
    var startDate = this.state.fromDate ? new Date(this.state.fromDate) : "";
    if (startDate) {
      startDate.setHours(0);
      startDate.setMinutes(0);
      startDate.setSeconds(0);
      startDate.setTime(startDate.getTime() + 1000);
      fromDate = moment(startDate).format("YYYY-MM-DD HH:mm");
    } else {
      fromDate = "";
    }
    var endDate = this.state.toDate ? new Date(this.state.toDate) : "";
    if (endDate) {
      endDate.setHours(23);
      endDate.setMinutes(59);
      endDate.setSeconds(59);
      toDate = moment(endDate).format("YYYY-MM-DD HH:mm");
    } else {
      toDate = "";
    }
    if ($("#textChatSelect").val() !== "0" && $("#txtTextChat").val() === "") {
      var t = document.getElementById("textChatSelect");
      var selectedText = t.options[t.selectedIndex].text;
      alert(selectedText + " Required");
      $("#txtTextChat").focus();
      return false;
    }
    if (fromDate > toDate) {
      alert("To date should be greater than or equal to from date");
      return false;
    } else {
      const searchData = {
        method: "POST",
        body: JSON.stringify({
          functionName: "paymentReport",
          subFunctionName: "textChatPayment",
          userId: this.props?.match?.params?.groupHospitalId,
          max: "10",
          offset: "0",
          cmbCategory1:
            $("#textChatSelect").val() === "0"
              ? ""
              : $("#textChatSelect").val(),
          searchKeyWord: $("#txtTextChat").val(),
          exportFromDate: fromDate,
          exportToDate: toDate,
          fromDate: fromDate,
          toDate: toDate,
        }),
      };
      $(".loader").show();
      let apiFunction =
        folderName !== null ? apiCalling : apiUrlRelianceFunction;
      apiFunction(searchData).then((data) => {
        if (data.success === "1") {
          $(".loader").hide();
          this.setState({
            paymentData: data.result.paymentData,
            csvDetails: data.result.paymentExportData,
            totalCount: data.result.count,
            activePage: 1,
          });
        }
        if (data.success === "0") {
          $(".loader").hide();
          this.setState({
            paymentData: [],
            csvDetails: [],
            showMessage: data.errorMessage,
          });
        }
      }).catch((error) => {
        // console.error("Error sending notification:", error);
    });
    }
  }
  csvFileDownload() {
    var fromDate;
    var toDate;
    var startDate = this.state.fromDate ? new Date(this.state.fromDate) : "";
    if (startDate) {
      startDate.setHours(0);
      startDate.setMinutes(0);
      startDate.setSeconds(0);
      startDate.setTime(startDate.getTime() + 1000);
      fromDate = moment(startDate).format("YYYY-MM-DD HH:mm");
    } else {
      fromDate = "";
    }
    var endDate = this.state.toDate ? new Date(this.state.toDate) : "";
    if (endDate) {
      endDate.setHours(23);
      endDate.setMinutes(59);
      endDate.setSeconds(59);
      toDate = moment(endDate).format("YYYY-MM-DD HH:mm");
    } else {
      toDate = "";
    }
    if (fromDate && toDate) {
      if (fromDate > toDate) {
        alert("To date should be greater than or equal to from date");
        return false;
      } else {
        $(".loader").show();
        const apiJson = {
          method: "POST",
          body: JSON.stringify
           (
            !$("#patientReply").val() ? // Check if patientReply exists or not for filter
             {
               functionName: "paymentReport",
               subFunctionName: "textChatPaymentExport",
               userId: this?.props?.match?.params?.groupHospitalId,
               max: "10",
               offset:"0",
               cmbCategory1: $("#textChatSelect").val() === "0" ? "" : $("#textChatSelect").val(),
               searchKeyWord: $("#txtTextChat").val(),
               exportFromDate: fromDate,
               exportToDate: toDate,
               fromDate: fromDate,
               toDate: toDate,
             } :
             {
               functionName: "paymentReport",
               subFunctionName: "textChatPaymentExport",
               userId: this?.props?.match?.params?.groupHospitalId, 
               max:"10",
               offset:"0",
               patientReply: $("#patientReply").val(), 
               exportFromDate: fromDate,
               exportToDate: toDate,
               fromDate: fromDate,
               toDate: toDate,
             }
           ),
        };
        let abcc = folderName != null ? "a1" : "apiUrlRelianceFunction";
        let apiFunction =
          folderName !== null ? apiCalling : apiUrlRelianceFunction;
        apiFunction(apiJson).then((data) => {
          $(".loader").hide();
          if (data.success === "1") {
            const csvRow = [];
            var dataArray = [];
            var columnDetails = [
              "Policy No.",
              "Employee Id.",
              "Patient Name",
              "Patient Mobile No.",
              "Hospital Name",
              "Doctor Name",
              "Chat Requested Date",
              // "Appointment Took Place Date",
              "Transaction Id",
              // "Appointment Time",
              "Appointment Status",
              "Patient Replied Status",
              "Coupon Amount",
              "Doctor Charge",
              "Amount Received",
            ];
            var results = data.result.paymentExportData;
            //* Policy Number, mobNo- new addition
            for (var item = 0; item < results.length; item++) {
              let testStatus = "";
              if (
                (results[item].doctorReply === true ||
                  results[item].doctorReply == 1) &&
                results[item].isComplete === 1
              ) {
                testStatus = "Completed - Doctor Replied";
              } else if (
                (results[item].doctorReply === false ||
                  results[item].doctorReply == 0) &&
                results[item].isComplete === 0
              ) {
                testStatus = "Active - Doctor Didn't Reply";
              } else if (
                (results[item].doctorReply === false ||
                  results[item].doctorReply == 0) &&
                results[item].isComplete === 1
              ) {
                testStatus = "Expired - Doctor Didn't Reply";
              } else if (
                (results[item].doctorReply === true ||
                  results[item].doctorReply == 1) &&
                results[item].isComplete === 0
              ) {
                testStatus = "Active - Doctor Replied";
              }
              dataArray.push([
                results[item].policyNo,
                results[item].empId ? results[item].empId : "No",
                results[item].memName,
                results[item].mobNo,
                results[item].hospitalName,
                results[item].docName,
                moment(results[item].requestedDate).format("DD-MMM-YYYY"),
                // moment(results[item].confirmedAppointmentDate).format("DD-MMM-YYYY") ===
                //   "01-Jan-1970"
                //   ? " "
                //   : moment(results[item].confirmedAppointmentDate).format(
                //     "DD-MMM-YYYY"
                //   ),
                results[item].transactionId,
                
                // moment(results[item].requestedDate).format("hh:mm A"),
                testStatus,
                results[item].patientReply===1?"Yes":"No",
                results[item].promoAmt,
                results[item].actualAmount,
                results[item].amount,
              ]);
            }
            const worksheet = XLSX.utils.json_to_sheet(dataArray);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(
              workbook,
              worksheet,
              "TextChatPaymentReports"
            );
            XLSX.utils.sheet_add_aoa(worksheet, [columnDetails], {
              origin: "A1",
            });
            XLSX.writeFile(workbook, "TextChatPaymentReports.xlsx", {
              compression: true,
            });
            this.setState({
              csvDetails: data.result.paymentExportData,
            });
          }
          if (data.success === "0") {
            $(".loader").hide();
            this.setState({
              paymentData: [],
              csvDetails: [],
              showMessage: data.errorMessage,
            });
          }
        }).catch((error) => {
          // console.error("Error sending notification:", error);
      });
      }
    } else {
      alert("Please Fill From Date and To Date");
    }
  }
  patientReplyFunction() {
    var fromDate;
    var toDate;
    var startDate = this.state.fromDate ? new Date(this.state.fromDate) : "";
    if (startDate) {
      startDate.setHours(0);
      startDate.setMinutes(0);
      startDate.setSeconds(0);
      startDate.setTime(startDate.getTime() + 1000);
      fromDate = moment(startDate).format("YYYY-MM-DD HH:mm");
    } else {
      fromDate = "";
    }
    var endDate = this.state.toDate ? new Date(this.state.toDate) : "";
    if (endDate) {
      endDate.setHours(23);
      endDate.setMinutes(59);
      endDate.setSeconds(59);
      toDate = moment(endDate).format("YYYY-MM-DD HH:mm");
    } else {
      toDate = "";
    }
    // if ($("#textChatSelect").val() !== "0" && $("#txtTextChat").val() === "") {
    //   var t = document.getElementById("textChatSelect");
    //   var selectedText = t.options[t.selectedIndex].text;
    //   alert(selectedText + " Required");
    //   $("#txtTextChat").focus();
    //   return false;
    // }
    if (fromDate > toDate) {
      alert("To date should be greater than or equal to from date");
      return false;
    } else {
      $("#textChatSelect").val("0")
              $("#txtTextChat").val("")
              // this.setState({
              //   toDate:"",
              //   fromDate:"",
              // });
      const searchData = {
        method: "POST",
        body: JSON.stringify(
          {
            "functionName": "paymentReport",
            "subFunctionName": "textChatPayment",
            "userId":this?.props?.match?.params?.groupHospitalId ,
            "max": "10",
            "offset": "0",
            "patientReply":$("#patientReply").val() ,
            exportFromDate: fromDate,
            exportToDate: toDate,
            fromDate: fromDate,
            toDate: toDate,
        }
        ),
      };
      $(".loader").show();
      let apiFunction =
        folderName !== null ? apiCalling : apiUrlRelianceFunction;
      apiFunction(searchData).then((data) => {
        if (data.success === "1") {
          $(".loader").hide();
          this.setState({
            paymentData: data.result.paymentData,
            csvDetails: data.result.paymentExportData,
            totalCount: data.result.count,
            activePage: 1,
          });
        }
        if (data.success === "0") {
          $(".loader").hide();
          this.setState({
            paymentData: [],
            csvDetails: [],
            showMessage: data.errorMessage,
          });
        }
      }).catch((error) => {
        // console.error("Error sending notification:", error);
    });
    }
  }
  render() {
    return (
      <div className="purpleWrap">
        <Suspense fallback={<div>Waiting...</div>}>
          <section>
            <Header />
            <div className="loader"></div>
            <div className="container">
              <div className="main-wrapper1">
                <div className="col-md-12 p-0">
                  <ul className="breadcrumb">
                    <li>
                      <a href={"/reliance/dashboard"}>Dashboard</a>
                      <i className="fa fa-chevron-right"></i>
                    </li>
                    <li> Text Chat Payment Report</li>
                  </ul>
                </div>
                <div className="form-head"> Text Chat Payment Report </div>
                <div className="custom-search row justify-content-lg-end justify-content-start">
                  <div className="col-md-4 col-6 custom-search-sub custom-search-subWidth">
                    <select
                      className="input-design"
                      id="textChatSelect"
                      name="textChatSelect"
                      onChange={() => this.changeText()}
                    >
                      <option value="0">- - Select - -</option>
                      <option value="Patient Name">Patient Name</option>
                      <option value="Doctor Name"> Doctor Name</option>
                      <option value="Hospital Name">Hospital Name</option>
                      <option value="Transaction Id"> Transaction ID</option>
                      <option value="PolicyNo"> Policy No</option>
                      {/* <option value="Status">Appointment Status</option> */}
                    </select>
                  </div>
                  <div className="col-md-4 col-6 custom-search-sub custom-search-subWidth">
                    <input
                      type="text"
                      className="input-design"
                      id="txtTextChat"
                      name="txtTextChat"
                      disabled
                    />
                  </div>
                  <div className="col-md-4 col-6 custom-search-sub custom-search-subWidth">
                    <DatePicker
                      placeholderText="From Date"
                      id="fromDate"
                      name="fromDate"
                      selected={this.state.fromDate}
                      onChange={this.handleChange}
                      maxDate={new Date()}
                      dateFormat="dd/MM/yyyy"
                      calendarClassName="react-date-picker"
                    />
                  </div>
                  <div className="col-md-4 col-6 custom-search-sub custom-search-subWidth">
                    <DatePicker
                      placeholderText="To Date"
                      id="toDate"
                      name="toDate"
                      selected={this.state.toDate}
                      onChange={this.handleChangeTo}
                      dateFormat="dd/MM/yyyy"
                      maxDate={new Date()}
                      calendarClassName="react-date-picker"
                    />
                  </div>
                  <div className="col-md-4 col-6 custom-search-sub custom-search-subWidth-sm">
                    {/* <label>patient Reply</label> */}
                    <select
                      className="input-design"
                      id="patientReply"
                      name="patientReply"
                      onChange={() => this.patientReplyFunction()}
                    >
                       <option value="">Patient Replied</option>
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                     
                      {/* <option value="Status">Appointment Status</option> */}
                    </select>
                  </div>
                  <div className="col-md-1 col-10 mb-3 custom-search-sub custom-search-subWidth">
                    <input
                      type="submit"
                      value="Search"
                      className="formButton m-0 formButtonsearch-width"
                      onClick={() => this.searchHandler()}
                    />
                  </div>
                  <div className="custom-search-sub ml-2 mb-2">
                    <a
                      onClick={() => this.csvFileDownload()}
                      className="cursor-pointer"
                    >
                      <img
                        src={csv}
                        alt="csv"
                        width="25"
                        title="Export CSV"
                        className="mt-1"
                      />
                    </a>
                  </div>
                </div>
                {this.state.paymentData.length > 0 ? (
                  <div className="table-responsive">
                    <table className="table video_audio_table">
                      <thead>
                        <tr>
                          <th>Policy Number</th>
                          <th>Employee ID</th>
                          <th>Patient Name</th>
                          <th>Patient Mobile No.</th>
                          <th>Hospital Name</th>
                          <th>Doctor Name</th>
                          <th>Text Chat Requested Date</th>
                          <th>Transaction Id </th>
                          <th>Appointment Status</th>
                          <th>Patient Replied Status</th>
                          <th>Coupon Amount</th>
                          <th>Doctor Charge</th>
                          <th>Amount Received</th>
                        </tr>
                      </thead>
                      <tbody style={{ fontSize: "12px" }}>
                        {this.state.paymentData.map((data) => {
                          let testStatus = "";
                          if (
                            (data.doctorReply === true ||
                              data.doctorReply === 1) &&
                            data.isComplete === 1
                          ) {
                            testStatus = "Completed - Doctor Replied";
                          } else if (
                            (data.doctorReply === false ||
                              data.doctorReply === 0) &&
                            data.isComplete === 0
                          ) {
                            testStatus = "Active - Doctor Didn't Reply";
                          } else if (
                            (data.doctorReply === false ||
                              data.doctorReply === 0) &&
                            data.isComplete === 1
                          ) {
                            testStatus = "Expired - Doctor Didn't Reply";
                          } else if (
                            (data.doctorReply === true ||
                              data.doctorReply === 1) &&
                            data.isComplete === 0
                          ) {
                            testStatus = "Active - Doctor Replied";
                          }
                          //   let requestedDate = moment(data.requestedDate).format("DD-MMM-YYYY");
                          return (
                            <tr>
                              <td>{data.policyNo}</td>
                              <td>{data.empId ? data.empId : "No"}</td>
                              <td>{data.memName}</td>
                              <td>{data.mobNo}</td>
                              <td>{data.hospitalName}</td>
                              <td>Dr.{data.docName}</td>
                              <td>{data.requestedDate}</td>
                              <td>{data.transactionId}</td>
                              <td>{testStatus}</td>
                              <td>{data.patientReply===1?"Yes":"NO"}</td>
                              <td>₹ {data.promoAmt}</td>
                              <td>₹ {data.actualAmount}</td>
                              <td>₹ {data.amount}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    {this.state.totalCount > 5 ? (
                      <div className="paginationSection orderm-paginationSection">
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={10}
                          totalItemsCount={this.state.totalCount}
                          pageRangeDisplayed={10}
                          onChange={this.handlePageChange}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <p className="error-msg text-center mb-0">
                    {" "}
                    {this.state.showMessage}{" "}
                  </p>
                )}
              </div>
            </div>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
