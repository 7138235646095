import React, { Component } from 'react';
import Header from "../layout/header"
import Footer from "../layout/footer"
import edit from "../image/media/edit.png"
import publish from "../image/media/draft.png"
import draft from "../image/media/publish.png"
import $ from "jquery";

export default class Healtharticle extends Component {

  componentDidMount(){
    $(".popup-hide").click(function () {
      $(".popup-overlay").hide();
    });
    $("html").click(function () {
      $(".popup-overlay").hide();
    });

    $(".popup-content").click(function (event) {
      event.stopPropagation();
    });
  }
  
  healthEmbedCode() {
    $("#healthEmbedCode-main").show();
  }
 
  render() {
    return (
      <div className="purpleWrap">
        <Header></Header>
        <div className="container container-auto">
          <div className="main-wrapper1">
                    
      
                    <div className="col-md-12 p-0">
                      <ul className="breadcrumb">
                        <li><a href="/doctor/dashboard">Dashboard</a><i className="fa fa-chevron-right"></i></li>
                        <li>Health Article List</li>
                      </ul>
                    </div>
                    
                    <h5 className="color-maintext"> Health Article List </h5><br></br>
                    <div className="col-sm-12 p-0 mb-3">
                      <a href="/doctor/healthblog">
                      <input type="button" value="Add Health Article" className="formButton mx-0"></input>
                      </a>
                      <input type="button" value="Embed Code" className="formButton pull-right mx-0" onClick={this.healthEmbedCode}></input>
                        
                    </div>
                    <div className="col-sm-12 search-main">
                      <div className="row">
                        <div className="col-md-2 col-lg-2 my-2">
                          <select className="input-design">
                            <option value="0" selected="">Search In</option>
                            <option value="level1">Id</option>
                            <option value="level2">Title</option>
                            <option value="level3">Category</option>
                          </select>
                        </div> 
                        <div className="col-md-2 col-lg-2 my-2">
                            <input type="text" className="input-design" disabled="" />
                        </div> 
                        <div className="col-md-2 col-lg-2 my-2">
                            <select className="input-design" >
                              <option value="0" selected="" className="input-design">Status</option>
                              <option value="false">Draft</option>
                              <option value="true">Published</option>										           
                            </select>
                        </div> 
                        <div className="col-md-2 col-lg-2 my-2">
                            <select className="input-design">
                            <option value="0" selected="">Approval Status</option>
                            <option value="false">Not approved</option>
                            <option value="true">Approved</option>										           
                          </select>
                        </div> 
                        <div className="col-md-2 col-lg-2 my-2">
                          <input type="submit" value="Search" className="btn-bg w-100"/>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead className="form-section-bgcolor">
                        <tr>  
                          <th>ID</th>         
                          <th>Title</th>
                          <th>Category</th>                             			                        					   
                          <th>Status</th>
                          <th>Approval Status</th>
                          <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>201	</td>
                            <td>Sanjay Deo	</td>
                            <td>Video	</td>
                            <td>Draft</td>
                            <td>Not approved		</td>
                            <td style={{whiteSpace:"nowrap"}}><a href="#"><img src={draft} width="25" /></a>
                            <a href="#"><img src={publish} width="25" /></a>
                            <a  href="/doctor/healthblog" className="ml-3"><img src={edit} width="25" /></a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

           </div>
        </div>

        <Footer></Footer>

        {/* Popup */}
        <div className="popup-overlay" id="healthEmbedCode-main">
          <div className="popup-content">
            <div className="modal-popup">
              <a className="close-popup  popup-hide">
                &times;
              </a>
              <div className="header border-0 color-maintext text-left"> Embed Code </div>
              <div className="content">
                <div className="col-md-12">
                  <textarea id="embedText" className="input-design" rows="4"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
