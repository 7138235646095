import React, { Component } from 'react';
import Header from "../layout/header"
import Footer from "../layout/footer"
import $ from "jquery";

export default class Healthblog extends Component {

  render() {
    return (
      <div className="purpleWrap">
        <Header></Header>
        <div className="container container-auto">
          <div className="main-wrapper1">
              <div className="col-md-12 p-0">
                <ul className="breadcrumb">
                  <li><a href="/doctor/dashboard">Dashboard</a><i className="fa fa-chevron-right"></i></li>
                  <li><a href="/doctor/healtharticle">Health Article List</a><i className="fa fa-chevron-right"></i></li>
                  <li>Health Article</li>
                </ul>
              </div>
              <div className="col-sm-12 px-lg-4 px-0">
                <h5 className="color-maintext"> Health Article</h5><br></br>
                <h5>Write Your Article or Blog</h5>
                <p className="font-18">Write using our text editor below. It's simple and easy to do. (*) Denotes a mandatory field.</p>
                <div className="row row-margin">
                  <div className="col-lg-10">
                    <label className="col-sm-12 label-text">Add Title  <span className="star-red">*</span></label>
                    <div className="col-sm-12">
                      <input type="text" className="input-design" placeholder="Blog Title" />
                    </div>
                  </div>

                  <div className="col-lg-2">
                    <label className="col-sm-12 label-text">Status</label>
                    <div className="col-sm-12">
                      <select className="input-design">
                        <option>Status</option>
                      </select>
                    </div>
                  </div> 
                </div>

                <div className="row row-margin">
                  <div className="col-12 col-lg-6">
                    <label className="col-sm-12 label-text">Add Category  <span className="star-red">*</span></label>
                    <div className="col-sm-12">
                      <select className="input-design">
                        <option>Status</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="row row-margin">
                  <div className="col-sm-12">
                    <label className="col-sm-12 label-text">Add a Short Description <span className="star-red">*</span> <small>(Max 500 characters)</small></label>
                    <div className="col-sm-12">
                      <textarea className="input-design p-3" rows="5" placeholder="Add a Short Description"></textarea>
                    </div>
                  </div>
                </div>

                <div className="row row-margin">
                  <div className="col-sm-12">
                    <label className="col-sm-12 label-text">Add Search Tags <span className="star-red">*</span> <small>(Add Tag(s) & Press Enter Key,Min 1 tag. eg Diabetes or Nutrition)</small></label>
                    <div className="col-sm-12">
                      <span>
                        <span className="badge badge-light">fdf<i className="fa fa-remove ml-2" style={{ fontSize: "10px" }}></i></span>
                        <span className="badge badge-light">fdf<i className="fa fa-remove ml-2" style={{ fontSize: "10px" }}></i></span>
                      </span>
                      <input className="input-design" style={{width: "100px"}}></input>
                    </div>
                  </div>
                </div>

                <div className="row row-margin">
                  <div className="col-sm-12">
                    <label className="col-sm-12 label-text">Add Affiliate URL </label>
                    <div className="col-sm-12">
                      <textarea className="input-design p-3" rows="5" placeholder="Add any Affiliate Link"></textarea>
                    </div>
                  </div>
                </div>

                <div className="row px-3">
                  <div className="col-sm-3">
                    <div className="fileUpload">
                      <span>Add image</span>
                      <input type="file" className="upload" />
                    </div>
                  </div>
                  <div className="col-sm-9">
                    <p className="note">Format: JPEG,JPG,PNG. Image Size: 500*245. File Size: 10MB maximum.(optional)</p>
                  </div>
                </div>

                <div className="col-sm-12 text-right">
                  <input type="button" value="Preview" className="formButton"></input>
                  <input type="submit" value="Save" className="formButton"></input>
                  <input type="button" value="Cancel" className="formButton cancelButton"></input>
                </div>
              </div>

          </div>
        </div>

        <Footer></Footer>
      </div>
    )
  }
}
