import React, { useState, useEffect, Suspense } from "react";
import Chart from "react-apexcharts";
import { awsAPIUrlcommon } from "../apiService";
import Header from "../layout/header";
import { useLocation } from "react-router-dom";
import Footer from "../layout/footer";

const colors = ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#00D9E9', '#FF66C3'];

const PollReport = () => {
    const location = useLocation();
    const [showingCondentData, setShowingCondentData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (location?.state?.data) {
            getPollGraphDetails(location.state.data);
        }
    }, []);

    const getPollGraphDetails = async (pollId) => {
        setLoading(true);
        const postData = {
            method: "POST",
            body: JSON.stringify({
                "functionName": "getUserPollAnalytics",
                "pollId": pollId
            })
        };

        try {
            const data = await awsAPIUrlcommon(postData);
            if (data?.success === "1") {
                setShowingCondentData(data.result[0]);
            } else {
                alert("Something went wrong");
            }
        } catch (error) {
            console.error("Error fetching poll data:", error);
            alert("An error occurred while fetching poll data");
        } finally {
            setLoading(false);
        }
    };

    const renderChart = (questionData, index) => {
        const categories = questionData?.userPollDetails?.map(answer => answer?.answer || "Text Answer") || [];
        const seriesData = questionData?.userPollDetails?.map(answer => answer?.totalCount || 0) || [];

        return (
            <div className="poll-report-chart" key={index}>
                <div id={`chart-${index}`}>
                    <Chart
                        options={{
                            chart: {
                                height: 350,
                                type: 'bar',
                                toolbar: { show: false }
                            },
                            tooltip: { enabled: true },
                            colors: colors,
                            plotOptions: {
                                bar: {
                                    columnWidth: '25%',
                                    distributed: true,
                                }
                            },
                            dataLabels: { enabled: false },
                            legend: { show: false },
                            xaxis: {
                                categories: categories,
                                labels: {
                                    style: {
                                        colors: colors,
                                        fontSize: '12px'
                                    },
                                    show: false
                                }
                            },
                            yaxis: {
                                labels: {
                                    formatter: (val) => Math.round(val)
                                }
                            }
                        }}
                        series={[
                            {
                                name: 'Total Counts',
                                data: seriesData
                            }
                        ]}
                        type="bar"
                        height={350}
                    />
                </div>
                <div id={`html-dist-${index}`}></div>
            </div>
        );
    };

    return (
        <div className="purpleWrap bg-clr-wthHead">
            <Suspense fallback={<div>Waiting...</div>}>
                <section>
                    <Header />
                    {loading && <div className="loader"></div>}
                    <div className="container mb-5">
                        <div>
                            <ul className="breadcrum-adminMangmnt">
                                <li>
                                    <a href="/admin/dashboard">Dashboard</a>
                                </li>
                                <li>
                                    <a href="/adminManagement/pollList">Poll List</a>
                                </li>
                                <li>
                                    <a href="" className="current">Poll Report</a>
                                </li>
                            </ul>
                        </div>
                        <div className="head-hospMangmntList">Poll Report</div>
                        {showingCondentData?.userPollDetails?.length ? (
                            <ul className="poll-report-ul">
                                <li className="poll-report-li">
                                    <div className="poll-report-head">
                                        Poll Title: <span className="poll-report-head-span">{showingCondentData.pollName}</span>
                                    </div>
                                    {showingCondentData.userPollDetails.map((questionData, i) => (
                                        <div className="poll-report-div" key={i}>
                                            {renderChart(questionData, i)}
                                            <div className="poll-report-view">
                                                <p className="poll-body-question">{i + 1}. {questionData?.pollQuestions}</p>
                                                {questionData.userPollDetails.map((answer, j) => (
                                                    <div className="pl-4 mb-2" key={j}>
                                                        <div className="poll-report-view-head">
                                                            {j + 1}. {answer?.answer || "Text Answer"}
                                                        </div>
                                                        {answer?.pollUsers?.length ? (
                                                            <div className="poll-report-body">
                                                                <ol className="pl-2">
                                                                    {answer.pollUsers.map((user, o) => (
                                                                        user?.userDisplayName && (
                                                                            <li key={o}>
                                                                                <div className="row">
                                                                                    <div className="col-4">
                                                                                    {user?.userDisplayName}
                                                                                    </div>
                                                                                    <div className="col-4">
                                                                                    {user?.mobile_number}
                                                                                    </div>
                                                                                    <div className="col-4">
                                                                                    {user?.textAnswer}
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        )
                                                                    ))}
                                                                </ol>
                                                            </div>
                                                        ) : (
                                                            <p className="poll-body-text">No one Answered Yet</p>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </li>
                            </ul>
                        ) : (
                            <div className="text-center">No Data Found</div>
                        )}
                    </div>
                </section>
            </Suspense>
            <Footer />
        </div>
    );
};

export default PollReport;
