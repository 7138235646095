import React, { Component } from 'react';
import Header from "../layout/header"
import Footer from "../layout/footer"
import $ from "jquery"
import tick from "../image/media/tick.png"
import avatar from "../image/media/avatar.png"

export default class ChatmessageGroup extends Component {
  componentDidMount(){
    $("#promoApplySection").hide()
    
  }
  havePromoCode(){
    $("#promoApplySection").show()
    $("#havePromoCode").hide();
  }

  render() {
    return (
      <div className="purpleWrap">
        <Header></Header>
        <div className="container container-auto">
          <div className="main-wrapper border-0 px-0 px-md-5">
            <div className="col-md-12 p-0">
            <ul className="breadcrumb">
              <li><a href="/doctor/dashboard">Dashboard</a><i className="fa fa-chevron-right"></i></li>
              <li><a >Old Chat Questions</a></li>
            </ul>
            </div>
            <div className="chat-outer">
              <div className="chat-header">
                <span className="chat-span-head">
                  <i className="fa fa-comment"></i> Old Chat Questions
                </span>
              </div>
              <div className="chat-bodygroup">

                <ul className="messages">
                  <li className="message mb-0">
                    <a href="/doctor/chatmessage" className="text-decoration-none">
                      <div className="avatar">
                        <img src={avatar} className="avatarimg"></img>
                      </div>
                      <div className="textwrappergroup">
                          <div className="text-header">
                            <h6 className="color-maintext">Dr. Paid Ortho Doc</h6>
                            <time className="message-time">  <i className="fa fa-clock-o"></i> 15 May 2020 06:05 PM</time>
                          </div>
                          <p className="text">chat with you soon.</p>
                      </div>
                    </a>
                  </li> 
                  <div className="divider"></div>
                  <li className="message mb-0">
                    <a href="/doctor/chatmessage" className="text-decoration-none">
                      <div className="avatar">
                        <img src={avatar} className="avatarimg"></img>
                      </div>
                      <div className="textwrappergroup">
                          <div className="text-header">
                            <h6 className="color-maintext">Dr. Paid Ortho Doc</h6>
                            <time className="message-time">  <i className="fa fa-clock-o"></i> 15 May 2020 06:05 PM</time>
                          </div>
                          <p className="text">chat with you soon.</p>
                      </div>
                    </a>
                  </li>
                  <div className="divider"></div>
                </ul>
              </div>
            </div>


          </div>
        </div>

        <Footer></Footer>
      </div>
    )
  }
}
