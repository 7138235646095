import React, { useEffect, Suspense } from "react";
import $ from "jquery";

const HospitalSpecialisation = ({ specializationIdEdit, specializationData }) => {
  useEffect(() => {
    if (specializationIdEdit.length > 0) {
      specializationIdEdit.forEach((item) => {
        $(
          "#chkSpecialisation" + item.specializationId
        ).prop("checked", true);
      });
    }
  }, [specializationIdEdit,specializationData]);

  return (
    <div className="purpleWrap bg-clr-wthHead" id="spc">
      <Suspense fallback={<div></div>}>
        <section>
          <div className="specializationWraper pb-5">
            <h2 className="hspInfoSubhead pb-3">
              Specialisation{" "}
              <span>(Choose atleast one Primary Specialisation)*</span>
            </h2>

            <div className="hspspecializationDiv">
              <div className="table-bordered-hspSpecl">
                <table className="table table-bordered-hspSpecl mainBx">
                  <tbody>
                    <tr>
                      {specializationData.map((specializationDataObj, s) => (
                        <td key={s} className="hosptable-tdinline">
                          <input
                            type="checkbox"
                            id={
                              "chkSpecialisation" +
                              specializationDataObj.specializationId
                            }
                          />

                          <div className="tdtxtLabel-hsp">
                            {specializationDataObj.specialityName}
                          </div>
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </Suspense>

      <Suspense fallback={<div></div>}></Suspense>
    </div>
  );
};

export default HospitalSpecialisation;
