import React, { Suspense, useEffect, useState } from "react";
import csv from "../image/icons/pdf.png";
import Pie from "./rh-pie-chart";
import HorizontalBar from "./rh-horizontal-bar";
import AppointmentConsult from "./rh-appointment-consult";
import BarLine from "./rh-bar-line";
import Header from "./header";
import Footer from "./footer";
import { awsAPIUrlcommonToken } from "../apiService";
// import RhaDashboardPdf from "./rh-dashboard-pdf"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import moment from "moment";

const TextChatReports = () => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear())
  const [startYear, setStartYear] = useState(2015)
  const [years, setYears] = useState([])
  const [startDate, setStartDate] = useState(new Date(currentYear, 0, 1))
  const [endDate, setEndDate] = useState("")
  const [isLoader, setIsLoader] = useState(true)
  const [specialityVsConsult, setSpecialityVsConsult] = useState({})
  const [doctorVsConsult, setDoctorVsConsult] = useState({})
  const [appointmentVsConsult, setAppointmentVsConsult] = useState({})
  const [monthVsConsult, setMonthVsConsult] = useState({})

  useEffect(() => {    
    YearsCount();
    fetchData();
  }, [])

  const YearsCount = () => {
    let yearsList = [];
    for (let year = startYear; year <= currentYear; year++) {
      yearsList.push(year);
    }
    setYears(yearsList)
  }
  const fetchData = () => {
    setIsLoader(true)
    Promise.all([
      SpecialityVsConsult(),
      GenerateDoctorVsConsult(),
      AppointmentVsConsult(),
      MonthVsConsult()
    ]).finally(() => 
    setTimeout(() => {
      setIsLoader(false)
    }, 1000));
  };
  const SpecialityVsConsult = () => {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "generateSpecialityVsConsultationReport",
        status: "Yes",
        fromYear: startDate ? moment(startDate).format("YYYY-MM-DD") : "",
        toYear: endDate ? moment(endDate).format("YYYY-MM-DD")  : ''
      })
    };
    // console.log("🚀 ~ SpecialityVsConsult ~ apiJson:", apiJson)
    awsAPIUrlcommonToken(apiJson).then((data) => {
      if (data.success === "1") {
        setSpecialityVsConsult(data.result[0]);
      }
      else {
        setSpecialityVsConsult({});
      }
    }).catch((error) => {
      console.error("Error in getting uploaded data:", error);
    });

  }
  const GenerateDoctorVsConsult = () => {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "generateDoctorVsConsultationReport",
        status: "Yes",
        fromYear: startDate ? moment(startDate).format("YYYY-MM-DD") : "",
        toYear: endDate ? moment(endDate).format("YYYY-MM-DD")  : ''
      })
    };
      // console.log("🚀 ~ GenerateDoctorVsConsult ~ apiJson:", apiJson)
    awsAPIUrlcommonToken(apiJson).then((data) => {
        // console.log("fileupload GenerateDoctorVsConsult---", data.result[0]);
      if (data.success === "1") {
        setDoctorVsConsult(data.result[0])
      }
      else {
        setDoctorVsConsult({});
      }
    }).catch((error) => {
      console.error("Error in getting uploaded data:", error);
    });

  }
  const AppointmentVsConsult = () => {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "generateAppointmentVsConsultationReport",
        status: "Yes",
        fromYear: startDate ? moment(startDate).format("YYYY-MM-DD") : "",
        toYear: endDate ? moment(endDate).format("YYYY-MM-DD")  : ''
      })
    };
      // console.log("🚀 ~ AppointmentVsConsult ~ apiJson:", apiJson)
    awsAPIUrlcommonToken(apiJson).then((data) => {
      if (data.success === "1") {
        setAppointmentVsConsult(data.result[0])
      }
      else {
        setAppointmentVsConsult({});
      }
    }).catch((error) => {
      console.error("Error in getting uploaded data:", error);
    });

  }
  const MonthVsConsult = () => {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "generateMonthWiseConsultationReport",
        status: "Yes",
        fromYear: startDate ? moment(startDate).format("YYYY-MM-DD") : "",
        toYear: endDate ? moment(endDate).format("YYYY-MM-DD")  : ''
      })
    };
      // console.log("🚀 ~ MonthVsConsult ~ apiJson:", apiJson)
    awsAPIUrlcommonToken(apiJson).then((data) => {
        //  console.log("fileupload MonthVsConsultGraph---", data.result[0]);
      if (data.success === "1") {
        setMonthVsConsult(data.result[0]);
      }
      else {
        setMonthVsConsult({});
      }
    }).catch((error) => {
      console.error("Error in getting uploaded data:", error);
    });

  }
  
  const HighToLowSort = () => {
    // Sort by consultationCount in descending order
    const sortedDetails = [...specialityVsConsult?.specialityConsultationDetails].sort(
      (a, b) => b.consultationCount - a.consultationCount
    );
    SpecialityConsultSortFn(sortedDetails)
    
    const sortedDocData = [...doctorVsConsult?.doctorConsultationDetails].sort(
      (a, b) => b.consultationCount - a.consultationCount
    );
    DocConsultSortFn(sortedDocData)
    
    const sortedAppointmentData = [...appointmentVsConsult?.appointmentConsultationDetails].sort(
      (a, b) => b.consultationCount - a.consultationCount
    );
    AppointmentConsultSortFn(sortedAppointmentData)
    
    const sortedMonthData = [...monthVsConsult?.monthlyConsultationDetails].sort(
      (a, b) => b.total - a.total
    );
    MonthConsultSortFn(sortedMonthData)
  }
  const LowToHighSort = () => {
    // Sort by consultationCount in ascending order
    const sortedDetails = [...specialityVsConsult?.specialityConsultationDetails].sort(
      (a, b) => a.consultationCount - b.consultationCount // Change for ascending order
    );
    SpecialityConsultSortFn(sortedDetails)
    
    const sortedDocData = [...doctorVsConsult?.doctorConsultationDetails].sort(
      (a, b) => a.consultationCount - b.consultationCount
    );
    DocConsultSortFn(sortedDocData)

    const sortedAppointmentData = [...appointmentVsConsult?.appointmentConsultationDetails].sort(
      (a, b) => a.consultationCount - b.consultationCount
    );
    AppointmentConsultSortFn(sortedAppointmentData)
    
    const sortedMonthData = [...monthVsConsult?.monthlyConsultationDetails].sort(
      (a, b) => a.total - b.total
    );
    MonthConsultSortFn(sortedMonthData)
  }
  const alphabeticalSort = () => {
    // Sort specialityConsultationDetails by Speciality in ascending order (alphabetical)
    const sortedDetails = [...specialityVsConsult?.specialityConsultationDetails].sort((a, b) => 
      a.Speciality.localeCompare(b.Speciality)
    );
    SpecialityConsultSortFn(sortedDetails)
    
    const sortedDocData = [...doctorVsConsult?.doctorConsultationDetails].sort(
      (a, b) => a.doctorName.localeCompare(b.doctorName)
    );
    DocConsultSortFn(sortedDocData)

    const sortedAppointmentData = [...appointmentVsConsult?.appointmentConsultationDetails].sort(
      (a, b) => a.Service.localeCompare(b.Service)
    );
    AppointmentConsultSortFn(sortedAppointmentData)
    
    MonthVsConsult()
  };
  
  const SpecialityConsultSortFn = (sortedDetails) => {
    const sortedGraphData = sortedDetails.map(detail => {
      const index = specialityVsConsult.graphData.label.indexOf(detail.Speciality);
      return {
        label: detail.Speciality,
        series: specialityVsConsult?.graphData.series[index]
      };
    });
    // Update state with sorted specialityVsConsult
    setSpecialityVsConsult({
      ...specialityVsConsult,
      specialityConsultationDetails: sortedDetails,
      graphData: {
        series: sortedGraphData.map(item => item.series),
        label: sortedGraphData.map(item => item.label)
      }
    });
  }
  const DocConsultSortFn = (sortedDocData) => {
    // Sorting graphData accordingly
    const sortedDoctorGraphData = sortedDocData.map(detail => {
      const index = doctorVsConsult.graphData.label.indexOf(detail.doctorName);
      return {
        label: detail.doctorName,
        series: doctorVsConsult?.graphData.series[index]
      };
    });
    // Update state with sorted specialityVsConsult
    setDoctorVsConsult({
      ...doctorVsConsult,
      doctorConsultationDetails: sortedDocData,
      graphData: {
        series: sortedDoctorGraphData.map(item => item.series),
        label: sortedDoctorGraphData.map(item => item.label)
      }
    });
  }
  const AppointmentConsultSortFn = (sortedAppointmentData) => {
    // Sorting graphData accordingly
    const sortedGraphData = sortedAppointmentData.map(detail => {
      const index = appointmentVsConsult.graphData.label.indexOf(detail.Service);
      return {
        label: detail.Service,
        series: appointmentVsConsult?.graphData.series[index]
      };
    });
    // Update state with sorted specialityVsConsult
    setAppointmentVsConsult({
      ...appointmentVsConsult,
      appointmentConsultationDetails: sortedAppointmentData,
      graphData: {
        series: sortedGraphData.map(item => item.series),
        label: sortedGraphData.map(item => item.label)
      }
    });
  }
  const MonthConsultSortFn = (sortedMonthData) => {
    const sortedGraphData = {
      series: monthVsConsult.graphData.series.map((seriesObj) => {
        return {
          ...seriesObj,
          data: sortedMonthData.map(detail => {
            const index = monthVsConsult.graphData.label.indexOf(detail.monthConsult);
            return seriesObj.data[index];
          })
        };
      }),
      label: sortedMonthData.map(detail => detail.monthConsult)

    }
    setMonthVsConsult( {
      ...monthVsConsult,
      monthlyConsultationDetails: sortedMonthData,
      graphData: sortedGraphData
    });
  }

  const handleSearch = () => {
    if ((startDate && !endDate) || (endDate.getTime() >= startDate.getTime())) {
      fetchData()
      
    } else {
      toast.warning("Please ensure the 'to date' is greater than the 'from date.")
    }
  }
  const sortOrderFn = (event) => {
    if(event.target.value === 'highest-to-lowest') {
      
      HighToLowSort()
    }
    else if(event.target.value === 'lowest-to-highest') {
      LowToHighSort()
    }
    else{
      alphabeticalSort()
    }
  }
  const handleDownloadPDF = async () => {
    const doc = new jsPDF("p", "mm", "a4");
    const pageHeight = doc.internal.pageSize.height;
    const margin = 10;
    let cursorY = margin;
  
    // Function to add a graph to the PDF
    const addGraphToPDF = async (doc, element, positionY) => {
      const canvas = await html2canvas(element);
      const imgData = canvas.toDataURL("image/png");
      const imgWidth = 190; // A4 page width minus margins
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
  
      if (positionY + imgHeight > pageHeight - margin) {
        doc.addPage();
        positionY = margin; // Reset Y position for new page
      }
  
      doc.addImage(imgData, "PNG", margin, positionY, imgWidth, imgHeight);
      return positionY + imgHeight + 10; // Return the new positionY
    };
  
    // Function to add a table to the PDF with proper alignment and borders
    const addTableToPDF = (doc, tableElement, positionY) => {
      const tableHeaders = Array.from(tableElement.querySelectorAll("thead tr th")).map((th) => th.innerText);
      const tableRows = Array.from(tableElement.querySelectorAll("tbody tr")).map((row) => {
        return Array.from(row.querySelectorAll("td")).map((td) => td.innerText);
      });
  
      doc.setFontSize(12);
      const startX = margin;
      const firstColumnWidth = 60; // Set width for the first column
      const subsequentColumnWidth = 40; // Width for the subsequent columns
      const rowHeight = 10; // Height for each row
      const headerHeight = 10; // Height for headers
      let startY = positionY;
      doc.setDrawColor(222, 226, 230);
  
      // Print table headers with proper alignment
      const printHeaders = () => {
        tableHeaders.forEach((header, i) => {
          const xPosition = i === 0 ? startX : startX + firstColumnWidth + (i - 1) * subsequentColumnWidth;
          const headerX = i === 0 ? startX + 2 : xPosition + 2; // Slight padding for header text
          doc.setFillColor(200, 200, 200); // Light gray background for headers
          doc.rect(xPosition, startY, i === 0 ? firstColumnWidth : subsequentColumnWidth, headerHeight, 'F'); // Fill rectangle for header background
          doc.text(header, headerX, startY + 7); // Proper vertical centering
        });
      };
  
      // Draw table header
      printHeaders();
      startY += headerHeight; // Increase startY after headers
  
      // Print table rows with alignment
      tableRows.forEach((row) => {
        if (startY + rowHeight > pageHeight - margin) {
          doc.addPage();
          startY = margin; // Reset Y position for new page
          printHeaders(); // Print headers again on the new page
          startY += headerHeight; // Increase startY after headers
        }
  
        row.forEach((cell, i) => {
          const xPosition = i === 0 ? startX : startX + firstColumnWidth + (i - 1) * subsequentColumnWidth;
          const cellX = i === 0 ? startX + 2 : xPosition + 2; // Padding for cell text
          doc.rect(xPosition, startY, i === 0 ? firstColumnWidth : subsequentColumnWidth, rowHeight); // Draw border for the cell
          doc.text(cell, cellX, startY + 7); // Proper vertical centering for cell text
        });
        startY += rowHeight; // Move down for the next row
      });
  
      return startY; // Return the new positionY
    };
  
    // Function to generate the PDF with each table and graph on its own page
    const generatePDF = async () => {
      // Add main heading "RGI Dashboard"
      doc.setFontSize(20);
      doc.text("RGI Report", margin, cursorY); // Add heading at the top
      cursorY += 10; // Add some space after the heading
  
      const sections = [
        { label: "Speciality vs No. of Consults", tableSelector: "#specialityTable", graphSelector: "#specialityGraph" },
        { label: "Doctors vs No. of Consults", tableSelector: "#doctorTable", graphSelector: "#doctorGraph" },
        { label: "Type of Consults", tableSelector: "#appointmentTable", graphSelector: "#appointmentGraph" },
        { label: "Month vs Type of Consults", tableSelector: "#monthTable", graphSelector: "#monthGraph" },
      ];
  
      for (const section of sections) {
        // Add the section label
        doc.setFontSize(16);
        doc.text(section.label, margin, cursorY);
        cursorY += 5;
  
        // Add the table for this section
        const tableElement = document.querySelector(section.tableSelector);
        if (tableElement) {
          cursorY = addTableToPDF(doc, tableElement, cursorY);
          cursorY += 10; // Add some space after the table
        }
  
        // Add the graph for this section
        const graphElement = document.querySelector(section.graphSelector);
        if (graphElement) {
          cursorY = await addGraphToPDF(doc, graphElement, cursorY);
          cursorY += 10; // Add some space after the graph
        }
  
        // After each table and graph, add a new page for the next section
        if (sections.indexOf(section) !== sections.length - 1) {
          doc.addPage(); // Add a new page before the next section starts
          cursorY = margin; // Reset cursor for the next section
        }
      }
  
      doc.save("report.pdf");
    };
  
    generatePDF();
  };
  


  return (
    <div className="purpleWrap">
      <ToastContainer />
      <Suspense fallback={<div>Waiting...</div>}>
        <section>
          <Header />
          <div className="container">
            <div className="main-wrapper1">
              <div className="col-md-12 p-0">
                <ul className="breadcrumb">
                  <li>
                    <a href={"/reliance/dashboard"}>Dashboard</a>
                    <i className="fa fa-chevron-right"></i>
                  </li>
                  <li>RHA Dashboard</li>
                </ul>
              </div>
              <div className="row align-items-center justify-content-between">
                <div className="col-xl-3 col-lg-2 col-md-12  mb-2 mb-lg-0">
                  <div className="form-head border-0 mb-0 pb-0">RHA Dashboard</div>
                </div>
                <div className="col-xl-5 col-lg-5 col-md-9">
                  <div className="row justify-content-center">
                    <div className="col-md-4 col-6 text-right mb-3 mb-md-0 ml-lg-4">
                      {/* <select className="input-design py-2 mb-0" value={startDate}
                        onChange={(e) => { setStartDate(e.target.value) }} >
                        <option value="">- - From Year - -</option>
                        {years.map((year, index) => (
                          <option value={year} key={index}>{year}</option>
                        ))}
                      </select> */}
                      
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        className="input-design py-2 mb-0"
                        yearDropdownItemNumber={5}
                        scrollableYearDropdown={true}
                        placeholderText="From Date"
                        showYearDropdown
                        showMonthDropdown
                        dateFormat="yyyy-MM-dd"
                    />
                    </div>
                    <div className="col-md-4 col-6 text-right">
                      
                      <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        className="input-design py-2 mb-0"
                        yearDropdownItemNumber={5}
                        scrollableYearDropdown={true}
                        placeholderText="End Date"
                        showYearDropdown
                        showMonthDropdown
                        dateFormat="yyyy-MM-dd"
                        maxDate={new Date()}
                    />
                    </div>
                    <div className="col-md-3 col-12 search-top">
                      <input type="submit" value="Search"
                        className="formButton mt-3 m-md-0 m-lg-0 formButtonsearch-width"
                        onClick={handleSearch} />
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-3 text-right mt-lg-0 col-12">
                  <a className="file-upload ml-2 py-2" href="/reliance/rha-fileupload">File Upload</a>
                </div>
              </div>
              <hr></hr>
              <div className="row justify-content-end mx-0">
                 <div className="sorting-wrapper">
                  <select className="input-design bg-light py-2 mb-0 mr-3"
                    onChange={sortOrderFn} style={{width:"170px"}}>
                      <option value="">- - Sorting - -</option>
                      <option value="highest-to-lowest">Highest to lowest</option>
                      <option value="lowest-to-highest">Lowest to highest</option>
                      <option value="alphabetical-order">Alphabetical order</option>
                    </select>
                    <a className="cursor-pointer">
                        <img src={csv} 
                        onClick={()=> handleDownloadPDF()} 
                        alt="Pdf download" width="25" title="Pdf download" className="mt-1" />
                    </a>
                 </div>
              </div>
              <div className="row"  id="specialityTable">
                <div className="main-head col-12 mx-0">Speciality vs No. of Consults</div>
                {((specialityVsConsult?.specialityConsultationDetails?.length > 0) && !isLoader) ?
                  <>
                    <div className="col-12 col-lg-5 col-md-12">
                      <div className="table-space">
                        <div className="table-responsive table-respons-scroll"
                          style={{
                            maxHeight: specialityVsConsult.specialityConsultationDetails.length > 25
                              ? '614px'
                              : specialityVsConsult.specialityConsultationDetails.length > 10
                                ? '550px'
                                : '350px'
                          }}
                        >
                          <table className="table video_audio_table table-bordered mb-0">
                            <thead>
                              <tr>
                                <th>Speciality Name</th>
                                <th>Consultation Count</th>
                                <th>%</th>
                              </tr>
                            </thead>
                            <tbody style={{ fontSize: "12px" }} className="tableHeight">
                              {(specialityVsConsult?.specialityConsultationDetails.length > 0 && !isLoader) && (
                                <>
                                {specialityVsConsult?.specialityConsultationDetails?.map((row, index) => (
                                  <tr key={index}>
                                    <td>{row.Speciality}</td>
                                    <td>{row.consultationCount}</td>
                                    <td>{row.percentage}</td>
                                  </tr>
                                ))}
                              <tr>
                                <td>Grand Total </td>
                                <td>{specialityVsConsult?.grandTotal}</td> 
                                <td></td>
                              </tr>
                              </>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-7 col-md-12"  id="specialityGraph">
                      <div className="graph-outer">
                        <div className="form-head text-center">Speciality vs No. of Consults</div>
                        {(specialityVsConsult?.graphData && !isLoader) && <Pie key={specialityVsConsult?.graphData} specialityVsConsultGraph={specialityVsConsult?.graphData} />}
                      </div>
                    </div>
                  </>
                  :
                  <div className="col-12">
                    <div className="no-data-div text-danger">
                    <small>
                      {startDate && endDate
                        ? `Data for the years ${startDate instanceof Date ? moment(startDate).format("YYYY-MM-DD") : ''} to ${endDate instanceof Date ? moment(endDate).format("YYYY-MM-DD") : ''} is currently unavailable.`
                        : `Data for the year ${startDate instanceof Date ? moment(endDate).format("YYYY-MM-DD") : endDate instanceof Date ? moment(endDate).format("YYYY-MM-DD") : ''} is currently unavailable.`}
                    </small>
                    </div>
                  </div>
                }
              </div>
              <div className="row my-4" id="doctorTable">
                <div className="main-head col-12 mx-0">Doctors vs No. of Consults</div>
                {((doctorVsConsult?.doctorConsultationDetails?.length > 0)  && !isLoader)?
                  <>
                    <div className="col-12 col-lg-5 col-md-12">
                      <div className="table-space">
                        <div
                          className="table-responsive table-respons-scroll"
                          style={{
                            maxHeight: doctorVsConsult?.doctorConsultationDetails?.length > 25
                              ? '825px'
                              : doctorVsConsult?.doctorConsultationDetails?.length > 10
                                ? '550px'
                                : '350px'
                          }}
                        >
                          <table className="table video_audio_table table-bordered mb-0">
                            <thead>
                              <tr>
                                <th>Doctor Name</th>
                                <th>Consultation Count</th>
                                <th>%</th>
                              </tr>
                            </thead>
                            <tbody style={{ fontSize: "12px" }} className="tableHeight">
                              {doctorVsConsult?.doctorConsultationDetails?.length > 0 && (
                                <>
                                {doctorVsConsult?.doctorConsultationDetails?.map((list, index) => (
                                  <tr key={index}>
                                    <td>{list.doctorName}</td>
                                    <td>{list.consultationCount}</td>
                                    <td>{list.percentage}</td>
                                  </tr>
                                ))}
                                <tr>
                                  <td>Grand Total </td>
                                  <td>{doctorVsConsult?.grandTotal}</td>
                                  <td></td>
                                </tr>
                                </>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-7 col-md-12" id="doctorGraph">
                      <div className="graph-outer">
                        <div className="form-head text-center">Doctors vs No. of Consults</div>
                        {doctorVsConsult?.graphData && <HorizontalBar key={doctorVsConsult?.graphData} generateDoctorVsConsultGraph={doctorVsConsult?.graphData} />}
                      </div>
                    </div>
                  </>
                  :
                  <div className="col-12">
                    <div className="no-data-div text-danger">
                    <small>
                      {startDate && endDate
                        ? `Data for the years ${startDate instanceof Date ? moment(startDate).format("YYYY-MM-DD") : ''} to ${endDate instanceof Date ? moment(endDate).format("YYYY-MM-DD") : ''} is currently unavailable.`
                        : `Data for the year ${startDate instanceof Date ? moment(endDate).format("YYYY-MM-DD") : endDate instanceof Date ? moment(endDate).format("YYYY-MM-DD") : ''} is currently unavailable.`}
                    </small>
                    </div>
                  </div>
                }
              </div>
              <div className="row my-4" id="appointmentTable">
                <div className="main-head col-12 mx-0">Type of Consults</div>
                {((appointmentVsConsult?.appointmentConsultationDetails?.length > 0)  && !isLoader)?
                  <>
                    <div className="col-12 col-lg-5 col-md-12">
                      <div className="table-space">
                        <div className="table-responsive table-respons-scroll"
                          style={{
                            maxHeight: appointmentVsConsult?.appointmentConsultationDetails?.length > 25
                              ? '614px'
                              : appointmentVsConsult?.appointmentConsultationDetails?.length > 10
                                ? '550px'
                                : '350px'
                          }}
                        >
                          <table className="table video_audio_table table-bordered mb-0">
                            <thead>
                              <tr>
                                <th>Service Name</th>
                                <th>Consultation Count</th>
                                <th>%</th>
                              </tr>
                            </thead>
                            <tbody style={{ fontSize: "12px" }} className="tableHeight">
                              {appointmentVsConsult?.appointmentConsultationDetails?.length > 0 && (
                                <>
                                {appointmentVsConsult?.appointmentConsultationDetails?.map((list, index) => (
                                  <tr key={index}>
                                    <td>{list.Service}</td>
                                    <td>{list.consultationCount}</td>
                                    <td>{list.percentage}</td>
                                  </tr>
                                ))}
                                <tr>
                                  <td>Grand Total</td>
                                  <td>{appointmentVsConsult?.grandTotal}</td>
                                  <td></td>
                                </tr>
                                </>
                                )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-7 col-md-12" id="appointmentGraph">
                      <div className="graph-outer">
                        <div className="form-head text-center">Type of Consults</div>
                        {appointmentVsConsult?.graphData && <AppointmentConsult key={appointmentVsConsult?.graphData} appointmentVsConsultGraph={appointmentVsConsult?.graphData} />}
                      </div>
                    </div>
                  </>
                  :
                  <div className="col-12">
                    <div className="no-data-div text-danger">
                    <small>
                      {startDate && endDate
                        ? `Data for the years ${startDate instanceof Date ? moment(startDate).format("YYYY-MM-DD") : ''} to ${endDate instanceof Date ? moment(endDate).format("YYYY-MM-DD") : ''} is currently unavailable.`
                        : `Data for the year ${startDate instanceof Date ? moment(endDate).format("YYYY-MM-DD") : endDate instanceof Date ? moment(endDate).format("YYYY-MM-DD") : ''} is currently unavailable.`}
                    </small>
                    </div>
                  </div>
                }

              </div>
              <div className="row my-4" id="monthTable">
                <div className="main-head col-12 mx-0">Month vs Type of Consults</div>
                {((monthVsConsult?.monthlyConsultationDetails?.length > 0)  && !isLoader) ?
                  <>
                    <div className="col-12 col-lg-5 col-md-12">
                      <div className="table-space">
                        <div className="table-responsive  table-respons-scroll"
                          style={{
                            maxHeight: monthVsConsult?.monthlyConsultationDetails?.length > 25
                              ? '425px'
                              : monthVsConsult?.monthlyConsultationDetails?.length > 10
                                ? '550px'
                                : '350px'
                          }}
                        >
                          <table className="table video_audio_table table-bordered mb-0">
                            <thead>
                              <tr>
                                <th style={{minWidth:"40px"}}>Month Consult</th>
                                <th style={{minWidth:"40px"}}>Text chat</th>
                                <th style={{minWidth:"40px"}}>Video chat</th>
                                <th style={{minWidth:"40px"}}>Grand Total</th>
                              </tr>
                            </thead>
                            <tbody style={{ fontSize: "12px" }} className="tableHeight">

                              {monthVsConsult?.monthlyConsultationDetails?.length > 0 && (
                                <>
                                {monthVsConsult?.monthlyConsultationDetails?.map((list, index) => (
                                  <tr key={index}>
                                    <td>{list.monthConsult}</td>
                                    <td>{list.textchat}</td>
                                    <td>{list.videochat}</td>
                                    <td>{list.total}</td>
                                  </tr>
                                ))}
                                <tr>
                                  <td>Grand Total </td>
                                  <td>{monthVsConsult?.grandTotal[0]?.textchat}</td>
                                  <td>{monthVsConsult?.grandTotal[0]?.videochat}</td>
                                  <td>{monthVsConsult?.grandTotal[0]?.grandTotal}</td>
                                </tr>
                                </>
                                )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-7 col-md-12" id="monthGraph">
                      <div className="graph-outer">
                        <div className="form-head text-center">Month vs Type of Consults</div>
                        {monthVsConsult?.graphData && <BarLine key={monthVsConsult?.graphData} monthVsConsultGraph={monthVsConsult?.graphData} />}   
                        </div>
                    </div>
                  </>
                  :
                  <div className="col-12">
                    <div className="no-data-div text-danger">
                    <small>
                      {startDate && endDate
                        ? `Data for the years ${startDate instanceof Date ? moment(startDate).format("YYYY-MM-DD") : ''} to ${endDate instanceof Date ? moment(endDate).format("YYYY-MM-DD") : ''} is currently unavailable.`
                        : `Data for the year ${startDate instanceof Date ? moment(endDate).format("YYYY-MM-DD") : endDate instanceof Date ? moment(endDate).format("YYYY-MM-DD") : ''} is currently unavailable.`}
                    </small>
                    </div>
                  </div>
                }
              </div>
            </div>
            {/* {(specialityVsConsult && doctorVsConsult && appointmentVsConsult && monthVsConsult)?
              <RhaDashboardPdf className="pdf-section" specialityVsConsult={specialityVsConsult}
              doctorVsConsult={doctorVsConsult} appointmentVsConsult={appointmentVsConsult}
              monthVsConsult={monthVsConsult}/>
              :""
            } */}
            {isLoader &&
              <div className="loader d-block"></div>
            }
          </div>
          <Footer />
        </section>
      </Suspense>
    </div>
  );
};
export default TextChatReports;
