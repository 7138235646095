import React, { Component } from 'react';
import Ser1 from "../image/theme/digital--branding.png"
import Ser2 from "../image/theme/Local-presence-management.png"
import Ser3 from "../image/theme/Digital-marketing.png"
import Rightarrow from "../image/theme/arrow_forward_purple.png"
import { Link } from "react-router-dom";
export default class HomeOurSolutions extends Component {

    render() {
        return (
            <div className="ph-main-outerdiv mb-4 mb-md-0 " id="solution-component">
                <div className='container-lg'>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="ph-db-banner">
                                <h2 className="ph-main-title-2 mt-5">
                                    Our Solutions
                                </h2>
                            </div>
                        </div>
                        <Link to="/digital-branding" className="col-lg-4 card mb-4 ph-card solution-cards">
                            <div className="ph-service-img">
                                <img src={Ser1} alt="Ser1" width="400" height="148" className='img-fluid' />
                            </div>                      
                            <div className="card-body ph-card-body">
                                <h4 className="ph-card-service-title">Digital Branding</h4>
                                <p className="card-text">Establish a strong and credible online presence with our digital branding tools and make a lasting impression on both existing and potential patients.  </p>
                                <div className='ph-card-keywords'>
                                    <span className='ph-keywords ph-light-pink'>
                                        Website Branding
                                    </span>
                                    <span className='ph-keywords ph-light-green'>
                                        PH Practitioner: CMS
                                    </span>
                                    <span className='ph-keywords ph-grey'>
                                        Telehealth Services
                                    </span>
                                    <span className='ph-keywords ph-dark-pink'>
                                        Brand Strategy
                                    </span>
                                    <span className='ph-keywords ph-light-green'>
                                        Content Creation
                                    </span>
                                </div>
                                <div className='ph-bottom-btn'>
                                    <Link to="/digital-branding" className='ph-explore-now ph-explore-now-brdr'>
                                        Explore Now
                                        <span>
                                            <img src={Rightarrow} alt="Rightarrow" className='img-fluid' width="16" height="16" />
                                        </span>
                                    </Link>
                                </div>
                            </div>
                            
                            </Link>
                       
                        <Link to="/local-presence-management" className="col-lg-4 mb-4 ph-card solution-cards">
                                <div className="ph-service-img">
                                    <img src={Ser2} alt="Ser1" width="400" height="148" className='img-fluid' />
                                </div>
                             
                                <div className="card-body ph-card-body">
                                    <h4 className="ph-card-service-title">Local Presence Management</h4>
                                    <p className="card-text">Drive more online traffic across various digital and social platforms by leveraging the power of hyperlocal management.</p>
                                    <div className='ph-card-keywords'>
                                        <span className='ph-keywords ph-light-pink'>
                                            Local Listing Management
                                        </span>
                                        <span className='ph-keywords ph-dark-pink'>
                                            Local Search
                                        </span>
                                        <span className='ph-keywords ph-grey'>
                                            Online Reputation Management
                                        </span>
                                        <span className='ph-keywords ph-light-green'>
                                            Clinic Locator & Location Pages
                                        </span>
                                    </div>
                                    <div className='ph-bottom-btn'>
                                        <Link to="/local-presence-management" className='ph-explore-now ph-explore-now-brdr'>
                                            Explore Now
                                            <span>
                                                <img src={Rightarrow} alt="Rightarrow" className='img-fluid' width="16" height="16" />
                                            </span>
                                        </Link>
                                    </div>
                                </div>                               
                            
                            </Link>
                    
                       
                            <Link to="/digital-marketing" className="col-lg-4 card mb-4 ph-card solution-cards">
                                <div className="ph-service-img">
                                    <img src={Ser3} alt="Ser1" width="400" height="148" className='img-fluid' />
                                </div>
                                <div className="card-body ph-card-body">
                                    <h4 className="ph-card-service-title">Digital Marketing</h4>
                                    <p className="card-text">Boost your online presence with digital marketing strategies, content planning, language localisation across digital platforms, and more.</p>
                                    <div className='ph-card-keywords'>
                                        <span className='ph-keywords ph-light-pink'>
                                            Paid Media
                                        </span>
                                        <span className='ph-keywords ph-light-green'>
                                            Search Engine Marketing
                                        </span>
                                        <span className='ph-keywords ph-grey'>
                                            Strategic Business Insights
                                        </span>
                                        <span className='ph-keywords ph-dark-pink'>
                                            Social Media
                                        </span>

                                    </div>
                                    <div className='ph-bottom-btn'>
                                        <Link to="/digital-marketing" className='ph-explore-now ph-explore-now-brdr'>
                                            Explore Now
                                            <span>
                                                <img src={Rightarrow} alt="Rightarrow" className='img-fluid' width="16" height="16" />
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                            </Link>
                        
                    </div>
                </div>
            </div>
        )
    }
}
