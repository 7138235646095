import React, { Component } from 'react';
import Header from "../layout/header"
import Footer from "../layout/footer"
import Dashboardleftmenu from "./dashboardleftmenu"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import MemberDetail from "./member-detail"
import EducationalDetails from "./educational-details"
import LanguagesKnown from "./languages-known"
import HospitalDetails from "./hospital-details"
import ProfessionalDetail from "./professional-details"
import SpecialityDetail from "./speciality-details"
import ExperienceDetail from "./experience-details"
import Achievements from "./achievements"
import DoctorImage from "./doctor-image"


export default class Doctorprofile extends Component {
 
  render() {
    return (
      <div className="purpleWrap">
        <Header></Header>
        <div className="container container-auto">
          <div className="main-wrapper1">
              <div className="row my-2">
                <div className="col-lg-4">
                  <Dashboardleftmenu></Dashboardleftmenu>
                </div>
                <div className="col-lg-8">
                  <div className="dashboard-right dashboard-rightBg">
                    <div className="col-sm-12">
                      <h5>PERSONAL DETAILS</h5>
                    </div>
                    <Accordion>
                        <AccordionItem >
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                  Member Details
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <MemberDetail></MemberDetail>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                  Educational Details
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <EducationalDetails></EducationalDetails>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                  Languages Known 
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <LanguagesKnown></LanguagesKnown>
                            </AccordionItemPanel>
                        </AccordionItem>
                        
                        <AccordionItem>
                          <AccordionItemHeading>
                              <AccordionItemButton>
                                Your Hospital Details
                              </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <HospitalDetails></HospitalDetails>
                          </AccordionItemPanel>
                        </AccordionItem>
                        
                        <AccordionItem>
                          <AccordionItemHeading>
                              <AccordionItemButton>
                              Professional Details
                              </AccordionItemButton>
                          </AccordionItemHeading> 
                          <AccordionItemPanel>
                              <ProfessionalDetail></ProfessionalDetail>
                          </AccordionItemPanel>
                        </AccordionItem>
                        
                        <AccordionItem>
                          <AccordionItemHeading>
                              <AccordionItemButton>
                              Speciality Details
                              </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                              <SpecialityDetail></SpecialityDetail>
                          </AccordionItemPanel>
                        </AccordionItem>
                      
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                Experience Details
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <ExperienceDetail></ExperienceDetail>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                          <AccordionItemHeading>
                              <AccordionItemButton>
                              Achievements
                              </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                              <Achievements></Achievements>
                          </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                          <AccordionItemHeading>
                              <AccordionItemButton>
                              Doctor Image
                              </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <DoctorImage></DoctorImage>
                          </AccordionItemPanel>
                        </AccordionItem>
                                                
                    </Accordion>
                  </div>
                </div>
              </div>
           </div>
        </div>

        <Footer></Footer>
      </div>
    )
  }
}
