import React from "react";
import upload from "../image/media/upload.png";
import WhatsAppImg01 from '../image/whatsapp.png'
const whatsappimg = "https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/image/dentaldesigns/whatsapp.png";
const WhatsApp = ({path}) => {
  return (
    <a href="https://wa.me/message/G5U5R3OS7UDKJ1" target="_blank" className="ph-whatsapp-icon">
      <div className="whatsapp">
        <img src={WhatsAppImg01} width="30" height="30px" onError={(e) => {
          e.target.onerror = null;
          e.target.src = upload;
        }} alt="Whatsapp Icon"></img>
        <span className="ml-1 mr-1">WhatsApp</span>
      </div>
    </a>
  );
}
export default WhatsApp;