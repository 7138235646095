import React, { Component } from 'react';
import shapeOrange from "../image/theme/shape-orange.png"
import shapeBlue from "../image/theme/shape-blue.png"
import shapeGreen from "../image/theme/shape-green.png"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export default class HomeProvenResults extends Component {

    render() {
        var settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                  breakpoint: 991,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                },
            ]
        };
        return (
            <div className="ph-proven-result-wrapper mt-5">
                <div className='container-lg'>
                    <div className="col-lg-12 px-0">
                        <div className="ph-db-banner">
                            <h2 className="ph-main-title-2 mt-4">
                                Proven Results
                            </h2>

                            <Slider {...settings} className='row'>
                                <div className=''>
                                    <div className="col-lg-12 ph-proven-result-list">
                                        <div className='ph-proven-result-sublist'>
                                            <div className='ph-proven-result-image'>
                                                <img className="ph-proven-result-img img-fluid" src={shapeOrange} alt="Generic placeholder image" width="140" height="140" />
                                                <p className='ph-proven-result-count'>76%</p>
                                            </div>
                                            <div className='ph-proven-result-content'>
                                                <h3 className='ph-proven-title mb-3'>Increase in Local Searches Leading to More Walk-Ins</h3>
                                                <p className='lead-sm'>
                                                Optimized local search that enhanced the clinic's visibility to nearby patients actively seeking relevant solutions or services.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="col-lg-12 ph-proven-result-list">
                                        <div className='ph-proven-result-sublist'>
                                            <div className='ph-proven-result-image'>
                                                <img className="ph-proven-result-img img-fluid" src={shapeBlue} alt="Generic placeholder image" width="140" height="140" />
                                                <p className='ph-proven-result-count'>3X</p>
                                            </div>
                                            <div className='ph-proven-result-content'>
                                                <h3 className='ph-proven-title mb-3'>More Google Actions for Unmatched Engagement</h3>
                                                <p className='lead-sm'>With more actions and clicks on Google we improved online visibility to drive more traffic to the website. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="col-lg-12 ph-proven-result-list">
                                        <div className='ph-proven-result-sublist'>
                                            <div className='ph-proven-result-image'>
                                                <img className="ph-proven-result-img img-fluid" src={shapeGreen} alt="Generic placeholder image" width="140" height="140" />
                                                <p className='ph-proven-result-count'>1st</p>
                                            </div>
                                            <div className='ph-proven-result-content'>
                                                <h3 className='ph-proven-title mb-3'><span className='ph-proven-span'>Rank on Google Search</span></h3>
                                                <p className='lead-sm'>Leveraged  advanced search engine optimization to move SERP ranking  from page 3 to page 1.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
