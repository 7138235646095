import React, { useEffect, useState } from "react";
import $ from "jquery";
import { apiCalling } from "../../apiService";
import Close from "../../image/icons/close-mark.png";
import * as XLSX from 'xlsx';
import csv from "../../image/icons/csv.png";

const Analisationpopup = (props) => {
  const [getingAnalyticsDetails, setGetingAnalyticsDetails] = useState([]);
  const [messegeTitle, setMessegeTitle] = useState();
  useEffect(() => {
    if (props && props.PopupnewsFeedDownloadType && props.PopupnewsFeedDownloadType === "Messege") {
      setMessegeTitle(props.PopupnewsFeedDownloadDataId.messageTitle)
      getPopUpDetails(props.PopupnewsFeedDownloadDataId.messageId, props.PopupnewsFeedDownloadDataId.analyticsType);
    } else if (props && props.PopupnewsFeedDownloadType && props.PopupnewsFeedDownloadType !== "Messege") {
      setGetingAnalyticsDetails(props.PopupnewsFeedDownloadDataId)
      setMessegeTitle(props.PopupnewsFeedDownloadType)
    }
  }, [props.PopupnewsFeedDownloadDataId.analyticsType]);

  const getPopUpDetails = (messageId, type) => {
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        "functionName": "getAnalyticsDetailsByTypeId",
        "messageId": messageId,
        "type": type
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        $(".loader").hide();
        setGetingAnalyticsDetails(data.result);
      }else{cancelPopup()
        $(".loader").hide();
      }
    }).catch((error) => {
      console.error("Error sending notification:", error);
      $(".loader").hide()
      // alert("An error occurred while Getting Details");
  });
  };

  const cancelPopup = () => {
    $("#analisePopup").hide();
  };

  const exportToExcel = () => {
    if(getingAnalyticsDetails.length>0){
    const title = messegeTitle ? messegeTitle : ""; // Ensure messegeTitle is defined
    const data = [
        [title], // Add title as the first row
        ["Created Date", "User Name"],
        ...getingAnalyticsDetails.map(item => [item.createdDate, item.userName])
    ];
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1"); // Add the worksheet to the workbook
    XLSX.writeFile(wb, `${title}.xlsx`); // Using the title as the file name
  }
};



  return (
    <div className="popup-overlay modal_overlay" id="analisePopup">
      <div className="popup-contentDiv popup-content-padd">
        <div className="modal-popup">
          <span className="closepopupqual close-popup-top" id="txtCancel" onClick={cancelPopup}>
            <img src={Close} alt="Close" className="img-fluid" width="25" />
          </span>
          <div className="content">
            <div className="HosptlMangemntTable">
              <div className="table-responsive">
                <div className="row">
                  {messegeTitle?
                    <div className="col-md-11 col-10 head-hospMangmntList mb-0">{messegeTitle}</div>
                    :<div className="col-md-11 col-10 head-hospMangmntList mb-0"></div>
                  }
                  <div className="col-md-1 col-2 mb-3 custom-search-sub p-0">
                    <a
                      onClick={exportToExcel}
                      className="cursor-pointer"
                    >
                      <img
                        src={csv}
                        alt="csv"
                        width="25"
                        title="Export CSV"
                        className="mt-1"
                      />
                    </a>
                  </div>
                </div>

                <table className="table table-bordered-hsp table_custom">
                  <thead>
                    <tr>
                      <th>User Name</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {getingAnalyticsDetails.map((data, index) => (
                      <tr key={index}>
                        <td>{data.userName}</td>
                        <td>{data.createdDate}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analisationpopup;
