import React, { Component } from 'react';

export default class MemberDetail extends Component {
 
  render() {
    return (
      <div className="row px-2">
        <div className="col-sm-6">
          <div className="row my-2">
            <label className="col-sm-12 label-text">Email ID (Username) <span className="star-red">*</span></label>
            <div className="col-sm-12  px-0  px-sm-3">
              <input type="text" className="input-design" />
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="row my-2">
            <label className="col-sm-12 label-text">Mobile Number <span className="star-red">*</span></label>
            <div className="col-sm-12  px-0  px-sm-3">
              <input type="text" className="input-design" />
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="row my-2">
            <label className="col-sm-12 label-text">First Name <span className="star-red">*</span></label>
            <div className="col-sm-12  px-0  px-sm-3">
              <input type="text" className="input-design" />
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="row my-2">
            <label className="col-sm-12 label-text">Last Name  <span className="star-red">*</span></label>
            <div className="col-sm-12  px-0  px-sm-3">
              <input type="text" className="input-design" />
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="row my-2">
            <label className="col-sm-12 label-text">Gender </label>
            <div className="col-sm-12  px-0  px-sm-3">
              <select className="input-design">
                <option>Male</option>
                <option>Female</option>
              </select>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="row my-2">
            <label className="col-sm-12 label-text">Date Of Birth <span className="star-red">*</span></label>
            <div className="col-sm-12  px-0  px-sm-3">
              <input type="date" className="input-design" />
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="row my-2">
            <label className="col-sm-12 label-text">Address </label>
            <div className="col-sm-12  px-0  px-sm-3">
              <textarea className="input-design textarea" style={{fontSize: "14px"}}></textarea>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="row my-2">
            <label className="col-sm-12 label-text">City </label>
            <div className="col-sm-12  px-0  px-sm-3">
              <input type="text" className="input-design" />
            </div>
          </div>
          <div className="row my-2">
            <label className="col-sm-12 label-text">State </label>
            <div className="col-sm-12  px-0  px-sm-3">
              <input type="text" className="input-design" />
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="row my-2">
            <label className="col-sm-12 label-text">Zip </label>
            <div className="col-sm-12  px-0  px-sm-3">
              <input type="text" className="input-design" />
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="row my-2">
            <label className="col-sm-12 label-text">country </label>
            <div className="col-sm-12  px-0  px-sm-3">
              <input type="text" className="input-design" />
            </div>
          </div> 
        </div>

        <div className="col-sm-12">
            <input type="submit" value="Save" className="formButton"></input>
            <a href="/doctor/changepassword">
              <input type="button" value="Change Password" className="formButton"></input>
            </a>
        </div>
      </div>
    )
  }
}
