import React, { useEffect } from 'react';
import Header from "../layout/header"
import Footer from "../layout/footer"
import bannerImg from '../image/theme/Case-Study-3.png'
import AboutTakeNextStep from './about-take-next-step';
import DigitalSuccess from "../home/digital-branding-success-stories"
function CaseStudy3() {
    useEffect(() => {
      document.getElementById("success-menu").classList.add("active");
    }, [])
    return (
        <div className='ph-main-wrapper'>
            <Header />
            <div className='ph-case-study'>
                <div className='ph-case-study-banner'>
                    <div className='container-lg'>
                        <div className='ph-case-study-inner-banner'>
                            <div className='row align-items-center'>
                                <div className='col-md-4 mb-3 mb-md-0'>
                                    <img src={bannerImg} className="img-fluid" alt='banner' width="auto" height="auto" />
                                </div>
                                <div className='col-md-8'>
                                    <div className="ph-db-banner">
                                        <span className="ph-banner-title d-none d-md-block">Case Studies</span>
                                        <h1 className="ph-case-study-title-1 mt-4 mt-md-5">A State Dental Association: Showing leadership by  providing Clinic Management Software for Members! </h1>
                                        {/* <div className='ph-case-study-btn-div'>
                                            <button className='ph-case-study-btn'><span className="ph-dot"></span>Solutions Act</button>
                                            <button className='ph-case-study-btn'><span className="ph-dot"></span>Customer Loyalty</button>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='ph-case-study-container'>
                    <div className='container-lg ph-lg-space'>
                        <div className='ph-case-study-inner-container'>
                            <h2 className="ph-main-title-2 mb-3">The Challenge  </h2>
                            <p className="lead-sm mb-0">The Indian Dental Association (Kerala State) had three requirements. First, they wanted a comprehensive solution to help their members go digital in terms of electronic health records for patients and general digitization of their clinics and practices. Secondly, they wanted a platform to be able to communicate directly to their membership on important issues, events and professional information. Finally, they wanted a branded portal that let patients book in-person appointments with IDA Kerala dentists directly.  </p>
                        </div>
                        <div className='ph-case-study-inner-container'>
                            <h2 className="ph-main-title-2 mb-3">Solution</h2>
                            <p className="lead-sm">
                                PurpleHealth built a complete solution that incorporated all three requirements. A dentist app was built for IDA Kerala members that let IDA Kerala leadership communicate important news, events and continuing dental education (CDE) information. Integrated into that same app was a clinic management system that allowed for patient booking, appointment management, prescriptions and billing.</p>

                            <p className="lead-sm">In addition, a branded patient portal was built that allowed patients to search for and select local dentists for in-person appointments. The system includes reminders and notifications for both dentists and patients as well as patient focused communication for dental services and dental education.</p>
                            <p className="lead-sm mb-0">This total solution includes Android and iOS apps as well as websites. PurpleHealth also provides required digital and physical marketing collateral as well as ongoing support and product upgrades.</p>
                        </div>
                        <div className='ph-case-study-inner-container'>
                            <h2 className="ph-main-title-2 mb-3">Outcome</h2>
                            <p className="lead-sm mb-0">IDA Kerala Dentists now have the most sophisticated software solution of any Dentist Association in India. Thousands of Dentists are signed up to the platform and have access to clinic management software, patient connection software, as well as the the association engagement software. PurpleHealth continues to improve and add new and innovative features to the product. </p>
                        </div>
                    </div>
                </div>
                <DigitalSuccess />
                <AboutTakeNextStep />
            </div>
            <Footer />
        </div>
    )
}
export default CaseStudy3;