import React, { Component, Suspense } from "react";
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import { apiCalling } from "../../apiService";
import Logoimg from "../../image/icons/image-preview.png";
import AWS from "aws-sdk";
import $ from "jquery";
var s3;
var imageFile;

export default class ImageUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      S3BucketBlogImages: "",
      s3bucketAccessKey: "",
      AWSAccessKeyId: "",
      region: "us-east-1",
      bucket: "",
      bucketFolder: "",
      imagePath: "",
      imageName: "",
    };
  }
  componentDidMount() {
    this.imageUploadHandler();
  }
  fileChange = (e) => {
    debugger;
    imageFile = e.target.files[0];
    if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
      alert("Add file format with only JPG,JPEG,PNG format");
      return false;
    }
    if (imageFile.size > 3145728) {
      alert("File size must under 3 MB!");
      return false;
    }
    $("#fileData").show();
    $("#fileData").attr("src", window.webkitURL.createObjectURL(imageFile));

    // URL.createObjectURL(imageFile)
  };
  fileUpload(file) {
    if(typeof file === 'undefined'){
    alert('Upload image');return false;
    }
    AWS.config.update({
      accessKeyId: this.state.s3bucketAccessKey,
      secretAccessKey: this.state.AWSAccessKeyId,
      region: this.state.region,
    });
    var bucketParams = {
      Bucket:
        this.state.bucket +
        "/" +
        this.state.bucketFolder +
        "/" +
        this.state.S3BucketBlogImages,
    };
    s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
    let fileName = this.uuidv4() + file.name;
    imageFile = fileName;
    s3.upload(
      {
        Key: fileName,
        Body: file,
        ACL: "public-read",
      },
      (err, data) => {
        $(".loader").show();
        if (err) {
          alert("There was an error uploading your photo: ", err.message);
        } else if (data) {
          this.setState({
            imagePath: data.Location,
            imageName: fileName,
          });
          $(".loader").hide();
          alert("Image uploaded successfully");
         // window.location.reload()
         $('.smssetng-svebtn').prop('disabled', true);
          return null;
        }
      }
    );
  }
  uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };
  imageUploadHandler() {
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "imagePath",
        siteId: "",
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        $(".loader").hide();
        this.setState({
          s3bucketAccessKey: data.configPathDetails[0].s3bucketAccessKey,
          AWSAccessKeyId: data.configPathDetails[0].s3bucketSecretKey,
          bucket: data.configPathDetails[0].PurpleHealthS3bucket,
          bucketFolder: data.configPathDetails[0].S3BucketMainFolder,
          S3BucketBlogImages: data.configPathDetails[0].S3BucketBlogImages,
        });
      }
    });
  }
  handleChange(e) {
    this.setState({
      [e.target.id]: e.target.value,
    });
  }
  srcTypeImage(ev) {
    ev.target.src = Logoimg;
  }
  cancelHandle() {
    window.location.href = "/admin/dashboardAdmin";
  }
  copyURL() {
    navigator.clipboard.writeText(this.state.imagePath);
    alert("Copied to Clipboard")
  }
  render() {
    return (
      <div className="purpleWrap bg-clr-wthHead">
        <Suspense fallback={<div></div>}>
          <section>
            <Header /> <div className="loader"></div>
          </section>
        </Suspense>
        <div className="loader"></div>
        <div className="container mb-5">
          <div>
            <ul className="breadcrum-adminMangmnt">
              <li>
                <a href="/admin/dashboardAdmin">Dashboard</a>
              </li>
              <li>
                <a href="/adminDashboard/imageUpload" className="current">
                  Image Upload
                </a>
              </li>
            </ul>
          </div>
          <div className="head-hospMangmntList">Image Upload</div>

          <div className="addrepwrapperborder container pt-5 m-auto text-left purple_edit">
            <div className="row">
              <div className="col-md-6">
                <div className="row mb-3">
                  <div className="col-md-4">
                    <label className="mb-0">Image</label>
                  </div>
                  <div className="col-md-4">
                    <div className="hspinfo-btnupload mb-auto hspinfo-btnupload-w">
                      <span>Select Image</span>
                      <input
                        type="file"
                        className="hspinfoInputfieldupload"
                        onChange={this.fileChange}
                      />
                    </div>
                    <div className="">
                      <div className="fileUpload-img fileUpload-imgreptop">
                        <img
                          src=""
                          id="fileData"
                          onError={this.srcTypeImage}
                          width="50"
                          alt="upload"
                        ></img>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <label className="mb-0">
                      Image URL<span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-md-8">
                    <input
                      id="imageName"
                      type="text"
                      className="form-control"
                      value={this.state.imagePath}
                      disabled
                    />
                    {this.state.imagePath !== "" ? (
                      <input
                        type="submit"
                        value="Copy URL"
                        className="smssetng-svebtn"
                        onClick={() => this.copyURL()}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-md-12 text-left pl-0 pt-4">
                  <input
                    type="submit"
                    value="Upload"
                    className="smssetng-svebtn"
                    onClick={() => this.fileUpload(imageFile)}
                  />
                  <input
                    type="submit"
                    value="Cancel"
                    className="smssetng-cancelbtn"
                    onClick={this.cancelHandle}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
