import React, { Component, Suspense } from "react";
import BgImg from "../image/promotion/banner_bg02.png";
import DocImg from "../image/promotion/doctor.png";
import PromImg01 from "../image/promotion/calendar.png";
import PromImg02 from "../image/promotion/bottle.png";
import moment from "moment";
import { apiCalling } from "../apiService";
import PromImg03 from "../image/promotion/medical-record.png";
import PromImg04 from "../image/promotion/telemedicine.png";
import PromImg05 from "../image/promotion/bill.png";
import PromImg06 from "../image/promotion/smartphone.png";
import PromImg07 from "../image/promotion/website.png";
import PromImg08 from "../image/promotion/business.png";
import PromImg09 from "../image/promotion/speaker.png";
import Header from "../layout/header";
import Footer from "../layout/footer";
import $ from "jquery";
export default class FreeCmsTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}
  contactUsEmailPaid() {
    var doctorName = $("#doctorName").val();
    var mrNumber = $("#mrNumber").val();
    var phone = $("#phone").val();
    var flag = true;
    if (mrNumber == "") {
      alert("Please Enter Medical Registration Name");
      flag = false;
    } else if (phone == "") {
      alert("Please Enter Mobile Number");
      flag = false;
    }
    if (flag == true) {
      const apiJson = {
        method: "POST",
        body: JSON.stringify({
          functionName: "contactUsEmail",
          siteId: null,
          emailId: "support@purplehealth.com",
          phone: phone,
          doctorName: doctorName,
          planType: "contactUsPaidPlan",
          mrNumber: mrNumber,
          DateSigned: moment(new Date()).format("DD-MM-YYYY"),
          amount: "Rs. 1999/mo",
        }),
      };
      apiCalling(apiJson).then((data) => {
        if (data.success === "1") {
          alert("Email Sent Successfully");
        } else {
          alert("Failed");
        }
      });
    }
  }
  allowNumbersOnly(e) {
    var code = e.which ? e.which : e.keyCode;
    if (code > 31 && (code < 48 || code > 57)) {
      e.preventDefault();
    }
  }
  contactUsEmailFree() {
    var doctorName = $("#doctorNameFree").val();
    var mrNumber = $("#mrNumberFree").val();
    var phone = $("#phoneFree").val();
    var flag = true;
    if (mrNumber == "") {
      alert("Please Enter Medical Registration Number");
      flag = false;
    } else if (phone == "") {
      alert("Please Enter Mobile Number");
      flag = false;
    }
    if (flag == true) {
      const apiJson = {
        method: "POST",
        body: JSON.stringify({
          functionName: "contactUsEmail",
          siteId: null,
          emailId: "support@purplehealth.com",
          phone: phone,
          doctorName: doctorName,
          planType: "contactUsFreePlan",
          mrNumber: mrNumber,
          DateSigned: moment(new Date()).format("DD-MM-YYYY"),
          amount: "",
        }),
      };
      apiCalling(apiJson).then((data) => {
        if (data.success === "1") {
          alert("Email Sent Successfully");
        } else {
          alert("Failed");
        }
      });
    }
  }
  render() {
    return (
      <div className="freecms freecmstwo">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
            <div className="freecms_banner">
              <div className="freecms_container container">
                <div className="freecms_sliderleft">
                  <div className="freecms_sliderhead">
                    Free Clinic Management Software for Doctors!
                  </div>
                  <div className="freecms_sliderpara">
                    Join the thousands of Doctors who have already signed up!
                  </div>
                  <div className="freecms_slidersubhead">Free Forever!</div>
                </div>
                <div className="freecms_left_user">
                  <img src={DocImg} />
                </div>
              </div>
              <div className="freecms_bg">
                <img src={BgImg} />
              </div>
            </div>
            <div className="freecms_features">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="freecms_innercard">
                      <div className="freecms_featureshead">
                        Smart Digital Clinic
                      </div>
                      <div className="freecms_inr">
                        <div className="freecms_inr_inner">
                          <sup>INR</sup>0
                        </div>
                      </div>
                      <div className="freecms_features_key">
                        <div className="freecms_features_img">
                          <img src={PromImg01} />
                        </div>
                        <div className="freecms_features_connt">
                          <div className="freecms_apponint_head">
                            Appointment Calendar
                          </div>
                          <div className="freecms_apponint_para">
                            Powerful Clinic Management Software with appointment
                            Scheduling
                          </div>
                        </div>
                      </div>
                      <div className="freecms_features_key">
                        <div className="freecms_features_img">
                          <img src={PromImg02} />
                        </div>
                        <div className="freecms_features_connt">
                          <div className="freecms_apponint_head">
                            Prescriptions
                          </div>
                          <div className="freecms_apponint_para">
                            Send Patients prescriptions and manage medicines &
                            drugs
                          </div>
                        </div>
                      </div>
                      <div className="freecms_features_key">
                        <div className="freecms_features_img">
                          <img src={PromImg03} />
                        </div>
                        <div className="freecms_features_connt">
                          <div className="freecms_apponint_head">
                            Patient Records
                          </div>
                          <div className="freecms_apponint_para">
                            Electronic Medical Records, Patient Details and
                          </div>
                        </div>
                      </div>
                      <div className="freecms_features_key">
                        <div className="freecms_features_img">
                          <img src={PromImg04} />
                        </div>
                        <div className="freecms_features_connt">
                          <div className="freecms_apponint_head">
                            Telehealth Technology
                          </div>
                          <div className="freecms_apponint_para">
                            Paid online consultations: Video-Chat, Messages and
                            Phone Consultations
                          </div>
                        </div>
                      </div>
                      <div className="freecms_features_key">
                        <div className="freecms_features_img">
                          <img src={PromImg05} />
                        </div>
                        <div className="freecms_features_connt">
                          <div className="freecms_apponint_head">Billing</div>
                          <div className="freecms_apponint_para">
                            Online payments, Patient Billing
                          </div>
                        </div>
                      </div>
                      <div className="freecms_features_key">
                        <div className="freecms_features_img">
                          <img src={PromImg06} />
                        </div>
                        <div className="freecms_features_connt">
                          <div className="freecms_apponint_head">Mobile</div>
                          <div className="freecms_apponint_para">
                            iOS & Android
                          </div>
                        </div>
                      </div>
                      <div className="freecms_featureshead freecms_editfeathead">
                        ...and many more powerful features!
                      </div>
                      <div className="freecms_required freecms_editrequired">
                        *Terms and Conditions Apply
                      </div>
                      <div className="freecms_input freecms_editinput">
                        <input type="text" placeholder="Enter Name" 
                        id="doctorNameFree"
                        />
                        <input
                          type="text"
                          placeholder="Medical Registration Number*"
                          id="mrNumberFree"
                        />
                        <input type="text" placeholder="Mobile Number*" 
                        id="phoneFree"
                        onKeyPress={(e) => this.allowNumbersOnly(e)}
                        onKeyDown={(event) => {
                          if (event.key === "Enter") {
                            this.contactUsEmailFree();
                          }
                        }}
                        />
                        <button type="submit" onClick={this.contactUsEmailFree}>Join Now!</button>
                        <div className="freecms_required">*Required</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="freecms_innercard feecms_editinnercard">
                      <div className="freecms_featureshead">
                        Build Your Brand Plan
                      </div>
                      <div className="freecms_inr">
                        <div className="freecms_inr_inner">
                          <sup>INR</sup>1999
                          <sub>
                            /mo*
                            <span className="freecms_billspan">
                              Billed Annually
                            </span>
                          </sub>
                        </div>
                      </div>
                      <div className="freecms_features_key">
                        <div className="freecms_features_img">
                          <img src={PromImg07} />
                        </div>
                        <div className="freecms_features_connt">
                          <div className="freecms_apponint_head">
                            Your Own Clinic Website
                          </div>
                          <div className="freecms_apponint_para">
                            Your Own Clinic Branding, Patients can book online
                            appointments directly
                          </div>
                        </div>
                      </div>
                      <div className="freecms_features_key">
                        <div className="freecms_features_img">
                          <img src={PromImg08} />
                        </div>
                        <div className="freecms_features_connt">
                          <div className="freecms_apponint_head">
                            Online Listing Management
                          </div>
                          <div className="freecms_apponint_para">
                            <ul>
                              <li>Medical SEO</li>
                              <li>Review & Ratings Management</li>
                              <li>Online Reputation Management</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="freecms_features_key">
                        <div className="freecms_features_img">
                          <img src={PromImg09} />
                        </div>
                        <div className="freecms_features_connt">
                          <div className="freecms_apponint_head">
                            Online Marketing Support
                          </div>
                          <div className="freecms_apponint_para">
                            Branded Digital Flyers to spread awareness to
                            patients
                          </div>
                        </div>
                      </div>
                      <div className="freecms_featureshead freecms_freeforever">
                        <span className="mr-3">+</span>Free Forever Plan
                      </div>
                      <div className="freecms_required freecms_editrequired">
                        *Terms and Conditions Apply
                      </div>
                      <div className="freecms_input freecms_editinput">
                        <input
                          type="text"
                          placeholder="Enter Name"
                          id="doctorName"
                        />
                        <input
                          type="text"
                          placeholder="Medical Registration Number*"
                          id="mrNumber"
                        />
                        <input
                          type="text"
                          placeholder="Mobile Number*"
                          id="phone"
                          onKeyPress={(e) => this.allowNumbersOnly(e)}
                          onKeyDown={(event) => {
                            if (event.key === "Enter") {
                              this.contactUsEmailPaid();
                            }
                          }}
                        />
                        <button type="submit" onClick={this.contactUsEmailPaid}>
                          Join Now!
                        </button>
                        <div className="freecms_required">*Required</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Suspense>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
