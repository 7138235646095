import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Team01 from '../image/theme/news/img3a.png';
import Team02 from '../image/theme/news/img2a.png';
function Bloglistblocks(props) {
    const teamDetailArray1 = [
        {
            id: "blog1",
            desc: "<p className='card-text'>In today's rapidly evolving healthcare landscape, effective communication and collaboration between pharmaceutical companies and doctors are essential. Traditional approaches often lack personalization and fail to deliver meaningful insights. However, with the emergence of innovative technologies, a new era of pharma-doctor engagement has dawned. This article explores the transformative power of closed-loop...</p>",
            name: "Transforming Pharma - Doctor Engagement: Data-Driven Closed Loop Marketing Strategies",
            image: Team01
        },
        {
            id: "blog2",
            desc: "<p className='card-text'>In today's digital age, a doctor's online presence is as crucial as their medical expertise. With more patients turning to the internet to find healthcare providers, managing Google listings has become essential for doctors. In this article, we'll explore why it's imperative for doctors to manage their Google listings effectively...</p>",
            name: "Unlocking Success: The Importance of Managing Google Listings for Doctors",
            image: Team02
        }
    ]; 
    const handleItemClick = (item) => {
        window.localStorage.setItem("blogid", item);
        window.location.href = `/blog-details`;
    }
    const [blogDisplayArray, setBlogDisplayArray] = useState([]);

    useEffect(() => {
        if (props.blogDetailFlag === true) {
            const numberOfArticlesInARow = 3;
            const updatedArray = teamDetailArray1.filter(item => item.id !== props.blogId);
            const clickedNewsIndex = teamDetailArray1.findIndex(item => item.id === props.blogId);
            const Total = teamDetailArray1.length;
            const lastIndex = Total - 1;
            if (Total <= numberOfArticlesInARow) {
                setBlogDisplayArray(updatedArray);
                return;
            }
            if (clickedNewsIndex >= lastIndex - 2) {
                if (clickedNewsIndex === lastIndex) {
                    const next3News1 = updatedArray.slice(clickedNewsIndex - 3, clickedNewsIndex);
                    setBlogDisplayArray(next3News1);
                }
                else if (clickedNewsIndex === lastIndex - 1) {
                    const next3News2 = updatedArray.slice(clickedNewsIndex, clickedNewsIndex + 1);
                    const next3News4 = updatedArray.slice(clickedNewsIndex - 2, clickedNewsIndex);
                    setBlogDisplayArray([...next3News2, ...next3News4]);
                }
                else if (clickedNewsIndex === lastIndex - 2) {
                    const next2News2 = updatedArray.slice(clickedNewsIndex, clickedNewsIndex + 2);
                    const next1News4 = updatedArray.slice(clickedNewsIndex - 1, clickedNewsIndex);
                    setBlogDisplayArray([...next1News4, ...next2News2]);
                }
            } else {
                const next3News = updatedArray.slice(clickedNewsIndex, clickedNewsIndex + 3);
                setBlogDisplayArray(next3News);
            }

        } else {
            setBlogDisplayArray(teamDetailArray1);
        }
    }, [props.blogDetailFlag, props.newsId]);

    return (

        <div className='container-lg'>
            <div className={`row  ${props.blogDetailFlag === false && `justify-content-center `} `}>
                {blogDisplayArray.map((team, index) => (
                    <div key={index} className="col-lg-4 ph-card-success1 cursor-pointer" onClick={() => handleItemClick(team.id)}>
                        <div className="ph-service-img">
                            <img src={team.image} alt="image-news" className='img-fluid' />
                        </div>
                        <div className="card-body px-0">
                            <Link to={team.profileLink} className="ph-card-success-title">{team.name}</Link>
                            <div className="ph-points" dangerouslySetInnerHTML={{ __html: team.desc }}>
                            </div>
                            <div className='ph-bottom-btn'>
                                <div className='ph-know-outcome'>
                                    Know the outcome
                                    <span className='ml-1'>
                                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Bloglistblocks;
