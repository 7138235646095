import React, { Component, Suspense } from "react";

export default class Seo extends Component {
  render() {
    return (
      <div className="purpleWrap bg-clr-wthHead mb-4">
        <Suspense fallback={<div></div>}>
          <section>
            <div className="seoWrapperHspMangemnt">
              <div className="container mt-5">
                <span className="seosectinTextHspMangemnt">
                  {"Click the button to create the default SEO details --> "}
                </span>
                <div className="generatebtnDiv-seohsp">
                  <button className="generatebtn-seohsp">Generate SEO</button>
                </div>
                <div className="mt-4 mb-5">
                  <div className="seofieldDivhsp">
                    <label>Title</label>
                    <textarea
                      name=""
                      id=""
                      cols="30"
                      rows="10"
                      placeholder="Title"
                      className="seofieldDivhsp-textarea"
                    ></textarea>
                  </div>

                  <div className="seofieldDivhsp">
                    <label>Keywords</label>
                    <textarea
                      name=""
                      id=""
                      cols="30"
                      rows="10"
                      placeholder="The ayurvedic Doctor, homeopathic doctors, veterinarians, Dentists in india.  Dr.   - Kollam - Kerala"
                      className="seofieldDivhsp-textarea"
                    ></textarea>
                  </div>

                  <div className="seofieldDivhsp">
                    <label>
                      Description <span className="hspifoMantory">*</span>
                    </label>
                    <textarea
                      name=""
                      id=""
                      cols="30"
                      rows="10"
                      placeholder="Search engine for doctors. Doctors of India, Doctors Directory,  Dr.   - Kollam - Kerala"
                      className="seofieldDivhsp-textarea"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Suspense>
        <Suspense fallback={<div></div>}></Suspense>
      </div>
    );
  }
}
