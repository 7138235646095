import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../css/purplestyle.css";
import "../css/purpleresponsive.css";
import "../css/admin.css";
import "../css/adminresponsive.css";
import "../css/font-awesome.min.css";
import Logoheader from "../image/theme/purplelogo.png";
import arrowDown from "../image/theme/arrow.png";
import arrowRight from "../image/theme/right-menu-arrow.png";
import arrowUp from "../image/theme/arrow-up.png";
import arrowDownclr from "../image/theme/down-blue.png";
import arrowredDown from "../image/theme/red-arrow.png";
import arrowwhtDown from "../image/theme/white-back.png";
import menu from "../image/theme/hamburger.png";
import { Link } from "react-router-dom";
import Login from "./login";
import Signup from "./signup";
import ForgotPassword from "./forgot-password";
import $ from "jquery";
import { apiCalling } from "../apiService";
import loader from "../image/theme/loader.gif";
import logoImg from '../image/theme/darker-logo.png'
import ArrowWhite from '../image/theme/arrow_forward.png'
export default class header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
    };
    this.state = {
      activeIndex: null,
      isSubmenuVisible: false
    };
  }
  handleClick = () => {
    this.setState(prevState => ({
      isSubmenuVisible: !prevState.isSubmenuVisible
    }));
  };
  menuClick() {
    $(".menu ul").slideToggle();
  }
  
  componentDidMount() {

    $("html, body").animate({ scrollTop: 0 }, "slow");
    $("#signUpWrap,#signInWrap,#forgotPswdWrap").hide();
    $(".layoutPatientMenuTop").hide();
    $(".layoutDoctorMenuTop").hide();
    $("#loginMenu").click(function () {
      $("#signUpWrap").hide();
      $("#signInWrap").toggle();
      $("#forgotPswdWrap").hide();
    });
    $("#signUp").click(function () {
      $("#signInWrap").hide();
      $("#signUpWrap").show();
      $("#forgotPswdWrap").hide();
    });
    $("#signIn,#signInNew").click(function () {
      $("#signUpWrap").hide();
      $("#signInWrap").show();
      $("#forgotPswdWrap").hide();
    });
    $("#forgotPswd").click(function () {
      $("#forgotPswdWrap").toggle();
      $("#signUpWrap").hide();
      $("#signInWrap").hide();
    });
    $("html").click(function () {
      $(".signInWrap").hide();
    });
    $(".signInWrap,#loginMenu").click(function (event) {
      event.stopPropagation();
    });
    if (localStorage.getItem("isLogin") == "true") {
      $("#loginMenu").hide();
    }
    if (localStorage.getItem("role") == "ROLE_DOCTOR") {
      $(".layoutDoctorMenuTop").show();
    }
    if (localStorage.getItem("role") == "ROLE_USER") {
      $(".layoutPatientMenuTop").show();
    }
    $(".signOutPatientClick").click(function () {
      const logoutReq = {
        method: "POST",
        body: JSON.stringify({
          functionName: "logout",
          siteId: localStorage.getItem("siteId"),
          userId: localStorage.getItem("userId"),
        }),
      };
      apiCalling(logoutReq).then((data) => {
        if (data.success == "1") {
          localStorage.clear();
          window.localStorage.clear()
          window.location.href = "/";
          alert(data.successMessage);
        } else {
          alert("Logout Failed");
        }
      });
    });

  }
  // componentDidUpdate() {
  //   console.log("file:header.jsx ~ componentDidUpdate ~ activeIndex", this.state.activeIndex)
  // }
  handleItemClick = (index, event) => {
    // event.preventDefault();
    this.setState((prevState) => (prevState.activeIndex === index ? { activeIndex: null } : { activeIndex: index }));
  };

  DoctorPageOffsetReset() {
    window.localStorage.setItem('doctorpageNumber', 1)
    window.localStorage.setItem('Doctoroffset', 0)
  }
  menuToggle = () => {
    this.setState((prevState) => ({
      menuOpen: !prevState.menuOpen,
    }));

    document.body.classList.toggle('overflow-hidden');
  };
  menuClick = () => {
    document.body.classList.remove('overflow-hidden');
  }
  render() {
    const { isSubmenuVisible } = this.state;
    const { menuOpen } = this.state;
    const { activeIndex } = this.state;
    return (

      <header className="ph-header">
        <div className="container-lg">
          <nav className="navbar navbar-expand-lg navbar-light p-0">
            <Link to="/" className="navbar-brand">
              <img src={logoImg} className="img-fluid" alt="logo" width="150" height="54"></img>
            </Link>
            <button className="navbar-toggler" type="button"
              onClick={this.menuToggle}
            >
              <div className={`bar-icon ${menuOpen ? 'menu-change' : ''}`}>
                <div className="bar1"></div>
                <div className="bar2"></div>
                <div className="bar3"></div>
              </div>
            </button>

            <div class={`navbar-collapse ph-menu-bar ${menuOpen ? 'menuFixed' : ''}`}>
              <ul className="navbar-nav ml-auto align-items-center">
                <li className="nav-item active">
                  <Link id="home-menu" to="/" className="nav_link" onClick={() => this.menuClick()}>Home</Link>
                </li>
                <li className="nav-item">
                  <Link id="about-menu" to="/about" className="nav_link" onClick={() => this.menuClick()}>About us</Link>
                </li>
                <li className={`dropdown nav-item ${activeIndex === 0 ? 'active' : ''}`} onClick={(e) => this.handleItemClick(0, e)}>
                  <span id="solution-menu" className="nav_link">
                    Solutions
                    <img
                      className="sub-menu-arrow"
                      src={arrowDown}
                      width="15"
                    ></img>
                  </span>
                  <div className="dropdown-menu">
                    <Link to="/digital-branding" className="nav_link" onClick={() => this.menuClick()}>
                      <img src={ArrowWhite} className="img-fluid ph_sub_arrow" width="50" height="50" alt="arrow" />
                      Digital Branding</Link>
                    <Link to="/local-presence-management" className="nav_link" onClick={() => this.menuClick()}>
                      <img src={ArrowWhite} className="img-fluid ph_sub_arrow" width="50" height="50" alt="arrow" />
                      Local Presence Management</Link>
                    <Link to="/digital-marketing" className="nav_link" onClick={() => this.menuClick()}>
                      <img src={ArrowWhite} className="img-fluid ph_sub_arrow" width="50" height="50" alt="arrow" />
                      Digital Marketing</Link>
                  </div>
                </li>
                <li className={`dropdown nav-item ${activeIndex === 1 ? 'active' : ''}`} onClick={(e) => this.handleItemClick(1, e)}>
                  <span id="products" className="nav_link">
                    Products
                    <img
                      className="sub-menu-arrow"
                      src={arrowDown}
                      width="15"
                    ></img>
                  </span>
                  <div className="dropdown-menu">

                  <div className="dropdown-submenu">
                      <Link className="nav_link" >
                      <img src={ArrowWhite} className="img-fluid ph_sub_arrow" width="50" height="50" alt="arrow" />
                        Clinic Management Software
                        <img
                          className="sub-menu-arrow"
                          src={ arrowRight }
                          width="15"
                          alt="arrow"
                        />
                      </Link>
                        <div className="dropdown-submenu-div">
                          <ul className="px-2">
                            <li onClick={this.menuClick()}> <Link to="/medical" className="nav_link px-0">
                            <img src={ArrowWhite} className="img-fluid ph_sub_arrow  d-inline-block" width="50" height="50" alt="arrow" />
                            For Medical </Link></li>
                            <li onClick={this.menuClick()}><Link to="/dental" className="nav_link px-0">
                            <img src={ArrowWhite} className="img-fluid ph_sub_arrow  d-inline-block" width="50" height="50" alt="arrow" />
                            For Dental</Link></li>
                          </ul>
                        </div>
                    </div>
                    <Link to="/products-for-doctors" className="nav_link" onClick={() => this.menuClick()}>
                      <img src={ArrowWhite} className="img-fluid ph_sub_arrow" width="50" height="50" alt="arrow" />
                      Products For Doctors</Link>
                    <Link to="/products-for-pharma-company" className="nav_link" onClick={() => this.menuClick()}>
                      <img src={ArrowWhite} className="img-fluid ph_sub_arrow" width="50" height="50" alt="arrow" />
                      Products For Pharma Company</Link>
                    <Link to="/products-for-pharmacy" className="nav_link" onClick={() => this.menuClick()}>
                      <img src={ArrowWhite} className="img-fluid ph_sub_arrow" width="50" height="50" alt="arrow" />
                      Products For Pharmacy</Link>
                    <Link to="/insightx" className="nav_link" onClick={() => this.menuClick()}>
                      <img src={ArrowWhite} className="img-fluid ph_sub_arrow" width="50" height="50" alt="arrow" />
                      Insightx</Link>
                  </div>
                </li>
                <li className="nav-item">
                  <Link id="success-menu" to="/resources" className="nav_link" onClick={() => this.menuClick()}>Success Stories</Link>
                </li>
                {/* <li className="nav-item">
                  <Link to="/contactus" className="nav_link" onClick={() => this.menuClick()}>Contact us</Link>
                </li> */}
                {/* <li className={`nav-item dropdown ${activeIndex === 1 ? 'active' : ''}`} onClick={(e) => this.handleItemClick(1, e)}>
                  <span id="success-menu" className="nav_link">
                    Success Stories
                    <img
                      className="sub-menu-arrow"
                      src={arrowDown}
                      width="15"
                    ></img>
                  </span>
                  <div className="dropdown-menu">
                    <span to="/" className="nav_link" onClick={() => this.menuClick()}>
                      <img src={ArrowWhite} className="img-fluid ph_sub_arrow" width="50" height="50" alt="arrow" />
                      Blogs</span>
                    <Link to="/resources" className="nav_link" onClick={() => this.menuClick()}>
                      <img src={ArrowWhite} className="img-fluid ph_sub_arrow" width="50" height="50" alt="arrow" />
                      Success Stories</Link>
                    <span to="/" className="nav_link" onClick={() => this.menuClick()}>
                      <img src={ArrowWhite} className="img-fluid ph_sub_arrow" width="50" height="50" alt="arrow" />
                      Updates</span>
                  </div>
                </li> */}
                {window.localStorage.getItem("isLogin") == "true" && window.localStorage.getItem("role") === "ROLE_ADMIN" ? (
                  <li className="nav-item">
                    <Link className="nav_link" to="/admin/dashboard" onClick={() => this.menuClick()}>Dashboard</Link>
                  </li>
                ) : (
                  <li className="nav-item">
                    <Link className="ph-button" to="/contactus" onClick={() => this.menuClick()}>
                      Contact Us
                    </Link>
                  </li>
                )}
                {/* {window.localStorage.getItem("isLogin") == "true" ? ( */}
                {/* <li className="nav-item ml-0 ml-lg-3">
                    <Link className="ph-button-border"
                      to="/login"
                    >
                        {window.localStorage.getItem("isLogin") == "true"
                          ? "Logout"
                          : "Login"}
                    </Link>
                  </li> */}
                {/* ) : ""} */}
              </ul>
            </div>
          </nav>
        </div>
      </header>
    );
  }
}
