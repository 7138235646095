import React, { Component, Suspense } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";

export default class DoctorManagementDashboard extends Component {
  // state = {};
  constructor(props) {
    super(props);
    window.localStorage.setItem("pageNumber", 1);
    window.localStorage.setItem("offset", 0);
  }
  DoctorOffsetPageNumberReset = () => {
    window.localStorage.setItem("doctorpageNumber", 1);
    window.localStorage.setItem("Doctoroffset", 0);
  };
  render() {
    return (
      <div className="dashboard-doctorOuter">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />

            <div className="container mt-5">
              <div>
                <ul className="breadcrum-adminMangmnt">
                  <li>
                    <a href="/admin/dashboardAdmin">Dashboard</a>
                  </li>
                  <li>
                    <a href="" className="current">
                      Doctor Management
                    </a>
                  </li>
                </ul>
              </div>

              <div className="doctorWork-dashboard">
                <div className="adminMangemnt-dashboard">
                  <div className="admin-dc-dashBtns">
                    <a
                      href="/adminManagement/doctorManagementList/doctorManagement"
                      onClick={() => this.DoctorOffsetPageNumberReset()}
                    >
                      <div>Doctors List </div>
                    </a>
                  </div>
                  <div className="admin-dc-dashBtns">
                    <a href="/adminManagement/doctorManagementList/simpleDoctorRegistrationForm">
                      <div>Doctor Registration Form</div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Suspense>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
