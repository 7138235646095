import React, { Component } from 'react';
import Header from "../layout/header"
import Footer from "../layout/footer"
import InboxMessage from "../patient/inboxmessage"
import $ from "jquery"

export default class Inbox extends Component {
  componentDidMount()
  {
    $("#inbox_btn").addClass("active")
  }
  render() {
    return (
      <div className="purpleWrap">
        <Header></Header>
        <div className="container container-auto">
          <div className="main-wrapper1">
              <InboxMessage></InboxMessage>
          </div>
        </div>

        <Footer></Footer>
      </div>
    )
  }
}
