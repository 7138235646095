import React, { Component } from 'react';

export default class ExperienceDetail extends Component {
 
  render() {
    return ( 
        <div className="row px-2">
          <div className="col-sm-12  px-0  px-sm-3">
            <div className="row my-2">
              <div className="col-sm-7 mb-2">
                <input type="text" className="input-design w-75" placeholder="Experience Description" />
                <button className="btn-icons" id="btnSearch"><span className=" fa fa-plus"></span></button></div>
            </div>
          </div>
          
          <div className="col-sm-12  px-0  px-sm-3">
            <h6 className="border-bottom pb-2">Experience In</h6>
          </div>
          <div className="col-sm-12  px-0  px-sm-3">
            <div className="row my-2">
              <div className="col-sm-7 mb-2">
                <input disabled="disabled" type="text" className="input-design w-75" />
                <span className=" fa fa-trash color-maintext ml-5"></span></div>
            </div>
            <div className="row my-2">
              <div className="col-sm-7 mb-2">
                <input disabled="disabled" type="text" className="input-design w-75" />
                <span className=" fa fa-trash color-maintext ml-5"></span></div>
            </div>
          </div>

          <div className="col-sm-12">
              <input type="submit" value="Save" className="formButton"></input>
          </div>
        </div>
    )
  }
}
