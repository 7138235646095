import React, { Component, Suspense } from "react";
import Edit from "../../image/icons/edit.png";
import tick from "../../image/icons/tick.png";
import Deactivate from "../../image/icons/deactvate.png";
import { apiCalling } from "../../apiService";
import $ from "jquery";
import Pagination from "react-js-pagination";
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import { Link } from "react-router-dom";
import Logoimg from "../../image/icons/image-preview.png";
import ApplicationsEditSave from "./applicationsEditSave";

export default class ApplicationsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hospitalBannerData: [],
      appDetailsData: [],
      totalCount: 0,
      activePage: 1,
      showMessage: "Waiting for data... ",
      applicationEditPopup: false,
      cmbCategory: "",
      textInput: "",
      offset: "0",
      appTypeData: [],
      editApplicationId: "",
    };
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  handlePageChange(pageNumber) {
    offset = "0";
    //alert(pageNumber);
    this.setState({ activePage: pageNumber });
    var offset;
    var max;
    // let cmbCategory =
    //   $("#cmbCategory").val() === "0" ? "" : $("#cmbCategory").val();
    // let txtInput = $("#txtInput").val();
    // let cmbStatus = $("#cmbStatus").val() === "0" ? "" : $("#cmbStatus").val();
    if (pageNumber == "1") {
      max = "10";
      this.setState(
        {
          activePage: pageNumber,
          isLoading: "0",
          offset: parseInt(offset),
        },
        () => this.getApplicationsList()
      );
    } else {
      offset = parseInt(pageNumber - 1) * 10;
      max = "10";
      this.setState(
        {
          activePage: pageNumber,
          isLoading: "0",
          offset: offset,
        },
        () => this.getApplicationsList()
      );
    }

    // const apiJson = {
    //   method: "POST",
    //   body: JSON.stringify({
    //     functionName: "getHospitalBannerUploadList",
    //     cmbCategory: cmbCategory,
    //     txtInput: txtInput,
    //     cmbStatus: cmbStatus,
    //     Offset: JSON.stringify(offset),
    //     max: max,
    //   }),
    // };
    // $(".loader").show();
    // return false;
    // apiCalling(apiJson).then((data) => {
    //   if (data.success === "1") {
    //     this.setState({
    //       hospitalBannerData: data.hospitalBannerData,
    //       totalCount: data.totalCount,
    //     });
    //     $(".loader").hide();
    //   }
    // });
  }

  componentDidMount() {
    this.getApplicationsList();
    this.getApptypeList();
  }
  getApplicationsList = () => {
    var req = {
      method: "POST",
      body: JSON.stringify({
        functionName: "appDetailsLists",
        appDetailsId: "",
        cmbSelect: this.state.cmbCategory, // or “bundleId”
        txtInput: this.state.textInput, // or “com.patientapp.purple”
        max: "10",
        Offset: this.state.offset,
      }),
    };
    $(".loader").show();
    apiCalling(req).then((data) => {
      $(".loader").hide();
      if (data.success === "1") {
        this.setState({
          appDetailsData: data.appDetailsData,
          totalCount: data.totalCount,
        });
      } else this.setState({ appDetailsData: [] });
    });
  };
  getApptypeList = () => {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "appTypeDetails",
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success == "1") {
        this.setState({
          appTypeData: data.resultData.appTypeData,
          siteDatas: data.resultData.siteeData,
        });
      } else {
        alert("Something went wrong for App Types");
      }
    });
  };
  //   gethospitalBannerData() {
  //     $(".loader").show();
  //     const apiJson = {
  //       method: "POST",
  //       body: JSON.stringify({
  //         functionName: "getHospitalBannerUploadList",
  //         cmbCategory: "",
  //         txtInput: "",
  //         cmbStatus: "",
  //         Offset: "0",
  //         max: "10",
  //       }),
  //     };
  //     apiCalling(apiJson).then((data) => {
  //       if (data.success === "1") {
  //         $(".loader").hide();
  //         this.setState({
  //           hospitalBannerData: data.hospitalBannerData,
  //           totalCount: data.totalCount,
  //         });
  //       }
  //       if (data.success === "0") {
  //         $(".loader").hide();
  //         this.setState({
  //           hospitalBannerData: [],
  //           showMessage: data.errorMessage,
  //         });
  //       }
  //     });
  //   }

  changeText = () => {
    this.setState({ offset: 0 });
    if ($("#cmbCategory").val() !== "0") {
      this.setState({ cmbCategory: $("#cmbCategory").val() });
      document.getElementById("txtInput").disabled = false;
      $("input[name=txtInput]").focus();
    } else {
      this.setState({ cmbCategory: "" });
      document.getElementById("txtInput").disabled = true;
    }
    document.getElementById("txtInput").value = "";
  };

  searchHandler() {
    // let cmbCategory =
    //   $("#cmbCategory").val() === "0" ? "" : $("#cmbCategory").val();
    // let txtInput = $("#txtInput").val();
    // let cmbStatus = $("#cmbStatus").val() === "0" ? "" : $("#cmbStatus").val();

    if ($("#cmbCategory").val() !== "0" && $("#txtInput").val() === "") {
      var t = document.getElementById("cmbCategory");
      var selectedText = t.options[t.selectedIndex].text;
      alert(selectedText + " Required");
      $("#txtInput").focus();
      return false;
    } else {
      this.getApplicationsList();
      // const searchData = {
      //   method: "POST",
      //   body: JSON.stringify({
      //     functionName: "getHospitalBannerUploadList",
      //     cmbCategory: cmbCategory,
      //     txtInput: txtInput,
      //     Offset: "0",
      //     max: "10",
      //   }),
      // };
      // $(".loader").show();
      // apiCalling(searchData).then((data) => {
      //   if (data.success === "1") {
      //     $("#paginationType").show();
      //     $(".loader").hide();
      //     this.setState({
      //       hospitalBannerData: data.hospitalBannerData,
      //       totalCount: data.totalCount,
      //       activePage: 1,
      //     });
      //   }
      //   if (data.success === "0") {
      //     $("#paginationType").hide();
      //     $(".loader").hide();
      //     this.setState({
      //       hospitalBannerData: [],
      //       showMessage: data.errorMessage,
      //     });
      //   }
      // });
    }
  }

  statusChangeHandler(id, Status) {
    var x = false;
    if (Status === false) {
      x = window.confirm("Do you want to Disable this?");
    } else {
      x = window.confirm("Do you want to Enable this?");
    }
    if (x === true) {
      const SatusData = {
        method: "POST",
        body: JSON.stringify({
          functionName: "applicationStatusChange",
          appDetailsId: id.toString(),
          Status: Status,
        }),
      };
      $(".loader").show();
      apiCalling(SatusData).then((data) => {
        if (data.success === "1") {
          $(".loader").hide();
          alert("Status Changed Successfully");
          // this.gethospitalBannerData();
          this.getApplicationsList();
        }
        if (data.success === "0") {
          $(".loader").hide();
          alert(data.errorMessage);
        }
      });
    }
  }

  render() {
    return (
      <div className="purpleWrap bg-clr-wthHead">
        <Suspense fallback={<div></div>}>
          <section>
            <Header /> <div className="loader"></div>
          </section>
        </Suspense>
        <div className="loader"></div>
        <div className="container mb-5">
          <div>
            <ul className="breadcrum-adminMangmnt">
              <li>
                <a href="/admin/dashboardAdmin">Dashboard</a>
              </li>
              <li>
                <a href="/adminDashboard/mobileappdashboard"> Mobile App Dashboard</a>
              </li>
              <li>
                <a href="" className="current">
                  Applications
                </a>
              </li>
            </ul>
          </div>
          <div className="head-hospMangmntList">Applications</div>

          <div className="HosptlMangemntTable">
            <div className="AddNewHspbtnDiv">
              <span
                onClick={() =>
                  {this.setState({
                    applicationEditPopup: true,
                    editApplicationId: "",
                  });
                $('body').css('overflow', 'hidden' )
                }
                }
                className="addnewhspbtn"
              >
                {" "}
                Add Application
              </span>
            </div>
            <div className="tableHeader-HspMangemnt">
              <div className="selectField-HspMangmnt">
                <select
                  className="slecthsp slecthspMob"
                  name="cmbCategory"
                  id="cmbCategory"
                  onChange={() => this.changeText()}
                >
                  <option value="0">Search In</option>
                  <option value="name">Name</option>
                  <option value="bundleId">Bundle ID</option>
                </select>
              </div>
              <div className="selectField-HspMangmnt">
                <input
                  type="text"
                  className="hspmngInput hspbanerInputmob"
                  id="txtInput"
                  name="txtInput"
                  onChange={(e) => this.setState({ textInput: e.target.value })}
                  disabled
                />
              </div>
              {/* <div className="selectField-HspMangmnt">
                <select
                  className="slecthsp slecthspMob"
                  name="cmbStatus"
                  id="cmbStatus"
                  onChange={(e) => this.setState({ cmbStatus: e.target.value })}
                >
                  <option value="0">Status</option>
                  <option value="true">Active</option>
                  <option value="false">InActive</option>
                  <option value="1">App BAnner Yes</option>
                  <option value="2">App Banner No</option>
                </select>
              </div> */}
              <div className="selectField-HspMangmnt">
                <button
                  className="searchbtn-hspMng"
                  onClick={() => this.searchHandler()}
                >
                  Search
                </button>
              </div>
            </div>
            {this.state.appDetailsData.length > 0 ? (
              <div className="table-responsive table-baner-res">
                <table className="table table-bordered-hsp">
                  <thead>
                    <tr>
                      {/* <th className="tablehead-hspmng">ID</th> */}
                      <th className="tablehead-hspmng">App Name</th>
                      <th className="tablehead-hspmng">Bundle ID</th>
                      <th className="tablehead-hspmng">Android Version</th>
                      <th className="tablehead-hspmng">IOS Version</th>
                      <th className="tablehead-hspmng">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.appDetailsData.map((data) => (
                      <tr>
                        <td>{data.appName}</td>
                        <td>{data.bundleId}</td>
                        <td>{data.androidVersion}</td>
                        <td>{data.iosVersion}</td>
                        <td className="nowrap">
                          <div>
                            <span
                              onClick={() =>
                              {this.setState({
                                applicationEditPopup: true,
                                editApplicationId:
                                  data.appDetailsId.toString(),
                              });
                              $('body').css('overflow', 'hidden' )
                            }
                              }
                            >
                              {/* <Link
                                to={
                                  "/adminManagement/mobileAppdashboard/applicationsDetailEditSave"
                                }
                                className="mr-2"
                              > */}
                              <img
                                src={Edit}
                                alt=""
                                width="18"
                                className="img-fluid"
                              />
                              {/* </Link> */}
                            </span>
                            {data.Status === true ? (
                              <img
                                src={tick}
                                width="22"
                                title="Click to Deactivate "
                                className="mr-2 conf-cursorpointer"
                                alt=""
                                onClick={() => {
                                  this.statusChangeHandler(
                                    data.appDetailsId,
                                    false
                                  );
                                }}
                              ></img>
                            ) : (
                              <img
                                src={Deactivate}
                                alt=""
                                title="Click to Activate"
                                className="mr-2 conf-cursorpointer"
                                width="20"
                                onClick={() => {
                                  this.statusChangeHandler(
                                    data.appDetailsId,
                                    true
                                  );
                                }}
                              ></img>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {this.state.totalCount > 5 ? (
                  <div className="pagination-qualList pagination-qualListwidth">
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={10}
                      totalItemsCount={this.state.totalCount}
                      onChange={this.handlePageChange}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <p className="error-msg text-center mb-0">
                {" "}
                {this.state.showMessage}{" "}
              </p>
            )}
          </div>
        </div>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
        {this.state.applicationEditPopup === true && (
          <ApplicationsEditSave
            showApplicationsEditPopup={(data) =>
              this.setState({ applicationEditPopup: data })
            }
            editApplicationId={this.state.editApplicationId}
            appTypesData={this.state.appTypeData}
            siteDatas={this.state.siteDatas}
          />
        )}
      </div>
    );
  }
}
