import React, { Component } from 'react';
import Ser1 from "../image/theme/digital--branding.png"
import Ser2 from "../image/theme/Digital-marketing.png"
import Rightarrow from "../image/theme/right-arrow.png"
import { Link } from "react-router-dom";
export default class localPMService extends Component {

    render() {
        return (
            <div className="ph-main-outerdiv ph-space-service">
                <div className='container-lg'>
                    <div className="row">
                        <div className="col-lg-4 d-flex justify-content-center-md align-items-center">
                            <h2 className='ph-explore-service mb-4 mb-md-1'>
                                Explore All Our Services...
                            </h2>
                        </div>

                        {/* <div className="col-lg-4 card mb-4 ph-card"> */}
                        <Link to="/digital-marketing" className="col-lg-4 card mb-4 ph-card solution-cards">
                            <div className="ph-service-img">
                                <img src={Ser2} alt="Ser1" width="400" height="148" className='img-fluid' />
                            </div>
                            <div className="card-body ph-card-body">
                                <h4 className="ph-card-service-title">Digital Marketing</h4>
                                <p className="card-text">Enhance your online presence with digital marketing strategies, content planning, language localisation across digital platforms and more. </p>
                                <div className='ph-card-keywords'>
                                    <span className='ph-keywords ph-light-pink'>
                                        Paid Media
                                    </span>
                                    <span className='ph-keywords ph-light-green'>
                                        Search Engine Marketing
                                    </span>
                                    <span className='ph-keywords ph-grey'>
                                        Strategic Business Insights
                                    </span>
                                    <span className='ph-keywords ph-dark-pink'>
                                        Social Media
                                    </span>

                                </div>
                                <div className='ph-bottom-btn'>
                                <Link to="/digital-marketing" className='ph-explore-now'>
                                        Explore Now
                                        <span>
                                            <img src={Rightarrow} alt="Rightarrow" className='img-fluid' width="20" height="20" />
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        </Link>
                        <Link to="/digital-branding" className="col-lg-4 card mb-4 ph-card solution-cards">
                            <div className="ph-service-img">
                                <img src={Ser1} alt="Ser1" width="400" height="148" className='img-fluid' />
                            </div>
                            <div className="card-body ph-card-body">
                                <h4 className="ph-card-service-title">Digital Branding</h4>
                                <p className="card-text">Establish a strong and credible online presence with our digital branding tools and make a lasting impression on both existing and potential patients.  </p>
                                <div className='ph-card-keywords'>
                                    <span className='ph-keywords ph-light-pink'>
                                        Website Branding
                                    </span>
                                    <span className='ph-keywords ph-light-green'>
                                        PH Practitioner: CMS
                                    </span>
                                    <span className='ph-keywords ph-grey'>
                                        Telehealth Services
                                    </span>
                                    <span className='ph-keywords ph-dark-pink'>
                                        Brand Strategy
                                    </span>
                                    <span className='ph-keywords ph-light-green'>
                                        Content Creation
                                    </span>
                                </div>
                                <div className='ph-bottom-btn'>
                                <Link to="/digital-branding" className='ph-explore-now'>
                                        Explore Now
                                        <span>
                                            <img src={Rightarrow} alt="Rightarrow" className='img-fluid' width="20" height="20" />
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}
