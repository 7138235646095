import React, { Component,useState,useEffect, Suspense } from "react";
import { useParams } from "react-router-dom";
import Edit from "../../image/icons/edit.png";
import tick from "../../image/icons/tick.png";
import Deactivate from "../../image/icons/deactvate.png";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import Header from "../../layout/header"
import Footer from "../../layout/footer"
import Logoimg from "../../image/icons/image-preview.png";
import { apiCalling } from "../../apiService";
import AWS from "aws-sdk";
import $ from 'jquery'
var s3;
var imageFile;

function PharmaEditnewCompany() {
  const { companyId } = useParams();
  const[activePage,setactivePage]=useState(15);
  const[companyName,setcompanyName]=useState('');
  const[hasNetwork,sethasNetwork]=useState('');
  const[hasExclusivity,sethasExclusivity]=useState('');
  const[companyLogoWithPath,setcompanyLogoWithPath]=useState('');
  const[companyLogoOnly,setcompanyLogoOnly]=useState('');
  const[s3bucketAccessKey,sets3bucketAccessKey]=useState('');
  const[AWSAccessKeyId,setAWSAccessKeyId]=useState('');
  const[region,setregion]=useState('us-east-1');
  const[bucket,setbucket]=useState('');
  const[bucketFolder,setbucketFolder]=useState('');
  const[pharmaImagePath,setpharmaImagePath]=useState('');
  const [state, setState] = useState({
    hasNetwork: "",
    hasExclusivity: "",
  });

  useEffect(() => {
    imageUploadHandler();
    getCompanyDetails();
  }, []);
  const handleChange = (e) => {
    sethasNetwork(e.target.value);
  }
  const handleChangeEx = (e) => {
    sethasExclusivity(e.target.value);
  }
 
 const srcTypeImage=(ev)=> {
    ev.target.src = Logoimg;
  }
  const getCompanyDetails=()=> {
    const getAPIData = {
      method: "POST",
      body: JSON.stringify({
        functionName: "editPharmaCompany",
        companyId: companyId
      })
    }
    apiCalling(getAPIData).then((data) => {
      if (data.success == "1") {
        setcompanyName(data.companyData[0].companyName);
        sethasNetwork(data.companyData[0].hasNetwork);
        sethasExclusivity(data.companyData[0].hasExclusivity)
        setcompanyLogoWithPath(data.companyData[0].companyLogoWithPath);
        setcompanyLogoOnly(data.companyData[0].companyLogoOnly);
      }
    })
  }
  const handlePageChange=(pageNumber)=> {
    setactivePage(pageNumber);
  }
 
  const validateForm=()=> {
    if (companyLogoOnly === "") {
      alert("Image required.");
      return false;
    } else {
      return true;
    }
  }
  const fileChange = (e) => {
    imageFile = e.target.files[0];
    if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
      alert("Add file format with only JPG,JPEG,PNG format");
      return false;
    }
    if (imageFile.size > 3145728) {
      alert("File size must under 3 MB!");
      return false;
    } else {
      fileUpload(imageFile);
    }
  }
  const imageUploadHandler=()=> {
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "imagePath",
        siteId: "",
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        $(".loader").hide();
        sets3bucketAccessKey(data.configPathDetails[0].s3bucketAccessKey);
        setAWSAccessKeyId(data.configPathDetails[0].s3bucketSecretKey);
        setbucket(data.configPathDetails[0].PurpleHealthS3bucket);
        setbucketFolder(data.configPathDetails[0].S3BucketMainFolder);
        setpharmaImagePath(data.configPathDetails[0].pharmaImagePath);
      }
    });
  }
  const fileUpload=(file)=> {
    AWS.config.update({
      accessKeyId: s3bucketAccessKey,
      secretAccessKey: AWSAccessKeyId,
      region:region,
    });
    var bucketParams = {
      Bucket:
        bucket +
        "/" +
        bucketFolder +
        "/" +
        pharmaImagePath,
    };
    s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
    let S3BucketMainFolder = "";
    let fileName = uuidv4() + file.name;
    let fullFilePath = S3BucketMainFolder + fileName;
    imageFile = fileName;
    s3.upload(
      {
        Key: fullFilePath,
        Body: file,
        ACL: "public-read",
      },
      (err, data) => {
        if (err) {
          alert("There was an error uploading your photo: ", err.message);
        } else if (data) {
          setcompanyLogoWithPath(data.Location)
          setcompanyLogoOnly(fileName);
          return null;
        }
      }
    );
  }
 const uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };
  const savehandle=()=> {
    if (validateForm) {
      const saveField = {
        method: "POST",
        body: JSON.stringify({
          functionName: "savePharmaCompany",
          companyName:companyName,
          hasNetwork: hasNetwork,
          hasExclusivity: hasExclusivity,
          companyLogo: companyLogoOnly,
          companyId: companyId ? companyId: ""
        })
      }
      $(".loader").show();
      apiCalling(saveField).then((data) => {
        if (data.success === "1") {
          $(".loader").hide();
          alert("Updated successfully");
          cancelHandle();
        }
        else if (data.success === "0") {
          alert(data.errorMessage);
        }
      })
    }
  }
  const cancelHandle=()=> {
    window.location.href = "/adminManagement/pharmadashboardList/pharmaCompanyList"
  }
    return (
      <div className="purpleWrap bg-clr-wthHead">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />{" "}
            <div className='loader'></div>
          </section>
        </Suspense>
        <div className='loader'></div>
        <div className="container mb-5">
          <div>
            <ul className="breadcrum-adminMangmnt">
              <li><a href="/admin/dashboardAdmin">Dashboard</a></li>
              <li><a href="/adminDashboard/pharmaDashboard">Pharma Ad Approval</a></li>
              <li><a href="/adminManagement/pharmadashboardList/pharmaCompanyList">Pharma Company List</a></li>
              <li><a href="" className="current">Edit Company</a></li>
            </ul>
          </div>
        </div>
        <div className="addrepwrapperborder container pt-5 m-auto text-left purple_edit">
          <div className="row">
            <div className="col-md-6">
              <div className="row mb-3">
                <div className="col-md-4">
                  <label className="mb-0">Company Name.<span className="text-danger">*</span></label>
                </div>
                <div className="col-md-8">
                  <input type="text" className="form-control" value={companyName} disabled />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-4">
                  <label className="mb-0">Has Network</label>
                </div>
                <div className="col-md-4">
                  <select name="hasNetwork"   className="slecthsp slecthspMob" onChange={(e) => handleChange(e)}>
                    {hasNetwork === false ? <><option value="0" selected>No</option>
                      <option value="1">Yes</option></> : <><option value="0" >No</option>
                      <option value="1" selected>Yes</option></>}
                  </select>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-4">
                  <label className="mb-0">Has Exclusivity</label>
                </div>
                <div className="col-md-4">
                  <select name="hasExclusivity"   className="slecthsp slecthspMob" onChange={(e) => handleChangeEx(e)}>
                  
                      {hasExclusivity === false ? <><option value="0" selected>No</option>
                      <option value="1">Yes</option></> : <><option value="0" >No</option>
                      <option value="1" selected>Yes</option></>}
                  </select>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-4">
                  <label className="mb-0">Company Logo</label>
                </div>
                <div className="col-md-4">
                  <div className="hspinfo-btnupload mb-auto">
                    <span>Upload</span>
                    <input type="file" className="hspinfoInputfieldupload" onChange={fileChange} />
                  </div>
                  <div className="file_img">
                    {companyLogoWithPath ?
                      <div className="fileUpload-img fileUpload-imgreptop">
                        <img
                          id="companyLogoWithPath"
                          onError={srcTypeImage}
                          src={companyLogoWithPath
                          }
                          width="50"
                          alt="upload"
                        ></img>
                      </div>
                      : ""}
                  </div>
                </div>
                <div className="col-md-12 text-right">
                  <input type="submit" value="Update" className="smssetng-svebtn" onClick={() => savehandle()} />
                  <input type="submit" value="Cancel" className="smssetng-cancelbtn" onClick={cancelHandle} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }

export default PharmaEditnewCompany;