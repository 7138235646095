import React, { useState } from "react";
import { useEffect } from "react";
import Team01 from '../image/theme/team01.png'
import Team02 from '../image/theme/team02.png'
import Team03 from '../image/theme/team03.png'
import Team04 from '../image/theme/team04.png'
import Team05 from '../image/theme/team05.png'
import ArrowFward from '../image/theme/arrow_forward-dark.png'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
function AboutOurTeam() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const [teamDetailArray, setTeamDetailArray] = useState([
        {
            name: "Dr. Nagarjun Mishra",
            description: "Dr. Nagarjun Mishra is the CEO and co-founder of PurpleHealth. An alumnus of Hyderabad Public School, Dr. Mishra is a trained emergency physician from Apollo Hospitals. He comes with a wealth of experience in healthcare investments, technology, insurance, and wellness sectors. Prior to joining PurpleHealth, Dr. Mishra was managing healthcare investments at Tata Industries Limited, the new ventures arm of the Tata Group. Dr. Mishra has also formed and led the wellness teams of Reliance General Insurance and ICICI Lombard, besides being the founding member of one of India's first healthcare portals, Total Health Needs.",
            image: Team01,
            link: "LinkedIn Profile",
            profileLink:"https://www.linkedin.com/in/nagarjunmishra/"
        },
        {
            name: "Mini Balaraman",
            description: "Mini is a co-founder, president and COO of PurpleHealth. Mini holds a Master's degree in computer applications. She is responsible for day-to-day running of operations and works with hospitals and clinic administrators to ensures excellent customer service for all PurpleHealth customers. Mini returned to India after 14 years in the US, having worked at Blue Shield of California and Sun Microsystems(now Oracle) in various technical roles, from software development to business Intelligence. She combines a unique blend of client-facing experience and strong technical knowledge.",
            image: Team02,
            link: "LinkedIn Profile",
            profileLink:"https://www.linkedin.com/in/mini-balaraman-1040a33/"
        },
        {
            name: "Prakash Sathyapalan",
            description: "As CTO and co-founder, Prakash is responsible for all technology and development for PurpleHealth and is focused on making PurpleHealth a world class product. Before returning to India he spent over 14 years in California where he used to work for Stubhub(an eBay company) as a technology manager. Prior to Stubhub he worked for IBM (Cognos Corporation) and SAS Institute. He has over 16 years experience in software development, design and business intelligence. Prakash holds a master's degree in computer science.",
            image: Team03,
            link: "LinkedIn Profile",
            profileLink:"https://www.linkedin.com/in/prakashsathyapalan/"
        },
        {
            name: "Vikram Nair",
            description: "Vikram is a co-founder and Managing Director of PurpleHealth. He has 20 years of experience working with IT companies in leadership, strategy and product design roles in North America and India. As an entrepreneur he has founded multiple start-ups and believes in the ability of technology to change the world.",
            image: Team04,
            link: "LinkedIn Profile",
            profileLink:"https://www.linkedin.com/in/viknair/"
        },
        {
            name: "Balaji Jagannathan",
            description: "Bala has over two decades of proven leadership experience in starting-up businesses, building teams and growing revenues. He has had a big impact in the growth stories of Mindcraft, Sybase, CA, SGI, and SAS. He has been the driving force behind three recent startups, Iaito Infotech, Covacsis & jusTap! Bala currently holds the position Founder & CEO at Katabole.",
            image: Team05,
            link: "LinkedIn Profile",
            profileLink:"https://www.linkedin.com/in/balajijagannathan/"
        }
    ]);

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
        ]
    };
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 767);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <>
            <div className='ph-ourteam'>
                <div className='container-lg'>
                    <h2 className="ph-main-title-2">Our Team</h2>
                    {isMobile ? (
                        <Slider {...settings} className='row'>
                            {teamDetailArray.map((member, index) => (
                                <div className='col-lg-12'>
                                    <div className='ph-our-team-inner-div'>
                                        <div>
                                            <div className='ph-our-team-inner-head'>
                                                <div className='ph-our-team-img'>
                                                    <img src={member.image} className="img-fluid" width="60px" height="60px" alt="team" />
                                                </div>
                                                <div className='ph-our-team-head-title'>
                                                    <h3 className='ph-our-team-title'>{member.name}</h3>
                                                    <a target="_blank" href={member.profileLink}
                                                        className='ph-our-team-linkedin'>{member.link}
                                                        <img src={ArrowFward} className="img-fluid ml-1" />
                                                    </a>
                                                </div>
                                            </div>
                                            <div className='our-team-inner-container'>
                                                <p className='ph-font-1'>
                                                    {member.description}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                            )}

                        </Slider>
                    ) : (
                        <div className='ph-our-team-div'>
                            {teamDetailArray.map((member, index) => (
                                <div className='ph-our-team-inner-div'>
                                    <div className='ph-our-team-inner-head'>
                                        <div className='ph-our-team-img'>
                                            <img src={member.image} className="img-fluid" width="60px" height="60px" alt="team" />
                                        </div>
                                        <div className='ph-our-team-head-title'>
                                            <h3 className='ph-our-team-title'>{member.name}</h3>
                                            <a target="_blank" href={member.profileLink}
                                                className='ph-our-team-linkedin'>{member.link}
                                                <img src={ArrowFward} className="img-fluid ml-1" />
                                            </a>
                                        </div>
                                    </div>
                                    <div className='our-team-inner-container'>
                                        <p className='ph-font-1'>
                                            {member.description}
                                        </p>
                                    </div>
                                </div>
                            )
                            )}                           
                        </div>
                    )}

                </div>
            </div>
        </>
    )
}
export default AboutOurTeam;