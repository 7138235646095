import React, { Component } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import $ from "jquery";
import { apiCalling, changeDateFormat } from "../apiService";
const proxyurl = "https://cors-anywhere.herokuapp.com/";
export default class BookappointmentSuccess extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ratePerSession: "",
      appointmentDate: "",
      doctorName: "",
      doctorEducation: "",
      userName: "",
      typeOfChat: "",
      hospitalName: "",
      hospitalCity: "",
      appointmentType: "",
    };

    this.confimAppointmentForNoPayment = this.confimAppointmentForNoPayment.bind(
      this
    );
    this.cancelAppointment = this.cancelAppointment.bind(this);
    this.createAppointment = this.createAppointment.bind(this);
  }
  componentDidMount() {
    $("#promoApplySection").hide();
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
    this.bindingBookAppointment();
    if (localStorage.getItem("isLogin") == "true") {
    }
  }
  payNowAction() {
    $(".loader").show();
    if ($("#invalidCheck").is(":checked") == true) {
      let email = localStorage.getItem("emailId");
      let mobileNumber = localStorage.getItem("mobileNumber");
      let userName = parseFloat(localStorage.getItem("userName"));
      let amount = parseFloat(this.state.ratePerSession).toFixed(2) * 100;
      let options = {
        key: "rzp_test_RHa5gpfK9ZcZLn",
        amount: amount,
        name: localStorage.getItem("userName"),
        description: "",
        image: "",
        handler: function (response) {
          const paymenTId = response.razorpay_payment_id;
          //alert(paymenTId);
          fetch(
            proxyurl + "https://api.razorpay.com/v1/payments/" + paymenTId,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization:
                  "Basic cnpwX3Rlc3RfTlVhaE94endlZVZCZXI6WHZiNXlLQVVrYzBqTVNBRENCSloxRXhE",
                Accept: "application/json",
                "Cache-Control": "no-cache",
                Host: "api.razorpay.com",
              },
            }
          )
            .then((response) => response.json())
            .then((responseJson) => {
              var razorTransferDetails = responseJson;
              if (razorTransferDetails) {
                var element = JSON.parse(localStorage.getItem("appArray"));
                let newList = Object.assign(razorTransferDetails, element);
                const apiJson = {
                  method: "POST",
                  body: JSON.stringify(newList),
                };
                //alert("spet 1 ok");
                apiCalling(apiJson).then((data) => {
                  //$(".asdf").text(JSON.stringify(data));
                  localStorage.setItem(
                    "appointmentSuccessArray",
                    JSON.stringify(data)
                  );
                  $(".loader").hide();
                  if (data.success == "1") {
                    alert(data.successMessage);
                    window.location.href = "../patient/textchatsuccess";
                  } else {
                    alert(data.errorMessage);
                    window.location.href = "../patient/textchatsuccess";
                  }
                });
              }
            });
        },
        prefill: {
          name: userName,
          email: email,
          contact: mobileNumber,
        },
        // "notes": {
        //     "address":
        // },
        theme: {
          color: "#514886",
        },
      };

      let rzp = new window.Razorpay(options);
      rzp.open();
    } else {
      alert("Please agree with terms and conditions");
    }
  }
  havePromoCode() {
    $("#promoApplySection").show();
    $("#havePromoCode").hide();
  }
  cancelAppointment() {
    window.location.href = "../home/doctor-list-main";
  }
  createAppointment(arryset) {
    let resMessage = "";
    //let arryset = JSON.parse(localStorage.getItem("appArray"));
    const apiJson = {
      method: "POST",
      body: JSON.stringify(arryset),
    };
    // alert(apiJson.body);
    //apiCalling(apiJson).then((data) => {
    // if (data.success == "1") {
    //   resMessage = data.appointmentId;
    //  } else {
    //    alert(data.errorMessage);
    //  }
    // });
    return resMessage;
  }
  //none payment appointment
  confimAppointmentForNoPayment() {
    if ($("#invalidCheck").is(":checked") == true) {
      $(".loader").show();
      let arryset = JSON.parse(localStorage.getItem("appArray"));
      const apiJson = {
        method: "POST",
        body: JSON.stringify(arryset),
      };
      apiCalling(apiJson).then((data) => {
        if (data.success == "1") {
          $(".loader").hide();
          alert(data.successMessage);
          window.location.href = "../patient/videocalllist";
        } else {
          alert(data.errorMessage);
          $(".loader").hide();
          window.location.href = "../patient/bookappointment";
        }
      });
    } else {
      alert("Please agree with terms and conditions");
    }
  }
  //Binding data
  bindingBookAppointment() {
    let arryset = JSON.parse(localStorage.getItem("appArray"));
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "appointmentConfirmation",
        userId: localStorage.getItem("userId"),
        siteId: localStorage.getItem("siteId"),
        doctorId: arryset.doctorId,
        hospitalId: arryset.hospitalId,
        appointmentType: arryset.appointmentType,
        appointmentDate: arryset.appointmentDate,
        appointmentTime: arryset.appointmentTime,
        reasonForVisit: arryset.reasonForVisit,
        appointmentPerson: "",
        dateOfBirth: "",
        firstName: "",
        gender: "",
        lastName: "",
        newUserDOB: "",
        newUserGender: "",
      }),
    };
    $(".loader").show();
    apiCalling(apiJson).then((data) => {
      if (data.success == "1") {
        $(".loader").hide();
        var month = new Array();
        month[0] = "JAN";
        month[1] = "FEB";
        month[2] = "MARCH";
        month[3] = "APRIL";
        month[4] = "MAY";
        month[5] = "JUNE";
        month[6] = "JULY";
        month[7] = "AUG";
        month[8] = "SEP";
        month[9] = "OCT";
        month[10] = "NOV";
        month[11] = "DEC";
        var newAppDate = "";
        if (data.doctorAndAppointmentData[0].appointmentDate) {
          const a = data.doctorAndAppointmentData[0].appointmentDate.split("/");
          newAppDate = a[1] + "/" + a[0] + "/" + a[2];
        }
        var DateApp = new Date(newAppDate);
        var month = month[DateApp.getMonth()];
        var date = DateApp.getDate();
        var year = DateApp.getFullYear();
        var appDate = month + " " + date + " " + year;
        this.setState({
          ratePerSession: data.doctorAndAppointmentData[0].ratePerSession,
          appointmentDate:
            appDate + " " + data.doctorAndAppointmentData[0].appointmentTime,
          appointmentTime: data.doctorAndAppointmentData[0].appointmentTime,
          appointmentType: data.doctorAndAppointmentData[0].appointmentType,
          doctorEducation: data.doctorAndAppointmentData[0].doctorEducation,
          doctorId: data.doctorAndAppointmentData[0].doctorId,
          doctorName: data.doctorAndAppointmentData[0].doctorName,
          hospitalCity: data.doctorAndAppointmentData[0].hospitalCity,
          hospitalId: data.doctorAndAppointmentData[0].hospitalId,
          hospitalName: data.doctorAndAppointmentData[0].hospitalName,
          typeOfChat: data.doctorAndAppointmentData[0].typeOfChat,
          userId: data.doctorAndAppointmentData[0].userId,
          userName: data.doctorAndAppointmentData[0].userName,
        });
      } else {
        alert(data.errorMessage);
      }
    });
  }

  render() {
    return (
      <div className="purpleWrap">
        <Header></Header>
        <div className="container">
          <div className="main-wrapper main-wrapper-padding">
            <div className="form-section form-section-padng">
              <div className="col-sm-12 p-0 mb-3">
                <h3 className="head-text">
                  Appointment Request Date &amp; Time :{" "}
                  {this.state.appointmentDate}{" "}
                </h3>
              </div>

              <div className="col-md-12 mb-4 p-0 asdf">
                {this.state.doctorEducation ? (
                  <p className="font-bold mb-0">
                    {this.state.doctorName}, {this.state.doctorEducation}{" "}
                  </p>
                ) : (
                  <p className="font-bold mb-0">{this.state.doctorName} </p>
                )}
                {this.state.hospitalCity ? (
                  <p className="font-bold mb-0">
                    {this.state.hospitalName}, {this.state.hospitalCity}{" "}
                  </p>
                ) : (
                  <p className="font-bold mb-0">{this.state.hospitalName} </p>
                )}
              </div>

              <div className="row row-margin font-bold">
                <label className="col-sm-3 label-text">Patient Name </label>
                <label className="col-sm-6 ">{this.state.userName}</label>
              </div>

              <div className="row row-margin">
                <label className="col-sm-3 label-text">Type Of Appointment</label>
                <label className="col-sm-6 "> {this.state.appointmentType}</label>
              </div>

              <div className="row row-margin">
                <label className="col-sm-3 label-text">Type Of Video Chat </label>
                <label className="col-sm-6 ">{this.state.typeOfChat}</label>
              </div>

              <div className="row row-margin">
                <div className="col-sm-12">
                  <input type="checkbox" id="invalidCheck" />
                  <label className="label-text ml-3" for="invalidCheck">
                    I agree with{" "}
                    <a href="#" className="link-color">
                      Terms and Conditions
                    </a>
                  </label>
                </div>
              </div>

              {/*<div className="row row-margin" id="havePromoCode">
                <label
                  className="col-sm-12 link-color"
                  onClick={(e) => {
                    this.havePromoCode();
                  }}
                >
                  Have a Promo code?
                </label>
              </div>*/}
              <div className="alert warnalert alert-danger col-md-12 mrgBottom alertScreen text-left smlNotification">
                You will need to pay Rs.{this.state.ratePerSession} to speak
                with {this.state.doctorName}, {this.state.doctorEducation} at
                Rs.{this.state.ratePerSession} /session on{" "}
                {this.state.hospitalName}.
              </div>

              <div className="row row-margin" id="promoApplySection">
                <label className="col-sm-3 label-text">Promo code</label>
                <label className="col-sm-6 ">
                  <input type="text" className="input-design input-box-w-150" />
                  <span className="link-color ml-2">Apply</span>
                </label>
              </div>
            </div>

            <div className="col-sm-12">
              {this.state.ratePerSession != "0.00" ? (
                <a href="#">
                  <input
                    type="submit"
                    value="Pay Now"
                    className="formButton"
                    onClick={(e) => {
                      this.payNowAction();
                    }}
                  ></input>
                </a>
              ) : (
                <a href="#">
                  <input
                    type="submit"
                    value="Confirm"
                    className="formButton"
                    onClick={this.confimAppointmentForNoPayment}
                  ></input>
                </a>
              )}
              <input
                type="button"
                value="Cancel"
                className="formButton cancelButton"
                onClick={this.cancelAppointment}
              ></input>
            </div>
          </div>
        </div>

        <Footer></Footer>
      </div>
    );
  }
}
