import React, { Component, Suspense } from "react";
import leftarrow from "../image/icons/left-arrow-hsp.png";
import { Link } from "react-router-dom";
import Header from "./header";
import Footer from "./footer";

class relianceDashboard extends React.Component {
  render() {
    return (
      <div>
        <Header></Header>

        <div className="purpleWrap bg-clr-wthHead">
          <Suspense fallback={<div></div>}>
            <section>
              <div className="container dashboardhspOuter">
                {/* <div className="backArowdImgdiv">
                <a href="/admin/dashboard">
                  <img src={leftarrow} alt="leftarrow" width="18" />
                  <span className="ml-2">Back</span>
                </a>
              </div> */}
                <div className="sectionItemDiv sectionItemDivBorder sectionBorderadminMangmnt">
                  <div className="adminMangemnt-dashboard">
                    <div className="admin-dashBtns">
                      <Link
                        className="sectionTag"
                        to={"/reliance/videoaudioReports/" + 180904}
                      >
                        Video /Audio Chat Payment Report
                      </Link>
                    </div>
                    <div className="admin-dashBtns">
                      <Link
                        className="sectionTag"
                        to={"/reliance/textChatReports/" + 180904}
                      >
                        Text Chat Payment Report
                      </Link>
                    </div>
                    <div className="admin-dashBtns">
                      <Link
                        className="sectionTag"
                        to={"/reliance/rha-dashboard/"}
                      >
                        RHA Dashboard
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Suspense>
        </div>

        <Footer></Footer>
      </div>
    );
  }
}

export default relianceDashboard;
