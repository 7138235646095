import React, { Component } from 'react';
import Header from "../layout/header";
import Footer from "../layout/footer";
import $ from "jquery";
import tick from "../image/media/tick.png";
import { apiCalling } from "../apiService";


export default class OldChatMessage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            oldChats: []
        };
    }
    componentDidMount() {
        $("#promoApplySection").hide();
        const apiJson = {
            method: "POST",
            body: JSON.stringify({
                functionName: "chat/getOldChatQuestions",
                siteId: localStorage.getItem("siteId"),
                hospitalId: "",
                userId: localStorage.getItem("userId"), //"19906",
                bundleId: "",
            }),
        };
        $(".loader").show();
        apiCalling(apiJson).then((data) => {
            if (data.success == "1") {
                $(".loader").hide();
                this.setState({
                    oldChats: data.resultArray.oldChats
                });
            }
        });
    }
    havePromoCode() {
        $("#promoApplySection").show()
        $("#havePromoCode").hide();
    }

    render() {
        return (
            <div className="purpleWrap">
                <Header></Header>
                <div className="container container-auto">
                    <div className="main-wrapper border-0 px-0 px-md-5">
                        <div className="col-md-12 p-0">
                            <ul className="breadcrumb">
                                <li>
                                    {localStorage.getItem("role") == "ROLE_USER" ?
                                        <a href="/home/doctor-list-main">Messaging</a>
                                        :
                                        <a href="/doctor/dashboard">Dashboard</a>
                                    }
                                    <i className="fa fa-chevron-right"></i></li>
                                <li>Old Chat Questions</li>
                            </ul>
                        </div>
                        <div className="chat-outer">
                            <div className="chat-header">
                                <span className="chat-span-head">
                                    <i className="fa fa-comment"></i> Old Chat Questions
                                </span>
                                <span className="chat-span">
                                    <a href="/chatmessagegroup">
                                        <button type="button" className="btn-default" >Active Chat(s)</button>
                                    </a>
                                </span>
                                <span className="chat-span">
                                    {localStorage.getItem("role") == "ROLE_USER" ?
                                        <a href="/home/doctor-list-main">
                                            <button type="button" className="btn-default" >Start New Chat</button>
                                        </a>
                                        : ""}
                                </span>
                            </div>
                            <div className="chat-bodygroup">
                                {this.state.oldChats.length > 0 ?
                                    <ul className="messages">
                                        {this.state.oldChats.map((old) => (
                                            <li className="message mb-0">
                                                <a href="/chatmessage" className="text-decoration-none">
                                                    <div className="avatar">
                                                        <img src={old.imageName} className="avatarimg"></img>
                                                        <span className="chatBubleOnline">
                                                            {old.isLogged == true ?
                                                                <img src={tick} />
                                                                : ""}
                                                        </span>
                                                    </div>
                                                    <div className="textwrappergroup">
                                                        <div className="text-header">
                                                            {localStorage.getItem("role") == "ROLE_USER" ?
                                                                <h6 className="color-maintext">{old.memberName}</h6>
                                                                :
                                                                <h6 className="color-maintext">{old.doctorName} - {old.hospitalName}</h6>
                                                            }
                                                            <time className="message-time">  <i className="fa fa-clock-o"></i> {old.modifiedOn}</time>
                                                        </div>
                                                        <p className="text">{old.message}</p>
                                                    </div>
                                                </a>
                                                <div className="divider"></div>
                                            </li>
                                        ))}
                                    </ul>
                                    : ""}
                            </div>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
            </div>
        )
    }
}
