import React, { Component } from "react";
import * as ReactDOM from "react-dom";
import Header from "../layout/header";
import Footer from "../layout/footer";
import Dashboardleftmenu from "../doctor/dashboardleftmenu";
import $ from "jquery";
import rx from "../image/media/rx.png";

import Popup from "reactjs-popup";
import { apiCalling, changeDateFormat } from "../apiService";

import moment from "moment";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";

let format = "hh:mm a";
let now = moment().hour(0).minute(0);

export default class VideoSchedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openAppointments: [],
      missedAppointments: [],
      todayAppointments: [],
      completedAppointments: [],
      futureAppointments: [],
      currentAppointments: [],
      videoAppointments: [],
      hospitalList: [],
      doctorCurrentAppointments: [],
      timePickerValue: moment(),
    };
    this.chatNow = this.chatNow.bind(this);
    this.confirmAppointment = this.confirmAppointment.bind(this);
    this.rejectOpenAppointment = this.rejectOpenAppointment.bind(this);
    this.enableDisableChat = this.enableDisableChat.bind(this);
    this.enterChatButton = this.enterChatButton.bind(this);
  }

  enterChatButton() {
    let hospitalId = $("#enterchatHospital").val();
    if (hospitalId) {
      $(".loader").show();
      const apiJson = {
        method: "POST",
        body: JSON.stringify({
          functionName: "enterChat",
          doctorId: localStorage.getItem("userId"),
          hospitalId: hospitalId,
        }),
      };
      apiCalling(apiJson).then((data) => {
        if (data.success == "1") {
          alert("Successfully updated");
        }
        $(".loader").hide();
      });
    } else {
      alert("Please select billing hospital");
    }
  }
  rejectOpenAppointment(id) {
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "rejectVideoChat",
        requestVideoAppointmentId: id,
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success == "1") {
        alert("Rejected Successfully");
      }
      $(".loader").hide();
    });
  }
  enableDisableChat(openByAdmin, videoChatTransactionId) {
    $(".loader").show();

    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "enable/disableVideoChat",
        status: openByAdmin == false ? "1" : "0",
        videoChatTransactionId: videoChatTransactionId,
      }),
    };

    apiCalling(apiJson).then((data) => {
      if (data.success == "1") {
      }
      $(".loader").hide();
    });
  }
  chatNow(
    room,
    doctorVideoScheduleId,
    memberId,
    hospitalId,
    doctorId,
    callType
  ) {
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "acceptCall",
        doctorVideoScheduleId: doctorVideoScheduleId,
        patientId: memberId,
        hospitalId: hospitalId,
        doctorId: doctorId,
        videoChatRoomId: room,
        siteId: localStorage.getItem("siteId"),
        bundleId: "",
      }),
    };

    apiCalling(apiJson).then((data) => {
      if (data.success == "1") {
        localStorage.setItem("apiKey", data.result[0].apiKey);
        localStorage.setItem("sessionId", data.result[0].sessionId);
        localStorage.setItem("token", data.result[0].token);
        localStorage.setItem("doctorVideoScheduleId", doctorVideoScheduleId);
        localStorage.setItem("drcallType", callType);
        window.location.href = "/doctor/Videocall";
      } else {
        alert(data.errorMessage);
      }
      $(".loader").hide();
    });
  }
  confirmAppointment(id) {
    $(".loader").show();
    let appDate = $("#datePick" + id).val();
    let appTime = $("#timePick" + id).val();
    let newDates = changeDateFormat(appDate);

    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "confirmVideoAppointment",
        appointmentDate: newDates,
        appointmentTime: appTime,
        requestVideoAppointmentId: id,
        ratePerSession: "",
        ratePerSessionAudio: "",
        siteId: localStorage.getItem("siteId"),
        isConfirmAndDeduct: "",
        isReferralWithOtp: "",
      }),
    };
    apiCalling(apiJson).then((data) => {
      $(".loader").hide();
      if (data.success == "1") {
        alert(data.successMessage);
      } else {
        alert(data.errorMessage);
      }
    });
  }
  componentDidMount() {
    //$(".loader").show();
    this.checkFunction("init");
    //$(".loader").hide();

    setInterval(async () => {
      this.checkFunction("loop");
    }, 5000);
  }
  checkFunction(fg) {
    if (fg.includes("init")) {
      $(".loader").show();
    }
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "videoAudioDoctorAppointment",
        siteId: localStorage.getItem("siteId"),
        doctorId: localStorage.getItem("userId"),
      }),
    };
    //  alert(apiJson.body);
    apiCalling(apiJson).then((data) => {
      if (data.success == "1") {
        // alert(data.openAppointments);
        this.setState({
          openAppointments: data.openAppointments,
          missedAppointments: data.missedAppointments,
          todayAppointments: data.todayAppointments,
          completedAppointments: data.completedAppointments,
          futureAppointments: data.futureAppointments,
          currentAppointments: data.currentAppointments,
          videoAppointments: data.videoAppointments,
          hospitalList: data.hospitalList,
          doctorCurrentAppointments: data.doctorCurrentAppointments,
        });
        if (data.openAppointments.length == 0) {
          $(".OpenAppointments").hide();
        }
        if (data.missedAppointments.length == 0) {
          //$(".FutureAppointments").hide();
        }
        if (data.todayAppointments.length == 0) {
          // $(".FutureAppointments").hide();
        }
        if (data.completedAppointments.length == 0) {
          $(".CompletedAppointments").hide();
        }
        if (data.futureAppointments.length == 0) {
          $(".FutureAppointments").hide();
        }
        if (data.currentAppointments.length == 0) {
          $(".CurrentAppointments").hide();
        }
        if (data.doctorCurrentAppointments.length == 0) {
          $(".doctorCurrentAppointments").hide();
        } else {
          $(".CurrentAppointments").hide();
          $(".doctorCurrentAppointments").show();
        }
      } else {
        alert(data.errorMessage);
      }
      if (fg.includes("init")) {
        $(".loader").hide();
      }
    });
  }
  render() {
    return (
      <div className="purpleWrap">
        <Header></Header>

        <div className="container container-auto">
          <div className="main-wrapper1">
            <div className="row">
              <div className="col-lg-4">
                <Dashboardleftmenu></Dashboardleftmenu>
              </div>
              <div className="col-lg-8">
                <div className="dashboard-right">
                  <div className="border error-msg py-4 px-1 mb-3 text-center">
                    <h6 className="color-maintext text-center enterChat">
                      You are currently not scheduled, press "ENTER CHAT" to be
                      available to chat.
                    </h6>
                    <div className="table-responsive enterChat">
                      <div className="adminLeftBoxBlock">
                        <p>
                          Billing Hospital :
                          <select className="input-design" id="enterchatHospital">
                            {this.state.hospitalList.map((obj) => {
                              return (
                                <option value={obj.hospitalId}>
                                  {obj.hospitalName}
                                </option>
                              );
                            })}
                          </select>
                          <button
                            className="btn-bg pull-right"
                            id="enterChatBut"
                            onClick={this.enterChatButton}
                          >
                            Enter Chat
                          </button>
                        </p>
                      </div>
                    </div>
                    <h6 className="color-maintext text-center doctorCurrentAppointments">
                      Dr Current Appointments
                    </h6>
                    <div className="table-responsive doctorCurrentAppointments">
                      <table className="table table-bordered form-section-bgcolor">
                        <thead>
                          <th>Date & Time of Appointment</th>
                          <th>Patient Name</th>
                          <th>Service</th>
                          <th>Is Session Based</th>
                          <th>Status</th>
                          <td></td>
                        </thead>
                        <tbody id="drcrtBody">
                          {this.state.doctorCurrentAppointments.map(
                            (obj, index) => {
                              let appD = new Date(obj.scheduledStartTime);
                              return (
                                <tr>
                                  <td>
                                    {appD.toDateString()}{" "}
                                    {appD.toLocaleTimeString("en-IN", {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      hour12: true,
                                    })}
                                  </td>
                                  <td>{obj.patientName} </td>
                                  <td>
                                    {obj.hasAudioChat == true
                                      ? "Audio"
                                      : "Video"}{" "}
                                  </td>
                                  <td>
                                    {obj.isSessionBased == true ? "Yes" : "No"}
                                  </td>
                                  <td>{obj.appStatus}</td>
                                  <td className="text-center">
                                    {this.state.videoAppointments.map(
                                      (vid, vin) => {
                                        if (
                                          obj.startTime === vid.startTime &&
                                          vid.active === true
                                        ) {
                                          return (
                                            <a
                                              // href="/doctor/Videocall"
                                              href="#"
                                              onClick={() =>
                                                this.chatNow(
                                                  vid.room,
                                                  obj.doctorVideoScheduleId,
                                                  obj.memberId,
                                                  obj.hospitalId,
                                                  obj.doctorId,
                                                  obj.hasAudioChat == true
                                                    ? "audio"
                                                    : "video"
                                                )
                                              }
                                            >
                                              <button className="btn-bg">
                                                Chat Now
                                              </button>
                                            </a>
                                          );
                                        }
                                      }
                                    )}

                                    <a href="#">
                                      <button className="btn-bg cancelButtonBg">
                                        Cancel
                                      </button>
                                    </a>
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                    </div>
                    <h6 className="color-maintext text-center CurrentAppointments">
                      Current Appointments
                    </h6>
                    <div className="table-responsive CurrentAppointments">
                      <table className="table table-bordered form-section-bgcolor">
                        <thead>
                          <th>Requested Date</th>
                          <th>Patient Name</th>
                          <th>Service</th>
                          <th>Is Session Based</th>
                          <th>Date & Time of Appointment</th>
                        </thead>
                        <tbody id="drcrtBody">
                          {this.state.currentAppointments.map((obj, index) => {
                            let appD = new Date(obj.appointmentDate);
                            return (
                              <tr>
                                <td>
                                  {" "}
                                  {appD.toDateString()}{" "}
                                  {appD.toLocaleTimeString("en-IN", {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: true,
                                  })}{" "}
                                </td>
                                <td>{obj.patientName} </td>
                                <td>
                                  {" "}
                                  {obj.hasAudioChat == true
                                    ? "Audio"
                                    : "Video"}{" "}
                                </td>
                                <td>
                                  {" "}
                                  {obj.isSessionBased == true ? "Yes" : "No"}
                                </td>
                                <td className="text-center">
                                  <a href="#">
                                    <button className="btn-bg cancelButtonBg">
                                      Cancel
                                    </button>
                                  </a>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <h6 className="color-maintext text-center FutureAppointments">
                      Future Appointments
                    </h6>
                    <div className="table-responsive FutureAppointments">
                      <table className="table table-bordered form-section-bgcolor">
                        <thead>
                          <th>Date & Time </th>
                          <th>Patient Name</th>
                          <th>Service</th>
                          <th>Is Session Based</th>
                          <th>Requested Date</th>
                        </thead>
                        <tbody>
                          {this.state.futureAppointments.map((obj, index) => {
                            let appD = new Date(obj.appointmentDate);
                            let reqD = new Date(obj.requestedDate);

                            return (
                              <tr>
                                <td>
                                  {" "}
                                  {appD.toDateString()}{" "}
                                  {appD.toLocaleTimeString("en-IN", {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: true,
                                  })}
                                </td>
                                <td>{obj.patientName} </td>
                                <td>
                                  {obj.hasAudioChat == true ? "Audio" : "Video"}{" "}
                                </td>
                                <td>
                                  {obj.isSessionBased == true ? "Yes" : "No"}
                                </td>
                                <td className="text-center">
                                  {reqD.toDateString()}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <h6 className="color-maintext text-center OpenAppointments">
                    Open Appointments
                  </h6>
                  <div className="table-responsive OpenAppointments">
                    <table className="table table-bordered form-section-bgcolor">
                      <thead>
                        <th>Requested Date </th>
                        <th>Patient Name</th>
                        <th>Service</th>
                        <th>Is Session Based</th>
                        <th>Date & Time</th>
                        <td>Date & Time</td>
                        <th></th>
                      </thead>
                      <tbody>
                        {this.state.openAppointments.map((obj, index) => {
                          let appD = new Date(obj.appTime);
                          let reqD = new Date(obj.requestedDate);
                          var year = appD.getFullYear();
                          var month = ("0" + (appD.getMonth() + 1)).slice(-2);
                          var day = ("0" + appD.getDate()).slice(-2);
                          let showDate = year + "-" + month + "-" + day; //"2020-8-3";
                          // $("#" + obj.id).val(showDate);
                          return (
                            <tr>
                              <td>{reqD.toDateString()} </td>
                              <td>{obj.patientName} </td>
                              <td>{obj.appointmentType}</td>
                              <td>
                                {obj.isSessionBased == true ? "Yes" : "No"}
                              </td>
                              <td>
                                {appD.toDateString()}{" "}
                                {appD.toLocaleTimeString("en-IN", {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: true,
                                })}
                              </td>
                              <td>
                                <p className="d-flex">
                                  <input
                                    type="date"
                                    className="input-design date-time-design appDateChages"
                                    placeholder="Appointment Date"
                                    id={"datePick" + obj.id}
                                    // value={moment(obj.appTime)}
                                    //min={this.state.maxDate}
                                    //value="2020-12-12" //{showDate} //{showDate}
                                    //data-date-format="DD/MM/YYYY"
                                  />
                                  <TimePicker
                                    id={"timePick" + obj.id}
                                    showSecond={false}
                                    defaultValue={now}
                                    value={moment(obj.appTime)}
                                    className="time-picker-time form-fields"
                                    format="hh:mm a"
                                    use12Hours
                                  />
                                </p>
                              </td>
                              <td className="text-center">
                                <p className="d-flex">
                                  <a
                                    href="#"
                                    onClick={() =>
                                      this.confirmAppointment(obj.id)
                                    }
                                  >
                                    <button className="btn-bg">Confirm</button>
                                  </a>
                                  <a href="#">
                                    <button className="btn-bg cancelButtonBg">
                                      Reject
                                    </button>
                                  </a>
                                </p>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>

                  <h6 className="color-maintext text-center CompletedAppointments">
                    Completed Appointments
                  </h6>
                  <div className="table-responsive CompletedAppointments">
                    <table className="table table-bordered form-section-bgcolor">
                      <thead>
                        <th>Date &amp; Time of Appointment</th>
                        <th>Patient Name</th>
                        <th>Service</th>
                        <th>Requested Date</th>
                        <th>Status</th>
                        <th></th>
                      </thead>
                      <tbody>
                        {this.state.completedAppointments.map((obj, index) => {
                          let reqD = new Date(obj.requestedDate);
                          let dates = new Date(obj.appointmentDate);
                          return (
                            <tr>
                              <td>
                                {dates.toDateString()}{" "}
                                {dates.toLocaleTimeString("en-IN", {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: true,
                                })}
                              </td>
                              <td>{obj.patientName} </td>
                              <td>
                                {" "}
                                {obj.hasAudioChat == true
                                  ? "Audio"
                                  : "Video"}{" "}
                              </td>
                              <td>
                                {" "}
                                {reqD.toDateString()}{" "}
                                {reqD.toLocaleTimeString("en-IN", {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: true,
                                })}{" "}
                              </td>
                              <td>Completed-{obj.openByAdmin}</td>
                              <td className="text-center">
                                <a
                                  href="#"
                                  onClick={() =>
                                    this.enableDisableChat(
                                      obj.openByAdmin,
                                      obj.videoChatTransactionId
                                    )
                                  }
                                >
                                  {obj.openByAdmin == false
                                    ? "Enable Video Chat"
                                    : "Disable Video Chat"}
                                </a>
                                {/*<a href="/doctor/Videocall">
                                  <button className="btn-bg w-100">Chat Now</button>
                                </a>
                                <a href="#">
                                  <button className="btn-bg cancelButtonBg w-100 my-1">
                                    Call Completed
                                  </button>
                                </a>
                                <a href="#">
                                  <Popup
                                    trigger={<img src={rx} width="20"></img>}
                                    modal
                                  >
                                    {(close) => (
                                      <div className="modal-popup">
                                        <a
                                          className="close-popup"
                                          onClick={close}
                                        >
                                          &times;
                                        </a>
                                        <div className="header border-0  text-left">
                                          {" "}
                                          Prescribe Medicine{" "}
                                        </div>

                                        <div className="content">
                                          <div className="row">
                                            <div className="col-lg-12">
                                              <textarea
                                                className="input-design"
                                                rows="5"
                                              ></textarea>
                                            </div>
                                          </div>
                                          <div className="text-center">
                                            <a href="#">
                                              <input
                                                type="submit"
                                                value="Send"
                                                className="formButtonBg"
                                              ></input>
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Popup>
                                </a>*/}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>

                  <div className="adminDashboardBox row">
                    <div className="col-md-7">
                      <div className="adminLeftBox form-section-bgcolor">
                        <div className="adminLeftBoxBlock">
                          <p>
                            Profile
                            <a href="/doctor/doctorprofile">
                              <button className="btn-bg pull-right">Enter</button>
                            </a>
                          </p>
                        </div>
                        <div className="adminLeftBoxBlock">
                          <p>
                            Appointment History
                            <a href="/doctor/chat-history">
                              <button className="btn-bg pull-right">Enter</button>
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="adminRightBox form-section-bgcolor">
                        <div className="requirementBox">
                          <p>Minimum requirement for video chat</p>
                          <ul className="px-3">
                            <li>
                              Latest version of Chrome 30+
                              <a
                                className="linktextclr"
                                href="https://www.google.com/intl/en_uk/chrome/browser/"
                                target="_blank"
                              >
                                Download Chrome
                              </a>
                            </li>
                            <li>
                              Wired internet connection with least 500KBps speed
                            </li>
                            <li>A webcam </li>
                            <li>A microphone</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer></Footer>
      </div>
    );
  }
}
