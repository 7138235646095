import React, { useState  } from 'react';
// import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import Newslistblocks from "./new-list-blocks"
function NewsLists() {
  

    return (
        <div className="ph-main-outerdiv">
            <div className="ph-success  ph-space-service mb-5 ">
                    <div className="ph-db-banner text-md-center mb-4">
                        <span className="ph-banner-title1">
                            Resources
                        </span>
                    </div>
                    <h2 className="ph-main-title-2 text-md-center">
                       News
                        <div className='text-md-center ph-success-story-subtitle pt-2 '>
                        PurpleHealth News Room
                        </div>
                    </h2>
                    {/* <div className="ph-serach">
                        <div className='ph-position'>
                            <input class="form-control mr-sm-2" type="text" placeholder="Search Success Stories" aria-label="Search" />
                           <div className='ph-search-icon'>
                            <img src={Search} alt="serach" width="25" height="25" className='img-fluid'/>
                           </div>
                           <div className='ph-search-button'>
                                <button className='ph-search-btn'>Search</button>
                           </div>
                        </div>
                    </div> */}
                </div>
            <div className='container'>
              <Newslistblocks blogDetailFlag={false} newsId={""}/>
              </div>
        </div>
    );
}

export default NewsLists;
