import React, { Component } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { divide } from "lodash";
import { apiCalling } from "../apiService";

export default class VideochatSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transactionId: "",
      doctorName: "",
      hospitalName: "",
      ChatStartTime: "",
      ChatEndTime: "",
      drrating: "",
      VCrating: "",
      suggestions: "",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.submitSummery = this.submitSummery.bind(this);
  }
  handleInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };
  submitSummery = (event) => {
    let drrating = this.state.drrating;
    let VCrating = this.state.VCrating;
    let suggestions = this.state.suggestions;
    let startChatData = JSON.parse(localStorage.getItem("startChatData"));
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "video/saveFeedback",
        userId: startChatData.result[0].memberId,
        hospitalId: startChatData.result[0].hospitalId,
        bundleId: "",
        doctorQuality: drrating,
        videoQuality: VCrating,
        message: suggestions,
        videoTransactionId: localStorage.getItem("videochatTransactionId"),
        siteId: localStorage.getItem("siteId"),
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success == "1") {
        alert(data.successMessage);
        window.location.href = "/patient/videocalllist";
      } else {
        alert(data.errorMessage);
        window.location.href = "/patient/videocalllist";
      }
    });
  };
  componentDidMount() {
    let self = this;
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "summaryPage",
        videoTransactionId: localStorage.getItem("videochatTransactionId"),
        siteId: localStorage.getItem("siteId"),
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success == "1") {
        this.setState({
          transactionId: data.summaryResultData[0].transactionId,
          doctorName: data.summaryResultData[0].doctorName,
          hospitalName: data.summaryResultData[0].hospitalName,
          ChatStartTime: data.summaryResultData[0].ChatStartTime,
          ChatEndTime: data.summaryResultData[0].ChatEndTime,
        });
      }
    });
  }
  render() {
    return (
      <div className="purpleWrap">
        <Header></Header>
        <div className="container">
          <div className="main-wrapper">
            <div className="col-sm-12 text-center">
              <h3 className="doctor-name">
                Your Video Chat with {this.state.doctorName} of{" "}
                {this.state.hospitalName} Ended Successfully!
              </h3>
              <h4 className="my-4">Video Chat Summary</h4>
              <div className="row row-margin">
                <label className="col-sm-6 label-text text-right">
                  Transaction Id
                </label>
                <label className="col-sm-6 text-left">
                  {this.state.transactionId}
                </label>
              </div>
              <div className="row row-margin">
                <label className="col-sm-6 label-text text-right">
                  Video Chat Start Time
                </label>
                <label className="col-sm-6 text-left">
                  {this.state.ChatStartTime}
                </label>
              </div>
              <div className="row row-margin">
                <label className="col-sm-6 label-text text-right">
                  Video Chat End Time
                </label>
                <label className="col-sm-6 text-left">
                  {this.state.ChatEndTime}
                </label>
              </div>
            </div>
            <div className="form-section form-section-bgcolor pb-3">
              <h5 className="my-4 text-center">Your Feedback</h5>
              <div className="row row-margin">
                <label className="col-sm-4 label-text label-text-right">
                  Doctor Rating
                </label>
                <div className="col-sm-8 ">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="drrating"
                      value="Low"
                      onChange={this.handleInputChange}
                    />
                    <label className="form-check-label">Low</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="drrating"
                      value="Average"
                      onChange={this.handleInputChange}
                    />
                    <label className="form-check-label">Average</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="drrating"
                      value="Good"
                      onChange={this.handleInputChange}
                    />
                    <label className="form-check-label">Good</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="drrating"
                      value="Very Good"
                      onChange={this.handleInputChange}
                    />
                    <label className="form-check-label">Very Good</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="drrating"
                      value="Excellent"
                      onChange={this.handleInputChange}
                    />
                    <label className="form-check-label">Excellent</label>
                  </div>
                </div>
              </div>

              <div className="row row-margin">
                <label className="col-sm-4 label-text label-text-right">
                  Video Quality
                </label>
                <div className="col-sm-8 ">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="VCrating"
                      value="Low"
                      onChange={this.handleInputChange}
                    />
                    <label className="form-check-label">Low</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="VCrating"
                      value="Average"
                      onChange={this.handleInputChange}
                    />
                    <label className="form-check-label">Average</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="VCrating"
                      value="Good"
                      onChange={this.handleInputChange}
                    />
                    <label className="form-check-label">Good</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="VCrating"
                      value="Very Good"
                      onChange={this.handleInputChange}
                    />
                    <label className="form-check-label">Very Good</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="VCrating"
                      value="Excellent"
                      onChange={this.handleInputChange}
                    />
                    <label className="form-check-label">Excellent</label>
                  </div>
                </div>
              </div>

              <div className="row row-margin">
                <label className="col-sm-4 label-text label-text-right">
                  How Can We Improve? <br></br>Please give your suggestions
                </label>
                <div className="col-sm-6 ">
                  <textarea
                    className="input-design"
                    rows="4"
                    name="suggestions"
                    id="suggestions"
                    onChange={this.handleInputChange}
                  ></textarea>
                </div>
              </div>
            </div>

            <div className="col-sm-12 text-center mt-4">
              <input
                type="submit"
                value="Submit"
                className="formButton"
                onClick={this.submitSummery}
              ></input>
            </div>
          </div>
        </div>

        <Footer></Footer>
      </div>
    );
  }
}
