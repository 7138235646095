import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    doctorId: null,
    isAuthenticated:false
    // other state variables
  },
  reducers: {
    setDoctorId: (state, action) => {
      state.doctorId = action.payload;
    },
    setIsAuthenticated:(state, action) => {
      state.isAuthenticated = action.payload;
    },
    // other reducers
  },
});

export const { setDoctorId,setIsAuthenticated } = authSlice.actions;

export default authSlice.reducer;
