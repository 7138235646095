import React, { useState, useEffect, Suspense,useRef  } from "react";
import DatePicker from "react-datepicker";
import { apiCalling } from "../../apiService";
import $ from "jquery";
import AWS from "aws-sdk";
import Close from "../../image/icons/close-mark.png";

var s3;
var fileNameUploadSignature;


const Doctorinformation = React.forwardRef((props, ref) => {
  const [languageData, setLanguageData] = useState([]);
  const [timeFilterData, setTimeFilterData] = useState([]);
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [fileSignature, setFileSignature] = useState(null);
  const [s3BucketDoctorImages, setS3BucketDoctorImages] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [s3BucketMainFolder, setS3BucketMainFolder] = useState("");

  
  useEffect(() => {
    listing()
    $("#changePassPopup").hide();
    $('#cmbTitle option[value="Dr."]').attr("selected", true);
    // const apiJson = {
    //   method: "POST",
    //   body: JSON.stringify({
    //     functionName: "getHospitalDropdown",
    //   }),
    // };
    
    BindProps();
        setLanguageData(props.getHospitalDropdown.languages);
        setTimeFilterData(props.getHospitalDropdown.timeFilterData);
        if (props.languageEdit.length > 0) {
          $("#cmbLanguage").val(props.languageEdit);
        } else {
          $('#cmbLanguage option[value="1"]').prop("selected", true);
        }

        var timeFilter = [];
        if (
          props.doctorData[0] !== undefined &&
          props.doctorData[0].timeFilter !== ""
        ) {
          timeFilter = props.doctorData[0].timeFilter.split(",");
        }

        if (timeFilter.length > 0) {
          $("#cmbWorkTimeFilter").val(timeFilter);
        } else {
          $('#cmbWorkTimeFilter option[value="1"]').prop("selected", true);
        }

       

  }, [props.languageEdit]);

  const BindProps = () => {
    if (props.doctorData.length > 0) {
      $("#txtFirstName").val(props.doctorData[0].doctorFirstName);
      $("#txtLastName").val(props.doctorData[0].doctorLastName);
      $("#cmbTitle").val(
        props.doctorData[0].doctorTitle !== "undefined"
          ? props.doctorData[0].doctorTitle.trim()
          : ""
      );
      $("#txtPassword").val(props.doctorData[0].password);
      $("#txtConfirmPassword").val(props.doctorData[0].password);
      if (props.doctorData[0].gender === "Male") {
        $("#rdoMale").prop("checked", true);
      } else {
        $("#rdoFemale").prop("checked", true);
      }

      if (props.doctorData[0].signatureOnly !== "") {
        $("#imgDiv").show();
        $("#image").attr("src", props.doctorData[0].signatureWithPath);
      }
      if (
        props.doctorData[0].dateofbirth !== "" &&
        props.doctorData[0].dateofbirth !== "00/00/1900"
      ) {
        var dateOfBithh = props.doctorData[0].dateofbirth
          .toString()
          .split("/");
        var dateConcate =
          dateOfBithh[2].toString().trim() +
          "," +
          dateOfBithh[1].toString().trim() +
          "," +
          dateOfBithh[0].toString().trim();
        var date = new Date(dateConcate);
        setDateOfBirth(date);
      } else {
        setDateOfBirth(null);
      }
    }
  };

  const listing = () => {
    // const apiJson = {
    //   method: "POST",
    //   body: JSON.stringify({
    //     functionName: "getDropdownLists",
    //   }),
    // };
    // apiCalling(apiJson).then((data) => {
      // if (data.success === "1") {
      
        setS3BucketDoctorImages(props.getDropdownLists.S3BucketDoctorImages);
        setS3BucketMainFolder(props.getDropdownLists.S3BucketMainFolder);
        AWS.config.update({
          accessKeyId: props.getDropdownLists.s3bucketAccessKey,
          secretAccessKey: props.getDropdownLists.s3bucketSecretKey,
          region: "us-east-1",
        });
        var bucketParams = {
          Bucket: props.getDropdownLists.s3Bucket,
        };
        s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
      // }
    // });
  };

  const uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (
      c
    ) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };

  const handleChangeDate = (date) => {
    setDateOfBirth(date);
  };

  const disableDateField = () => {
    $("#txtDateOfBirth").attr("readonly", "readonly");
    setDateOfBirth(null);
  };

  const handleImageUpload = (e, mode) => {
    if (typeof e.target.files[0] !== "undefined") {
      let file = e.target.files[0];
      let fileType = file["type"];
      let validImageTypes = ["image/jpg", "image/jpeg", "image/png"];
      if (!validImageTypes.includes(fileType)) {
        alert("Invalid file type");
        $("#" + mode + "File").val(null);
        if (mode === "attachImg") {
          $("#image").attr("src", "");
          $("#imgDiv").hide();
        }
      } else {
        var fileObj = e.target.files[0];
        let reader = new FileReader();
        var img = new Image();
        img.src = window.URL.createObjectURL(fileObj);
        img.onload = function () {
          reader.onload = function (e) {
            if (mode === "attachImg") {
              $("#image").attr("src", e.target.result);
              $("#imgDiv").show();
            }
          };

          reader.readAsDataURL(fileObj);
        };
        if (mode ===
        "attachImg") {
          setFileSignature(fileObj);
        }
      }
    }
  };

  const fileUpload = (file, mode) => {
    let reader = new FileReader();
    let s3BucketMainFolder1 =
      s3BucketMainFolder + "/" + s3BucketDoctorImages;
    let fileName = uuidv4() + file.name;
    let fullFilePath = s3BucketMainFolder1 + "/" + fileName;
    if (mode === "attachImg") {
      fileNameUploadSignature = fileName;
    }
    s3.upload(
      {
        Key: fullFilePath,
        Body: file,
        ACL: "public-read",
      },
      (err, data) => {
        if (err) {
          return null;
        } else {
          return data.Location
          // Handle success
        }
      }
    );
  };

  const doctorinformation = () => {
    var image = "";
    if (fileSignature !== null) {
      fileUpload(fileSignature, "attachImg");
      image = fileNameUploadSignature;
    } else if (
      props.doctorData.length > 0 &&
      props.doctorData[0].signatureOnly !== ""
    ) {
      image = props.doctorData[0].signatureOnly;
    }
    return image;
  };

  const SetLocalStorage = () => {
    window.localStorage.setItem("seoTitle", $("#cmbTitle").val());
    var name = $("#txtFirstName").val() + " " + $("#txtLastName").val();
    window.localStorage.setItem("seoDisplaytName", name);
  };

  const changePass = () => {
    if (newPassword === "") {
      alert("Enter password to be changed");
      return false;
    }
    $("#changePassPopup").show();
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "changePassword",
        userId: props.doctorId,
        siteId: "",
        role: "ROLE_ADMIN",
        bundleId: "",
        currentPassword: "",
        newPassword: newPassword,
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        alert("Password Changed successfully");
        $("#changePassPopup").hide();
        $(".loader").hide();
      } else {
        alert("Something went wrong while changing password");
      }
    });
  }
  React.useImperativeHandle(ref, () => ({
    doctorinformation: () => {
      const imagePath = doctorinformation();
      return imagePath; // Return the image path directly
    }
  }));
  return (
    <div className="bg-clr-wthHead">
      <Suspense fallback={<div></div>}>
        <section>
          <div className="container mb-5">
            <div>
              <div className="hspInformation-wraper">
                <h2 className="hspInfoSubhead">Doctor Information</h2>

                <div className="hspinfo-contentdiv">
                  <label>
                    First Name <span className="hspifoMantory">*</span>
                  </label>
                  <input
                    type="text"
                    id="txtFirstName"
                    autoComplete="off"
                    name="txtFirstName"
                    className="hspInfofieldinput Infoinputwidthdoctr"
                    placeholder="First Name"
                    onKeyUp={() => SetLocalStorage()}
                  />
                </div>
                <div className="hspinfo-contentdiv">
                  <label>Last Name</label>
                  <input
                    type="text"
                    id="txtLastName"
                    autoComplete="off"
                    name="txtLastName"
                    className="hspInfofieldinput Infoinputwidthdoctr"
                    placeholder="Last Name"
                    onKeyUp={() => SetLocalStorage()}
                  />
                </div>

                <div className="hspinfo-contentdiv">
                  <label>
                    Title <span className="hspifoMantory">*</span>
                  </label>
                  <select
                    name="cmbTitle"
                    id="cmbTitle"
                    className="hspInfofieldinput Infoinputwidthdoctr"
                    onChange={() => SetLocalStorage()}
                  >
                    <option value="">Select Title</option>
                    <option value="Dr.">Dr</option>
                    <option value="Mr.">Mr</option>
                    <option value="Ms.">Ms</option>
                  </select>
                </div>
                <div className="hspinfo-contentdiv">
                  <label className="pt-0">
                    Gender<span className="hspifoMantory">*</span>
                  </label>
                  <div className="superadminDivBox">
                    <input
                      type="radio"
                      name="rdoGenderType"
                      value="Male"
                      id="rdoMale"
                      className=""
                    />{" "}
                    <span className="gendrfldHspdctr pr-2">Male</span>
                    <input
                      type="radio"
                      name="rdoGenderType"
                      value="Female"
                      id="rdoFemale"
                      className=""
                    />{" "}
                    <span className="gendrfldHspdctr">Female</span>
                  </div>
                </div>
                <div className="hspinfo-contentdiv">
                  <label>Doctor ID</label>
                  <input
                    type="text"
                    value={props.doctorId}
                    autoComplete="off"
                    className="hspInfofieldinput Infoinputwidthdoctr"
                    disabled
                  />
                </div>
                {props.doctorId ? (
                  <div className="hspinfo-contentdiv">
                    <span
                      className="smssetng-svebtn"
                      onClick={() => {
                        $("#changePassPopup").show();
                      }}
                    >
                      Change Password
                    </span>
                  </div>
                ) : (
                  ""
                )}
                <div className="superadminDivBoxcover">
                  <div className="superadminDivBox superadminDivBoxwidtAuto">
                    <label>Signature(jpeg,png)</label>
                    <div className="hspinfoimgaeuploadDiv">
                      <div className="hspinfo-btnupload">
                        <span for="attachImg">Upload</span>
                        <input
                          type="file"
                          id="attachImg"
                          accept="image/*"
                          name="attachImg"
                          className="hspinfoInputfieldupload"
                          onChange={(e) =>
                            handleImageUpload(e, "attachImg")
                          }
                        />
                      </div>
                      <div id="imgDiv" style={{ display: "none" }}>
                        {" "}
                        <img height="30" width="35" id="image"></img>
                      </div>
                      <hidden
                        id="hidSIgnatureName"
                        name="hidSIgnatureName"
                      ></hidden>
                    </div>
                  </div>
                </div>
                <div className="superadminDivBoxcover">
                  <div className="superadminDivBox  react-date-dctr-width">
                    <label className="pt-0">Date Of Birth</label>
                    <DatePicker
                      placeholderText="Date of Birth"
                      id="txtDateOfBirth"
                      name="txtDateOfBirth"
                      maxDate={new Date()}
                      showMonthDropdown
                      className="doctrHsp-dateinput"
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={100}
                      dropdownMode="scroll"
                      peekNextMonth
                      disabledKeyboardNavigation
                      selected={dateOfBirth}
                      onSelect={handleChangeDate}
                      dateFormat="dd/MM/yyyy"
                      calendarClassName="react-date-picker"
                      autoComplete="off"
                      tabIndex="8"
                      onKeyDown={() => disableDateField()}
                    />
                  </div>
                </div>
                <div className="hspinfo-contentdiv">
                  <label>
                    Language <span className="hspifoMantory">*</span>
                  </label>
                  <select
                    multiple
                    name="cmbLanguage"
                    id="cmbLanguage"
                    style={{ height: "95px" }}
                    className="hspInfofieldinput Infoinputwidthdoctr"
                  >
                    {languageData.map((LangObj, i) => {
                      return (
                        <option
                          key={LangObj.languageId}
                          value={LangObj.languageId}
                        >
                          {LangObj.languageName}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="hspinfo-contentdiv">
                  <label>
                    Work Time Filter{" "}
                    <span className="hspifoMantory">*</span>
                  </label>
                  <select
                    multiple
                    name="cmbWorkTimeFilter"
                    id="cmbWorkTimeFilter"
                    style={{ height: "95px" }}
                    className="hspInfofieldinput Infoinputwidthdoctr"
                  >
                    {timeFilterData.map((timeObj) => {
                      return (
                        <option
                          key={timeObj.timeFilterId}
                          value={timeObj.timeFilterId}
                        >
                          {timeObj.timeFilterName}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
      <Suspense fallback={<div></div>}></Suspense>
      <div className="modalinput_show" id="changePassPopup">
        <div className="modalinput_inner">
          <div className="close_modalinput">
            <img
              src={Close}
              onClick={() => {
                $("#changePassPopup").hide();
              }}
            />
          </div>
          <div className="modal_innerform">
            <label>New Password</label>
            <input
              type="password"
              className="form-control"
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <div className="modal_informationfooter">
            <input
              type="button"
              className="smssetng-svebtn"
              value="save"
              onClick={() => changePass()}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
)
export default Doctorinformation;
