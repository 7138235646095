import React, { useState, useEffect, Suspense } from "react";
import $ from "jquery";
import AWS from "aws-sdk";
import { apiCalling } from "../../apiService";

let s3;
let fileNameUploadPhoto;

// const DoctorPhoto = ({ uploadedImageUrlRef, doctorData }) => {
  const DoctorPhoto = React.forwardRef(({uploadedImageUrlRef,doctorData}, ref) => {
  const [filePhoto, setFilePhoto] = useState(null);
  const [s3bucketAccessKey, setS3bucketAccessKey] = useState("");
  const [s3bucketSecretKey, setS3bucketSecretKey] = useState("");
  const [s3BucketMainFolder, setS3BucketMainFolder] = useState("");
  const [s3BucketAddressPath, setS3BucketAddressPath] = useState("");
  const [s3BucketDoctorImages, setS3BucketDoctorImages] = useState("");
  const [s3Bucket, setS3Bucket] = useState("");

  useEffect(() => {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({ functionName: "getDropdownLists" }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        setS3bucketAccessKey(data.result[0].s3bucketAccessKey);
        setS3bucketSecretKey(data.result[0].s3bucketSecretKey);
        setS3BucketMainFolder(data.result[0].S3BucketMainFolder);
        setS3BucketAddressPath(data.result[0].s3BucketAddressPath);
        setS3BucketDoctorImages(data.result[0].S3BucketDoctorImages);
        setS3Bucket(data.result[0].s3Bucket);
      }
      if (doctorData[0] !== undefined && doctorData[0].photoOnly !== "") {
        $('#photoDiv').show();
        $('#photo').attr('src', doctorData[0].photoWithPath);
      }
      AWS.config.update({
        accessKeyId: data.result[0].s3bucketAccessKey,
        secretAccessKey: data.result[0].s3bucketSecretKey,
        region: "us-east-1",
      });
      const bucketParams = { Bucket: data.result[0].s3Bucket };
      s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
    });
  }, [doctorData]);

  const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  };

  const handleImageUpload = (e, mode) => {
    if (e.target.files[0] !== undefined) {
      let file = e.target.files[0];
      let fileType = file['type'];
      const validImageTypes = ['image/jpg', 'image/jpeg', 'image/png'];
      if (!validImageTypes.includes(fileType)) {
        alert("Invalid file type");
        $("#" + mode + "File").val(null);
        if (mode === 'attachPhoto') {
          $("#photo").attr('src', '');
          $("#photoDiv").hide();
        }
      } else {
        const fileObj = e.target.files[0];
        const reader = new FileReader();
        const img = new Image();
        img.src = window.URL.createObjectURL(fileObj);
        img.onload = function () {
          reader.onload = function (e) {
            if (mode === 'attachPhoto') {
              $("#photo").attr('src', e.target.result);
              $("#photoDiv").show();
            }
          };
          reader.readAsDataURL(fileObj);
        };
        if (mode === 'attachPhoto') {
          setFilePhoto(fileObj);
        }
      }
    }
  };

  const fileUpload = (file, mode) => {
    const reader = new FileReader();
    const s3BucketMainFolder1 = s3BucketMainFolder + "/" + s3BucketDoctorImages;
    const fileName = uuidv4() + file.name;
    const fullFilePath = s3BucketMainFolder1 + "/" + fileName;
    fileNameUploadPhoto = fileName;
    if (mode === 'attachPhoto') {
      fileNameUploadPhoto = fileName;
    }
    s3.upload(
      {
        Key: fullFilePath,
        Body: file,
        ACL: "public-read",
      },
      (err, data) => {
        if (err) {
          return null;
        } else {
          if(uploadedImageUrlRef)
          uploadedImageUrlRef.current = data.Location;
        }
      }
    );
  };

  const doctorPhoto = () => {
    let image = '';
    if (filePhoto !== null && doctorData.length > 0) {
      fileUpload(filePhoto, 'attachPhoto');
      image = fileNameUploadPhoto;
    } else if (doctorData.length > 0 && doctorData[0].photoOnly !== "") {
      image = doctorData[0].photoOnly;
    }
    return image;
  };
  React.useImperativeHandle(ref, () => ({
    doctorPhoto: () => {
      const imagePath = doctorPhoto();
      return imagePath; // Return the image path directly
    }
  }));
  return (
    <div className="bg-clr-wthHead mb-4">
      <Suspense fallback={<div></div>}>
        <section>
          <div className="seoWrapperHspMangemnt">
            <div className="container doctr-wraper-border">
              <span className="seosectinTextHspMangemnt">
                Please save doctors details before you upload photos
              </span>
              <div className="mt-4 mb-5">
                <div className="superadminDivBoxcover">
                  <div className="superadminDivBox superadminDivBoxwidtAuto">
                    <label> </label>
                    <div className="hspinfoimgaeuploadDiv">
                      <div className="hspinfo-btnupload">
                        <span htmlFor="attachPhoto">Upload</span>
                        <input
                          type="file"
                          id="attachPhoto"
                          accept="image/*"
                          name="attachPhoto"
                          className="hspinfoInputfieldupload"
                          onChange={(e) => handleImageUpload(e, "attachPhoto")}
                        />
                      </div>
                      <div id="photoDiv" style={{ display: 'none' }}>
                        <img height="30" width="35" id="photo" alt="doctor" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
      <Suspense fallback={<div></div>}></Suspense>
    </div>
  );
}
  )

export default DoctorPhoto;
