import React, { Component } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import calendar from "../image/media/calendar.png";
import vitalsign from "../image/media/vitalsign.png";
import casesheet from "../image/media/case-sheet.png";
import treatmentplan from "../image/media/treatment-plan.png";
import invoice from "../image/media/invoice.png";
import compplans from "../image/media/comp-plans.png";
import rx from "../image/media/rx.png";
import cash from "../image/media/cash.png";

export default class History extends Component {
  render() {
    return (
      <div className="purpleWrap">
        <Header></Header>
        <div className="container container-auto">
          <div className="main-wrapper1">
            <h4 className="color-maintext">History</h4>
            <div className="form-section mt-2 form-section-padng">
              <div className="row">
                <div className="col-6">
                  <h6> Types </h6>
                </div>
                <div className="col-6">
                  <div className="pendingLink">
                    <a>Select All</a>
                  </div>
                  <div className="pendingLink">
                    <a>Deselect All</a>
                  </div>
                </div>
                <div className="col-6 col-lg-3 col-md-4 mb-2">
                  <input type="checkbox" className="mr-2" /> Appointments{" "}
                </div>
                <div className="col-6 col-lg-3 col-md-4 mb-2">
                  <input type="checkbox" className="mr-2" /> Case Sheet{" "}
                </div>
                <div className="col-6 col-lg-3 col-md-4 mb-2">
                  <input type="checkbox" className="mr-2" /> Vital Signs{" "}
                </div>
                <div className="col-6 col-lg-3 col-md-4 mb-2">
                  <input type="checkbox" className="mr-2" /> Treatment Plans{" "}
                </div>
                <div className="col-6 col-lg-3 col-md-4 mb-2">
                  <input type="checkbox" className="mr-2" /> Completed Plans{" "}
                </div>
                <div className="col-6 col-lg-3 col-md-4 mb-2">
                  <input type="checkbox" className="mr-2" /> Files{" "}
                </div>
                <div className="col-6 col-lg-3 col-md-4 mb-2">
                  <input type="checkbox" className="mr-2" /> Prescriptions{" "}
                </div>
                <div className="col-6 col-lg-3 col-md-4 mb-2">
                  <input type="checkbox" className="mr-2" /> Lab Order{" "}
                </div>
                <div className="col-6 col-lg-3 col-md-4 mb-2">
                  <input type="checkbox" className="mr-2" /> Invoices{" "}
                </div>
                <div className="col-6 col-lg-3 col-md-4 mb-2">
                  <input type="checkbox" className="mr-2" /> Payments{" "}
                </div>
                <div className="col-6 col-lg-3 col-md-4 mb-2">
                  <input type="checkbox" className="mr-2" /> Test Result{" "}
                </div>
                <div className="col-6 col-lg-3 col-md-4 mb-2">
                  <input type="checkbox" className="mr-2" /> Refer A Doctor{" "}
                </div>
                <div className="col-6 col-lg-3 col-md-4 mb-2">
                  <input type="checkbox" className="mr-2" /> Admit{" "}
                </div>
                <div className="col-6 col-lg-3 col-md-4 mb-2">
                  <input type="checkbox" className="mr-2" /> Discharge Note{" "}
                </div>
              </div>
              <div className="treatmentCommonTxtWrap">History Not Available</div>
              <div className="treatmentTimelineBlockMain">
                <div className="timelineList">
                  <div className="timelineListDate">
                    <span className="timelineDate">08</span>
                    <div className="timelineDateCvr">
                      <span className="timelineMnthDate"> 2020</span>
                      <span className="timelineDay">July</span>
                    </div>
                  </div>
                  <div className="treatmentTimelineBlock">
                    <div className="timelineListContent appointments-div">
                      <span className="timelineArrow"></span>{" "}
                      <span className="timelineCircle"></span>
                      <div className="timelineListName">
                        <img alt="" src={calendar} /> <span>APPOINTMENTS</span>
                      </div>
                      <div className="appoinmentDateDetail">
                        <div className="appoinmentLeftDiv">
                          <span className="appoinmentReason">
                            <span>Reason:</span>
                            <span className="elipsis"> efd</span>
                          </span>
                        </div>
                        <div className="appoinmentSatusWrap">
                          <span className="appoinmentTYpe">Type : In Person</span>
                        </div>
                        <div className="appoinmentSatusWrap">
                          <span className="appoinmentSTatus">Status : OPEN</span>
                        </div>
                        <div className="appoinmentRightDiv">
                          <span className="appoinmentDrName">
                            Dr.Dikson s (Ortho)
                          </span>
                        </div>
                      </div>
                      <div className="appoinmentDateDetail">
                        <div className="appoinmentLeftDiv">
                          <span className="appoinmentReason">
                            <span>Reason:</span>
                            <span className="elipsis"> efd</span>
                          </span>
                        </div>
                        <div className="appoinmentSatusWrap">
                          <span className="appoinmentTYpe">Type : In Person</span>
                        </div>
                        <div className="appoinmentSatusWrap">
                          <span className="appoinmentSTatus">Status : OPEN</span>
                        </div>
                        <div className="appoinmentRightDiv">
                          <span className="appoinmentDrName">
                            Dr.Dikson s (Ortho)
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="timelineList">
                  <div className="timelineListDate">
                    <span className="timelineDate">08</span>
                    <div className="timelineDateCvr">
                      <span className="timelineMnthDate"> 2020</span>
                      <span className="timelineDay">July</span>
                    </div>
                  </div>
                  <div className="treatmentTimelineBlock">
                    <div className="timelineListContent appointments-div">
                      <span className="timelineArrow"></span>{" "}
                      <span className="timelineCircle"></span>
                      <div className="timelineListName">
                        <img alt="" src={calendar} /> <span>APPOINTMENTS</span>
                      </div>
                      <div className="appoinmentDateDetail">
                        <div className="appoinmentLeftDiv">
                          <span className="appoinmentReason">
                            <span>Reason:</span>
                            <span className="elipsis"> efd</span>
                          </span>
                        </div>
                        <div className="appoinmentSatusWrap">
                          <span className="appoinmentTYpe">Type : In Person</span>
                        </div>
                        <div className="appoinmentSatusWrap">
                          <span className="appoinmentSTatus">Status : OPEN</span>
                        </div>
                        <div className="appoinmentRightDiv">
                          <span className="appoinmentDrName">
                            Dr.Dikson s (Ortho)
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="timelineList">
                  <div className="timelineListDate">
                    <span className="timelineDate">08</span>
                    <div className="timelineDateCvr">
                      <span className="timelineMnthDate"> 2020</span>
                      <span className="timelineDay">July</span>
                    </div>
                  </div>
                  <div className="treatmentTimelineBlock">
                    <div className="timelineListContent vitalSign-div">
                      <span className="timelineArrow"></span>{" "}
                      <span className="timelineCircle"></span>
                      <div className="timelineListName">
                        <img alt="" src={vitalsign} /> <span>Vital Signs</span>
                      </div>
                      <div className="treatmentTable prescriptionTable ">
                        <div className="treatmentComplaintWrap">
                          <div className="treatmentComplaintRowWrap treatmentComplaintRowWrap">
                            <div className="treatmentComplaintRow">
                              <div className="treatmentComplaintLeft">
                                <span>Time</span>
                              </div>
                              <div className="treatmentComplaintRight">
                                <span>15:58</span>
                              </div>
                            </div>
                            <div className="treatmentComplaintRow">
                              <div className="treatmentComplaintLeft">
                                <span>Weight (Kg)</span>
                              </div>
                              <div className="treatmentComplaintRight">
                                <span>74</span>
                              </div>
                            </div>
                            <div className="treatmentComplaintRow">
                              <div className="treatmentComplaintLeft">
                                <span>Blood Pressure SYS/DIA (mmHg)</span>
                              </div>
                              <div className="treatmentComplaintRight">
                                <span>110/90 Sitting</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="treatmentComplaintNoted">
                          Noted by <span>Weekend doctor</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="timelineList">
                  <div className="timelineListDate">
                    <span className="timelineDate">08</span>
                    <div className="timelineDateCvr">
                      <span className="timelineMnthDate"> 2020</span>
                      <span className="timelineDay">July</span>
                    </div>
                  </div>
                  <div className="treatmentTimelineBlock">
                    <div className="timelineListContent caseSheet-div">
                      <span className="timelineArrow"></span>
                      <span className="timelineCircle"></span>
                      <div className="timelineListName">
                        <img src={casesheet}></img> <span>Case Sheet</span>
                      </div>
                      <div className="treatmentPlanListWrap">
                        <div className="treatmentComplaintWrap">
                          <div className="treatmentComplaintRowWrap">
                            <div className="treatmentComplaintRow">
                              <div className="treatmentComplaintLeft">
                                {" "}
                                Chief presenting complaint{" "}
                              </div>
                              <div className="treatmentComplaintRight">
                                <span className="treatmentTAgName elipsis">
                                  Chief Complaint
                                </span>
                                <span className="treatmentTAgDate">
                                  13,Jul 2020
                                </span>
                              </div>
                            </div>
                            <div className="treatmentComplaintRow">
                              <div className="treatmentComplaintLeft">
                                Associated complaints
                              </div>
                              <div className="treatmentComplaintRight">
                                <span className="treatmentTAgName elipsis">
                                  Associated Complaint<br></br>Known Case of
                                </span>
                                <span className="treatmentTAgDate">
                                  13,Jul 2020
                                </span>
                              </div>
                            </div>
                            <div className="treatmentComplaintRow">
                              <div className="treatmentComplaintLeft">
                                {" "}
                                Observations{" "}
                              </div>
                              <div className="treatmentComplaintRight">
                                <span className="treatmentTAgName elipsis"></span>
                                <span className="treatmentTAgDate"></span>
                                <span style={{ fontWeight: "bold" }}>
                                  Description :
                                </span>
                                <span>Observations</span>
                              </div>
                            </div>
                            <div className="treatmentComplaintNoted">
                              Noted by <span>Dr.Ortho Paid Doc (Ortho)</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="timelineList">
                  <div className="timelineListDate">
                    <span className="timelineDate">08</span>
                    <div className="timelineDateCvr">
                      <span className="timelineMnthDate"> 2020</span>
                      <span className="timelineDay">July</span>
                    </div>
                  </div>
                  <div className="treatmentTimelineBlock">
                    <div className="timelineListContent treatmentplan-div">
                      <span className="timelineArrow"></span>{" "}
                      <span className="timelineCircle"></span>
                      <div className="timelineListName">
                        <img alt="" src={treatmentplan} />{" "}
                        <span>Treatment plan</span>
                      </div>
                      <div className="treatmentTable treatmentpadZero">
                        <div className="treatmentPlanList">
                          <div className="treatmentTable ">
                            <table>
                              <thead>
                                <tr>
                                  <th
                                    className="col-md-3"
                                    style={{ minWidth: "200px" }}
                                  >
                                    <div className="dentalProName">PROCEDURE</div>
                                  </th>
                                  <th className="col-md-2">
                                    <div className="dentalProCost">COST</div>
                                  </th>
                                  <th className="col-md-2">
                                    <div className="dentalProDis">TAX</div>
                                  </th>
                                  <th className="col-md-2">
                                    <div className="dentalProTotal">TOTAL</div>
                                  </th>
                                  <th className="col-md-3">
                                    <div className="dentalProNote">NOTES</div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr className="groupBrdr">
                                  <td className="col-md-3">
                                    <div className="dentalProName">
                                      <div className="optionWrapper">
                                        <img
                                          src={invoice}
                                          title="Invoice Generated"
                                        ></img>
                                        <label for="cool011"></label>
                                      </div>
                                      <span className="elipsis">t102</span>
                                    </div>
                                  </td>
                                  <td className="col-md-2">
                                    <div className="dentalProCost">100.00</div>
                                  </td>
                                  <td className="col-md-2">
                                    <div className="dentalProDis">0.00</div>
                                  </td>
                                  <td className="col-md-2">
                                    <div className="dentalProTotal">100.00</div>
                                  </td>
                                  <td className="col-md-3">
                                    <div className="dentalProNote">
                                      <span className="elipsis" title="">
                                        &nbsp;
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                                <tr className="dentalProPlaned">
                                  <input
                                    type="hidden"
                                    name="hidTreatmentIds00"
                                    id="hidTreatmentIds00"
                                    value=""
                                  />
                                  <td>
                                    <div className="plannedBy">
                                      Planned by{" "}
                                      <span>Dr. Ortho Paid Doc (Ortho)</span>
                                    </div>
                                  </td>
                                  <td colspan="3">
                                    <div className="estimatedPrice txtRight">
                                      Estimated amount : 100.00
                                    </div>
                                  </td>
                                  <td colspan="1">
                                    <div className="estimatedPrice">&nbsp;</div>
                                  </td>
                                </tr>
                                <tr className="groupBrdr">
                                  <td className="col-md-3">
                                    <div className="dentalProName">
                                      <div className="optionWrapper">
                                        <img
                                          src={invoice}
                                          title="Invoice Generated"
                                        ></img>
                                        <label for="cool011"></label>
                                      </div>
                                      <span className="elipsis">t102</span>
                                    </div>
                                  </td>
                                  <td className="col-md-2">
                                    <div className="dentalProCost">100.00</div>
                                  </td>
                                  <td className="col-md-2">
                                    <div className="dentalProDis">0.00</div>
                                  </td>
                                  <td className="col-md-2">
                                    <div className="dentalProTotal">100.00</div>
                                  </td>
                                  <td className="col-md-3">
                                    <div className="dentalProNote">
                                      <span className="elipsis" title="">
                                        &nbsp;
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                                <tr className="dentalProPlaned">
                                  <input
                                    type="hidden"
                                    name="hidTreatmentIds01"
                                    id="hidTreatmentIds01"
                                    value=""
                                  />
                                  <td>
                                    <div className="plannedBy">
                                      Planned by{" "}
                                      <span>Dr. Ortho Paid Doc (Ortho)</span>
                                    </div>
                                  </td>
                                  <td colspan="3">
                                    <div className="estimatedPrice txtRight">
                                      Estimated amount : 100.00
                                    </div>
                                  </td>
                                  <td colspan="1">
                                    <div className="estimatedPrice">&nbsp;</div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="timelineList">
                  <div className="timelineListDate">
                    <span className="timelineDate">08</span>
                    <div className="timelineDateCvr">
                      <span className="timelineMnthDate"> 2020</span>
                      <span className="timelineDay">July</span>
                    </div>
                  </div>
                  <div className="treatmentTimelineBlock">
                    <div className="timelineListContent treatmentplan-div">
                      <span className="timelineArrow"></span>{" "}
                      <span className="timelineCircle"></span>
                      <div className="timelineListName">
                        <img alt="" src={compplans} />{" "}
                        <span>COMPLETED PLANS</span>
                      </div>
                      <div className="treatmentTable treatmentpadZero">
                        <div className="treatmentPlanList">
                          <div className="treatmentTable ">
                            <table>
                              <thead>
                                <tr>
                                  <th
                                    className="col-md-3"
                                    style={{ minWidth: "200px" }}
                                  >
                                    <div className="dentalProName">PROCEDURE</div>
                                  </th>
                                  <th className="col-md-2">
                                    <div className="dentalProCost">COST</div>
                                  </th>
                                  <th className="col-md-2">
                                    <div className="dentalProDis">DISCOUNT</div>
                                  </th>
                                  <th className="col-md-2">
                                    <div className="dentalProDis">TAX</div>
                                  </th>
                                  <th className="col-md-2">
                                    <div className="dentalProTotal">TOTAL</div>
                                  </th>
                                  <th className="col-md-3">
                                    <div className="dentalProNote">NOTES</div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr className="groupBrdr">
                                  <td className="col-md-3">
                                    <div className="dentalProName">
                                      <div className="optionWrapper">
                                        <img
                                          src={invoice}
                                          title="Invoice Generated"
                                        ></img>
                                        <label for="cool011"></label>
                                      </div>
                                      <span className="elipsis">t102</span>
                                    </div>
                                  </td>
                                  <td className="col-md-2">
                                    <div className="dentalProCost">100.00</div>
                                  </td>
                                  <td className="col-md-2">
                                    <div className="dentalProDis">0.00</div>
                                  </td>
                                  <td className="col-md-2">
                                    <div className="dentalProDis">0.00</div>
                                  </td>
                                  <td className="col-md-2">
                                    <div className="dentalProTotal">100.00</div>
                                  </td>
                                  <td className="col-md-3">
                                    <div className="dentalProNote">
                                      <span className="elipsis" title="">
                                        &nbsp;
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                                <tr className="dentalProPlaned">
                                  <input
                                    type="hidden"
                                    name="hidTreatmentIds00"
                                    id="hidTreatmentIds00"
                                    value=""
                                  />
                                  <td colspan="6">
                                    <div className="plannedBy">
                                      Planned by{" "}
                                      <span>Dr. Ortho Paid Doc (Ortho)</span>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="timelineList">
                  <div className="timelineListDate">
                    <span className="timelineDate">08</span>
                    <div className="timelineDateCvr">
                      <span className="timelineMnthDate"> 2020</span>
                      <span className="timelineDay">July</span>
                    </div>
                  </div>
                  <div className="treatmentTimelineBlock">
                    <div className="timelineListContent treatmentplan-div">
                      <span className="timelineArrow"></span>{" "}
                      <span className="timelineCircle"></span>
                      <div className="timelineListName">
                        <img alt="" src={rx} /> <span>Prescriptions</span>
                      </div>
                      <div className="treatmentTable treatmentpadZero">
                        <div className="treatmentPlanList">
                          <div className="treatmentTable ">
                            <table>
                              <thead>
                                <tr>
                                  <th
                                    className="col-md-3"
                                    style={{ minWidth: "200px" }}
                                  >
                                    <div className="dentalProName">Medicine</div>
                                  </th>
                                  <th className="col-md-2">
                                    <div className="dentalProCost">
                                      Frequency & Dosage{" "}
                                    </div>
                                  </th>
                                  <th className="col-md-2">
                                    <div className="dentalProCost">Intake</div>
                                  </th>
                                  <th className="col-md-2">
                                    <div className="dentalProCost">Qty</div>
                                  </th>
                                  <th className="col-md-2">
                                    <div className="dentalProCost">Days</div>
                                  </th>
                                  <th className="col-md-3">
                                    <div className="dentalProCost">Start Date</div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr className="groupBrdr">
                                  <td className="col-md-3">
                                    <div className="dentalProName">
                                      {" "}
                                      CINCLOX-S inj vial{" "}
                                    </div>
                                  </td>
                                  <td className="col-md-2">
                                    <div
                                      className="dentalProCost"
                                      style={{ whiteSpace: "nowrap" }}
                                    >
                                      Three Times A Day - 0 - 0 - 0
                                    </div>
                                  </td>
                                  <td className="col-md-2">
                                    <div
                                      className="dentalProCost"
                                      style={{ whiteSpace: "nowrap" }}
                                    >
                                      Before Food
                                    </div>
                                  </td>
                                  <td className="col-md-2">
                                    <div className="dentalProCost"></div>
                                  </td>
                                  <td className="col-md-2">
                                    <div className="dentalProCost"></div>
                                  </td>
                                  <td className="col-md-2">
                                    <div
                                      className="dentalProCost"
                                      style={{ whiteSpace: "nowrap" }}
                                    >
                                      13-Jul-2020
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <div className="treatmentPlanDate">
                              <div className="treatmentComplaintNoted ">
                                Noted by <span>Dr.Ortho Paid Doc (Ortho)</span>
                              </div>
                              <div className="dentalSelect">
                                <div className="dentalProNote">
                                  Lorem Ipsum is simply dummy text of the
                                  printing and typesetting industry. Lorem Ipsum
                                  has been the industry's standard dummy text
                                  ever since the 1500s, when an unknown printer
                                  took a galley of type and scrambled it to make
                                  a type specimen book. It has survived not only
                                  five centuries
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="timelineList">
                  <div className="timelineListDate">
                    <span className="timelineDate">08</span>
                    <div className="timelineDateCvr">
                      <span className="timelineMnthDate"> 2020</span>
                      <span className="timelineDay">July</span>
                    </div>
                  </div>
                  <div className="treatmentTimelineBlock">
                    <div className="timelineListContent treatmentplan-div">
                      <span className="timelineArrow"></span>{" "}
                      <span className="timelineCircle"></span>
                      <div className="timelineListName">
                        <img alt="" src={treatmentplan} />{" "}
                        <span>Lab Order</span>
                      </div>
                      <div className="treatmentTable treatmentpadZero">
                        <div className="treatmentPlanList">
                          <div className="treatmentTable ">
                            <table>
                              <thead>
                                <tr>
                                  <th
                                    className="col-md-3"
                                    style={{ minWidth: "200px" }}
                                  >
                                    <div className="dentalProName">TEST NAME </div>
                                  </th>
                                  <th className="col-md-2">
                                    <div className="dentalProCost">SPECIMEN</div>
                                  </th>
                                  <th className="col-md-2">
                                    <div className="dentalProDis">TEST TYPE</div>
                                  </th>
                                  <th className="col-md-2">
                                    <div className="dentalProDis">AMOUNT</div>
                                  </th>
                                  <th className="col-md-2">
                                    <div className="dentalProTotal">NOTES</div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr className="groupBrdr">
                                  <td className="col-md-3">
                                    <div className="dentalProName">ECG </div>
                                  </td>
                                  <td className="col-md-2">
                                    <div
                                      className="dentalProCost"
                                      style={{ whiteSpace: "nowrap" }}
                                    >
                                      blood
                                    </div>
                                  </td>
                                  <td className="col-md-2">
                                    <div
                                      className="dentalProDis"
                                      style={{ whiteSpace: "nowrap" }}
                                    >
                                      Hepatitis
                                    </div>
                                  </td>
                                  <td className="col-md-2">
                                    <div className="dentalProDis">1500.00</div>
                                  </td>
                                  <td className="col-md-2">
                                    <div className="dentalProTotal"></div>
                                  </td>
                                </tr>
                                <tr className="dentalProPlaned">
                                  <input
                                    type="hidden"
                                    name="hidTreatmentIds01"
                                    id="hidTreatmentIds01"
                                    value=""
                                  />
                                  <td colspan="2">
                                    <div className="plannedBy">
                                      Noted by{" "}
                                      <span>Dr. Ortho Paid Doc (Ortho)</span>
                                    </div>
                                  </td>
                                  <td colspan="3">
                                    <div className="estimatedPrice">&nbsp;</div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="timelineList">
                  <div className="timelineListDate">
                    <span className="timelineDate">08</span>
                    <div className="timelineDateCvr">
                      <span className="timelineMnthDate"> 2020</span>
                      <span className="timelineDay">July</span>
                    </div>
                  </div>
                  <div className="treatmentTimelineBlock">
                    <div className="timelineListContent treatmentplan-div">
                      <span className="timelineArrow"></span>{" "}
                      <span className="timelineCircle"></span>
                      <div className="timelineListName">
                        <img alt="" src={cash} /> <span>Payment</span>
                      </div>
                      <div className="treatmentTable treatmentpadZero">
                        <div className="treatmentPlanList">
                          <div className="treatmentTable ">
                            <table>
                              <thead>
                                <tr>
                                  <th
                                    className="col-md-3"
                                    style={{ minWidth: "200px" }}
                                  >
                                    <div className="dentalProName">
                                      RECEIPT NUMBER{" "}
                                    </div>
                                  </th>
                                  <th className="col-md-2">
                                    <div className="dentalProCost">AMOUNT PAID</div>
                                  </th>
                                  <th className="col-md-2">
                                    <div className="dentalProDis">
                                      INVOICES NUMBER
                                    </div>
                                  </th>
                                  <th className="col-md-2">
                                    <div className="dentalProDis">TOWARDS</div>
                                  </th>
                                  <th className="col-md-2">
                                    <div className="dentalProTotal">
                                      MODE OF PAYMENT
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr className="groupBrdr">
                                  <td className="col-md-3">
                                    <div className="dentalProName">RCP5 </div>
                                  </td>
                                  <td className="col-md-2">
                                    <div
                                      className="dentalProCost"
                                      style={{ whiteSpace: "nowrap" }}
                                    >
                                      1,600.00
                                    </div>
                                  </td>
                                  <td className="col-md-2">
                                    <div
                                      className="dentalProDis"
                                      style={{ whiteSpace: "nowrap" }}
                                    ></div>
                                  </td>
                                  <td className="col-md-2">
                                    <div className="dentalProDis">INV7</div>
                                  </td>
                                  <td className="col-md-2">
                                    <div className="dentalProTotal">
                                      new proc (t102)ECG
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer></Footer>
      </div>
    );
  }
}
