import React, { useEffect, useState, Suspense } from "react";
import { useNavigate } from "react-router-dom";
import Pagination from "react-js-pagination";
import { RiWhatsappFill } from "react-icons/ri";
import { MdEmail } from "react-icons/md";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { apiCalling, awsAPIUrlcommon } from "../apiService";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import DoctorTemplate from "../image/excel/DoctorDashboardDetails.xlsx"
import DoctorDashboardSearch from "./doctorDashboardListSearch.jsx"
import DoctorFilePopup from "./doctorDashboardFilePopup.jsx"
import DoctorExcelPopup from "./doctorDashboardExcelPopup.jsx"

const DoctorPanel = () => {
  const history = useNavigate();
  const [siteId, setSiteId] = useState("")
  const [doctorName, setDoctorName] = useState("")
  const [siteName, setSiteName] = useState("")
  const [activePage, setActivePage] = useState(1);
  const [max, setMax] = useState(10);
  const [offSet, setOffSet] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [doctorList, setDoctorList] = useState([])
  const [doctorFileFlag, setDoctorFileFlag] = useState(false)
  const [showMessage, setShowMessage] = useState("Please select site name first.")
  const [excelPopupFlag, setExcelPopupFlag] = useState(false)
  
  useEffect(() => {
    siteId && getDoctorList(offSet)
  }, [siteId])
  
  const pdfDoctorDetails = (data) => {
    window.sessionStorage.setItem("doctorDashBId", data.doctorId)
    window.sessionStorage.setItem("doctorDashBName", data.displayName)
    history('/admin/doctor-report');
  }
  const handlePageChange = (pageNumber) => {
    const offset = (pageNumber - 1) * max;
    setOffSet(offset)
    setActivePage(pageNumber);
    getDoctorList(offset)
  };
  
  
  const getDoctorList = (offSetNo) => {
    document.getElementsByClassName("loader")[0].style.display = "block"
    let postData = {
      method: "POST",
      body: JSON.stringify({
        functionName: "doctorList",
        siteId: siteId,
        doctorName: doctorName,
        max: offSetNo,
        offset: max,
      }),
    }
    apiCalling(postData).then((data) => {
      if (data.success === "1") {
        setDoctorList(data.doctorData)
        setTotalCount(data.doctorDataCount)
        if(data.doctorData.length === 0) {
          setShowMessage("No data found.")
        }
      }
      else {
        setShowMessage("No data found.")
        setDoctorList([])
        setTotalCount(0)
      }
      document.getElementsByClassName("loader")[0].style.display = "none"
    }).catch((error) => {
      document.getElementsByClassName("loader")[0].style.display = "none"
      toast.error("An error occurred while Getting Details");
    });
  }
  return (
    <div className="purpleWrap bg-clr-wthHead">
      <ToastContainer />
      <Suspense fallback={<div></div>}>
        <section>
          <Header />
          <div className="loader"></div>
          <div className="container mb-5">
            <div>
              <ul className="breadcrum-adminMangmnt">
                <li>
                  <a href="/admin/dashboard">Dashboard</a>
                </li>
                <li>
                  <a className="current">Doctor Dashboard List</a>
                </li>
              </ul>
            </div>
            <div className="head-hospMangmntList">Doctor Dashboard List</div>
            <div className="row tableHeader-HspMangemnt table-headerDctrPanel tble-dctrpanel-mob
            justify-content-between" style={{ height: "auto" }}>
              <div className="row col-md-6 ml-0 pl-2">
                <DoctorDashboardSearch
                  siteName={siteName} setSiteName={setSiteName} setSiteId={setSiteId}/>
                <div className="d-flex col-md-6 dctrpanel-headinput mb-1 mb-md-0">
                  <input type="text" className="hspInfofieldinput w-75" disabled={siteName ? false : true}
                    onChange={(e) => { setDoctorName(e.target.value) }} value={doctorName} placeholder="Enter Doctor Name"/>
                
                  <button className="searchbtn-hspMng w-auto m-0 ml-3" type="button"
                    onClick={()=>{getDoctorList(0)}} disabled={(siteName && doctorName) ? false : true}>Search</button>
                </div>
              </div>
              <div className="row col-md-6 justify-content-end mt-0">
                  <button className="searchbtn-hspMng w-auto mt-0" type="button"
                    onClick={()=>{setDoctorFileFlag(true)}}>
                    Show Files
                  </button>
                  <button className="searchbtn-hspMng w-auto mt-0" type="button"
                    onClick={()=>{setExcelPopupFlag(true)}}>
                    Excel Upload
                  </button>
                  <a className="searchbtn-hspMng w-auto text-white mt-0" href={DoctorTemplate} download="DoctorDashboardDetails.xlsx"
                    style={{ paddingTop: "7px" }}>
                    Download Template
                  </a>
                  {/* <button className="searchbtn-hspMng mb-md-auto m-0 w-auto" type="button"
                    onClick={fileSave}
                    disabled={uploaded ? false : true}>
                    Save
                  </button> */}
                {/* <div className="col-4 col-md-3 text-right">
                  <RiWhatsappFill size={24} className="theme-color mt-1 mr-xl-2" />
                  <MdEmail size={24} className="theme-color mt-1" />
                </div> */}
                </div>
            </div>
            <div className="row mx-0">
              
          {doctorList.length > 0 ? (
            <div className="table-responsive">
               <table className="table table-bordered-hsp">
                  <thead>
                    <tr>
                      <th className="tablehead-hspmng" colSpan="2">
                        Doctor Id
                      </th>
                      <th className="tablehead-hspmng" colSpan="4">
                        Doctor Name{" "}
                      </th>
                      <th className="tablehead-hspmng" colSpan="3">
                        City
                      </th>
                      <th className="tablehead-hspmng" colSpan="4">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {doctorList.map((data) => (
                      <tr key={data.doctorId}>
                        <td colSpan="2">{data.doctorId}</td>
                        <td colSpan="4">{data.displayName}</td>
                        <td colSpan="3">{data.city}</td>
                        <td colSpan="4" className="nowrap d-flex aling-items-center flex-wrap">
                          <div>
                            <span className="ph_action_horizontal">
                              <a
                                href="#"
                                className="listLink-hspmangmnt"
                                 onClick={() => pdfDoctorDetails(data)}
                              >
                                View Dashboard
                              </a>
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
            </div>
          ) : (
            <p className="error-msg text-center w-100 mt-4">{showMessage}</p>
          )}
          {totalCount > 10 &&
              <div className="paginationSection orderm-paginationSection pagination-HspList pagination-HspListwidth p-3">
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={max}
                  totalItemsCount={totalCount}
                  pageRangeDisplayed={10}
                  onChange={handlePageChange}
                />
              </div>
          }
            </div>
          </div>
          {doctorFileFlag &&
            <DoctorFilePopup setDoctorFileFlag={setDoctorFileFlag}/>
          }
          {excelPopupFlag && <DoctorExcelPopup setExcelPopupFlag={setExcelPopupFlag}/> }
        </section>
      </Suspense>
      <Suspense fallback={<div></div>}>
        <section>
          <Footer />
        </section>
      </Suspense>
    </div>
  );
};

export default DoctorPanel;
