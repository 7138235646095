import React, { useState, useEffect } from "react";
import { useMemo } from "react";
import ReactApexChart from "react-apexcharts";

const ApexChart = ({ specialityVsConsultGraph }) => {
  const [graphData, setGraphData] = useState({})
  
  useEffect(() => {
    setGraphData(specialityVsConsultGraph)
  }, [specialityVsConsultGraph])

  const chartData = useMemo(() => ({
    series: specialityVsConsultGraph.series || [],
    options: {
      chart: {
        type: 'pie',
        width: '100%',
        height: '300px'
      },
      dataLabels: { 
        enabled: true,
        offsetX: 0,
        offsetY: 0,
         style: { fontSize: '11px' }, 
         formatter: function (value, opts) {return [opts.w.globals.labels[opts.seriesIndex], opts.w.config.series[opts.seriesIndex]];} },
      labels: specialityVsConsultGraph.label || [],
      colors: ['#ff757b', '#ffd775', '#7fd5c3', '#54a4a6', '#9d8a84', '#d84b51', '#c79210', '#027e81', '#6d4e44', '#df5f9f', '#ff9966', '#cc99ff', '#9933ff'],
      legend: {
        position: 'bottom'
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 310
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    },
  }), [graphData]);



  return (
    <div>
      <div id="chart">
        <ReactApexChart options={chartData?.options} series={chartData?.series} type="pie" />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default ApexChart;
