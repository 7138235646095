import React, { Component } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import $ from "jquery";
import { apiCalling } from "../apiService";
import { Link } from "react-router-dom";
export default class VideocallList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      failedAppointments: [],
      pendingAppointments: [],
      missedAppointments: [],
      appointments: [],
    };

    this.chatNow = this.chatNow.bind(this);
  }
  chatNow(
    doctorId,
    hospitalId,
    doctorVideoScheduleId,
    isSessionBased,
    isFree,
    memberId
  ) {
    if ($("#invalidCheck").is(":checked") == true) {
      //localStorage.setItem("vdoctorId", doctorId);
      // localStorage.setItem("vhospitalId", hospitalId);
      //localStorage.setItem("vdoctorVideoScheduleId", doctorVideoScheduleId);
      // localStorage.setItem("visSessionBased", isSessionBased);
      // localStorage.setItem("visFree", isFree);
      //localStorage.setItem("vuserId", memberId);
      // window.location.href = "/patient/Videocall";
      const apiJson = {
        method: "POST",
        body: JSON.stringify({
          functionName: "startChat",
          siteId: localStorage.getItem("siteId"),
          doctorId: doctorId,
          doctorVideoscheduleId: doctorVideoScheduleId,
          isSessionBased: "1",
          isFree: isFree,
          hospitalId: hospitalId,
          cardId: "1",
          userId: memberId,
          sessionChatNo: "",
          bundleId: "",
        }),
      };

      $(".loader").show();
      apiCalling(apiJson).then((data) => {
        if (data.success == "1") {
          $(".loader").hide();
          localStorage.setItem("apiKey", data.result[0].apiKey);
          localStorage.setItem("sessionId", data.result[0].sessionId);
          localStorage.setItem("token", data.result[0].token);
          localStorage.setItem("startChatData", JSON.stringify(data));
          //localStorage.setItem("chatGroupData", data.result[0].chatGroup);
          window.location.href = "/patient/Videocall";
        } else {
          alert(data.errorMessage);
          $(".loader").hide();
        }
      });
    } else {
      alert("Please agree with terms and conditions");
    }
  }
  componentDidMount() {
    let self = this;
    $(".PAppointment").hide();
    $(".FailedAppointment").hide();
    $(".PendingAppointment").hide();
    $(".MissedAppointment").hide();
    let flag = 0;
    if (flag == 0) {
      $(".loader").show();
    }
    // alert(localStorage.getItem("userId"));
    setInterval(async () => {
      if (localStorage.getItem("isLogin") == "true") {
        const apiJson = {
          method: "POST",
          body: JSON.stringify({
            functionName: "videoAudioAppointment",
            userId: localStorage.getItem("userId"),
            siteId: localStorage.getItem("siteId"),
          }),
        };

        apiCalling(apiJson).then((data) => {
          if (data.success == "1") {
            $(".loader").hide();
            this.setState({
              failedAppointments: data.failedAppointments,
              pendingAppointments: data.pendingAppointments,
              missedAppointments: data.missedAppointments,
              appointments: data.appointments,
            });

            this.setState({
              pendingAppointments: data.pendingAppointments,
            });
            if (data.failedAppointments.length != 0) {
              $(".FailedAppointment").show();
            }
            if (data.pendingAppointments.length != 0) {
              $(".PendingAppointment").show();
            }
            if (data.missedAppointments.length != 0) {
              $(".MissedAppointment").show();
            }
            if (data.appointments.length != 0) {
              $(".PAppointment").show();
            }
          } else {
            alert(data.errorMessage);
            $(".loader").hide();
          }
        });
      }
    }, 5000);
  }

  render() {
    return (
      <div className="purpleWrap">
        <Header></Header>
        <div className="container">
          <div className="main-wrapper main-wrapper-padding">
            <div className="col-sm-12">
              <input type="checkbox" id="invalidCheck" />
              <label className="label-text ml-3" for="invalidCheck">
                I agree with{" "}
                <a href="#" className="link-color">
                  Terms and Conditions
                </a>
              </label>
            </div>
            <div className="table-section mt-5 PAppointment">
              <h4 className="mb-4">Appointment(s) </h4>
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <th>Appointment Date & Time</th>
                    <th>Doctor Name</th>
                    <th>Hospital</th>
                    <th>Service</th>
                    <th>Is Session Based</th>
                    <th>Charge Per Session/Minute</th>
                    <th>Requested Date &amp; Time</th>
                    <th>Status</th>
                  </thead>
                  <tbody>
                    {this.state.appointments.map((obj, index) => {
                      let dates = new Date(obj.startTime);
                      let curDate = new Date();
                      let showStatus = "hide";
                      if (curDate > obj.startTime) {
                        showStatus = "show";
                      }
                      return (
                        <tr>
                          <td className="whitespace-nowrap">
                            {dates.toDateString()}{" "}
                            {dates.toLocaleTimeString("en-IN", {
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: true,
                            })}
                          </td>
                          <td className="whitespace-nowrap">
                            Dr.{obj.doctorFirstName}
                            {obj.doctorLastName}
                          </td>
                          <td>{obj.hospitalName} </td>
                          <td className="whitespace-nowrap">
                            {obj.appointmentType}{" "}
                          </td>
                          <td>{obj.isSessionBased == true ? "Yes" : "No"}</td>
                          <td>Rs 0.00 </td>
                          <td>
                            {obj.requestedDate}{" "}
                            {dates.toLocaleTimeString("en-IN")}{" "}
                          </td>
                          <td className="whitespace-nowrap">
                            {showStatus == "show" ? (
                              <a
                                href="#"
                                className="formButton formButton-size"
                                onClick={() =>
                                  this.chatNow(
                                    obj.doctorId,
                                    obj.hospitalId,
                                    obj.doctorVideoScheduleId,
                                    obj.isSessionBased,
                                    obj.isFree,
                                    obj.memberId
                                  )
                                }
                              >
                                Chat Now
                              </a>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="table-section mt-5 FailedAppointment">
              <h4 className="mb-4">Failed Appointment(s) </h4>
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <th>Appointment Date &amp; Time</th>
                    <th>Doctor Name</th>
                    <th>Hospital</th>
                    <th>Service</th>
                    <th>Is Session Based</th>
                    <th>Charge Per Session/Minute</th>
                    <th>Status</th>
                  </thead>
                  <tbody>
                    {this.state.failedAppointments.map((obj, index) => {
                      let dates = new Date(obj.startTime);
                      return (
                        <tr>
                          <td>
                            {dates.toDateString()}{" "}
                            {dates.toLocaleTimeString("en-IN")}{" "}
                          </td>
                          <td className="whitespace-nowrap">{obj.displayName}</td>
                          <td>{obj.hospitalName} </td>
                          <td className="whitespace-nowrap">
                            {obj.appointmentType}{" "}
                          </td>
                          <td>{obj.isSessionBased == true ? "Yes" : "No"}</td>
                          <td>Rs {obj.ratePerSession} </td>

                          <td className="whitespace-nowrap">
                            <span
                              className="formButton formButton-size"
                              //onClick={this.chatNow()}
                            >
                              Chat Now?
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="table-section mt-5 PendingAppointment">
              <h4 className="mb-4">Pending Appointment(s) </h4>
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <th>Appointment Date &amp; Time</th>
                    <th>Doctor Name</th>
                    <th>Hospital</th>
                    <th>Service</th>
                    <th>Is Session Based</th>
                    <th>Charge Per Session/Minute</th>

                    <th>Status</th>
                  </thead>
                  <tbody>
                    {this.state.pendingAppointments.map((obj, index) => {
                      let dates = new Date(obj.appTime);
                      return (
                        <tr>
                          <td>
                            {dates.toDateString()}{" "}
                            {dates.toLocaleTimeString("en-IN")}
                          </td>
                          <td className="whitespace-nowrap">{obj.displayName}</td>
                          <td>{obj.hospitalName} </td>
                          <td className="whitespace-nowrap">
                            {obj.appointmentType}
                          </td>
                          <td>{obj.isSessionBased == true ? "Yes" : "No"}</td>
                          <td>Rs {obj.ratePerSession} </td>

                          <td>Pending</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="table-section mt-5 MissedAppointment">
              <h4 className="mb-0">Missed Appointment(s) </h4>
              <div className="pendingLink">
                <a href="../home/doctor-list-main">Create New Appointments</a>
              </div>
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <th>Appointment Date & Time</th>
                    <th>Doctor Name</th>
                    <th>Hospital</th>
                    <th>Service</th>
                    <th>Requested Date</th>
                    <th>Is Session Based</th>
                    <th>Status</th>
                  </thead>
                  <tbody>
                    {this.state.missedAppointments.map((obj, index) => {
                      let dates = new Date(obj.appointmentDate);
                      return (
                        <tr>
                          <td>
                            {dates.toDateString()}{" "}
                            {dates.toLocaleTimeString("en-IN")}
                          </td>
                          <td className="whitespace-nowrap">
                            Dr.Test doc Orthopod{" "}
                          </td>
                          <td>Xilligence Clinic Test ortho </td>
                          <td className="whitespace-nowrap">Video Chat </td>
                          <td>{obj.requestedDate}</td>
                          <td>Yes</td>
                          <td>{obj.status}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <Footer></Footer>
      </div>
    );
  }
}
