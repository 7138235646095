import React, { useEffect, useState } from "react";
import { awsAPIUrlcommonToken } from "../apiService";
import Pagination from "react-js-pagination";
import moment from "moment";

const RhFileuploadList = (props) => {
  const [tableData, setTableData] = useState([]);
  const [tableDatas, setTableDatas] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [pageRange, setPageRange] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [offsetNo, setOffsetNo] = useState(0);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    const offset = (pageNumber - 1) * itemsPerPage;
    setOffsetNo(offset)
    consultationReport(offset);
  };

  useEffect(() => {
    consultationReport(offsetNo);
  }, []);



  const consultationReport = (offset) => {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "doctorConsultationReport",
        offset: offset,
        max: itemsPerPage
      })
    };
    // console.log(apiJson)
    awsAPIUrlcommonToken(apiJson)
      .then((data) => {
        // console.log('API Data:', data);
        if (data.success === "1") {
          setTableData(data.result);
          props.sendDataToParent(data.result);
          setTotalCount(data.totalCount);
        } else {
          // console.log('Failed to get data');
          setTableData([]);
          props.sendDataToParent([]);
          setTotalCount(0);
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setTableData([]);
        setTotalCount(0);
      });
  };

  return (<div className="row my-4">
    <div className="main-head col-12">Doctor Consultation Report</div>
    <div className="col-12 col-lg-12 col-md-12">
      <div className="table-responsive">
        <table className="table video_audio_table table-bordered">
          <thead>
            <tr>
              <th>Doctor Name</th>
              <th>Patient Name</th>
              <th>Speciality</th>
              <th>Patient Mobile Number</th>
              <th>Date of Submission</th>
              <th>Month Submitted</th>
              <th>Day</th>
              <th>Transaction Id</th>
              <th>Service</th>
              <th>Date of Transaction</th>
              <th>Time</th>
              <th>Call Status</th>
              <th style={{minWidth:'120px'}}>Feedback</th>
              <th>Policy Number</th>
              <th>Employee Id</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody style={{ fontSize: "12px" }} className="tableHeight">
            {tableData.length > 0 ? (
              tableData.map((row, index) => (
                <tr key={index}>
                  <td>{row.doctor_name}</td>
                  <td>{row.patient_name}</td>
                  <td>{row.speciality}</td>
                  <td>{row.patient_mobile}</td>
                  <td>{moment(row.date_of_submission).format("DD-MM-YYYY")}</td>
                  <td>{row.month_submitted}</td>
                  <td>{row.day}</td>
                  <td>{row.transaction_id}</td>
                  <td>{row.service}</td>
                  <td>{moment(row.date_of_transaction).format("DD-MM-YYYY")}</td>
                  <td>{row.time}</td>
                  <td> {row.call_completed_status}</td>
                  <td>{row.feedback}</td>
                  <td>{row.policy_number}</td>
                  <td>{row.employee_id}</td>
                  <td>{row.status}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="16" className="text-center">No data available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {totalCount > itemsPerPage && (
        <div className="pagination-qualList pagination-qualListwidth text-right mt-3" id="paginationType">
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={itemsPerPage}
            totalItemsCount={totalCount}
            pageRangeDisplayed={pageRange}
            onChange={handlePageChange}
            itemClass="page-item"
            linkClass="page-link"
          />
        </div>
      )}
    </div>
  </div>
  );
};

export default RhFileuploadList;
