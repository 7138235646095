import React, { useEffect } from "react";
import $ from "jquery";

const Doctorseo = ({ doctorData }) => {
  // console.log(doctorData,"doctorDatadoctorDatadoctorDatadocfffffffffffffffffffffftorData");
  useEffect(() => {
    if (doctorData.length > 0) {
      $("#txtSlug").val(doctorData[0].slug);
      $("#txtTitle").val(doctorData[0].seoTitle);
      $("#txtKeyword").val(doctorData[0].keywords);
      $("#txtDescriptionNew").val(
        doctorData[0].description === "undefined" ? "" : doctorData[0].description
      );
    }
  }, [doctorData]);

  const generateSEO = () => {
    if (
      window.localStorage.getItem("seoDisplaytName") === "" ||
      window.localStorage.getItem("seoDisplaytName") === null ||
      window.localStorage.getItem("seoDisplaytName") === undefined
    ) {
      alert("Full Name Required");
    } else if (
      window.localStorage.getItem("seoTitle") === "" ||
      window.localStorage.getItem("seoTitle") === null ||
      window.localStorage.getItem("seoTitle") === undefined
    ) {
      alert("Title Required");
    } else if (
      window.localStorage.getItem("seoCity") === "" ||
      window.localStorage.getItem("seoCity") === null ||
      window.localStorage.getItem("seoCity") === undefined
    ) {
      alert("State Required");
    } else {
      var DoctorId = window.localStorage.getItem("seoDoctorId");
      var displayName =
        window.localStorage.getItem("seoTitle") +
        " " +
        window.localStorage.getItem("seoDisplaytName");
      var seoCity = window.localStorage.getItem("seoCity");
      var seoSpecialization = window.localStorage.getItem("seoSpecialization");
      var seoAddress = window.localStorage.getItem("seoAddress");
      var seoState = window.localStorage.getItem("seoState");
      var seoHospital = window.localStorage.getItem("seoHospital");
      var seoSubcity = window.localStorage.getItem("seoSubcity");

      var slugArray = [];
      var titleArray = [];
      var keywordsArray = [];
      var descriptionArray = [];

      slugArray.push(DoctorId, displayName, seoCity, seoState);
      titleArray.push(displayName, seoCity, seoState);
      keywordsArray.push(displayName, seoCity, seoState);
      descriptionArray.push(
        displayName,
        seoHospital,
        seoSpecialization,
        seoAddress,
        seoCity,
        seoState
      );

      if (seoSubcity !== "") {
        slugArray.push(seoSubcity);
      }
      slugArray.push(seoSpecialization);

      var slug = slugArray.join(" | ");
      var title = titleArray.join("|");
      var keywords = keywordsArray.join(" | ");
      var description = descriptionArray.join(" | ");

      $("#txtSlug").val(slug);
      $("#txtTitle").val(title);
      $("#txtKeyword").val(keywords);
      $("#txtDescriptionNew").val(description);
    }
  };

  return (
    <div className="bg-clr-wthHead mb-4">
      <section>
        <div className="seoWrapperHspMangemnt doctr-wraper-border">
          <div className="container mt-3">
            <span className="seosectinTextHspMangemnt">
              Click the button to create the default SEO details --{" "}
            </span>
            <div className="generatebtnDiv-seohsp">
              <button
                className="generatebtn-seohsp"
                onClick={() => generateSEO()}
              >
                Generate SEO
              </button>
            </div>
            <div className="mt-4 mb-5">
              <div className="seofieldDivhsp mb-2">
                <label>Slug</label>
                <input
                  type="text"
                  name="txtSlug"
                  id="txtSlug"
                  placeholder="Slug"
                  className="hspInfofieldinput"
                />
              </div>
              <div className="seofieldDivhsp">
                <label>Title</label>
                <textarea
                  name="txtTitle"
                  id="txtTitle"
                  cols="30"
                  rows="10"
                  placeholder="Title"
                  className="seofieldDivhsp-textarea"
                ></textarea>
              </div>

              <div className="seofieldDivhsp">
                <label>Keywords</label>
                <textarea
                  name="txtKeyword"
                  id="txtKeyword"
                  cols="30"
                  rows="10"
                  placeholder="The ayurvedic Doctor, homeopathic doctors, veterinarians, Dentists in india.  Dr.   - Kollam - Kerala"
                  className="seofieldDivhsp-textarea"
                ></textarea>
              </div>

              <div className="seofieldDivhsp">
                <label>Description</label>
                <textarea
                  name="txtDescriptionNew"
                  id="txtDescriptionNew"
                  cols="30"
                  rows="10"
                  placeholder="Search engine for doctors. Doctors of India, Doctors Directory,  Dr.   - Kollam - Kerala"
                  className="seofieldDivhsp-textarea"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Doctorseo;
