import React from 'react';
import AboutBannerImg from '../image/theme/about-banner.png'
function AboutBanner() {
    return (
        <>
            <div className='ph-about-banner'>
                <div className='container-lg'>
                    <div className='row align-items-center flex-direction-change'>
                        <div className='col-md-6'>
                            <img src={AboutBannerImg} className="img-fluid" width="auto" height="auto" alt="banner"/>
                        </div>
                        <div className='col-md-6'>
                        <div className="ph-db-banner">
                            <span className="ph-banner-title">About Us</span>

                            <h1 className="ph-about-title mt-5">Embark on the path to <span className='ph-about-title-span'>digital triumph</span> with us as your strategic companion!</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>     
    )
}
export default AboutBanner;