import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header";
import Footer from "../layout/footer";
import ContactForm from "../home/contactus-form";

export default class TermsAndConditions extends Component {
  render() {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    return (
      <div className="purpleWrap green-shade">
        <Header></Header>
        <div className="container">
          <div className="main-wrapper1 terms-wrapper">
            <div className="terms-head pb-4">TERMS AND CONDITIONS</div>
            <div>
              <div className="terms-wrapper terms-wrapper-popup">
                <p className="terms-p-bold">
                  This is the Terms of Service for this website or mobile app
                  and associated clinic.
                </p>
                <p className="terms-p-bold">
                  Please note that this service should not be used for medical
                  emergencies. If there is an immediate medical issue users
                  should go to a proper and accredited health care provider
                  in-person.
                </p>
                <p className="terms-p-bold">
                Tele-Consult is not a substitute for an in-person Doctor visit. Please visit your nearest healthcare provider for a complete check up.
                </p>
                <p className="terms-p-bold">
                  In addition, in order to use any of our services or to engage
                  with a medical professional, users must be 18+ Years of age
                  and Citizens of India.
                </p>
                <p className="terms-p-bold">General</p>
                <p>
                  Please read this Terms of Service policy and in order to use
                  our products and services. By use of our products and services
                  you acknowledge that you have read, understood and have
                  irrevocably agreed to our following Terms of Service
                  conditions and agree to be bound to our{" "}
                  <span className="terms-p-bold">Terms of Service</span> by
                  contractual terms, as well as to our{" "}
                  <span className="terms-p-bold">Privacy Policy</span>.
                </p>
                <p className="terms-p-bold">Definitions</p>
                <p>
                  “We”, “Our”, “Us” - refers to the owner(s) of this website,
                  mobile app and its associated clinic.
                </p>
                <p>
                  “You”, “Your”, “User”, “Patient”, “Client” - refer to the
                  individual User of the mobile app or website and its
                  associated services.
                </p>
                <p>
                  “Health Care Provider” or “HCP” “Provider” - refers to
                  Doctors, Clinicians, Therapists or any other health and
                  medical professional, expert, consultant or practitioner that
                  may be using the platform or service.
                </p>
                <p>
                “Health Care Partner”, “Affiliate”, “Partner”, “Service Provider” - refer to associated partners that we may engage with or whose services that we use such as health care providers, clinics, hospitals, labs, pharmaceutical companies, pharmacies, insurance companies, medical device & equipment providers, third party technology providers or any third party service providers that we may engage with in order to provide services for the User of the apps & website.
                </p>
                <p>
                  “Personal Information”, “Personally Identifiable Information”,
                  “PII” - refer to information that you provide us that
                  identifies you like:&nbsp;
                </p>
                <ul>
                  <li>
                    {" "}
                    <p>Name, Phone Number, Home Address, Email address.</p>{" "}
                  </li>
                  <li>
                    <p>Financial Information like Bank Accounts</p>
                  </li>
                </ul>
                <p>
                  “Personal Health Information”, “PHI” - refer to information
                  that you provide us in regards to your health like:
                </p>
                <ul>
                  <li>
                    <p>
                      Medical information: age, gender, information on your
                      medical conditions, information on your health related
                      issues
                    </p>
                  </li>
                </ul>
                <p>
                  “Digital Health Platform”, “Platform” - refer to the mobile
                  app or website and the underlying technology being used by
                  users in order to access or engage with Health Care Partners
                  and Health Care Providers who provide Digital Health Services.
                </p>
                <p>
                  “Digital Health Services”, “Health Services”, “Services” -
                  Refers to the key functions offered by the Digital Health
                  Platform eg. Booking an appointment with a doctor, Telehealth
                  consultations, Sending of Prescriptions, Creation of an
                  Electronic Health Records and any other medical or health
                  related service enabled by the Platform
                </p>
                <p>
                  “Telehealth”, “Telemedicine” - refers to remote consultation
                  between a patient and a Health Care Provider (eg. a doctor)
                  via phone-call, video-call, email, or text/message chat.
                </p>
                <p className="terms-p-bold">What this Service is For</p>
                <p>
                  This service is primarily designed to help users connect to
                  healthcare providers and medical professionals &amp;
                  practitioners (eg.like doctors) in order to book in-clinic
                  appointments, engage in remote telehealth consultations (eg.
                  video-consult, audio/phone consult or message-chat)&nbsp; and
                  to facilitate any engagement between users and the healthcare
                  providers that are accessible on our service.
                </p>
                <p>
                Tele-Consult is not a substitute for an in-person Doctor visit. Please visit your nearest healthcare provider for a complete check up"
                </p>
                <p className="terms-p-bold">
                  Please note that this service should not be used for medical
                  emergencies. If there is an immediate medical issue users
                  should go to a proper and accredited health care provider
                  in-person.
                </p>
                <p className="terms-p-bold">
                  In addition, in order to use any of our services or to engage
                  with a medical professional, users must be 18+ Years of age
                  and Citizens of India.
                </p>
                <p className="terms-p-bold">Partners &amp; Affiliates</p>
                <p>
                  Any advice or recommendation is not endorsed by our technology
                  partners, affiliates or service providers in any capacity. You
                  acknowledge understanding and agreeing to this fact and you
                  agree that they have no liability to you in any manner or
                  capacity for your use of our services, regardless of outcomes.
                </p>
                <ul>
                  <li>
                    <p>
                      Our services may provide information in the form of
                      articles, videos, graphics or links to other websites and
                      content. Some of the content may be provided by healthcare
                      professionals. Our partners do not endorse or recommend
                      any information, tests, procedures, as viewed on our
                      platforms, services, websites or apps.
                    </p>
                  </li>
                  <li>
                    <p>
                      The Information and Services that we provide or that of
                      our partners, affiliates, associated service providers or
                      licensors are provided on an "as is" and “as available’
                      basis, and without any warranties or conditions (express
                      or implied, including the implied warranties of
                      merchantability, accuracy, or fitness for a particular
                      purpose). To the fullest extent of the law possible, they
                      shall in no event be liable to you or any party or user in
                      regards to any actions taken by you based on any
                      information found on our services provided by us, other
                      users or third parties or partners. As well, the use of
                      any information to verify credentials or healthcare
                      providers or practitioners is not approved by us and is
                      prohibited.
                    </p>
                  </li>
                  <li>
                    <p>
                      We or our partners and affiliates do not endorse or
                      recommend any information on third party websites or apps
                      that may be linked to from our service.
                    </p>
                  </li>
                  <li>
                    <p>
                      You should only follow a course of action that you have
                      discussed with a qualified doctor or medical professional
                      and not simply by following any content viewed on this
                      service. You understand and agree to this fact. Only you
                      are responsible for the information or advice that you
                      follow and you agree that we or our partners are not
                      responsible in any way for the consequences of your
                      actions.
                    </p>
                  </li>
                </ul>
                <p className="terms-p-bold">Unapproved Uses of Our Service</p>
                <p>
                  You agree to not copy our information, content, details of our
                  partners, healthcare providers, details of healthcare
                  professionals whether logged in our logged out of our service,
                  through manual or automated means.
                </p>
                <p>
                  In general you agree to not use our services in a manner other
                  than what it is intended for - namely for you (or your family)
                  to get access to health care professionals and for viewing
                  content on healthcare information. No other use of our
                  services is acceptable.
                </p>
                <p className="terms-p-bold">
                  Limitations of Liability &amp; Indemnification
                </p>
                <p>
                  You agree to not include our technology partners in any legal
                  action in relation to your health for any advice that you have
                  taken or any health related action or medical procedure that
                  you have embarked on using our services.
                </p>
                <p>
                  Should you add your dependent’s personal and/or health
                  information to the services then you are responsible for the
                  information that you provide including ensuring that you have
                  permission to do so and that the information is correct. You
                  agree that you are liable for misinformation, lack of
                  permission to share any personal information of a dependent
                  and that you indemnify us in case of any claim or liability
                  that arises from improper or unauthorized use of such
                  information.
                </p>
                <p>
                  You agree that the maximum liability that we have is the
                  amount that you may have paid for an appointment directly
                  through our platform for services or Rs. 1000 at a maximum.
                </p>
                <p className="terms-p-bold">You Are Responsible For Your Account</p>
                <p>
                  You agree to keep your login, password and account details
                  private at all times. You agree that we are not responsible
                  for any hacking or breach of your account due to any sharing
                  of your account or password information. You must keep your
                  information private at all times.
                </p>
                <p>
                  Furthermore, you must not use someone else’s credentials and
                  access their account.
                </p>
                <p>
                  If you suspect that someone else has your credentials, account
                  information and is using your account, you must contact us
                  immediately and inform us. Please email
                  support@purplehealth.com.
                </p>
                <p className="terms-p-bold">
                  Your Data and Information that You Provide
                </p>
                <p className="terms-p-bold">
                  Please read our Privacy Policy for more details on how we
                  handle your data.
                </p>
                <ul>
                  <li>
                    <p>
                      Note that we do not sell your personal health data or
                      personal identifying data to anyone.
                    </p>
                  </li>
                  <li>
                    <p>
                      You do agree to let us share your data with health care
                      practitioners, healthcare providers or partners &amp;
                      affiliates in order to facilitate booking an appointment,
                      for a telehealth consultation, for appointment
                      rescheduling or cancellation, e-prescriptions or for any
                      action relevant to providing our services to you.
                    </p>
                  </li>
                  <li>
                    <p>
                      You understand and acknowledge that we have no control
                      over or have any liability as it relates to personal
                      information or personal health information that you choose
                      to give to a healthcare provider or healthcare
                      practitioner, using our platform and services.
                    </p>
                  </li>
                  <li>
                    <p>
                      You may voluntarily choose to use services such as
                      uploading a personal health record to the platform or wish
                      to receive health records such as prescriptions from the
                      healthcare provider that you choose to engage with, such
                      as a doctor. This information can be sent through email,
                      SMS and similar means whose details you provide us and the
                      healthcare provider and practitioner for this purpose.
                      Should you provide a wrong email or phone number to which
                      this information is sent, we are not liable in any way for
                      the wrong party receiving this information. It is up to
                      you to ensure that the information that you provide is
                      100% accurate.
                    </p>
                  </li>
                  <li>
                    <p>
                      In addition, should you change your number or give it up,
                      you must update your information with us. You agree that
                      we are not responsible for any issues or problems arising
                      with you not informing us of any changes in your contact
                      details and updating your information on the platform.
                    </p>
                  </li>
                </ul>
                <p className="terms-p-bold">Suspension or Termination of Account</p>
                <p>
                  We reserve the right to suspend or cancel an account for any
                  reason, including:
                </p>
                <ul>
                  <li>
                    <p>
                      Harassment of others including healthcare providers and
                      healthcare practitioners
                    </p>
                  </li>
                  <li>
                    <p>
                      Booking of appointments and not attending the appointment
                      after booking. Typically, though not always, we will
                      suspend an account after 3 such occurrences
                    </p>
                  </li>
                  <li>
                    <p>
                      Sending purposely misleading information to our partner
                      healthcare providers or practitioners
                    </p>
                  </li>
                  <li>
                    <p>Using the system to surveil our partners</p>
                  </li>
                  <li>
                    <p>Copying our Intellectual Property</p>
                  </li>
                  <li>
                    <p>Scraping data from our services</p>
                  </li>
                  <li>
                    <p>Using an account that does not belong to you.</p>
                  </li>
                  <li>
                    <p>
                      Trying to get credentials of accounts that do not belong
                      to you.
                    </p>
                  </li>
                  <li>
                    <p>Any other reason that we see fit.</p>
                  </li>
                </ul>
                <p>
                  Should you wish to delete your account please email{" "}
                  <a href="mailTo:support@purplehealth.com">
                    support@purplehealth.com
                  </a>
                  .
                </p>
                <p className="terms-p-bold">Notifications and Communications</p>
                <ul>
                  <li>
                    <p>
                    In order to remind you of appointments, booking confirmations, telehealth consultations, canceled appointments or for any reason related to our services, you agree to allow us to send you notices and information by SMS, in-app mobile push notifications, browser notifications, messages, emails or phone calls. You may manage these notifications as appropriate in any available settings in our services related to notifications or in your mobile phone or computer device & browser settings.
                    </p>
                  </li>
                  <li>
                    <p>
                      Should you opt out of receiving notifications, you
                      acknowledge that you may miss important notifications such
                      as reminders for events. In addition, should you opt out
                      of notifications and communications, the services may not
                      work properly or may not work at all.
                    </p>
                  </li>
                  <li>
                    <p>
                      You also agree that we may send your Personal Information
                      or Personal Health Information when you use our services
                      to consult with a healthcare provider or healthcare
                      professional such as a doctor. We may send information via
                      email, sms, in-app push-notification as it relates to
                      prescriptions, lab-orders, medical advice and related
                      services which you have voluntarily expressly opted into
                      and consented to allow. Should these communications be
                      intercepted or viewed by someone else, while unlikely, you
                      agree to release us from any liability arising from any
                      interception or unauthorized access.
                    </p>
                  </li>
                </ul>
                <p className="terms-p-bold">Refund of Fees Paid</p>
               
                    <p>
                    Occasionally there may be a scenario in which paid services are, for unforeseen reasons, not delivered by us, our partner, affiliate or service provider. An example may be a telehealth appointment in which the Doctor may not be available as per the booked timeslot. Should that occur, and a rescheduled appointment time is not feasible, your money will be refunded as quickly as possible, usually within a 10 business day time period. Please contact support@purplehealth.com with any such issues. We reserve the right to judge what is reasonable in terms of refunds but will certainly always endeavour to ensure that people are treated fairly.
                    </p>
                 
                <p className="terms-p-bold">Ownership, Trademarks and Copyrights</p>
                <ul>
                  <li>
                    <p>
                      All of the software, content and intellectual property is
                      owned by us or has been licensed by us. We give users such
                      as yourself a limited permission to use our services and
                      access our content. This does not give any permanent
                      access or re-sale or commercial rights to our content or
                      services. You agree to not try to copy, sell or license
                      our content or services or any of our intellectual
                      property.
                    </p>
                  </li>
                </ul>
                <p className="terms-p-bold">User Generated Content</p>
                <p>
                  There may be parts of our service or platform that allows for
                  user generated. In those instances:
                </p>
                <ul>
                  <li>
                    <p>
                      As a user, you agree that any user generated content shall
                      automatically be licensed to us free of charge and free of
                      any legal, trademark or intellectual property encumbrances
                      and free for us to use, should we wish to use it, at any
                      time without any required payment of any sort and in any
                      manner that we choose. This is a perpetual, worldwide
                      license.
                    </p>
                  </li>
                  <li>
                    <p>
                      You also agree to not upload or save any trademarked
                      content on our services or platforms and that we are not
                      liable for any such misuse by you and that you indemnify
                      us for any IP or trademarked content that is uploaded by
                      you.
                    </p>
                  </li>
                  <li>
                    <p>
                      You agree that we are not liable for any of the content
                      uploaded by users. As expressed elsewhere in the Terms of
                      Service, we express no warranties or recommend any such
                      content to follow or believe, which has been stated in
                      other parts of this Terms of Service.
                    </p>
                  </li>
                  <li>
                    <p>
                      You agree to not upload pornography, illegal content or
                      any other content that may harm others, damage the service
                      or platform in any way such as malicious code or anything
                      similar.
                    </p>
                  </li>
                  <li>
                    <p>
                      You also agree that we are free to delete or modify any
                      such content that is posted or uploaded by you.
                    </p>
                  </li>
                </ul>
                <p className="terms-p-bold">Severability</p>
                <p>
                  If any part of the Agreement is held by a legal court, or
                  legal arbitrator, to be unenforceable then that provision will
                  be excluded but this shall not invalidate the rest of the
                  agreement which shall remain and considered to be valid.
                </p>
               
                <p className="terms-p-bold">Jurisdiction and Disputes</p>
                <p>
                  This Agreement is governed by the Laws of India and any legal
                  dispute shall be resolved in the State of Kerala in the City
                  of Thiruvananthapuram.
                </p>
                <p>
                  The dispute resolution method shall be by arbitration for
                  which we (or based on its approval a partner or affiliate)
                  will appoint an arbitrator and the arbitration shall be
                  conducted in English in accordance with the Arbitration and
                  Conciliation Act, 1996.
                </p>
                <p className="terms-p-bold">We May Update This Agreement</p>
                <p>
                  You acknowledge, understand and agree that we may update these
                  Terms of Service from time to time and that the latest Terms
                  of Service will be in effect. We may require you to re-accept
                  the Terms of Use from time to time if we feel that the changes
                  have been substantial enough to warrant that effort.
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    );
  }
}
