import React, { Component } from "react";
import { apiCalling } from "../apiService";
import $ from "jquery";

export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recMobileNo: "",
      recUserId: "",
      textedEmail: "",
      submitOtp: "",
    };
  }
  componentDidMount() {
    let self = this;
    $("#resetPasswordBut").click(function () {
      let emailMob = $("#emailMobno").val();
      if (!emailMob) {
        $("#resetPswMessage").text("Please Enter Your Email Id or Mobile No");
        $("#resetPswMessage").css("color", "red");
      } else {
        const resetPassword = {
          method: "POST",
          body: JSON.stringify({
            functionName: "forgotPassword",
            siteId: localStorage.getItem("siteId"),
            username: emailMob,
            mobileNumber: "",
            otp: "",
            newPassword: "",
            userId: "",
          }),
        };

        apiCalling(resetPassword).then((data) => {
          if (data.success == "1") {
            alert("Please enter otp");
            $("#resetPswMessage").text(data.successMessage);
            $("#resetPswMessage").css("color", "green");
            $("#forgotPasswordFirstDiv").hide();
            $("#forgotPasswordOTPDiv").show();
            self.setState({ recUserId: data.userId });
            self.setState({ recMobileNo: data.mobileNumber });
            self.setState({ textedEmail: emailMob });
          } else {
            alert(data.errorMessage);
          }
        });
      }

      setTimeout(() => {
        $("#resetPswMessage").text("");
      }, 4000);
    });

    $("#resetPasswordButOTP").click(function () {
      let otpTxt = $("#otpTxt").val();
      if (!otpTxt) {
        $("#resetPswMessage").text("Please Enter Your OTP");
        $("#resetPswMessage").css("color", "red");
      } else {
        const password = {
          method: "POST",
          body: JSON.stringify({
            functionName: "forgotPassword",
            siteId: localStorage.getItem("siteId"),
            username: self.state.textedEmail,
            mobileNumber: self.state.recMobileNo,
            otp: otpTxt,
            newPassword: "",
            userId: self.state.recUserId,
          }),
        };

        apiCalling(password).then((data) => {
          if (data.success == "1") {
            $("#forgotPasswordOTPDiv").hide();
            $("#forgotPasswordChangePsd").show();
            $("#resetPswMessage").text("Please Change Your Password");
            $("#resetPswMessage").css("color", "green");
            self.setState({ otpTxt: otpTxt });
          }
        });
      }
      setTimeout(() => {
        $("#resetPswMessage").text("");
      }, 4000);
    });
    //----------------------------------------------------
    $("#resetPasswordButFinal").click(function () {
      let password1 = $("#forgotpasswordNew").val();
      let password2 = $("#forgotpasswordConfirm").val();
      if (!password1) {
        $("#resetPswMessage").text("Please enter your password properly");
        $("#resetPswMessage").css("color", "red");
      } else if (!password2) {
        $("#resetPswMessage").text("Please enter your password properly");
        $("#resetPswMessage").css("color", "red");
      } else if (password1 != password2) {
        $("#resetPswMessage").text(" Your password's are not matched !");
        $("#resetPswMessage").css("color", "red");
      } else {
        const password = {
          method: "POST",
          body: JSON.stringify({
            functionName: "forgotPassword",
            siteId: localStorage.getItem("siteId"),
            username: self.state.textedEmail,
            mobileNumber: self.state.recMobileNo,
            otp: self.state.otpTxt,
            newPassword: password1,
            userId: self.state.recUserId,
          }),
        };
        apiCalling(password).then((data) => {
          if (data.success == "1") {
            $("#resetPswMessage").text("Successfully change your password");
            $("#resetPswMessage").css("color", "green");
          }
        });
      }
      setTimeout(() => {
        $("#resetPswMessage").text("");
      }, 4000);
    });

    $("#forgotPasswordOTPDiv").hide();
    $("#forgotPasswordChangePsd").hide();
  }
  render() {
    return (
      <div className="signInWrap" id="forgotPswdWrap">
        <div className="signInHead">
          <h2>Retrieve Password!</h2>
        </div>
        <span id="resetPswMessage"></span>
        <div className="signInBody">
          <div id="forgotPasswordFirstDiv">
            <input
              type="text"
              className="input-design my-2"
              placeholder="Enter Your Email ID or Mobile Number"
              id="emailMobno"
            />

            <div className="signInButton">
              <input
                type="button"
                value="Send Me"
                className="formButtonBg"
                id="resetPasswordBut"
              />
            </div>
          </div>
          <div id="forgotPasswordOTPDiv">
            <input
              type="text"
              className="input-design my-2"
              placeholder="Enter Your OTP"
              id="otpTxt"
            />

            <div className="signInButton">
              <input
                type="button"
                value="Submit"
                className="formButtonBg"
                id="resetPasswordButOTP"
              />
            </div>
          </div>
          <div id="forgotPasswordChangePsd">
            <input
              type="password"
              id="forgotpasswordNew"
              name="forgotpasswordNew"
              className="input-design my-2"
              placeholder="Password"
            />
            <input
              type="password"
              id="forgotpasswordConfirm"
              name="forgotpasswordConfirm"
              className="input-design my-2"
              placeholder="Confirm Password"
            />

            <div className="signInButton">
              <input
                type="button"
                value="Submit"
                className="formButtonBg"
                id="resetPasswordButFinal"
              />
            </div>
          </div>
          <div className="signUp">
            <h3>
              Back to login? <a id="signInNew">Sign In!</a>
            </h3>
          </div>
        </div>
      </div>
    );
  }
}
