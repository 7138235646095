import React, { Component } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import leftarrow from "../image/media/left-arrow-hsp.png";
export default class doctorDashboardAdmin extends Component {

  render() {
    return (
      <div>
        <Header></Header>
        <div className="dashboardAdminOuter">
          <div className="container">
            <div className="backArowdImgdiv">
              <a href="/admin/dashboard">
                <img src={leftarrow} alt="leftarrow" width="18" />
                <span className="ml-2">Back</span>
              </a>
            </div>
            <div className="sectionItemDiv-dashboard">
              <div className="adminMangemnt-dashboard">
                <div className="admin-dc-dashBtns">
                  <a href="/admin/doctor-dashboard-list">
                    <div>Doctor Wise</div>
                  </a>
                </div>
                {/* <div className="admin-dc-dashBtns">
                  <a href="#">
                    <div>Site Wise</div>
                  </a>
                </div> */}
               
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    );
  }
}
