import React, { Component } from 'react';

export default class EducationalDetails extends Component {
 
  render() {
    return (
                                
      <div className="row px-2"> 
        <div className="col-sm-6">
          <div className="row my-2">
            <label className="col-sm-12 label-text">Graduation  <span className="star-red">*</span></label>
            <div className="col-sm-12 mb-3">
              <select className="input-design">
                <option>Graduation</option>
              </select>
            </div>
            <div className="col-sm-12 mb-3">
              <select className="input-design">
                <option>Year</option>
              </select>
            </div>
            <div className="col-sm-12 mb-3">
              <select className="input-design">
                <option>College</option>
              </select>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="row my-2">
            <label className="col-sm-12 label-text">Post Graduation</label>
            <div className="col-sm-12 mb-3">
              <select className="input-design">
                <option>Post Graduation</option>
              </select>
            </div>
            <div className="col-sm-12 mb-3">
              <select className="input-design">
                <option>Year</option>
              </select>
            </div>
            <div className="col-sm-12 mb-3">
              <select className="input-design">
                <option>College</option>
              </select>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="row my-2">
            <label className="col-sm-12 label-text">Medical Registration </label>
            <div className="col-sm-12 mb-3">
              <select className="input-design">
                  <option>Reg.State</option>
                </select>
            </div>
            <div className="col-sm-12 mb-3">
              <input type="text" className="input-design" placeholder="Medical Reg. No." />
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="row my-2">
            <label className="col-sm-12 label-text">Super Specialization </label>
            <div className="col-sm-12 mb-3">
              <select className="input-design">
                  <option>Select</option>
                </select>
            </div>
            <div className="col-sm-12 mb-3">
              <select className="input-design">
                  <option>Year</option>
                </select>
            </div>
            <div className="col-sm-12 mb-3">
              <input type="text" className="input-design" placeholder="College" />
            </div>
          </div>
        </div>

        <div className="col-sm-12">
            <input type="submit" value="Save" className="formButton"></input>
        </div>
      </div>
    )
  }
}
