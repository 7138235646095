import React from "react";
import { FaCheckCircle } from "react-icons/fa";
function Thankyou() {
    return (
        <div class="vh-100 d-flex justify-content-center align-items-center">
        <div class="card col-md-8 col-lg-4 bg-white shadow-md p-5">
            <div class="mb-4 text-center">
            <FaCheckCircle size={80} className="text-success"/>
            </div>
            <div class="text-center">
                <h1>Thank You !</h1>
                <p className="mb-3">Your form has been submitted successfully.</p>
                <a class="ph-button py-2" href="/">Back Home</a>
            </div>
        </div>
        </div>
    );
}

export default Thankyou;
