import React, { Component } from "react";
import Header from "./header";
import Footer from "./footer";
import RelianceLogin from "./reliance-login";

export default class relianceIndex extends Component {
  constructor() {}
  render() {
    return (
      <div>
        <Header></Header>       
          <RelianceLogin></RelianceLogin>        
        <Footer></Footer>
      </div>
    );
  }
}
