import React, { Component } from 'react';
import Header from "../layout/header";
import Footer from "../layout/footer";
import $ from "jquery";
import tick from "../image/media/tick.png";
import { apiCalling } from "../apiService";


export default class ChatmessageGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeChats: []
    };
    this.onRefresh = this.onRefresh.bind(this);
  }
  componentDidMount() {
    $("#promoApplySection").hide();
    this.getActiveChatQuestions();
  }
  havePromoCode() {
    $("#promoApplySection").show()
    $("#havePromoCode").hide();
  }
  getActiveChatQuestions() {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "chat/getChatQuestions",
        siteId: localStorage.getItem("siteId"),
        hospitalId: "",
        userId: localStorage.getItem("userId"), //"19906",
        bundleId: ""
      }),
    };
    $(".loader").show();
    apiCalling(apiJson).then((data) => {
      if (data.success == "1") {
        $(".loader").hide();
        this.setState({
          activeChats: data.resultArray.activeChats
        });
      }
    });
  }
  onRefresh() {
    this.getActiveChatQuestions();
  }

  render() {
    return (
      <div className="purpleWrap">
        <Header></Header>
        <div className="container container-auto">
          <div className="main-wrapper border-0 px-0 px-md-5">
            <div className="col-md-12 p-0">
              <ul className="breadcrumb">
                <li>
                  {localStorage.getItem("role") == "ROLE_USER" ?
                    <a href="/home/doctor-list-main">Messaging</a>
                    :
                    <a href="/doctor/dashboard">Dashboard</a>
                  }
                  <i className="fa fa-chevron-right"></i></li>
                <li>Chat Questions</li>
              </ul>
            </div>
            <div className="chat-outer">
              <div className="chat-header">
                <span className="chat-span-head">
                  <i className="fa fa-comment"></i> Chat Questions
                </span>
                <span className="chat-span">
                  <button type="button" className="btn-default" onClick={() => this.onRefresh()} >Refresh</button>
                </span>
                <span className="chat-span">
                  <a href="/archivedMessageChats">
                    <button type="button" className="btn-default" >Old Chat(s)</button>
                  </a>
                </span>
                <span className="chat-span">
                  {localStorage.getItem("role") == "ROLE_USER" ?
                    <a href="/home/doctor-list-main">
                      <button type="button" className="btn-default" >Start New Chat</button>
                    </a>
                    : ""}


                </span>
              </div>
              <div className="chat-bodygroup">
                {this.state.activeChats.length > 0 ?
                  <ul className="messages">
                    {this.state.activeChats.map((active) => (
                      <li className="message mb-0">
                        <a href="/chatmessage" className="text-decoration-none">
                          <div className="avatar">
                            <img src={active.imageName} className="avatarimg"></img>
                            <span className="chatBubleOnline">
                              {active.isLogged == "true" ?
                                <img src={tick} />
                                : ""}
                            </span>
                          </div>
                          <div className="textwrappergroup">
                            <div className="text-header">
                              {localStorage.getItem("role") == "ROLE_USER" ?
                                <h6 className="color-maintext">{active.doctorName}</h6>
                                :
                                <h6 className="color-maintext">{active.memberName}</h6>
                              }
                              <time className="message-time">  <i className="fa fa-clock-o"></i> {active.modifiedOn}</time>
                            </div>
                            <p className="text">{active.message}</p>
                          </div>
                        </a>
                        <div className="divider"></div>
                      </li>
                    ))}
                  </ul>
                  : ""}
              </div>
            </div>
          </div>
        </div>

        <Footer></Footer>
      </div>
    )
  }
}
