import React, { useState, useEffect, useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';

const ApexChart = ({ patientVisitData }) => {
  const [graphData, setGraphData] = useState({});
  
  useEffect(() => {
    setGraphData(patientVisitData);
  }, [patientVisitData]);

  const chartData = useMemo(() => ({
    series: patientVisitData, // Using graphData here instead of patientVisitData to reflect the updated state
    options: {
      chart: {
        height: 350,
        type: 'line',
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2
        },
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        }
      },
      colors: ['#a4b592', '#f6b467'],
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '10px',
          fontWeight: 'normal'
        }
      },
      stroke: {
        curve: 'smooth'
      },
      title: {
        text: 'MoM Patient Visits',
        align: 'center',
        style: {
          fontWeight: 100,
          fontSize: '12px'
        }
      },
      grid: {
        borderColor: '#e7e7e7',
        row: {
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.5
        }
      },
      markers: {
        size: 1
      },
      xaxis: {
        categories: patientVisitData?.[0]?.monthYear
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        floating: false,
        offsetY: 0,
        offsetX: 0,
        markers: {
          width: 25,
          height: 5,
          radius: 0,
          shape: 'line'
        }
      }
    }
  }), [graphData]);

  return (
    <div>
      <div id="chart" className="line-chart-report">
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="line"
          height="100%"
          width="90%"
          className="chart-width"
        />
      </div>
    </div>
  );
};

export default ApexChart;
