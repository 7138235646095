import React, { Component, Suspense } from "react";
import Edit from "../../image/icons/edit.png";
import AddImage from "../../image/icons/addImage.png";
import rupeeicon from "../../image/icons/rupee.png";
import Copy from "../../image/icons/copy.png";
import Procedure from "../../image/icons/procedure.svg";
import tick from "../../image/icons/tick.png";
import Deactivate from "../../image/icons/deactvate.png";
import { apiCalling } from "../../apiService";
import $ from "jquery";
import Pagination from "react-js-pagination";
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import { Link } from "react-router-dom";
export default class ContactUsReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactUsReportsData: [],
      totalCount: 0,
      activePage: window.localStorage.getItem("contactUsReportsPageNumber"),
      hospitalIds: [],
      showMessage: "Waiting for data... ",
      pageOffset: 0,
      cmbSelect: "",
      txtInput: "",
    };
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  handlePageChange(pageNumber) {
    window.localStorage.setItem("contactUsReportsPageNumber", pageNumber);
    this.setState({ activePage: pageNumber });
    var offset;
    var max;
    if (pageNumber === "1") {
      offset = "0";
      max = "10";
      this.setState({
        activePage: pageNumber,
        isLoading: "0",
      });
      this.getContactUsEmailDetails(
        offset,
        this.state.cmbSelect,
        this.state.txtInput
      );
    } else {
      offset = parseInt(pageNumber - 1) * 10;
      max = "10";
      this.setState({
        activePage: pageNumber,
        isLoading: "0",
        pageOffset: offset,
      });
      window.localStorage.setItem("contactUsReportsPageNumber", pageNumber);
      window.localStorage.setItem("contactUsReportsOffset", offset);
      this.getContactUsEmailDetails(
        offset,
        this.state.cmbSelect,
        this.state.txtInput
      );
    }
  }
  setactivepagenumber = (contactUsReportsOffset) => {
    window.localStorage.setItem(
      "contactUsReportsOffset",
      contactUsReportsOffset
    );
  };
  componentDidMount() {
    let offset =
      window.localStorage.getItem("contactUsReportsOffset") == null
        ? 0
        : window.localStorage.getItem("contactUsReportsOffset");
    // this.getHospitalDetails();
    this.getContactUsEmailDetails(offset, "", "");
    window.addEventListener("beforeunload", (e) => this.handleBefore(e));
    return () => {
      window.removeEventListener("beforeunload", (e) => this.handleBefore(e));
    };
  }
  handleBefore = (e) => {
    e.preventDefault();
    window.localStorage.setItem("contactUsReportsPageNumber", 1);
    window.localStorage.setItem("contactUsReportsOffset", 0);
  };
  //   getHospitalDetails() {
  //     $(".loader").show();
  //     this.setState({
  //       activePage: Number(
  //         window.localStorage.getItem("contactUsReportsPageNumber")
  //       ),
  //     });
  //     const apiJson = {
  //       method: "POST",
  //       body: JSON.stringify({
  //         functionName: "getHospitalManagementList",
  //         cmbSelect: "",
  //         txtInput: "",
  //         Offset:
  //           window.localStorage.getItem("contactUsReportsOffset") == null
  //             ? 0
  //             : window.localStorage.getItem("contactUsReportsOffset"),
  //         max: "10",
  //       }),
  //     };
  //     apiCalling(apiJson).then((data) => {
  //       if (data.success === "1") {
  //         $(".loader").hide();
  //         this.setState({
  //           contactUsReportsData: data.contactUsReportsData,
  //           totalCount: data.totalCount,
  //         });
  //         // for(let i = 0;i<this.state.contactUsReportsData.length;i++){
  //         //   this.state.hospitalIds.push(this.state.contactUsReportsData[i].hospitalId)
  //         // }
  //         // console.log(this.state.hospitalIds.toString())
  //       }
  //       if (data.success === "0") {
  //         $(".loader").hide();
  //         this.setState({
  //           contactUsReportsData: [],
  //           showMessage: data.errorMessage,
  //         });
  //       }
  //     });
  //   }
  getContactUsEmailDetails(offset, cmbSelect, txtInput) {
    $(".loader").show();
    this.setState({
      activePage: Number(
        window.localStorage.getItem("contactUsReportsPageNumber")
      ),
    });
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "contactUsEmailDetails",
        siteId: "",
        cmbSelect: cmbSelect,
        txtInput: txtInput,
        offset: offset.toString(),
        max: "10",
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        $(".loader").hide();
        this.setState({
          contactUsReportsData: data.resultData,
          totalCount: data.count,
        });
        // for(let i = 0;i<this.state.contactUsReportsData.length;i++){
        //   this.state.hospitalIds.push(this.state.contactUsReportsData[i].hospitalId)
        // }
        // console.log(this.state.hospitalIds.toString())
      }
      if (data.success === "0") {
        $(".loader").hide();
        this.setState({
          contactUsReportsData: [],
          showMessage: data.errorMessage,
        });
      }
    });
  }
  changeText = () => {
    if ($("#selectCategory").val() !== "0") {
      document.getElementById("contactUsReportsTxt").disabled = false;
      $("input[name=contactUsReportsTxt]").focus();
    } else {
      document.getElementById("contactUsReportsTxt").disabled = true;
    }
    document.getElementById("contactUsReportsTxt").value = "";
  };
  searchHandler() {
    if (
      $("#selectCategory").val() !== "0" &&
      $("#contactUsReportsTxt").val() === ""
    ) {
      var t = document.getElementById("selectCategory");
      var selectedText = t.options[t.selectedIndex].text;
      alert(selectedText + " Required");
      $("#contactUsReportsTxt").focus();
      return false;
    } else {
      this.setState(
        {
          cmbSelect: $("#selectCategory").val(),
          txtInput: $("#contactUsReportsTxt").val(),
        },
        () => {
          this.getContactUsEmailDetails(
            "0",
            this.state.cmbSelect,
            this.state.txtInput
          );
        }
      );
      //
    }
  }
  //   statusChangeHandler(id, adminId, Status) {
  //     var x = false;
  //     if (Status === true) {
  //       x = window.confirm("Do you want to Disable this?");
  //     } else {
  //       x = window.confirm("Do you want to Enable this?");
  //     }
  //     if (x === true) {
  //       const SatusData = {
  //         method: "POST",
  //         body: JSON.stringify({
  //           functionName: "deactivateHospital",
  //           hospitalId: id,
  //           hospitalAdminId: adminId,
  //           isActive: Status === true ? 0 : 1,
  //         }),
  //       };
  //       $(".loader").show();
  //       apiCalling(SatusData).then((data) => {
  //         if (data.success === "1") {
  //           $(".loader").hide();
  //           alert("Status Changed Successfully");
  //           this.getHospitalDetails();
  //         }
  //         if (data.success === "0") {
  //           $(".loader").hide();
  //           alert(data.errorMessage);
  //         }
  //       });
  //     }
  //   }
  render() {
    return (
      <div className="purpleWrap bg-clr-wthHead">
        <Suspense fallback={<div></div>}>
          <section>
            <Header /> <div className="loader"></div>
          </section>
        </Suspense>
        <div className="loader"></div>
        <div className="container mb-5">
          <div>
            <ul className="breadcrum-adminMangmnt">
              <li>
                <a href="/admin/dashboardAdmin">Dashboard</a>
              </li>
              <li>
                <a
                  href="/adminManagement/hospitalManagementList/hospitalManagementList"
                  className="current"
                >
                  Reports
                </a>
              </li>
            </ul>
          </div>
          <div className="head-hospMangmntList">Enquiry Report</div>
          <div className="HosptlMangemntTable">
            {this.state.totalCount > 5 ? (
              <div
                className="paginationSection orderm-paginationSection pagination-HspList"
                id="paginationType" style={{width:'100%'}}
              >
                <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.totalCount}
                  pageRangeDisplayed={10}
                  onChange={this.handlePageChange}
                />
              </div>
            ) : (
              ""
            )}
            <div className="tableHeader-HspMangemnt">
              <div className="selectField-HspMangmnt">
                <select
                  className="slecthsp slecthspMob"
                  id="selectCategory"
                  name="selectCategory"
                  onChange={() => this.changeText()}
                >
                  <option value="0">Search In</option>
                  <option value="1">Name</option>
                  <option value="2">Email</option>
                  <option value="3">Enquiry Type</option>
                </select>
              </div>
              <div className="selectField-HspMangmnt">
                <input
                  type="text"
                  className="hspmngInput hspmngInputmob"
                  name="contactUsReportsTxt"
                  id="contactUsReportsTxt"
                  hidden={false}
                  disabled
                  tabIndex="0"
                  onKeyPress={(event) => {
                    if (event.key === "Enter" || event.key === "NumpadEnter") {
                      event.preventDefault();
                      this.searchHandler();
                    }
                  }}
                />
                <select id="messageTypeSelect" hidden={true}></select>
              </div>
              <div className="selectField-HspMangmnt">
                <button
                  className="searchbtn-hspMng"
                  onClick={() => this.searchHandler()}
                >
                  Search
                </button>
              </div>
            </div>
            {this.state.contactUsReportsData.length > 0 ? (
              <div className="table-responsive">
                <table className="table table-bordered-hsp">
                  <thead>
                    <tr>
                      <th className="tablehead-hspmng" width="30%">
                        Name
                      </th>
                      <th className="tablehead-hspmng" width="10%">
                        Phone
                      </th>
                      <th className="tablehead-hspmng" width="20%">
                        Email Id
                      </th>
                      <th className="tablehead-hspmng" width="10%">
                        Message
                      </th>
                      <th className="tablehead-hspmng" width="10%">
                        Enquiry Type
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.contactUsReportsData.map((data, i) => (
                      <tr key={i}>
                        <td>{data.name}</td>
                        <td>{data.phone}</td>
                        <td>{data.emailId}</td>
                        <td>{data.message}</td>
                        <td>{data.messageType}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {this.state.totalCount > 5 ? (
                  <div className="paginationSection orderm-paginationSection pagination-HspList pagination-HspListwidth">
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={10}
                      totalItemsCount={this.state.totalCount}
                      pageRangeDisplayed={10}
                      onChange={this.handlePageChange}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <p className="error-msg text-center mb-0">
                {" "}
                {this.state.showMessage}{" "}
              </p>
            )}
          </div>
        </div>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
