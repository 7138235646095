import React, { Component,useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { apiCalling } from "../../apiService";
import QRcode from "react-qr-code";
import $ from "jquery";

const GenerateQRcodeIda =()=> {
    const[storeName,setstoreName]=useState('');
    const[storeLocation,setstoreLocation]=useState('');
    const[storeContact,setstoreContact]=useState('');
    const[storeCode,setstoreCode]=useState('');
    const { storeId } = useParams();
    const [state, setState] = useState({});
    useEffect(() => {
        qrIdaStoreDetails();
      }, []);
    const cancelHandle=()=> {
        window.location.href = "/adminManagement/pharmadashboardList/idaStoresList";
    }

    const qrIdaStoreDetails=()=> {
        const idaStoreData = {
            method: "POST",
            body: JSON.stringify({
                functionName: "getStoreListingByCategory",
                idaStoreCategoryId: "",
                keyWord: "",
                idaStoreDetailsId: storeId,
                Offset: "0",
                max: "10",
            }),
        };
        $(".loader").show();
        apiCalling(idaStoreData).then((data) => {
            if (data.success === "1") {
                setstoreName(data.result[0].storeName);
                setstoreLocation(data.result[0].address);
                setstoreContact(data.result[0].contactNo);
                setstoreCode(data.result[0].storeCode);

            }
            $(".loader").hide();
        });
    }

   const printQrcode=()=> {
        document.getElementById('printButton').style.opacity = '0';
        document.getElementById('cancelButton').style.opacity = '0';
        window.print();
        document.getElementById('printButton').style.opacity = '1';
        document.getElementById('cancelButton').style.opacity = '1';
    };
        const objectString = JSON.stringify(state);
        return (
            <div>
                <br /><br />
                <div className="loader"></div>
                {storeName !== "" ? 
                <div  style={{ height: "auto", margin: "0 auto", maxWidth: "30%", width: "100%" }}
                className="qrcode-container">
                    <label>QR Code For:{storeName}</label>
                   <QRcode
                        size={256}
                        style={{ height: "auto", maxWidth: "100%", width: "100%", }}
                        value={objectString}
                        viewBox={`0 0 256 256`}
                    />
                    <br /><br />
                    <div className="col-md-12 text-right mb-3 p-0">
                        <input
                            type="submit"
                            id="printButton"
                            className="smssetng-svebtn"
                            value="Print"
                            onClick={() =>printQrcode()}
                        />
                        <input
                            type="submit"
                            value="Cancel"
                            id="cancelButton"
                            className="smssetng-cancelbtn"
                            onClick={cancelHandle}
                        />
                    </div>
                </div> : ""}

            </div>
        );
    }
export default GenerateQRcodeIda;

