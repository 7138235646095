import React, { Component } from 'react';

export default class Achievements extends Component {
 
  render() {
    return (
        <div className="row px-2">
            <div className="col-sm-6">
            <div className="row my-2"> 
                <label className="col-sm-12 label-text">Type</label>
                <div className="col-sm-12  px-0  px-sm-3">
                <select className="input-design">
                    <option>Select </option>
                </select>                                  
                </div>
            </div>
            </div>
            <div className="col-sm-12  px-0  px-sm-3">
            <div className="row my-2">
                <label className="col-sm-12 label-text">Description</label>
                <div className="col-sm-12  px-0  px-sm-3">
                <textarea className="input-design textarea" style={{fontSize: "14px"}} placeholder="Description"></textarea>                                 
                </div>
            </div>
            </div>
            <div className="table-responsive">
            <table className="table" id="tblLocation">
                <thead>
                    <tr>           
                    <th className="">Achievement Type</th>
                    <th className=""> Description</th>
                    </tr>
                </thead>
                <tbody>
                <tr>
                    <td className="">
                        <input disabled="disabled" placeholder="Certifications" type="text" className="input-design" />
                    </td>
                    <td className="">
                        <input disabled="disabled" type="text" className="input-design" style={{width:"80%"}}/>
                        <span className=" fa fa-trash color-maintext ml-3"></span>
                    </td>
                </tr>
                    
                </tbody>
            </table>
            </div>
            
            <div className="col-sm-12">
                <input type="submit" value="Add" className="formButton"></input>
            </div>
    </div>
    )
  }
}
