import React, { Component } from 'react';
import doctor from "../image/media/doctor.jpg"

export default class DoctorImage extends Component {
 
  render() {
    return (
        <div className="row px-2">
          <div className="col-sm-4"> 
            <div className="fileUpload">
              <span>Add Medical Records</span>
              <input type="button" id="attach" name="attach" className="upload" value="Add Medical Records" />
              <input type="file" className="upload" />
            </div>
          </div>
          <div className="col-sm-4">
            <img src={doctor} width="50"></img>
          </div>
          <div className="col-sm-12  px-0  px-sm-3">
            <p className="note">Select a recent photograph of yourself in jpg/png/gif format to upload.</p>
          </div>
          
          <div className="col-sm-12">
                <input type="submit" value="Upload" className="formButton"></input>
            </div>
        </div>
    )
  }
}
