export function awsUrl() {
  const apiUrl =
    // "https://a4c2uh4pka.execute-api.ap-south-1.amazonaws.com/test/purplehealth";
    "https://a4c2uh4pka.execute-api.ap-south-1.amazonaws.com/prod_Token/purplehealth";
  //  "https://a4c2uh4pka.execute-api.ap-south-1.amazonaws.com/development-ph/purplehealth";
  return apiUrl;
}
// 
export function awsUrlcommon() {
  const apiUrl =
    // "https://a4c2uh4pka.execute-api.ap-south-1.amazonaws.com/test/purplehealthcommon";
    "https://a4c2uh4pka.execute-api.ap-south-1.amazonaws.com/prod_Token/purplehealthcommon";
  return apiUrl;
}

export function apiUrlReliance() {
  const apiUrlReliance =
    // "https://a4c2uh4pka.execute-api.ap-south-1.amazonaws.com/reliancetest/purplehealth";
    "https://a4c2uh4pka.execute-api.ap-south-1.amazonaws.com/relianceProd_Token/purplehealth";
  return apiUrlReliance;
}

export function awsUrlcommonToken() {
  const apiUrlReliance =
    // "https://a4c2uh4pka.execute-api.ap-south-1.amazonaws.com/reliancetest/purplehealthcommon";
    "https://a4c2uh4pka.execute-api.ap-south-1.amazonaws.com/relianceProd_Token/purplehealthcommon";
  return apiUrlReliance;
}
// export function jwtSecret() {
//   const jwtSecret = "Xilli1123581321$";
//   return jwtSecret;
// }
   