import React, { Component, Suspense } from "react";
import Header from "../../layout/header";
import Footer from "../../layout/footer";

class medicineBranch extends Component {
  state = {};

  render() {
    const navigation = [
      { pageName: "DashBoard", url: "/admin/dashboard" },
      {
        pageName: "Medical Branch Dashboard",
        url: "/adminManagement/medicineBranch",
        current: true,
      },
    ];
    return (
      <div className="purpleWrap bg-clr-wthHead">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />

            <div className="container">
              <div>
                <ul className="breadcrum-adminMangmnt">
                  {navigation.map((nav) => (
                    <li>
                      <a
                        href={nav.url}
                        className={nav.current ? "current" : null}
                      >
                        {nav.pageName}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="head-hospMangmntList">MedicineBranch</div>
              <div className="sectionItemDiv sectionItemDivBorder sectionBorderadminMangmnt mt-3">
                <div className="adminMangemnt-dashboard">
                  <div className="admin-dc-dashBtns">
                    <a href="/adminManagement/Specialisation">
                      <div>Specialisation</div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Suspense>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}

export default medicineBranch;
