import React, { Component, Suspense } from "react";
import Doctor from "./doctor";
import Pagination from "react-js-pagination";
import $ from "jquery";
import { apiCalling } from "../../../apiService";
import HospitalAutoComplete from "./HospitalAutoComplete";
import DoctorAutoComplete from "./DoctorAutoComplete";
import SpecialityAutoComplete from "./SpecialityAutoComplete";
import Header from "../../../layout/header"
import Footer from "../../../layout/footer"

class doctorPanel extends Component {
  state = {
    docMsgSearchData: [],
    activePage: 1,
    max: 12,
    offset: 0,
    totalCount: 0,
    tempHospitalData: { hospitalId: 0, hospitalName: "" },
    tempSpecialityData: { specialityId: 0, specialityName: "" },
    tempDoctorData: { doctorId: 0, doctorName: "" },
    chatStatus:1   
  };

  componentDidMount() {
    this.getDocMsgSearchData();
  }

  async handlePageChange(pageNumber) {
    await this.setState({
      activePage: pageNumber,
      offset: (parseInt(pageNumber) - 1) * this.state.max,
    });

    this.getDocMsgSearchData();
  }

  renderPanel() {
    return this.state.docMsgSearchData.map((dm) => (
      <Doctor
        key={dm.doctorId}
        doctorData={dm}
        addRemoveMessaging={this.handleAddRemoveMessaging}
      ></Doctor>
    ));
  }

  getDocMsgSearchData() {
    //debugger;
    const getDoctorMessaginglist = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getDoctorMessaginglist",
        specialityId: this.state.tempSpecialityData.specialityId,
        hospitalId: this.state.tempHospitalData.hospitalId,
        doctorId: this.state.tempDoctorData.doctorId?this.state.tempDoctorData.doctorId:"",
        max: this.state.max,
        Offset: this.state.offset,
      }),
    };

    $(".loader").show();
    apiCalling(getDoctorMessaginglist).then((data) => {
        //debugger;
      if (data.success === "1") {
        this.setState(
          {
            docMsgSearchData: data.DocMsgSearchData,
            totalCount: data.totalCount,
          },
          () => {
            $(".loader").hide();
          }
        );
      } else if (data.success === "0") {
        this.setState({ docMsgSearchData: [], totalCount: 0 }, () => {
          $(".loader").hide();
        });
      }
    });
  }

  doctorPanelMsgDisable(state) {
    $(".loader").show();
    const doctorPanelMsgDisable = {
      method: "POST",
      body: JSON.stringify({
        functionName: "doctorPanelMsgDisable",
        hospitalId: state.hospitalId,
        doctorId: state.doctorId,
      }),
    };

    apiCalling(doctorPanelMsgDisable).then((data) => {
      if (data.success === "1") {
        this.getDocMsgSearchData();
        alert("Doctor is removed from message panel");
      }
    });
  }

  saveDoctorChatSetting(state) {
    $(".loader").show();
    const doctorChatSetting = {
      method: "POST",
      body: JSON.stringify({
        functionName: "doctorChatSetting",
        status: state.chatStatus,
        hospitalId: state.hospitalId,
        doctorId: state.doctorId,
        expirydate: state.dayOfExpiry,
        messagecost: state.messagingCost,
      }),
    };
    apiCalling(doctorChatSetting).then((data) => {
      if (data.success === "1") {
        this.getDocMsgSearchData();
        alert("Doctor is added to message panel");
      }
    });
  }

  handleAddRemoveMessaging = (state, isAddedPanel) => {
    if (isAddedPanel) {
      this.doctorPanelMsgDisable(state);
    } else {
      this.saveDoctorChatSetting(state);
    }
  };

  handleHospitalAutoFill = (e) => {
    this.setState({ tempHospitalData: e });
  };

  handleSpecialityAutoFill = (e) => {
    this.setState({ tempSpecialityData: e });
  };

  handleDoctorAutoFill = (e) => {
    this.setState({ tempDoctorData: e });
  };

  handleDoctorSearch = (e) => {
    this.setState({
      tempDoctorData: {
        doctorId: 0,
        doctorName: e,
      },
    });
  };

  handleSpecialitySearch = (e) => {
    this.setState({
      tempSpecialityData: { 
        specialityId: 0,
        specialityName: e,
      },
    });
  };

  handleHospitalSearch = (e) => {
    this.setState({
      tempHospitalData: {
        hospitalId: 0,
        hospitalName: e,
      },
    });
  };

  render() {
    return (
      <div className="purpleWrap bg-clr-wthHead">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />

            <div className="container mb-5">
              <div>
                <ul className="breadcrum-adminMangmnt">
                  <li>
                    <a href="/admin/dashboard">Dashboard</a>
                  </li>
                  <li>
                    <a href="/adminManagement/messaging">Messaging</a>
                  </li>
                  <li>
                    <a
                      href="/adminManagement/messaging/doctorPanenl"
                      className="current"
                    >
                      Doctor Panel
                    </a>
                  </li>
                </ul>
              </div>
              <div className="head-hospMangmntList">
                Add Doctor Panel To Messaging
              </div>

              <div className="row tableHeader-HspMangemnt table-headerDctrPanel tble-dctrpanel-mob">
                <div className="col-md-3 dctrpanel-headinput">
                  <HospitalAutoComplete
                    onItemChange={this.handleHospitalAutoFill}
                    setSearch={this.handleHospitalSearch}
                    tempHospitalData={this.state.tempHospitalData}
                  />
                </div>
                <div className="col-md-3 dctrpanel-headinput">
                  <SpecialityAutoComplete
                    onItemChange={this.handleSpecialityAutoFill}
                    setSearch={this.handleSpecialitySearch}
                    tempSpecialityData={this.state.tempSpecialityData}
                  />
                </div>
                <div className="col-md-3 dctrpanel-headinput">
                  <DoctorAutoComplete
                    onItemChange={this.handleDoctorAutoFill}
                    setSearch={this.handleDoctorSearch}
                    tempHospitalData={this.state.tempDoctorData}
                  />
                </div>
                <div className="col-md-3">
                  <button
                    className="searchbtn-hspMng"
                    onClick={() => this.getDocMsgSearchData()}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="row">
                {this.renderPanel()}

                {/* <div
                  style={{
                    marginTop: "15px",
                    marginBottom: "15px",
                  }}
                > */}
                  {this.state.totalCount > this.state.max ? (
                    <div className="paginationSection orderm-paginationSection pagination-HspList pagination-HspListwidth p-3">
                      <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.state.max}
                        totalItemsCount={this.state.totalCount}
                        pageRangeDisplayed={10}
                        onChange={(pageNumber) =>
                          this.handlePageChange(pageNumber)
                        }
                      />
                    </div>
                  ) : (
                    ""
                  )}
                {/* </div> */}
              </div>
            </div>
          </section>
        </Suspense>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}

export default doctorPanel;
