import React, { Component } from "react";
import $ from "jquery";
export default class DoctorOfficeTime extends Component {
  officeTime(value, name, i, mode) {
    alert(value + name + mode)
    this.props.setOfficeHoursData(value, name, i, mode)
  }
  loadTime(mode1, mode2, mode3) {
    var dayArray = ["Mon", "Tue", "Wed", "Thur", "Fri", "Sat", "Sun"]
    var val = $("#" + mode3).val()
    for (var i = 0; i < dayArray.length; i++) {
      if ($("#" + mode1 + dayArray[i] + mode2).val() === "0") {
        $("#" + mode1 + dayArray[i] + mode2).val(val)
      }
    }
    if (mode1 !== "sh1" && mode1 !== "sh2") {
      this.props.setOfficeHoursData(this.props.idValue, this.props.index, "", this.props.objectVal, this.props.schedule)
    }
  }
  render() {
    return (
      <div className="doctroffcehrDay">
        <select value={this.props.inputVal} id={this.props.idValue} name={this.props.nameValue} onChange={() => this.loadTime(this.props.nameValue, this.props.timeType, this.props.idValue)}>
          <option value="0">{this.props.timeType}</option>
          <option value="24">12:00 AM</option>
          <option value="36">12:30 AM</option>
          <option value="1">01:00 AM</option>
          <option value="25">01:30 AM</option>  
          <option value="2">02:00 AM</option>
          <option value="26">02:30 AM</option>
          <option value="3">03:00 AM</option>
          <option value="27">03:30 AM</option>
          <option value="4">04:00 AM</option>
          <option value="28">04:30 AM</option>
          <option value="5">05:00 AM</option>
          <option value="29">05:30 AM</option>
          <option value="6">06:00 AM</option>
          <option value="30">06:30 AM</option>
          <option value="7">07:00 AM</option>
          <option value="31">07:30 AM</option>
          <option value="8">08:00 AM</option>
          <option value="32">08:30 AM</option>
          <option value="9">09:00 AM</option>
          <option value="33">09:30 AM</option>
          <option value="10">10:00 AM</option>
          <option value="34">10:30 AM</option>
          <option value="11">11:00 AM</option>
          <option value="35">11:30 AM</option>
          <option value="12">12:00 PM</option>
          <option value="48">12:30 PM</option>
          <option value="13">01:00 PM</option>
          <option value="37">01:30 PM</option>
          <option value="14">02:00 PM</option>
          <option value="38">02:30 PM</option>
          <option value="15">03:00 PM</option>
          <option value="39">03:30 PM</option>
          <option value="16">04:00 PM</option>
          <option value="40">04:30 PM</option>
          <option value="17">05:00 PM</option>
          <option value="41">05:30 PM</option>
          <option value="18">06:00 PM</option>
          <option value="42">06:30 PM</option>
          <option value="19">07:00 PM</option>
          <option value="43">07:30 PM</option>
          <option value="20">08:00 PM</option>
          <option value="44">08:30 PM</option>
          <option value="21">09:00 PM</option>
          <option value="45">09:30 PM</option>
          <option value="22">10:00 PM</option>
          <option value="46">10:30 PM</option>
          <option value="23">11:00 PM</option>
          <option value="47">11:30 PM</option>
          <option value="49">12:00 PM</option>
        </select>
      </div>
    )
  }
}