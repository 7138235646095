import React, { useState } from "react";
import ViewImg from "../../image/icons/view.png";
import InnerHTML from "dangerously-set-html-content";
import Pagination from "react-js-pagination";
import Analisationpopup from "./analisationpopup";

function AnalyticsListNewsFeedDownload(props) {
    const [emojiData, setEmojidata] = useState()
    const [SelectedDetailsType, setSelectedDetailsType] = useState("");
    const [flag, setFlag] = useState(false);
    const handlePageChange = (pageNumber) => {
        props.setPagination(pageNumber);
    };

    const addEditSpec = (data, type) => {
        setEmojidata(data)
        setSelectedDetailsType(type)
        setFlag(!flag)
    };

    return (
        <div>
            <div className="loader"></div>
            {props.newsFeedDownloadcount !== 0 && (
                <div className="paginationSection orderm-paginationSection pagination-HspList w-100">
                    <Pagination
                        activePage={props.activepage}
                        itemsCountPerPage={10}
                        totalItemsCount={props.newsFeedDownloadcount}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                    />
                </div>
            )}
            <div className="table-responsive">
                {props.newsFeedDownloadcount !== 0 ? (
                    <table className="table table-bordered-hsp table_custom">
                        <thead>
                            <tr>
                                <th>Newsfeed Download Title</th>
                                <th>Newsfeed Download Message</th>
                                <th>Count</th>
                                <th>Emoji Count</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.newsFeedDownloadData.map((data) => (
                               <tr key={data.messageId}>
                               <td>{data.messageTitle}</td>
                               <td className="journl-des-txt span_12">
                                   <InnerHTML html={data.messageDetails} />
                               </td>
                               <td>
                                   <a
                                       className="mr-2 count-text"
                                       onClick={() => addEditSpec(data, "Messege")}
                                   >
                                       {data.viewCount}{" "}
                                       <img src={ViewImg} alt="Edit" title="More" width="18" className="img-fluid edit_view" />
                                   </a>
                               </td>
                               <td>
                                   {data.emojiData.map((EmojiData) => (
                                       <span key={EmojiData.imageName}>
                                           <a className="mr-2 emoji-text">{EmojiData.count}</a>
                                           <img
                                               src={EmojiData.imageName}
                                               onClick={() => addEditSpec(EmojiData.userData, data.messageTitle)}
                                               alt="Edit"
                                               title={EmojiData.emojiName}
                                               width="18"
                                               className="img-fluid edit_view"
                                           />
                                       </span>
                                   ))}
                               </td>
                           </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <div className="nodata_found">No Data Found</div>
                )}
            </div>
            {SelectedDetailsType !== "" && (
                <Analisationpopup
                    key={flag}
                    PopupnewsFeedDownloadDataId={emojiData}
                    PopupnewsFeedDownloadType={SelectedDetailsType}
                />
            )}
        </div>
    );
}

export default AnalyticsListNewsFeedDownload;
