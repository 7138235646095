import React, { useState,useEffect } from "react";
import { useMemo } from "react";
import ReactApexChart from "react-apexcharts";

  const ApexChart = ({ generateDoctorVsConsultGraph }) => {
    const [graphData, setGraphData] = useState({})
    
    useEffect(() => {
      setGraphData(generateDoctorVsConsultGraph)
    }, [generateDoctorVsConsultGraph])

  const chartData= useMemo(() => ({
    series: [{
      name: 'Consultation Count',
      data: generateDoctorVsConsultGraph.series,     
    }],
    options: {
      chart: {
        type: 'bar',
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          borderRadiusApplication: 'end',
          horizontal: true,
          dataLabels: {
            position: 'top', // Ensure the dataLabels position is within plotOptions.bar
          }
        }
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '12px',
          colors: ['#000'],
        },
        formatter: function (val) {
          return val;
        },
        offsetX: 10,
        offsetY: 0,
        textAnchor: 'start' // Ensure the text anchor is set to 'end' for right-aligned text
      },
      xaxis: {
        categories: generateDoctorVsConsultGraph.label,
      },
      colors: ['#00a76d'] // Custom colors
    }
  }), [graphData]);

  return (
    <div>
      <div id="chart">
        <ReactApexChart 
          options={chartData.options} 
          series={chartData.series} 
          type="bar" 
          height={
          generateDoctorVsConsultGraph.label.length > 25
            ? 750
            :generateDoctorVsConsultGraph.label.length > 10
            ? 550
            : 350
           } 
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default ApexChart;
