import React, { Component, Suspense, useState, useEffect } from "react";
import Edit from "../../image/icons/edit.png";
import tick from "../../image/icons/tick.png";
import QrcodeImg from "../../image/icons/qrcode.png";
import Deactivate from "../../image/icons/deactvate.png";
import { apiCalling } from "../../apiService";
import { Link } from "react-router-dom";
import $ from "jquery";
import Pagination from "react-js-pagination";
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import Logoimg from "../../image/icons/image-preview.png";
import "../../css/admin.css"
const IdaStoresList = () => {
  const [storeResult, setstoreResult] = useState([]);
  const [totalCount, settotalCount] = useState(0);
  const [activePage, setactivePage] = useState(1);
  const [showMessage, setshowMessage] = useState("Waiting for data... ");
  const [isLoading, setisLoading] = useState("0");
  const [approvalResult, setapprovalResult] = useState([])
  const handlePageChange = (pageNumber) => {
    setactivePage(pageNumber);
    var offset;
    var max;
    if (pageNumber === "1") {
      offset = "0";
      max = "10";
      setactivePage(pageNumber);
      setisLoading("0");
    } else {
      offset = parseInt(pageNumber - 1) * 10;
      max = "10";
      setactivePage(pageNumber);
      setisLoading("0");
      window.localStorage.setItem("idaStoresListpageNumber", pageNumber);
      window.localStorage.setItem("idaStoresListoffset", offset);
    }
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getStoreListingByCategory",
        cmbSelect: $("#searchType").val() === "0" ? "" : $("#searchType").val(),
        txtInput: $("#resultTxt").val(),
        Offset: JSON.stringify(offset),
        max: max,
      }),
    };
    $(".loader").show();
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        setstoreResult(data.result)
        // setapprovalResult(data.RepApprovalSearchData);
        settotalCount(data.totalCount);
        $(".loader").hide();
      }
    });
  }

  useEffect(() => {
    getIdaStoreDetails();
  }, []);

  const getIdaStoreDetails = () => {
    $(".loader").show();
    const idaStoreList = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getStoreListingByCategory",
        idaStoreCategoryId: "",
        keyWord: "",
        idaStoreDetailsId: "",
        Offset:
          window.localStorage.getItem("idaStoresListoffset") == null
            ? 0
            : window.localStorage.getItem("idaStoresListoffset"),
        max: "10"
      }),
    };
    apiCalling(idaStoreList).then((data) => {
      if (data.success === "1") {
        $(".loader").hide();
        setstoreResult(data.result);
        settotalCount(data.totalCount);
        setactivePage(Number(window.localStorage.getItem("idaStoresListpageNumber")));

      }
      if (data.success === "0") {
        $(".loader").hide();
        setstoreResult([]);
        setshowMessage(data.errorMessage);
      }
    });
  }
  const changeText = () => {
    if ($("#searchType").val() !== "0") {
      document.getElementById("resultTxt").disabled = false;
      $("input[name=resultTxt]").focus();
    } else {
      document.getElementById("resultTxt").disabled = true;
    }
    document.getElementById("resultTxt").value = "";
  };
  const searchHandler = () => {
    if ($("#searchType").val() !== "0" && $("#resultTxt").val() === "") {
      var t = document.getElementById("searchType");
      var selectedText = t.options[t.selectedIndex].text;
      alert(selectedText + " Required");
      $("#resultTxt").focus();
      return false;
    } else {
      const searchData = {
        method: "POST",
        body: JSON.stringify({
          functionName: "getStoreListingByCategory",
          keyWord: $("#resultTxt").val(),
          status: $("#searchStatusType").val() === '0' ? '' : $("#searchStatusType").val(),
          idaStoreDetailsId: "",
          Offset: "0",
          max: "10",
        }),
      };
      $(".loader").show();
      apiCalling(searchData).then((data) => {
        if (data.success === "1") {
          $("#paginationType").show();
          $(".loader").hide();
          setstoreResult(data.result);
          settotalCount(data.totalCount);
          setactivePage(1);
        }
        if (data.success === "0") {
          $("#paginationType").hide();
          $(".loader").hide();
          setstoreResult([]);
          setshowMessage(data.errorMessage);
        }
      });
    }
  }
  const statusChangeHandler = (id, Status) => {
    var x = false;
    if (Status === true) {
      x = window.confirm("Do you want to Disable this?");
    } else {
      x = window.confirm("Do you want to Enable this?");
    }
    if (x === true) {
      const SatusData = {
        method: "POST",
        body: JSON.stringify({
          functionName: "storeListingStatusChange",
          idaStoreDetailsId: id,
          status: Status === true ? "false" : "true",
        }),
      };
      $(".loader").show();
      apiCalling(SatusData).then((data) => {
        if (data.success === "1") {
          $(".loader").hide();
          alert("Status Changed Successfully");
          getIdaStoreDetails();
        }
        if (data.success === "0") {
          $(".loader").hide();
          alert(data.errorMessage);
        }
      });
    }
  }
  const srcTypeImage = (ev) => {
    ev.target.src = Logoimg;
  }
  return (
    <div className="purpleWrap bg-clr-wthHead">
      <Suspense fallback={<div></div>}>
        <section>
          <Header /> <div className="loader"></div>
        </section>
      </Suspense>
      <div className="loader"></div>
      <div className="container mb-5">
        <div>
          <ul className="breadcrum-adminMangmnt">
            <li>
              <a href="/admin/dashboardAdmin">Dashboard</a>
            </li>
            <li>
              <a href="/adminDashboard/pharmaDashboard">Pharma Ad Approval</a>
            </li>
            <li>
              <a href="" className="current">
                IDA Stores List
              </a>
            </li>
          </ul>
        </div>
        <div className="head-hospMangmntList">IDA Stores List</div>

        <div className="HosptlMangemntTable">
          <div className="AddNewHspbtnDiv">
            <a
              href="/adminManagement/pharmadashboardList/addEditIdaStore"
              className="addnewhspbtn"
            >
              {" "}
              Add New Store
            </a>
          </div>
          {totalCount > 5 ? (
            <div className="pagination-qualList" id="paginationType">
              <Pagination
                activePage={activePage}
                itemsCountPerPage={10}
                totalItemsCount={totalCount}
                pageRangeDisplayed={10}
                onChange={handlePageChange}
              />
            </div>
          ) : (
            ""
          )}
          <div className="tableHeader-HspMangemnt">
            <div className="selectField-HspMangmnt">
              <select
                className="slecthsp slecthspMob"
                id="searchType"
                name="searchType"
                onChange={() => changeText()}
              >
                <option value="0">Search In</option>
                <option value="1">Store Code</option>
                <option value="2">Store Name</option>
                <option value="3">Contact Number</option>
              </select>
            </div>
            <div className="selectField-HspMangmnt">
              <input
                type="text"
                className="hspmngInput hspmngInputmob"
                name="resultTxt"
                id="resultTxt"
                disabled
              />
            </div>
            <div className="selectField-HspMangmnt">
              <select className="slecthsp slecthspMob" id="searchStatusType" name="searchStatusType">
                <option value="0">Status</option>
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </select>
            </div>
            <div className="selectField-HspMangmnt">
              <button
                className="searchbtn-hspMng"
                onClick={() => searchHandler()}
              >
                Search
              </button>
            </div>
          </div>
          {storeResult.length > 0 ? (
            <div className="table-responsive">
              <table className="table table-bordered-hsp">
                <thead>
                  <tr>
                    <th className="tablehead-hspmng">ID</th>
                    <th className="tablehead-hspmng">Store Code</th>
                    <th className="tablehead-hspmng">Store Name </th>
                    <th className="tablehead-hspmng">Address</th>
                    <th className="tablehead-hspmng">Contact Number</th>
                    <th className="tablehead-hspmng">Service Number</th>
                    <th className="tablehead-hspmng">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {storeResult.map((data) => (
                    <tr>
                      <td>{data.idaStoreDetailsId}</td>
                      <td>{data.storeCode}</td>
                      <td>{data.storeName}</td>
                      <td>{data.address}</td>
                      <td>{data.contactNo}</td>
                      <td>{data.seviceContactNumber}</td>
                      <td className="nowrap">
                        <div>
                          <span>
                            <Link
                              to={
                                "/adminManagement/pharmadashboardList/addEditIdaStore/" +
                                data.idaStoreDetailsId
                              }
                              className="mr-3"
                            >
                              <img
                                src={Edit}
                                alt=""
                                width="18"
                                className="img-fluid"
                              />
                            </Link>
                          </span>
                          <span>
                            <Link
                              to={
                                "/adminManagement/pharmadashboardList/generateQRcodeIda/" +
                                data.idaStoreDetailsId
                              }
                              className="mr-3"
                            >
                              <img
                                src={QrcodeImg}
                                alt=""
                                title="Click to Generate QRCode"
                                width="18"
                                className="img-fluid"
                              />
                            </Link>
                          </span>
                          {data.status === true ? (
                            <img
                              src={tick}
                              width="20"
                              title="Click to Deactivate "
                              className="conf-cursorpointer"
                              alt=""
                              onClick={() => {
                                statusChangeHandler(data.idaStoreDetailsId, true);
                              }}
                            ></img>
                          ) : (
                            <img
                              src={Deactivate}
                              alt=""
                              title="Click to Activate"
                              className="conf-cursorpointer"
                              width="18"
                              onClick={() => {
                                statusChangeHandler(data.idaStoreDetailsId, false);
                              }}
                            ></img>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {totalCount > 5 ? (
                <div className="pagination-qualList pagination-qualListwidth" id="paginationType">
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={10}
                    totalItemsCount={totalCount}
                    pageRangeDisplayed={10}
                    onChange={handlePageChange}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            <p className="error-msg text-center mb-0">
              {" "}
              {showMessage}{" "}
            </p>
          )}
        </div>
      </div>
      <Suspense fallback={<div></div>}>
        <section>
          <Footer />
        </section>
      </Suspense>
    </div>
  );
}
export default IdaStoresList;