import React, { useEffect } from 'react';
import Header from "../layout/header"
import Footer from "../layout/footer"
import CaseStudyBanner from './case-study-banner';
import AboutTakeNextStep from './about-take-next-step';
import CaseStudyContainer from './case-study-container';
import DigitalSuccess from "../home/digital-branding-success-stories"
function CaseStudy() {
    useEffect(() => {
      document.getElementById("success-menu").classList.add("active");
    }, [])
    return (
        <div className='ph-main-wrapper'>
        <Header/>
        <div className='ph-case-study'>
            <CaseStudyBanner/>
            <CaseStudyContainer/>
            <DigitalSuccess/>
           <AboutTakeNextStep/>
        </div>
        <Footer/>
        </div>     
    )
}
export default CaseStudy;