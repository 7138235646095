import React, { Component, Suspense } from "react";
import { apiCalling } from "../../apiService";
import $, { event } from "jquery";
import Close from "../../image/icons/close-mark.png";
import AWS from "aws-sdk";
var s3;

export default class ApplicationsEditSave extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appTypeData: this.props.appTypesData,
      siteDatas: this.props.siteDatas,
      editApplicationId: this.props.editApplicationId,
      appTypeId: 0,
      appName: "",
      bundleId: "",
      androidVersion: "",
      iosVersion: "",
      isAndroidUpdateReq: false,
      isIosUpdateReq: false,
      minReqAndroidVersion: "",
      minReqIosVersion: "",
      hospitalId: null,
      Status: false,
      appStoreUrl: "",
      playStoreUrl: "",
      siteId: 0,
      iosP12Certificate: "",
      iosP8Certificate: "",
      keyId: "",
      teamId: "",
      iosPassphrase: "",
      iosBundleId: "",
      s3bucketAccessKey: "",
      s3bucketSecretKey: "",
      s3BucketMainFolder: "",
      s3BucketAddressPath: "",
      s3BucketCertificateFolder: "",
      s3Bucket: "",
    };
  }

  componentDidMount() {
    this.getFileUploadDetails();
    if (this.state.editApplicationId !== "") {
      this.getApplicationDetails();
    }
  }
  getApplicationDetails = () => {
    $(".loader").show();
    var req = {
      method: "POST",
      body: JSON.stringify({
        functionName: "appDetailsLists",
        appDetailsId: this.state.editApplicationId,
        cmbSelect: "", // or “bundleId”
        txtInput: "", // or “com.patientapp.purple”
        max: "",
        Offset: "",
      }),
    };
    apiCalling(req).then((data) => {
      $(".loader").hide();
      this.setState({
        appTypeId: data.appDetailsData[0].appTypeId,
        appName: data.appDetailsData[0].appName,
        bundleId: data.appDetailsData[0].bundleId,
        androidVersion: data.appDetailsData[0].androidVersion,
        iosVersion: data.appDetailsData[0].iosVersion,
        isAndroidUpdateReq: data.appDetailsData[0].isAndroidUpdateReq,
        isIosUpdateReq: data.appDetailsData[0].isIosUpdateReq,
        minReqAndroidVersion: data.appDetailsData[0].minReqAndroidVersion,
        minReqIosVersion: data.appDetailsData[0].minReqIosVersion,
        hospitalId: data.appDetailsData[0].hospitalId,
        Status: data.appDetailsData[0].Status,
        appStoreUrl: data.appDetailsData[0].appStoreUrl,
        playStoreUrl: data.appDetailsData[0].playStoreUrl,
        siteId: data.appDetailsData[0].siteId,
        iosP12Certificate: data.appDetailsData[0].iosPushCertificate,
        iosP8Certificate: data.appDetailsData[0].iosP8Certificate,
        keyId: data.appDetailsData[0].keyId,
        teamId: data.appDetailsData[0].teamId,
        iosPassphrase: data.appDetailsData[0].iosPassphrase,
        iosBundleId: data.appDetailsData[0].iosBundleId,
      });
    });
  };
  cancelPopup() {
    this.props.showApplicationsEditPopup(false);
    $("body").css("overflow", "unset");
  }
  fileUploadHandler1 = (file) => {
    this.setState({ iosP12Certificate: file.name });
      if (file.type !== "application/pkcs8") {
        $(".loader").show();
        
        s3.upload(
          {
            Key: file.name,
            Body: file,
            ACL: "public-read",
          },
          (err, data) => {
            if (err) alert("Something went wrong in Upload");
            else {
              $(".loader").hide();
              alert("Uploaded Successfully");
            }
          }
        );
      }

    // application/x-pkcs12
    // this.setState({ iosP12Certificate: file.name });
  };
  fileUploadHandlers = (file) => {
   
    if (file.type !== "application/x-pkcs12") {
      $(".loader").show();
      this.setState({ iosP8Certificate: file.name });
      s3.upload(
        {
          Key: file.name,
          Body: file,
          ACL: "public-read",
        },
        (err, data) => {
          if (err) alert("Something went wrong in Upload");
          else {
            $(".loader").hide();
            alert("Uploaded Successfully");
          }
        }
      );
    } 
 };
  getFileUploadDetails = () => {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getDropdownLists",
      }),
    };

    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        this.setState(
          {
            s3bucketAccessKey: data.result[0].s3bucketAccessKey,
            s3bucketSecretKey: data.result[0].s3bucketSecretKey,
            s3BucketMainFolder: data.result[0].S3BucketMainFolder,
            s3BucketAddressPath: data.result[0].s3BucketAddressPath,
            s3BucketCertificateFolder: "APNFile",
            s3Bucket: data.result[0].s3Bucket,
          },
          () => {
            AWS.config.update({
              accessKeyId: data.result[0].s3bucketAccessKey,
              secretAccessKey: data.result[0].s3bucketSecretKey,
              region: "us-east-1",
            });
            var bucketParams = {
              Bucket: data.result[0].s3Bucket,
            };
            s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
          }
        );
      }
    });
  };
  saveApplicationDetails = () => {
    if (this.state.appTypeId === 0) {
      alert("App Type is required");
      return false;
    } else if (this.state.appName === "") {
      alert("App Name required");
      return false;
    } else if (this.state.bundleId === "") {
      alert("Bundle ID is required");
      return false;
    } else if (this.state.siteId === 0) {
      alert("Site is required is required");
      return false;
    }
    $(".loader").show();
    var req = {
      method: "POST",
      body: JSON.stringify({
        functionName: "saveAppDetails",
        appDetailsId: this.state.editApplicationId,
        appName: this.state.appName,
        appTypeId: this.state.appTypeId,
        siteId: this.state.siteId,
        hospitalId: "",
        bundleId: this.state.bundleId,
        androidVersion: this.state.androidVersion,
        iosVersion: this.state.iosVersion,
        isAndroidUpdateReq: false,
        isIosUpdateReq: false,
        minReqAndroidVersion: this.state.minReqAndroidVersion,
        minReqIosVersion: this.state.minReqIosVersion,
        appStoreUrl: this.state.appStoreUrl,
        playStoreUrl: this.state.playStoreUrl,
        iosPushCertificate: this.state.iosP12Certificate,
        iosP8Certificate: this.state.iosP8Certificate,
        keyId: this.state.keyId,
        teamId: this.state.teamId,
        iosPassphrase: this.state.iosPassphrase,
        iosBundleId: this.state.iosBundleId,
      }),
    };
    apiCalling(req).then((data) => {
      if (data.success === "1") {
        $(".loader").hide();
        alert("Application added successfully");
        window.location.reload();
      } else {
        alert(data.errorMessage);
        return false;
      }
    });
  };
  render() {
    return (
      <div
        className="popup-overlay custom_popupstyle"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header modal-popup">
              <span
                className="closepopupqual"
                type="button"
                onClick={() => {
                  this.cancelPopup();
                  $("body").css("overflow", "unset");
                }}
              >
                <img src={Close} alt="Close" className="img-fluid" width="25" />
                {/* &times; */}
              </span>
              <div className="header border-0">Application Details</div>
            </div>
            <div className="modal-body">
              <div className="row">
                <div
                  key={2}
                  className="row col-md-12"
                  style={{ margin: "10px" }}
                >
                  <div className="row col-md-12">
                    <div className="row row-margin col-md-12">
                      <div className="popupdiv">
                        <label className="qualifcatin-popuplabel popuplabel-widthhspmob">
                          App Type <span className="hspifoMantory">*</span>
                        </label>
                        <select
                          className="qualifcatin-popupInput"
                          value={this.state.appTypeId}
                          disabled={
                            this.state.editApplicationId !== "" ? true : false
                          }
                          onChange={(e) =>
                            this.setState({ appTypeId: e.target.value })
                          }
                        >
                          <option value={0}>Select</option>
                          {this.state.appTypeData.map((data) => (
                            <option value={data.appTypeId}>
                              {data.appTypeName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="row row-margin col-md-12">
                      <div className="popupdiv">
                        <label className="qualifcatin-popuplabel popuplabel-widthhspmob">
                          App Name <span className="hspifoMantory">*</span>
                        </label>
                        <input
                          type="text"
                          className="qualifcatin-popupInput"
                          id="commision"
                          name="commision"
                          onChange={(e) =>
                            this.setState({ appName: e.target.value })
                          }
                          value={this.state.appName}
                        />
                      </div>
                    </div>
                    <div className="row row-margin col-md-12">
                      <div className="popupdiv">
                        <label className="qualifcatin-popuplabel popuplabel-widthhspmob">
                          Bundle ID <span className="hspifoMantory">*</span>
                        </label>
                        {/* </div> */}
                        {/* <div className="col-md-7"> */}
                        <input
                          type="text"
                          className="qualifcatin-popupInput"
                          id="commision"
                          name="commision"
                          value={this.state.bundleId}
                          onChange={(e) =>
                            this.setState({ bundleId: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="row row-margin col-md-12">
                      <div className="popupdiv">
                        <label className="qualifcatin-popuplabel popuplabel-widthhspmob">
                          Site Id <span className="hspifoMantory">*</span>
                        </label>
                        {/* </div> */}
                        {/* <div className="col-md-7"> */}
                        {/* <input
                          type="text"
                          className="qualifcatin-popupInput"
                          id="commision"
                          name="commision"
                          value={3}
                          //   onInput={(e) => {
                          //     this.changeHandler(e, h);
                          //   }}
                         
                          //   onKeyPress={(e) => {
                          //     this.isDecimalKey(e);
                          //   }}
                        /> */}
                        <select
                          value={this.state.siteId}
                          onChange={(e) =>
                            this.setState({ siteId: e.target.value })
                          }
                          disabled={
                            this.state.editApplicationId !== "" ? true : false
                          }
                          className="qualifcatin-popupInput"
                        >
                          <option value="0">Select</option>
                          {this.state.siteDatas.map((data) => (
                            <option value={data.siteId}>{data.siteName}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="row row-margin col-md-12">
                      <div className="popupdiv">
                        <label className="qualifcatin-popuplabel popuplabel-widthhspmob">
                          Android Version:{" "}
                        </label>
                        {/* </div> */}
                        {/* <div className="col-md-7"> */}
                        <input
                          type="text"
                          className="qualifcatin-popupInput"
                          id="commision"
                          name="commision"
                          value={this.state.androidVersion}
                          onChange={(e) =>
                            this.setState({ androidVersion: e.target.value })
                          }
                        />
                      </div>
                    </div>{" "}
                    <div className="row row-margin col-md-12">
                      <div className="popupdiv">
                        <label className="qualifcatin-popuplabel popuplabel-widthhspmob">
                          iOS Version
                        </label>
                        {/* </div> */}
                        {/* <div className="col-md-7"> */}
                        <input
                          type="text"
                          className="qualifcatin-popupInput"
                          id="commision"
                          name="commision"
                          value={this.state.iosVersion}
                          onChange={(e) =>
                            this.setState({ iosVersion: e.target.value })
                          }
                        />
                      </div>
                    </div>{" "}
                    <div className="row row-margin col-md-12">
                      <div className="popupdiv">
                        <label className="qualifcatin-popuplabel popuplabel-widthhspmob">
                          PlayStore URL:{" "}
                        </label>
                        <input
                          type="text"
                          className="qualifcatin-popupInput"
                          id="commision"
                          name="commision"
                          value={this.state.playStoreUrl}
                          onChange={(e) =>
                            this.setState({ playStoreUrl: e.target.value })
                          }
                        />
                      </div>
                    </div>{" "}
                    <div className="row row-margin col-md-12">
                      <div className="popupdiv">
                        <label className="qualifcatin-popuplabel popuplabel-widthhspmob">
                          App Store URL:{" "}
                        </label>
                        <input
                          type="text"
                          className="qualifcatin-popupInput"
                          id="commision"
                          name="commision"
                          value={this.state.appStoreUrl}
                          onChange={(e) =>
                            this.setState({ appStoreUrl: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    {/* <div className="row row-margin col-md-12">
                      <div className="popupdiv">
                        <label className="qualifcatin-popuplabel popuplabel-widthhspmob">
                          is Mandatory Update{" "}
                        </label>
                        <label className="label-text">
                          <input
                            type="checkbox"
                            name="status"
                            id="status"
                            checked={this.state.isAndroidUpdateReq}
                            onChange={(e) =>
                              this.setState({
                                isAndroidUpdateReq: e.target.checked,
                              })
                            }
                          />{" "}
                          Android
                        </label>
                        &nbsp;
                        <label className="label-text">
                          <input
                            type="checkbox"
                            name="status"
                            id="status"
                            checked={this.state.isIosUpdateReq}
                            onChange={(e) =>
                              this.setState({
                                isIosUpdateReq: e.target.checked,
                              })
                            }
                          />{" "}
                          iOS
                        </label>
                      </div>
                    </div>{" "} */}
                    <div className="row row-margin col-md-12">
                      <div className="popupdiv">
                        <label className="qualifcatin-popuplabel popuplabel-widthhspmob">
                          Min. Android Version{" "}
                        </label>
                        {/* </div> */}
                        {/* <div className="col-md-7"> */}
                        <input
                          type="text"
                          className="qualifcatin-popupInput"
                          id="commision"
                          name="commision"
                          value={this.state.minReqAndroidVersion}
                          onChange={(e) =>
                            this.setState({
                              minReqAndroidVersion: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>{" "}
                    {/* <div className="row row-margin col-md-12">
                      <div className="popupdiv">
                        <label className="qualifcatin-popuplabel popuplabel-widthhspmob">
                          Min iOS Version{" "}
                        </label>
                        <input
                          type="text"
                          className="qualifcatin-popupInput"
                          id="commision"
                          name="commision"
                          value={this.state.minReqIosVersion}
                          onChange={(e) =>
                            this.setState({
                              minReqIosVersion: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div> */}
                    <div className="row row-margin col-md-12">
                      <div className="popupdiv">
                        <label className="qualifcatin-popuplabel popuplabel-widthhspmob">
                          iOS VoIP Push Certificate{" "}
                        </label>
                        <input
                          type="file"
                          className="removeValue"
                          style={{ color: "transparent" }}
                          id="commision"
                          name="commision"
                          title={this.state.iosP12Certificate}
                          onChange={(e) => {
                            this.fileUploadHandler1(e.target.files[0]);
                            e.target.value = "";
                          }}
                        />
                        {this.state.iosP12Certificate !== "" && (
                          <span>{this.state.iosP12Certificate}</span>
                        )}
                      </div>
                    </div>
                    <div className="row row-margin col-md-12">
                      <div className="popupdiv">
                        <label className="qualifcatin-popuplabel popuplabel-widthhspmob">
                          iOS P8 Certificate{" "}
                        </label>
                        <input
                          type="file"
                          className="removeValue"
                          style={{ color: "transparent" }}
                          id="commision"
                          name="commision"
                          title={this.state.iosP8Certificate}
                          onChange={(e) => {
                            this.fileUploadHandlers(e.target.files[0]);
                            e.target.value = "";
                          }}
                        />
                        {this.state.iosP8Certificate !== "" && (
                          <span>{this.state.iosP8Certificate}</span>
                        )}
                      </div>
                    </div>
                    <div className="row row-margin col-md-12">
                      <div className="popupdiv">
                        <label className="qualifcatin-popuplabel popuplabel-widthhspmob">
                          Key ID
                        </label>
                        <input
                          type="text"
                          className="qualifcatin-popupInput"
                          id="commision"
                          name="commision"
                          value={this.state.keyId}
                          onChange={(e) =>
                            this.setState({
                              keyId: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>{" "}
                    <div className="row row-margin col-md-12">
                      <div className="popupdiv">
                        <label className="qualifcatin-popuplabel popuplabel-widthhspmob">
                          Team ID
                        </label>
                        <input
                          type="text"
                          className="qualifcatin-popupInput"
                          id="commision"
                          name="commision"
                          value={this.state.teamId}
                          onChange={(e) =>
                            this.setState({
                              teamId: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>{" "}
                    <div className="row row-margin col-md-12">
                      <div className="popupdiv">
                        <label className="qualifcatin-popuplabel popuplabel-widthhspmob">
                          iOS Passphrase:{" "}
                        </label>
                        <input
                          type="text"
                          className="qualifcatin-popupInput"
                          id="commision"
                          name="commision"
                          value={this.state.iosPassphrase}
                          onChange={(e) =>
                            this.setState({
                              iosPassphrase: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>{" "}
                    <div className="row row-margin col-md-12">
                      <div className="popupdiv">
                        <label className="qualifcatin-popuplabel popuplabel-widthhspmob">
                          iOS Bundle ID
                        </label>
                        <input
                          type="text"
                          className="qualifcatin-popupInput"
                          id="commision"
                          name="commision"
                          value={this.state.iosBundleId}
                          onChange={(e) =>
                            this.setState({
                              iosBundleId: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <span
                  className="sitesetng-svebtn"
                  onClick={() => this.saveApplicationDetails()}
                >
                  Save
                </span>
                <input
                  type="button"
                  value="Cancel"
                  className="sitesetng-cancelbtn"
                  onClick={() => this.cancelPopup()}
                ></input>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
