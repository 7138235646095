import React, { Component } from 'react';

export default class ProfessionalDetail extends Component {
 
  render() {
    return ( 
        <div className="row px-2">
          <div className="col-sm-6">
            <div className="row my-2">
              <label className="col-sm-12 label-text">Practice Start Year <span className="star-red">*</span></label>
              <div className="col-sm-12  px-0  px-sm-3">
                <select className="input-design">
                  <option>Year </option>
                </select>                                  
              </div>
            </div>
          </div>
          <div className="col-sm-12  px-0  px-sm-3">
            <div className="row my-2">
              <label className="col-sm-12 label-text">Professional Statement</label>
              <div className="col-sm-12  px-0  px-sm-3">
                <textarea className="input-design textarea" style={{fontSize: "14px"}}></textarea>                                 
              </div>
            </div>
          </div>
          
          <div className="col-sm-12">
                <input type="submit" value="Save" className="formButton"></input>
            </div>
        </div>
    )
  }
}
