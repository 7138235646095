import React, { Component } from 'react';
import Header from "../layout/header"
import Footer from "../layout/footer"
import $ from "jquery";

export default class Messgedetail extends Component {
  componentDidMount(){
    $("#file1,#remfile1,#file2,#remfile2,#file3,#remfile3").hide();
    $("#attach").click(function(){
	
      if($("#file1").is(':hidden'))
      {
      $("#file1").show();
      $("#file1").click();
      $("#remfile1").show();
      }
      else if($("#file2").is(':hidden'))
      {
      $("#file2").show();
      $("#file2").click();
      $("#remfile2").show();
      }
      else if($("#file3").is(':hidden'))
      {
      $("#file3").show();
      $("#file3").click();
      $("#remfile3").show();
      }
      
    });
    $("#remfile1").click(function(){	 	
  		$("#file1").val("");
  		$("#file1").hide();
  		$("#remfile1").hide();
  	});
	$("#remfile2").click(function(){
  		$("#file2").val("");
  		$("#file2").hide();
  		$("#remfile2").hide();
  	});
	$("#remfile3").click(function(){
  		$("#file3").val("");
  		$("#file3").hide();
  		$("#remfile3").hide();
  	});
  }

  render() {
    return (
      <div className="purpleWrap">
        <Header></Header>
        <div className="container container-auto">
          <div className="main-wrapper1">
          
           <div className="col-md-12">
             <div className="memberHead">
               <h4>Message Center</h4>
             </div>
             <div className="messageCenterDiv">
                
                <div className="col-sm-12 mb-3">
                <a href="/patient/inbox"><input type="button" value="Inbox" className="formButton"></input></a>
                <a href="/patient/send"><input type="button" value="Sent" className="formButton"></input></a>
                </div>
                <div className="form-section-bgcolor py-4">
                  <div className="col-sm-12">
                    <textarea className="input-design border-0" placeholder="" rows="6"></textarea>
                  </div>

                  <div className="col-sm-12 mt-2">
                    <a href="#" id="attach" title="Add" className="addAttachment">Add Attachment</a>
                    <p className="note">(Max. 3 files. format: JPEG, PDF, GIF,JPG,PNG,File Size: 10MB maximum.)</p>
                  </div>
                  
                  <div className="fileAttachment col-sm-12">
                    <div className="fileAttachmentList">
                    <input type="file" id="file1" className="attchedFile" />
                    <input type="button" id="remfile1" className="attchedDelete"/>
                    </div>
                    <div className="fileAttachmentList">
                    <input type="file" id="file2" className="attchedFile" />
                    <input type="button" id="remfile2" className="attchedDelete"/>
                    </div>
                    <div className="fileAttachmentList">
                    <input type="file" id="file3" className="attchedFile" />
                    <input type="button" id="remfile3" className="attchedDelete"/>
                    </div>
                  </div>

                  <div className="col-sm-12">
                      <input type="submit" value="Send Message" className="formButton mt-4 mx-0"></input>
                  </div>

                  <div className="mailArea col-sm-12">
                    <table>
                      <thead>
                        <tr>
                          <th width="50%">Subject</th>
                          <th width="50%">Ask The Doctor - TEST PATIENT</th>
                        </tr>
                      </thead>
                      <tbody>
                          <tr>
                            <td>
                              <div className="info infoDivSec">
                                  <span className="name">Me</span>
                                  <span className="date" style={{fontSize:"12px"}}>Tue May 19 2020 4:24 PM</span>
                              </div>
                              <div className="attachment attachmentDivSec">
                                <h5>File attachments</h5>
                                <a href="#" target="_blank" className="color-maintext" style={{fontSize:"12px"}}>1589885652778_bg.png</a>
                                </div>
                            </td>
                            <td>
                              <p>
                                wew
                              </p>
                            </td>
                          </tr>                  
                      </tbody>
                    </table>
                  </div>
                  
                  <div className="col-sm-12 text-center">
                    <input type="button" value="Close" className="formButton"></input>
                  </div>
                </div>
             </div>
           </div>


          </div>
        </div>

        <Footer></Footer>
      </div>
    )
  }
}
