import React from 'react';
import bannerImg from '../image/theme/Case-Study-1.png'
function CaseStudyBanner() {
    return (
        <div className='ph-case-study-banner'>
            <div className='container-lg'>
            <div className='ph-case-study-inner-banner'>
                <div className='row align-items-center'>
                    <div className='col-md-4 mb-3 mb-md-0'>
                        <img src={bannerImg} className="img-fluid" alt='banner' width="auto" height="auto"/>
                    </div>
                    <div className='col-md-8'>
                    <div className="ph-db-banner">
                            <span className="ph-banner-title">Case Studies</span>
                            <h1 className="ph-case-study-title-1 mt-4 mt-md-5">Thriving Growth, 100% Annual Usage Surge, Enhanced Customer Loyalty! </h1>
                            {/* <div className='ph-case-study-btn-div'>
                            <button className='ph-case-study-btn'><span className="ph-dot"></span>Solutions Act</button>
                            <button className='ph-case-study-btn'><span className="ph-dot"></span>Customer Loyalty</button>
                        </div> */}
                        </div>                        
                    </div>
                </div>
            </div>
            </div>
        </div> 
    )
}
export default CaseStudyBanner;