import React, { Component, Suspense,useState,useEffect } from "react";
import Edit from "../../image/icons/edit.png";
import tick from "../../image/icons/tick.png";
import Deactivate from "../../image/icons/deactvate.png";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import Header from "../../layout/header"
import Footer from "../../layout/footer"
import Logoimg from "../../image/icons/image-preview.png";
import $ from 'jquery'
import { apiCalling } from "../../apiService";

const PharmaCompayListing =()=>{
    const[totalCount,settotalCount]=useState(0);
    const[activePage,setactivePage]=useState(1);
    const[companyListingData,setcompanyListingData]=useState([]);
    const[showMessage,setshowMessage]=useState("Waiting for data... ");
    const[isLoading,setisLoading]=useState('');
    const[adApprovalData,setadApprovalData]=useState('');
   const handlePageChange=(pageNumber) =>{
        setactivePage(pageNumber);
        var offset;
        var max;
        if (pageNumber === "1") {
            offset = "0";
            max = "10";
            setactivePage(pageNumber);
            setisLoading('0');
        }
        else {
            offset = parseInt(pageNumber - 1) * 10;
            max = "10";
            setactivePage(pageNumber);
            setisLoading('0');
        }
        const apiJson = {
            method: "POST",
            body: JSON.stringify({
                functionName: "pharmaCompanyListing",
                cmbSelect: "",
                txtInput: "",
                status:"",
                Offset: JSON.stringify(offset),
                max: max
            }),
        };
        $(".loader").show();
        apiCalling(apiJson).then((data) => {
            if (data.success === "1") {
                setadApprovalData(data.AdApprovalSearchData);
                settotalCount(data.totalCount);
                $(".loader").hide();
            }
            if (data.success === "0") {
                $(".loader").hide();
                setadApprovalData([]);
                setshowMessage(data.errorMessage);
            }
        });
    }
   
    useEffect(() => {
        pharmaCompanyList();
      }, []);

   const pharmaCompanyList=()=>{
        $(".loader").show();
        const pharmaList = {
            method:"POST",
            body: JSON.stringify({
                functionName: "pharmaCompanyListing",
                cmbSelect:"",
                status:"",
                txtInput: "",
                max: "10",
                Offset: "0"
            }),
        }
        apiCalling(pharmaList).then((data)=>{
            if (data.success === "1") {
                $(".loader").hide();
                setcompanyListingData(data.companyListingData);
                settotalCount(data.totalCount);
            }
            if (data.success === "0") {
                $(".loader").hide();
                setcompanyListingData([]);
                setshowMessage(data.errorMessage);
            }

        })
    }
    const changeText = () => {
        if ($("#searchType").val() !== '0') {
            document.getElementById("resultTxt").disabled = false
            $('input[name=resultTxt]').focus();
        }
        else {
            document.getElementById("resultTxt").disabled = true
        }
        document.getElementById("resultTxt").value = ""
    }
 const  searchHandler=()=>{
        if ($("#searchType").val() !== "0" && $("#resultTxt").val() === "") {
            var t = document.getElementById("searchType");
            var selectedText = t.options[t.selectedIndex].text;
            alert(selectedText + " Required");
            $("#resultTxt").focus();
            return false;
        } else {
            const searchData = {
                method: "POST",
                body: JSON.stringify({
                    functionName: "pharmaCompanyListing",
                    cmbSelect: $("#searchType").val() === '0' ? '' : $("#searchType").val(),
                    status: $("#searchStatusType").val() === '0' ? '' : $("#searchStatusType").val(),
                    txtInput: $("#resultTxt").val(),
                    max: "10",
                    Offset: "0"
                }),
            };
            $(".loader").show();
            apiCalling(searchData).then((data) => {
                if (data.success === "1") {
                    $("#paginationType").show();
                    $(".loader").hide();
                    setcompanyListingData(data.companyListingData);
                    settotalCount(data.totalCount);
                    setactivePage(1);
                }
                if (data.success === "0") {
                    $("#paginationType").hide();
                    $(".loader").hide();
                    setcompanyListingData([]);
                    setshowMessage("No Data Found....");
                }
            })
        }
    } 
 const   statusChangeHandler=(e,pid)=>{
        if(e.target.name === "Approved"){
            const approveData = {
                method: "POST",
                body: JSON.stringify({
                    functionName: "approvePharmaCompany",
                    isApproved: false,
                    companyId: pid,
                    
                })
            }
            $(".loader").show();
            apiCalling(approveData).then((data) => {
                if (data.success === "1") {
                    $(".loader").hide();
                    pharmaCompanyList();
                }
                if (data.success === "0") {
                    $(".loader").hide();
                    alert(data.errorMessage)
                }
            })
        }
        else if(e.target.name === "Rejected"){
            const rejectData = {
                method: "POST",
                body: JSON.stringify({
                    functionName: "approvePharmaCompany",
                    isApproved: true,
                    companyId: pid
                })
            }
            $(".loader").show();
            apiCalling(rejectData).then((data) => {
                if (data.success === "1") {
                    $(".loader").hide();
                    alert("Status Changed Successfully")
                    pharmaCompanyList();
                }
                if (data.success === "0") {
                    $(".loader").hide();
                    alert(data.errorMessage)
                }
            })
        }
    }
        return (
            <div className="purpleWrap bg-clr-wthHead">
                <Suspense fallback={<div></div>}>
                    <section>
                        <Header />{" "}
                        <div className='loader'></div>
                    </section>
                </Suspense>
                <div className='loader'></div>
                <div className="container mb-5">
                    <div>
                        <ul className="breadcrum-adminMangmnt">
                            <li><a href="/admin/dashboardAdmin">Dashboard</a></li>
                            <li><a href="/adminDashboard/pharmaDashboard">Pharma Ad Approval</a></li>
                            <li><a href="" className="current">Pharma Company List</a></li>
                        </ul>
                    </div>
                    <div className="head-hospMangmntList">Company List</div>
                    
                    <div className="HosptlMangemntTable">
                        <div className="AddNewHspbtnDiv"><a href="/adminManagement/pharmadashboardList/pharmaAddnewCompany" className="addnewhspbtn"> Add New Company</a></div>
                        <div className="row py-2 mx-0 mb-2 bg_row">
                            <div className="col-md-2">
                                <select className="slecthsp slecthspMob" id="searchType" name="searchType" onChange={()=>changeText()}>
                                    <option value="0">Search In</option>
                                    <option value="1">ID</option>
                                    <option value="2">Company Name</option>
                                </select>
                            </div>
                            <div className="col-md-2">
                                <input type="text" className="hspmngInput hspmngInputmob" name="resultTxt" id="resultTxt" disabled />
                            </div>
                            <div className="col-md-2">
                                <select className="slecthsp slecthspMob" id="searchStatusType" name="searchStatusType">
                                    <option value="0">Status</option>
                                    <option value="true">Active</option>
                                    <option value="false">Inactive</option>
                                </select>
                            </div>
                            <div className="col-md-2">
                                <button className="searchbtn-hspMng" onClick={()=>searchHandler()}>Search</button>
                            </div>
                        </div>
                        {companyListingData.length > 0 ? 
                        <div className="table-responsive">
                            <table className="table table-bordered-hsp">
                                <thead>
                                    <tr>
                                        <th className="">ID</th>
                                        <th className="">Company Name</th>
                                        <th className="">Has Network</th>
                                        <th className="">Has Exclusivity</th>
                                        <th className="">Actions</th>
                                    </tr>
                                </thead>
                                {companyListingData.map((data) => (
                                    <tbody>
                                        <tr>
                                            <td>{data.companyId}</td>
                                            <td>{data.companyName}</td>
                                            <td>{data.hasNetwork === false?<span>No</span>:<span>Yes</span>}</td>
                                            <td>{data.hasExclusivity === false?<span>No</span>:<span>Yes</span>}</td>
                                            <td>
                                                <div className="action_tdtr">
                                                    <Link to={"/adminManagement/pharmadashboardList/pharmaEditnewCompany/"+data.companyId}  className="mr-2">
                                                        <img src={Edit} alt="edit"/>
                                                    </Link>
                                                    {data.isApproved === true ? 
                                                        <img src={tick} alt="activate" name="Approved" title="Click here to Deactivate" onClick={(e)=>statusChangeHandler(e,data.companyId)}/> : 
                                                        <img src={Deactivate} alt="deactivate" name="Rejected" title="Click here to Activate" onClick={(e)=>statusChangeHandler(e,data.companyId)}/>
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                ))}
                            </table>
                            <div className="pagination-qualList pagination-qualListwidth">
                                <Pagination
                                activePage={activePage}
                                itemsCountPerPage={10}
                                totalItemsCount={totalCount}
                                pageRangeDisplayed={5}
                                onChange={handlePageChange}
                                />
                            </div>
                        </div>
                        :<p className="error-msg text-center mb-0"> {showMessage} </p>}
                    </div>
                       
                </div>
                <Suspense fallback={<div></div>}>
                    <section>
                        <Footer />
                    </section>
                </Suspense>
            </div>
        );
    }

export default PharmaCompayListing;