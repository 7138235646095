import React, { Component } from 'react'
import bannerImg from "../image/theme/banner.png"
import DArrowImg from "../image/theme/down-arrow.png"
import DocCountImg from "../image/theme/doctor-count.png"

export default class HomeBanner extends Component {
    componentDidMount() {
        document.getElementById("know-more").addEventListener("click", function () {
            const start = window.pageYOffset;
            const end = document.body.scrollHeight;
            const duration = 1000; // duration in milliseconds
            const startTime = performance.now();

            function scrollAnimation(currentTime) {
                const elapsedTime = currentTime - startTime;
                const scrollProgress = Math.min(elapsedTime / duration, 1); // Ensure scroll progress doesn't exceed 1
                const ease = scrollProgress ** 2; // Using a quadratic easing function for smoother scrolling
                window.scrollTo(0, start + (end - start) * ease);

                if (scrollProgress < 1) {
                    requestAnimationFrame(scrollAnimation);
                }
            }

            requestAnimationFrame(scrollAnimation);
        });
    }
    render() {

        return (
            <div className='ph-banner-main container'>
                <div className='ph-banner-top-shade'></div>
                <div className='ph-banner-bottom-shade'></div>
                <div className="container-lg mr-auto">
                    <div className="row">
                        <div className="col-md-10 col-lg-8 col-xl-6">
                            <h1 className="ph-banner-heading">Grow Your Independent Practice with PurpleHealth.</h1>
                            <p className="lead mt-3 mb-4">
                                PurpleHealth offers a unique, highly effective, and comprehensive solution tailored for doctors and their clinics in today's fast-moving online world.
                            </p>
                            <button className='ph-btn-theme mb-2' type='button' id="know-more">Know More <img className="img-fluid ml-4" src={DArrowImg} alt="Know More" title="Know More" width={20} height={10} /></button>
                            <div className="col-md-11 mt-4 pl-0">
                                <img className="ph-doc-count-img img-fluid" src={DocCountImg} alt="Doctor Count image" title="Doctor Count image" width={400} height={200} />
                            </div>
                        </div>
                        {/* <div className="col-md-6 ph-banner-image">
                    <img className="ph-banner-img img-fluid mx-auto" src={bannerImg} alt="Banner image" title="Banner image" width={900} height={600} />
                </div> */}
                    </div>
                </div>
            </div>
        )
    }
}
