import React from 'react';
function CaseStudyContainer() {
    return (
        <div className='ph-case-study-container'>
            <div className='container-lg ph-lg-space'>
                <div className='ph-case-study-inner-container'>
                <h2 className="ph-main-title-2 mb-3">The Challenge  </h2>
                <p className="lead-sm mb-0 text-dark">Reliance General Insurance (Reliance)one of the largest Indian health insurance companies needed a telemedicine platform to connect their corporate health insurance customers to empanelled doctors in their network. </p>
                </div>
                <div className='ph-case-study-inner-container'>
                <h2 className="ph-main-title-2 mb-3">Solution</h2>
                <p className="lead-sm text-dark"> PurpleHealth built a platform called RHealth Assist that connected insurance policy holders to a group of doctors across the country. These doctors had different specializations and different rates that they could set and from which patients would book appointments. PurpleHealth built a system that connected with Reliance servers to verify policy holders to consult with doctors as well as the ability to pay them directly once the consultations were completed</p>
               <p className="lead-sm text-dark">The telehealth platform included mobile apps for iOS and Android as well as being accessible by a web portal. An administration application was built in the back-end to manage the doctors on the platform as well as the creation of a robust reporting and analytics system for Reliance. </p>
               <p className="lead-sm text-dark mb-0">On top of the technology and software, PurpleHealth also provides live customer support as well as software support, maintenance and upgrades.</p>
                </div>
                <div className='ph-case-study-inner-container'>
                <h2 className="ph-main-title-2 mb-3">Outcome</h2>
                <p className="lead-sm text-dark mb-0">The RHealth Assist platform has been established for multiple years and is continuing to grow with a compounded 100% increase in usage year over year which is beneficial to creating loyalty among RGI customers. PurpleHealth continues to add features and improve the platform for Reliance General Insurance customers. </p>
                </div>
            </div>
        </div> 
    )
}
export default CaseStudyContainer;