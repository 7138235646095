import React, { Component } from "react";
import doctor from "../image/media/doctor.jpg";
import profile from "../image/media/profile.png";
import purple from "../image/media/purple.png";
import video from "../image/media/video.png";
import chat from "../image/media/chat.png";
import inbox from "../image/media/inbox.png";
import article from "../image/media/article.png";
import password from "../image/media/password.png";
import time from "../image/media/time.png";
import service from "../image/media/service.png";
import { apiCalling } from "../apiService";
export default class DashboardLeftmenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drFName: "",
      drLName: "",
      drSpec: "",
      drImg: "",
    };
  }
  componentDidMount() {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "doctorProfile",
        siteId: localStorage.getItem("siteId"),
        doctorId: localStorage.getItem("userId"),
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success == "1") {
        this.setState({
          drName: data.doctorData[0].firstName,
          drLName: data.doctorData[0].lastName,
          drSpec: data.doctorData[0].primarySpeciality,
          drImg: data.doctorData[0].image,
        });
      }
    });
  }
  render() {
    return (
      <div className="">
        <div className="col-md-12 bg-grey text-center p-4">
          <img src={this.state.drImg} width="120"></img>
          <div className="healthpageDocName">
            {this.state.drFName}
            {this.state.drLName}
          </div>
          <div className="healthpageDocSpl">{this.state.drSpec}</div>
        </div>
        <div className="col-md-12 bg-grey mt-3">
          <div className="QckLinksNew">
            <div className="QuickHead">Quick Links</div>
            <div className="LinksList">
              <ul className="dashBoardMenu quicklinks">
                <li id="profile" className="book hvr-float-shadow greyHeaderClr">
                  <a href="/doctor/doctorprofile" className="practiceImg">
                    <img src={profile} />
                    <span className="videoSpan">Profile</span>
                  </a>
                </li>

                <li className="book hvr-float-shadow greyHeaderClr">
                  <a href="#" className="practiceImg">
                    <img src={purple} />
                    <span className="videoSpan">Practice Management</span>
                  </a>
                </li>

                <li id="textChat" className="video hvr-float-shadow greyHeaderClr">
                  <a href="/chatmessagegroup">
                    <img src={chat} />
                    <span className="videoSpan">Text Chat</span>
                  </a>
                </li>

                <li id="videoChat" className="video hvr-float-shadow greyHeaderClr">
                  <a href="/doctor/videoSchedule">
                    <img src={video} />
                    <span className="videoSpan">Video/Audio Chat</span>
                  </a>
                </li>

                <li id="mailBox" className="ask hvr-float-shadow greyHeaderClr">
                  <a href="/doctor/doctorinbox">
                    <img src={inbox} />
                    <span className="videoSpan">Inbox</span>
                  </a>
                </li>

                <li
                  id="healthArticle"
                  className="ask hvr-float-shadow greyHeaderClr"
                >
                  <a href="/doctor/healtharticle">
                    <img src={article} />
                    <span className="videoSpan">Write health Article</span>
                  </a>
                </li>

                <li id="changePassword">
                  <a href="/doctor/changepassword" className="practiceImg">
                    <img src={password} />
                    <span>Change Password</span>
                  </a>
                </li>

                <li id="workingTime">
                  <a href="/doctor/worktime" className="practiceImg">
                    <img src={time} />
                    <span>Working Time</span>
                  </a>
                </li>

                <li id="services">
                  <a href="/doctor/docservices" className="practiceImg">
                    <img src={service} />
                    <span>Services</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
