import React, { Component } from 'react';
import Header from "../layout/header";
import Footer from "../layout/footer";
import $ from "jquery";
import { apiCalling } from "../apiService";
import avatar from "../image/media/avatar.png";

export default class Chatmessage extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
    if (this.props.location.doctorId) {
      localStorage.setItem("appDocId", this.props.location.doctorId);
      localStorage.setItem("appHosId", this.props.location.hospitalId);
      localStorage.setItem("chatDoctorPanelId", this.props.location.chatDoctorPanelId);
    }
    this.sendAsMail = this.sendAsMail.bind(this);
    this.endChat = this.endChat.bind(this);
  }
  componentDidMount() {
    $("#promoApplySection").hide();
    $("#chat-upload").hide();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "messageChatFree",
        chatDoctorPanelId: localStorage.getItem("chatDoctorPanelId"),
        memberId: localStorage.getItem("userId"),
        doctorId: localStorage.getItem("appDocId"),
        hospitalId: localStorage.getItem("appHosId")
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success == "1") {
      }
    });
  }
  upload() {
    $("#chat-upload").show();
  }
  havePromoCode() {
    $("#promoApplySection").show()
    $("#havePromoCode").hide();
  }
  sendAsMail() {
    alert("Your chat messages have been send to your mail successfully.");
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "chat/sendChatAsMail",
        siteId: localStorage.getItem("siteId"),
        chatGroupId: "1703",
        userId: "19909",
        bundleId: "",
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success == "1") {

      }
    });
  };
  endChat() {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "endChat",
        siteId: localStorage.getItem("siteId"),
        chatGroupId: "1703",
        userId: localStorage.getItem("userId"), //"19906",
        bundleId: "",
      }),
    };
    $(".loader").show();
    apiCalling(apiJson).then((data) => {
      if (data.success == "1") {
        $(".loader").hide();
      }
    });
  }
  render() {
    return (
      <div className="purpleWrap">
        <Header></Header>
        <div className="container">
          <div className="main-wrapper border-0 px-0 px-md-5">
            <div className="col-md-12 p-0">
              <ul className="breadcrumb">
                {/* <li><a href="#">Messaging</a><i className="fa fa-chevron-right"></i></li> */}
                <li>
                {localStorage.getItem("role") == "ROLE_USER" ?
                    <a href="/home/doctor-list-main">Messaging</a>
                    :
                    <a href="/doctor/dashboard">Dashboard</a>
                  }
                   <i className="fa fa-chevron-right"></i></li>
                <li><a href="/chatmessagegroup">Chat Questions</a><i className="fa fa-chevron-right"></i></li>
                <li>Chats</li>
              </ul>
            </div>
            <div className="chat-outer">
              <div className="chat-header">
                <span className="chat-span-head">
                  <i className="fa fa-comment"></i> Chats
                </span>
                <span className="chat-span">
                  <button type="button" className="btn-default" onClick={() => this.endChat()} >End Chat</button>
                </span>
                <span className="chat-span">
                  <a href="/chatmessagegroup">
                    <button type="button" className="btn-default" >View Question</button>
                  </a>
                </span>
                <span className="chat-span">
                  <button type="button" onClick={() => this.sendAsMail()} className="btn-default" >Send As Mail</button>
                </span>
              </div>
              <div className="chat-body">

                <ul className="messages">
                  <div className="col-md-12 text-center color-maintext cursor-pointer">
                    <i className="fa fa-arrow-down"></i> Load earlier messages
                  </div><br></br>
                  <li className="message left">
                    <div className="avatar"><img src={avatar} className="avatarimg"></img></div>
                    <div className="text_wrapper">
                      <div className="text-header">
                        <h6>Ortho</h6>
                        <time className="message-time">15 May 2020 06:05 PM</time>
                      </div>
                      <p className="text">Hello Name,</p>
                      <p className="text">Dr. Matt Murdock will chat with you soon.</p>
                    </div>
                  </li>
                  <li className="message right">
                    <div className="avatar"><img src={avatar} className="avatarimg"></img></div>
                    <div className="text_wrapper">
                      <div className="text-header">
                        <h6>Name</h6>
                        <time className="message-time"> 06:05 PM</time>
                      </div>
                      <p className="text">chat with you soon.</p>
                    </div>
                  </li>
                </ul>
                <div className="col-md-12 textarea-wrapper">
                  <p className="textarea-count">10000 characters left</p>
                  <textarea className="textarea" placeholder="Send a message..."></textarea>
                </div>
                <div className="col-md-12 chat-bottom">
                  <div className="messageFileUpload" onClick={this.upload}>
                    <input id="file1" name="file1" tabindex="33" className="uploadattach" type="file" />
                  </div>
                  <input type="button" value="Upload" className="chat-upload" id="chat-upload"></input>
                  <input type="submit" value="Send" className="chat-send float-right"></input>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    )
  }
}
