import React, { Component } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../layout/header";
import Footer from "../layout/footer";
import DoctorList from "./doctor-list";
import search from "../image/theme/magnifying-glass.png";
import Pagination from "react-js-pagination";
import { apiCalling } from "../apiService";
import $ from "jquery";

export default class DoctorListMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      doctorlist: [],
      doctorDataCount: "",
      max: 0,
      offset: "24",
      loading: false,
    };

    const loginRequest = {
      method: "POST",
      body: JSON.stringify({
        functionName: "doctorList",
        siteId: localStorage.getItem("siteId"),
        max: this.state.max,
        offset: this.state.offset,
      }),
    };

    apiCalling(loginRequest).then((data) => {
      if (data.success == "1") {
        this.setState({
          doctorlist: data.doctorData,
        });
        this.setState({ doctorDataCount: data.doctorDataCount });
        //alert(this.state.doctorDataCount);
        $(".loader").hide();
      }
    });
  }
  hideLoader = () => {
    this.setState({ loading: false });
  };

  doctorListAPI(max, offset) {
    window.scrollTo(0, 0);
    $(".loader").show();
    const loginRequest = {
      method: "POST",
      body: JSON.stringify({
        functionName: "doctorList",
        siteId: localStorage.getItem("siteId"),
        max: max,
        offset: offset,
      }),
    };
    apiCalling(loginRequest).then((data) => {
      if (data.success == "1") {
        this.setState({
          doctorlist: data.doctorData,
        });
        $(".loader").hide();
      } else {
        alert(data.errorMessage);
      }
    });
  }
  handlePageChange(pageNumber) {
    let max = this.state.max;
    let offset = this.state.offset;
    const newmax = Number(pageNumber - 1) * Number(offset);
    this.setState({ max: newmax });
    this.doctorListAPI(newmax, offset);
    this.setState({ activePage: pageNumber });
  }

  profileAvailable() {
    $("#drServiceMain1").slideDown(400);
  }
  profileAvailableCancl() {
    $("#drServiceMain1").slideUp(400);
  }
  componentDidMount() {
    let self = this;
    $(".loader").show();
    //this.handlePageChange(4);
  }
  render() {
    return (
      <div className="purpleWrap">
        <Header></Header>
        <div className="container drListMain">
          <div className="purplehealthfeaturehead text-left">
            Doctors
            <div className="drSearch">
              <input
                type="text"
                className="drSearchBox"
                placeholder="Search doctor..."
              ></input>
              <img src={search} width="20"></img>
            </div>
          </div>
          <div className="specltyBox">
            <span className="specltyList specltyactv">All</span>
            <span className="specltyList">Cardiology</span>
            <span className="specltyList">Orthopaedics</span>
            <span className="specltyList">Concology</span>
            <span className="specltyList">Dermetology</span>
            <span className="specltyList">Surgery</span>
            <span className="specltyList">Gynocology</span>
          </div>

          <div className="doctorDetailMain my-4">
            {this.state.doctorlist.map((obj, index) => {
              return (
                <DoctorList
                  docData={obj}
                  drService={obj.services}
                  consti={index}
                ></DoctorList>
              );
            })}
          </div>
          <div className="text-center">
            <Pagination
              hideFirstLastPages
              activePage={this.state.activePage}
              itemsCountPerPage="24"
              totalItemsCount={this.state.doctorDataCount}
              pageRangeDisplayed="10"
              onChange={this.handlePageChange.bind(this)}
            />
          </div>
        </div>
        <Footer></Footer>
      </div>
    );
  }
}
