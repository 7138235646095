import React, {  Suspense, useState,useEffect } from "react";
import Edit from "../../image/icons/edit.png";
import tick from "../../image/icons/tick.png";
import Deactivate from "../../image/icons/deactvate.png";
import { apiCalling } from "../../apiService";
import { Link } from "react-router-dom";
import $ from "jquery";
import Pagination from "react-js-pagination";
import Header from "../../layout/header"
import Footer from "../../layout/footer"
import Logoimg from "../../image/icons/image-preview.png";

const RepApprovalList=()=>  {
    const[approvalResult,setapprovalResult]=useState([]);
    const[totalCount,settotalCount]=useState(0)
    const[activePage,setactivePage]=useState(1);
    const[isLoading,setisLoading]=useState('0');
    const[showMessage,setshowMessage]=useState('"Waiting for data... "');

    const handlePageChange=(pageNumber) =>{
        setactivePage({ activePage: pageNumber });
        var offset;
        var max;
        if (pageNumber === "1") {
            offset = "0";
            max = "10";
            setactivePage(pageNumber);
            setisLoading('0');           
        }
        else {
            offset = parseInt(pageNumber - 1) * 10;
            max = "10";
            setactivePage(pageNumber);
            setisLoading('0');  
            window.localStorage.setItem("repapprovalpageNumber", pageNumber);
      window.localStorage.setItem("repapprovaloffset", offset);
        }
        const apiJson = {
            method: "POST",
            body: JSON.stringify({
                functionName: "repApprovalListing",
                cmbSelect: $("#searchType").val() === '0' ? '' : $("#searchType").val(),
                txtInput: $("#resultTxt").val(),
                Offset: JSON.stringify(offset),
                max: max
            }),
        };
        $(".loader").show();
        apiCalling(apiJson).then((data) => {
            if (data.success === "1") {
                setapprovalResult(data.RepApprovalSearchData);
                settotalCount(data.totalCount);  
                $(".loader").hide();
            }
        });
    }

    
    useEffect(() => {
        getReApprovalDetails();
      }, []);

    const getReApprovalDetails=()=> {
        $(".loader").show();
        const apiJson = {
            method: "POST",
            body: JSON.stringify({
                functionName: "repApprovalListing",
                cmbSelect: "",
                txtInput: "",
                Offset:
          window.localStorage.getItem("repapprovaloffset") == null
            ? 0
            : window.localStorage.getItem("repapprovaloffset"),
                max: "10"
            }),
        };
        apiCalling(apiJson).then((data) => {
            if (data.success === "1") {
                $(".loader").hide();
                setapprovalResult(data.RepApprovalSearchData);
                settotalCount(data.totalCount);  
                setactivePage(Number(window.localStorage.getItem("repapprovalpageNumber")));
            }
            if (data.success === "0") {
                $(".loader").hide();
                setapprovalResult([]);
                setshowMessage(data.errorMessage); 
            }
        });
    }

  const  changeText = () => {
        if ($("#searchType").val() !== '0') {
            document.getElementById("resultTxt").disabled = false
            $('input[name=resultTxt]').focus();
        }
        else {
            document.getElementById("resultTxt").disabled = true
        }
        document.getElementById("resultTxt").value = ""
    }

    const searchHandler=()=> {

        if ($("#searchType").val() !== "0" && $("#resultTxt").val() === "") {
            var t = document.getElementById("searchType");
            var selectedText = t.options[t.selectedIndex].text;
            alert(selectedText + " Required");
            $("#resultTxt").focus();
            return false;
        } else {
            const searchData = {
                method: "POST",
                body: JSON.stringify({
                    functionName: "repApprovalListing",
                    cmbSelect: $("#searchType").val() === '0' ? '' : $("#searchType").val(),
                    txtInput: $("#resultTxt").val(),
                    Offset: "0",
                    max: "10"
                }),
            };
            $(".loader").show();
            apiCalling(searchData).then((data) => {
                if (data.success === "1") {
                    $("#paginationType").show();
                    $(".loader").hide();
                    setapprovalResult(data.RepApprovalSearchData);
                    settotalCount(data.totalCount);  
                    setactivePage(1); 
                }
                if (data.success === "0") {
                    $("#paginationType").hide();
                    $(".loader").hide();
                    setapprovalResult([]);
                    setshowMessage(data.errorMessage);  
                }
            })
        }
    }

    const statusChangeHandler=(id, Status)=> {

        var x = false;
        if (Status === true) {
            x = window.confirm("Do you want to Enable this?")
        }
        else {
            x = window.confirm("Do you want to Disable this?")
        }
        if (x === true) {
            const SatusData = {
                method: "POST",
                body: JSON.stringify({
                    functionName: "approveRepId",
                    repId: id,
                    isApproved: Status === true ? 'false' : 'true'
                }),
            };
            $(".loader").show();
            apiCalling(SatusData).then((data) => {
                if (data.success === "1") {
                    $(".loader").hide();
                    alert("Status Changed Successfully")
                    getReApprovalDetails()
                }
                if (data.success === "0") {
                    $(".loader").hide();
                    alert(data.errorMessage)
                }
            })
        }
    }

    const srcTypeImage=(ev)=> {
        ev.target.src = Logoimg;
    }


        return (
            <div className="purpleWrap bg-clr-wthHead">
                <Suspense fallback={<div></div>}>
                    <section>
                        <Header />{" "}
                        <div className='loader'></div>
                    </section>
                </Suspense>
                <div className='loader'></div>
                <div className="container mb-5">
                    <div>
                        <ul className="breadcrum-adminMangmnt">
                            <li><a href="/admin/dashboardAdmin">Dashboard</a></li>
                            <li><a href="/adminDashboard/pharmaDashboard">Pharma Ad Approval</a></li>
                            <li><a href="" className="current">Rep Approval List</a></li>
                        </ul>
                    </div>
                    <div className="head-hospMangmntList">Rep Approval List</div>

                    <div className="HosptlMangemntTable">
                        <div className="AddNewHspbtnDiv">
                            <a href="/adminManagement/pharmadashboardList/addRep" className="addnewhspbtn"> Add New Rep</a>
                        </div>
                        {totalCount > 5 ?
                            <div className="pagination-qualList" id="paginationType">
                                <Pagination
                                    activePage={activePage}
                                    itemsCountPerPage={10}
                                    totalItemsCount={totalCount}
                                    pageRangeDisplayed={10}
                                    onChange={handlePageChange}
                                />
                            </div>
                            : ""}
                        <div className="tableHeader-HspMangemnt">
                            <div className="selectField-HspMangmnt">
                                <select className="slecthsp slecthspMob" id="searchType" name="searchType" onChange={() => changeText()}  >
                                    <option value="0">Search In</option>
                                    <option value="1">ID</option>
                                    <option value="2">Rep Name</option>
                                    <option value="3">Email id</option>
                                    <option value="4">Phone Number</option>
                                    <option value="5">Company Name</option>
                                </select>
                            </div>
                            <div className="selectField-HspMangmnt">
                                <input type="text" className="hspmngInput hspmngInputmob" name="resultTxt" id="resultTxt" disabled />
                            </div>
                            <div className="selectField-HspMangmnt">
                                <button className="searchbtn-hspMng" onClick={() => searchHandler()}>Search</button>
                            </div>
                        </div>
                        {approvalResult.length > 0 ?
                            <div className="table-responsive">
                                <table className="table table-bordered-hsp">
                                    <thead>
                                        <tr>
                                            <th className="tablehead-hspmng">ID</th>
                                            <th className="tablehead-hspmng">Rep Name </th>
                                            <th className="tablehead-hspmng">Company Name</th>
                                            <th className="tablehead-hspmng">Phone Number</th>
                                            <th className="tablehead-hspmng">Email Id</th>
                                            <th className="tablehead-hspmng">Pharma Rep Logo</th>
                                            <th className="tablehead-hspmng">Profile description</th>
                                            <th className="tablehead-hspmng">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {approvalResult.map((data) => (
                                            <tr>
                                                <td>{data.repId}</td>
                                                <td>{data.repName}</td>
                                                <td>{data.companyName}</td>
                                                <td>{data.phoneNumber}</td>
                                                <td>{data.emailId}</td>
                                                <td className="text-center"><span className="replogoImg"> <img onError={srcTypeImage} src={data.repLogo} alt="Logoimg" /></span> </td>
                                                <td><div className="repdescritn-pharma">{data.profileDescription}</div></td>
                                                <td className="nowrap">
                                                    <div>
                                                        <span><Link to={
                                                            "/adminManagement/pharmadashboardList/addRep/" +
                                                            data.repId
                                                        } className="mr-2"><img src={Edit} alt="" width="18" className="img-fluid" /></Link></span>
                                                        {data.isApproved === true ? <img src={Deactivate} width="20" title="Click to Activate " className="mr-1 conf-cursorpointer" alt="" onClick={() => { statusChangeHandler(data.repId, true) }}></img> : <img src={tick} alt="" title="Click to Deactivate" className="mr-1 conf-cursorpointer" width="18" onClick={() => { statusChangeHandler(data.repId, false) }}></img>}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {totalCount > 5 ?
                                    <div className="pagination-qualList pagination-qualListwidth">
                                        <Pagination
                                            activePage={activePage}
                                            itemsCountPerPage={10}
                                            totalItemsCount={totalCount}
                                            pageRangeDisplayed={10}
                                            onChange={handlePageChange}
                                        />
                                    </div>
                                    : ""}
                            </div>
                            :
                            <p className="error-msg text-center mb-0"> {showMessage} </p>
                        }
                    </div>
                </div>
                <Suspense fallback={<div></div>}>
                    <section>
                        <Footer />
                    </section>
                </Suspense>
            </div>
        );
    }

export default RepApprovalList;