import React, { Component, Suspense } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";

const Pharmadashboard =()=> {
  
    return (
      <div className="dashboard-doctorOuter">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />

            <div className="container mt-5">
              <div>
                <ul className="breadcrum-adminMangmnt">
                  <li>
                    <a href="/admin/dashboardAdmin">Dashboard</a>
                  </li>
                  <li>
                    <a href="" className="current">
                      Pharma Ad Approval
                    </a>
                  </li>
                </ul>
              </div>

              <div className="doctorWork-dashboard">
                <div className="adminMangemnt-dashboard">
                  <div className="admin-dc-dashBtns">
                    <a href="/adminManagement/pharmadashboardList/repApprovalList">
                      <div>Rep Approval</div>
                    </a>
                  </div>
                  <div className="admin-dc-dashBtns">
                    <a href="/adminManagement/pharmadashboardList/adApproval">
                      <div>Ad Approval</div>
                    </a>
                  </div>
                  <div className="admin-dc-dashBtns">
                    <a href="/adminManagement/pharmadashboardList/pharmaCompanyList">
                      <div>Pharma Company List</div>
                    </a>
                  </div>
                  <div className="admin-dc-dashBtns">
                    <a href="/adminManagement/pharmadashboardList/idaStoresList">
                      <div>Ida Store(s) List</div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Suspense>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
export default  Pharmadashboard;
