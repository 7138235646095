import React, { useState } from "react";
import InnerHTML from "dangerously-set-html-content";
import ViewImg from "../../image/icons/view.png";
import Pagination from "react-js-pagination";
import Analisationpopup from "./analisationpopup";

function AnalyticsListNewsFeedView(props) {
    const [emojiData, setEmojidata] = useState()
    const [SelectedDetailsType, setSelectedDetailsType] = useState("");
    const [flag, setFlag] = useState(false);

    const handlePageChange = (pageNumber) => {
        props.setPagination(pageNumber);
    };

    const addEditSpec = (data, type) => {
        setEmojidata(data)
        setSelectedDetailsType(type)
        setFlag(!flag)
    };

    return (
        <div>
            <div className="loader"></div>
            {props.newsFeedCount !== 0 && (
                <div className="paginationSection orderm-paginationSection pagination-HspList w-100">
                    <Pagination
                        activePage={props.activepage}
                        itemsCountPerPage={10}
                        totalItemsCount={props.newsFeedCount}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                    />
                </div>
            )}
            <div className="table-responsive">
                {props.newsFeedCount !== 0 ? (
                    <table className="table table-bordered-hsp table_custom">
                        <thead>
                            <tr>
                                <th>Newsfeed Title</th>
                                <th>Newsfeed Messages</th>
                                <th>Count</th>
                                <th>Emoji Count</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.newsFeedListData.map((data, index) => (
                                <tr key={index}>
                                    <td>{data.messageTitle}</td>
                                    <td className="journl-des-txt">
                                        <InnerHTML html={data.messageDetails} />
                                    </td>
                                    <td>
                                        <a className="mr-2 count-text" onClick={() => addEditSpec(data, "Messege")}>
                                            {data.viewCount} <img src={ViewImg} alt="Edit" title="More" width="18" className="img-fluid edit_view" />
                                        </a>
                                    </td>
                                    <td>
                                        {data.emojiData.map((EmojiData) => (
                                            <span key={EmojiData.imageName}>
                                                <a className="mr-2 emoji-text">{EmojiData.count}</a>
                                                <img
                                                    src={EmojiData.imageName}
                                                    onClick={() => addEditSpec(EmojiData.userData, data.messageTitle)}
                                                    alt="Edit"
                                                    title={EmojiData.emojiName}
                                                    width="18"
                                                    className="img-fluid edit_view"
                                                />
                                            </span>
                                        ))}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <div className="nodata_found">No Data Found</div>
                )}
            </div>
            {/* {EmojiDeatilsUser.length !== 0 && (
        <div className="popup-overlay modal_overlay" id="analiseEmojiPopup">
          <div className="popup-contentDiv popup-content-padd">
            <div className="modal-popup">
              <span className="closepopupqual close-popup-top" id="txtCancel" onClick={cancelPopup}>
                <img src={Close} alt="Close" className="img-fluid" width="25" />
              </span>
              <div className="content">
                <div className="">
                  <div className="HosptlMangemntTable">
                    <div className="table-responsive">
                      <table className="table table-bordered-hsp table_custom">
                        <thead>
                          <tr>
                            <th>User Name</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {EmojiDeatilsUser.map((EmojiData, index) => (
                            <tr key={index}>
                              <td>{EmojiData.userName}</td>
                              <td>{EmojiData.createdDate}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )} */}
            {SelectedDetailsType !== "" && (
                <Analisationpopup
                    key={flag}
                    PopupnewsFeedDownloadDataId={emojiData}
                    PopupnewsFeedDownloadType={SelectedDetailsType}
                />
            )}
        </div>
    );
}

export default AnalyticsListNewsFeedView;
