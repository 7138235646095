import React, { Component } from 'react';
import Header from "../layout/header"
import Footer from "../layout/footer"
import AboutTakeNextStep from './about-take-next-step';
import Blogslist from './blogs-list';
export default class Resources extends Component {
  componentDidMount() {
    document.getElementById("success-menu").classList.add("active");
  }

  render() {
    return (
      <div className="ph-main-wrapper">
        <Header></Header>
        <Blogslist/>
        <AboutTakeNextStep />
        <Footer></Footer>
      </div>
    )
  }
}
