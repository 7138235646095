import React, { useEffect, useState } from 'react';
import Header from "../layout/header";
import Footer from "../layout/footer";
import Micorlabs from "../image/media/micro.png"
import Application from "../image/media/application.png"
import Transaction from "../image/media/transaction.png"
import Video from "../image/media/video-message.png"
import People from "../image/media/two-people.png"
import Direction from "../image/media/direction.png"
import Booking from "../image/media/booking.png"
import Telephone from "../image/media/telephone.png"
import Record from "../image/media/medical-record.png"
import Interface from "../image/media/interface.png"
import Speech from "../image/media/speech-bubble.png"
import PrimeLogo from "../image/media/prime logo1.jpg"
import Purple1 from "../image/media/purple-health.png"
import Linechart from "./doctor-report-chart"
import PDFIcon from "../image/icons/ht-pdficon.png"
import DatePicker from "react-datepicker";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { useNavigate, Link, useLocation } from "react-router-dom";
import { apiCalling, awsAPIUrlcommon } from "../apiService";
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import { isArray } from 'lodash';
function DoctorReport() {
    const location = useLocation();
    const [doctorReportList, setDoctorReportList] = useState([]);
    const [monthYear, setMonthYear] = useState(moment().format("MMMM YYYY"));
    const [imagePathData, setImagePathData] = useState("");
    const [imageDeatils, setimageDeatils] = useState({
        AWSAccessKeyId: "",
        s3bucketAccessKey: "",
        region: "us-east-1",
        bucket: "",
        bucketFolder: "",
    })

    const handleChange = (date) => {
        setMonthYear(date ? moment(date).format("MMMM YYYY") : "")
    };
    const searchDate = () => {
        if (monthYear) {
            getDoctorSiteDashboard();
        }
    }
    useEffect(() => {
        // handleChange(new Date())
        getImagePath();
        if (monthYear) {
            getDoctorSiteDashboard();
        }
    }, []);

    const downloadPDF = () => {
        const element = document.getElementById("pdf-content");
        const options = {
            background: 'white',
            scale: 2,
            useCORS: true,
        };
        html2canvas(element, options).then((canvas) => {
            const pdf = new jsPDF("p", "mm", "a4");
            const imgData = canvas.toDataURL("image/jpeg", 1.0);
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);
            pdf.save(`doctor-dashboard-${window.sessionStorage.getItem("doctorDashBName").trim().replaceAll(/\s|_/g, "-")}-${moment(monthYear).format("MM YYYY").trim().replaceAll(/\s|_/g, "-")}.pdf`);
        });
    };

    const getDoctorSiteDashboard = () => {
        document.getElementsByClassName("loader")[0].style.display = "block";
        let postData = {
            method: "POST",
            body: JSON.stringify({
                functionName: "purpleHealthDoctorSiteDashboard",
                doctor_id: window.sessionStorage.getItem("doctorDashBId"),
                month_and_year: moment(monthYear).format("YYYY-MM")
            }),
        }
        awsAPIUrlcommon(postData).then((data) => {
            if (data.success === "1") {
                setDoctorReportList(data.result);
            } else {
                setDoctorReportList([]);
            }
            document.getElementsByClassName("loader")[0].style.display = "none";
        }).catch((error) => {
            document.getElementsByClassName("loader")[0].style.display = "none"
            toast.error("An error occurred while getting details");
        });
    }

    const getImagePath = () => {
        const apiJson = {
            method: "POST",
            body: JSON.stringify({
                functionName: "imagePath",
            }),
        };
        apiCalling(apiJson).then((data) => {
            if (data.success === "1") {
                let bucketPath =
                    data.configPathDetails[0].s3BucketRelianceAddressPath +
                    "/" +
                    data.configPathDetails[0].S3BucketMainFolder +
                    "/" +
                    'doctorDashboard' +
                    "/";
                setImagePathData(bucketPath);
            }
        }).catch((error) => {
            toast.error("An error occurred while getting details");
        });
    };


    return (
        <div>
            <ToastContainer />
            <Header />
            <div className="loader"></div>
            <div className="container">
                <div>
                    <ul className="breadcrum-adminMangmnt mt-2">
                        <li>
                            <a href="/admin/doctor-dashboard-list">Doctor Dashboard List</a>
                        </li>
                        <li>
                            <a className="current">Doctor Report</a>
                        </li>
                    </ul>
                </div>
                <div className="m-auto">
                    <div className="col-11 col-lg-10 col-md-10">
                        <div className='d-flex justify-content-end my-3 align-items-center'>
                            <div className="d-flex">
                                <DatePicker
                                    dateFormat="MMMM yyyy"
                                    showMonthYearPicker
                                    selected={monthYear}
                                    onChange={handleChange}
                                    className="slecthsp slecthspMob mb-0 border-rad0"
                                />
                                <button
                                    className="searchbtn-hspMng ml-2 mt-0"
                                    type="button"
                                    onClick={searchDate}
                                    disabled={!monthYear}
                                >
                                    Search
                                </button>
                                <div className='col-12 col-lg-2 col-md-2 cursor-pointer'>
                                    <img src={PDFIcon} alt="PDFIcon" className='img-fluid ml-1' width="25" height="25" onClick={downloadPDF} title="Download Pdf" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='main-outer doctor-report' id="pdf-content">
                    <div className="dwnlod-btn-right">
                    </div>
                    <header className="header">
                        <div className='header-top'>
                            <div className="row">
                                <div className="col-lg-9 col-6 mt-3 mb-3">
                                    <h1 className='header-h1 mt-2'>Business Insights of {window.sessionStorage.getItem("doctorDashBName")}</h1>
                                    <p className='header-date'>{monthYear ?? monthYear}</p>
                                </div>
                                {(imagePathData && doctorReportList?.[0]?.association?.[0]?.topRightLogo) &&
                                    <div className="col-lg-3 col-6 d-flex justify-content-end align-items-center pr-5">
                                        <img src={imagePathData + doctorReportList?.[0]?.association?.[0]?.topRightLogo} alt="Img1" className='img-fluid' width="100" height="100" />
                                    </div>
                                }
                            </div>

                        </div>
                    </header>
                    <div className='violet-border'></div>
                    <div className="utilization-snapshot">
                        <div className="">
                            <div className="">
                                <h2 className='utilization-head'>Utilization Snapshot</h2>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-12 pr-lg-0 pr-md-0">
                                    <div className="stats">
                                        <div className="stat-box">
                                            <div className="row border-blue">
                                                <div className="col-8">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <h3 className='count-report'>{doctorReportList?.[0]?.utilizationSnapshot?.totalPatient || 0}
                                                            </h3>
                                                        </div>
                                                        <div className="col-6">
                                                            <span className={(doctorReportList?.[0]?.utilizationSnapshot?.totalPatientChange >= 0) ? `increase` : `decrease`}>
                                                                {(doctorReportList?.[0]?.utilizationSnapshot?.totalPatientChange >= 0) ? `▲ ${doctorReportList?.[0]?.utilizationSnapshot?.totalPatientChange}` : `▼ ${Math.abs(doctorReportList?.[0]?.utilizationSnapshot?.totalPatientChange)}`}
                                                                {/* {doctorReportList?.[0]?.utilizationSnapshot?.totalPatientChange || 0} */}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <p className='patient-visit-count'>Total patient visits</p>
                                                </div>
                                                <div className="col-4">
                                                    <img src={Interface} alt="Img1" className='img-fluid' width="60" height="60" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="stat-box">
                                            <div className="row border-blue">
                                                <div className="col-8">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <h3 className='count-report'>{doctorReportList?.[0]?.utilizationSnapshot?.uniquePatient || 0}</h3>
                                                        </div>
                                                        <div className="col-6">
                                                            <span className={(doctorReportList?.[0]?.utilizationSnapshot?.uniquePatientChange >= 0) ? `increase` : `decrease`}>
                                                                {(doctorReportList?.[0]?.utilizationSnapshot?.uniquePatientChange >= 0) ? `▲ ${doctorReportList?.[0]?.utilizationSnapshot?.uniquePatientChange}` : `▼ ${Math.abs(doctorReportList?.[0]?.utilizationSnapshot?.uniquePatientChange)}`}
                                                                {/* {doctorReportList?.[0]?.utilizationSnapshot?.uniquePatientChange || 0} */}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <p className='patient-visit-count'>Unique patient visits</p>
                                                </div>
                                                <div className="col-4">
                                                    <img src={Record} alt="Img1" className='img-fluid' width="80" height="80" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {(isArray(doctorReportList?.[0]?.patientVisitData) && (doctorReportList?.[0]?.patientVisitData.length >= 0)) &&
                                    <div className="col-lg-8 col-md-6 col-12 graph-line" >
                                        <Linechart patientVisitData={doctorReportList?.[0]?.patientVisitData} />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='blue-border'></div>
                    <div className="business-snapshot">
                        <div className="">
                            <div className="">
                                <h2 className='business-head'>Business Snapshot</h2>
                            </div>
                            <div className="row mr-0 border-green">
                                <div className="col-lg-5 col-md-5 col-12">
                                    <div className="stats">
                                        <div className="stat-box">
                                            <div className="row border-grey m-0">
                                                <div className="col-8">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <h3 className='count-report'>{doctorReportList?.[0]?.businessSnapshot?.TotalTransaction || 0}
                                                            </h3>
                                                        </div>
                                                        <div className="col-6">
                                                            <span className={(doctorReportList?.[0]?.businessSnapshot?.totalTransactionChange >= 0) ? `increase` : `decrease`}>
                                                                {(doctorReportList?.[0]?.businessSnapshot?.totalTransactionChange >= 0) ? `▲ ${doctorReportList?.[0]?.businessSnapshot?.totalTransactionChange}` : `▼ ${Math.abs(doctorReportList?.[0]?.businessSnapshot?.totalTransactionChange)}`}
                                                                {/* {doctorReportList?.[0]?.businessSnapshot?.totalTransactionChange || 0} */}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <p className='patient-visit-count'>Transactions
                                                    </p>
                                                </div>
                                                <div className="col-4">
                                                    <img src={Transaction} alt="Img1" className='img-fluid' width="60" height="60" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="stat-box">
                                            <div className="row m-0">
                                                <div className="col-8 ">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <h3 className='count-report'>{doctorReportList?.[0]?.businessSnapshot?.inPerson || 0}</h3>
                                                        </div>
                                                        <div className="col-6">
                                                            <span className={(doctorReportList?.[0]?.businessSnapshot?.inPersonChange >= 0) ? `increase` : `decrease`}>
                                                                {(doctorReportList?.[0]?.businessSnapshot?.inPersonChange >= 0) ? `▲ ${doctorReportList?.[0]?.businessSnapshot?.inPersonChange}` : `▼ ${Math.abs(doctorReportList?.[0]?.businessSnapshot?.inPersonChange)}`}
                                                                {/* {doctorReportList?.[0]?.businessSnapshot?.inPersonChange || 0} */}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <p className='patient-visit-count'>In-person</p>
                                                </div>
                                                <div className="col-4">
                                                    <img src={People} alt="Img1" className='img-fluid' width="60" height="60" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-12">
                                </div>
                                <div className="col-lg-5 col-md-5 col-12">
                                    <div className="stats">
                                        <div className="stat-box">
                                            <div className="row border-grey m-0" >
                                                <div className="col-8">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <h3 className='count-report'>{doctorReportList?.[0]?.businessSnapshot?.videoChat || 0}
                                                            </h3>
                                                        </div>
                                                        <div className="col-6">
                                                            <span className={(doctorReportList?.[0]?.businessSnapshot?.videoChatChange >= 0) ? `increase` : `decrease`}>
                                                                {(doctorReportList?.[0]?.businessSnapshot?.videoChatChange >= 0) ? `▲ ${doctorReportList?.[0]?.businessSnapshot?.videoChatChange}` : `▼ ${Math.abs(doctorReportList?.[0]?.businessSnapshot?.videoChatChange)}`}
                                                                {/* {doctorReportList?.[0]?.businessSnapshot?.videoChatChange || 0} */}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <p className='patient-visit-count'>Video Chat</p>
                                                </div>
                                                <div className="col-4">
                                                    <img src={Video} alt="Img1" className='img-fluid' width="60" height="60" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="stat-box">
                                            <div className="row m-0">
                                                <div className="col-8">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <h3 className='count-report'>{doctorReportList?.[0]?.businessSnapshot?.textChat || 0}</h3>
                                                        </div>
                                                        <div className="col-6">
                                                            <span className={(doctorReportList?.[0]?.businessSnapshot?.textChatChange >= 0) ? `increase` : `decrease`}>
                                                                {(doctorReportList?.[0]?.businessSnapshot?.textChatChange >= 0) ? `▲ ${doctorReportList?.[0]?.businessSnapshot?.textChatChange}` : `▼ ${Math.abs(doctorReportList?.[0]?.businessSnapshot?.textChatChange)}`}
                                                                {/* {doctorReportList?.[0]?.businessSnapshot?.textChatChange || 0} */}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <p className='patient-visit-count'>Text Chat</p>
                                                </div>
                                                <div className="col-4">
                                                    <img src={Speech} alt="Img1" className='img-fluid' width="60" height="60" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='green-border'></div>
                    <div className="google-snapshot">
                        <div className="">
                            <div className="">
                                <h2 className='google-head'>Google My Business</h2>

                            </div>
                            <div className="row mr-0 border-red">
                                <div className="col-12 text-center py-2 business-profile-head">
                                    Business Profile Interaction - {doctorReportList?.[0]?.googleMyBusiness?.businessProfileInteraction || 0}

                                </div>
                                <div className="col-lg-5 col-md-5 col-12">
                                    <div className="stats">
                                        <div className="stat-box">
                                            <div className="row border-grey m-0">
                                                <div className="col-8">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <h3 className='count-report'>{doctorReportList?.[0]?.googleMyBusiness?.Calls || 0}
                                                            </h3>
                                                        </div>

                                                    </div>
                                                    <p className='patient-visit-count'>Calls</p>
                                                </div>
                                                <div className="col-4">
                                                    <img src={Telephone} alt="Img1" className='img-fluid' width="60" height="60" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="stat-box">
                                            <div className="row m-0">
                                                <div className="col-8">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <h3 className='count-report'>{doctorReportList?.[0]?.googleMyBusiness?.Bookings || 0}</h3>
                                                        </div>

                                                    </div>
                                                    <p className='patient-visit-count'>Bookings</p>
                                                </div>
                                                <div className="col-4">
                                                    <img src={Booking} alt="Img1" className='img-fluid' width="60" height="60" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-12">

                                </div>
                                <div className="col-lg-5 col-md-5 col-12">
                                    <div className="stats">
                                        <div className="stat-box">
                                            <div className="row border-grey m-0">
                                                <div className="col-8 ">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <h3 className='count-report'>{doctorReportList?.[0]?.googleMyBusiness?.Directions || 0}
                                                            </h3>
                                                        </div>

                                                    </div>
                                                    <p className='patient-visit-count'>Directions</p>
                                                </div>
                                                <div className="col-4">
                                                    <img src={Direction} alt="Img1" className='img-fluid' width="60" height="60" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="stat-box">
                                            <div className="row m-0">
                                                <div className="col-8">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <h3 className='count-report'>{doctorReportList?.[0]?.googleMyBusiness?.websiteClicks || 0}</h3>
                                                        </div>

                                                    </div>
                                                    <p className='patient-visit-count'>Website Clicks</p>
                                                </div>
                                                <div className="col-4">
                                                    <img src={Application} alt="Img1" className='img-fluid' width="60" height="60" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='red-border'></div>
                    <div className="header py-3">
                        <div className="">
                            <div className="row mr-0 violet-text bottom-text">
                                <div className="col-6">
                                    <img src={Purple1} alt="Img1" className='img-fluid mb-2' width="190" height="auto" />
                                    <div>
                                        {doctorReportList?.[0]?.association?.[0]?.contactPersonName
                                            ? `${doctorReportList.association[0].contactPersonName}`
                                            : ""}
                                    </div>
                                    <div>{doctorReportList?.[0]?.association?.[0]?.contactPersonPhone || ""}</div>
                                    <div>{doctorReportList?.[0]?.association?.[0]?.contactPersonEmail || ""}</div>
                                </div>
                                {(imagePathData && doctorReportList?.[0]?.association?.[0]?.bottomRightLogo) &&
                                    <div className="col-6 d-flex align-items-center justify-content-end pr-5">
                                        <img src={imagePathData + doctorReportList?.[0]?.association?.[0]?.bottomRightLogo} alt="Img1" className='img-fluid'
                                        height="100" />
                                    </div>
                                }
                            </div>
                            <div className="text-small text-center py-4">
                                - Private and confidential. Property of
                                PurpleHealth
                                . Not to be forwarded or duplicated -

                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            <Footer />
        </div>
    );
                                       
}

export default DoctorReport;
