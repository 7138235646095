import React, { Component } from 'react';
import Solution1 from "../image/theme/paid-media-marketing.png"
import Solution2 from "../image/theme/search-engine-marketing.png"
import Solution3 from "../image/theme/strategic-business-insights.png"
import Solution4 from "../image/theme/social-media-marketing.png"
import Close from "../image/theme/closebtn.png"
import Add from "../image/theme/add.png"
export default class digitalMarketingolutions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isContentVisible: false,
            isContentVisibleTwo:false,
            isContentVisibleThree:false,
            isContentVisibleFour:false
          };
    }
    toggleVisibility = (contentId) => {
        this.setState((prevState) => ({
          [contentId]: !prevState[contentId],
        }));
      };
    render() {
        const { isContentVisible, isContentVisibleTwo, isContentVisibleThree,isContentVisibleFour } = this.state;
        return (
            <div className="ph-main-outerdiv">
                <div className='container-lg'>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="ph-db-banner">
                                <h2 className="ph-main-title-2 mt-5">
                                Our Solutions for Digital Marketing
                                </h2>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="d-md-flex flex-md-equal w-100 mt-3 mb-5 my-md-5">
                                <div className="col-lg-6 px-0">
                                    <div className='ph-local-pm-more'>
                                        <img src={Solution1} alt="digitalBrand" width="605" height="291" className='img-fluid' />
                                    </div>
                                </div>
                                <div className="col-lg-6 px-0">
                                    <div className="ph-solutions">
                                        <h3 className="ph-solution-title">Paid Media Marketing</h3>
                                        <p className="ph-solution-content">
                                        Expand your reach and attract more patients. Run campaigns across social platforms to generate leads, engagement etc.
                                        </p>
                                        <h4 className="ph-solution-sub">
                                            Key Features :
                                        </h4>
                                        <div className="d-flex flex-wrap">
                                            <div className="ph-key-features">
                                            Reach the right audience with precision targeting based on demographics, location, and search intent
                                            </div>
                                            <div className="ph-key-features">
                                            Capture the attention of potential patients actively searching for healthcare services
                                            </div>
                                            <div className={`d-flex flex-wrap ${isContentVisible ? 'visible-class' : 'invisible-class'}`}>
                                                    {/* <div className="ph-key-features">
                                                    &nbsp;
                                                    </div> */}
                                                </div>
                                        </div>
                                        <button className={`ph-learn-more ${isContentVisible ? 'ph-btn-show' : 'ph-btn-hide'}`} onClick={() => this.toggleVisibility("isContentVisible")}>
                                                <span className={`isspan ${isContentVisible ? 'isspanHide' : 'isspanShow'}`}>Learn More</span>
                                                <span className='text-right ph-icon-wrapper'>
                                                    {isContentVisible ?
                                                    <img src={Close} alt="Add" className='img-fluid' width="20" height="20" />:
                                                    <img src={Add} alt="Close" className='img-fluid' width="20" height="20" />
                                                    }
                                                </span>
                                            </button>
                                    </div>
                                </div>
                            </div>
                            <div className="d-md-flex flex-md-equal w-100 my-5">
                                <div className="col-lg-6 px-0">
                                    <div className='ph-local-pm-more'>
                                        <img src={Solution2} alt="digitalBrand" width="605" height="291" className='img-fluid' />
                                    </div>
                                </div>
                                <div className="col-lg-6 px-0">
                                    <div className="ph-solutions">
                                        <h3 className="ph-solution-title">Search Engine Marketing</h3>
                                        <p className="ph-solution-content">
                                        {!isContentVisibleTwo ?
                                            "Through strategic keyword targeting, SEM positions your services at the forefront of patient searches, significantly improving discovery and engagement."
                                            :
                                            "Increase your visibility in search engine results and attract more patients. We optimize your online content, making it easier for potential patients to find you."
                                        }
                                        </p>
                                        <h4 className="ph-solution-sub">
                                            Key Features :
                                        </h4>
                                        <div className="d-flex flex-wrap">
                                            <div className="ph-key-features">
                                            Direct traffic to your local listings, landing pages, websites etc
                                            </div>
                                            <div className="ph-key-features">
                                            Ensure that your brand shows up in search engine results
                                            </div>
                                            <div className={`d-flex flex-wrap ${isContentVisibleTwo ? 'visible-class' : 'invisible-class'}`}>
                                                    <div className="ph-key-features">
                                                        Higher website traffic and patient inquiries, translating into increased patient acquisition rates
                                                    </div>
                                                </div>
                                        </div>
                                        <button className={`ph-learn-more ${isContentVisibleTwo ? 'ph-btn-show' : 'ph-btn-hide'}`} onClick={() => this.toggleVisibility("isContentVisibleTwo")}>
                                                <span className={`isspan ${isContentVisibleTwo ? 'isspanHide' : 'isspanShow'}`}>Learn More</span>
                                                <span className='text-right ph-icon-wrapper'>
                                                    {isContentVisibleTwo ?
                                                    <img src={Close} alt="Add" className='img-fluid' width="20" height="20" />:
                                                    <img src={Add} alt="Close" className='img-fluid' width="20" height="20" />
                                                    }
                                                </span>
                                            </button>
                                    </div>
                                </div>
                            </div>
                            <div className="d-md-flex flex-md-equal w-100 my-5">
                                <div className="col-lg-6 px-0">
                                    <div className='ph-local-pm-more'>
                                        <img src={Solution3} alt="digitalBrand" width="605" height="291" className='img-fluid' />
                                    </div>
                                </div>
                                <div className="col-lg-6 px-0">
                                    <div className="ph-solutions">
                                        <h3 className="ph-solution-title">Strategic Business Insights</h3>
                                        <p className="ph-solution-content">
                                        Understand patient needs, discover patient trends, grasp healthcare dynamics and enhance patient satisfaction and loyalty.
                                        </p>
                                        <h4 className="ph-solution-sub">
                                            Key Features :
                                        </h4>
                                        <div className="d-flex flex-wrap">
                                            <div className="ph-key-features">
                                            Acquire understanding of effective communication channels for engaging with patients
                                            </div>
                                            <div className="ph-key-features">
                                            Derive actionable insights based on customer sentiment, campaign performance etc
                                            </div>
                                            <div className={`d-flex flex-wrap ${isContentVisibleThree ? 'visible-class' : 'invisible-class'}`}>
                                                    <div className="ph-key-features">
                                                        Analyse traffic reports, reviews feeds, keyword clouds, reviews bucketing, google Q & A etc
                                                    </div>
                                                </div>
                                        </div>
                                        <button className={`ph-learn-more ${isContentVisibleThree ? 'ph-btn-show' : 'ph-btn-hide'}`}  onClick={() => this.toggleVisibility("isContentVisibleThree")}>
                                                    <span className={`isspan ${isContentVisibleThree ? 'isspanHide' : 'isspanShow'}`}>Learn More</span>
                                                    <span className='text-right ph-icon-wrapper'>
                                                    {isContentVisibleThree ?
                                                    <img src={Close} alt="Add" className='img-fluid' width="20" height="20" />:
                                                    <img src={Add} alt="Close" className='img-fluid' width="20" height="20" />
                                                    }
                                                </span>
                                            </button>
                                    </div>
                                </div>
                            </div>
                            <div className="d-md-flex flex-md-equal w-100 my-5">
                                <div className="col-lg-6 px-0">
                                    <div className='ph-local-pm-more'>
                                        <img src={Solution4} alt="digitalBrand" width="605" height="291" className='img-fluid' />
                                    </div>
                                </div>
                                <div className="col-lg-6 px-0">
                                    <div className="ph-solutions">
                                        <h3 className="ph-solution-title">Social Media Marketing</h3>
                                        <p className="ph-solution-content">
                                            Build a strong online community and connect with patients on social media.
                                            {isContentVisibleFour && "Our social media management services help you maintain an active and engaging online presence."}
                                        </p>
                                        <h4 className="ph-solution-sub">
                                            Key Features :
                                        </h4>
                                        <div className="d-flex flex-wrap">
                                            <div className="ph-key-features">
                                            Create, maintain, and manage informative health content across social platforms
                                            </div>
                                            <div className="ph-key-features">
                                            Ensure a professional image across social media platforms
                                            </div>
                                            <div className={`d-flex flex-wrap ${isContentVisibleFour ? 'visible-class' : 'invisible-class'}`}>
                                                    <div className="ph-key-features">
                                                        Leverage the potential of social media to broaden your audience reach
                                                    </div>
                                                </div>
                                        </div>
                                        <button className={`ph-learn-more ${isContentVisibleFour ? 'ph-btn-show' : 'ph-btn-hide'}`}  onClick={() => this.toggleVisibility("isContentVisibleFour")}>
                                                    <span className={`isspan ${isContentVisibleFour ? 'isspanHide' : 'isspanShow'}`}>Learn More</span>
                                                    <span className='text-right ph-icon-wrapper'>
                                                    {isContentVisibleFour ?
                                                    <img src={Close} alt="Add" className='img-fluid' width="20" height="20" />:
                                                    <img src={Add} alt="Close" className='img-fluid' width="20" height="20" />
                                                    }
                                                </span>
                                            </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}
