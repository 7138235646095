import React, { Component } from 'react';
import Header from "../layout/header"
import Footer from "../layout/footer"

export default class EmailSuccess extends Component {

  render() {
    return (
      <div className="purpleWrap">
        <Header></Header>
        <div className="container">
          <div className="main-wrapper">
              <div className="col-sm-12 text-center">
                <h3 className="doctor-name">Your Request Send Successfully</h3>
                <h4 className="my-4">Summary</h4>
              </div>
              <div className="form-section form-section-bgcolor pb-3">
                <div className="row row-margin">
                  <label className="col-sm-6 label-text label-text-right">Request for</label>
                  <label className="col-sm-6 ">Ask the Doctor</label>
                </div>

                <div className="row row-margin">
                  <label className="col-sm-6 label-text label-text-right">Transaction ID</label>
                  <label className="col-sm-6 ">071287-190</label>
                </div>
                <div className="col-sm-12 text-center">You will reaceive a response from the Doctor within 24-48 hours.</div>
              </div>

              <div className="col-sm-12 text-center mt-4">
                <input type="button" value="Close" className="formButton cancelButton"></input>
              </div>
          </div>
        </div>

        <Footer></Footer>
      </div>
    )
  }
}
