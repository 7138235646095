import React, { Component } from 'react';
import Header from "../layout/header"
import Footer from "../layout/footer"
import $ from "jquery";
import { apiCalling } from "../apiService";
const proxyurl = "https://cors-anywhere.herokuapp.com/";

export default class Textchat extends Component {
  constructor(props) {
    super(props);

    this.state = {
      appDocId: "",
      appHosId: "",
      chatDoctorPanelId:""
    };
    if (this.props.location.doctorId) {
      localStorage.setItem("appDocId", this.props.location.doctorId);
      localStorage.setItem("appHosId", this.props.location.hospitalId);
      localStorage.setItem("chatDoctorPanelId", this.props.location.chatDoctorPanelId);
    }
  }
  componentDidMount(){
    $("#promoApplySection").hide();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "messageChatPayment",
        chatDoctorPanelId: localStorage.getItem("chatDoctorPanelId"),
        memberId: localStorage.getItem("userId"),
        doctorId: localStorage.getItem("appDocId"),
        hospitalId: localStorage.getItem("appHosId")
      }),
    };
    
    apiCalling(apiJson).then((data) => {
      if (data.success == "1") {
      }
    });
    const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        document.body.appendChild(script);
  }
  havePromoCode(){
    $("#promoApplySection").show()
    $("#havePromoCode").hide();
  }

  paymentHandler() {
     let email = localStorage.getItem('emailId')
     let mobileNumber = localStorage.getItem('mobileNumber')
     let userName = parseFloat(localStorage.getItem('userName'))
     let options = {
         "key": "rzp_test_NUahOxzweeVBer",
         "amount": parseFloat(this.props.location.textChatPrice).toFixed(2) * 100, 
         "name": localStorage.getItem('userName'),
         "description": "",
         "image": "",
         "handler": function (response) {
             const paymenTId = response.razorpay_payment_id
             fetch( proxyurl+ "https://api.razorpay.com/v1/payments/"+ paymenTId, {
                 method: "GET",
                 headers: {
                   "Content-Type": "application/json",
                   Authorization: "Basic cnpwX3Rlc3RfTlVhaE94endlZVZCZXI6WHZiNXlLQVVrYzBqTVNBRENCSloxRXhE",
                   Accept: "application/json",
                   "Cache-Control": "no-cache",
                   Host: "api.razorpay.com"
                 },
               })
                 .then(response => response.json())
                 .then(responseJson => {
                   var razorTransferDetails = responseJson;
                   if(razorTransferDetails){
                      //  saveRazorPay(razorTransferDetails).then((data) => {
                      //      console.log("razorDetails", data.data)
                      //      if (data.data.success == "1") {
                      //        alert("Saved")
                      //      }
                      //      else {

                      //      }
                      //  })
                 }
                 });
         },
         "prefill": {
             "name": userName,
             "email": email,
             "contact": mobileNumber
         },
         "theme": {
             "color": "#514886"
         }
     };

     let rzp = new window.Razorpay(options);
     rzp.open();
 }

  render() {
    return (
      <div className="purpleWrap">
        <Header></Header>
        <div className="container">
          <div className="main-wrapper">
              <div className="col-sm-12 text-center">
                <h3 className="doctor-name">Message Chat Payment</h3>
              </div>
              <div className="form-section">
                <div className="row row-margin">
                  <label className="col-sm-6 label-text label-text-right">Amount *</label>
                  <label className="col-sm-6 "><i className="fa fa-rupee"></i> {this.props.location.textChatPrice}</label>
                </div>

                <div className="row row-margin" id="havePromoCode">
                  <label className="col-sm-12 text-center link-color" onClick={(e) => { this.havePromoCode() }}>Have a Promo code?</label>
                </div>

                <div className="row row-margin" id="promoApplySection">
                  <label className="col-sm-6 label-text label-text-right">Promo code</label>
                  <label className="col-sm-6 ">
                    <input type="text" className="input-design input-box-w-150" /><span className="link-color ml-2">Apply</span>
                  </label>
                </div>

                <div className="row row-margin">
                  <div className="col-sm-12 text-center">
                    <input  type="checkbox" id="invalidCheck" />
                    <label className="label-text ml-3" for="invalidCheck">
                      I agree with <a href="#" className="link-color">Terms and Conditions</a>
                    </label>
                  </div>
                </div>

              </div>

              <div className="col-sm-12 text-center mt-4">
                {/* <a href="../patient/textchatsuccess"> */}
                  <input type="submit" onClick={(e) => { this.paymentHandler()}} value="Pay Now" className="formButton"></input>
                {/* </a> */}
                <input type="button" value="Cancel" className="formButton cancelButton"></input>
              </div>
          </div>
        </div>

        <Footer></Footer>
      </div>
    )
  }
}
