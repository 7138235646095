import React, { Component } from 'react';
import { sendMailOrder } from '../service';
import $ from 'jquery';
export default class contactusForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
        captcha:''
        }
        
        
      }

      componentDidMount(){  
        var dataLayer = window.dataLayer || []; 
        let datalayereventAction;
        let datalayerHrefLabel;
        $("#purpleCommonButtonContact" ).on("click", function () {
            datalayereventAction = $(this).text();
            datalayerHrefLabel = "contact us";
            dataLayer.push({
              "event": "trackEvent",
              "eventCategory": "Contact us Button Click",
              "eventlabel": datalayerHrefLabel,
              "eventAction": datalayereventAction + " click"
            });
          });

          this.Captcha()
      }


    Captcha() {
        var alpha = new Array(
          "A",
          "B",
          "C",
          "D",
          "E",
          "F",
          "G",
          "H",
          "I",
          "J",
          "K",
          "L",
          "M",
          "N",
          "O",
          "P",
          "Q",
          "R",
          "S",
          "T",
          "U",
          "V",
          "W",
          "X",
          "Y",
          "Z",
          "a",
          "b",
          "c",
          "d",
          "e",
          "f",
          "g",
          "h",
          "i",
          "j",
          "k",
          "l",
          "m",
          "n",
          "o",
          "p",
          "q",
          "r",
          "s",
          "t",
          "u",
          "v",
          "w",
          "x",
          "y",
          "z"
        );
        var i;
        for (i = 0; i < 6; i++) {
          var a = alpha[Math.floor(Math.random() * alpha.length)];
          var b = alpha[Math.floor(Math.random() * alpha.length)];
          var c = alpha[Math.floor(Math.random() * alpha.length)];
          var d = alpha[Math.floor(Math.random() * alpha.length)];
          var e = alpha[Math.floor(Math.random() * alpha.length)];
          var f = alpha[Math.floor(Math.random() * alpha.length)];
          var g = alpha[Math.floor(Math.random() * alpha.length)];
        }
        var code = a + " " + b + " " + c + " " + d + " " + e + " " + f;
        
        this.setState({
            captcha:code
          });
      }
    




    validate() {           
    

        var string1 = this.state.captcha;
        var string2 = $("#txtCaptcha").val();
       
        var realCap = string1.split(" ").join("")
        
        if (realCap == string2) {

        let msg = $("#txtMsg").val()
        let usermail = $("#txtEmail").val()
        let contactno = $("#txtPhone").val()
        let name = $("#txtName").val()
       // let allData = "Hi  " + msg + " .  Name: " + name + " , Contact email : " + usermail + " , Contact Number : " + contactno
        
        let mail_text = 
        "<div>" +
        " Hi ,<br><br>" +
        "A new enquiry has been received on your website <br> <br> " +
            
        " <b>Name</b> :" + name + " <br>" +
        " <b>Phone Number </b> :" + contactno + " <br>" +
        " <b>Email ID</b> :" + usermail + " <br>" +
        " <b>Message</b> : " + msg + "<br><br>" +
        "Please reach out to them at the earliest. <br><br>" +
        " Thanks! <br>" +   
        "</div>"   
        const postData = {            
            mail_text : mail_text,  
            subject : "You have an Enquiry"               
            }
            
        sendMailOrder(postData).then((data) => {
            // alert("Successfully received your query.")
            window.location.href = "/thankyou";
            
          });

           
          } else {
            alert("Invalid Captcha")
            return false;
          }

        
    
    }
    
  render() {
    return (
        
        
        <div className="container contactus mt-4 mb-5">
            <div className="ph-fullcontactus">
            <div className="row">
                <div className="col-lg-6">
                    <div className="ph-contactleftcontent mb-5">
                        <div className="ph-purplehealthfeaturehead text-left pt-0">Contact Us</div>
                        <p className="">We want to hear from you! <br></br> Please fill out the form.</p>
                    </div>
                    <div className="ph-contactleftcontent mt-5 mb-5">
                        <div className="ph-purplehealthfeaturehead text-left pt-0">Support </div>
                        <p className="mb-0">support@purplehealth.com</p>
                        <hr></hr>
                        <p className="mb-0">Phone: 9544986088 / 8589089036 / 9544976677</p>
                        <p className="mb-0">Mon-Fri 9:30 AM - 5:30 PM</p>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="contactrightcontent">
                        <input type="text" placeholder="Your Name" id="txtName" className="ph-contactrightfield"></input>
                        <input type="text" placeholder="E-mail" id="txtEmail" className="ph-contactrightfield"></input>
                        <input type="number" placeholder="Phone Number" id="txtPhone" className="ph-contactrightfield"></input>
                        {/* <select className="ph-contactrightfield">
                            <option>Feedback</option>
                        </select> */}
                        {/* <input type="text" placeholder="Leave us a message..." className="ph-contactrightfield"></input> */}
                        <textarea type="message" placeholder="Leave us a message..." id="txtMsg" className="ph-contactrightfield"></textarea>
                        <div className="row">
                            <div className="col-md-7">
                                <input type="text" id="txtCaptcha" placeholder="Enter Displayed Characters" className="ph-contactrightfield"></input>
                            </div>
                            <div className="col-md-5 captchaBox">
                                <span className="captchaOverTexr noselect" id="lblCaptchaCode">{this.state.captcha}</span>
                                <div className="captchaRefresh">Request New Captcha
                                    <i className="fa fa-refresh" onClick={(e) => { this.Captcha() }}></i>
                                </div>
                            </div>
                        </div>
                        <div className="purplecontactusbtn mt-4"><span className="purpleCommonButton px-5" id="purpleCommonButtonContact"  onClick={(e) => { this.validate() }}>Send</span></div>
                    </div>
                </div>
            </div>
            </div>
            </div>
            
        
       
    )
  }
}
