import React, { Component, Suspense } from "react";
import { apiCalling } from "../../../apiService";
import $, { event } from "jquery";
import Close from "../../../image/icons/close-mark.png";

export default class hospitalPopup extends Component {
  state = {
    hospitalId: this.props.hospitalDeatils.hospitalId,
    hospitalProductData: [],
  };

  componentDidMount() {
    if (this.state.hospitalId > 0) {
      this.getProductDetails();
    }
  }

  isNumberKey = (event) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  isDecimalKey = (event) => {
    var charCode = event.which ? event.which : event.keyCode;
    if (charCode == 46) {
      let index = event.target.value.indexOf(".");
      if (!(index === -1)) {
        event.preventDefault();
        return false;
      }
    } else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
  };

  handleSave = () => {
    this.props.saveHospitalProductData(this.state);
  };

  changeHandler = (e, hospitalObj) => {
    let tempHospitalProductData = this.state.hospitalProductData;

    let index = tempHospitalProductData.indexOf(hospitalObj);
    if (e.target.id == "expiryDays") {
      tempHospitalProductData[index].expiryDays = e.target.value;
    } else if (e.target.id == "status") {
      tempHospitalProductData[index].status = !e.target.checked;
    } else if (e.target.id == "commision") {
      tempHospitalProductData[index].commision = e.target.value;
    }

    this.setState({ hospitalProductData: tempHospitalProductData });
  };

  cancelPopup() {
    this.props.showHospitalPopup(false);
  }

  getProductDetails = () => {
    $(".loader").show();
    const productDetails = {
      method: "POST",
      body: JSON.stringify({
        functionName: "productDetails",
        hospitalId: this.state.hospitalId,
      }),
    };
    apiCalling(productDetails).then((data) => {
      //debugger;
      if (data.success === "1") {
        let hospitalProductData = this.props.allProducts;

        data.hospitalProductData.map((s) => {
          hospitalProductData.map((d) => {
            if (s.productId === d.productId) {
              d.status = s.status;
            }
          });
        });

        let index = hospitalProductData.findIndex((d) => d.productId == 2);

        // setting 1 as defalult for messaging
        if (index > -1) {
          hospitalProductData[index].expiryDays =
            hospitalProductData[index].expiryDays > 0
              ? hospitalProductData[index].expiryDays
              : 1;
        }

        this.setState({
          hospitalProductData: hospitalProductData,
        });
      }
      $(".loader").hide();
    });
  };

  getEmbedCodeURL(productId) {
    switch (productId) {
      case 1:
        return (
          "https://www.purplehealth.com/vaas/videoChat?hospitalId=" +
          this.state.hospitalId
        );
      case 2:
        return (
          "https://www.purplehealth.com/vaas/messageChatDoctors?hospitalId=" +
          this.state.hospitalId
        );
      case 3:
        return (
          "https://www.purplehealth.com/vaas/emailConsult?hospitalId=" +
          this.state.hospitalId
        );
      case 4:
        return (
          "https://www.purplehealth.com/vaas/bookAppointment?hospitalId=" +
          this.state.hospitalId
        );
      case 5:
        return (
          "https://www.purplehealth.com/vaas/callADoctor?hospitalId=" +
          this.state.hospitalId
        );
      case 6:
        return (
          "https://www.purplehealth.com/vaas/planPurchase?hospitalId=" +
          this.state.hospitalId
        );
      case 7:
        return (
          "https://www.purplehealth.com/vaas/healthBlog?hospitalId=" +
          this.state.hospitalId
        );
      default:
        return "https://www.purplehealth.com/";
    }
  }

  render() {
    return (
      <div className="popup-overlay" style={{ display: "block" }}>
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header modal-popup">
              <span
                className="closepopupqual"
                type="button"
                onClick={() => this.cancelPopup()}
              >
                <img src={Close} alt="Close" className="img-fluid" width="25" />
                {/* &times; */}
              </span>
              <div className="header border-0">
                {this.props.hospitalDeatils.hopsitalName}
              </div>
            </div>
            <div className="modal-body">
              <div
                className="row"
                style={{
                  left: "15%!important",
                  top: "-100%",
                  zIndex: "11",
                  minHeight: "380",
                  maxHeight: "380px",
                  height: "auto",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                {this.state.hospitalProductData.map((h) => (
                  <div
                    key={h.productId}
                    className="row col-md-12"
                    style={{ margin: "10px" }}
                  >
                    <div className="row row-margin col-md-12">
                      <label className="label-text">
                        <input
                          type="checkbox"
                          name="status"
                          id="status"
                          checked={h.status}
                          onInput={(e) => {
                            this.changeHandler(e, h);
                          }}
                        />{" "}
                        {h.productName}
                      </label>
                    </div>
                    {h.status && (
                      <div className="row col-md-12">
                        <div className="row row-margin col-md-12">
                          <div className="popupdiv">
                            <label className="qualifcatin-popuplabel popuplabel-widthhspmob">
                              Commision(%){" "}
                              <span className="hspifoMantory">*</span>
                            </label>
                            {/* </div> */}
                            {/* <div className="col-md-7"> */}
                            <input
                              type="text"
                              className="qualifcatin-popupInput"
                              id="commision"
                              name="commision"
                              value={h.commision}
                              onInput={(e) => {
                                this.changeHandler(e, h);
                              }}
                              style={{ width: "140px" }}
                              onKeyPress={(e) => {
                                this.isDecimalKey(e);
                              }}
                              maxLength="10"
                            />
                          </div>
                        </div>
                        {h.productId == 2 && (
                          <div className="row row-margin col-md-6">
                            {/* <div className="col-md-5">
                              <label className="label-text">
                                Expires In Days
                                <span className="hspifoMantory">*</span>
                              </label>
                            </div>
                            <div className="col-md-7">
                              <input
                                type="text"
                                className="qualifcatin-popupInput"
                                id="expiryDays"
                                name="expiryDays"
                                value={h.expiryDays}
                                onInput={(e) => {
                                  this.changeHandler(e, h);
                                }}
                                style={{ width: "140px" }}
                                onKeyPress={(e) => {
                                  this.isNumberKey(e);
                                }}
                                maxLength="10"
                              />
                            </div> */}
                            <div className="popupdiv">
                            <label className="qualifcatin-popuplabel popuplabel-widthhspmob">
                            Expires In Days{" "}
                              <span className="hspifoMantory">*</span>
                            </label>
                            {/* </div> */}
                            {/* <div className="col-md-7"> */}
                            <input
                                type="text"
                                className="qualifcatin-popupInput"
                                id="expiryDays"
                                name="expiryDays"
                                value={h.expiryDays}
                                onInput={(e) => {
                                  this.changeHandler(e, h);
                                }}
                                style={{ width: "140px" }}
                                onKeyPress={(e) => {
                                  this.isNumberKey(e);
                                }}
                                maxLength="10"
                              />
                          </div>
                          </div>
                        )}

                        <div className="popupdiv">
                          <label className="qualifcatin-popuplabel popuplabel-widthhspmob">
                            Embed Code
                          </label>
                          {/* <div
                            className="col-md-9"
                            style={{
                              marginLeft: "-40px",
                            }}
                          > */}
                          <input
                            type="text"
                            className="qualifcatin-popupInput popupinput-widthproduct"
                            value={this.getEmbedCodeURL(h.productId)}
                            // style={{ width: "430px" }}
                          />
                          {/* </div> */}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <div className="modal-footer">
                <span className="sitesetng-svebtn" onClick={this.handleSave}>
                  Save
                </span>
                <input
                  type="button"
                  value="Cancel"
                  className="sitesetng-cancelbtn"
                  onClick={() => this.cancelPopup()}
                ></input>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
