import React, { Component } from 'react';
import Header from "../layout/header"
import Footer from "../layout/footer"
import Dashboardleftmenu from "./dashboardleftmenu"
import $ from "jquery";
import InboxMessage from "../doctor/inboxmessage" 

export default class DcotorInbox extends Component {
  componentDidMount()
  {
    $("#inbox_btn").addClass("active")
  }
 
  render() {
    return (
      <div className="purpleWrap">
        <Header></Header>
        <div className="container container-auto">
          <div className="main-wrapper1">
              <div className="row">
                <div className="col-lg-4">
                  <Dashboardleftmenu></Dashboardleftmenu>
                </div>
                <div className="col-lg-8">
                  <InboxMessage></InboxMessage>
                </div>
              </div>
           </div>
        </div>

        <Footer></Footer>
      </div>
    )
  }
}
